import React from 'react';
import styled from 'styled-components';
import { Popconfirm } from 'antd';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Box } from 'atoms';

const StyledPopconfirm = styled(Popconfirm)`
	.ant-popover-content {
		width: 450px;
		.ant-popover-inner > .ant-popover-inner-content {
			background-color: red;
		}
	}
	.ant-popover-message > .ant-popover-message-title {
		background-color: red !important;
	}
`;

export function ConfirmBeforDelete({
	actualFileCount,
	handleUploadModalOpen,
	record,
	handleDelete,
	statusExist,
}) {
	const onConfirm = () => {
		if (actualFileCount?.length === 1 && statusExist.invoiceReturnedVoucher) {
			handleUploadModalOpen();
		} else {
			handleDelete(record.id);
		}
	};

	return (
		<StyledPopconfirm
			title={
				actualFileCount?.length === 1 && statusExist.invoiceReturnedVoucher ? (
					<Box
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'flex-start',
						}}
					>
						<text style={{ fontSize: '16px', fontWeight: '500', marginBottom: '15px' }}>
							<WarningOutlined style={{ color: '#000', marginRight: '5px' }} />
							You can’t delete when there is a single file.
						</text>
						<text
							style={{
								fontSize: '14px',
								fontWeight: '400',
								textAlign: 'left',
								color: '#343434',
							}}
						>
							There should be atleast one file available, Please <br></br>upload new
							file to delete this file.
						</text>
					</Box>
				) : (
					<text style={{ fontSize: '16px', fontWeight: '500' }}>
						Are you sure you want to delete this file?
					</text>
				)
			}
			onConfirm={onConfirm}
			placement="bottomRight"
			cancelText={
				actualFileCount?.length === 1 && statusExist.invoiceReturnedVoucher
					? 'Cancel'
					: 'No'
			}
			okText={
				actualFileCount?.length === 1 && statusExist.invoiceReturnedVoucher
					? 'Upload New File'
					: 'Delete'
			}
			okButtonProps={{
				style: {
					backgroundColor: '#CA5010',
					width: '180px',
					height: '40px',
					border: 'none',
					fontSize: '14px',
					marginTop: '20px',
				},
			}}
			cancelButtonProps={{
				style: {
					backgroundColor: 'transparent',
					width: '180px',
					height: '40px',
					border: '1px solid #CA5010',
					color: '#CA5010',
					fontSize: '14px',
					marginTop: '20px',
				},
			}}
			overlayInnerStyle={{
				fontSize: '1rem',
				padding: '20px',
				textAlign: 'center',
			}}
			arrow={false}
			icon={
				actualFileCount?.length === 1 && statusExist.invoiceReturnedVoucher ? null : (
					<WarningOutlined style={{ color: '#000', left: '30px' }} />
				)
			}
		>
			{record?.is_active && (
				<a
					href="/"
					style={{
						color: record?.is_active ? '#ca5010' : 'transparent',
					}}
				>
					<DeleteOutlined />
				</a>
			)}
		</StyledPopconfirm>
	);
}

ConfirmBeforDelete.propTypes = {
	actualFileCount: PropTypes.number,
	handleUploadModalOpen: PropTypes.func,
	handleDelete: PropTypes.func || undefined,
	record: PropTypes.object,
	statusExist: PropTypes.object,
};
