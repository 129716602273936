/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Input, Table, Upload, message } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Button, Flex, Text } from 'atoms';
import { Rupee } from 'components';
import { AttachmentSvg, getBase64, maxFileSize } from 'utils';
import { CashSettlementInsert } from 'store/invoiceSlice';
import { ReasonModal } from './ReasonModal';

const UploadWrap = styled(Upload)`
	display: flex;
	width: 100%;
	cursor: pointer;
	background: #f6f5f5;
	min-height: 35px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 5px;
	margin-bottom: 8px;
	.ant-upload-list-item {
		min-height: 30px;
	}
	.ant-upload-list-item-name {
		word-break: break-all;
		white-space: normal;
		display: flex;
		line-height: 14px;
	}
`;

const InputWrapper = styled(Input)`
	background: #f6f5f5;
	border-radius: 4px;
	width: 100%;
	border: 0;
	min-height: 35px;
	padding-right: 60px;
`;

const InputLabel = styled(Box)`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 20px;
	color: #626262;
	font-size: 12px;
	line-height: 15px;
`;

const AmountInput = styled(Input)`
	width: 120px;
	margin: 0 15px;
	height: 26px;
	text-align: center;
`;

const AmountButton = styled(Box)`
	width: 24px;
	height: 24px;
	background: #fdfdfd;
	border: 0.5px solid #352a35;
	border-radius: 2px;
	text-align: center;
	cursor: pointer;
`;

function getTotal(tableData = []) {
	return tableData?.reduce((a, c) => a + (c?.total_amount || 0), 0) || 0;
}

const TableConfigs = ({ handleOnChange }) => [
	{
		title: (
			<Box textAlign="center" fontSize="12px" color="#646464">
				Amount
			</Box>
		),
		dataIndex: 'salesman_amount',
		key: 'salesman_amount',
		render: (salesman_amount, rec) => (
			<Box textAlign="center" color="#000">
				{!rec?.lastElement ? <Rupee>{salesman_amount || 0}</Rupee> : 'Coins'}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontSize="12px" color="#646464">
				Cashier Entered
			</Box>
		),
		dataIndex: 'id',
		key: 'id',
		render: (id, rec) => (
			<>
				<Flex justifyContent="center" alignItems="center" color="#000">
					<AmountButton
						style={{
							pointerEvents:
								(rec?.enteredCash || 0) <= 0 || rec.hasErrorMessage
									? 'none'
									: 'auto',
						}}
						onClick={() => handleOnChange((rec?.enteredCash || 0) - 1, 'DEC', id)}
					>
						-
					</AmountButton>
					<AmountInput
						onChange={({ target }) => handleOnChange(target?.value, 'ONCHANGE', id)}
						value={rec?.enteredCash}
					/>
					<AmountButton
						onClick={() => handleOnChange((rec?.enteredCash || 0) + 1, 'INC', id)}
						style={{
							background: '#352A35',
							pointerEvents: rec.hasErrorMessage ? 'none' : 'auto',
						}}
						color="#fff"
					>
						+
					</AmountButton>
				</Flex>
				{rec.hasErrorMessage && (
					<Box textAlign="center" fontSize="12px" color="red">
						{rec.hasErrorMessage}
					</Box>
				)}
			</>
		),
	},
	{
		title: (
			<Box textAlign="center" fontSize="12px" color="#646464">
				Amount
			</Box>
		),
		dataIndex: 'total_amount',
		key: 'total_amount',
		render: (total_amount) => (
			<Box textAlign="center" color="#000">
				{total_amount || 0}
			</Box>
		),
	},
];

const TableData = [
	{
		id: 1,
		salesman_amount: 2000,
		enteredCash: 0,
		hasErrorMessage: '',
		total_amount: 0,
	},
	{
		id: 2,
		salesman_amount: 500,
		enteredCash: 0,
		hasErrorMessage: '',
		total_amount: 0,
	},
	{
		id: 3,
		salesman_amount: 200,
		enteredCash: 0,
		hasErrorMessage: '',
		total_amount: 0,
	},
	{
		id: 4,
		salesman_amount: 100,
		enteredCash: 0,
		hasErrorMessage: '',
		total_amount: 0,
	},
	{
		id: 5,
		salesman_amount: 50,
		enteredCash: 0,
		hasErrorMessage: '',
		total_amount: 0,
	},
	{
		id: 6,
		salesman_amount: 20,
		enteredCash: 0,
		hasErrorMessage: '',
		total_amount: 0,
	},
	{
		id: 7,
		salesman_amount: 10,
		enteredCash: 0,
		hasErrorMessage: '',
		total_amount: 0,
	},
	{
		id: 8,
		salesman_amount: 5,
		enteredCash: 0,
		hasErrorMessage: '',
		total_amount: 0,
	},
	{
		id: 9,
		lastElement: true,
		salesman_amount: 1,
		enteredCash: 0,
		hasErrorMessage: '',
		total_amount: 0,
	},
];

export const getHandleupload = (info) => {
	const nextState = {};

	switch (info?.file?.status) {
		case 'uploading':
			nextState.selectedFileList = [info.file];
			break;
		case 'done':
			nextState.selectedFileList = [info.file];
			break;
		default:
			// error or removed
			nextState.selectedFileList = [];
	}

	return nextState?.selectedFileList;
};

function Increment(x, val) {
	return {
		...x,
		enteredCash: +val || 0,
		total_amount: x.salesman_amount * +val,
		hasErrorMessage:
			Math.sign(+val) === -1 || !Number.isInteger(+val) ? 'Enter Valid Number' : '',
	};
}

function Decrement(x, val) {
	return {
		...x,
		enteredCash: +val || 0,
		hasErrorMessage:
			Math.sign(+val) === -1 || !Number.isInteger(+val) ? 'Enter Valid Number' : '',
		total_amount: x.salesman_amount * +val,
	};
}

export function SettleCashModal({ toggleModal = [], amountDetials, asyncCall }) {
	const dispatch = useDispatch();

	const [innerModal, setInnerModal] = useState(false);
	const [showModal, setShowModal] = toggleModal;
	const [tableData, setTableData] = useState(TableData);
	const [disablebtn, setDisablebtn] = useState(false);
	const [selectedFileList, setSelectedFileList] = useState([]);
	const [qrCode, setQRCode] = useState('');

	const handleOk = () => {
		setShowModal(false);
	};

	const handleCancel = () => {
		setShowModal(false);
	};

	const handleOnChange = (val, counter, id) => {
		setTableData((prev) =>
			prev?.map((x) => {
				if (x.id === id) {
					if (counter === 'INC') {
						return Increment(x, val);
					}
					if (counter === 'DEC') {
						return Decrement(x, val);
					}
					if (counter === 'ONCHANGE') {
						return val < x.enteredCash ? Decrement(x, val) : Increment(x, val);
					}
				}

				return x;
			})
		);
	};

	const dummyRequest = ({ onSuccess }) => {
		setTimeout(() => {
			onSuccess('ok');
		}, 0);
	};
	const handleupload = (info) => {
		setSelectedFileList(getHandleupload(info));
	};

	const handleSettle = async (settleIt, reasons = '') => {
		const totalAmount = getTotal(tableData);

		if (totalAmount === (Number(amountDetials?.total_unsettled) || 0) || settleIt) {
			try {
				setDisablebtn(true);
				console.log('yes', totalAmount > amountDetials?.total_unsettled);
				if (totalAmount > Number(amountDetials?.total_unsettled)) {
					message.error('Denomination amount should not be more than total cash amount');
					return;
				}
				console.log('yes', totalAmount > amountDetials?.total_unsettled, selectedFileList);
				await getBase64(selectedFileList?.[0]?.originFileObj).then((res) => {
					const payload = {
						two_thousand_count: tableData[0]?.enteredCash || 0,
						five_hundred_count: tableData[1]?.enteredCash || 0,
						two_hundred_count: tableData[2]?.enteredCash || 0,
						hundred_count: tableData[3]?.enteredCash || 0,
						fifty_count: tableData[4]?.enteredCash || 0,
						twenty_count: tableData[5]?.enteredCash || 0,
						ten_count: tableData[6]?.enteredCash || 0,
						five_count: tableData[7]?.enteredCash || 0,
						coins_count: tableData[8]?.enteredCash || 0,
						challan_copy_attachment: res,
						reason: reasons,
						qr_code_number: qrCode,
					};

					console.log(payload);
					dispatch(CashSettlementInsert(payload))
						.then(() => {
							handleOk();
							asyncCall();
							message.info('Cash settled Successfully ');
						})
						.finally(() => setDisablebtn(false));
				});
			} catch (e) {
				console.log(e);
			}
		} else {
			console.log('noo');
			setInnerModal(true);
		}
	};

	return (
		<>
			<Modal
				centered
				width="40%"
				visible={showModal}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={false}
				maskClosable={false}
				className="settleCash"
			>
				<Box>
					<Box padding="16px 24px" background="#352A35">
						<Box fontWeight="300" fontSize="12px" lineHeight="24px" color="#FDFCFC">
							Cash to be settled
						</Box>
						<Box fontSize="14px" lineHeight="18px" color="#FDFCFC">
							<Rupee>{amountDetials?.total_unsettled}</Rupee>
						</Box>
					</Box>
					<Box padding="5px 16px 20px">
						<Table
							rowKey="id"
							bordered
							columns={TableConfigs({ handleOnChange })}
							dataSource={tableData}
							pagination={false}
							footer={() => `Total : ${getTotal(tableData)}`}
						/>
					</Box>
					<Flex padding="5px 16px 20px" justifyContent="space-between">
						<Box flex="0 0 45%">
							<Text
								color="#000000"
								fontSize="12px"
								lineHeight="15px"
								margin="0 0 8px"
							>
								Challan number
							</Text>
							<Box>
								<InputWrapper
									type="number"
									value={qrCode}
									onChange={(e) => {
										if (e.target.value?.toString()?.length >= 6) {
											return setQRCode(
												e.target.value.toString().substring(0, 6),
												10
											);
										}
										return setQRCode(e?.target?.value);
									}}
								/>
								<InputLabel>6 digit</InputLabel>
							</Box>
						</Box>
						<Box flex="0 0 45%" className="uploadChallen">
							<Text
								color="#000000"
								fontSize="12px"
								lineHeight="15px"
								margin="0 0 8px"
							>
								Upload Challan copy
							</Text>
							<UploadWrap
								fileList={selectedFileList}
								customRequest={dummyRequest}
								onChange={handleupload}
								beforeUpload={maxFileSize}
								onRemove={false}
								accept="application/pdf,image/jpeg,image/png"
							>
								<Flex
									width="100%"
									justifyContent="space-between"
									padding="0 10px"
									color="#626262"
									fontSize="12px"
									lineHeight="15px"
								>
									<Box>Upload files</Box>
									<Box>
										<AttachmentSvg />
									</Box>
								</Flex>
							</UploadWrap>
							<Box
								textAlign="center"
								fontSize="12px"
								lineHeight="15px"
								color="#4B4B4B"
							>
								Supported format: PDF, JPEG, PNG, JPG
							</Box>
						</Box>
					</Flex>
					<Box padding="5px 16px 20px" textAlign="right">
						<Button
							backgroundColor="#CA5010"
							borderRadius="4px"
							fontSize="12px"
							lineHeight="15px"
							color="#FFFFFF"
							disabled={
								qrCode?.length !== 6 ||
								selectedFileList.length === 0 ||
								getTotal(tableData) < 1 ||
								getTotal(tableData) > Number(amountDetials?.total_unsettled)
							}
							onClick={() => handleSettle(false)}
						>
							Settle
						</Button>
					</Box>
				</Box>
			</Modal>
			{innerModal && (
				<ReasonModal
					toggleModal={[innerModal, setInnerModal]}
					handleSettle={handleSettle}
					toggleDisable={[disablebtn]}
				/>
			)}
		</>
	);
}

SettleCashModal.propTypes = {
	toggleModal: PropTypes.array,
	amountDetials: PropTypes.object,
	asyncCall: PropTypes.func,
};
