/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Select, DatePicker, Input, message as toaster, Modal, Table } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
// import * as SVGICONS from 'utils/Svg';
import {
	getSaleStoreList,
	getSoTaggingList,
	postMarkOrders,
} from 'store/fulfilment/salesOrderSlice';
import { Box, Flex, Button, AsyncSelect, Text } from 'atoms';
import { RCTable, SearchBar, LabelWrapper, LoadAsyncSelectComponent } from 'components/common';
import { markEcoBillsTableConfig, handleFilterChange, handleSearch } from 'utils/tableConfig';
import {
	toAsysnOption,
	ORDER_SEARCH_FIELDS,
	disableFutureDate,
	disableDateRangesNew,
	ORDER_STATUSES,
	ORDER_STATUSES_ENUM,
	SalesOrderQueries,
	SelectFilterLabelOptions,
	searchBox,
	initialPaginateConfigWithoutQ,
} from 'utils';
import { getFcListOnLoad, getFcOnSearchList } from 'store/masters';
// import { getExportSalesOrderReport } from 'store/downloads/reportSlice';
import { SearchField } from 'components/common/custom-table/Style';
import { useQueryParams } from 'hooks';
import { getStoreDataOnLoad } from 'store/OnBoardSlice';
import * as SVGICONS from 'utils/Svg';

// const CheckboxGroup = Checkbox.Group;

export const StatusInfoText = styled(Text)`
	border-radius: 4px;
	background: #e0f3ff;
	color: #1b4965;
	padding: 10px 5px;
	width: 50rem;
	height: 40px;
	margin-left: 10px;
	margin-top: 10px;
	display: flex;
	align-items: center;
`;

const ConfirmationMessage = styled(Text)`
	color: #000;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const ErrorText = styled(Text)`
	color: #ca5010;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;
export const FilterByOptions = [
	{ value: 'fields', label: 'Search By' },
	{ value: 'statuses', label: 'Status(s)' },
	// { value: 'invoice_date__gte', label: 'From Date' },
	// { value: 'invoice_date__lte', label: 'To Date' },
	{ value: 'gst_number', label: 'GST' },
];

export default function MarkPodBills() {
	// const navigate = useNavigate();
	const dispatch = useDispatch();
	const [storeList, setStoreList] = useState({ type: 'NotAsked' });
	const [rowData, setRowData] = useState({});
	// const [totalrowData, settotalRowData] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalErrorVisible, setModalErrorVisible] = useState(false);
	const [errorMsg, setErrorMsg] = useState(false);
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [rowSelect, setrowSelect] = useState([]);
	// const [wmsSuccessMessage, setWmsSuccessMessage] = useState(null);

	const [paginateConfig, setpaginateConfig] = useQueryParams({ filterQuery: SalesOrderQueries });
	const [filtersEnabled, setSelectFilter] = useState([]);
	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);

	const navigate = useNavigate();

	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
		handleMarkPodBills();
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const showModalError = () => {
		setModalErrorVisible(true);
	};
	const handleCancelError = () => {
		setModalErrorVisible(false);
		window.location.reload();
	};

	// const handleClick = (id) => navigate(`/order-management/sales-order/${id}`);

	useEffect(() => {
		setStoreList({ type: 'Loading' });
		setFcList({ type: 'Loading' });

		dispatch(
			getFcListOnLoad({ fcIds: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [] })
		).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});

		dispatch(
			getStoreDataOnLoad({
				fc_ids: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [],
				storeIds: paginateConfig?.stores?.length ? paginateConfig?.stores : [],
			})
		).then((res) => setStoreList({ type: 'Success', data: toAsysnOption(res) }));
	}, [dispatch]);

	useEffect(() => {
		// Load the options onLoad
		setSelectFilter((prev) => [
			...prev,
			...FilterByOptions.filter((x) => !isEmpty(paginateConfig[x.value])).map((x) => x.value),
		]);
	}, [paginateConfig]);

	useEffect(() => {
		if (rowSelect?.length > 99) {
			toaster.error('Max 100 invoices selection completed');
		}
	}, [rowSelect]);

	// const handleExport = () => {
	// 	const msgKey = Math.random().toString();
	// 	const config = {
	// 		key: msgKey,
	// 		type: 'loading',
	// 		content: 'Downloading Reports Sales Order',
	// 		duration: 0,
	// 		className: 'customDownloadMsg',
	// 	};
	// 	message.open(config);

	// 	const { offset, limit, sort, currentPage, ...rest } = paginateConfig;
	// 	dispatch(
	// 		getExportSalesOrderReport({
	// 			filter: { ...rest },
	// 		})
	// 	)
	// 		.then(() => message.destroy(msgKey))
	// 		.catch(() => message.destroy(msgKey));
	// };

	const rowSelection = {
		onChange: (selectedRowKeys) => {
			setrowSelect([...selectedRowKeys]);
		},
	};

	const handleChangeSelect = (name, value) => {
		// as this is asyncselect we need to destructure value from the option value
		const getValue = isEmpty(value) ? [] : value.map((y) => y.value);
		setpaginateConfig((prev) => handleFilterChange(name, getValue, prev));
	};

	const handleFCChangeSelect = (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value
		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		const fcValues = value?.value ? [value?.value] : [];
		dispatch(getSaleStoreList({ q: '**', fc_ids: fcValues })).then((res) => {
			setStoreList({ type: 'Success', data: toAsysnOption(res) });
			setpaginateConfig((prev) => ({
				...handleFilterChange(name, fcValues, prev),
				brands: [],
				stores: [],
			}));
		});
	};

	const handleMarkPodBills = () => {
		dispatch(postMarkOrders('POD', rowSelect)).then((res) => {
			if (isEmpty(res?.message)) {
				toaster.info('POD Marked Sucessfully');
				navigate(`/order-management/sales-order/`);
			} else {
				console.log('RES', res?.message);
				setErrorMsg(res?.message);
				showModalError();
			}
		});
		// .catch((er) => {
		// 	// toaster.error(`${er}`);
		// 	showModalError();
		// 	console.log('thiserrr', er?.response?.data?.message);
		// 	console.log('thiserrr', er);
		// 	setErrorMsg(er?.response?.data?.message);
		// });
	};

	// const handleFilterByOptions = (val) => {
	// 	const checkFilterRemoved = filtersEnabled.filter((x) => !val.includes(x));
	// 	// check any value is removed and removed value has any value or not
	// 	if (checkFilterRemoved.length > 0 && !isEmpty(paginateConfig[checkFilterRemoved[0]])) {
	// 		const { [checkFilterRemoved[0]]: RemoveEmpty, ...rest } = paginateConfig;
	// 		setpaginateConfig({
	// 			...rest,
	// 			...(checkFilterRemoved[0] === 'fields' ? { query: '' } : {}), // if fields is removed then query has to be empty
	// 		});
	// 	}
	// 	setSelectFilter(val);
	// };

	const columns = [
		{
			title: 'Sr No.',
			dataIndex: 'sr_no',
			key: 'sr_no',
			render: (_, rec, index) => <Box>{index + 1}</Box>,
		},
		{
			title: 'Invoice No.',
			// eslint-disable-next-line sonarjs/no-duplicate-string
			dataIndex: 'Invoice No',
			key: 'Invoice No',
		},
		{
			title: 'Status',
			dataIndex: 'Status',
			key: 'Status',
			render: (status) => {
				const statusObject = ORDER_STATUSES_ENUM.find((item) =>
					// eslint-disable-next-line no-prototype-builtins
					item.hasOwnProperty(status)
				);
				const displayStatus = statusObject ? statusObject[status] : 'Unknown Status';
				return <Box>{displayStatus}</Box>;
			},
		},
	];

	return (
		<>
			<Flex alignItems="center">
				<Flex width="100%" flexWrap="wrap" alignItems="center" gap="4px">
					{filtersEnabled.includes('fields') && (
						<LabelWrapper label="Search By" marginspace="3px 0 -1px 1rem">
							<Select
								name="fields"
								style={searchBox}
								allowClear
								showSearch
								label="Search By"
								mode="multiple"
								value={paginateConfig?.fields}
								options={ORDER_SEARCH_FIELDS}
								filterOption={SelectFilterLabelOptions}
								placeholder="Search By"
								onChange={(value) =>
									setpaginateConfig((prev) => ({
										...handleFilterChange('fields', value, prev),
										...(isEmpty(value) ? { query: '' } : {}),
									}))
								}
							/>
						</LabelWrapper>
					)}

					{filtersEnabled.includes('fields') && !isEmpty(paginateConfig?.fields) && (
						<LabelWrapper label="Search" marginspace="-1px 0 5px 0">
							<SearchField>
								<SearchBar
									placeholder="Search"
									queryValue={paginateConfig?.query?.replaceAll('*', '')}
									onSearch={(val) =>
										setpaginateConfig((prev) => handleSearch(val, prev))
									}
								/>
							</SearchField>
						</LabelWrapper>
					)}
					<Box marginLeft="1rem">
						<LabelWrapper label="Search by Invoice Number" marginspace="-1px 0 5px 0">
							<SearchField>
								<SearchBar
									placeholder="Search by Invoice Number"
									queryValue={paginateConfig?.queryInvoice?.replaceAll('*', '')}
									onSearch={(val) =>
										setpaginateConfig((prev) => ({
											...prev,
											limit: 10,
											offset: 0,
											currentPage: 1,
											queryInvoice: val,
										}))
									}
								/>
							</SearchField>
						</LabelWrapper>
					</Box>

					<LabelWrapper label="Store(s)" marginspace="3px 0 -1px 1rem">
						<LoadAsyncSelectComponent options={storeList}>
							<AsyncSelect
								style={searchBox}
								allowClear
								name="stores"
								mode="multiple"
								fetchOptions={getSaleStoreList}
								apiParams={{ fc_ids: paginateConfig?.fcs || [] }}
								placeholder="Store(s)"
								defaultValue={paginateConfig?.stores}
								value={paginateConfig?.stores}
								onChange={(value) => handleChangeSelect('stores', value)}
								selectOptions={[...(storeList.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>

					<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
						<LoadAsyncSelectComponent
							loadFCs={paginateConfig?.fcs}
							setList={(val) => setBrandList(val)}
							options={fcsList}
						>
							<AsyncSelect
								style={searchBox}
								allowClear
								name="fcs"
								showSearch
								fetchOptions={getFcOnSearchList}
								transformOptions={(val) => toAsysnOption(val)}
								defaultValue={paginateConfig?.fcs}
								placeholder="Select FC(s)"
								onChange={(value, options) => {
									handleFCChangeSelect('fcs', value, options);
								}}
								selectOptions={[...(fcsList.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>

					<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
						<Select
							name="fields"
							style={searchBox}
							allowClear
							showSearch
							label="brands"
							mode="multiple"
							value={paginateConfig?.brands}
							filterOption={SelectFilterLabelOptions}
							options={brandsList}
							onChange={(value) =>
								setpaginateConfig((prev) => ({
									...prev,
									brands: value,
									...initialPaginateConfigWithoutQ,
								}))
							}
						/>
					</LabelWrapper>

					{filtersEnabled.includes('statuses') && (
						<LabelWrapper label="Status(s)" marginspace="3px 0 -1px 1rem">
							<Select
								name="statuses"
								style={searchBox}
								allowClear
								showSearch
								options={ORDER_STATUSES}
								value={paginateConfig?.statuses}
								filterOption={SelectFilterLabelOptions}
								mode="multiple"
								placeholder="Status(s)"
								onChange={(value) =>
									setpaginateConfig((prev) =>
										handleFilterChange('statuses', value, prev)
									)
								}
							/>
						</LabelWrapper>
					)}

					{/* {filtersEnabled.includes('invoice_date__gte') && ()} */}
					<LabelWrapper label="From Date" marginspace="3px 0 -1px 1rem">
						<DatePicker
							name="invoice_date__gte"
							style={searchBox}
							label="From Date"
							value={
								paginateConfig?.invoice_date__gte
									? moment(paginateConfig?.invoice_date__gte)
									: ''
							}
							disabledDate={disableFutureDate}
							onChange={(value) =>
								setpaginateConfig((prev) =>
									handleFilterChange('invoice_date__gte', value, prev)
								)
							}
							placeholder="From Date"
						/>
					</LabelWrapper>

					{/* {filtersEnabled.includes('invoice_date__lte') && ()} */}
					<LabelWrapper label="To Date" marginspace="3px 0 -1px 1rem">
						<DatePicker
							name="invoice_date__lte"
							style={searchBox}
							value={
								paginateConfig?.invoice_date__lte
									? moment(paginateConfig?.invoice_date__lte)
									: ''
							}
							label="To Date"
							onChange={(value) =>
								setpaginateConfig((prev) =>
									handleFilterChange('invoice_date__lte', value, prev)
								)
							}
							disabled={!paginateConfig?.invoice_date__gte}
							disabledDate={disableDateRangesNew(paginateConfig?.invoice_date__gte)}
							placeholder="To Date"
						/>
					</LabelWrapper>

					{filtersEnabled.includes('gst_number') && (
						<LabelWrapper label="Search GST" marginspace="0 0 5px 1rem">
							<SearchField width="200px" margin="0 1rem 1rem">
								<Input
									name="gst_number"
									placeholder="Search GST"
									value={paginateConfig?.gst_number}
									onChange={(e) =>
										setpaginateConfig((prev) =>
											handleFilterChange('gst_number', e?.target?.value, prev)
										)
									}
								/>
							</SearchField>
						</LabelWrapper>
					)}
				</Flex>
				<Button
					style={{ marginBottom: 16 }}
					onClick={() => setBtnClickSearh(Math.random())}
				>
					Search
				</Button>
				{/* <Button type="variant" onClick={handleExport}>
					<Flex alignItems="center" justifyContent="center">
						<Box width={32}>
							<SVGICONS.DownloadsStrokeIcons />
						</Box>
						<Box>Export</Box>
					</Flex>
				</Button> */}

				<Button
					onClick={showModal}
					style={{ marginBottom: 16, background: '#FE5722', width: '16rem' }}
					disabled={isEmpty(rowSelect)}
				>
					Mark Pay On Delivery
				</Button>

				{/* <Popover
					content={
						<CheckboxGroup
							options={FilterByOptions}
							value={filtersEnabled}
							onChange={handleFilterByOptions}
							className="vertical-checkbox"
						/>
					}
					title="Add Filters"
					className="hide__show--columns"
				>
					<Button style={{ marginBottom: 16 }}>
						<Flex alignItems="center" justifyContent="center">
							<Box width={32}>
								<SVGICONS.FilterStrokeIcons />
							</Box>
							<Box>Add Filter</Box>
						</Flex>
					</Button>
				</Popover> */}
			</Flex>
			{/* <Box mt={3}>
				<RCTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'id',
					}}
					dispatchAction={getSalesOrderList}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					columns={salesOrderListColumns({
						handleClick,
						defaultSort: paginateConfig?.sort_direction || 'DESC',
						defaultsortField: paginateConfig?.sort_column || 'id',
						handleStockOneCallBack,
					})}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box> */}
			<Box>
				<StatusInfoText>
					{`Invoices labeled '`}
					<SVGICONS.QuidSalesOrderIcons
						style={{ width: '20px', margin: '3px', display: 'inline-flex' }}
					/>
					{`', 'Out For Delivery' or 'Vehicle Allocated' will not be available to mark as Pay On Delivery.`}
				</StatusInfoText>
			</Box>
			<Box mt={3}>
				<RCTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					rowSelection={{
						type: 'checkbox',
						preserveSelectedRowKeys: true,
						// hideSelectAll: rowSelect.length > 99,
						hideSelectAll: 100 - rowSelect.length <= (paginateConfig?.pageSize || 10),
						...rowSelection,
					}}
					dispatchAction={getSoTaggingList}
					// dispatchAction={viewDAList}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					columns={markEcoBillsTableConfig({
						defaultSort: paginateConfig?.sort_direction || 'DESC',
						defaultsortField: paginateConfig?.sort_column || 'createdAt',
					})}
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'createdAt',
					}}
					rowClassName={(record) => {
						if (rowSelect?.length > 99) {
							return rowSelect?.includes(record.id) ? '' : 'disabled-div';
						}
						return '';
					}}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
			<Modal onCancel={handleCancel} visible={isModalOpen} footer={null}>
				<ConfirmationMessage style={{ margin: '20px' }}>
					Do you want to mark selected invoices as Pay On Delivery?
				</ConfirmationMessage>
				<Button
					style={{
						borderRadius: 4,
						background: '#E1F4FF',
						color: '#1B4965',
						marginRight: 10,
						marginLeft: 16,
					}}
					onClick={handleCancel}
				>
					No
				</Button>
				<Button
					style={{
						borderRadius: 4,
						background: '#1B4965',
						color: '#FFF',
					}}
					onClick={handleOk}
				>
					Yes
				</Button>
			</Modal>
			<Modal
				visible={modalErrorVisible}
				footer={null}
				// closable={false}
				onCancel={handleCancelError}
			>
				<ErrorText>Error</ErrorText>

				<ConfirmationMessage style={{ margin: '20px' }}>
					<Box style={{ fontSize: 18 }}>Failed to Update </Box>
					<Box style={{ marginTop: '16px', marginBottom: '16px' }}>
						{`Looks like some of the order's status is changed:`}
					</Box>
					{/* {errorMsg?.length > 0 &&
						errorMsg?.map((msg) => (
							<Box style={{ marginTop: '16px' }}>
								Invoice no: <span>{msg?.['Invoice No']} </span>
								<span> Status: {msg?.Status}</span>
							</Box>
						))} */}

					<Table pagination={false} dataSource={errorMsg} columns={columns} />
				</ConfirmationMessage>
			</Modal>
		</>
	);
}
