/* eslint-disable sonarjs/no-duplicate-string */
import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api';
// import { GoogleMap, useLoadScript, MarkerF, Circle } from '@react-google-maps/api';
import Location from '../../assets/Location.png';
// import AnimatedLocation from '../../assets/Location-Animation.gif';
import NewLocation from '../../assets/NewLocation.png';
const googleKey = process.env.React_APP_GOOGLE_MAPS_KEY;

function MapComponent({ selectedLocation = null, newSelectedLocation = null, kycDetails = null }) {
	// const centerData = newSelectedLocation
	// 	? [newSelectedLocation, selectedLocation]
	// 	: selectedLocation;

	console.log('kycDetails ', kycDetails);

	const centerData =
		newSelectedLocation && newSelectedLocation?.lat ? newSelectedLocation : selectedLocation;
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: googleKey,
	});
	const mapRef = React.useRef();
	const onMapLoad = React.useCallback((map) => {
		mapRef.current = map;
	}, []);
	if (loadError) return 'Error';
	if (!isLoaded) return 'Maps';
	const exampleMapStyles = [
		// {
		// 	featureType: 'landscape.natural',
		// 	elementType: 'labels.icon',
		// 	stylers: [
		// 		{
		// 			visibility: 'off',
		// 		},
		// 	],
		// },
		{
			featureType: 'poi.attraction',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'poi.business',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'poi.government',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		// {
		// 	featureType: 'poi.medical',
		// 	elementType: 'labels.icon',
		// 	stylers: [
		// 		{
		// 			visibility: 'off',
		// 		},
		// 	],
		// },
		{
			featureType: 'poi.park',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'poi.place_of_worship',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'poi.school',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'poi.sports_complex',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		// {
		// 	featureType: 'road.arterial',
		// 	elementType: 'labels.icon',
		// 	stylers: [
		// 		{
		// 			visibility: 'off',
		// 		},
		// 	],
		// },
		{
			featureType: 'road.highway',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		// {
		// 	featureType: 'road.highway.controlled_access',
		// 	elementType: 'labels.icon',
		// 	stylers: [
		// 		{
		// 			visibility: 'off',
		// 		},
		// 	],
		// },
		// {
		// 	featureType: 'transit.station',
		// 	elementType: 'labels.icon',
		// 	stylers: [
		// 		{
		// 			visibility: 'off',
		// 		},
		// 	],
		// },
		{
			featureType: 'transit.station.airport',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		// {
		// 	featureType: 'transit.station.bus',
		// 	elementType: 'labels.icon',
		// 	stylers: [
		// 		{
		// 			visibility: 'off',
		// 		},
		// 	],
		// },
		{
			featureType: 'transit.station.rail',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
	];

	// const places = {
	// 	id: 1,
	// 	name: 'Park Slope',
	// 	latitude: '40.6710729',
	// 	longitude: '-73.9988001',
	// 	circle: {
	// 		radius: 3000,
	// 		options: {
	// 			strokeColor: '#ff0000',
	// 		},
	// 	},
	// };

	return (
		<GoogleMap
			mapContainerStyle={{
				height: '100%',
			}}
			options={{
				streetViewControl: false,
				// zoomControl: false,
				styles: exampleMapStyles,
			}}
			center={centerData}
			zoom={15}
			onLoad={onMapLoad}
		>
			{console.log('selectedLocation ', selectedLocation)}
			{console.log('newSelectedLocation ', newSelectedLocation)}
			{selectedLocation && selectedLocation?.lat && (
				<MarkerF position={selectedLocation} icon={Location} />
			)}
			{newSelectedLocation &&
				kycDetails?.approval_status !== 'approved' &&
				newSelectedLocation?.lat && (
					<>
						<MarkerF position={newSelectedLocation} icon={NewLocation} />
						{/* <Circle
						defaultCenter={{
							lat: parseFloat(places.latitude),
							lng: parseFloat(places.longitude),
						}}
						radius={places.circle.radius}
						options={places.circle.options}
					/> */}
					</>
				)}
		</GoogleMap>
	);
}

export default MapComponent;

MapComponent.propTypes = {
	selectedLocation: PropTypes.any,
	newSelectedLocation: PropTypes.any,
	kycDetails: PropTypes.any,
};
