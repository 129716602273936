import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { Flex } from 'atoms';
import { OutOfLocationIcons } from 'utils';

const RowWrapper = styled(Row)`
	width: 100%;
`;

const newTitle = {
	color: '#CA5010',
	fontWeight: '600',
};

const newBg = {
	background: '#01e64d',
};

const CircleBg = styled.div`
	height: 12px;
	background: #fd7567;
	border-radius: 50%;
	flex: 0 0 12px;
`;

const PointerWrapper = styled.div`
	width: 24px;
	height: 16px;
`;

const locationWrapper = {
	gap: '6px',
	alignItems: 'center',
};

const Approved = styled.span`
	background: #efffda;
	color: #5b7f2b;
	padding: 8px 16px;
`;

const Rejected = styled.span`
	background: #ffefef;
	color: #ff4747;
	padding: 8px 16px;
`;

function MapHeaderPannel(HeaderData) {
	const data = HeaderData;
	return (
		<RowWrapper>
			{console.log('data?.HeaderData?.KycDetails ', data?.HeaderData?.KycDetails)}
			<Col flex="auto">
				<Flex style={newTitle}>
					<PointerWrapper>
						<OutOfLocationIcons width={16} />
					</PointerWrapper>
					Store Location.
				</Flex>
			</Col>
			{data?.HeaderData?.KycDetails?.outside_fence &&
				data?.HeaderData?.KycDetails?.approval_status !== 'approved' && (
					<>
						<Col xs={5}>
							<Flex style={locationWrapper}>
								<CircleBg style={newBg} />
								{data?.HeaderData?.KycDetails?.approval_status === 'rejected'
									? 'New Location'
									: 'Delivered Location'}
							</Flex>
						</Col>
						<Col xs={5}>
							<Flex style={locationWrapper}>
								<CircleBg />
								{data?.HeaderData?.KycDetails?.approval_status === 'rejected'
									? 'Old Location'
									: 'Store Location'}
							</Flex>
						</Col>
					</>
				)}
			{data?.HeaderData?.KycDetails?.approval_status && (
				<Col style={{ width: 160 }}>
					{data?.HeaderData?.KycDetails?.approval_status === 'approved' && (
						<Approved>Approved</Approved>
					)}
					{data?.HeaderData?.KycDetails?.approval_status === 'rejected' && (
						<Rejected>Rejected</Rejected>
					)}
				</Col>
			)}
		</RowWrapper>
	);
}

export default MapHeaderPannel;
