import { Link } from 'react-router-dom';
import styled from 'styled-components';
import viewIcon from 'assets/icons/eye.svg';
import errorIcon from 'assets/icons/error-alert.svg';
import { Text, Image, Flex } from 'atoms';
import DateFormat from 'components/DateFormat';
import * as SVGICONS from 'utils/Svg';

const DateContainer = styled(Text)`
	color: #737373;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const StatusContainer = styled(Text)`
	border-radius: 2px;
	text-align: center;
	padding: 8px 0;
	font-size: 12px;
`;

const add_info_function = (info) =>
	info.errors?.error_status === true ? (
		<Image
			src={errorIcon}
			alt="edit-icon"
			height={15}
			width={20}
			style={{ marginBottom: '5px' }}
		/>
	) : (
		''
	);

export const returnsLogsTableConfig = ({
	defaultSort,
	defaultsortField,
	showModal,
	getErrorData,
}) => {
	const sortDirection = defaultSort === 'ASC' ? 'ascend' : 'descend';
	return [
		{
			title: '',
			dataIndex: 'bnpl_status',
			key: 'bnpl_status',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.bnpl_enabled ? <SVGICONS.QuidSalesOrderIcons /> : ''}
				</Text>
			),
		},
		{
			title: 'Return No.',
			dataIndex: 'return_no',
			key: 'return_no',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => a.return_no - b.return_no,
			sortOrder: defaultsortField === 'return_no' ? sortDirection : false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{record?.return_no}
				</Text>
			),
		},
		{
			title: 'Brand',
			dataIndex: 'brand',
			key: 'brand',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.brand}
				</Text>
			),
		},
		{
			title: 'FC',
			dataIndex: 'fc',
			key: 'fc',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.fc}
				</Text>
			),
		},
		{
			title: 'Type',
			dataIndex: 'return_type',
			key: 'return_type',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			// eslint-disable-next-line consistent-return
			render: (_, record) => {
				if (record?.return_type === 'DR') {
					return (
						<Text overFlow="hidden" fontSize="12px" color="#CA5010">
							Delivery Return
						</Text>
					);
				}

				return (
					<Text overFlow="hidden" fontSize="12px" color="#0094FF">
						Sales Return
					</Text>
				);
			},
		},

		{
			title: 'Invoice No',
			dataIndex: 'invoice_no',
			key: 'invoice_no',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{record?.invoice_no}
				</Text>
			),
		},

		{
			title: 'WMS Return Number',
			dataIndex: 'wms_return_number',
			key: 'wms_return_number',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{record?.wms_return_number}
				</Text>
			),
		},

		{
			title: 'WMS Credit Note Number',
			dataIndex: 'wms_credit_note_number',
			key: 'wms_credit_note_number',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{record?.wms_credit_note_number}
				</Text>
			),
		},

		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			sortId: 2,
			overflow: 'hidden',
			width: '10%',
			hidden: false,
			render: (status) => {
				switch (status) {
					case 'wms_failed':
						return (
							<StatusContainer
								overFlow="hidden"
								style={{ background: '#FFECEC', color: '#FF4747' }}
							>
								WMS Failed
							</StatusContainer>
						);
					case 'completed':
						return (
							<StatusContainer
								overFlow="hidden"
								style={{ background: '#DEFFDC', color: '#0A9600' }}
							>
								Completed
							</StatusContainer>
						);
					// case 'missing_qty_returns':
					// 	return (
					// 		<StatusContainer
					// 			overFlow="hidden"
					// 			style={{ background: '#FFF4E2', color: '#FCA311' }}
					// 		>
					// 			Returns Qty Missing
					// 		</StatusContainer>
					// 	);
					case 'partial_qty_missing':
						return (
							<StatusContainer
								overFlow="hidden"
								style={{ background: '#FFD8EC', color: '#A3603C' }}
							>
								Partial Qty Missing
							</StatusContainer>
						);
					case 'all_qty_missing':
						return (
							<StatusContainer
								overFlow="hidden"
								style={{ background: '#FFF4C5', color: '#B23A48' }}
							>
								All Qty Missing
							</StatusContainer>
						);

					case 'cancelled':
						return (
							<StatusContainer
								overFlow="hidden"
								style={{ background: '#FFECEC', color: '#FF4747' }}
							>
								Cancelled
							</StatusContainer>
						);

					case 'received':
						return (
							<StatusContainer
								overFlow="hidden"
								style={{ background: '#C9EFEC', color: '#710193' }}
							>
								Received
							</StatusContainer>
						);

					default:
						return (
							<StatusContainer
								overFlow="hidden"
								style={{ background: '#C9EFEC', color: '#710193' }}
							>
								Created
							</StatusContainer>
						);
				}
			},
		},

		{
			title: 'Created By',
			dataIndex: 'return_date',
			key: 'return_date',
			sortId: 1,
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => a.return_date - b.return_date,
			sortOrder: defaultsortField === 'return_date' ? sortDirection : false,
			render: (_, record) => (
				<>
					<Text overFlow="hidden" fontSize="12px" color="#000">
						{record?.created_by}
					</Text>
					<DateContainer overFlow="hidden" fontSize="12px" color="#000">
						<DateFormat date={record?.created_at} />
					</DateContainer>
				</>
			),
		},
		{
			title: 'Last Modified By',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<>
					<Text overFlow="hidden" fontSize="12px" color="#000">
						{record?.modified_by}
					</Text>
					<DateContainer overFlow="hidden" fontSize="12px" color="#000">
						<DateFormat date={record?.updated_at} />
					</DateContainer>
				</>
			),
		},
		{
			title: '',
			dataIndex: 'actions',
			key: 'actions',
			sortId: 6,
			hidden: false,
			render: (_, record) => {
				if (record?.return_type === 'DR') {
					return (
						<Flex justifyContent="center">
							<Link to={`/order-management/returns/${record?.id}`}>
								<Image src={viewIcon} alt="edit-icon" height={15} width={20} />
							</Link>
						</Flex>
					);
				}
				return (
					<Flex justifyContent="center">
						<Link to={`/order-management/returns/update-sales-return/${record?.id}`}>
							<Image src={viewIcon} alt="edit-icon" height={15} width={20} />
						</Link>
					</Flex>
				);
			},
		},

		{
			title: '',
			key: 'errors',
			dataIndex: 'errors',
			render: (_, record) => (
				<Flex
					justifyContent="center"
					onClick={() => {
						showModal();
						getErrorData(record);
					}}
				>
					{add_info_function(record)}
				</Flex>
			),
		},
	];
};
