import { Col, message, Row } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';
import { userLogin } from 'store/authSlice';
import { LoginInput } from 'atoms';
import { IconLabel } from 'components/common';
import PasswordIcon from 'assets/password.svg';
import * as styled from './AuthFormStyle';
import { validateRipplrEmail } from 'utils/fieldValidation';

const { LoginContainer, StyledSubmitButton } = styled;

const FormSchema = Yup.object({
	email: Yup.string()
		.required()
		.label('Email')
		.test('is-RipplrEmail', 'Not a valid Email', (value) => validateRipplrEmail(value)),
	password: Yup.string().required().label('Password'),
});

let initialValues = {
	email: undefined,
	password: undefined,
};

if (process.env.REACT_APP_ENV === 'development') {
	/**
	 * On development set login form details prefilled with data
	 */
	initialValues = {
		email: 'admin@ripplr.in',
		password: 'r!pp1r@123',
	};
}

function LoginForm() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	function handleSubmit(values, { setErrors, setSubmitting }) {
		return dispatch(userLogin(values, setErrors))
			.then((res) => {
				const userRes = res?.data?.user;

				if (isEmpty(userRes?.groups) && !userRes?.is_superuser) {
					window.localStorage.clear();
					return message.error('your role for is not specified');
				}
				console.log('Login api done 1');
				return navigate('/dashboard');
			})
			.catch((e) => {
				console.log('Login form catch', e);
				setSubmitting(false);
			});
	}

	return (
		<LoginContainer>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={FormSchema}
				onSubmit={handleSubmit}
			>
				{({ isSubmitting }) => (
					<Form layout="vertical">
						<Row gutter={8} justify="space-between">
							<Col span={24}>
								<LoginInput
									name="email"
									label={<IconLabel label="User ID" icon={PasswordIcon} />}
									className="email-field"
								>
									<Input name="email" />
								</LoginInput>
							</Col>
						</Row>
						<Row gutter={8} justify="space-between">
							<Col span={24}>
								<LoginInput
									name="password"
									label={<IconLabel label="Password" icon={PasswordIcon} />}
									className="password-field"
								>
									<Input.Password name="password" />
								</LoginInput>
							</Col>
						</Row>
						{/* <ForgotPassword id="forgot-password-link" to="/forgot-password">
							Forgot Password?
						</ForgotPassword> */}

						<Row gutter={8} justify="space-between">
							<Col span={24}>
								<StyledSubmitButton disabled={isSubmitting} id="login-btn">
									Login
								</StyledSubmitButton>
							</Col>
						</Row>
					</Form>
				)}
			</Formik>
		</LoginContainer>
	);
}

export default LoginForm;
