import { createSlice } from '@reduxjs/toolkit';

const wmsUrl = `${process.env?.REACT_APP_API_WMS_GRN_OA_URL}wms-view/`;
const wmsUrlRetry = `${process.env?.REACT_APP_API_WMS_GRN_OA_URL}wms-view/?action=retry&id_list`;

export const wmslogsSlice = createSlice({
	name: 'wmslogs',
	initialState: {},
	reducers: {},
});

export const filterObject = (me) => ({
	user_id: me.id,
	user_brands_only: true,
	user_fcs_only: true,
});

export const getWmsLogsList =
	({ offset, limit, sort = { sort_column: 'createdAt', sort_direction: 'DESC' } }) =>
	async (dispatch, getState) => {
		const {
			auth: { me },
		} = getState();

		const CREDENTIALS = {
			method: 'POST',
			body: JSON.stringify({
				filter: filterObject(me),
				page: { offset, limit },
				sort,
			}),
		};
		return fetch(`${wmsUrl}`, CREDENTIALS)
			.then((res) => res.json())
			.then((res) => res.data);
	};

export const getRetrtWmsLogsList =
	({
		selectionType,
		offset,
		limit,
		sort = { sort_column: 'createdAt', sort_direction: 'DESC' },
	}) =>
	async (dispatch, getState) => {
		const {
			auth: { me },
		} = getState();

		const CREDENTIALS = {
			method: 'POST',
			body: JSON.stringify({
				filter: filterObject(me),
				page: { offset, limit },
				sort,
			}),
		};
		return fetch(`${wmsUrlRetry}=${selectionType}`, CREDENTIALS)
			.then((res) => res.json())
			.then((res) => res.data);
	};

export const getInfoByInvoice =
	({
		invoice,
		fcs,
		brands,
		offset,
		limit,
		sort = { sort_column: 'createdAt', sort_direction: 'DESC' },
	}) =>
	async (dispatch, getState) => {
		const {
			auth: { me },
		} = getState();

		let query = `?invoice_id=${invoice}`;
		if (fcs) {
			query += `&fcs=${fcs}`;
		}
		if (brands) {
			query += `&brands=${brands}`;
		}

		const CREDENTIALS = {
			method: 'POST',
			body: JSON.stringify({
				filter: filterObject(me),
				page: { offset, limit },
				sort,
			}),
		};
		return fetch(`${wmsUrl}${query}`, CREDENTIALS)
			.then((res) => res.json())
			.then((res) => res.data);
	};

export const getStatusWMSlogs =
	({ status, offset, limit, sort = { sort_column: 'createdAt', sort_direction: 'DESC' } }) =>
	async (dispatch, getState) => {
		const {
			auth: { me },
		} = getState();

		const CREDENTIALS = {
			method: 'POST',
			body: JSON.stringify({
				filter: filterObject(me),
				page: { offset, limit },
				sort,
			}),
		};
		return fetch(`${wmsUrl}?status=${status}`, CREDENTIALS)
			.then((res) => res.json())
			.then((res) => res.data);
	};

export const getPaginatedlogs =
	({ status, offset, limit, sort = { sort_column: 'createdAt', sort_direction: 'DESC' } }) =>
	async (dispatch, getState) => {
		const {
			auth: { me },
		} = getState();

		const CREDENTIALS = {
			method: 'POST',
			body: JSON.stringify({
				filter: filterObject(me),
				page: { offset, limit },
				sort,
			}),
		};
		if (status) {
			return fetch(`${wmsUrl}?status=${status}`, CREDENTIALS)
				.then((res) => res.json())
				.then((res) => res.data);
		}
		return fetch(`${wmsUrl}`, CREDENTIALS)
			.then((res) => res.json())
			.then((res) => res.data);
	};

export default wmslogsSlice.reducer;
