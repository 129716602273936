import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { RemarksIcon, Verification } from 'components/pages/segregator';
import { Rupee, RejectedListModal } from 'components';
import {
	getChequesUPIList,
	saveChequesOrUPIList,
	rejectChequesOrUPIList,
} from 'store/invoiceSlice';
import { Flex, Button, Text } from 'atoms';
import { useURL } from 'hooks';
import { getunique, unScReplace } from 'utils/text';
import { getGreenRedTicks, getTransactions } from 'utils/helper';

export default function ChequeList() {
	const dispatch = useDispatch();
	const [invoices, setInvoices] = useState([]);
	const [isValid, setIsValid] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [rejectedList, setRejectedList] = useState([]);
	const urlData = useURL();
	const navigate = useNavigate();
	const { cashierPersonHeaderDetails = {} } = useSelector((state) => state.invoice);

	useEffect(() => {
		setIsValid(invoices?.some((item) => item?.verification_checked || item?.rejection_checked));
	}, [invoices, cashierPersonHeaderDetails]);

	useEffect(() => {
		const data = {
			...urlData,
			payment_type: 'Cheque',
		};

		dispatch(getChequesUPIList(null, data)).then((res) => {
			res?.data?.forEach((element) => {
				element.disabled = element?.payment_verification_status === 'VerifiedByCashier';
				element.verification_checked =
					element?.payment_verification_status === 'VerifiedByCashier';
				element.rejection_checked =
					element?.payment_verification_status === 'RejectedByCashier';
			});
			setInvoices(res?.data);
		});
	}, [dispatch, urlData]);

	function handleVerifyReject(record, type = 'verify', rejectedReason = null) {
		const records = invoices.map((item) => {
			if (item?.key === record?.key) {
				return getGreenRedTicks(type, item, rejectedReason);
			}
			return item;
		});
		setInvoices([...records]);
	}

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const columns = [
		{
			title: 'Invoice Numbers',
			dataIndex: 'invoice_nos',
			key: 'invoice_nos',
			render: (invoice_nos) => invoice_nos.split(',').map((si) => <Text key={si}>{si}</Text>),
		},
		{
			title: 'Store Name',
			dataIndex: 'storeName',
			key: 'storeName',
			filters: getunique(invoices, 'storeName'),
			onFilter: (value, record) => record.storeName.startsWith(value),
			filterSearch: true,
			render: (storeName) => <span>{unScReplace(storeName)}</span>,
		},
		{
			title: 'Cheque no.',
			dataIndex: 'referenceNumber',
			key: 'referenceNumber',
			render: (record) => <div>{record}</div>,
		},
		{
			title: 'Bank',
			dataIndex: 'bankName',
			key: 'bankName',
			filters: getunique(invoices, 'bankName'),
			onFilter: (value, record) => record.bankName.startsWith(value),
			filterSearch: true,
			render: (bankName) => <span>{unScReplace(bankName)}</span>,
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (record) => <Rupee>{Number(record)}</Rupee>,
		},
		{
			title: 'Due Date',
			dataIndex: 'dueDate',
			key: 'dueDate',
			render: (dueDate) => <>{moment(moment(dueDate)).format('DD-MMM-YYYY')}</>,
		},
		{
			title: 'Verification',
			dataIndex: 'verified_by_segregator_status',
			key: 'verified_by_segregator_status',
			render: (_, record) => (
				<Verification
					record={record}
					handleVerifyReject={handleVerifyReject}
					showRejection
					remarksType="CHEQUE_PAYMENT_REJECT"
					disabled={record?.disabled || false}
				/>
			),
		},
		{
			title: 'Remarks',
			dataIndex: 'salesman_name',
			key: 'salesman_name',
			render: (_, record) => <RemarksIcon record={record} type="CHEQUE_PAYMENT" />,
		},
	];

	function askConfirmation() {
		const summary = invoices.filter((item) => item.rejection_checked);
		if (summary?.length > 0) {
			dispatch(
				rejectChequesOrUPIList({
					payment_type: 'Cheque',
					collected_person_id:
						cashierPersonHeaderDetails?.data_summary?.collected_person_id,
					allocation_date_time:
						cashierPersonHeaderDetails?.data_summary?.allocation_date_time,
					transactions: getTransactions(invoices, urlData, 'CHEQUE_PAYMENT_REJECT'),
				})
			)
				.then((res) => {
					setRejectedList(res.data);
					setIsModalOpen(true);
				})
				.catch((er) => {
					console.log('res', er);
				});
		} else {
			handleChequeVerification();
		}
	}

	function handleChequeVerification() {
		dispatch(
			saveChequesOrUPIList({
				payment_type: 'Cheque',
				collected_person_id: cashierPersonHeaderDetails?.data_summary?.collected_person_id,
				allocation_date_time:
					cashierPersonHeaderDetails?.data_summary?.allocation_date_time,
				cid: cashierPersonHeaderDetails?.data_summary?.cid,
				transactions: getTransactions(invoices, urlData, 'CHEQUE_PAYMENT_REJECT'),
			})
		).then(() => {
			redirect();
		});
	}

	function redirect() {
		let pathname = `/cashier`;
		if (
			cashierPersonHeaderDetails?.data_summary?.total_upi > 0 &&
			!cashierPersonHeaderDetails?.data_summary?.payment_verification_status?.UPI
		) {
			pathname = `/cashier/upi-verify`;
		} else if (
			cashierPersonHeaderDetails?.data_summary?.total_cash > 0 &&
			!cashierPersonHeaderDetails?.data_summary?.payment_verification_status?.Cash
		) {
			pathname = `/cashier/cash-verify`;
		} else if (
			cashierPersonHeaderDetails?.data_summary?.total_neft > 0 &&
			!cashierPersonHeaderDetails?.data_summary?.payment_verification_status?.NEFT
		) {
			pathname = `/cashier/neft-verify`;
		}
		navigate({
			pathname,
			search: urlData?.queryString,
		});
	}

	return (
		<div>
			<Table
				rowKey={(record) => `${record?.key}`}
				dataSource={invoices}
				columns={columns}
				pagination={false}
			/>
			<Flex justifyContent="flex-end" mt="2rem">
				<Button
					onClick={askConfirmation}
					disabled={
						invoices?.length === 0 ||
						!isValid ||
						cashierPersonHeaderDetails?.data_summary?.payment_verification_status
							?.Cheque
					}
				>
					{cashierPersonHeaderDetails?.data_summary?.payment_verification_status?.Cheque
						? 'Verified'
						: 'Save'}
				</Button>
				{/* <Button variant="secondary" onClick={redirect}>
					redirect
				</Button> */}
				<RejectedListModal
					visible={isModalOpen}
					rejectedList={rejectedList}
					onCancel={handleCancel}
					confirmRejection={handleChequeVerification}
				/>
			</Flex>
		</div>
	);
}
