import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Table, Modal, Select, message as toaster } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
// eslint-disable-next-line import/no-unresolved
import PropTypes from 'prop-types';
import { getChequeBounceList, updateChequeStatus } from 'store/financeTeamSlice';
import { Button, Flex, Box, AsyncSelect, Text } from 'atoms';
import { useQueryParams } from 'hooks';
import { LoadAsyncSelectComponent, LabelWrapper } from 'components/common';
import { getFcListOnLoad, getFcOnSearchList } from 'store/masters';
import {
	handleFilterChange,
	chequeBounceQueries,
	toAsysnOption,
	searchBox,
	SelectFilterLabelOptions,
	initialPaginateConfigWithoutQ,
} from 'utils';
import { chequeBounceStatuses } from 'utils/consts/cheque';
import ShowExpandableData from '../finance/ShowExpandableData';

export const Input = styled.input`
	height: 40px;
	width: 300px;
	background: #faf9f8;
	border: 1px solid #edebe9;
	border-radius: 2px;
	margin-top: 7px;
	padding: 8px 16px 8px 16px;
	margin-right: 24px;
`;

const ButtonContainer = styled(Flex)`
	justify-content: center;
	margin-top: 2rem;
`;

export const ExpandedData = styled.div`
	margin-right: 20px;
	padding: 5px 50px 5px 50px;
`;

export default function CashierChequeBounce({ columns, popup, handleCancel }) {
	const { auth } = useSelector((state) => state?.auth);
	const dispatch = useDispatch();

	const [chequeBounceList, setChequeBounceList] = useState();
	const [searchText, setSearchText] = useState('');
	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);
	const [paginateConfig, setpaginateConfig] = useQueryParams({
		filterQuery: chequeBounceQueries,
	});

	useEffect(() => {
		setFcList({ type: 'Loading' });

		dispatch(
			getFcListOnLoad({ fcIds: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [] })
		).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});
	}, [dispatch, paginateConfig?.fcs]);

	const handleChangeSelect = (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value

		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		const fcValues = value?.value ? [value?.value] : [];
		setpaginateConfig((prev) => ({
			...handleFilterChange(name, fcValues, prev),
			brands: [],
		}));
	};
	const handleOk = (data) => {
		updateChequeStatus(data).then((r) => {
			toaster.info(r.message);
		});
		handleCancel();
		loadCheques();
	};

	const loadCheques = useCallback(
		(text, filters) => {
			if (auth.user.groups[0].name === 'Cashier') {
				getChequeBounceList(text, filters, true).then((r) => {
					setChequeBounceList(r);
				});
			} else {
				getChequeBounceList(text, filters, false).then((r) => {
					setChequeBounceList(r);
				});
			}
		},
		[auth.user.groups]
	);

	useEffect(() => {
		loadCheques();
	}, [loadCheques]);

	return (
		<div>
			<Box mb="10px">
				<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
					<LoadAsyncSelectComponent
						loadFCs={paginateConfig?.fcs}
						setList={(val) => setBrandList(val)}
						options={fcsList}
					>
						<AsyncSelect
							style={searchBox}
							allowClear
							name="fcs"
							showSearch
							fetchOptions={getFcOnSearchList}
							transformOptions={(val) => toAsysnOption(val)}
							defaultValue={paginateConfig?.fcs}
							placeholder="Select FC(s)"
							onChange={(value, options) => {
								handleChangeSelect('fcs', value, options);
							}}
							selectOptions={[...(fcsList.data || [])]}
						/>
					</LoadAsyncSelectComponent>
				</LabelWrapper>

				<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
					<Select
						name="fields"
						style={searchBox}
						allowClear
						showSearch
						label="brands"
						mode="multiple"
						value={paginateConfig?.brands}
						filterOption={SelectFilterLabelOptions}
						options={brandsList}
						onChange={(value) =>
							setpaginateConfig((prev) => ({
								...prev,
								brands: value,
								...initialPaginateConfigWithoutQ,
							}))
						}
					/>
				</LabelWrapper>

				<LabelWrapper label="Status" marginspace="3px 0 -1px 1rem">
					<Select
						name="fields"
						style={searchBox}
						allowClear
						showSearch
						label="Status"
						placeholder="Select Status"
						value={paginateConfig?.statuses}
						filterOption={SelectFilterLabelOptions}
						options={
							chequeBounceStatuses?.map((x) => ({
								label: x.label,
								value: x?.value,
							})) || []
						}
						onChange={(value) =>
							setpaginateConfig((prev) => ({
								...prev,
								statuses: value,
								...initialPaginateConfigWithoutQ,
							}))
						}
					/>
				</LabelWrapper>
				<Input
					onChange={(e) => setSearchText(e.target.value)}
					placeholder="Search by Cheque Number"
					pattern="[0-9]*"
					inputMode="numeric"
					maxLength={6}
					value={searchText}
				/>
				<Button type="button" onClick={() => loadCheques(searchText, paginateConfig)}>
					Search
				</Button>
			</Box>
			<Table
				rowKey={(record) => `${record?.cheque_bounce_unique_column_hash}`}
				dataSource={chequeBounceList}
				expandable={{
					// eslint-disable-next-line react/no-unstable-nested-components
					expandedRowRender: (record) => (
						<Box>
							<Flex>
								<ExpandedData>
									<ShowExpandableData record={record} />
								</ExpandedData>
							</Flex>
						</Box>
					),
				}}
				columns={columns}
				pagination={{
					...paginateConfig,
					total: chequeBounceList?.length,
					showSizeChanger: true,
					page: Number(paginateConfig?.currentPage) || 1,
					item_per_page: Number(paginateConfig?.limit) || 10,
				}}
			/>
			<Modal
				centered
				visible={popup}
				footer={null}
				onCancel={handleCancel}
				maskClosable={false}
			>
				<Box mt="3" textAlign="center">
					<Text>Did you receive the Physical copy of Bounced Cheque?</Text>
				</Box>
				<ButtonContainer>
					<Button color="#fff" border={0} variant="secondary" onClick={handleCancel}>
						No
					</Button>
					<Button variant="primary" onClick={() => handleOk(popup)} ml="2rem">
						Yes
					</Button>
				</ButtonContainer>
			</Modal>
		</div>
	);
}

CashierChequeBounce.propTypes = {
	columns: PropTypes.array,
	popup: PropTypes.bool,
	handleCancel: PropTypes.func,
};
