import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Button, Image } from 'atoms';
import Alert from 'assets/Alert.svg';

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1.4rem;
	width: 100%;

	button {
		width: 80%;
	}
`;

export default function RejectModal({ modalVisible, modalClose, width = '25%' }) {
	return (
		<Modal centered width={width} visible={modalVisible} footer={null} onCancel={modalClose}>
			<Box textAlign="center" cursor="pointer">
				<Image src={Alert} alt="alert" />
			</Box>
			<Box mt="3" textAlign="center">
				<h1>Provide reason for rejecting the leave.</h1>
			</Box>
			<ButtonContainer>
				<Button variant="primary" onClick={modalClose}>
					Reject
				</Button>
			</ButtonContainer>
		</Modal>
	);
}

RejectModal.propTypes = {
	modalVisible: PropTypes.bool,
	modalClose: PropTypes.func,
	width: PropTypes.number,
};
