import React, { useState } from 'react';
import { Select } from 'antd';
import { isEmpty } from 'lodash';
import { getPincodeList } from 'store/masters';
import { Box, Flex, Button } from 'atoms';
import { RCTable, LabelWrapper, SearchBar } from 'components/common';
import {
	pincodeColumns,
	PincodeQueries,
	handleFilterChange,
	handleSearch,
	SelectFilterLabelOptions,
	searchBox,
} from 'utils';
import { SearchField } from 'components/common/custom-table/Style';
import { useQueryParams } from 'hooks';

export default function PinCodePage() {
	const [paginateConfig, setpaginateConfig] = useQueryParams({ filterQuery: PincodeQueries });
	const [rowData, setRowData] = useState({});
	const [btnClickSearh, setBtnClickSearh] = useState({});

	return (
		<Box mt={3}>
			<Flex alignItems="center" margin="0 0 1rem">
				<Flex width="100%" flexWrap="wrap" alignItems="center" gap="4px">
					<LabelWrapper label="Search By" marginspace="3px 0 -1px 1rem">
						<Select
							name="fields"
							style={searchBox}
							allowClear
							label="Search By"
							mode="multiple"
							showSearch
							filterOption={SelectFilterLabelOptions}
							value={paginateConfig?.fields}
							options={[{ value: 'pincode', label: 'Pincode' }]}
							placeholder="Search By"
							onChange={(value) =>
								setpaginateConfig((prev) => {
									const fieldsValue = handleFilterChange('fields', value, prev);
									return {
										...fieldsValue,
										...(isEmpty(value) ? { query: '' } : {}),
									};
								})
							}
						/>
					</LabelWrapper>
					{!isEmpty(paginateConfig?.fields) && (
						<LabelWrapper label="Search" marginspace="-1px 0 5px 0">
							<SearchField>
								<SearchBar
									placeholder="Search"
									queryValue={paginateConfig?.query?.replaceAll('*', '')}
									onSearch={(val) =>
										setpaginateConfig((prev) => handleSearch(val, prev))
									}
								/>
							</SearchField>
						</LabelWrapper>
					)}
					<Box mt=".75rem">
						<Button onClick={() => setBtnClickSearh(Math.random())}>Search</Button>
					</Box>
				</Flex>
			</Flex>
			<RCTable
				rowKey="id"
				tableDatas={[rowData, setRowData]}
				togglePaginate={[paginateConfig, setpaginateConfig]}
				dispatchAction={getPincodeList}
				configs={{ defaultSort: 'ASC', defaultsortField: 'id' }}
				columns={pincodeColumns}
				triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
				disableAutoSearch
			/>
		</Box>
	);
}
