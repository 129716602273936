import React, { useState } from 'react';
import { Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex } from 'atoms';
import {
	RetailCategoryColumns,
	handleFilterChange,
	handleSearch,
	SelectFilterLabelOptions,
	StoreCategoryQueries,
	searchBox,
	UserPersmission,
} from 'utils';
import { RCTable, SearchBar, LabelWrapper, ACL } from 'components/common';
import * as SVGICONS from 'utils/Svg';
import { getCategoryList } from 'store/categorySlice';
import { SearchField } from 'components/common/custom-table/Style';
import { useQueryParams } from 'hooks';

const STORE_CLASS_CHOICE = [
	{ value: 'A', label: 'Class A' },
	{ value: 'B', label: 'Class B' },
	{ value: 'C', label: 'Class C' },
	{ value: 'D', label: 'Class D' },
];

export function CategoryPage() {
	const navigate = useNavigate();
	const [rowData, setRowData] = useState({});
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [paginateConfig, setpaginateConfig] = useQueryParams({
		filterQuery: StoreCategoryQueries,
	});

	return (
		<Box mt={3}>
			<Flex alignItems="center" marginBottom="1rem">
				<Flex width="100%" flexWrap="wrap" alignItems="center" gap="4px">
					<LabelWrapper label="Search" marginspace="-1px 0 5px 0">
						<SearchField>
							<SearchBar
								placeholder="Search"
								queryValue={paginateConfig?.query?.replaceAll('*', '')}
								onSearch={(val) =>
									setpaginateConfig((prev) => handleSearch(val, prev))
								}
							/>
						</SearchField>
					</LabelWrapper>
					<LabelWrapper label="Store Class" marginspace="3px 0 -1px 1rem">
						<Select
							name="retailer_class"
							style={searchBox}
							allowClear
							showSearch
							filterOption={SelectFilterLabelOptions}
							value={paginateConfig?.retailer_class}
							placeholder="Store Class"
							options={STORE_CLASS_CHOICE}
							onChange={(value) =>
								setpaginateConfig((prev) =>
									handleFilterChange('retailer_class', value, prev)
								)
							}
						/>
					</LabelWrapper>
					<Box mt=".75rem">
						<Button onClick={() => setBtnClickSearh(Math.random())}>Search</Button>
					</Box>
				</Flex>
				<ACL allowedGroups={UserPersmission.storeCategory_create}>
					<Button
						style={{ minWidth: '180px' }}
						onClick={() => navigate('/onboarding/retail-category/create')}
					>
						<Flex alignItems="center" justifyContent="center">
							<Box width={36}>
								<SVGICONS.PlusOutlinedIcons />
							</Box>
							<Box>Add Store Category</Box>
						</Flex>
					</Button>
				</ACL>
			</Flex>

			<RCTable
				rowKey="id"
				configs={{
					defaultSort: 'DESC',
					defaultsortField: 'created_at',
				}}
				tableDatas={[rowData, setRowData]}
				togglePaginate={[paginateConfig, setpaginateConfig]}
				dispatchAction={getCategoryList}
				columns={RetailCategoryColumns}
				triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
				disableAutoSearch
			/>
		</Box>
	);
}
