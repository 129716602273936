import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Modal, Select, List, Card, Space, Tag, message } from 'antd';
import { Box, Flex, Button } from 'atoms';
import { RCTable, LabelWrapper, ACL } from 'components/common';
import { UploadPageModal } from 'components/pages/';
import { getUploadList, downloadExcelsUsingUrl } from 'store/UploadSlice';
import {
	UploadListColumns,
	UPLOAD_FILE_TYPE_CHOICE,
	DownloadFile,
	handleFilterChange,
	initialPaginateConfig,
	UploadQueries,
	SelectFilterLabelOptions,
	UserPersmission,
} from 'utils';
import * as SVGICONS from 'utils/Svg';
import { useQueryParams } from 'hooks';
import { showLoader, hideLoader } from 'store/appSlice';

const selectOptions = UPLOAD_FILE_TYPE_CHOICE.map((x) => ({
	label: x.name,
	value: x.id,
}));

const createItemList = (dataList) => {
	const listItems = dataList.map((resVal) => (
		<Card
			key={resVal.invoice}
			style={{ marginBottom: '10px' }}
			title={`Invoice No: ${resVal.invoice}`}
		>
			<p>Detail: {resVal?.status?.error?.details}</p>
			<p>Error: {resVal?.status?.error?.error}</p>
		</Card>
	));
	return <div>{listItems}</div>;
};

const createItemCount = (countList) => {
	const listCountItems = countList.map((countRes, index) => (
		// eslint-disable-next-line no-nested-ternary
		<Tag key={countRes?.label} color={index === 0 ? 'green' : index === 1 ? 'red' : 'blue'}>
			{countRes?.label}: <strong>{countRes?.value}</strong>
		</Tag>
	));
	return (
		<Space style={{ width: '100%' }} size="large">
			{listCountItems}
		</Space>
	);
};

const getInvoiceList = (val) => {
	const invalidInvString = JSON.stringify(val);
	return (
		<Box mt="12px" key={invalidInvString.invoice}>
			<p>Invoice no: {val.invoice}</p>
			<p>Error: {val.status.error.error}</p>
			<p>Details: {val.status.error.details}</p>
		</Box>
	);
};

export function UploadListPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		auth: { me },
	} = useSelector((state) => state);

	const [rowData, setRowData] = useState({});
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [modalVisible, setModalVisible] = useState(false);
	const [errormodalVisible, setErrorModalVisible] = useState({ show: false, message: '' });
	const [statusModalVisibles, setStatusModalVisibles] = useState({ show: false });
	const [statusModalData, setStatusModalData] = useState(null);
	const [statusData, setStatusData] = useState(null);
	const [paginateConfig, setpaginateConfig] = useQueryParams({ filterQuery: UploadQueries });
	const handleClick = (id) => navigate(`/uploads/${id}`);
	const orderAdaptorApi = `${process.env.REACT_APP_API_WMS_GRN_OA_URL}filestatus/`;

	const handleStatusModalVisibles = (data) => {
		setStatusData(null);
		setStatusModalData(data);
		setStatusModalVisibles({
			show: true,
		});
		if (data.user_id && data.filename && orderAdaptorApi) {
			fetch(
				`${orderAdaptorApi}?user_id=${data.user_id}&file_name=${data.filename}`
				// `https://mf42giwodl.execute-api.ap-south-1.amazonaws.com/Prod/filestatus?user_id=41&file_name=dev/salesorder/PUNE1/HUL/2022/sep/09/11amE_SALES.csv`
			)
				.then((res) => {
					if (!res.ok) {
						setStatusData([{ title: 'error', value: `Error Status: ${res.status}` }]);
						throw Error(res.status);
					}
					return res.json();
				})
				.then((res1) => {
					if (isEmpty(res1)) {
						setStatusData([{ title: 'empty' }]);
						return;
					}
					if (res1) {
						setStatusData(Object.values(res1)[0]?.status);
					}
				});
		}
	};

	const downloadFiles = (rec) => {
		dispatch(showLoader());
		fetch(`${orderAdaptorApi}?user_id=${me?.id}&file_name=${rec?.fileName}&get_error_file=true`)
			.then((res) => res.json())
			.then((res) => {
				dispatch(hideLoader());
				if (res?.data) {
					DownloadFile({ name: rec?.fileName, url: res?.data });
					return message.info('File Downloaded Successfully');
				}
				return message.info('No File Found');
			});
	};

	// res1 = {};
	const handleDownloadExcel = (id) => {
		dispatch(downloadExcelsUsingUrl({ url: `champ/file/${id}/download`, data: {} })).then(
			(res) => DownloadFile(res)
		);
	};

	return (
		<>
			<Box mt={3}>
				<Box>
					<Flex
						width="100%"
						flexWrap="wrap"
						alignItems="center"
						justifyContent="space-between"
						marginBottom="1rem"
					>
						<Box>
							<LabelWrapper label="Select File Types" marginspace="0px 0 3px 0">
								<Select
									style={{ width: '200px', alignSelf: 'center' }}
									allowClear
									name="fileType"
									placeholder="Select File Type"
									showSearch
									filterOption={SelectFilterLabelOptions}
									value={paginateConfig?.fileType}
									options={selectOptions}
									onChange={(value) =>
										setpaginateConfig((prev) =>
											handleFilterChange('fileType', value, prev)
										)
									}
								/>
							</LabelWrapper>
							<Button onClick={() => setBtnClickSearh(Math.random())}>Search</Button>
						</Box>
						<ACL allowedGroups={UserPersmission.upload_list_create}>
							<Button onClick={() => setModalVisible(true)}>
								<Flex alignItems="center" justifyContent="center">
									<Box width={36}>
										<SVGICONS.UploadIcons />
									</Box>
									<Box>Upload Excel</Box>
								</Flex>
							</Button>
						</ACL>
					</Flex>
				</Box>

				<RCTable
					rowKey="id"
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'id',
					}}
					tableDatas={[rowData, setRowData]}
					dispatchAction={getUploadList}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					columns={UploadListColumns({
						handleClick,
						handleDownloadExcel,
						downloadFiles,
						setErrorModalVisible,
						handleStatusModalVisibles,
						defaultSort: paginateConfig?.sort_direction || 'DESC',
						defaultsortField: paginateConfig?.sort_column || 'id',
					})}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
			{modalVisible && (
				<UploadPageModal
					toggleState={[modalVisible, setModalVisible]}
					handleTogglePaginate={() => setpaginateConfig({ ...initialPaginateConfig })}
				/>
			)}
			{errormodalVisible.show && (
				<Modal
					destroyOnClose
					title="Error Message"
					visible={errormodalVisible.show}
					onOk={() => setErrorModalVisible({ message: '', show: false })}
					onCancel={() => setErrorModalVisible({ message: '', show: false })}
					footer={null}
					width="60%"
				>
					<Box>{errormodalVisible.message}</Box>
				</Modal>
			)}
			{statusModalVisibles.show && (
				<Modal
					title={statusModalData?.title}
					visible={statusModalVisibles.show}
					onOk={() => {
						setStatusModalVisibles({ show: false });
						setStatusData(null);
						setRowData(null);
					}}
					onCancel={() => {
						setStatusModalVisibles({ show: false });
						setStatusData(null);
						setRowData(null);
						setpaginateConfig((prev) =>
							handleFilterChange('fileType', undefined, prev)
						);
					}}
					footer={null}
					width="60%"
				>
					{statusData && (
						<Box>
							<Button
								style={{ marginBottom: '10px' }}
								onClick={() =>
									handleStatusModalVisibles({
										title: statusModalData.title,
										filename: statusModalData.filename,
										user_id: statusModalData.user_id,
									})
								}
							>
								<Flex alignItems="center" justifyContent="center">
									<Box>Refresh</Box>
								</Flex>
							</Button>
						</Box>
					)}
					<Box>
						{!statusData ? (
							<p>Fetching Details...</p>
						) : (
							<List
								itemLayout="horizontal"
								dataSource={statusData}
								renderItem={(item) => (
									<List.Item>
										<>
											{item?.title === 'empty' && <p>No Error Found</p>}
											{item?.title === 'error' && <p>{item?.value}</p>}
											{item?.label === 'cdms_invalid_invoices' && (
												<Card
													style={{ width: '100%' }}
													type="inner"
													extra={
														item?.count?.length > 0 && (
															<p key={item?.label}>
																{createItemCount(item?.count)}
															</p>
														)
													}
													title="CDMS Invalid Invoices"
												>
													{item?.value?.length > 0 &&
														createItemList(item?.value)}
												</Card>
											)}
											{item?.label === 'invalid_invoices' && (
												<Card
													style={{ width: '100%' }}
													type="inner"
													extra={
														item?.count?.length > 0 && (
															<p key={item?.label}>
																{createItemCount(item?.count)}
															</p>
														)
													}
													title="Invalid Invoices"
												>
													{console.log('working ', item?.value)}
													{item?.value.map(getInvoiceList)}
												</Card>
											)}
											{item?.label === 'existing_invoices' &&
												item?.value?.length > 0 && (
													<Card
														style={{ width: '100%' }}
														type="inner"
														title="Existing Invoices"
														extra={
															<Space
																style={{ width: '100%' }}
																size="large"
															>
																<Tag key="count" color="green">
																	Count:{' '}
																	<strong>
																		{item?.value?.length}
																	</strong>
																</Tag>
															</Space>
														}
													>
														{console.log('working ', item?.value)}
														{item?.value
															.map((e) => e)
															?.join(', ')
															?.toString()}
													</Card>
												)}
										</>
									</List.Item>
								)}
							/>
						)}
					</Box>
				</Modal>
			)}
		</>
	);
}
