import { Link } from 'react-router-dom';
import { CheckCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import viewIcon from 'assets/icons/eye.svg';
import { Text, Image, Box, Flex } from 'atoms';
import Rupee from 'components/Rupee';
import DateFormat from 'components/DateFormat';
import { GRNImageIcon, GRNLogIcon, GRNPdfIcon, GRNUploadIcon } from 'utils/Svg';
import { UserPersmission } from 'utils';
import { ACL } from 'components/common';

export const GRNListColumns = ({
	handleUploadModalOpen,
	handleInvocieView,
	defaultSort,
	defaultsortField,
	handleGrnLogsModalOpen,
	// eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
	const sortDirection = defaultSort === 'ASC' ? 'ascend' : 'descend';

	function getViewType(res) {
		const getActiveRes = res?.GrnSignedInvoices.find((x) => x.is_active === true);
		return getActiveRes?.file_type ? getActiveRes?.file_type?.toLowerCase() : null;
	}

	return [
		{
			title: 'Brand GRN Date',
			dataIndex: 'brand_grn_date',
			key: 'brand_grn_date',
			sortId: 1,
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => a.brand_grn_date - b.brand_grn_date,
			sortOrder: defaultsortField === 'brand_grn_date' ? sortDirection : false,
			render: (brand_grn_date) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={brand_grn_date} />
				</Text>
			),
		},
		{
			title: 'FC',
			dataIndex: 'FC.name',
			key: 'FC.name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.FC?.name}
				</Text>
			),
		},
		{
			title: 'Brand',
			dataIndex: 'Brand.name',
			key: 'Brand',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.Brand?.name}
				</Text>
			),
		},
		{
			title: 'GRN No',
			dataIndex: 'brand_grn_no',
			key: 'brand_grn_no',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{record?.brand_grn_no}
				</Text>
			),
		},
		{
			title: 'GRN Value',
			dataIndex: 'grn_value',
			key: 'grn_value',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<Rupee roundOff={0}>{record?.grn_value}</Rupee>
				</Text>
			),
		},
		{
			title: 'Invoice No',
			dataIndex: 'invoice_no',
			key: 'invoice_no',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{record.invoice_no}
				</Text>
			),
		},
		{
			title: 'Uploaded By',
			dataIndex: 'full_name',
			key: 'full_name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.File?.FileUploadedBy?.full_name}
				</Text>
			),
		},
		{
			title: 'Updated At',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={record?.updatedAt} />
				</Text>
			),
		},
		{
			title: '',
			dataIndex: 'actions',
			key: 'actions',
			sortId: 6,
			hidden: false,
			render: (_, record) => (
				<Flex>
					<Link to={`/warehouse-management/asn/grn/${record?.id}/show`}>
						<Image src={viewIcon} alt="edit-icon" height={15} width={20} />
					</Link>

					{!record?.GrnSignedInvoices.some((x) => x.is_active === true) ? (
						<ACL allowedGroups={UserPersmission.grn_create}>
							<Tooltip title="Upload">
								<Box
									cursor="pointer"
									alignSelf="center"
									alt="upload-icon"
									onClick={() => {
										handleUploadModalOpen(record);
									}}
								>
									<GRNUploadIcon width="15" />
								</Box>
							</Tooltip>
						</ACL>
					) : (
						<ACL allowedGroups={UserPersmission.grn_show}>
							<Tooltip
								title={getViewType(record) === 'pdf' ? 'View Pdf' : 'View Image'}
							>
								<Box
									cursor="pointer"
									alignItem="center"
									alt="pdf-icon"
									onClick={() => {
										handleInvocieView(record);
									}}
								>
									{getViewType(record) === 'pdf' ? (
										<GRNPdfIcon width="15" />
									) : (
										<GRNImageIcon width="15" />
									)}
								</Box>
							</Tooltip>
						</ACL>
					)}

					{record?.GrnSignedInvoices.length > 0 &&
					record?.GrnSignedInvoices.some((x) => x.is_active === true && x.is_verified) ? (
						<Tooltip title="verified">
							<Box alignItem="center" alt="verified-Icons">
								<CheckCircleFilled style={{ color: '#5B7F2B' }} />
							</Box>
						</Tooltip>
					) : (
						<div style={{ width: '15px' }}></div>
					)}
					{record?.GrnSignedInvoices.length > 0 &&
					record?.GrnSignedInvoices.some((x) => !x.is_active) ? (
						<Tooltip title="Deleted Logs">
							<Box
								cursor="pointer"
								alignItem="center"
								alt="Logs-Icons"
								onClick={() => {
									handleGrnLogsModalOpen(record);
								}}
							>
								<GRNLogIcon width="16" />
							</Box>
						</Tooltip>
					) : (
						<div style={{ width: '15px' }}></div>
					)}
				</Flex>
			),
		},
	];
};
