/* eslint-disable sonarjs/cognitive-complexity */
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import viewIcon from 'assets/icons/eye.svg';
import { Text, Image, Flex, Box } from 'atoms';
import Rupee from 'components/Rupee';
import DateFormat from 'components/DateFormat';
import { ACL } from 'components/common';
import { GRNLogIcon, GRNPdfIcon, GRNUploadIcon, GRNImageIcon } from 'utils/Svg';
import { UserPersmission } from 'utils';
import * as SVGICONS from 'utils/Svg';

const DateContainer = styled(Text)`
	color: #737373;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export const brandSalesReturnListColumns = ({
	defaultSort,
	defaultsortField,
	handleUploadModalOpen,
	handleInvocieView,
	handleSalesLogsModalOpen,
}) => {
	const sortDirection = defaultSort === 'ASC' ? 'ascend' : 'descend';
	function getViewType(res) {
		const getActiveRes = res?.credit_note_invoices.find((x) => x.is_active);
		return getActiveRes?.file_type ? getActiveRes?.file_type?.toLowerCase() : null;
	}
	return [
		{
			title: '',
			dataIndex: 'bnpl_status',
			key: 'bnpl_status',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.bnpl_enabled ? <SVGICONS.QuidSalesOrderIcons /> : ''}
				</Text>
			),
		},
		{
			title: 'Return No',
			dataIndex: 'return_no',
			key: 'return_no',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => a.return_no - b.return_no,
			sortOrder: defaultsortField === 'return_no' ? sortDirection : false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{record?.return_no}
				</Text>
			),
		},
		{
			title: 'Brand',
			dataIndex: 'brand',
			key: 'brand',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{record?.brand}
				</Text>
			),
		},
		{
			title: 'FC',
			dataIndex: 'fc',
			key: 'fc',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.fc}
				</Text>
			),
		},
		{
			title: 'Invoice No',
			dataIndex: 'invoice_no',
			key: 'invoice_no',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{record?.invoice_no}
				</Text>
			),
		},
		{
			title: 'Store Name',
			dataIndex: 'name',
			key: 'name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.store_name}
				</Text>
			),
		},
		{
			title: 'Sales Return Value',
			dataIndex: 'return_value',
			key: 'return_value',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<Rupee roundOff={0}>{record?.return_value}</Rupee>
				</Text>
			),
		},
		{
			title: 'Uploaded By',
			dataIndex: 'uploaded_by',
			key: 'uploaded_by',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<>
					<Text overFlow="hidden" fontSize="12px" color="#000">
						{record?.uploaded_by}
					</Text>
					<DateContainer overFlow="hidden" fontSize="12px" color="#000">
						<DateFormat date={record.uploaded_at} format="HMSA" />
					</DateContainer>
				</>
			),
		},
		{
			title: 'Actions',
			dataIndex: 'actions',
			key: 'actions',
			sortId: 6,
			hidden: false,
			render: (_, record) => (
				<Flex>
					<Link to={`/order-management/brand-sales-return/${record?.id}/show`}>
						<Image src={viewIcon} alt="edit-icon" height={15} width={20} />
					</Link>
					{!record?.credit_note_invoices.some((x) => x.is_active === 1) ? (
						<ACL allowedGroups={UserPersmission.grn_create}>
							<Tooltip title="Upload">
								<Box
									cursor="pointer"
									alignSelf="center"
									alt="upload-icon"
									onClick={() => {
										handleUploadModalOpen(record);
									}}
								>
									<GRNUploadIcon width="15" />
								</Box>
							</Tooltip>
						</ACL>
					) : (
						<ACL allowedGroups={UserPersmission.grn_show}>
							<Tooltip
								title={getViewType(record) === 'pdf' ? 'View Pdf' : 'View Image'}
							>
								<Box
									cursor="pointer"
									alignItem="center"
									alt="pdf-icon"
									onClick={() => {
										handleInvocieView(record);
									}}
								>
									{getViewType(record) === 'pdf' ? (
										<GRNPdfIcon width="15" />
									) : (
										<GRNImageIcon width="15" />
									)}
								</Box>
							</Tooltip>
						</ACL>
					)}
					{record?.credit_note_invoices.length > 0 &&
					record?.credit_note_invoices.some((x) => x.is_active === 0) ? (
						<Tooltip title="Deleted Logs">
							<Box
								cursor="pointer"
								alignItem="center"
								alt="Logs-Icons"
								onClick={() => {
									handleSalesLogsModalOpen(record);
								}}
							>
								<GRNLogIcon width="16" />
							</Box>
						</Tooltip>
					) : (
						<div style={{ width: '15px' }}></div>
					)}
				</Flex>
			),
		},
	];
};
