/* eslint-disable sonarjs/prefer-single-boolean-return */
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { checkAssignedGroups } from 'utils/userPermission';

export default function ACLBoolean(allowedGroups) {
	const { auth: userDefinitions } = useSelector((state) => state.auth);

	const hasAccess = allowedGroups
		? checkAssignedGroups(allowedGroups, userDefinitions?.user)
		: false;

	if (userDefinitions?.user?.is_superuser) {
		return true;
	}

	if (
		(userDefinitions?.user?.is_challenger && !userDefinitions?.user?.is_champion) ||
		(!userDefinitions?.user?.is_challenger && !userDefinitions?.user?.is_champion)
	) {
		return false;
	}

	if (hasAccess) {
		return true;
	}

	return false;
}

ACLBoolean.propTypes = {
	allowedGroups: PropTypes.array,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
