import styled from 'styled-components';
import { Box, Flex } from 'atoms';

export const LabelText = styled(Box)`
	font-weight: 300;
	font-size: 12px;
	line-height: 15px;
	color: #5a5a5a;
`;

export const SummaryValues = styled(Box)`
	font-size: ${(props) => (props.isHead ? '26px' : '16px')};
	line-height: ${(props) => (props.isHead ? '34px' : '20px')};
	margin-bottom: 10px;
`;

export const ValueTextIn = styled(Box)`
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
	margin-bottom: 5px;
	color: rgba(0, 0, 0, 0.5);
	display: flex;
`;

export const ValueTextInvdetails = styled(Box)`
	font-size: 15px;
	line-height: 22px;
	color: #1b4965;
	flex: 0 0 70%;
`;

export const LabelTextInvdetails = styled(Box)`
	font-size: 12px;
	line-height: 14px;
	color: #3f3f3f;
	align-self: center;
	flex: 0 0 30%;
`;

export const StatusTextInvdetails = styled(Flex)`
	background: #ffdbfb;
	border-radius: 2px;
	padding: 3px 10px;
`;

export const StatusWrapInvdetails = styled(Flex)`
	margin-bottom: 10px;
	padding: 3px 10px;
	flex: 0 0 50%;
`;
