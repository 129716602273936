import { createSlice } from '@reduxjs/toolkit';
import { API } from 'api';

const initialState = {};

export const salesReturnSlice = createSlice({
	name: 'salesReturn',
	initialState,
	reducers: {},
});

export const getSalesReturnList =
	({ offset, limit, sort_column = 'id', sort_direction = 'DESC', fcs, brands }) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/sales_return/list',
			data: {
				filter: {
					user_fcs_only: true,
					user_brands_only: true,
					brands: brands?.length ? brands : undefined,
					fcs: fcs?.length ? fcs : undefined,
				},
				page: { offset, limit },
				sort: { sort_column, sort_direction },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const ShowSalesReturnId = (id) => async () => {
	const CREDENTIALS = {
		url: `champ/sales_return/${id}`,
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export default salesReturnSlice.reducer;
