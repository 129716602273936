import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { Text } from 'atoms';
import { Rupee } from 'components';

function OrderJourneySummary({ data }) {
	return (
		<Row
			style={{
				marginBottom: '10px',
				marginLeft: '40px',
				border: '1px solid #CA5010',
				backgroundColor: '#FFF7F2',
				borderRadius: '4px',
			}}
		>
			{data?.days_to_deliver && (
				<Col
					span={4}
					style={{ backgroundColor: '#CA5010', textAlign: 'center', padding: '5px 0px' }}
				>
					<Text fontSize="14px" fontWeight="600" color="#fff">
						{data?.days_to_deliver} {`${data?.days_to_deliver > 1 ? 'days' : 'day'}`} to
						close invoice
					</Text>
				</Col>
			)}
			{data?.difference_amount > 0 && (
				<Col span={8} style={{ padding: '5px 0px' }}>
					<Text fontSize="14px" fontWeight="600" textAlign="center">
						Delivered <Rupee>{Number(data?.difference_amount).toFixed(2)}</Rupee> lesser
						than ordered value
					</Text>
				</Col>
			)}

			{data?.warehouse_due > 0 && (
				<Col span={4} style={{ padding: '5px 0px' }}>
					<Text fontSize="14px" fontWeight="600" color="#CA5010" textAlign="center">
						<Rupee>{Number(data?.warehouse_due).toFixed(2)}</Rupee> Warehouse Due
					</Text>
				</Col>
			)}
			{data?.pd_amount > 0 && (
				<Col span={4} style={{ padding: '5px 0px' }}>
					<Text fontSize="14px" fontWeight="600" color="#CA5010" textAlign="center">
						<Rupee>{Number(data?.pd_amount).toFixed(2)}</Rupee> Partial Delivery
					</Text>
				</Col>
			)}
		</Row>
	);
}

export default OrderJourneySummary;

OrderJourneySummary.propTypes = {
	data: PropTypes.object,
};
