import React from 'react';
import { Modal } from 'antd';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
	padding: 20px;
	.ant-modal-footer {
		border-top: 0px;
		text-align: center;
	}
	.ant-modal-header {
		border-bottom: 0px;
	}
	.ant-modal-body {
		padding: 12px;
	}
	.ant-modal-title {
		font-size: 16px;
		font-weight: 600;
	}
`;

function VerifyCompleteAlert({ toggleState, handleUploadModalOpen }) {
	const [modalVisible, setModalVisible] = toggleState;

	const handleOk = () => {
		setModalVisible(false);
		handleUploadModalOpen(true);
	};
	const handleCancel = () => {
		setModalVisible(false);
	};

	return (
		<StyledModal
			title="Please upload the documents to complete the verification process"
			visible={modalVisible}
			onOk={handleOk}
			onCancel={handleCancel}
			closable={false}
			okText="Upload"
			cancelText="Cancel"
			okButtonProps={{
				style: {
					backgroundColor: '#107C10',
					width: '180px',
					height: '40px',
					border: 'none',
					fontSize: '14px',
					marginTop: '20px',
				},
			}}
			cancelButtonProps={{
				style: {
					backgroundColor: 'transparent',
					width: '180px',
					height: '40px',
					border: '1px solid #107C10',
					color: '#107C10',
					fontSize: '14px',
					marginTop: '20px',
				},
			}}
		/>
	);
}
export default VerifyCompleteAlert;

VerifyCompleteAlert.propTypes = {
	toggleState: PropTypes.array,
	handleUploadModalOpen: PropTypes.bool,
};
