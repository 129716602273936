import React from 'react';
import { Modal, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { Button, Flex } from 'atoms';
import { PanelData } from 'components/common';
import { DateFormat } from 'components';

export default function AllocationDetailsModal({
	salesOrderList,
	showAllocationDetail,
	setShowAllocationDetail,
}) {
	const closeModal = () => {
		setShowAllocationDetail(false);
	};
	const {
		createdAt = '',
		vehicle_no = '',
		driver_name = '',
		vendor = '',
		driver_mobile = '',
		// driver_temperature = '',
		DeliveryBoy = '',
		// delivery_boy_temperature = '',
	} = salesOrderList;
	return (
		<Modal
			closable={false}
			visible={showAllocationDetail}
			title={null}
			footer={null}
			width="60%"
		>
			<Row gutter={16}>
				<Col mb="12px" xs={8}>
					<PanelData title="Vehicle No." value={vehicle_no} />
				</Col>
				<Col mb="12px" xs={8}>
					<PanelData
						title="Vehicle Allocated Date"
						value={vehicle_no ? <DateFormat date={createdAt} format="DMY" /> : ''}
					/>
				</Col>
				<Col mb="12px" xs={8}>
					<PanelData title="Driver Name" value={driver_name} />
				</Col>
				<Col mb="12px" xs={8}>
					<PanelData title="Driver Vendor" value={vendor} />
				</Col>
				<Col mb="12px" xs={8}>
					<PanelData title="Driver Phone Number" value={driver_mobile} />
				</Col>
				{/* <Col mb="12px" xs={8}>
					<PanelData title="Driver Body Temperature" value={driver_temperature} />
				</Col> */}
				<Col mb="12px" xs={8}>
					<PanelData
						title="Delivery Boy"
						value={DeliveryBoy ? DeliveryBoy.full_name : ''}
					/>
				</Col>
				{/* <Col mb="12px" xs={8}>
					<PanelData
						title="Delivery Boy Body Temperature"
						value={delivery_boy_temperature}
					/>
				</Col> */}
			</Row>
			<Flex justifyContent="flex-end">
				<Button width="42px" onClick={closeModal}>
					Ok
				</Button>
			</Flex>
		</Modal>
	);
}

AllocationDetailsModal.propTypes = {
	salesOrderList: PropTypes.object,
	showAllocationDetail: PropTypes.bool,
	setShowAllocationDetail: PropTypes.func,
};
