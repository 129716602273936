import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StatsWrap = styled.div`
	text-align: right;
`;
const Stats = styled.div`
	font-size: 1.25rem;
	font-weight: 400;
	line-height: 27px;
	color: ${(props) => props.theme.colors.black};
`;

const StatsName = styled.div`
	font-size: 0.8rem;
	font-weight: 300;
	line-height: 16px;
`;

export default function StatsDetails({ statCount = 0, statName = '' }) {
	return (
		<StatsWrap>
			<Stats>{statCount}</Stats>
			<StatsName>{statName}</StatsName>
		</StatsWrap>
	);
}

StatsDetails.propTypes = {
	statCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	statName: PropTypes.string,
};
