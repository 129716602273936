import React, { useEffect, useState } from 'react';
import { Table, Spin } from 'antd';
import { LoadingOutlined, PlusCircleTwoTone } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmpty, uniqBy } from 'lodash';
import styled from 'styled-components';
import { nanoid } from 'nanoid';
import { Box, Flex, Button, Text } from 'atoms';
import { SelectFilterOption } from './helpers';
import { LabelWrapper } from 'components/common';
import { SearchBox } from 'components';
import { getNextDayCollectionList, assignSalesMan, getSalesManList } from 'store/collectionSlice';
import { ColumnsList } from './TableConfigs';
import UnassignModal from 'components/modals/UnassignAlertModal';
import RemoveInvoiceListModal from 'components/modals/RemoveInvoiceListModal';
import { NDCModalPopup } from './NDCModal';
import { ErrorNotificationModal } from 'components/modals/ErrorNotificatonModal';

const StyledText = styled(Text)`
	font-size: 14px;
	font-weight: 400;
`;
const StyledSpan = styled.span`
	font-size: 14px;
	font-weight: 400;
	margin-right: 5px;
`;

const StyledButton = styled(Button)`
	width: 120px;
	height: 40px;
	border-radius: 4px;
`;
const SearchButton = styled(Button)`
	width: 170px;
	height: 38px;
	border-radius: 4px;
`;

const PAGE_OPTIONS = [50, 100, 150, 200];
export default function SalesmanAllocationDetail() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const [modalVisible, setModalVisible] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalErrorVisible, setModalErrorVisible] = useState({ errData: null, visible: false });
	const [showUnassignModal, setShowUnassignModal] = useState(false);
	const [totalData, setTotalData] = useState({ type: 'NotAsked' }); // data will be stored in this state
	const [rowSelect, setrowSelect] = useState([]);
	const [rowSelectKeys, setrowSelectKeys] = useState([]);
	const [salesManList, setsalesManList] = useState([]);
	const [rowData, setRowData] = useState({ type: 'NotAsked' });
	const [pageoffset, setOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchText, setSearchText] = useState('');
	const [pagelimit, setpagelimit] = useState(PAGE_OPTIONS[0]);
	const salesman = searchParams.get('salesperson_id');
	const salesperson_name = searchParams.get('salesperson_name');
	const collection_date = searchParams.get('collection_date');
	const brand = searchParams.get('brand_id');

	useEffect(() => {
		getInvoicesHandOverData();
	}, [dispatch, pageoffset, pagelimit]);

	const onLoadAsyncCall = (isDownload = false, offset = 0, limit = PAGE_OPTIONS[0]) => {
		let dayRange;
		const status = 'assigned';
		let filterBrands;
		dispatch(
			getNextDayCollectionList({
				status,
				dayRange,
				searchText,
				salesman,
				isDownload,
				filterBrands,
				offset,
				limit,
				collection_date,
			})
		).then((x) => {
			setRowData({
				type: 'Success',
				data: x?.data?.map((item) => ({
					...item,
					id_nanoid: nanoid(),
				})),
			});

			setTotalData((prev) => ({
				type: 'Success',
				data: {
					...x,
					data: uniqBy(
						[...(prev?.data?.data || []), ...(x?.data || [])],
						(z) => z.collection_invoice_id
					),
				},
			}));

			return true;
		});
	};

	const getInvoicesHandOverData = () => {
		setRowData({ type: 'Loading' });
		onLoadAsyncCall(false, pageoffset, pagelimit);
	};

	const handleSearch = () => {
		getInvoicesHandOverData();
	};

	const rowSelection = {
		onChange: (keys) => {
			setrowSelectKeys(keys); // added the rowselectkeys to reset the table selection.
			setrowSelect(totalData?.data?.data?.filter((x) => keys.includes(x.invoice_number)));
		},
		selectedRowKeys: rowSelectKeys,
		preserveSelectedRowKeys: true,
		getCheckboxProps: (record) => ({
			disabled: isEmpty(salesman),
			name: record.salesperson_id,
			defaultValue: rowSelectKeys.includes(record?.invoice_number),
		}),
	};

	const handleSubmit = (invoice_array, newSalesMan) => {
		dispatch(
			assignSalesMan({
				data: { invoice_array, invoice_ids: invoice_array?.map((x) => x.invoice_id) },
			})
		).then((res) => {
			setModalVisible(false);
			setrowSelect([]);
			setModalErrorVisible({ errData: { ...res, newSalesMan }, visible: true });
			resetRowSelect();
		});
	};

	const handleChange = (pagination) => {
		const pageoffsets = pagination.current * pagination.pageSize - pagination.pageSize;
		setOffset(pageoffsets);
		setpagelimit(pagination.pageSize);
		setCurrentPage(Math.ceil(pageoffsets / pagination.pageSize) + 1);
	};

	const resetRowSelect = () => {
		setrowSelect([]);
		setrowSelectKeys([]);
	};
	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleModalVisible = () => {
		setModalVisible(true);
		dispatch(
			getSalesManList({
				fc_id: [rowSelect[0]?.collection_fc_id],
				brand_id: [rowSelect[0]?.brand_id],
			})
		).then((x) => setsalesManList(SelectFilterOption(x)));
	};

	return (
		<>
			<Box bg="white">
				<Flex
					justifyContent="space-between"
					alignItems="center"
					padding="20px"
					boxShadow="0px 1px 3px 0px #00000026"
				>
					<Flex alignItems="center">
						<Box>
							<StyledText>Search</StyledText>
							<LabelWrapper>
								<SearchBox
									name="invoice_no_query"
									onSearch={(e) => {
										setSearchText(e?.target?.value?.toLowerCase());
									}}
									style={{
										width: '300px',
										margin: '5px 5px 7px 0px',
									}}
									placeholder="Search by Invoice No."
									label=""
								/>
							</LabelWrapper>
							<Text fontSize="12px">Please enter at least four character</Text>
						</Box>

						<SearchButton onClick={handleSearch} disabled={searchText?.length < 4}>
							Click here to Search
						</SearchButton>
					</Flex>

					<Box>
						<StyledButton
							variant="outlined"
							style={{ marginRight: '5px' }}
							disabled={rowSelect.length}
							onClick={() => {
								navigate(`/allocation?brand=${brand}&salesman=${salesman}`);
							}}
						>
							Add Inv
							<PlusCircleTwoTone
								twoToneColor="#CA5010"
								style={{ marginLeft: '5px' }}
							/>
						</StyledButton>

						<StyledButton
							style={{ backgroundColor: '#007FFF' }}
							onClick={showModal}
							disabled={rowSelect.length}
						>
							Download
						</StyledButton>
					</Box>
				</Flex>
				<Flex justifyContent="space-between" alignItems="center" padding="20px">
					{rowData?.data?.length ? (
						<StyledText>Total Allocated Invoices: {rowData?.data?.length}</StyledText>
					) : (
						<StyledText></StyledText>
					)}

					<Box>
						{rowSelect?.length > 0 && (
							<StyledSpan>
								Selected &nbsp;
								{rowSelect?.length > 10
									? rowSelect?.length
									: `0${rowSelect?.length}`}
								&nbsp;/&nbsp;{totalData?.data?.count}
							</StyledSpan>
						)}
						<StyledButton
							onClick={handleModalVisible}
							style={{ marginRight: '5px' }}
							disabled={rowSelect.length <= 0}
						>
							Re-Assign
						</StyledButton>
						<StyledButton
							onClick={() => {
								setShowUnassignModal(true);
							}}
							disabled={rowSelect.length <= 0}
						>
							Un-Assign
						</StyledButton>
					</Box>
				</Flex>
				<Box backgroundColor="#fff">
					{/* Table data component here */}
					{rowData?.data?.length > 0 && (
						<Box padding="5px 15px">
							{['Loading', 'NotAsked'].includes(totalData?.data) ? (
								<Flex height="300px" alignItems="center" justifyContent="center">
									Fetching Invoices...
								</Flex>
							) : (
								<Box className="invoice-handover">
									<Table
										rowKey="invoice_number"
										onChange={handleChange}
										rowSelection={rowSelection}
										loading={{
											spinning: ['Loading', 'NotAsked'].includes(
												rowData.type
											),
											indicator: (
												<Spin
													tip="Fetching Invoices..."
													indicator={<LoadingOutlined />}
												/>
											),
											size: 'large',
										}}
										columns={ColumnsList('assigned')}
										dataSource={rowData?.data}
										pagination={{
											total: totalData?.data?.count,
											current: currentPage,
											showSizeChanger: true,
											pageSizeOptions: PAGE_OPTIONS,
											pageSize: pagelimit,
										}}
										scroll={{
											x: 2500,
											y: 500,
										}}
									/>
								</Box>
							)}
						</Box>
					)}

					{/* Assign/Reassign ModalPopup */}
					{modalVisible && (
						<NDCModalPopup
							rowSelect={rowSelect}
							totalData={totalData?.data}
							toggleSalesMan={[salesManList, setsalesManList]}
							toggleModal={[modalVisible, setModalVisible]}
							assignstatus="assigned"
							handleSubmit={handleSubmit}
						/>
					)}

					{/* Error Notification Modal */}
					{modalErrorVisible.visible && (
						<ErrorNotificationModal
							modalErrorVisible={modalErrorVisible}
							setModalErrorVisible={setModalErrorVisible}
							onLoadAsyncCall={onLoadAsyncCall}
						/>
					)}
				</Box>
			</Box>
			<UnassignModal
				modalVisible={showUnassignModal}
				setModalVisible={setShowUnassignModal}
				rowSelect={rowSelect}
				getInvoicesHandOverData={getInvoicesHandOverData}
				setrowSelect={setrowSelect}
				resetRowSelect={resetRowSelect}
			/>
			<RemoveInvoiceListModal
				isModalOpen={isModalOpen}
				setIsModalOpen={setIsModalOpen}
				rowData={rowData}
				salesperson_name={salesperson_name}
				salesman_id={salesman}
				collection_date={collection_date}
				brand={brand}
			/>
		</>
	);
}
