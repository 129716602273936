import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getColor } from 'utils';

const StatusIndicatorDot = styled.div`
	width: ${(props) => props.size};
	height: ${(props) => props.size};
	border-radius: 50%;
	display: inline-block;
	margin-right: ${(props) => props.rightSpace};
	background-color: ${(props) => getColor(props)};
`;
export default function StatusIndicator({ status = '', size = '10px', rightSpace = '0' }) {
	return <StatusIndicatorDot status={status} size={size} rightSpace={rightSpace} />;
}

StatusIndicator.propTypes = {
	status: PropTypes.string,
	size: PropTypes.string,
	rightSpace: PropTypes.string,
};
