/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { Formik, FieldArray } from 'formik';
import { Form, SubmitButton, Switch, Select } from 'formik-antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isArray } from 'lodash';
import styled from 'styled-components';
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Debug } from 'components/forms/Debug';
import {
	getBrandOnSearchFilter,
	CreateUpdateFC,
	GetFcById,
	getPincodeListOnSearch,
	getPincodeOnLoad,
	toggleWmsCallback,
	// toggleWmsCallbackVerified,
} from 'store/masters';
import { getClientDataOnLoad, getClientListonSearch } from 'store/OnBoardSlice';
import { FormInput, CustomInput, Flex, Box, Text, AsyncSelect } from 'atoms';
import { toAsysnOption, SelectFilterLabelOptions, SelectOptions } from 'utils';
import { theme } from 'styles/theme';
import { FCInitialValues, FCFormSchema } from './helper';
import { LoadAsyncSelectComponent } from 'components/common';

export const StyledSubmitButton = styled(SubmitButton)`
	border: 0;
	height: 35px;
	background-color: ${(props) => props.theme.colors.primary};
	color: ${(props) => props.theme.colors.white};
	margin-top: 1.3rem;
	&:hover {
		background-color: ${(props) => props.theme.colors.primary};
		color: ${(props) => props.theme.colors.white};
	}
`;
const RedBoldSpan = styled.span`
	color: red;
	font-weight: bold;
`;

function FCForm({ isEditPage, fcId }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [formInitial, setFormInitial] = useState({});
	const [clientList, setClientList] = useState({ type: 'NotAsked' });
	const [companylist, setcompanylist] = useState({ type: 'NotAsked' });
	const [brandlist, setBrandList] = useState({ type: 'NotAsked' });
	const [wmsCallbackModal, setWmsCallbackModal] = useState(false);
	const [selectedCallbackIndex, setSelectedCallbackIndex] = useState(null);
	const [ppOrderCount, setPpOrderCount] = useState(null);

	useEffect(() => {
		setClientList({ type: 'Loading' });
		setcompanylist({ type: 'Loading' });
		setBrandList({ type: 'Loading' });
		const hasEditForm = isEditPage ? dispatch(GetFcById({ id: fcId })) : Promise.resolve();
		hasEditForm
			.then((resp) => {
				dispatch(getBrandOnSearchFilter({})).then((res) => {
					setBrandList({
						type: 'Success',
						data: toAsysnOption(res),
					});
				});

				dispatch(getPincodeOnLoad({ pinCodeId: resp?.pincode_id })).then((res) => {
					setcompanylist({
						type: 'Success',
						data: SelectOptions(res, 'pincode', 'id'),
					});
				});

				dispatch(getClientDataOnLoad({ clientId: resp?.client_id })).then((res) => {
					setClientList({
						type: 'Success',
						data: SelectOptions(res, 'name', 'id'),
					});
				});

				return resp;
			})
			.then((resp) => {
				const formValues = isEditPage ? FCInitialValues(resp, true) : FCInitialValues();
				setFormInitial(formValues);
				console.log(formValues, 'some');
			});
	}, [dispatch]);

	function handleSubmit(values, { resetForm, setSubmitting, setErrors }) {
		const payload = isEditPage ? { id: +fcId, ...values } : values;
		dispatch(CreateUpdateFC(payload, setErrors))
			.then(() => {
				setSubmitting(false);
				resetForm();
				navigate('/onboarding/fc');
			})
			.catch(() => {
				setSubmitting(false);
			});
	}

	const addBrands = (arrayHelpers, index) => {
		const newData = {
			id: undefined,
			non_picking: false,
			warehouse_status: false,
			block_rfc: false,
			uniqueKey: Math.random(),
			crate_tracking: false,
			wms_callback_enabled: false,
		};

		if (index === 0) {
			arrayHelpers.push(newData);
		} else {
			arrayHelpers.remove(index);
		}
	};

	const handleSelect = (val, name, setFieldValue) => setFieldValue(name, val?.value);

	const handleToggle = (val, idx) => {
		const toggleValue = val;
		const payload = {
			wms_callback_enabled: val,
			fc_id: +fcId,
			brand_id: idx?.values?.brands[idx?.id]?.id,
		};
		setSelectedCallbackIndex({ value: idx, params: payload });
		dispatch(toggleWmsCallback(payload))
			.then((res) => {
				if (!toggleValue) {
					const ppOrderCountRes = res?.pp_order_count;
					setPpOrderCount(ppOrderCountRes);
					if (ppOrderCountRes > 0) {
						setWmsCallbackModal(true);
					} else {
						setWmsCallbackModal(false);
						// dispatch(toggleWmsCallbackVerified(payload))
						// 	.then(() => {
						// 		setWmsCallbackModal(false);
						// 	})
						// 	.catch((err) => {
						// 		console.log('err ', err);
						// 	});
					}
				} else {
					setPpOrderCount(null);
					setSelectedCallbackIndex(null);
				}
			})
			.catch((err) => {
				console.log('err ', err);
			});
	};

	const hideModal = () => {
		setWmsCallbackModal(false);
		selectedCallbackIndex?.value?.fieldVal(
			`brands[${selectedCallbackIndex?.value?.id}].wms_callback_enabled`,
			true
		);
	};

	const handleConfirm = () => {
		setWmsCallbackModal(false);
		// dispatch(toggleWmsCallbackVerified(selectedCallbackIndex?.params))
		// 	.then(() => {
		// 		setWmsCallbackModal(false);
		// 	})
		// 	.catch((err) => {
		// 		console.log('err ', err);
		// 	});
	};

	return (
		<Formik
			initialValues={formInitial}
			validationSchema={FCFormSchema}
			enableReinitialize
			onSubmit={handleSubmit}
		>
			{({ values, isSubmitting, isValid, errors, dirty, setFieldValue }) => (
				<>
					<Form layout="vertical">
						<Row className="user__form" gutter={24} justify="space-between">
							<Col lg={6} sm={24}>
								<FormInput name="name" label="*FC Name">
									<CustomInput name="name" />
								</FormInput>
							</Col>

							<Col lg={6} sm={24}>
								<FormInput name="code" label="*FC Code">
									<CustomInput disabled={isEditPage} name="code" />
								</FormInput>
							</Col>

							<Col lg={6} sm={24}>
								<FormInput name="latitude" label="*Latitude">
									<CustomInput type="number" name="latitude" />
								</FormInput>
							</Col>

							<Col lg={6} sm={24}>
								<FormInput name="longitude" label="*Longitude">
									<CustomInput type="number" name="longitude" />
								</FormInput>
							</Col>
							<Col lg={6} sm={24}>
								<FormInput name="proximityRadius" label="*Proximity Radius">
									<CustomInput type="number" name="proximityRadius" />
								</FormInput>
							</Col>
							<Col lg={6} sm={24}>
								<FormInput name="address" label="*Address">
									<CustomInput name="address" />
								</FormInput>
							</Col>
							<Col lg={6} sm={24}>
								<FormInput name="pincode_id" label="*Pincode">
									<LoadAsyncSelectComponent options={companylist}>
										<AsyncSelect
											className="customDropdown"
											allowClear
											component="formikSelect"
											name="pincode_id"
											showSearch
											defaultValue={
												companylist?.data?.filter(
													(x) => x?.value === values?.pincode_id
												)[0] || null
											}
											fetchOptions={getPincodeListOnSearch}
											onChange={(val) =>
												handleSelect(val, 'pincode_id', setFieldValue)
											}
											placeholder="Select Pincode"
											transformOptions={(val) =>
												SelectOptions(val, 'pincode', 'id')
											}
											selectOptions={[...(companylist.data || [])]}
										/>
									</LoadAsyncSelectComponent>
								</FormInput>
							</Col>
							<Col lg={6} sm={24}>
								<FormInput name="gst_number" label="*GST Number">
									<CustomInput name="gst_number" />
								</FormInput>
							</Col>
							<Col lg={6} sm={24}>
								<FormInput name="client_id" label="*Client">
									<LoadAsyncSelectComponent options={clientList}>
										<AsyncSelect
											className="customDropdown"
											disabled={isEditPage}
											allowClear
											name="client_id"
											showSearch
											component="formikSelect"
											defaultValue={
												clientList?.data?.filter(
													(x) => x?.value === values?.client_id
												)[0] || null
											}
											fetchOptions={getClientListonSearch}
											onChange={(val) =>
												handleSelect(val, 'client_id', setFieldValue)
											}
											placeholder="Select Client"
											transformOptions={(val) =>
												SelectOptions(val, 'name', 'id')
											}
											selectOptions={[...(clientList.data || [])]}
										/>
									</LoadAsyncSelectComponent>
								</FormInput>
							</Col>
							<Col lg={6} sm={24}>
								<FormInput
									name="wms_warehouse_code"
									label="WMS Warehouse Code"
									onChange={(e) => {
										/**
										 * When WMS Warehouse Code is empty, we have to disable warehouse_status and make them as false
										 */
										if (e?.target?.value === '') {
											values?.brands?.forEach((item, index) => {
												setFieldValue(
													`brands.${index}.warehouse_status`,
													false
												);
											});
										}
									}}
								>
									<CustomInput name="wms_warehouse_code" />
								</FormInput>
							</Col>
							<Col lg={6} sm={24}></Col>
							<Col lg={6} sm={24}></Col>
							<Col lg={6} sm={24}></Col>
						</Row>
						<FieldArray
							name="brands"
							render={(arrayHelpers) => (
								<div>
									{values?.brands?.map((data, idx) => (
										<div key={idx}>
											<Row gutter={8} justify="flex-start">
												<Col lg={6} sm={24}>
													<FormInput
														name={`brands[${idx}].id`}
														label="Brand*"
													>
														<Select
															showSearch
															className="form__field"
															name={`brands[${idx}].id`}
															filterOption={SelectFilterLabelOptions}
															allowClear
															options={brandlist?.data || []}
														/>
													</FormInput>
												</Col>
												{data?.id && (
													<Col lg={8} sm={24}>
														<Flex alignItems="center">
															<FormInput
																className="form__switch"
																name={`brands[${idx}].non_picking`}
															>
																<h4> Non Picking</h4>
																<Switch
																	checked={
																		values?.brands[idx]
																			?.non_picking
																	}
																	name={`brands[${idx}].non_picking`}
																/>
															</FormInput>

															<Box pl="3rem">
																<FormInput
																	className="form__switch"
																	name={`brands[${idx}].warehouse_status`}
																>
																	<h4>WMS Integration Enabled</h4>
																	<Switch
																		disabled={
																			!values?.wms_warehouse_code
																		}
																		checked={
																			values?.brands[idx]
																				?.warehouse_status
																		}
																		name={`brands[${idx}].warehouse_status`}
																	/>
																</FormInput>
															</Box>
															<Box pl="3rem">
																<FormInput
																	className="form__switch"
																	name={`brands[${idx}].wms_callback_enabled`}
																>
																	<h4>WMS Callback Enabled</h4>
																	<Switch
																		checked={
																			values?.brands[idx]
																				?.wms_callback_enabled
																		}
																		name={`brands[${idx}].wms_callback_enabled`}
																		onChange={(val) =>
																			handleToggle(val, {
																				id: idx,
																				values,
																				fieldVal:
																					setFieldValue,
																			})
																		}
																	/>
																</FormInput>
															</Box>
															<Box pl="3rem">
																<FormInput
																	className="form__switch"
																	name={`brands[${idx}].block_rfc`}
																>
																	<h4>Block RFC Edit</h4>
																	<Switch
																		checked={
																			values?.brands[idx]
																				?.block_rfc
																		}
																		name={`brands[${idx}].block_rfc`}
																	/>
																</FormInput>
															</Box>
															<Box pl="3rem">
																<FormInput
																	className="form__switch"
																	name={`brands[${idx}].crate_tracking`}
																>
																	<h4>Crate Tracking</h4>
																	<Switch
																		checked={
																			values?.brands[idx]
																				?.crate_tracking
																		}
																		name={`brands[${idx}].crate_tracking`}
																	/>
																</FormInput>
															</Box>
															{values?.brands.length === idx + 1 && (
																<Tooltip
																	title="Add Brand"
																	onClick={() =>
																		addBrands(arrayHelpers, 0)
																	}
																>
																	<PlusCircleOutlined
																		style={{
																			fontSize: '150%',
																			margin: '30px',
																			cursor: 'pointFcBrander',
																			color: '#16697A',
																		}}
																	/>
																</Tooltip>
															)}
															{idx !== 0 && (
																<Tooltip title="Delete Brand">
																	<DeleteOutlined
																		onClick={() =>
																			addBrands(
																				arrayHelpers,
																				idx
																			)
																		}
																		style={{
																			fontSize: '150%',
																			margin: '30px',
																			cursor: 'pointer',
																			color: '#16697A',
																		}}
																	/>
																</Tooltip>
															)}
														</Flex>
													</Col>
												)}
											</Row>
										</div>
									))}
								</div>
							)}
						/>
						{!isArray(errors?.brands) && errors?.brands && (
							<Text style={{ color: theme.colors.error }}>
								Duplicate brand selection is not allowed
							</Text>
						)}
						{/* Submit Button */}
						<StyledSubmitButton
							disabled={isSubmitting || !isValid || !dirty}
							id="add-user-submit"
						>
							<Flex alignItems="center" justifyContent="center">
								<Box>{isEditPage ? 'Update' : 'Save'}</Box>
							</Flex>
						</StyledSubmitButton>
						<Debug />
					</Form>
					<Modal
						title="Warning"
						visible={wmsCallbackModal}
						onOk={handleConfirm}
						onCancel={hideModal}
						okText="Yes"
						cancelText="No"
					>
						<p>
							There are <RedBoldSpan>{ppOrderCount} orders </RedBoldSpan>for which
							picking is not completed yet, do you want to continue?
						</p>
					</Modal>
				</>
			)}
		</Formik>
	);
}

FCForm.propTypes = {
	isEditPage: PropTypes.bool,
	fcId: PropTypes.string,
};

export default FCForm;
