import React, { useMemo } from 'react';
import { debounce } from 'lodash';
import styled from 'styled-components';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import { Text, Box } from 'atoms';
import { SearchIcons } from 'utils';

export default function SearchBox({
	onSearch,
	isMandate = false,
	label = 'Search',
	placeholder = 'Search',
	style,
	...rest
}) {
	const handleSearchChange = useMemo(
		() =>
			debounce((event) => {
				onSearch(event);
			}, 1000),
		[onSearch]
	);

	return (
		<Box margin="0 0 10px" style={style}>
			<Text fontWeight="600" fontSize="14px" lineHeight="24px">
				{label}
				{isMandate && (
					<Box as="span" color="red">
						*
					</Box>
				)}
			</Text>
			<Box>
				<InputIcon>
					<SearchIcons />
				</InputIcon>
				<InputWrapper
					{...rest}
					onChange={handleSearchChange}
					placeholder={placeholder}
					allowClear
				/>
			</Box>
		</Box>
	);
}

export const InputIcon = styled(Box)`
	position: absolute;
	top: 50%;
	z-index: 2;
	transform: translateY(-50%);
	left: 10px;
`;

export const InputWrapper = styled(Input)`
	background: #faf9f8;
	border-radius: 2px;
	padding-left: 50px;
	min-height: 35px;
	max-width: 390px;
	input {
		background: #faf9f8;
		border-radius: 2px;
	}
	&:focus,
	&:hover {
		outline: none;
		box-shadow: none;
		border-color: transparent !important;
	}
`;

SearchBox.propTypes = {
	onSearch: PropTypes.func,
	placeholder: PropTypes.string,
	isMandate: PropTypes.bool,
	label: PropTypes.string,
	style: PropTypes.object,
};
