import { isEmpty } from 'lodash';
// import moment from 'moment';

export const CommonFCBrandsQueries = [
	{
		query: 'brands',
		transformFn: (v) => (!isEmpty(v) ? v.split(',').map((x) => +x) : []),
		value: '',
	},
	{
		query: 'fcs',
		transformFn: (v) => (!isEmpty(v) ? v.split(',').map((x) => +x) : []),
		value: '',
	},
	{
		query: 'bnpl_enabled',
		transformFn: (v) => (!isEmpty(v) ? v.split(',').map((x) => +x) : []),
		value: '',
	},
];

export const returnToFCQueries = [
	{ query: 'vehicle_no', transformFn: (v) => v, value: '' },
	{ query: 'driver_name', transformFn: (v) => v, value: '' },
	{ query: 'return_verification_status', transformFn: (v) => v, value: '' },
	{ query: 'return_status', transformFn: (v) => v, value: '' },
	{ query: 'date__gte', transformFn: (v) => v, value: '' },
	{ query: 'date__lte', transformFn: (v) => v, value: '' },
	{
		query: 'delivery_boy',
		transformFn: (v) => v,
		value: '',
	},
	...CommonFCBrandsQueries,
];

export const DAQueries = [
	{ query: 'qinvoice', transformFn: (v) => (v ? v.replaceAll('*', '') : v), value: '' },
	{
		query: 'salesmen',
		transformFn: (v) => (!isEmpty(v) ? v.split(',').map((x) => +x) : []),
		value: '',
	},
	{
		query: 'stores',
		transformFn: (v) => (!isEmpty(v) ? v.split(',').map((x) => +x) : []),
		value: '',
	},
	{ query: 'tag__like', transformFn: (v) => v, value: '' },
	{ query: 'gst_number', transformFn: (v) => v, value: '' },
	{ query: 'statuses', transformFn: (v) => v.split(',').map((x) => x), value: ['PU', 'RD'] },
	...CommonFCBrandsQueries,
];

export const UploadQueries = [
	{ query: 'query', transformFn: (v) => (v ? v.replaceAll('*', '') : v), value: '' },
	{ query: 'fileType', transformFn: (v) => v, value: '' },
	{ query: 'statuses', transformFn: (v) => v.split(',').map((x) => x), value: '' },

	...CommonFCBrandsQueries,
];

export const chequeBounceQueries = [
	{ query: 'statuses', transformFn: (v) => v.split(',').map((x) => x), value: [] },

	...CommonFCBrandsQueries,
];

export const DAListQueries = [
	{ query: 'query', transformFn: (v) => (v ? v.replaceAll('*', '') : v), value: '' },
	{ query: 'date', transformFn: (v) => v, value: '' },
	...CommonFCBrandsQueries,
];

export const SalesOrderQueries = [
	{ query: 'query', transformFn: (v) => (v ? v.replaceAll('*', '') : v), value: '' },
	{ query: 'fields', transformFn: (v) => v.split(',').map((x) => x), value: '' },
	{ query: 'gst_number', transformFn: (v) => v, value: '' },
	{ query: 'invoice_date__gte', transformFn: (v) => v, value: '' },
	{ query: 'invoice_date__lte', transformFn: (v) => v, value: '' },
	{
		query: 'stores',
		transformFn: (v) => (!isEmpty(v) ? v.split(',').map((x) => +x) : []),
		value: '',
	},
	{ query: 'statuses', transformFn: (v) => v.split(',').map((x) => x), value: '' },
	{
		query: 'queryInvoice',
		transformFn: (v) => (v ? v.replaceAll('*', '') : v),
		value: '',
	},
	{ query: 'bnpl_enabled', transformFn: (v) => v, value: '' },
	...CommonFCBrandsQueries,
];

// const dateformat = 'YYYY-MM-DD';
// const TODAY = moment().format(dateformat);
// const LAST5DAY = moment().subtract(4, 'day').format(dateformat);

export const SalesOrderBlockedQueries = [
	{ query: 'query', transformFn: (v) => (v ? v.replaceAll('*', '') : v), value: '' },
	{ query: 'fields', transformFn: (v) => v.split(',').map((x) => x), value: '' },
	{ query: 'statuses', transformFn: (v) => v.split(',').map((x) => x), value: ['BL'] },
	{ query: 'page', transformFn: (v) => v, value: 'UB' },

	// { query: 'invoice_date__gte', transformFn: (v) => v, value: LAST5DAY },
	// { query: 'invoice_date__lte', transformFn: (v) => v, value: TODAY },
	{
		query: 'queryInvoice',
		transformFn: (v) => (v ? v.replaceAll('*', '') : v),
		value: '',
	},
	...CommonFCBrandsQueries,
];

export const ReturnsLogsQueries = [
	{ query: 'invoice_date__gte', transformFn: (v) => v, value: '' },
	{ query: 'invoice_date__lte', transformFn: (v) => v, value: '' },
	{ query: 'statuses', transformFn: (v) => v.split(',').map((x) => x), value: '' },
	{ query: 'query', transformFn: (v) => (v ? v.replaceAll('*', '') : v), value: '' },
	{ query: 'fileType', transformFn: (v) => v, value: '' },
	...CommonFCBrandsQueries,
];

export const MissingInvoicesQueries = [
	...SalesOrderQueries,
	...CommonFCBrandsQueries,
	{
		query: 'statuses',
		transformFn: (v) => v.split(',').map((x) => x),
		value: ['OP', 'OC', 'PP'],
	},
	{
		query: 'sort_column',
		transformFn: (v) => v,
		value: 'createdAt',
	},
];

export const PincodeQueries = [
	{ query: 'query', transformFn: (v) => (v ? v.replaceAll('*', '') : v), value: '' },
	{ query: 'fields', transformFn: (v) => v.split(',').map((x) => x), value: ['pincode'] },
];

export const StoreCategoryQueries = [
	{ query: 'query', transformFn: (v) => (v ? v.replaceAll('*', '') : v), value: '' },
	{
		query: 'fields',
		transformFn: (v) => v.split(',').map((x) => x),
		value: ['retail_category'],
	},
	{ query: 'retailer_class', transformFn: (v) => v, value: '' },
];

export const CommonQueries = [
	{ query: 'query', transformFn: (v) => (v ? v.replaceAll('*', '') : v), value: '' },
	{ query: 'fields', transformFn: (v) => v.split(',').map((x) => x), value: ['name'] },
];

export const CompanyClientQueries = [
	{ query: 'query', transformFn: (v) => (v ? v.replaceAll('*', '') : v), value: '' },
	{ query: 'fields', transformFn: (v) => v.split(',').map((x) => x), value: '' },
];

export const BankQueries = [
	{ query: 'query', transformFn: (v) => (v ? v.replaceAll('*', '') : v), value: '' },
	{ query: 'fields', transformFn: (v) => v, value: '' },
];

export const FCQueries = [
	{ query: 'fcIds', transformFn: (v) => +v, value: '' },
	{
		query: 'brand_ids',
		transformFn: (v) => (!isEmpty(v) ? v.split(',').map((x) => +x) : []),
		value: '',
	},
];

export const StoreListQueries = [
	{ query: 'query', transformFn: (v) => (v ? v.replaceAll('*', '') : v), value: '' },
	{ query: 'store_verify_status', transformFn: (v) => +v, value: '' },
	{ query: 'fields', transformFn: (v) => v, value: '' },
	{
		query: 'fc_ids',
		transformFn: (v) => (!isEmpty(v) ? v.split(',').map((x) => +x) : []),
		value: '',
	},
	{
		query: 'stores',
		transformFn: (v) => (!isEmpty(v) ? v.split(',').map((x) => +x) : []),
		value: '',
	},
	{
		query: 'client_ids',
		transformFn: (v) => (!isEmpty(v) ? v.split(',').map((x) => +x) : []),
		value: '',
	},
];

export const UserQueries = [
	{ query: 'groups', transformFn: (v) => v.split(',').map((x) => +x), value: '' },
	{ query: 'fcs', transformFn: (v) => v.split(',').map((x) => +x), value: '' },
];

export const CollectionQueries = [
	{ query: 'query', transformFn: (v) => (v ? v.replaceAll('*', '') : v), value: '' },
	{
		query: 'user_stores',
		transformFn: (v) => (!isEmpty(v) ? v.split(',').map((x) => +x) : []),
		value: '',
	},
	{ query: 'date__gte', transformFn: (v) => v, value: '' },
	{ query: 'date__lte', transformFn: (v) => v, value: '' },
	...CommonFCBrandsQueries,
];

export const SalesmanQueries = [
	{ query: 'mobile_q', transformFn: (v) => (v ? v.replaceAll('*', '') : v), value: '' },
	{
		query: 'salesmen',
		transformFn: (v) => (!isEmpty(v) ? v.split(',').map((x) => +x) : []),
		value: '',
	},
	...CommonFCBrandsQueries,
];

export const DownloadQueries = [
	{
		query: 'report_type',
		transformFn: (v) => v,
		value: '',
	},
	...CommonFCBrandsQueries,
];
export const chequeSettlement = [
	{ query: 'search_info', transformFn: (v) => (v ? v.replaceAll('*', '') : v), value: '' },
];
