/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal, Select } from 'antd';
import PropTypes from 'prop-types';
import { isEmpty, uniqBy } from 'lodash';
import { Box, Button, Flex, AsyncSelect } from 'atoms';
import { getSalesManList } from 'store/collectionSlice';
import {
	SelectFilterLabelOptions,
	getdayOptions,
	SelectFilterOption,
	takeTotalNumber,
} from './helpers';
import { ModalLabel } from './styles';

export function NDCModalPopup({
	toggleModal = [],
	rowSelect = [],
	totalData = {},
	assignstatus = '',
	toggleSalesMan = [],
	handleSubmit = () => null,
}) {
	const [modalVisible, setModalVisible] = toggleModal;
	const [salesman, setsalesMan] = useState(undefined);
	const [collectionDay, setcollectionDay] = useState(getdayOptions()[0]?.value);
	const [optionList, setOptionList] = useState([]);

	useEffect(() => {
		const defaultSalesMan = {
			name: rowSelect[0]?.salesperson_name,
			id: rowSelect[0]?.salesperson_id,
			mobile: rowSelect[0]?.salesperson_phone_number,
		};

		setOptionList(toggleSalesMan[0]);
		setsalesMan(SelectFilterOption([defaultSalesMan])[0]);
	}, [toggleSalesMan]);

	const funcParams =
		assignstatus === 'assigned'
			? ['assigned_date', 'assigned_weekday_number', 'assigned_weekday_name']
			: ['collection_date', 'collection_weekday_number', 'collection_weekday_name'];

	const takeInvoiceCountByDays = takeTotalNumber(rowSelect, funcParams[0], funcParams[1]);
	const takeInvoiceCountByTotal = takeTotalNumber(
		totalData?.data?.filter((x) => x?.salesperson_id === rowSelect[0]?.salesperson_id),
		funcParams[0],
		funcParams[1]
	);

	const onSubmit = () => {
		const invoice_array = rowSelect.map((x) => ({
			invoice_id: x.collection_invoice_id,
			invoice_no: x.invoice_number,
			new_salesman_id: salesman?.value,
			store_id: x?.store_id,
			new_collection_date: collectionDay,
			re_assign_flag: assignstatus === 'assigned' || undefined,
			brand_id: x?.brand_id || undefined,
			fc_id: x?.collection_fc_id || undefined,
		}));

		return handleSubmit(invoice_array, salesman);
	};

	return (
		<Modal
			centered
			destroyOnClose
			onCancel={() => setModalVisible(false)}
			footer={null}
			width="60%"
			visible={modalVisible}
			className="nextDayModalPopup"
		>
			<Box>
				<ModalLabel padding="20px" borderBottom="1px solid #EDEBE9">
					<Box marginRight="15px">Invoice Assign:</Box>
					<Box>
						{rowSelect[0]?.salesperson_name || ''} -{' '}
						<Box fontWeight="400" as="span">
							{rowSelect[0]?.salesperson_phone_number || ''}
						</Box>
					</Box>
				</ModalLabel>
				<Flex padding="20px" borderBottom="1px solid #EDEBE9">
					<ModalLabel marginRight="15px">Selected Invoice:</ModalLabel>
					<Flex flexWrap="wrap" width="100%">
						{!isEmpty(rowSelect) &&
							uniqBy(rowSelect, (x) => x[funcParams[0]]).map((x) => {
								const countFormat = `${x[funcParams[0]]}_${x[funcParams[1]]}`;
								return (
									<Box
										margin="0 10px 10px"
										key={x?.collection_invoice_id}
										flex="0 0 25%"
										borderRight="1px solid #EDEBE9"
									>
										<Box>
											{x[funcParams[2]] || '-'} - {x[funcParams[0]] || '-'}
										</Box>
										<Box>
											Inv Count:
											<Box marginLeft="5px" as="span" fontWeight="600">
												{takeInvoiceCountByDays[countFormat]}/
												{takeInvoiceCountByTotal[countFormat]}
											</Box>
										</Box>
									</Box>
								);
							})}
					</Flex>
				</Flex>
				<Flex padding="20px">
					<Box marginRight="30px">
						<ModalLabel marginBottom="5px">Assign To</ModalLabel>
						<AsyncSelect
							name="assignedTo"
							style={{ width: '260px' }}
							showSearch
							selectOptions={optionList}
							fetchOptions={getSalesManList}
							onChange={(val) => setsalesMan(val)}
							apiParams={{
								fc_id: [rowSelect[0]?.collection_fc_id],
								brand_id: [rowSelect[0]?.brand_id],
							}}
							value={salesman}
							transformOptions={(val) => SelectFilterOption(val, rowSelect)}
							placeholder="Search SalesMan"
						/>
					</Box>
					<Box>
						<ModalLabel marginBottom="5px">Collectable Date</ModalLabel>
						<Select
							name="collectableDate"
							showSearch
							style={{ width: '220px' }}
							options={getdayOptions()}
							onChange={(val) => setcollectionDay(val)}
							value={collectionDay}
							filterOption={SelectFilterLabelOptions}
							placeholder="Search By"
						/>
					</Box>
				</Flex>
				<Box padding="20px" textAlign="right">
					<Button backgroundColor="transparent" onClick={() => setModalVisible(false)}>
						Cancel
					</Button>
					<Button
						backgroundColor="#CA5010"
						border="1px solid #FFFFFF"
						onClick={onSubmit}
						disabled={isEmpty(salesman)}
					>
						Submit
					</Button>
				</Box>
			</Box>
		</Modal>
	);
}

NDCModalPopup.propTypes = {
	toggleModal: PropTypes.array,
	rowSelect: PropTypes.array,
	toggleSalesMan: PropTypes.array,
	totalData: PropTypes.object,
	assignstatus: PropTypes.string,
	handleSubmit: PropTypes.func,
};
