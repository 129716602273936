/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { Collapse, message } from 'antd';
import { Formik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Box } from 'atoms';
import {
	getOrdersById,
	getReturnToFCReasonListNew,
	orderComplete,
} from 'store/fulfilment/returnToFCSlice';
import RTCInvoice from './RTCInvoice';
import { RTCDeliveryForm, RTCCollectionForm, Debug } from 'components/forms';
import { ScrollComponent } from 'components/common';
import { getBankOnSearchFilter } from 'store/masters';
import { SelectOptions } from 'utils';
import {
	RTCSetInitialValues,
	RTCInitialValues,
	RTCFormSchema,
	RTCRefinedPayload,
	RTCUpdateOrderDetails,
} from '../helper';

const { Panel } = Collapse;
const returnAmount = (data) => data?.reduce((a, c) => parseFloat(c?.amount || 0) + a, 0);

export default function RTCDetailPage() {
	const { id: orderId } = useParams();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const dispatch = useDispatch();
	const { orderId: invoiceId } = useSelector((s) => s?.returnToFC);
	const _deliveryRef = useRef();
	const _invoiceRef = useRef();
	// const searchParams = new URLSearchParams(locationRoutes.search);
	const searchVal = searchParams.get('filter') ?? '';
	const [data, setData] = useState({ type: 'NotAsked' });
	const [bankList, setBankList] = useState([]);
	const [returnReason, setReturnReason] = useState([]);
	const [mismatchReason, setmismatchReason] = useState([]);
	const [initialValues, setFormInitial] = useState(RTCInitialValues);

	useEffect(() => {
		setData({ type: 'Loading' });
		dispatch(getOrdersById(orderId)).then((res) => {
			const { invoice, order, orderDetail } = res;

			const returnfilter = {
				is_attempt: order?.status === 'DA',
				is_cancel: order?.status === 'CA',
				is_partial: order?.status === 'PD',
				is_return: false,
			};

			const mismatchFilter = { is_mismatch: true, user_fcs_only: true };

			Promise.all([
				dispatch(getBankOnSearchFilter({ limit: 100 })),
				dispatch(getReturnToFCReasonListNew({ ...returnfilter })),
				dispatch(getReturnToFCReasonListNew({ ...mismatchFilter })),
			]).then(([bankRes, returnReasonList, mismatchlist]) => {
				setBankList(bankRes);
				setReturnReason(SelectOptions(returnReasonList, 'name', 'id'));
				setmismatchReason(SelectOptions(mismatchlist, 'name', 'id'));
				setFormInitial(RTCSetInitialValues(invoice, order, orderDetail));
				setData({ type: 'Success', data: res || {} });
			});
		});
	}, [dispatch, orderId]);

	useEffect(() => {
		if (data.type === 'Success') {
			const sections = searchParams.get('section') || 'delivery';
			if (sections === 'delivery') {
				_deliveryRef.current.scrollIntoView({ behavior: 'smooth' });
			} else {
				_invoiceRef.current.scrollIntoView({ behavior: 'smooth' });
			}
		}
	}, [data]);

	const handleSubmit = (values, { setSubmitting, setErrors }) => {
		setSubmitting(true);

		const enteredAmount =
			(parseFloat(values?.cash) || 0) +
			returnAmount(values?.cheque) +
			returnAmount(values?.upi) +
			returnAmount(values?.neft);

		console.log(enteredAmount, values);

		if (enteredAmount > values?.delivered_amount) {
			setSubmitting(false);
			message.info('Entered Amount is greater than Delivered Amount');
			return false;
		}

		if (
			enteredAmount === values?.delivered_amount &&
			['Bill Back', 'Voucher'].includes(values?.invoice_status)
		) {
			setSubmitting(false);
			message.info(
				'Please select Invoice settled as entered Amount is equal to delivery Amount'
			);
			return false;
		}

		if (
			enteredAmount >= values?.delivered_amount &&
			values?.invoice_status === 'No Bill Back' &&
			isEmpty(values?.remarks)
		) {
			setSubmitting(false);
			setErrors({ remarks: 'Remarks is required' });
			return false;
		}

		return dispatch(orderComplete(RTCRefinedPayload(values), orderId, setErrors))
			.then((r) => {
				if (r.data) {
					message.info('Order Data updated Successfully');
					navigate(`/logistics-management/return-to-fc-new/${+invoiceId}/show`);
				}
			})
			.catch((e) => {
				if ('response' in e && e?.response?.status === 422) {
					message.error('Error while updating the order data');
					// const errData = e?.response?.data?.error;

					// const getDetailsErrors = Object?.keys(errData).reduce(
					// 	(acc, cur) => {
					// 		if (cur?.includes('details')) {
					// 			const spitDetails = cur?.split('.');
					// 			acc[spitDetails[1]] = {
					// 				// [spitDetails[2]]: 'Code is required',
					// 				delivered_qty: 'code',
					// 			};
					// 		}
					// 		return acc;
					// 	},
					// 	[...new Array(values?.orderDetails.length)].map(() => ({}))
					// );

					// setErrors({ orderDetails: getDetailsErrors });
				}
			});
	};

	const handleSearch = (query, setFieldValue, values) => {
		// this logic is for making orderdetails search in sync
		// if (values?.order_status === 'PD') {
		// 	const getOrderId = values?.orderDetails?.map((y) => y?.id);
		// 	const setValueOnWholeObj = values?.searchOrderDetails?.map((x, index) => {
		// 		if (getOrderId.includes(x.id)) {
		// 			return values?.orderDetails[index];
		// 		}
		// 		return x;
		// 	});

		// 	const filterValues = setValueOnWholeObj?.filter(
		// 		(x) =>
		// 			x?.product_name?.toLowerCase()?.includes(query?.toLowerCase()) && query !== ''
		// 	);

		// 	setFieldValue('orderDetails', query === '' ? setValueOnWholeObj : filterValues);
		// 	setFieldValue('searchOrderDetails', setValueOnWholeObj || filterValues);
		// } else {
		const filterValues = values?.searchOrderDetails?.filter((x) =>
			x?.product_name?.toLowerCase()?.includes(query?.toLowerCase())
		);

		setFieldValue('orderDetails', filterValues);
		// }
	};

	const handleOrderStatus = async (val, setFieldValue, hascancelReason) => {
		if (hascancelReason) {
			setFieldValue('reason', hascancelReason);
			setFieldValue('orderDetails', RTCUpdateOrderDetails('CA', data?.data, hascancelReason));
			return true;
		}

		if (val === 'CA') {
			const returnfilter = {
				is_attempt: false,
				is_cancel: true,
				is_partial: false,
				is_return: false,
			};

			dispatch(getReturnToFCReasonListNew({ ...returnfilter })).then((res) =>
				setReturnReason(SelectOptions(res, 'name', 'id'))
			);
		} else {
			setFieldValue('reason', undefined);
		}
		setFieldValue('order_status', val);
		setFieldValue('orderDetails', RTCUpdateOrderDetails(val, data?.data)); // this is for resetting value on delivery status change.
		return true;
	};

	if (data.type === 'Loading' || data.type === 'NotAsked') {
		return (
			<Box className="RTCDetailsPage" background="#F5F5F5" textAlign="center">
				Loading...
			</Box>
		);
	}

	return (
		<Box className="RTCDetailsPage" background="#F5F5F5">
			<Box padding="20px 30px">
				<Formik
					initialValues={initialValues}
					validationSchema={RTCFormSchema}
					enableReinitialize
					onSubmit={handleSubmit}
				>
					{({ values, setFieldValue, errors }) => (
						<Form layout="vertical">
							<Box marginBottom="30px">
								<Collapse
									bordered={false}
									defaultActiveKey={['invoice_details']}
									expandIconPosition="end"
								>
									<Panel header="Invoice Details" key="invoice_details">
										<RTCInvoice
											invoice={data?.data?.invoice}
											order={data?.data?.order}
										/>
									</Panel>
								</Collapse>
							</Box>
							<ScrollComponent ref={_deliveryRef}>
								<Box marginBottom="30px">
									<Collapse
										bordered={false}
										defaultActiveKey={['delivery_details']}
										expandIconPosition="end"
									>
										<Panel header="Delivery Details" key="delivery_details">
											<RTCDeliveryForm
												values={values}
												returnReason={returnReason}
												mismatchReason={mismatchReason}
												setFieldValue={setFieldValue}
												handleOrderStatus={handleOrderStatus}
												errors={errors}
												handleSearch={handleSearch}
											/>
										</Panel>
									</Collapse>
								</Box>
							</ScrollComponent>
							<ScrollComponent ref={_invoiceRef}>
								<Box marginBottom="30px">
									<Collapse
										bordered={false}
										defaultActiveKey={['collection_details']}
										expandIconPosition="end"
									>
										<Panel
											header="Collection Details:"
											key="collection_details"
										>
											<RTCCollectionForm
												values={values}
												bankList={SelectOptions(bankList, 'name', 'id')}
												setFieldValue={setFieldValue}
												errors={errors}
												searchVal={searchVal}
											/>
										</Panel>
									</Collapse>
								</Box>
							</ScrollComponent>

							<SubmitButton disabled={values?.return_verified_by}>
								Update
							</SubmitButton>
							<Debug />
						</Form>
					)}
				</Formik>
			</Box>
		</Box>
	);
}
