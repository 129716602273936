import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Text, FormInput, CustomInput, Box, AsyncSelect } from 'atoms';
import { getUserTypes, addUser } from 'store/userSlice';
import { LoadAsyncSelectComponent } from 'components/common';
import { getUserById, getBrandsSearchList } from 'store/masters';
import {
	unScReplace,
	SelectFilterOptions,
	transformFCBrandsOptions,
	handleFCBrands,
	fcBrandsDefaultValue,
} from 'utils';
import { FormSchema } from './Validations';
import { StyledSubmitButton, UserInitialValues } from './helper';
import { Debug } from 'components/forms';

const { Option } = Select;

// const transformOptions = (data) =>
// 	data?.rows?.map((x) => ({
// 		label: `${x?.code}: ${x?.name || ''}`,
// 		value: x.id,
// 		options: x?.fc?.map((y) => ({ ...y, brands_id: x.id })),
// 	}));

// const transformOptions = (data) =>
// 	uniqBy(
// 		data?.rows
// 			?.map((x) =>
// 				x?.fc?.map((y) => ({
// 					value: `${x.id}_${y.id}`,
// 					label: `${y?.code || ''}: ${x?.name || ''}`,
// 				}))
// 			)
// 			.flat(),
// 		(x) => x.value
// 	);

function AddUserForm() {
	const { id: userId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { userTypes = [] } = useSelector((state) => state.user);
	const [formInitial, setFormInitial] = useState({});
	const [brandsList, setbrandsList] = useState({ type: 'NotAsked' });
	const editMode = pathname.includes('edit');

	useEffect(() => {
		setbrandsList({ type: 'Loading' });

		const hasEditpage = editMode ? dispatch(getUserById({ userId })) : Promise.resolve({});

		hasEditpage
			.then((resp) => {
				dispatch(getUserTypes());
				return resp;
			})
			.then((res) => {
				dispatch(getBrandsSearchList({ limit: 100 })).then((brandList) => {
					setbrandsList({ type: 'Success', data: transformFCBrandsOptions(brandList) });
					setFormInitial(
						editMode
							? UserInitialValues(res[0], true, transformFCBrandsOptions(brandList))
							: UserInitialValues()
					);
				});
			});
	}, [dispatch, editMode, userId]);

	function handleSubmit({ fcsOptions, ...rest }, { setErrors, resetForm, setSubmitting }) {
		const { brands, fcs } = handleFCBrands(rest?.brands);
		const postValues = {
			...rest,
			fcs,
			brands,
			...(editMode ? { user_id: userId } : {}),
		};

		dispatch(addUser(postValues, setErrors))
			.then(() => {
				setSubmitting(false);
				resetForm();
				navigate('/onboarding/user');
			})
			.catch(() => {
				setSubmitting(false);
			});
	}

	console.log(brandsList);

	return (
		<Formik
			initialValues={formInitial}
			validationSchema={FormSchema(editMode)}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, setFieldValue, isSubmitting, isValid, dirty }) => (
				<Form layout="vertical">
					<Text fontSize="14px" fontWeight="700">
						{editMode ? 'Edit' : 'Add'} User Details
					</Text>
					<Row gutter={16} justify="space-between">
						<Col lg={8} span={24}>
							<FormInput name="user_types" label="*User Type">
								<Select
									showSearch
									className="customDropdown"
									name="user_types"
									placeholder="User Type"
									filterOption={SelectFilterOptions}
									allowClear
								>
									{userTypes?.rows?.map((data) => (
										<Option key={data?.id} value={data?.id}>
											{unScReplace(data?.name)}
										</Option>
									))}
								</Select>
							</FormInput>
						</Col>
					</Row>
					<Text fontSize="14px" fontWeight="300">
						Personal Details
					</Text>
					<Row className="user__form" gutter={16} justify="flex-start">
						<Col lg={8} sm={24}>
							<FormInput
								name="first_name"
								label="*First Name"
								className="test-first-name"
							>
								<CustomInput name="first_name" />
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput
								name="last_name"
								label="*Last Name"
								className="test-last-name"
							>
								<CustomInput name="last_name" />
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput
								name="mobile_no"
								label="*Phone Number"
								className="test-mobile-number"
							>
								<CustomInput name="mobile_no" />
							</FormInput>
						</Col>
					</Row>

					<Text fontSize="14px" fontWeight="300">
						Work Details
					</Text>
					<Row className="user__form" gutter={16}>
						{values?.user_types !== 12 && (
							<Col lg={8} sm={24}>
								<FormInput name="email" label="*Email" className="test-email">
									<CustomInput name="email" />
								</FormInput>
							</Col>
						)}
						{!editMode && values.user_types !== 12 && (
							<Col lg={8} sm={24}>
								<FormInput
									name="password"
									label="*Password"
									className="test-password"
								>
									<Input.Password type="password" name="password" />
								</FormInput>
							</Col>
						)}
					</Row>
					<Row gutter={16}>
						<Col lg={8}>
							{/* <FormInput name="fcs" label="*FC:Brands(s)">
								<LoadAsyncSelectComponent options={brandsList}>
									<AsyncSelect
										mode="multiple"
										showSearch
										name="brands"
										component="formikSelect"
										onChange={(val) => {
											setFieldValue('brands', val);
											// setFieldValue(
											// 	'fcsOptions',
											// 	uniqBy(
											// 		[...values.fcsOptions, ...options],
											// 		(x) => x.value
											// 	)
											// );
										}}
										placeholder="Select Brands"
										defaultValue={
											brandsList?.data?.filter((x) =>
												values?.brands?.includes(x?.value)
											) || undefined
										}
										selectOptions={[...(brandsList?.data || [])]}
										fetchOptions={getBrandsSearchList}
										transformOptions={(val) => transformOptions(val)}
									/>
								</LoadAsyncSelectComponent>
							</FormInput> */}
							<FormInput name="brands" label="FC:Brands(s)">
								<LoadAsyncSelectComponent
									options={brandsList}
									hasLoadingValues={editMode ? isEmpty(values?.brands) : false}
								>
									<AsyncSelect
										allowClear
										name="brands"
										mode="multiple"
										component="formikSelect"
										fetchOptions={getBrandsSearchList}
										transformOptions={(val) => transformFCBrandsOptions(val)}
										placeholder="Select Brand(s)"
										defaultValue={
											fcBrandsDefaultValue(
												brandsList.data,
												values?.brands?.map((x) => x.value),
												[]
											) || []
										}
										onChange={(value) => {
											console.log(value);
											setFieldValue('brands', value);
										}}
										selectOptions={[...(brandsList.data || [])]}
									/>
								</LoadAsyncSelectComponent>
							</FormInput>
						</Col>
					</Row>

					<Box>
						<StyledSubmitButton
							disabled={isSubmitting || !isValid || !dirty}
							id="add-user-submit"
						>
							<Box>{editMode ? 'Update' : 'Save'}</Box>
						</StyledSubmitButton>
					</Box>
					{process.env.NODE_ENV === 'development' && <Debug />}
				</Form>
			)}
		</Formik>
	);
}

export default AddUserForm;
