import React, { useState } from 'react';
import { Modal, Select } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Button, Text } from 'atoms';

const ReasonsSelect = styled(Select)`
	background: #f9f9f9;
	border: 1px solid #f3f3f3;
	border-radius: 4px;
	width: 100%;
	min-height: 37px;
	.ant-select-selector {
		min-height: 37px;
		.ant-select-selection-item {
			line-height: 37px;
		}
	}
`;

export function ReasonModal({ toggleModal, handleSettle, toggleDisable }) {
	const [reasons, setReasons] = useState('');
	const [innerModal, setInnerModal] = toggleModal;
	const [disablebtn] = toggleDisable;

	return (
		<Modal
			centered
			width="35%"
			visible={innerModal}
			onOk={() => setInnerModal(false)}
			onCancel={() => setInnerModal(false)}
			footer={false}
			maskClosable={false}
			className="innerModal"
		>
			<Box padding="5px 0 0">
				<Text color="#000000" fontSize="14px" lineHeight="19px" margin="0 0 8px">
					Please select reason for less settlement
				</Text>
				<Box margin="0 0 10px">
					<ReasonsSelect
						name="reasons"
						onChange={(e) => setReasons(e)}
						value={reasons}
						showSearch
						filterOption={(input, option) =>
							option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						options={[
							{ label: 'Coins', value: 'Coins' },
							{ label: 'Damage', value: 'Damage' },
							{ label: 'Duplicate', value: 'Duplicate' },
						]}
						placeholder="Select Reasons"
					/>
				</Box>
				<Box padding="5px 16px" textAlign="right">
					<Button
						backgroundColor="#CA5010"
						borderRadius="4px"
						fontSize="12px"
						lineHeight="15px"
						color="#FFFFFF"
						disabled={!reasons || disablebtn}
						onClick={() => handleSettle(true, reasons)}
					>
						Submit and Settle
					</Button>
				</Box>
			</Box>
		</Modal>
	);
}

ReasonModal.propTypes = {
	toggleModal: PropTypes.array,
	handleSettle: PropTypes.func,
	toggleDisable: PropTypes.array,
};
