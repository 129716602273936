import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from 'atoms';

export const PCS = styled(Text)`
	font-size: 10px;
	color: #ccc;
`;

export default function Thead({ children }) {
	return (
		<div>
			<Text fontSize="12px">{children}</Text>
			<PCS>(in pcs)</PCS>
		</div>
	);
}

Thead.propTypes = {
	children: PropTypes.any,
};
