import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// eslint-disable-next-line react/prop-types
export default function PdfViewer({ fileUrl }) {
	const defaultLayoutPluginInstance = defaultLayoutPlugin({
		renderToolbar: (Toolbar) => (
			<Toolbar>
				{(toolbarSlot) => {
					const {
						// CurrentPageLabel,
						// CurrentScale,
						// DownloadMenuItem,
						GoToFirstPage,
						CurrentPageInput,
						GoToLastPage,
						// GoToFirstPageMenuItem,
						// GoToLastPageMenuItem,
						// GoToNextPageMenuItem,
						GoToPreviousPage,
						NumberOfPages,
						GoToNextPage,
						// GoToPreviousPageMenuItem,
						// Open,
						// OpenMenuItem,
						// PrintMenuItem,
						// RotateBackwardMenuItem,
						// RotateForwardMenuItem,
						// Search,
						// ShowProperties,
						// ShowPropertiesMenuItem,
						// ShowSearchPopover,
						// SwitchScrollMode,
						// SwitchScrollModeMenuItem,
						// SwitchSelectionMode,
						// SwitchSelectionModeMenuItem,
						// SwitchTheme,
						// SwitchThemeMenuItem,
						// SwitchViewMode,
						// SwitchViewModeMenuItem,
						ZoomOut,
						Zoom,
						ZoomIn,
						// ZoomInMenuItem,
						// ZoomOutMenuItem,
						Print,
						Download,
						EnterFullScreen,
						Rotate,
						// EnterFullScreenMenuItem,
					} = toolbarSlot;

					return (
						<>
							{/* <CurrentPageLabel />
							<CurrentScale />
						<DownloadMenuItem /> */}
							<GoToFirstPage />
							<CurrentPageInput />
							{/* <GoToFirstPageMenuItem /> */}
							<GoToLastPage />
							{/* <GoToLastPageMenuItem /> */}
							{/* <GoToNextPageMenuItem /> */}
							<GoToPreviousPage />
							<NumberOfPages />
							<GoToNextPage />
							{/* <GoToPreviousPageMenuItem /> */}
							{/* <Open />
							<OpenMenuItem /> */}
							{/* <PrintMenuItem /> */}
							{/* <RotateBackwardMenuItem />
							<RotateForwardMenuItem /> */}
							{/* <Search /> */}
							{/* <ShowProperties /> */}
							{/* <ShowPropertiesMenuItem />
							<ShowSearchPopover />
							<SwitchScrollMode />
							<SwitchScrollModeMenuItem />
							<SwitchSelectionMode />
							<SwitchSelectionModeMenuItem />
							<SwitchTheme />
							<SwitchThemeMenuItem />
							<SwitchViewMode />
						<SwitchViewModeMenuItem /> */}
							<ZoomOut />
							<Zoom />
							<ZoomIn />
							{/* <ZoomInMenuItem /> */}
							{/* <ZoomOutMenuItem /> */}
							<Print />
							<Download />
							<EnterFullScreen />
							{/* <EnterFullScreenMenuItem /> */}
							<Rotate />
						</>
					);
					// 	const { NumberOfPages, Rotate, Print, ZoomIn, ZoomOut, Zoom } = toolbarSlot;
					// 	return (
					// 		<>
					// 			<NumberOfPages />
					// 			<ZoomOut />
					// 			<Zoom />
					// 			<ZoomIn />
					// 			<Rotate />
					// 			<Print />
					// 		</>
					// 	);
				}}
			</Toolbar>
		),
		sidebarTabs: (tabs) => tabs.filter((tab) => tab?.title === 'Thumbnail'), // Exclude the bookmark tab
	});

	return (
		<div style={{ height: '750px' }}>
			<Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
				<Viewer
					fileUrl={fileUrl}
					theme="dark"
					plugins={[defaultLayoutPluginInstance]}
					defaultScale={1.0}
				/>
			</Worker>
		</div>
	);
}
