import { createSlice } from '@reduxjs/toolkit';
import { message as toaster } from 'antd';
import { API } from 'api';

const initialState = {};

const OMS_BASE_URL = process.env.REACT_APP_OMS;

const downloadExcel = (
	response,
	fileName,
	type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
) => {
	const blob = new Blob([response], {
		type,
	});
	const url = URL.createObjectURL(blob);
	const element = document.createElement('a');

	element.setAttribute('href', url);
	element.setAttribute('download', fileName);
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);
};

export const deliveryAllocationSlice = createSlice({
	name: 'deliveryAllocation',
	initialState,
	reducers: {},
});

export const getDAList =
	({
		offset,
		limit,
		query,
		sort = { sort_column: 'createdAt', sort_direction: 'DESC' },
		currentPage,
		brands,
		fcs,
		...rest
	}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/order/allocation/list',
			data: {
				filter: {
					statuses: ['VA'],
					fields: ['vehicle_no'],

					user_brands_only: true,
					...rest,
					q: query ? `*${query || ''}*` : undefined,
					brands: brands?.length ? brands : undefined,
					fcs: fcs?.length ? fcs : undefined,
				},
				page: { offset, limit },
				sort,
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const viewDAList =
	({
		offset = 0,
		limit = 10,
		query,
		sort_column = 'createdAt',
		sort_direction = 'DESC',
		statuses = ['PU', 'RD'],
		brands,
		fcs,
		currentPage,
		stores,
		salesmen,
		...rest
	}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/order/list',
			data: {
				filter: {
					...rest,
					statuses,
					qinvoice: `*${query}*` || '**',
					salesmen: salesmen?.length ? salesmen : undefined,
					brands: brands?.length ? brands : undefined,
					fcs: fcs?.length ? fcs : undefined,
					stores: stores?.length ? stores : undefined,
					user_brands_only: true,
					user_fcs_only: true,
				},
				page: { offset, limit },
				sort: { sort_column, sort_direction },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const viewDAListView =
	({
		offset = 0,
		limit = 100,
		sort = { sort_column: 'createdAt', sort_direction: 'DESC' },
		...rest
	}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/order/list',
			data: {
				filter: { ...rest },
				page: { offset, limit },
				sort,
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getSalesOrderAllocation =
	({ id }) =>
	async () => {
		const CREDENTIALS = {
			url: `champ/order/allocation/${id}`,
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getUserList =
	({ q = '**' }) =>
	async () => {
		const CREDENTIALS = {
			url: '/champ/user/list',
			method: 'post',
			data: {
				filter: {
					groups: [22], // changed from 2 ---> 22
					user_fcs_only: true,
					user_brands_only: true,
					first_name__icontains: q || '**',
				},
				sort: {
					sort_column: 'id',
					sort_direction: 'DESC',
				},
				page: {
					limit: 50,
					offset: 0,
				},
			},
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const downloadDA =
	({ id }) =>
	async () => {
		const msgKey = Math.random().toString();
		const config = {
			key: msgKey,
			type: 'loading',
			content: 'Downloading Reports Delivery Allocation',
			duration: 0,
			className: 'customDownloadMsg',
		};
		toaster.open(config);

		const downloadId = id;
		const headers = { 'Content-Type': 'blob' };
		const CREDENTIALS = {
			url: `champ/order/allocation/download`,
			method: 'post',
			data: {
				id,
			},
			headers,
			config: {
				responseType: 'blob',
			},
		};
		return API.common(CREDENTIALS)
			.then((response) => {
				downloadExcel(response, `Delivery Allocation - ${downloadId}`, 'application/pdf');
			})
			.finally(() => {
				toaster.destroy(msgKey);
			});
	};

export const downloadRFC =
	({ id }) =>
	async () => {
		// const msgKey = Math.random().toString();
		const downloadId = id;
		const headers = { 'Content-Type': 'blob' };
		const CREDENTIALS = {
			url: `${OMS_BASE_URL}api/rfc_pdf_report?allocation_id=${downloadId}`,
			method: 'get',
			headers,
			config: {
				responseType: 'blob',
			},
		};
		return API.common(CREDENTIALS);
		// return API.common(CREDENTIALS)
		// 	.then((response) => {
		// 		if (response?.success) {
		// 			window.open(response?.message);
		// 		} else {
		// 			toaster.info(response?.message);
		// 		}
		// 	})
		// 	.finally(() => {
		// 		toaster.destroy(msgKey);
		// 	});
	};

export const downloadRFCFinal =
	({ id }) =>
	async () => {
		const msgKey = Math.random().toString();
		const config = {
			key: msgKey,
			type: 'loading',
			content: 'Downloading The PDF Report',
			duration: 0,
		};
		toaster.open(config);
		const downloadId = id;
		const headers = { 'Content-Type': 'blob' };
		const CREDENTIALS = {
			url: `${OMS_BASE_URL}api/rfc_pdf_report?allocation_id=${downloadId}`,
			method: 'get',
			data: {
				id,
			},
			headers,
			config: {
				responseType: 'blob',
			},
		};
		return API.common(CREDENTIALS)
			.then((response) => {
				// downloadExcel(response, `Return to FC - ${downloadId}`, 'application/pdf');
				// window.open(response?.message);
				const element = document.createElement('a');
				element.setAttribute('href', response?.message);
				element.setAttribute('download', `Return to FC - ${downloadId}.pdf`);
				document.body.appendChild(element);
				element.click();
				document.body.removeChild(element);
			})
			.finally(() => {
				setTimeout(() => {
					toaster.destroy(msgKey);
				}, 1000);
			});
	};

export const getSalesMenOnSearchList =
	({ q = '**', fc_ids = [] }) =>
	async () => {
		const CREDENTIALS = {
			url: 'champ/salesman/list',
			method: 'post',
			data: {
				filter: {
					fc_ids: fc_ids?.length ? fc_ids : undefined,
					user_fcs_only: true,
					user_brands_only: true,
					q: q || '**',
				},
				sort: { sort_column: 'id', sort_direction: 'DESC' },
				page: {
					limit: 25,
					offset: 0,
				},
			},
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const postVehicleAllocate =
	({ data }) =>
	async () => {
		const CREDENTIALS = {
			url: 'champ/order/allocate',
			method: 'post',
			data,
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const checkCrateTracking = (id) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${OMS_BASE_URL}api/crate_tracking`,
		data: {
			order_id: id,
		},
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export default deliveryAllocationSlice.reducer;
