/* eslint-disable sonarjs/prefer-immediate-return */
import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal, message } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Text } from 'atoms';
import { unassignInvoiceFromSalesman } from 'store/collectionSlice';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	button {
		width: 140px;
		height: 40px;
		border-radius: 4px;
		font-size: 14px;
		font-weight: 400;
	}
`;
const StyledText = styled(Text)`
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 20px;
`;
export default function UnassignModal({
	modalVisible,
	setModalVisible,
	rowSelect,
	getInvoicesHandOverData,
	resetRowSelect,
	setrowSelect,
}) {
	const dispatch = useDispatch();
	const handleUnassign = () => {
		if (rowSelect?.length) {
			const updatedData = rowSelect.map((rowData) => {
				const updatedObj = {
					invoice_id: rowData?.collection_invoice_id,
					invoice_no: rowData?.invoice_number,
					new_salesman_id: rowData?.salesperson_id,
					brand_id: rowData?.brand_id,
					fc_id: rowData?.collection_fc_id,
				};
				return updatedObj;
			});

			dispatch(unassignInvoiceFromSalesman(updatedData)).then(() => {
				getInvoicesHandOverData();
				setrowSelect([]);
				resetRowSelect();
				message.success('Invoices unassigned successfully');
			});

			setModalVisible(false);
		} else {
			message.success('Please select Invoices to un-assign them');
		}
	};

	return (
		<Modal visible={modalVisible} footer={null} width="432px" closable={false}>
			<StyledText textAlign="center">Are you sure you want to Un Assign?</StyledText>

			<ButtonContainer>
				<Button
					variant="primary"
					onClick={() => setModalVisible(false)}
					style={{ marginRight: '10px' }}
				>
					No
				</Button>
				<Button variant="primary" style={{ marginLeft: '10px' }} onClick={handleUnassign}>
					Unassign
				</Button>
			</ButtonContainer>
		</Modal>
	);
}

UnassignModal.propTypes = {
	modalVisible: PropTypes.bool,
	setModalVisible: PropTypes.func,
	rowSelect: PropTypes.func,
	setrowSelect: PropTypes.func,
	resetRowSelect: PropTypes.func,
	getInvoicesHandOverData: PropTypes.func,
};
