export const validateRipplrEmail = (email) => {
	/* eslint-disable no-useless-escape */
	const RIPPLR_MAIL = '@ripplr.in';
	const emailRegExp =
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	// const name = email.replace(RIPPLR_MAIL, '');
	// console.log(name);
	// if (name.match('[a-z]+')) {
	// 	console.log('valid');
	// } else {
	// 	console.log('invalid email');
	// }
	return (
		emailRegExp.test(email) &&
		email.indexOf(RIPPLR_MAIL, email.length - RIPPLR_MAIL.length) !== -1
	);
};

export const validateMobileNumber = (value) => {
	const phoneRegExp = /^[6-9]\d{9}$/gi;

	return phoneRegExp.test(value);
};

export const validateEmailMobile = (data) => {
	if (validateRipplrEmail(data)) {
		return true;
	}
	return validateMobileNumber(data);
};
