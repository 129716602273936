/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-key */
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Image } from 'antd';
// import { CheckCircleOutlined } from '@ant-design/icons';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Box } from 'atoms';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { PdfViewer } from 'components/common';

const ImagesContainer = styled(Box)`
	padding: 16px;
	position: relative;

	.carousel {
		.carousel-status {
			text-shadow: none !important;
			color: #000000;
			background: rgba(255, 255, 255, 0.5);
			border-radius: 2px;
			padding: 2px 5px;
		}
	}
`;

const StyledThumbnail = styled(Image)`
	width: 100%;
	height: 450px;
	object-fit: contain;
`;

const ImageWrapper = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	transition: transform 0.3s, z-index 0.3s;

	.ant-image-mask:hover {
		z-index: 2;
	}
	img {
		&:hover {
			transform: scale(1.05);
			z-index: 1;
		}
	}
`;

const VerifyWrapper = styled.div`
	width: 80px;
	height: 80px;
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	background: rgba(0, 0, 0, 0.75);
	z-index: 2;
	left: 50%;
	bottom: 45%;
	transform: translateX(-50%);
	flex-direction: column;
	color: #52c41a;
	gap: 3px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const CustomPrevButton = styled.button`
	position: absolute;
	z-index: 2;
	top: 50%;
	left: 15px;
	transform: translateY(-50%);
	background: rgba(255, 255, 255, 0.5);
	border: none;
	color: #ca5010;
	cursor: pointer;
	border-radius: 50%;
	width: 66px;
	height: 66px;
	transition: all 0.3s ease;
	&:hover {
		transform: translateY(-50%) scale(1.1);
		background-color: rgba(255, 255, 255, 0.8);
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
	}
`;

const CustomNextButton = styled.button`
	position: absolute;
	z-index: 2;
	top: 50%;
	right: 15px;
	transform: translateY(-50%);
	background: rgba(255, 255, 255, 0.5);
	border: none;
	color: #ca5010;
	cursor: pointer;
	border-radius: 50%;
	width: 66px;
	height: 66px;
	transition: all 0.3s ease;
	&:hover {
		background-color: rgba(255, 255, 255, 0.8);
		transform: translateY(-50%) scale(1.1);
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
	}
`;

const isPDFView = (res) => {
	const fileExtension = res.split('.').pop();
	if (fileExtension.toLowerCase() === 'pdf') {
		return 'pdf';
	}
	return 'image';
};

function getFileName(res) {
	return res?.split('/').pop();
}

export default function CarouselComponent({ currentSliderNo, imageList, setCurrentSlider }) {
	function currentSlide(e) {
		setCurrentSlider(imageList[e]);
	}

	return (
		imageList && (
			<ImagesContainer>
				<Carousel
					dynamicHeight
					selectedItem={currentSliderNo}
					onChange={(e) => currentSlide(e)}
					renderArrowPrev={(onClickHandler, hasPrev, label) =>
						hasPrev && (
							<CustomPrevButton onClick={onClickHandler} title={label}>
								<LeftOutlined />
							</CustomPrevButton>
						)
					}
					renderArrowNext={(onClickHandler, hasNext, label) =>
						hasNext && (
							<CustomNextButton onClick={onClickHandler} title={label}>
								<RightOutlined />
							</CustomNextButton>
						)
					}
				>
					{imageList?.map((image, index) => (
						<ImageWrapper className={image?.is_verified && 'verified'} key={index}>
							<div style={{ position: 'relative', width: '100%', height: 'auto' }}>
								{image?.is_verified && (
									<VerifyWrapper>
										<svg
											viewBox="64 64 896 896"
											focusable="false"
											data-icon="check-circle"
											width="22px"
											height="22px"
											fill="#52c41a"
											aria-hidden="true"
										>
											<path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"></path>
											<path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
										</svg>
										Verified
									</VerifyWrapper>
								)}
								{isPDFView(image?.image_path) === 'pdf' ? (
									<PdfViewer fileUrl={image.image_path} />
								) : (
									<StyledThumbnail
										src={image.image_path}
										alt={getFileName(image.image_path)}
									/>
								)}
							</div>
						</ImageWrapper>
					))}
				</Carousel>
			</ImagesContainer>
		)
	);
}

CarouselComponent.propTypes = {
	currentSliderNo: PropTypes.number,
	imageList: PropTypes.array,
	setCurrentSlider: PropTypes.any,
};
