/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from 'react';
import { Modal, Col, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DOWNLOADS_CHOICE, DOWNLOADS_CASHIER_CHOICE, SelectFilterLabelOptions } from 'utils';
import {
	CurrentInventoryFilters,
	GrnFilters,
	CurrentUnsellableFilters,
	OutstandingInvoiceFilters,
	SettlementFilters,
	BankSettlementFilters,
	CurrentCashInHandFilters,
	DeliveryReturnInvoiceFilters,
	DeliveryReturnVehicleFilters,
	CollectionSummaryFilters,
	// CollectionDetailFilters,
	SalesOrderInvoiceFilters,
	SalesOrderProductFilters,
	CollectionDetails,
	StockoneRfcFilters,
	SalesReturnReport,
} from 'components/forms/report-forms';
import { Text } from 'atoms';
import { LabelWrapper } from 'components/common';

export default function ReportsModal({ showModal, setShowModal, setpaginateConfig }) {
	const { auth } = useSelector((state) => state?.auth);
	const [reportType, setReportType] = useState(null);

	function handleModalClose() {
		setReportType(null);
		setShowModal(false);
		setpaginateConfig({
			query: '',
			offset: 0,
			limit: 10,
			currentPage: 1,
		});
	}

	return (
		<Modal
			title="Download Reports"
			visible={showModal}
			footer={null}
			onCancel={handleModalClose}
			width="65%"
			maskClosable={false}
		>
			<Row gutter={8}>
				<Col lg={8} sm={24}>
					<LabelWrapper label="Report Type" marginspace="0px 0 -10px 0">
						<Select
							showSearch
							className="report__drpdwn"
							value={reportType}
							options={
								auth.user.groups[0].name === 'Cashier'
									? DOWNLOADS_CASHIER_CHOICE
									: DOWNLOADS_CHOICE
							}
							filterOption={SelectFilterLabelOptions}
							allowClear
							onChange={(e) => setReportType(e)}
							placeholder="Select Report Type"
						/>
					</LabelWrapper>
				</Col>
			</Row>

			<Row gutter={24} justify="flex-start">
				<Col lg={24} sm={24}>
					{reportType && <Text mt={3}>Report Filters</Text>}
				</Col>
			</Row>
			{console.log(reportType)}
			{reportType === 'sales_order_invoice_wise' && (
				<SalesOrderInvoiceFilters setShowModal={handleModalClose} />
			)}
			{reportType === 'sales_order_invoice_product_wise' && (
				<SalesOrderProductFilters setShowModal={handleModalClose} />
			)}
			{reportType === 'sales_return_report' && (
				<SalesReturnReport setShowModal={handleModalClose} />
			)}
			{reportType === 'grn' && <GrnFilters setShowModal={handleModalClose} />}
			{reportType === 'current_inventory' && (
				<CurrentInventoryFilters setShowModal={handleModalClose} />
			)}
			{reportType === 'current_unsellable_inventory' && (
				<CurrentUnsellableFilters setShowModal={handleModalClose} />
			)}

			{reportType === 'delivery_return_invoice_wise' && (
				<DeliveryReturnInvoiceFilters setShowModal={handleModalClose} />
			)}
			{reportType === 'delivery_return_vehicle_wise' && (
				<DeliveryReturnVehicleFilters setShowModal={handleModalClose} />
			)}
			{reportType === 'outstanding_invoice' && (
				<OutstandingInvoiceFilters setShowModal={handleModalClose} />
			)}
			{reportType === 'collection_summary' && (
				<CollectionSummaryFilters setShowModal={handleModalClose} />
			)}
			{/* {reportType === 'collection_detail' && (
				<CollectionDetailFilters setShowModal={handleModalClose} />
			)} */}
			{reportType === 'settlement' && <SettlementFilters setShowModal={handleModalClose} />}
			{reportType === 'bank_settlement' && (
				<BankSettlementFilters setShowModal={handleModalClose} />
			)}
			{reportType === 'current_cash_in_hand' && (
				<CurrentCashInHandFilters setShowModal={handleModalClose} />
			)}
			{reportType === 'collection_detail' && (
				<CollectionDetails setShowModal={handleModalClose} />
			)}
			{reportType === 'stockone_rfc_report' && (
				<StockoneRfcFilters setShowModal={handleModalClose} />
			)}
		</Modal>
	);
}

ReportsModal.propTypes = {
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
	setpaginateConfig: PropTypes.func,
};
