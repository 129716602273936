/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-child-element-spacing */
import React, { useEffect, useState } from 'react';
import { Col, Image, Modal, Row, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box, Button, Flex } from 'atoms';
import { OutOfLocationIcons, StoreMallIcons } from 'utils';
// import SearchLocationInput from 'components/map/GooglePlacesApi';
import MapComponent from 'components/map/MapComponent';
import StoreImage from '../../assets/StoreImage.jpeg';
// import { getSalesOrderAllocation } from 'store/fulfilment/deliveryAllocationSlice';
import { retailerGeofenceVerify, retailerKYC } from 'store/fulfilment/salesOrderSlice';

const ButtonContainer = styled.div`
	button {
		border-radius: 4px;
	}
	.approve {
		background: #5b7f2b;
	}
	.reject {
		background: #ff4747;
	}
`;

const ConfirmBtnContainer = styled.div`
	button {
		border-radius: 4px;
	}
	.yes {
		min-width: 88px;
		margin-left: 16px;
	}
	.no {
		min-width: 88px;
		color: #ca5010;
		background: #fff7f2;
		margin-left: 0;
		border: 1px solid #ffcdb2;
	}
`;

const locationWrapper = {
	gap: '6px',
	alignItems: 'center',
};

const locationTitle = {
	color: '#CA5010',
	fontSize: '15px',
	fontWeight: 600,
};

const StoreName = {
	color: '#174060',
	fontSize: '18px',
	fontWeight: 600,
};

// const storeLocationTitle = {
// 	color: '#CA5010',
// 	fontSize: '15px',
// 	fontWeight: 600,
// 	paddingBottom: '16px',
// 	paddingLeft: '16px',
// };

const newBg = {
	background: '#01e64d',
};

const confirmationContent = {
	paddingTop: '12px',
	paddingBottom: '16px',
};

const approveColor = {
	color: '#5B7F2B',
};

const rejectColor = {
	color: '#FF4747',
};

const TitleColor = styled.div`
	color: #ca5010;
`;

const PointerWrapper = styled.div`
	width: 24px;
	height: 16px;
`;

const StoreNameWrapper = styled.div`
	width: 28px;
	height: 26px;
	margin-right: 6px;
`;

const CircleBg = styled.div`
	height: 12px;
	background: #fd7567;
	border-radius: 50%;
	flex: 0 0 12px;
`;

const ListStyle = styled.ul`
	list-style: disc;
	margin: 6px 0 6px 19px;
`;

const StoreImageWrapper = styled.div`
	padding-top: ${(props) => (props?.locationType === 'newLocation' ? '16px' : '0')};
`;

const CustomModal = styled(Modal)``;

// const CustomModal = styled(Modal)`
// 	.ant-modal-close-x {
// 		width: ${(props) => props.locationType === 'newLocation' && '25px'};
// 		height: ${(props) => props.locationType === 'newLocation' && '25px'};
// 		line-height: ${(props) => props.locationType === 'newLocation' && '25px'};
// 	}
// `;

const ImageStyle = {
	maxHeight: '430px',
	background: '#000000',
	objectFit: 'contain',
};

const Approved = styled.span`
	background: #efffda;
	color: #5b7f2b;
	padding: 8px 16px;
`;

const Rejected = styled.span`
	background: #ffefef;
	color: #ff4747;
	padding: 8px 16px;
`;

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function RFCLocationModal({
	currentInvoiceStatus,
	isVerifiyCompleted,
	uploadAllowed,
	rfcEditBlock,
	invoiceId,
	currentOrderId,
	locationModalVisible,
	setLocationModalVisible,
	hangleVerify,
	width = '75%',
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [kycDetails, setKycDetails] = useState({ reason: null, kyc_id: null });
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState();
	const [newSelectedLocation, setNewSelectedLocation] = useState();
	const [storeUrl, setStoreUrl] = useState();
	const [kycData, setKycData] = useState();

	// eslint-disable-next-line sonarjs/cognitive-complexity
	useEffect(() => {
		// console.log('isVerifiyCompleted ', isVerifiyCompleted);
		if (locationModalVisible?.latLong?.lat && locationModalVisible?.latLong?.long) {
			setSelectedLocation({
				lat: +locationModalVisible?.latLong?.lat,
				lng: +locationModalVisible?.latLong?.long,
			});
		}

		dispatch(retailerKYC(currentOrderId)).then((res) => {
			setKycData(res?.data);
			// console.log('retailerKYC res ', res);
			if (res?.data?.approval_status === 'rejected') {
				setNewSelectedLocation({
					lat: +res?.data?.latitude,
					lng: +res?.data?.longitude,
				});
			} else if (res?.data?.approval_status === 'approved') {
				setSelectedLocation({
					lat: +locationModalVisible?.latLong?.lat,
					lng: +locationModalVisible?.latLong?.long,
				});
			} else if (
				currentOrderId &&
				(locationModalVisible?.locationType === 'newLocation' ||
					locationModalVisible?.locationType === 'outOfLocation' ||
					res?.data?.outside_fence)
			) {
				setKycDetails({ ...kycDetails, kyc_id: res?.data?.id });
				if (res?.data?.image_filename) {
					setStoreUrl(res?.data?.image_filename);
				}
				if (res?.data?.for_kyc && res?.data?.kyc_latitude && res?.data?.kyc_longitude) {
					setNewSelectedLocation({
						lat: +res?.data?.kyc_latitude,
						lng: +res?.data?.kyc_longitude,
					});
				} else if (!res?.data?.for_kyc && res?.data?.latitude && res?.data?.longitude) {
					setNewSelectedLocation({
						lat: +res?.data?.latitude,
						lng: +res?.data?.longitude,
					});
				}
			}
		});
	}, []);

	const modalClose = () => {
		setLocationModalVisible({
			showModal: false,
			locationType: null,
			latLong: null,
			storeName: null,
		});
	};

	const confirmCancel = () => {
		setShowConfirmation(false);
	};

	const approve = () => {
		setKycDetails({ ...kycDetails, reason: 'approved' });
		setShowConfirmation(true);
		// if (kycDetails?.kyc_id) {
		// 	dispatch(retailerGeofenceVerify('approved', kycDetails?.kyc_id)).then((res) => {
		// 		console.log('approved api ', res);
		// 	});
		// }
	};

	const reject = () => {
		setKycDetails({ ...kycDetails, reason: 'rejected' });
		setShowConfirmation(true);
		// if (kycDetails?.kyc_id) {
		// 	dispatch(retailerGeofenceVerify('rejected', kycDetails?.kyc_id)).then((res) => {
		// 		console.log('approved api ', res);
		// 	});
		// }
	};

	const LocationConfirm = (type) => {
		if (type === 'no') {
			setShowConfirmation(false);
			return false;
		}
		// console.log('kycDetails ', kycDetails);
		// console.log('invoiceId ', invoiceId);
		const { reason, kyc_id } = kycDetails;
		const successMsg =
			reason === 'approved' ? 'New Lat-long Updated Successfully' : 'New Lat-long Rejected';
		if (kyc_id) {
			dispatch(retailerGeofenceVerify(reason, kyc_id))
				.then((res) => {
					if (res.status) {
						setShowConfirmation(false);
						message.info(successMsg);
						navigate(`/logistics-management/return-to-fc-new/${+invoiceId}/show`);
					}
				})
				.catch((error) => {
					console.log('error', error);
					message.error(`Error on Geo Verify`);
				})
				.finally(() => {
					setLocationModalVisible({
						showModal: false,
						locationType: null,
					});
					hangleVerify();
				});
		}
		return false;
	};

	return (
		<>
			{console.log('currentInvoiceStatus ', currentInvoiceStatus)}
			<CustomModal
				centered
				width={width}
				maskClosable={false}
				visible={locationModalVisible?.showModal}
				footer={null}
				onCancel={modalClose}
				locationType={locationModalVisible?.locationType}
			>
				<Row gutter={32}>
					{console.log('kycData kycData ', kycData)}
					{locationModalVisible?.locationType === 'newLocation' &&
						locationModalVisible?.locationType !== 'outOfLocation' &&
						kycData?.approval_status !== 'rejected' &&
						kycData?.approval_status !== 'approved' && (
							<>
								{locationModalVisible?.storeName && (
									<Col xs={24} style={StoreName}>
										<Flex>
											<StoreNameWrapper>
												<StoreMallIcons width={42} />
											</StoreNameWrapper>
											{locationModalVisible?.storeName}
										</Flex>
									</Col>
								)}
								<Col xs={12}>
									<Row gutter={6}>
										<Col xs={14} style={locationTitle}>
											<Flex>
												<PointerWrapper>
													<OutOfLocationIcons width={16} />
												</PointerWrapper>
												New Latlong is captured for this Store.
											</Flex>
										</Col>
										<Col xs={5}>
											<Flex style={locationWrapper}>
												<CircleBg style={newBg} />
												New Location
											</Flex>
										</Col>
										<Col xs={5}>
											<Flex style={locationWrapper}>
												<CircleBg />
												Old Location
											</Flex>
										</Col>
									</Row>
									<Box>
										<>
											<ListStyle>
												<li>
													<span style={approveColor}>Approving </span>{' '}
													this Lat-Long will replace the Store&apos;s
													Lat-Long details.
												</li>
												<li>
													<span style={rejectColor}>Rejecting </span>
													this Lat-Long will not update the Store&apos;s
													Lat-Long details.
												</li>
											</ListStyle>
											{!rfcEditBlock &&
												uploadAllowed &&
												isVerifiyCompleted !== 'CO' &&
												!['OD', 'VA', 'XD'].includes(
													currentInvoiceStatus
												) && (
													<ButtonContainer>
														<Button
															variant="primary"
															className="reject"
															onClick={reject}
														>
															Reject
														</Button>
														<Button
															variant="primary"
															className="approve"
															onClick={approve}
														>
															Approve
														</Button>
													</ButtonContainer>
												)}
										</>
										<StoreImageWrapper
											locationType={locationModalVisible?.locationType}
										>
											<Image
												style={ImageStyle}
												width="100%"
												src={storeUrl || StoreImage}
											/>
										</StoreImageWrapper>
									</Box>
								</Col>
							</>
						)}
					{locationModalVisible?.locationType !== 'newLocation' &&
						locationModalVisible?.locationType === 'outOfLocation' && (
							<Col style={{ marginBottom: '20px' }} xs={24}>
								<Row gutter={6}>
									<Col xs={14} style={locationTitle}>
										<Flex>
											<PointerWrapper>
												<OutOfLocationIcons width={16} />
											</PointerWrapper>
											Store Location
										</Flex>
									</Col>
									<Col xs={5}>
										<Flex style={locationWrapper}>
											<CircleBg style={newBg} />
											Delivered Location
										</Flex>
									</Col>
									<Col xs={5}>
										<Flex style={locationWrapper}>
											<CircleBg />
											Store Location
										</Flex>
									</Col>
								</Row>
							</Col>
						)}

					{!kycData?.for_kyc && !kycData?.outside_fence && (
						<Col style={{ marginBottom: '20px' }} xs={24}>
							<Row gutter={6}>
								<Col flex="auto" style={locationTitle}>
									<Flex>
										<PointerWrapper>
											<OutOfLocationIcons width={16} />
										</PointerWrapper>
										Store Location
									</Flex>
								</Col>
							</Row>
						</Col>
					)}
					{locationModalVisible?.locationType !== 'outOfLocation' &&
						(kycData?.approval_status === 'rejected' ||
							kycData?.approval_status === 'approved') && (
							<Col style={{ marginBottom: '20px' }} xs={24}>
								<Row gutter={6}>
									<Col flex="auto" style={locationTitle}>
										<Flex>
											<PointerWrapper>
												<OutOfLocationIcons width={16} />
											</PointerWrapper>
											Store Location
										</Flex>
									</Col>
									{kycData?.outside_fence &&
										kycData?.approval_status !== 'approved' && (
											<>
												<Col xs={5}>
													<Flex style={locationWrapper}>
														<CircleBg style={newBg} />
														{kycData?.approval_status === 'rejected'
															? 'New Location'
															: 'Delivered Location'}
													</Flex>
												</Col>
												<Col xs={5}>
													<Flex style={locationWrapper}>
														<CircleBg />
														{kycData?.approval_status === 'rejected'
															? 'Old Location'
															: 'Store Location'}
													</Flex>
												</Col>
											</>
										)}
									{kycData?.approval_status && (
										<Col style={{ width: 160 }}>
											{kycData?.approval_status === 'approved' && (
												<Approved>Approved</Approved>
											)}
											{kycData?.approval_status === 'rejected' && (
												<Rejected>Rejected</Rejected>
											)}
										</Col>
									)}
								</Row>
							</Col>
						)}
					<Col
						xs={
							locationModalVisible?.locationType === 'newLocation' &&
							kycData?.approval_status !== 'rejected' &&
							kycData?.approval_status !== 'approved'
								? 12
								: 24
						}
					>
						{console.log('kycDetails ', kycDetails)}
						<div
							style={{
								height: '100%',
								maxHeight: '560px',
								minHeight: '360px',
								width: '100%',
							}}
						>
							{/* <SearchLocationInput setSelectedLocation={setSelectedLocation} /> */}
							<MapComponent
								newSelectedLocation={newSelectedLocation}
								selectedLocation={selectedLocation}
							/>
						</div>
					</Col>
				</Row>
			</CustomModal>
			{showConfirmation && (
				<Modal
					centered
					visible={locationModalVisible?.showModal}
					footer={null}
					closable={false}
					maskClosable={false}
					onCancel={confirmCancel}
				>
					<TitleColor>
						{kycDetails?.reason === 'approved'
							? `By Approving this Lat-Long will replace the Store's Lat-Long details.`
							: `By Rejecting this Lat-Long will not update the Store's Lat-Long details.`}
					</TitleColor>
					<p style={confirmationContent}>
						Are you sure you want to{' '}
						{kycDetails?.reason === 'approved' ? (
							<span style={approveColor}>Approve</span>
						) : (
							<span style={rejectColor}>Reject</span>
						)}{' '}
						?
					</p>
					<ConfirmBtnContainer>
						<Button
							variant="primary"
							className="no"
							onClick={() => LocationConfirm('no')}
						>
							No
						</Button>
						<Button
							variant="primary"
							className="yes"
							onClick={() => LocationConfirm('yes')}
						>
							Yes
						</Button>
					</ConfirmBtnContainer>
				</Modal>
			)}
		</>
	);
}

RFCLocationModal.propTypes = {
	currentInvoiceStatus: PropTypes.any,
	isVerifiyCompleted: PropTypes.any,
	uploadAllowed: PropTypes.any,
	rfcEditBlock: PropTypes.any,
	invoiceId: PropTypes.number,
	currentOrderId: PropTypes.number,
	locationModalVisible: PropTypes.any,
	setLocationModalVisible: PropTypes.any,
	width: PropTypes.number,
	hangleVerify: PropTypes.func,
};
