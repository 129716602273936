import React from 'react';
import { Input } from 'formik-antd';
import styled from 'styled-components';

const CustomInput = styled(Input)`
	background: #faf9f8;
	border: 1px solid #edebe9 !important;
	border-radius: 4px;
	width: 200px;
	height: 40px !important;
`;

export default function InputWrapper(props) {
	return (
		<CustomInput
			onKeyPress={(evt) => {
				if (!evt.code.includes('Digit')) {
					evt.preventDefault();
					return false;
				}
				return true;
			}}
			{...props}
		/>
	);
}

export { CustomInput };
