/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { Col, Row, Steps, Empty, Card } from 'antd';
import { v1 as uuidv1 } from 'uuid';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Text } from 'atoms';
import { Rupee } from 'components';
import { CollectionUserIcon, SalesmanIcon } from 'utils';

const { Step } = Steps;
const CustomStep = styled(Step)`
	.ant-steps-item-title {
		font-size: 18px;
		color: #000 !important;
		font-weight: 600;
	}
	.ant-steps-item-subtitle {
		font-size: 14px;
		color: #352a35;
		font-weight: 500;
		display: block;
		margin-left: 0px;
	}
	.ant-steps-item-content {
		background-color: white;
		margin-bottom: 20px;
		padding: 16px 20px 6px 20px;
		border-radius: 4px;
	}
	.ant-steps-item-icon {
		background-color: #d9d9d9;
		border-color: transparent;
	}
	.ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: #d9d9d9;
	}
	.remarksBg {
		padding: 8px 16px;
		margin-top: 12px;
		border-radius: 4px;
		background: #fff8f4;
	}
	.greyRemarksBg {
		background: #f6f6f6;
	}
`;
const Container = styled.div`
	.ant-steps > .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
		background-color: green;
		svg {
			color: white;
		}
	}
	.ant-steps > .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: green;
	}
	.ant-steps > .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
		background-color: green;
		svg {
			color: white;
		}
	}
	.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
		padding-bottom: 0;
	}
	.ant-steps-item-wait
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-description,
	.ant-steps-item-finish
		> .ant-steps-item-container
		> .ant-steps-item-content
		> .ant-steps-item-description {
		color: rgba(0, 0, 0);
	}
	.contentLabel {
		font-size: 12px;
		color: #1f1f1f;
		font-weight: 500;
		margin-bottom: 5px;
	}
	.bold-text {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 10px;
	}

	.phoneNumber {
		font-size: 12px;
		color: #4e4e4e;
		font-weight: 400;
	}
	.borderBottom {
		border-bottom: 1px solid #e5e5e5;
	}
	.invoiceReturned {
		color: #ca5010;
	}
	.invoiceSettled {
		color: #107c10;
	}
	.noCollectionMade {
		.ant-steps-item-content {
			border: 1px solid #ca5010;
		}
	}
`;

function CollectionHistory({ data }) {
	const [transformedData, setTransformedData] = useState();
	const DateFormat = 'DD-MM-YYYY';

	const getLocalDate = (date) => moment(date).add(330, 'minutes').format(DateFormat);

	const deliveryFooter = () => (
		<Row
			style={{
				marginBottom: '10px',
				marginLeft: '40px',
				border: '1px solid #CA5010',
				backgroundColor: '#FFF7F2',
				borderRadius: '4px',
			}}
		>
			{data?.invoice_amount && (
				<Col span={6} style={{ backgroundColor: '#CA5010', padding: '5px 20px' }}>
					<Text fontSize="14px" fontWeight="600" color="#fff">
						Invoice Value:&nbsp;
						<Rupee>{Number(data?.invoice_amount).toFixed(2)}</Rupee>
					</Text>
				</Col>
			)}
			{data?.invoice_settled ? null : (
				<Col span={6} style={{ padding: '5px 20px' }}>
					<Text fontSize="14px" fontWeight="600">
						Invoice Status : Unsettled
					</Text>
				</Col>
			)}

			{data?.invoice_settled ? (
				<Col span={6} style={{ padding: '5px 20px' }}>
					<Text fontSize="14px" fontWeight="600">
						Took {data?.days} {`${data?.days > 1 ? 'days' : 'day'}`} to settle the
						invoice
					</Text>
				</Col>
			) : (
				<Col span={6} style={{ padding: '5px 20px' }}>
					<Text fontSize="14px" fontWeight="600">
						Aging: {data?.days}
						&nbsp;{`${data?.days > 1 ? 'days' : 'day'}`}
					</Text>
				</Col>
			)}
		</Row>
	);

	useEffect(() => {
		if (data?.data.length) {
			const transformData = () => {
				const updatedData = data?.data.map((item) => {
					if (item?.payments?.length) {
						const payments = item?.payments;

						const cheques = payments.filter(
							(payment) => payment.payment_type === 'Cheque'
						);
						const otherPayments = payments.filter(
							(payment) => payment.payment_type !== 'Cheque'
						);
						const sortedPaymentData = otherPayments.concat(cheques);
						return { ...item, payments: sortedPaymentData };
					}
					return item;
				});
				setTransformedData(updatedData);
			};
			transformData();
		}
	}, [data]);

	return data?.data?.length > 0 ? (
		<Container>
			{data?.invoice_amount > 0 && data?.days > 0 && <div>{deliveryFooter()}</div>}
			<Steps direction="vertical" size="small">
				{transformedData?.map((item) => (
					<CustomStep
						className={item?.payments.length === 0 && 'noCollectionMade'}
						remark={item?.remark}
						key={item?.id}
						status={item?.payments.length === 0 ? 'wait' : 'process'}
						description={
							item && (
								<>
									<Row gutter={16}>
										<Col span={3}>
											<div className="contentLabel">Collected Date</div>
											<div className="bold-text">
												{getLocalDate(item?.collection_date)}
											</div>
										</Col>
										<Col span={3}>
											<div
												className="contentLabel"
												style={{
													position: 'relative',
												}}
											>
												Collected By
												<div
													style={{
														position: 'absolute',
														top: '2px',
														left: '75px',
													}}
												>
													{item?.filled_by_delivery_boy_id > 0 ? (
														<CollectionUserIcon />
													) : (
														<SalesmanIcon />
													)}
												</div>
											</div>
											<div className="bold-text">
												{item?.collected_by_name}
												<div className="phoneNumber">
													{item?.collected_by_mobile}
												</div>
											</div>
										</Col>
										<Col className="borderBottom" span={18}>
											<Row gutter={16}>
												<Col span={6}>
													<div className="contentLabel">
														Collected Amount
													</div>
													<div className="bold-text">
														<Rupee>{item?.collected_amount}</Rupee>
													</div>
												</Col>
												<Col span={6}>
													<div className="contentLabel">
														Current Outstanding Amount
													</div>
													<div className="bold-text">
														<Rupee>
															{item?.current_outstanding_amount}
														</Rupee>
													</div>
												</Col>
												<Col span={12}>
													<div className="contentLabel">
														Invoice Status
													</div>
													<div
														className={`bold-text ${
															item?.invoice_status ===
															'Invoice Settled'
																? 'invoiceSettled'
																: 'invoiceReturned'
														}`}
													>
														{item?.invoice_status}
													</div>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row style={{ margin: '10px 0 ' }}>
										{item?.payments?.length > 0 && (
											<>
												<Col span={6} style={{ padding: '0px' }}>
													<div className="contentLabel">
														Collection Details
													</div>
												</Col>
												<Col span={18}>
													{item?.payments.map((collectionDetails) => (
														<Row
															key={uuidv1()}
															style={{ flexFlow: 'revert' }}
														>
															<Col
																className="contentLabel bold-text"
																span={4}
															>
																{collectionDetails?.payment_type}
																:&nbsp;
																<Rupee>
																	{collectionDetails?.amount}
																</Rupee>
															</Col>

															{collectionDetails?.reference_number && (
																<Col
																	className="contentLabel bold-text"
																	span={
																		collectionDetails?.payment_type ===
																		'Cheque'
																			? 6
																			: 10
																	}
																>
																	{collectionDetails?.payment_type ===
																	'Cheque'
																		? 'Cheque Number:'
																		: 'Reference Number:'}
																	&nbsp;
																	{
																		collectionDetails?.reference_number
																	}
																</Col>
															)}

															{collectionDetails?.payment_type ===
																'Cheque' && (
																<Col
																	className="contentLabel bold-text"
																	span={6}
																>
																	Due Date:&nbsp;
																	{getLocalDate(
																		collectionDetails?.due_date
																	)}
																</Col>
															)}
															{collectionDetails?.payment_type ===
																'Cheque' && (
																<Col
																	className="contentLabel bold-text bank-word-break"
																	span={8}
																	style={{
																		wordWrap: 'break-word',
																	}}
																>
																	Bank Name:&nbsp;
																	{collectionDetails?.name}
																</Col>
															)}
														</Row>
													))}
												</Col>
											</>
										)}
									</Row>
									{item?.remarks && (
										<Col span={24}>
											<Row
												gutter={16}
												className={
													item.payments.length > 0
														? 'remarksBg'
														: 'greyRemarksBg'
												}
											>
												<Col
													span={6}
													className="bold-text"
													style={{ marginBottom: '0px' }}
												>
													Remarks:
												</Col>
												<Col
													span={18}
													className="bold-text"
													style={{
														marginBottom: '0px',
														paddingLeft: '0px',
														wordWrap: 'break-word',
													}}
												>
													{item?.remarks}
												</Col>
											</Row>
										</Col>
									)}
								</>
							)
						}
					/>
				))}
			</Steps>
		</Container>
	) : (
		<Card>
			<Empty />
		</Card>
	);
}
export default CollectionHistory;

CollectionHistory.propTypes = {
	data: PropTypes.object,
};
