import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import { isEmpty } from 'lodash';
import { getCompanyList } from 'store/OnBoardSlice';
import { Box, Flex, Button } from 'atoms';
import { RCTable, LabelWrapper, SearchBar, ACL } from 'components/common';
import {
	CompanyColumns,
	CompanyClientQueries,
	handleFilterChange,
	handleSearch,
	SelectFilterLabelOptions,
	searchBox,
	UserPersmission,
} from 'utils';
import { SearchField } from 'components/common/custom-table/Style';
import { useQueryParams } from 'hooks';
import * as SVGICONS from 'utils/Svg';

export default function CompanyListPage() {
	const navigate = useNavigate();
	const [paginateConfig, setpaginateConfig] = useQueryParams({
		filterQuery: CompanyClientQueries,
	});
	const [rowData, setRowData] = useState({});
	const [btnClickSearh, setBtnClickSearh] = useState({});

	return (
		<Box mt={3}>
			<Flex alignItems="center" margin="0 0 1rem">
				<Flex width="100%" flexWrap="wrap" alignItems="center" gap="4px">
					<LabelWrapper label="Search By" marginspace="3px 0 -1px 1rem">
						<Select
							name="fields"
							style={searchBox}
							allowClear
							label="Search By"
							showSearch
							filterOption={SelectFilterLabelOptions}
							value={paginateConfig?.fields}
							options={[
								{ value: 'name', label: 'Name' },
								{ value: 'code', label: 'Code' },
							]}
							placeholder="Search By"
							onChange={(value) =>
								setpaginateConfig((prev) => {
									const fieldsValue = handleFilterChange('fields', value, prev);
									return {
										...fieldsValue,
										...(isEmpty(value) ? { query: '' } : {}),
									};
								})
							}
						/>
					</LabelWrapper>
					{!isEmpty(paginateConfig?.fields) && (
						<LabelWrapper label="Search" marginspace="-1px 0 5px 0">
							<SearchField>
								<SearchBar
									placeholder="Search"
									queryValue={paginateConfig?.query?.replaceAll('*', '')}
									onSearch={(val) => ({
										...setpaginateConfig((prev) => handleSearch(val, prev)),
										...(isEmpty(val) ? { query: '' } : {}),
									})}
								/>
							</SearchField>
						</LabelWrapper>
					)}
					<Box mt=".75rem">
						<Button onClick={() => setBtnClickSearh(Math.random())}>Search</Button>
					</Box>
				</Flex>
				<ACL allowedGroups={UserPersmission.company_create}>
					<Button onClick={() => navigate('/onboarding/company/add')} type="variant">
						<Flex alignItems="center" justifyContent="center">
							<Box width={30}>
								<SVGICONS.PlusOutlinedIcons />
							</Box>
							<Box>Add Company</Box>
						</Flex>
					</Button>
				</ACL>
			</Flex>
			<RCTable
				rowKey="id"
				tableDatas={[rowData, setRowData]}
				togglePaginate={[paginateConfig, setpaginateConfig]}
				dispatchAction={getCompanyList}
				configs={{ defaultSort: 'DESC', defaultsortField: 'createdAt' }}
				columns={CompanyColumns}
				triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
				disableAutoSearch
			/>
		</Box>
	);
}
