/* eslint-disable sonarjs/cognitive-complexity */
import { Rupee } from 'components';
import { createdDate, createdTime, utcToLocDT } from 'utils';

const dlPL = ['DL', 'PD'];
const outstandingDisplayValue = (salesOrderList) =>
	dlPL.includes(salesOrderList?.status)
		? salesOrderList?.OutstandingInvoice?.current_outstanding_amount
		: 0 || '';

const DeliverdDisplayValue = (salesOrderList) =>
	dlPL.includes(salesOrderList?.status)
		? salesOrderList?.OutstandingInvoice?.initial_outstanding_amount
		: 0 || '';

export const billStatusOption = (type) => {
	console.log(type);
	switch (type) {
		case 'Bill Back':
			return 'Invoice Returned';
		case 'No Bill Back':
			return 'Invoice Settled';
		case 'Pending':
			return 'Pending';
		case 'Voucher Back':
			return 'Voucher Returned';
		case 'No Voucher Back':
			return 'Voucher Settled';
		case 'Voucher':
			return 'Voucher';
		case 'Void Bill':
			return 'Void Bill';
		case 'Unattended':
			return 'Unattended';
		default:
			return '';
	}
};

export const returnToFcShowPanel = ({ salesOrderDetailsData = {} }) => [
	{
		title: 'FC',
		value: salesOrderDetailsData?.FC?.name || '',
		additionalValue: salesOrderDetailsData?.FC?.code || '',
	},
	{
		title: 'Brand',
		value: salesOrderDetailsData?.Brand?.name || '',
	},
	{
		title: 'Salesman',
		value: salesOrderDetailsData?.Salesman?.name || '',
	},
	{
		title: 'Created At',
		value: utcToLocDT(salesOrderDetailsData?.createdAt),
	},
	{
		title: 'Order Number',
		value: salesOrderDetailsData?.order_no?.toUpperCase() || '',
	},
	{
		title: 'Ordered Date',
		type: 'date',
		value: salesOrderDetailsData?.order_date || '',
	},
	{
		title: 'Order value',
		type: 'rupee',
		value: salesOrderDetailsData?.total_net_amount || 0,
	},
	{
		title: 'Invoice Date',
		type: 'date',
		value: salesOrderDetailsData?.invoice_date || '',
	},
	{
		title: 'Additional Discount',
		type: 'rupee',
		value: salesOrderDetailsData?.brandAjustment?.valueData || 0,
		infoDetails: [salesOrderDetailsData, 'Brand Adjustment'],
	},
	{
		title: 'Credit Adjustment',
		type: 'rupee',
		value: salesOrderDetailsData?.brandAjustment?.creditNote || 0,
	},
	{
		title: 'Delivered Amount',
		value: <Rupee roundOff={0}>{salesOrderDetailsData?.total_delivered_qty_amount || 0}</Rupee>,
	},
	{
		title: 'Collectable Amount',
		value:
			salesOrderDetailsData?.status === 'DL' || salesOrderDetailsData?.status === 'PD' ? (
				<Rupee roundOff={0}>
					{salesOrderDetailsData?.OutstandingInvoice?.initial_outstanding_amount}
				</Rupee>
			) : (
				'0'
			),
	},
	{
		title: 'Collected Amount',
		value: (
			<Rupee roundOff={0}>
				{salesOrderDetailsData?.OutstandingInvoice?.collected_amount || 0}
			</Rupee>
		),
	},
	{
		title: 'Outstanding Amount',
		value: <Rupee roundOff={0}>{outstandingDisplayValue(salesOrderDetailsData)}</Rupee>,
	},
	{
		title: 'Invoice Status',
		value: billStatusOption(salesOrderDetailsData?.OutstandingInvoice?.bill_status),
	},
];

// eslint-disable-next-line sonarjs/no-identical-functions
export const SalesOrderToFcShowPanel = ({ salesOrderDetailsData = {} }) => [
	{
		title: 'FC',
		value: salesOrderDetailsData?.FC?.name || '',
		additionalValue: salesOrderDetailsData?.FC?.code || '',
	},
	{
		title: 'Order No',
		value: salesOrderDetailsData?.order_no?.toUpperCase() || '',
	},
	{
		title: 'Ordered Date',
		type: 'date',
		value: salesOrderDetailsData?.order_date || '',
	},
	{
		title: 'Invoice No',
		value: salesOrderDetailsData?.invoice_no || '',
		type: 'upper',
	},
	{
		title: 'Invoice Date',
		type: 'date',
		value: salesOrderDetailsData?.invoice_date || '',
	},
	{
		title: 'Store',
		value: salesOrderDetailsData?.Store?.name || '',
		additionalValue: salesOrderDetailsData?.Store?.code || '',
	},
	{
		title: 'Salesman',
		value: salesOrderDetailsData?.Salesman?.name || '',
	},
	{
		title: 'System Reference No',
		value: salesOrderDetailsData?.ref_no || '',
	},
	{
		title: 'Order value',
		type: 'rupee',
		value: salesOrderDetailsData?.total_net_amount || 0,
	},
	{
		title: 'Invoice Amount',
		type: 'rupee',
		value: salesOrderDetailsData?.correctInvoiceAmount || 0,
		infoDetails: [true, 'Invoice Amount'],
	},
	{
		title: `${salesOrderDetailsData?.brandAjustment?.heading}`,
		type: 'rupee',
		value: salesOrderDetailsData?.brandAjustment?.valueData || 0,
		infoDetails: [salesOrderDetailsData, 'Brand Adjustment'],
		hidden: salesOrderDetailsData?.brandAjustment === false,
	},
	{
		title: 'Outstanding Amount',
		type: 'rupee',
		value: outstandingDisplayValue(salesOrderDetailsData),
	},
	{
		title: 'Debit Note',
		type: 'rupee',
		value: salesOrderDetailsData?.debitNote || 0,
	},
	{
		title: 'Collected Amount',
		type: 'rupee',
		value: salesOrderDetailsData?.OutstandingInvoice?.collected_amount || '0',
	},
	{
		title: 'Delivered Amount',
		type: 'rupee',
		value: DeliverdDisplayValue(salesOrderDetailsData) || 0,
	},
	{
		title: 'Credit Note',
		type: 'rupee',
		value: salesOrderDetailsData?.creditNote || 0,
	},
	{
		title: 'Order Status',
		type: 'status',
		value: salesOrderDetailsData?.status || '',
	},
	{
		title: 'Bill Status',
		value: billStatusOption(salesOrderDetailsData?.OutstandingInvoice?.bill_status),
	},
	{
		title: 'Created Date',
		type: 'date',
		value: createdDate(salesOrderDetailsData?.createdAt),
	},
	{
		title: 'Created time',
		type: 'time',
		value: createdTime(salesOrderDetailsData?.createdAt),
	},
	{
		title: 'Delivered Date',
		type: 'date',
		value: salesOrderDetailsData?.order_delivered_date
			? createdDate(salesOrderDetailsData?.order_delivered_date)
			: '',
	},
	{
		title: 'Cancelled Date',
		type: 'date',
		value: salesOrderDetailsData?.order_cancelled_date
			? createdDate(salesOrderDetailsData?.order_cancelled_date)
			: '',
	},
];
