/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from 'react';
import { Modal, Upload, message, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { CloseCircleFilled, FilePdfFilled, LoadingOutlined } from '@ant-design/icons';
import { config, S3, CognitoIdentityCredentials } from 'aws-sdk';
import { isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Button, Text, Flex } from 'atoms';
import { getUploadfilePath } from 'pages/uploads/uploadsController';
import './uploadModal.scss';
import { postCrateDetails } from 'store/fulfilment/returnToFCSlice';

const BUCKET_NAME = process.env?.REACT_APP_CDMS_SIGNED_INVOICE_BUCKET;
const BUCKET_REGION = process.env?.REACT_APP_BUCKET_REGION;
const IDENTITYPOOL_ID = process.env?.REACT_APP_S3_IDENTITYPOOL_ID;

const { Dragger } = Upload;

const { confirm } = Modal;

const ChooseFileButton = styled(Button)`
	font-size: 18px;
	border-radius: 4px;
	background-color: #107c10;
	width: 400px;
	height: 56px;
`;

const UploadButton = styled(Button)`
	font-size: 16px;
	border-radius: 4px;
	background: #ca5010;
	width: 200px;
	height: 40px;
`;

const StyledCard = styled(Box)`
	border-radius: 4px;
	background-color: #faf9f8;
	padding: 10px 0;
	font-size: 16px;
	position: relative;
	width: 400px;
	height: 66px;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export function UploadInvoiceModal({
	toggleState,
	daysLeft,
	rowData,
	auth,
	setFilesUploaded,
	fileCount,
	crateFieldValues,
	crateData,
	setCrateFieldValues,
	grnUpload = false,
	refreshData = null,
	salesReturnUpload = false,
}) {
	const [modalVisible, setModalVisible] = toggleState;
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [uploading, setUploading] = useState(false);
	const { id } = rowData;
	const dispatch = useDispatch();

	const fileSize = grnUpload || salesReturnUpload ? 15 : 100;

	const acceptableTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
	// const acceptableOnlyPDF = ['application/pdf'];

	const props = {
		name: 'file',
		multiple: !(grnUpload || salesReturnUpload),
		accept: 'image/png, image/jpeg, image/jpg, .pdf',
		showUploadList: false,
		beforeUpload: (file, fileList) => {
			const files = [...selectedFiles, ...fileList];

			if (!acceptableTypes.includes(file.type)) {
				message.error({
					key: 'extensions',
					content: 'Please upload document with image (jpeg,jpg,png) or pdf type',
				});
			} else if ((file.size / 1024 ** 2).toFixed(2) > fileSize) {
				message.error({
					key: 'sizeLimit',
					content: `Please upload document less than ${fileSize} mb`,
				});
			} else if (fileCount + files.length > 20) {
				message.error({
					key: 'maxLimit',
					content: 'You can select upto 20 documents',
				});
			} else if (fileCount + files.length > 2 && grnUpload) {
				message.error({
					key: 'maxLimit',
					content: 'You can select upto 1 document',
				});
			} else {
				setSelectedFiles(files);
			}

			return false;
		},
	};

	const removeSelectedFile = (file) => {
		const index = selectedFiles.indexOf(file);
		const newFileList = selectedFiles.slice();
		newFileList.splice(index, 1);
		setSelectedFiles(newFileList);
	};

	const findDuplicates = (arr) => arr.length !== new Set(arr).size;

	const s3Upload = async (file, Uploadedfiles, nanoId, flagtoCheckUnique) => {
		let uploadMetaData = {};
		if (!isEmpty(file)) {
			Promise.all(
				file?.map((nextfile) => {
					const metafile = getUploadfilePath(
						nextfile,
						file,
						id,
						grnUpload,
						salesReturnUpload
					);

					config.update({
						region: BUCKET_REGION,
						credentials: new CognitoIdentityCredentials({
							IdentityPoolId: IDENTITYPOOL_ID,
						}),
					});

					if (grnUpload || salesReturnUpload) {
						uploadMetaData = {
							created_by_id: String(auth.user.id),
							filename: metafile.filenamePath,
							file_type: nextfile.type.split('/')[1],
							filesize: String(nextfile.size),
						};
						if (grnUpload) {
							uploadMetaData.purchase_order_id = `${rowData?.PurchaseOrderDetails[0]?.purchase_order_id}`;
						} else if (salesReturnUpload) {
							uploadMetaData.brand_return_id = `${id}`;
						}
					} else {
						uploadMetaData = {
							allocation_id: `${id}`,
							created_by_id: String(auth.user.id),
							filename: metafile.filename,
							file_type: nextfile.type.split('/')[1],
							filesize: String(nextfile.size),
						};
					}

					const metaDataObj = {
						Key: metafile.filenamePath,
						Body: nextfile,
						ACL: 'public-read',
						Metadata: uploadMetaData,
					};
					if (salesReturnUpload || grnUpload) {
						metaDataObj.ContentDisposition = 'inline';
						metaDataObj.ContentType = nextfile.type;
					}

					return new S3({
						apiVersion: '2006-03-01',
						params: { Bucket: BUCKET_NAME },
					})
						.upload(metaDataObj, (err, data) => {
							if (err) {
								message.info(`${nextfile.name}: ${err}`);
							} else if (!flagtoCheckUnique) {
								Uploadedfiles.push(data.ETag);
								flagtoCheckUnique = findDuplicates(Uploadedfiles);
								if (flagtoCheckUnique) {
									confirm({
										title: 'Duplicates files',
										content: 'Same files not allowed to upload',
										// eslint-disable-next-line consistent-return
										async onOk() {
											try {
												// setSubmitting(false);
												// resetForm();
												// afterUpload();
											} catch (e) {
												setUploading(false);
												return console.log('Oops errors!');
											}
										},
										onCancel() {},
									});
								} else {
									message.info(`${nextfile.name}  Uploaded Successfully`);
								}
							}
						})
						.promise();
				})
			).then((res) => {
				if (grnUpload || salesReturnUpload) {
					setTimeout(() => {
						message.info(
							`${res[0]?.Location?.split('/')?.pop()}  Uploaded Successfully`
						);
					}, 2500);
					setTimeout(() => {
						refreshData();
						setModalVisible(false);
					}, 5000);
				} else {
					setTimeout(handleCancel, 1000);
				}
			});
		}
	};

	const intiateFileUpload = (fileList) => {
		const Uploadedfiles = [];
		const flagtoCheckUnique = false;
		const nanoId = nanoid();
		if (fileCount + selectedFiles.length > 20) {
			message.error(`You can upload ${20 - fileCount} documents`);
			setUploading(false);
		} else {
			s3Upload(fileList, Uploadedfiles, nanoId, flagtoCheckUnique);
		}
	};

	const handleCancel = () => {
		setModalVisible(false);
		setFilesUploaded((c) => c + 1);

		setTimeout(() => {
			setUploading(false);
			window.location.reload();
		}, 1000);
	};

	return (
		<Modal
			className="upload-modal"
			destroyOnClose
			visible={modalVisible}
			onOk={() => setModalVisible(false)}
			onCancel={() => {
				setModalVisible(false);
			}}
			footer={null}
			width="717px"
			maskClosable={false}
		>
			{uploading && (grnUpload || salesReturnUpload) && (
				<div
					style={{
						opacity: '0.9',
						height: 'calc(100% - 48px)',
						width: 'calc(100% - 48px)',
						position: 'absolute',
						zIndex: '2',
						background: '#fff',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: '18px',
					}}
				>
					<Spin
						indicator={
							<LoadingOutlined
								style={{
									fontSize: 42,
								}}
								spin
							/>
						}
					/>
					<div style={{ fontSize: '32px' }}>Uploading...</div>
				</div>
			)}
			<Box textAlign="center">
				<Text textAlign="center" fontSize="24px" fontWeight="400">
					Upload
				</Text>
				{daysLeft ? (
					<Text
						mt="14px"
						mb="14px"
						textAlign="center"
						fontSize="12px"
						fontWeight="400"
						color="#6A6A6A"
						fontStyle="italic"
					>
						{`${daysLeft} days left to upload Document`}
					</Text>
				) : null}
				{(grnUpload || salesReturnUpload) && selectedFiles < 1 ? (
					<Dragger {...props}>
						<Text fontSize="20px" fontWeight="400">
							Drag and Drop File here...
						</Text>
						<Text mt="10px" mb="10px" fontSize="18px" fontWeight="400">
							or
						</Text>
						<ChooseFileButton>Choose File</ChooseFileButton>
					</Dragger>
				) : null}
				{!(grnUpload || salesReturnUpload) && (
					<Dragger {...props}>
						<Text fontSize="20px" fontWeight="400">
							Drag and Drop File here...
						</Text>
						<Text mt="10px" mb="10px" fontSize="18px" fontWeight="400">
							or
						</Text>
						<ChooseFileButton>Choose File</ChooseFileButton>
					</Dragger>
				)}
				{selectedFiles.length > 0 && (
					<Flex flexDirection="column" alignItems="center">
						<Text mt="18px" mb="18px" fontSize="16px" fontWeight="400">
							Selected File List
						</Text>
						{selectedFiles.length > 0 &&
							selectedFiles?.map((fileName) => (
								<StyledCard key={fileName.uid}>
									<Text>
										<FilePdfFilled
											style={{ marginRight: '15px', color: '#F40F02' }}
										/>
										{fileName.name}
									</Text>
									<CloseCircleFilled
										style={{
											position: 'absolute',
											right: '-6px',
											top: '-8px',
											color: '#CA5010',
										}}
										onClick={() => removeSelectedFile(fileName)}
									/>
								</StyledCard>
							))}
						<UploadButton
							textAlign="center"
							mt="30px"
							onClick={() => {
								setUploading(true);
								if (
									crateData?.crate_log?.dispatched_crate > 0 &&
									crateFieldValues?.edit
								) {
									dispatch(postCrateDetails(id, crateFieldValues)).then((res) => {
										setCrateFieldValues({ ...crateFieldValues, edit: false });
										console.log('success', res);
									});
								}
								intiateFileUpload(selectedFiles);
							}}
							loading={uploading}
						>
							{uploading ? 'Loading' : 'Upload'}
						</UploadButton>
					</Flex>
				)}
			</Box>
		</Modal>
	);
}

UploadInvoiceModal.propTypes = {
	toggleState: PropTypes.array,
	daysLeft: PropTypes.string,
	grnUpload: PropTypes.bool,
	salesReturnUpload: PropTypes.bool,
	rowData: PropTypes.object,
	auth: PropTypes.object,
	setFilesUploaded: PropTypes.func,
	fileCount: PropTypes.number,
	crateFieldValues: PropTypes.object,
	crateData: PropTypes.object,
	setCrateFieldValues: PropTypes.func,
	multiple: PropTypes.bool,
	refreshData: PropTypes.func,
};
