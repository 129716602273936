import styled from 'styled-components';
import { SubmitButton } from 'formik-antd';
import { uniqBy } from 'lodash';
import { Flex, Box } from 'atoms';

export const BtnWrap = styled(Flex)`
	justify-content: flex-end;
`;

export const StyledSubmitButton = styled(SubmitButton)`
	border: 0;
	border-radius: 0;
	background-color: ${(props) => props.theme.colors.primary};
	color: ${(props) => props.theme.colors.white};
	display: flex;
	&:hover {
		background-color: ${(props) => props.theme.colors.primary};
		color: ${(props) => props.theme.colors.white};
	}
`;

export const renderBrandOptions = (options, onhandleClose = null, hasmultiple = false) => (
	<Box title={options.label} className="ant-select-selection-item" as="span">
		<Box as="span" className="ant-select-selection-item-content">
			{options.label}
		</Box>
		<Box
			className="ant-select-selection-item-remove"
			as="span"
			onClick={() => (hasmultiple ? onhandleClose(options.value) : onhandleClose())}
		>
			x
		</Box>
	</Box>
);

// #region User form Configs

export const UserInitialValues = (userFormData, editMode, brandList) => {
	let filterBrands = [];

	if (editMode) {
		const uniqueBrands = uniqBy(
			userFormData?.fcbranddetail?.map((x) => `${x.fc_id}_${x.brand_id}`),
			(x) => x
		);
		filterBrands = brandList?.filter((x) => uniqueBrands.includes(x.value));
	}

	return {
		user_types: userFormData?.userGroup?.[0]?.group_id || undefined,
		first_name: userFormData?.userdetail?.first_name || undefined,
		last_name: userFormData?.userdetail?.last_name || undefined,
		mobile_no: userFormData?.userdetail?.mobile_no || undefined,
		email: userFormData?.userdetail?.email || undefined,
		password: !editMode ? userFormData?.userdetail?.password : undefined,
		fcs: [],
		brands: editMode ? filterBrands : undefined,
	};
};

// #endregion
