/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Box, Flex } from 'atoms';
import { getCollectionById } from 'store/collectionSlice';
import { DateFormat, Rupee } from 'components';
import { getInvStatus } from 'utils';
import CollectionStatus from 'components/pages/collections/collectionStatus';

const LabelText = styled(Box)`
	font-size: 14px;
	color: #000;
	font-weight: ${(props) => (props.lightWeight ? '400' : '700')};
	margin-bottom: 6px;
`;

const ValueText = styled(Box)`
	font-size: 13px;
	color: #000;
	margin-bottom: 8px;
`;

export default function CollectionDetailPage() {
	const { id: collectionId } = useParams();
	const dispatch = useDispatch();
	const [data, setData] = useState({ type: 'NotAsked' });

	useEffect(() => {
		setData({ type: 'Loading' });
		dispatch(getCollectionById({ id: collectionId })).then((res) =>
			setData({ type: 'Sucess', data: res })
		);
	}, [dispatch, collectionId]);

	if (data.type === 'Loading' || data.type === 'NotAsked') {
		return (
			<Box width="100%" textAlign="center">
				Loading...
			</Box>
		);
	}
	return (
		<Box bg="white" p="2rem">
			<Flex flexWrap="wrap" marginBottom="10px" justifyContent="space-between">
				<Box flex="1 1 25%" marginBottom="15px">
					<LabelText>Collection Date</LabelText>
					<ValueText>
						<DateFormat date={data?.data?.collection_date} />
					</ValueText>
				</Box>
				<Box flex="1 1 25%" marginBottom="15px">
					<LabelText>FC</LabelText>
					<ValueText>{data?.data?.FC?.name || ''}</ValueText>
				</Box>
				<Box flex="1 1 25%" marginBottom="15px">
					<LabelText>Brand</LabelText>
					<ValueText>{data?.data?.Brand?.name || ''}</ValueText>
				</Box>
				<Box flex="1 1 25%" marginBottom="15px">
					<LabelText>Store</LabelText>
					<ValueText textTransform="capitalize">
						{data?.data?.Store?.name || ''}
					</ValueText>
				</Box>
				<Box flex="1 1 25%" marginBottom="15px">
					<LabelText>Invoice No.</LabelText>
					<ValueText textTransform="uppercase">{data?.data?.invoice_no || ''}</ValueText>
				</Box>
				<Box flex="1 1 25%" marginBottom="15px">
					<LabelText>Invoice Amount</LabelText>
					<ValueText>
						<Rupee>{data?.data?.invoice_amount || ''}</Rupee>
					</ValueText>
				</Box>
				<Box flex="1 1 25%" marginBottom="15px">
					<LabelText>Collected Amount</LabelText>
					<ValueText>
						<Rupee>
							{(data?.data?.initial_outstanding_amount || 0) -
								(data?.data?.current_outstanding_amount || 0)}
						</Rupee>
					</ValueText>
				</Box>
				<Box flex="1 1 25%" marginBottom="15px">
					<LabelText>Invoice Status</LabelText>
					<ValueText>{getInvStatus(data?.data?.status)}</ValueText>
				</Box>
				{data?.data?.verified_by_segregator && (
					<Box flex="1 1 25%" marginBottom="15px">
						<LabelText>Segregator Verification</LabelText>
						<ValueText>
							<Box>{data?.data?.verified_by_segregator?.full_name || ''}</Box>
						</ValueText>
					</Box>
				)}

				{data?.data?.verified_by_cashier && (
					<Box flex="1 1 25%" marginBottom="15px">
						<LabelText>Cashier Verification</LabelText>
						<ValueText>
							<Box>{data?.data?.verified_by_cashier?.full_name || ''}</Box>
						</ValueText>
					</Box>
				)}

				{data?.data?.filled_by_delivery_boy && (
					<Box flex="1 1 25%" marginBottom="15px">
						<LabelText>Filled By Delivery Boy</LabelText>
						<ValueText>
							<Box>{data?.data?.filled_by_delivery_boy?.full_name || ''}</Box>
						</ValueText>
					</Box>
				)}

				{data?.data?.filled_by_additional_resource && (
					<Box flex="1 1 25%" marginBottom="15px">
						<LabelText>Filled By Additional Resource</LabelText>
						<ValueText>
							<Box>{data?.data?.filled_by_additional_resource?.full_name || ''}</Box>
						</ValueText>
					</Box>
				)}

				{data?.data?.filled_by_salesman && (
					<Box flex="1 1 25%" marginBottom="15px">
						<LabelText>Filled By SalesMan</LabelText>
						<ValueText>
							<Box>{data?.data?.filled_by_salesman?.full_name || ''}</Box>
						</ValueText>
					</Box>
				)}
				<Box flex="1 1 25%" marginBottom="15px"></Box>
				<Box flex="1 1 25%" marginBottom="15px"></Box>
			</Flex>
			<Box marginBottom="15px" fontWeight="700" mt="3rem" mb="2rem">
				Payment Info
			</Box>

			<CollectionStatus data={data?.data?.payments} />
			{/* {data?.data?.payments?.map((x) => (
				<Flex
					key={x.payment_type}
					flexWrap="wrap"
					padding="8px 0"
					borderBottom="0.01em solid #0000001a"
					marginBottom="5px"
					alignItems="center"
					style={{ display: 'none' }}
				>
					<Box flex="0 0 16%" marginBottom="15px">
						<LabelText lightWeight as="span">
							Payment Type:
						</LabelText>{' '}
						<LabelText as="span">{x.payment_type}</LabelText>
					</Box>
					<Box flex="0 0 10%" marginBottom="15px">
						<LabelText lightWeight as="span">
							Amount:
						</LabelText>{' '}
						<LabelText lightWeight as="span">
							<Rupee>{x.amount}</Rupee>
						</LabelText>
					</Box>
					{x?.reference_number && (
						<Box flex="0 0 20%" marginBottom="15px">
							<LabelText lightWeight as="span">
								Refernce Number:
							</LabelText>{' '}
							<LabelText lightWeight as="span">
								{x.reference_number}
							</LabelText>{' '}
						</Box>
					)}
					{x?.due_date && (
						<Box flex="0 0 20%" marginBottom="15px">
							<LabelText lightWeight as="span">
								Due Date:
							</LabelText>{' '}
							<LabelText lightWeight as="span">
								<DateFormat date={x.due_date} />
							</LabelText>
						</Box>
					)}
					<Box flex="0 0 20%" marginBottom="15px">
						<LabelText lightWeight as="span">
							Verification Status:
						</LabelText>{' '}
						<LabelText lightWeight as="span">
							{x.payment_verification_status || '-'}
						</LabelText>
					</Box>
					<Box flex="0 0 20%" marginBottom="15px">
						<LabelText lightWeight as="span">
							Settlement Status:``
						</LabelText>{' '}
						<LabelText lightWeight as="span">
							{x.payment_settlement_status || '-'}
						</LabelText>
					</Box>
				</Flex>
			))} */}
		</Box>
	);
}
