import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';
import { getSalesOrderList, getStockFetchStatusList } from 'store/fulfilment/salesOrderSlice';
import { Box, Flex, Button, Text } from 'atoms';
import { RCTable, SearchBar, LabelWrapper, WMSMessage } from 'components/common';
import { missingInvoicesColumns, handleFilterChange } from 'utils/tableConfig';
import { MissingInvoicesQueries, SelectFilterLabelOptions, searchBox, ORDER_STATUSES } from 'utils';
import { SearchField } from 'components/common/custom-table/Style';
import { useQueryParams } from 'hooks';

const MISSING_INVOICES = ['OP', 'OC', 'PP'];

export default function MissingInvoices() {
	const dispatch = useDispatch();
	const [rowData, setRowData] = useState({});
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [rowSelect, setrowSelect] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [wmsSuccessMessage, setWmsSuccessMessage] = useState(null);
	const [paginateConfig, setpaginateConfig] = useQueryParams({
		filterQuery: MissingInvoicesQueries,
	});

	const rowSelection = {
		onChange: (selectedRowKeys) => {
			setrowSelect([...selectedRowKeys]);
		},
	};

	function handleFetchStatus() {
		dispatch(getStockFetchStatusList(rowSelect)).then((res) => {
			setWmsSuccessMessage(res?.message);
			setIsModalOpen(true);
		});
	}

	function hideModal() {
		setIsModalOpen(false);
		window.location.reload();
	}

	return (
		<>
			<Flex alignItems="center">
				<Flex width="100%" flexWrap="wrap" alignItems="center" gap="4px">
					<Box marginLeft="1rem">
						<LabelWrapper label="Search by Invoice Number" marginspace="0 0 5px 0">
							<SearchField>
								<SearchBar
									placeholder="Search by Invoice Number"
									queryValue={paginateConfig?.queryInvoice?.replaceAll('*', '')}
									onSearch={(val) =>
										setpaginateConfig((prev) => ({
											...prev,
											limit: 10,
											offset: 0,
											currentPage: 1,
											queryInvoice: val,
										}))
									}
								/>
							</SearchField>
						</LabelWrapper>
						<LabelWrapper label="Status(s)" marginspace="3px 0 -1px 1rem">
							<Select
								name="statuses"
								style={searchBox}
								allowClear
								showSearch
								options={ORDER_STATUSES?.filter((item) =>
									MISSING_INVOICES?.includes(item?.value)
								)}
								value={paginateConfig?.statuses}
								filterOption={SelectFilterLabelOptions}
								mode="multiple"
								placeholder="Status(s)"
								onChange={(value) =>
									setpaginateConfig((prev) =>
										handleFilterChange('statuses', value, prev)
									)
								}
							/>
						</LabelWrapper>
						<Button onClick={() => setBtnClickSearh(Math.random())}>Search</Button>
					</Box>
				</Flex>
				<Flex flexDirection="column" alignItems="center">
					<Button
						onClick={handleFetchStatus}
						disabled={rowSelect?.length === 0 || rowSelect?.length > 3}
					>
						Fetch Status
					</Button>
					<Text fontSize="10px" mt="0.5rem" color="red">
						* Max 3 Invoices
					</Text>
				</Flex>
			</Flex>
			<Box mt={3}>
				<RCTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					rowSelection={{
						type: 'checkbox',
						preserveSelectedRowKeys: true,
						hideSelectAll: true,
						...rowSelection,
					}}
					dispatchAction={getSalesOrderList}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					columns={missingInvoicesColumns({
						defaultSort: paginateConfig?.sort_direction || 'DESC',
						defaultsortField: paginateConfig?.sort_column || 'id',
					})}
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'id',
					}}
					rowClassName={(record) => {
						if (rowSelect?.length > 2) {
							return rowSelect?.includes(record.id) ? '' : 'disabled-div';
						}
						return '';
					}}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
			<WMSMessage visible={isModalOpen} handleHide={hideModal} data={wmsSuccessMessage} />
		</>
	);
}
