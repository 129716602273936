import * as Yup from 'yup';
import { isNumber } from 'lodash';
import { validateMobileNumber, validateRipplrEmail } from 'utils/fieldValidation';

export const FormSchema = (editpage) =>
	Yup.object({
		user_types: Yup.string().required().label('User Type'),
		first_name: Yup.string()
			.required()
			.min(4)
			.max(30)
			.label('First Name')
			.matches(/^[a-zA-Z0-9]*$/, 'Please enter valid first name'),
		last_name: Yup.string()
			.required()
			.label('Last Name')
			.matches(/^[a-zA-Z0-9]*$/, 'Please enter valid last name'),
		mobile_no: Yup.string()
			.label('Phone Number')
			.test('Not a valid phone number', (value) => validateMobileNumber(value)),
		email: Yup.string().when('user_types', {
			is: (user_types) => Number(user_types) === 12,
			then: Yup.string().nullable(true),
			otherwise: Yup.string()
				.required()
				.email('Must be a valid email')
				.label('Email')
				.test('is-RipplrEmail', 'Not a valid Ripplr Email', (value) =>
					validateRipplrEmail(value)
				),
		}),
		...(!editpage
			? {
					password: Yup.string().when(['user_types', 'user_id'], {
						is: (user_types, user_id) => Number(user_types) === 12 || isNumber(user_id),
						then: Yup.string().nullable(true),
						otherwise: Yup.string()
							.required()
							.label('Password')
							.min(6)
							.max(16)
							.matches(
								'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$])(?![!@#$])[a-zA-Z0-9@#$!]+$',
								'Password should contain upper case letter[A-Z], lower case letter[a-z],number[0-9] and special characters[!@#$] (password should not start with a special character)'
							),
					}),
			  }
			: {}),
		fcs: Yup.array(),
		brands: Yup.array().required().min(1).label('FC:Brands'),
	});
