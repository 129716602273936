import { Text } from 'atoms';
import DateFormat from 'components/DateFormat';

export const bankListColumns = ({ defaultSort, defaultsortField }) => {
	const sortDirection = defaultSort === 'ASC' ? 'ascend' : 'descend';
	return [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.name - b.name,
			sortOrder: defaultsortField === 'name' ? sortDirection : false,
			overflow: 'hidden',
			hidden: false,
			render: (name) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="capitalize">
					{name?.toLowerCase() || ''}
				</Text>
			),
		},
		{
			title: 'Code',
			dataIndex: 'code',
			key: 'code',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (code) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{code || ''}
				</Text>
			),
		},
		{
			title: 'Country',
			dataIndex: 'country',
			key: 'country',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (country) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="capitalize">
					{country?.toLowerCase() || ''}
				</Text>
			),
		},
		{
			title: 'Created At',
			dataIndex: 'createdAt',
			key: 'createdAt',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (createdAt) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={createdAt} format="HMSA" />
				</Text>
			),
		},
	];
};
