import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import { API } from 'api';
const initialState = {};

export const FinanceTeamSlice = createSlice({
	name: 'finance',
	initialState,
	reducers: {},
});

const URL = process.env.REACT_APP_CHEQUE_BOUNCE_API_BASE_URL;
const CHEQUE_BOUNCE_URL = process.env.REACT_APP_CHEQUE_BOUNCE_API_BASE_URL;

export const getChequeBounceList = (text, filters, isCashier) => {
	const query = {
		url: `${URL}/cheque-bounce-list`,
		method: 'get',
		queryParams: {
			// eslint-disable-next-line no-unneeded-ternary
			'cheque-number': text ? text : null,
			brand_id: filters?.brands?.length !== 0 ? JSON.stringify(filters?.brands) : null,
			fc_id: filters?.fc_id?.length !== 0 ? JSON.stringify(filters?.fcs) : null,
			status: filters?.statuses?.length !== 0 ? filters?.statuses : null,
			cashier: isCashier ? 'True' : undefined,
		},
	};

	return API.common(query).then((response) => response.data);
};

export const getInvoiceMappings = (data) => {
	console.log(data);
	const query = {
		url: `${URL}/invoice-mappings`,
		method: 'get',
		queryParams: {
			'cheque-number': data?.cheque_number,
		},
	};
	return API.common(query).then((response) => response.data);
};

export const createChequeBounceEntry = (invoice, formData, boun_date) => {
	const selectedInvoices = {
		cheque_number: formData.cheque_number,
		due_date: moment(invoice.due_date).format('YYYY-MM-DD'),
		bounce_date: boun_date,
		fc_id: invoice.fc_id,
		brand_id: invoice.brand_id,
		store_id: invoice.store_id,
		collection_invoice_id: invoice.collection_invoice_id,
		bank_id: invoice.bank_id,

		'invoice-details': invoice.invoice_details,
		amount: invoice.store_amount,
	};

	const query = {
		url: `${URL}/cheque-bounce`,
		method: 'post',
		data: selectedInvoices.length === 1 ? selectedInvoices[0] : selectedInvoices,
	};
	return API.common(query).then((response) => response.data);
};

export const updateChequeStatus = (data) => {
	const chequeDetails = {
		cheque_number: data?.cheque_number,
		store_id: data.store_id,
		hash_key: data.cheque_bounce_unique_column_hash,
		collection_invoice_id: data.collection_invoice_id,
		bank_id: data.bank_id,
	};
	const query = {
		url: `${URL}/update-cheque-number-status`,
		method: 'post',
		data: chequeDetails,
	};
	return API.common(query).then((response) => response.data);
};

export const getChequeBounceDeleteOptions = () => async () => {
	const CREDENTIALS = {
		url: `${CHEQUE_BOUNCE_URL}/voidChequeReasons`,
		method: 'get',
	};

	return API.common(CREDENTIALS).then((response) => response.data);
};

export const markChequeVoid = (data, void_cheque_reason) => async () => {
	const CREDENTIALS = {
		url: `${CHEQUE_BOUNCE_URL}/markChequeVoid`,
		method: 'post',
		data: {
			cheque_number: data?.cheque_number,
			hash_key: data?.cheque_bounce_unique_column_hash,
			store_id: data?.store_id,
			void_cheque_reason,
			cids: data?.collection_invoice_id,
		},
	};

	return API.common(CREDENTIALS).then((response) => response);
};

export default FinanceTeamSlice.reducer;
