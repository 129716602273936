import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'atoms';

export default function InvoiceStatus({ invoice }) {
	function getNewStatus() {
		if (invoice?.is_invoice_missing === '1') {
			return <Text color="red">Invoice Missing</Text>;
		}
		if (invoice?.status === 'No Bill Back') {
			return <Text color="green">Invoice Settled</Text>;
		}
		if (invoice?.status === 'Bill Back') {
			return <Text color="#F09000">Invoice Returned</Text>;
		}
		if (invoice?.status === 'Pending') {
			return <Text color="rgb(0, 127, 255);">Pending</Text>;
		}
		return invoice?.status;
	}

	return getNewStatus();
}

InvoiceStatus.propTypes = {
	invoice: PropTypes.any,
};
