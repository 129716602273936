/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useACL } from 'hooks';
import { Contaniner } from 'components/common/sidebar/NavLinks.style';

function getActivePath(sidebarMenus, location) {
	return sidebarMenus
		.map((x) => {
			if (x.children) {
				return x?.children
					?.map((y) => {
						if (y?.children) {
							return y?.children?.map((z) =>
								location.pathname?.includes(z?.linkto) ? z : false
							);
						}
						return location.pathname?.includes(y?.linkto) ? y : false;
					})
					.flat()
					.filter((a) => a);
			}
			return x;
		})
		?.flat()
		?.filter((x) => location.pathname?.includes(x?.linkto))[0]?.key;
}

const submenuKeys = ['onboarding', 'warehousemanagement', 'logisticsmanagement', 'ordermanagement'];

export default function NavLinks() {
	const { sidebarMenus } = useACL();
	const location = useLocation();
	const [defaultKey, setdefaultKey] = useState([]);
	const [openKeys, setOpenKeys] = useState([]);
	const { sideBarStatus } = useSelector((state) => state.app);

	const onOpenChange = (keys) => {
		const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

		if (submenuKeys.indexOf(latestOpenKey) === -1) {
			setOpenKeys(keys);
		} else {
			setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
		}
	};

	useEffect(() => {
		if (location?.pathname) {
			const getPathActive = getActivePath(sidebarMenus, location);
			const splitMenus = getPathActive?.split('-');
			const menusSplittes =
				splitMenus?.length >= 2
					? [splitMenus[0], `${splitMenus[0]}-${splitMenus[1]}`]
					: [splitMenus?.[0]];
			setOpenKeys(menusSplittes || []);
			setdefaultKey([getPathActive] || []);
		}
	}, [location]);

	return (
		<Contaniner inlineCollapsed={sideBarStatus}>
			<Menu
				openKeys={openKeys}
				onOpenChange={onOpenChange}
				items={sidebarMenus}
				mode="inline"
				selectedKeys={defaultKey}
				inlineCollapsed={sideBarStatus}
			/>
		</Contaniner>
	);
}
