import moment from 'moment';
import styled from 'styled-components';
import { Upload } from 'antd';
import { Rupee } from 'components';
import { Box, Button, Flex, Text } from 'atoms';
export const chequeSettlementTableConfig = [
	{
		title: (
			<Box textAlign="center" fontSize="12px" color="#646464">
				Cheque number
			</Box>
		),
		dataIndex: 'referenceNumber',
		key: 'referenceNumber',
		render: (cheque_number) => (
			<Box textAlign="center" color="#000">
				{cheque_number}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontSize="12px" color="#646464">
				Bank
			</Box>
		),
		dataIndex: 'bankName',
		key: 'bankName',
		render: (bankName) => (
			<Box textAlign="center" color="#000">
				{bankName || ''}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontSize="12px" color="#646464">
				Amount
			</Box>
		),
		dataIndex: 'amount',
		key: 'amount',
		render: (amount) => (
			<Box textAlign="center" color="#000">
				<Rupee>{amount || 0}</Rupee>
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontSize="12px" color="#646464">
				Due Date
			</Box>
		),
		dataIndex: 'dueDate',
		key: 'dueDate',
		render: (dueDate) => (
			<Box textAlign="center" color="#000">
				{dueDate ? moment(dueDate).format('DD/MM/YYYY') : ''}
			</Box>
		),
	},
];
export const BoxFlx = styled(Box)`
	width: 50%;
	margin-top: 50px;
`;
export const CHEQUE_MAX_COUNT = 200;
export const pdfimgformates = ['application/pdf', 'image/png', 'image/jpeg'];
export const FlexWrap = styled(Flex)`
	padding: 10px 16px;
	margin: 10px 0;
	background: aliceblue;
	justify-content: space-between;
	align-items: center;
`;
export const UploadWrap = styled(Upload)`
	display: flex;
	width: 100%;
	cursor: pointer;
	border-radius: 3px;
	background: #fff;
	min-height: 35px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 5px;
	margin-bottom: 8px;
	.ant-upload-list-item {
		min-height: 30px;
	}
	.ant-upload-list-item-name {
		word-break: break-all;
		white-space: normal;
		display: flex;
		line-height: 14px;
	}
`;
export const Btn = styled(Button)`
	max-height: 30px;
	margin-top: 16px;
`;
export const CommonButtons = styled(Button)`
	font-size: 12px;
	border-radius: 4px;
	line-height: 15px;
	align-self: center;
	height: 35px;
`;
export const TextHeader = styled(Text)`
	font-size: 18px;
	font-weight: 600;
`;
