import React from 'react';
import { Col, Row, Cascader } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select, DatePicker, SubmitButton } from 'formik-antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { unScReplace, SelectFilterOptions } from 'utils';
import { BtnWrap, renderBrandOptions } from '../add-user/helper';
import { Button, FormInput } from 'atoms';
import { theme } from 'styles/theme';
import { FormSchema } from './Validations';
import { Debug } from 'components/forms';

const { RangePicker } = DatePicker;

const { Option } = Select;

const dropDown = [
	{
		_id: 1,
		user_type: 'deliverer',
	},
	{
		_id: 2,
		user_type: 'billing_executive,deliverer',
	},
];

const btnSubmit = {
	margin: '10px',
	width: '150px',
	height: '40px',
	fontSize: '12px',
	backgroundColor: `${theme.colors.primary}`,
	borderColor: `${theme.colors.primary}`,
};

function LeaveManagementForm({ applyLeaveToggle, fillFcBrands }) {
	const dispatch = useDispatch();

	console.log('applyLeaveToggle ', applyLeaveToggle);

	const { fcBrandsList } = useSelector((state) => state.user);

	const initialValues = {
		employee_name: '',
		date_filter: undefined,
		reason: '',
		fcBrands: undefined,
	};

	const tagRenderFn = (prop, formValues, setFieldValue) => {
		const { value, onClose } = prop;
		if (!value.includes('__RC_CASCADER_SPLIT__')) {
			const filterOpt = fcBrandsList.filter((data) => data.value === Number(value));
			const onhandleClose = (val) => {
				const updatedOptions = filterOpt[0].children
					.map((data) => (data.value !== val ? [Number(value), data.value] : false))
					.filter((x) => x);
				const updatedValues = formValues.fcBrands.filter((x) => x[0] !== Number(value)); // remove the previous key
				onClose();
				setFieldValue('fcBrands', [...updatedValues, ...updatedOptions]);
			};

			return filterOpt[0].children.map((opt) => (
				<React.Fragment key={opt.label}>
					{renderBrandOptions(opt, onhandleClose, true)}
				</React.Fragment>
			));
		}
		return renderBrandOptions(prop, onClose);
	};

	function handleSubmit(values, { setErrors, resetForm, setSubmitting }) {
		console.log('values', values);
		dispatch(setErrors)
			.then(() => {
				setSubmitting(false);
				resetForm();
			})
			.catch(() => {
				setSubmitting(false);
			});
	}

	return (
		<Formik
			enableReinitialize
			validationSchema={FormSchema}
			onSubmit={handleSubmit}
			initialValues={initialValues}
		>
			{({ values, setFieldValue, isSubmitting }) => (
				<Form layout="vertical">
					<Row gutter={8} className="user__form" justify="space-between">
						<Col lg={8} sm={24}>
							<FormInput
								name="fcBrands"
								label="*FC & Brands"
								className="test-fcBrands"
							>
								<Cascader
									name="fcBrands"
									style={{ width: '100%' }}
									value={values.fcBrands}
									options={fcBrandsList}
									onChange={(e) => {
										setFieldValue('fcBrands', e);
									}}
									defaultValue={fillFcBrands}
									maxTagCount="100"
									showSearch
									allowClear={values?.user_types !== 12}
									tagRender={(prop) => tagRenderFn(prop, values, setFieldValue)}
								/>
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput
								name="date_filter"
								hasFeedback={false}
								showValidateSuccess={false}
								label="*Date Range"
								labelCol={{ span: 24 }}
								wrapperCol={{ span: 24 }}
							>
								<RangePicker className="rangePicker" name="date_filter" />
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput name="employee_name" label="*Employee Name">
								<Select
									showSearch
									name="employee_name"
									placeholder="Employee Name"
									filterOption={SelectFilterOptions}
									allowClear
								>
									{dropDown?.map((data3) => (
										<Option key={data3?._id} value={data3?._id}>
											{unScReplace(data3?.user_type)}
										</Option>
									))}
								</Select>
							</FormInput>
						</Col>
					</Row>
					<Row className="user__form" gutter={8} justify="space-between">
						<Col span={24}>
							<FormInput
								style={{ width: '100%' }}
								name="reason"
								hasFeedback={false}
								showValidateSuccess={false}
								label="*Reason"
								labelCol={{ span: 24 }}
								wrapperCol={{ span: 24 }}
							>
								<Input name="reason" />
							</FormInput>
						</Col>
					</Row>
					<BtnWrap>
						<Button
							onClick={() => applyLeaveToggle(false)}
							type="button"
							variant="secondary"
						>
							Cancel
						</Button>
						<SubmitButton disabled={isSubmitting} style={btnSubmit}>
							Accept & Add
						</SubmitButton>
					</BtnWrap>
					{process.env.NODE_ENV === 'development' && <Debug />}
				</Form>
			)}
		</Formik>
	);
}

LeaveManagementForm.propTypes = {
	applyLeaveToggle: PropTypes.func,
	fillFcBrands: PropTypes.array,
};

export default LeaveManagementForm;
