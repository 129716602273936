/* eslint-disable sonarjs/cognitive-complexity */
export const brandSalesReturnShowPanel = ({ showData = {} }) => [
	{
		title: 'FC',
		value: showData?.brand_data?.fc || '',
		additionalValue: showData?.brand_data?.fc_code || '',
	},
	{
		title: 'Brand',
		value: showData?.brand_data?.brand || '',
		additionalValue: showData?.brand_data?.brand_code || '',
	},
	{
		title: 'Return No',
		value: showData?.brand_data?.return_no || '',
		type: 'upper',
	},
	{
		title: 'Invoice No',
		value: showData?.brand_data?.invoice_no || '',
		type: 'upper',
	},
	{
		title: 'Store',
		value: showData?.brand_data?.store || '',
		additionalValue: showData?.brand_data?.store_code || '',
	},
];
