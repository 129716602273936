import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import StatusIndicator from 'components/common/StatusIndicator';
import { getColor } from 'utils';
import { UserStatusModal } from 'components/modals';

const StatusContainer = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	height: 24px;
	width: 75px;
	border: 1px solid
		${(props) =>
			props.statusText === 'Inactive'
				? props.theme.colors.FireOpal
				: props.theme.colors.Ming};
	cursor: pointer;
	flex-direction: ${(props) => (props.statusText === 'Inactive' ? 'row' : 'row-reverse')};
`;

const StatusText = styled.p`
	font-size: 12px;
	line-height: 14px;
	color: ${(props) => getColor(props)};
	margin-right: ${(props) => props?.status === 'Active' && '.5rem'};
	margin-left: ${(props) => props?.status === 'Inactive' && '.3rem'};
`;

export default function UserStatus({ data: { user_id, is_active } }) {
	const [modalVisible, setModalVisible] = useState(false);
	const modalClose = () => setModalVisible(false);
	const statusText = is_active === 0 ? 'Inactive' : 'Active';

	return (
		<>
			<StatusContainer onClick={() => setModalVisible(true)} statusText={statusText}>
				<StatusIndicator status={statusText} />
				<StatusText status={statusText}>{statusText}</StatusText>
			</StatusContainer>
			{modalVisible && (
				<UserStatusModal
					status={statusText}
					userId={user_id}
					modalVisible={modalVisible}
					modalClose={modalClose}
				/>
			)}
		</>
	);
}

UserStatus.propTypes = {
	data: PropTypes.shape({
		user_id: PropTypes.number,
		is_active: PropTypes.number,
	}),
};
