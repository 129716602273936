/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { message, Collapse, Button, Tabs, Row, Col } from 'antd';
import { Box, Text, Image, Flex } from 'atoms';
import { AccordionHeader, PanelData } from 'components/common';
import {
	getSalesOrderAllocation,
	getSalesOrderById,
	getSalesReturnList,
	getCollectionData,
	getOrderJourneyData,
} from 'store/fulfilment/salesOrderSlice';
import { AllocationDetailsModal, EpodNotAvailableModal } from 'components/modals';
import ShippingIcon from 'assets/shipping-icon.svg';
import SalesOrderShowTable from 'pages/fulfillment/sales-order/SalesOrderShowTable';
import { returnToFcShowPanel } from './salesOrderConfig';
import { ShowUploadListModal } from 'components/modals/ShowUploadListModal';
import SalesOrderReturnTable from './SalesOrderReturnTable';
import OrderJourney from './OrderJourney';
import CollectionHistory from './CollectionHistory';
import { MultipleImagePreview } from 'components';
import { getImagesList } from 'store/fulfilment/returnToFCSlice';
import { UserPersmission } from 'utils';

const { Panel } = Collapse;

const { TabPane } = Tabs;

const StyledInvoiceCopyButton = styled(Button)`
	border-color: #448ef7;
	color: #448ef7;
	font-size: 14px;
	border-radius: 4px;
	height: 40px;
	width: 180px;
	.ant-btn:hover,
	.ant-btn:focus {
		color: #fff;
		border-color: #448ef7;
		background: #448ef7;
	}
	&:hover {
		background-color: transparent;
		color: #448ef7;
		border-color: #448ef7;
	}
`;

export default function SalesOrderView() {
	const [showAllocationDetail, setShowAllocationDetail] = useState(false);
	const location = useLocation();
	const dispatch = useDispatch();
	const { id } = useParams();
	const { auth } = useSelector((state) => state?.auth);
	const allowedUser = auth?.user?.groups[0].name;
	const searchParams = new URLSearchParams(location.search);
	const navigate = useNavigate();
	const [salesOrderList, setSalesOrderList] = useState({});
	const [salesOrderDetailsData, setsalesOrderDetailsData] = useState({});
	const [viewInvoiceModal, setViewInvoiceModal] = useState(false);
	const [salesReturnTabData, setSalesReturnTabData] = useState();
	const [collectionData, setCollectionData] = useState();
	const [orderJourneyData, setOrderJourneyData] = useState();
	const [imageList, setImageList] = useState([]);
	const [isSuperAdmin, setIsSuperAdmin] = useState(false);

	useEffect(() => {
		if (!isEmpty(id)) {
			if (location?.state?.from?.pathname) {
				dispatch(getSalesReturnList(id)).then((res) => {
					setSalesReturnTabData(res);
				});
			}

			setIsSuperAdmin(auth?.user?.is_superuser);

			dispatch(getSalesOrderById({ id })).then((res) => {
				setsalesOrderDetailsData(res);
				if (res?.allocation_id) {
					dispatch(getSalesOrderAllocation({ id: res.allocation_id })).then((res1) => {
						if (res1) delete res1.status;
						const salesData = {
							...res,
							...res1,
						};
						setSalesOrderList(salesData);
					});
				} else {
					setSalesOrderList(res);
					setsalesOrderDetailsData(res);
				}
			});
		} else {
			message.error('Something went wrong!');
			navigate('/order-management/sales-order');
		}
	}, [dispatch]);

	const handleTabChange = (numberKey) => {
		const tabKey = Number(numberKey);
		if (tabKey === 3) {
			dispatch(getOrderJourneyData(id)).then((res) => {
				setOrderJourneyData(res);
			});
		} else if (tabKey === 4) {
			dispatch(getCollectionData(id)).then((res) => {
				setCollectionData(res);
			});
		} else if (tabKey === 5) {
			dispatch(getSalesReturnList(id)).then((res) => {
				setSalesReturnTabData(res);
			});
		} else if (tabKey === 6) {
			dispatch(getImagesList(id)).then((res) => {
				setImageList(res);
			});
		}
	};

	return (
		<>
			<Collapse expandIconPosition="right" ghost>
				<Panel
					header={
						<AccordionHeader
							title="Order Details:"
							status={salesOrderDetailsData?.status}
							invoice={salesOrderDetailsData?.invoice_no}
							storeName={salesOrderDetailsData?.Store?.name}
							quidEnabled={searchParams.get('quid-enabled')}
						/>
					}
					key="accoridon"
					className="site-collapse-custom-panel"
				>
					<>
						<Row gutter={32}>
							{returnToFcShowPanel({ salesOrderDetailsData }).map(
								(x) =>
									!x.hidden && (
										<Col key={x?.title} xs={6}>
											<PanelData
												title={x?.title}
												value={x?.value}
												additionalValue={x?.additionalValue || ''}
												type={x?.type || ''}
												infoDetails={x?.infoDetails}
												brandInfo={salesOrderDetailsData?.Brand}
											/>
											{x.hidden}
										</Col>
									)
							)}
						</Row>
						<Button
							mt="20px"
							disabled={salesOrderList?.status === 'PU'}
							onClick={() => setShowAllocationDetail(true)}
						>
							<Flex alignItems="center">
								<Image
									width={16}
									height={16}
									src={ShippingIcon}
									alt="Shipping Icon"
								/>
								<Text ml="8px">View Vehicle Allocation Details</Text>
							</Flex>
						</Button>
						<AllocationDetailsModal
							salesOrderList={salesOrderList}
							showAllocationDetail={showAllocationDetail}
							setShowAllocationDetail={setShowAllocationDetail}
						/>
					</>
				</Panel>
			</Collapse>
			<Flex justifyContent="flex-end" mt="8px">
				<StyledInvoiceCopyButton onClick={() => setViewInvoiceModal(true)}>
					View Invoice Copy
				</StyledInvoiceCopyButton>
			</Flex>
			<Box mt="30px">
				<Tabs
					defaultActiveKey={location?.state?.from.pathname ? '5' : '1'}
					onChange={handleTabChange}
				>
					<TabPane tab="Value Wise Details" key="1">
						<SalesOrderShowTable tab="value" data={salesOrderList} />
					</TabPane>
					<TabPane tab="Delivery Details" key="2">
						<SalesOrderShowTable tab="delivery" data={salesOrderList} />
					</TabPane>
					{UserPersmission.returnToFC_create.includes(allowedUser) &&
						(!salesOrderList?.CollectionInvoice?.verified_by_cashier_at ||
							isSuperAdmin) && (
							<TabPane tab="Proof of Delivery" key="6">
								{imageList.length > 0 ? (
									<MultipleImagePreview
										imageList={imageList}
										tab="Proof of Delivery"
									/>
								) : (
									<EpodNotAvailableModal tab="Proof of Delivery" />
								)}
							</TabPane>
						)}
					<TabPane tab="Order Journey" key="3">
						<OrderJourney tab="Order Journey" data={orderJourneyData} />
					</TabPane>
					<TabPane tab="Collection History" key="4">
						<CollectionHistory tab="Collection History" data={collectionData} />
					</TabPane>
					<TabPane tab="Sales Return" key="5">
						<SalesOrderReturnTable tab="Sales Return" data={salesReturnTabData} />
					</TabPane>
				</Tabs>
			</Box>
			{viewInvoiceModal && (
				<ShowUploadListModal
					toggleState={[viewInvoiceModal, setViewInvoiceModal]}
					showHeader={false}
					orderId={salesOrderList.id}
					totalData={salesOrderList}
				/>
			)}
		</>
	);
}
