import { createSlice } from '@reduxjs/toolkit';
// import { rest } from 'lodash';
// import { message } from 'antd';
import { API } from 'api';

const returnsUrl = `${process.env?.REACT_APP_API_RETURNS_API}`;

export const returnsLogsSlice = createSlice({
	name: 'salesReturn',
	initialState: {},
	reducers: {},
});

export const getReturnsLogsList =
	({
		offset,
		limit,
		sort_column = 'id',
		sort_direction = 'DESC',
		fcs,
		brands,
		// queryInvoice,
		// query,
		...rest
	}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: `${returnsUrl}`,
			data: {
				filter: {
					user_fcs_only: true,
					user_brands_only: true,
					brands: brands?.length ? brands : undefined,
					fcs: fcs?.length ? fcs : undefined,
					...rest,
				},
				page: { offset, limit },
				sort: { sort_column, sort_direction },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getSalesReturnsList = (payload, id) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${returnsUrl}sales-return/create/${id}`,
		data: payload,
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const ShowSalesReturns = (id) => async () => {
	const CREDENTIALS = {
		method: 'get',
		url: `${returnsUrl}sales-return/create/${id}`,
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const updateSalesReturnsList = (payload, id) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${returnsUrl}${id}/`,
		data: payload,
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const getSearchSalesReturn =
	({ invoice_no }) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: `${returnsUrl}search-invoice/`,
			data: {
				invoice_no,
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const ShowReturnsLogsId = (id) => async () => {
	const CREDENTIALS = {
		method: 'get',
		url: `${returnsUrl}${id}/`,
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const getProductList =
	({ order_id, query }) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: `${returnsUrl}order/product_search/`,
			data: {
				order_id,
				query,
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export default returnsLogsSlice.reducer;
