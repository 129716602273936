import React, { useState } from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { changeUserPassword } from 'store/authSlice';
import { ChangePasswordForm, AddUserForm } from 'components/forms';
import { Box, Button } from 'atoms';

export default function AddUser() {
	const dispatch = useDispatch();
	const { userDetails = {} } = useSelector((state) => state?.masters);
	const { pathname } = useLocation();
	const [modalVisible, setmodalVisible] = useState(false);
	const editMode = pathname.includes('edit');

	const handleSubmit = (values, { setErrors, resetForm, setSubmitting }) => {
		dispatch(
			changeUserPassword(
				{ username: userDetails?.userdetail?.username, password: values?.password },
				setErrors
			)
		)
			.then(() => {
				setSubmitting(false);
				setmodalVisible(false);
				resetForm();
			})
			.catch(() => {
				setSubmitting(false);
			});
	};
	return (
		<>
			<Box>
				<Box
					display={editMode ? 'block' : 'none'}
					position="absolute"
					right="15px"
					zIndex="5"
				>
					<Button onClick={() => setmodalVisible(true)}>Reset Password</Button>
				</Box>
				<AddUserForm />
			</Box>
			{modalVisible && (
				<Modal
					title="Reset Password"
					centered
					onCancel={() => setmodalVisible(false)}
					destroyOnClose
					footer={false}
					width="60%"
					visible={modalVisible}
				>
					<Box mt={3}>
						<ChangePasswordForm onlyChangePassword handleSubmit={handleSubmit} />
					</Box>
				</Modal>
			)}
		</>
	);
}
