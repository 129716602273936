import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Image } from 'atoms';
import StoreMallIcon from 'assets/store-mall-icon.svg';
import DescriptionIcon from 'assets/description-icon.svg';
import { getstatus } from 'utils/tableConfig/helpers';
import * as SVGICONS from 'utils/Svg';

function statusCheck(status) {
	switch (status) {
		case 'DL':
		case 'PD':
			return '#AAF27F';
		case 'VA':
		case 'OD':
			return '#FFE16A';
		case 'CA':
		case 'DA':
			return '#FFA48D';
		case 'OP':
			return '#74CAFF';
		case 'OC' || 'PI' || 'PU' || 'RA' || 'RD':
		default:
			return '#DFE3E8';
	}
}

export default function AccordionHeader({
	status = '',
	invoice = '',
	storeName = '',
	title = '',
	returnProduct = 0,
	quidEnabled,
}) {
	return (
		<Flex flex={1} justifyContent="space-between" alignItems="center" alignSelf="center">
			<Flex alignItems="center" title="Store Name">
				<Text fontSize="14px" fontWeight="700">
					{title}
				</Text>
				{quidEnabled === 'true' ? (
					<div style={{ marginLeft: '16px' }}>
						<SVGICONS.QuidSalesOrderIcons />
					</div>
				) : null}
			</Flex>
			<Flex alignItems="center" title="Store Name">
				<Image width={16} height={16} src={StoreMallIcon} alt="Store Mail" />
				<Text fontSize="14px" fontWeight="700" ml="8px">
					{storeName}
				</Text>
			</Flex>
			<Flex alignItems="center" title="Invoice Number">
				<Image width={16} height={16} src={DescriptionIcon} alt="Description" />
				<Text fontSize="14px" fontWeight="700" ml="8px" textTransform="uppercase">
					{invoice}
				</Text>
			</Flex>
			<Text
				mr="52px"
				fontSize="13px"
				fontWeight="600"
				p=" 5px 20px"
				bg={status ? statusCheck(status) : '#FFE16A'}
				borderRadius="15px"
			>
				{!status ? returnProduct : getstatus(status)}
			</Text>
		</Flex>
	);
}

AccordionHeader.propTypes = {
	status: PropTypes.string,
	invoice: PropTypes.string,
	storeName: PropTypes.string,
	title: PropTypes.string,
	returnProduct: PropTypes.string,
	quidEnabled: PropTypes.string,
};
