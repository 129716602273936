import React from 'react';
import PropTypes from 'prop-types';

export default function TotalCount({ count = null }) {
	if (!count) {
		return null;
	}

	return <span> ({count})</span>;
}

TotalCount.propTypes = {
	count: PropTypes.number,
};
