import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Collapse, Row, Col } from 'antd';
import { PanelData } from 'components/common';
import { GRNShowId } from 'store/GRNSlice';
import { Flex } from 'atoms';
import ShowTable from 'pages/GRN/ShowTable';

const { Panel } = Collapse;

export default function GRNShow() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [showData, setShowData] = useState({});

	useEffect(() => {
		dispatch(GRNShowId(id)).then((res) => setShowData(res));
	}, [dispatch, id]);

	return (
		<>
			<Collapse expandIconPosition="right" bordered={false}>
				<Panel header="GRN Details:" key="1">
					<Row gutter={32}>
						<Col xs={6}>
							<PanelData
								title="FC"
								value={showData?.FC?.name}
								additionalValue={showData?.FC?.code}
							/>
						</Col>
						<Col xs={6}>
							<PanelData title="GRN No" value={showData?.brand_grn_no} type="upper" />
						</Col>
						<Col xs={6}>
							<PanelData
								title="Brand GRN Date"
								value={showData?.brand_grn_date}
								type="date"
							/>
						</Col>
						<Col xs={6}>
							<PanelData
								title="Invoice No"
								value={showData?.invoice_no}
								type="upper"
							/>
						</Col>
					</Row>
					<Row gutter={32}>
						<Col xs={6}>
							<PanelData title="GRN Value" value={showData?.grn_value} type="rupee" />
						</Col>
						<Col xs={6}>
							<PanelData
								title="Uploaded by"
								value={showData?.File?.FileUploadedBy?.full_name}
							/>
						</Col>
						<Col xs={6}>
							<PanelData
								title="Uploaded Date"
								value={showData?.createdAt}
								type="date"
							/>
						</Col>
					</Row>
				</Panel>
			</Collapse>
			<Flex mt="30px">
				<ShowTable data={showData} />
			</Flex>
		</>
	);
}
