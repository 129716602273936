import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetBrandId, CreateUpdateBrands } from 'store/masters';
import { getClientDataOnLoad, getClientListonSearch } from 'store/OnBoardSlice';
import { FormInput, CustomInput, Flex, Box, AsyncSelect } from 'atoms';
import { StyledSubmitButton } from '../masters/helper';
import { BrandsFormSchema, BrandsInitialValues } from './helper';
import { LoadAsyncSelectComponent } from 'components/common';
import { SelectOptions } from 'utils';

function BrandForm({ isEditPage, brandId }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [formInitial, setFormInitial] = useState({});
	const [clientList, setClientList] = useState({ type: 'NotAsked' });

	useEffect(() => {
		setClientList({ type: 'Loading' });
		const hasEditForm = isEditPage ? dispatch(GetBrandId({ brandId })) : Promise.resolve();

		hasEditForm.then((resp) => {
			dispatch(getClientDataOnLoad({})).then((res) => {
				setFormInitial(isEditPage ? BrandsInitialValues(resp) : BrandsInitialValues());
				setClientList({
					type: 'Success',
					data: SelectOptions(res, 'name', 'id'),
				});
			});
		});
	}, [brandId, dispatch, isEditPage]);

	function handleSubmit(values, { setErrors, resetForm, setSubmitting }) {
		const payload = isEditPage ? { id: +brandId, name: values?.name } : { ...values };

		dispatch(CreateUpdateBrands(payload, setErrors))
			.then(() => {
				setSubmitting(false);
				resetForm();
				navigate('/onboarding/brand');
			})
			.catch(() => {
				setSubmitting(false);
			});
	}

	const handleSelect = (val, name, setFieldValue) => setFieldValue(name, val?.value);

	return (
		<Formik
			initialValues={formInitial}
			validationSchema={BrandsFormSchema}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ isSubmitting, isValid, dirty, values, setFieldValue }) => (
				<Form layout="vertical">
					<Row gutter={8} justify="flex-start">
						<Col lg={8} sm={24}>
							<FormInput name="name" label="*Brand Name">
								<CustomInput name="name" />
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput name="code" label="*Brand Code">
								<CustomInput disabled={isEditPage} name="code" />
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput name="client_id" label="*Client">
								<LoadAsyncSelectComponent options={clientList}>
									<AsyncSelect
										className="customDropdown"
										disabled={isEditPage}
										allowClear
										name="client_id"
										showSearch
										component="formikSelect"
										defaultValue={
											clientList?.data?.filter(
												(x) => x?.value === values?.client_id
											)[0] || null
										}
										fetchOptions={getClientListonSearch}
										onChange={(val) =>
											handleSelect(val, 'client_id', setFieldValue)
										}
										placeholder="Select Client"
										transformOptions={(val) => SelectOptions(val, 'name', 'id')}
										selectOptions={[...(clientList.data || [])]}
									/>
								</LoadAsyncSelectComponent>
							</FormInput>
						</Col>
					</Row>
					<StyledSubmitButton
						disabled={isSubmitting || !isValid || !dirty}
						id="add-user-submit"
					>
						<Flex alignItems="center" justifyContent="center">
							<Box>{isEditPage ? 'Save' : 'Add'}</Box>
						</Flex>
					</StyledSubmitButton>
				</Form>
			)}
		</Formik>
	);
}
BrandForm.propTypes = {
	isEditPage: PropTypes.bool,
	brandId: PropTypes.string,
};
export default BrandForm;
