/* eslint-disable sonarjs/no-identical-functions */
import moment from 'moment';
// dateformat
const dateformat = 'YYYY-MM-DD';
const dateFormatDDMMYYYY = 'DD-MM-YYYY';
export const TODAYDDMMYYYY = moment().format(dateFormatDDMMYYYY);
export const PAST5DAYSDDMMYYYY = moment().subtract(4, 'days').format(dateFormatDDMMYYYY);

export function dateFormat(date = '', d_format = dateformat) {
	return moment(date).format(d_format);
}

// get currentdate
export default function currentDate(format = dateformat) {
	return moment().format(format);
}

export function utcToLocDT(dateString = null) {
	return dateString ? moment.utc(dateString).local().format('DD MMM YYYY | hh:mm A') : '-';
}

// disable future date in datepicker
export function disableFutureDate(current) {
	// Can not select future days
	return current && current.valueOf() >= Date.now();
}
export function createdDate(time) {
	return new Date(time);
}

export function createdTime(time) {
	return new Date(time).toTimeString().split(' ')[0];
}

export function disableDateRangesNew(values) {
	const startDate = moment(values);
	let endDate = moment(values).add(90, 'days');
	const todayDate = moment(new Date());
	const isTodayIsEndDate = endDate.diff(todayDate, 'days') >= 0;

	if (isTodayIsEndDate) {
		endDate = todayDate;
	}

	return function disabledDate(current) {
		const startCheck = current && current < moment(startDate, dateformat);
		const endCheck = current && current > moment(endDate, dateformat);

		return startCheck || endCheck;
	};
}

export function showLast5DateRanges(values) {
	const startDate = moment(values);
	let endDate = moment(values).add(5, 'days');
	const todayDate = moment(new Date());
	const isTodayIsEndDate = endDate.diff(todayDate, 'days') > 0;

	if (isTodayIsEndDate) {
		endDate = todayDate;
	}

	return function disabledDate(current) {
		const startCheck = current && current < moment(startDate, dateformat);
		const endCheck = current && current > moment(endDate, dateformat);

		return startCheck || endCheck;
	};
}

export function disableFeatureDateRanges(values) {
	const startDate = moment(values);
	let endDate = moment(values).add(90, 'days');
	const todayDate = moment(new Date());
	const isTodayIsEndDate = endDate.diff(todayDate, 'days') > 0;

	if (isTodayIsEndDate) {
		endDate = todayDate;
	}

	return function disabledDate(current) {
		const startCheck = current && current < moment(startDate, dateformat);
		const endCheck = current && current > moment(endDate, dateformat);

		return startCheck || endCheck;
	};
}

export function disableDateRangesMonth(values) {
	const startDate = moment(values);
	const endDate = moment(values).add(31, 'days');
	const todayDate = moment(new Date());
	const isTodayIsEndDate = endDate.diff(todayDate, 'days') >= 0;

	if (isTodayIsEndDate) {
		// endDate = moment().endOf(todayDate);

		return function disabledDate(current) {
			const startCheck = current && current < moment(startDate, dateformat);
			const endCheck = current && current > moment().endOf(todayDate, dateformat);

			return startCheck || endCheck;
		};
	}

	return function disabledDate(current) {
		const startCheck = current && current < moment(startDate, dateformat);
		const endCheck = current && current > moment(endDate, dateformat);

		return startCheck || endCheck;
	};
}

export function disableDateRanges(values) {
	// https://stackoverflow.com/a/68923354/3882241
	let FUTURE_RANGE = 1;
	const PAST_RANGE = 45;
	/**
	 * By default past dates are 45 from today date
	 */

	console.log('acc', moment().diff(moment(values), 'days'));

	if (moment().diff(moment(values), 'days') < 60) {
		/**
		 * if invoice date is lesser than 60 days,
		 * minus that age with 60, result is the future date to allowed
		 */
		FUTURE_RANGE = 60 - moment().diff(moment(values), 'days') || 1;
	}

	const startDate = moment().subtract(PAST_RANGE, 'days');
	const endDate = moment().add(FUTURE_RANGE, 'days');

	return function disabledDate(current) {
		const startCheck = current && current < moment(startDate, dateformat);
		const endCheck = current && current > moment(endDate, dateformat);

		return startCheck || endCheck;
	};
}

export function disableDateRangesCheque(values, metadata) {
	console.log(metadata, values);
	// https://stackoverflow.com/a/68923354/3882241
	const FUTURE_RANGE = metadata?.cheque_credit_period || 0;
	const PAST_RANGE = 45;
	/**
	 * By default past dates are 45 from today date
	 */

	// if (moment().diff(moment(values), 'days') < 60) {
	// 	/**
	// 	 * if invoice date is lesser than 60 days,
	// 	 * minus that age with 60, result is the future date to allowed
	// 	 */
	// 	FUTURE_RANGE = 60 - moment().diff(moment(values), 'days') || 0;
	// 	console.log('accFutureWith21days', FUTURE_RANGE);
	// }

	console.log('accFutureWith21days', FUTURE_RANGE);
	const startDate = moment().subtract(PAST_RANGE, 'days');
	const endDate = moment().add(FUTURE_RANGE, 'days');

	return function disabledDate(current) {
		const startCheck = current && current < moment(startDate, dateformat).startOf('day');
		const endCheck = current && current > moment(endDate, dateformat).endOf('day');

		return startCheck || endCheck;
	};
}

export function disablePastDate(current) {
	// Can not select future days
	return current < moment().endOf('day');
}

// disable past and current date in datepicker
export function disablePastCurrentDate(current) {
	// Can not select future days
	return current && current < moment().endOf('day');
}

export function getDateDiff(date) {
	const a = moment(date);
	const b = moment();

	return b.diff(a, 'days');
}

export function getLocalDateAndTime(date) {
	const DateFormat = 'DD-MM-YYYY';
	const TIME_FORMAT = 'hh:mm A';
	if (date) {
		const formattedTime = moment(date).add(0, 'minutes').format(TIME_FORMAT);
		const formattedDate = moment(date).add(0, 'minutes').format(DateFormat);
		// const formattedDate = moment(istTime).add(330, 'minutes').format(DateFormat);
		// Need to check with Radha for added 330 minutes extra.
		return `${formattedDate} ${formattedTime}`;
	}
	return '';
}
