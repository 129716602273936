import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Row, Table } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { Box, Flex, Image, Text } from 'atoms';
import viewIcon from 'assets/icons/eye.svg';
import { Rupee } from 'components';

const StatusContainer = styled(Text)`
	border-radius: 2px;
	text-align: center;
	padding: 8px 0;
	font-size: 12px;
`;

const DateFormat = 'DD/MM/YYYY';
const TIME_FORMAT = 'hh:mm a';
function getLocalTime(time) {
	if (time) {
		return moment(time).add(0, 'minutes').format(TIME_FORMAT);
	}
	return '';
}
function getLocalDate(date) {
	if (date) {
		return moment(date).add(330, 'minutes').format(DateFormat);
	}
	return '';
}

const sales_return_columns = [
	{
		title: 'Return No',
		dataIndex: 'return_no',
		key: 'product_name',
		render: (return_no) => <Text>{return_no}</Text>,
	},
	{
		title: 'Created By',
		dataIndex: 'username',
		key: 'username',
		align: 'center',
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{record?.username}
			</Text>
		),
	},
	{
		title: 'Return Created Date',
		dataIndex: 'created_at',
		key: 'created_at',
		align: 'center',

		render: (created_at) => (
			<Box>
				<Text overFlow="hidden">
					{`${getLocalDate(created_at)}  ${getLocalTime(created_at)}`}
				</Text>
			</Box>
		),
	},
	{
		title: 'Created Return Quantity',
		dataIndex: 'returned_qty',
		key: 'returned_qty',
		align: 'center',
		render: (returned_qty) => (
			<Text>{`${returned_qty} ${returned_qty > 1 ? ' pcs' : ' pc'}`}</Text>
		),
	},
	{
		title: 'Return Value',
		dataIndex: 'return_value',
		key: 'return_value',
		align: 'center',
		render: (return_value) => <Rupee>{return_value}</Rupee>,
	},
	{
		title: 'Return Status',
		dataIndex: 'return_status',
		key: 'return_status',
		width: '10%',
		align: 'center',
		render: (return_status) => {
			switch (return_status) {
				case 'wms_failed':
					return (
						<StatusContainer
							overFlow="hidden"
							style={{ background: '#FFECEC', color: '#FF4747' }}
						>
							WMS Failed
						</StatusContainer>
					);
				case 'completed':
					return (
						<StatusContainer
							overFlow="hidden"
							style={{ background: '#DEFFDC', color: '#0A9600' }}
						>
							Completed
						</StatusContainer>
					);
				case 'PD':
					return (
						<StatusContainer
							overFlow="hidden"
							style={{ background: '#FFD8EC', color: '#A3603C' }}
						>
							Partial Delivery
						</StatusContainer>
					);
				case 'all_qty_missing':
					return (
						<StatusContainer
							overFlow="hidden"
							style={{ background: '#FFF4C5', color: '#B23A48' }}
						>
							All Qty Missing
						</StatusContainer>
					);

				case 'cancelled':
				case 'CA':
					return (
						<StatusContainer
							overFlow="hidden"
							style={{ background: '#FFECEC', color: '#FF4747' }}
						>
							Cancelled
						</StatusContainer>
					);
				case 'failed':
					return (
						<StatusContainer
							overFlow="hidden"
							style={{ background: '#FFECEC', color: '#FF4747' }}
						>
							Failed
						</StatusContainer>
					);

				case 'received':
					return (
						<StatusContainer
							overFlow="hidden"
							style={{ background: '#C9EFEC', color: '#710193' }}
						>
							Received
						</StatusContainer>
					);

				default:
					return (
						<StatusContainer
							overFlow="hidden"
							style={{ background: '#C9EFEC', color: '#710193' }}
						>
							Created
						</StatusContainer>
					);
			}
		},
	},
	{
		title: '',
		dataIndex: 'actions',
		key: 'actions',
		sortId: 6,
		hidden: false,
		render: (_, record) => (
			<Flex justifyContent="center">
				<Link
					to={`/order-management/sales-order/management/${record?.return_no.substring(
						3
					)}?return_id=${record?.id}&return_no=${
						record?.return_no
					}&created_at=${getLocalDate(record?.created_at)}&created_time=${getLocalTime(
						record?.created_at
					)}&username=${record?.username}`}
				>
					<Image src={viewIcon} alt="edit-icon" height={15} width={20} />
				</Link>
			</Flex>
		),
	},
];

const deliveryFooter = (data) => (
	<Row
		style={{
			marginBottom: '10px',
		}}
	>
		{data?.sales_return_value > 0 ? (
			<Col
				span={6}
				style={{ backgroundColor: '#CA5010', padding: '5px 20px', borderRadius: '4px' }}
			>
				<Text fontSize="14px" fontWeight="600" color="#fff">
					Sales Return Value: <Rupee>{Number(data?.sales_return_value).toFixed(2)}</Rupee>
				</Text>
			</Col>
		) : null}
	</Row>
);

// eslint-disable-next-line react/prop-types
export default function SalesOrderReturnTable({ data }) {
	return (
		<>
			{data?.sales_return_value > 0 && <div>{deliveryFooter(data)}</div>}
			<div>
				<Table columns={sales_return_columns} dataSource={data?.data} pagination={false} />
			</div>
		</>
	);
}

SalesOrderReturnTable.propTypes = {
	data: PropTypes.object,
};
