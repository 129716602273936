import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Text } from 'atoms';
import DateFormat from 'components/DateFormat';

export const brandListColumns = ({ defaultSort, defaultsortField }) => {
	const sortDirection = defaultSort === 'ASC' ? 'ascend' : 'descend';

	return [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			sortId: 1,
			overflow: 'hidden',
			hidden: false,
			render: (name) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" maxWidth="250px">
					{name || ''}
				</Text>
			),
		},
		{
			title: 'Code',
			dataIndex: 'code',
			key: 'code',
			sortId: 1,
			overflow: 'hidden',
			hidden: false,
			render: (code) => (
				<Text
					overFlow="hidden"
					fontSize="12px"
					color="#000"
					maxWidth="250px"
					textTransform="uppercase"
				>
					{code || ''}
				</Text>
			),
		},
		{
			title: 'Created At',
			dataIndex: 'createdAt',
			key: 'createdAt',
			sortId: 1,
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => a.createdAt - b.createdAt,
			sortOrder: defaultsortField === 'createdAt' ? sortDirection : false,
			render: (createdAt) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={createdAt} format="HMSA" />
				</Text>
			),
		},
		{
			title: '',
			dataIndex: 'id',
			key: 'id',
			sortId: 6,
			render: (id) => (
				<Link style={{ color: '#000' }} to={`/onboarding/brand/${id}/edit`}>
					<EditOutlined />
				</Link>
			),
		},
	];
};
