import {
	CashierSalesManStatsHeader,
	CashierTopStatusTabs,
	ChequeList,
} from 'components/pages/cashier';

export function ChequeVerification() {
	return (
		<div>
			<CashierTopStatusTabs />
			<CashierSalesManStatsHeader />
			<ChequeList />
		</div>
	);
}
