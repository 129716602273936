/* eslint-disable prettier/prettier */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from 'atoms';

const Notes = styled.div`
	display: block;
	width: 300px;
	height: 38px;
	margin: 0 auto;
	margin-top: 1rem;
	padding: 0.2rem 1.3rem;
	background-color: ${(props) => props.bgColor};
	text-align: center;
`;

const NotesText = styled.span`
	font-weight: 600;
`;

export function NotesBox({ text = '', bgColor = '#ECFFF8' }) {
	return (
		<Notes bgColor={bgColor}>
			<Text fontSize="10px">
				<NotesText fontWeight="600">Note:</NotesText> {text}
			</Text>
		</Notes>
	);
}

NotesBox.propTypes = {
	text: PropTypes.string,
	bgColor: PropTypes.string,
};
