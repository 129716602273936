/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable consistent-return */
import { createSlice } from '@reduxjs/toolkit';
import { API } from 'api';

const omsUrl = `${process.env?.REACT_APP_OMS}`;
const initialState = {};

export const brandSalesReturnSlice = createSlice({
	name: 'brandSalesReturn',
	initialState,
	reducers: {},
});

export const getBrandSalesReturnList =
	({
		offset,
		limit,
		sort_column = 'id',
		sort_direction = 'DESC',
		fcs,
		brands,
		invoice_no,
		return_no,
		bnpl_enabled,
		...rest
	}) =>
	async () => {
		let CREDENTIALS = {};

		console.log(bnpl_enabled);

		if (bnpl_enabled === undefined) {
			CREDENTIALS = {
				method: 'post',
				url: `${omsUrl}returns/brand-return`,
				data: {
					filter: {
						user_fcs_only: true,
						user_brands_only: true,
						brands: brands?.length ? brands : undefined,
						fcs: fcs?.length ? fcs : undefined,
						invoice_no: invoice_no?.length ? invoice_no : undefined,
						return_no: return_no?.length ? return_no : undefined,

						...rest,
					},
					page: { offset, limit },
					sort: { sort_column, sort_direction },
				},
			};
		}
		if (bnpl_enabled) {
			CREDENTIALS = {
				method: 'post',
				url: `${omsUrl}returns/brand-return`,
				data: {
					filter: {
						user_fcs_only: true,
						user_brands_only: true,
						brands: brands?.length ? brands : undefined,
						fcs: fcs?.length ? fcs : undefined,
						invoice_no: invoice_no?.length ? invoice_no : undefined,
						return_no: return_no?.length ? return_no : undefined,
						bnpl_enabled: true,

						...rest,
					},
					page: { offset, limit },
					sort: { sort_column, sort_direction },
				},
			};
		} else {
			CREDENTIALS = {
				method: 'post',
				url: `${omsUrl}returns/brand-return`,
				data: {
					filter: {
						user_fcs_only: true,
						user_brands_only: true,
						brands: brands?.length ? brands : undefined,
						fcs: fcs?.length ? fcs : undefined,
						invoice_no: invoice_no?.length ? invoice_no : undefined,
						return_no: return_no?.length ? return_no : undefined,
						bnpl_enabled: false,

						...rest,
					},
					page: { offset, limit },
					sort: { sort_column, sort_direction },
				},
			};
		}

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const ShowSalesReturnId = (id) => async () => {
	const CREDENTIALS = {
		url: `${omsUrl}returns/brand-return/${id}`,
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export default brandSalesReturnSlice.reducer;
