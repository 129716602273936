import { Link } from 'react-router-dom';
import viewIcon from 'assets/icons/eye.svg';
import DownloadIcon from 'assets/download.svg';
import { Text, Image, Flex, Box } from 'atoms';
import { getstatus } from '../../helpers';
import DateFormat from 'components/DateFormat';
import { downloadDA } from 'store/fulfilment/deliveryAllocationSlice';
import { Rupee } from 'components';
import ecoIcon from 'assets/svg/ecoIcon.svg';
import podIcon from 'assets/svg/podTag.svg';
import * as SVGICONS from 'utils/Svg';

export const deliveryAllocationListColumns = [
	{
		title: 'Vehicle No',
		dataIndex: 'vehicle_no',
		key: 'vehicle_no',
		sortId: 1,
		overflow: 'hidden',
		hidden: false,
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{record?.vehicle_no}
			</Text>
		),
	},
	{
		title: 'Allocated Date',
		dataIndex: 'createdAt',
		key: 'createdAt',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		sorter: (a, b) => a.createdAt - b.createdAt,
		render: (_, record) => (
			<Text
				overFlow="hidden"
				fontSize="12px"
				color="#000"
				style={{ whiteSpace: 'break-word', wordBreak: 'break-all' }}
			>
				<DateFormat date={record?.createdAt} format="HMSA" />
			</Text>
		),
	},
	{
		title: 'FC',
		dataIndex: 'name',
		key: 'name',
		sortId: 3,
		overflow: 'hidden',
		hidden: false,
		width: '8%',
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{record?.FC?.name}
			</Text>
		),
	},
	{
		title: 'Vendor',
		dataIndex: 'vendor',
		key: 'vendor',
		sortId: 4,
		overflow: 'hidden',
		hidden: false,
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{record?.vendor}
			</Text>
		),
	},
	{
		title: 'Driver Name',
		dataIndex: 'driver_name',
		key: 'driver_name',
		sortId: 5,
		overflow: 'hidden',
		hidden: false,
		width: 100,
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{record?.driver_name} <br /> ({record?.driver_mobile})
			</Text>
		),
	},
	{
		title: 'Delivery Boy',
		dataIndex: 'first_name',
		key: 'first_name',
		sortId: 6,
		overflow: 'hidden',
		hidden: false,
		width: 100,
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{record?.DeliveryExecutive?.first_name} {record?.DeliveryExecutive?.last_name}
			</Text>
		),
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		sortId: 7,
		overflow: 'hidden',
		hidden: false,
		width: 100,
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{getstatus(record?.status)}
			</Text>
		),
	},
	{
		title: 'Total order',
		dataIndex: 'totalOrders',
		key: 'totalOrders',
		sortId: 8,
		overflow: 'hidden',
		hidden: false,
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{record?.totalOrders}
			</Text>
		),
	},
	{
		title: 'Full/Partial Delivery',
		dataIndex: 'fulfilledOrders',
		key: 'fulfilledOrders',
		sortId: 9,
		overflow: 'hidden',
		hidden: false,
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{record?.fulfilledOrders}
			</Text>
		),
	},
	{
		title: 'Cancelled',
		dataIndex: 'cancelledOrders',
		key: 'cancelledOrders',
		sortId: 10,
		overflow: 'hidden',
		hidden: false,
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{record?.cancelledOrders}
			</Text>
		),
	},
	{
		title: 'Attempted',
		dataIndex: 'attemptedOrders',
		key: 'attemptedOrders',
		sortId: 11,
		overflow: 'hidden',
		hidden: false,
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{record?.attemptedOrders}
			</Text>
		),
	},
	{
		title: 'Pending',
		dataIndex: 'attemptedOrders',
		key: 'attemptedOrders',
		sortId: 12,
		overflow: 'hidden',
		hidden: false,
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{(record?.totalOrders || 0) -
					((record?.fulfilledOrders || 0) +
						(record?.cancelledOrders || 0) +
						(record?.attemptedOrders || 0))}
			</Text>
		),
	},
	{
		title: '',
		dataIndex: 'actions',
		key: 'actions',
		sortId: 13,
		hidden: false,
		render: (_, record) => (
			<Flex>
				<Link to={`/logistics-management/delivery-allocation/${record?.id}/show`}>
					<Image src={viewIcon} alt="edit-icon" height={15} width={20} />
				</Link>
				<Box
					ml="8px"
					style={{ cursor: 'pointer' }}
					onClick={downloadDA({ id: record?.id })}
				>
					<Image src={DownloadIcon} alt="edit-icon" height={18} width={20} />
				</Box>
			</Flex>
		),
	},
];

export const deliveryAllocationCreateColumns = ({ defaultSort, defaultsortField }) => {
	const sortDirection = defaultSort === 'ASC' ? 'ascend' : 'descend';
	return [
		{
			title: '',
			dataIndex: 'bnpl_status',
			key: 'bnpl_status',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.bnpl_enabled ? <SVGICONS.QuidSalesOrderIcons /> : ''}
				</Text>
			),
		},
		{
			title: 'Invoice Date',
			dataIndex: 'invoice_date',
			key: 'invoice_date',
			sortId: 1,
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => a.invoice_date - b.invoice_date,
			sortOrder: defaultsortField === 'invoice_date' ? sortDirection : false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={record?.invoice_date} />
				</Text>
			),
		},
		{
			title: 'Invoice No',
			dataIndex: 'invoice_no',
			key: 'invoice_no',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => a.invoice_no - b.invoice_no,
			sortOrder: defaultsortField === 'invoice_no' ? sortDirection : false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{record?.invoice_no}
				</Text>
			),
		},
		// {
		// 	title: 'GST No',
		// 	dataIndex: 'gst_number',
		// 	key: 'gst_number',
		// 	sortId: 2,
		// 	overflow: 'hidden',
		// 	hidden: false,
		// 	render: (gst_number) => (
		// 		<Text overFlow="hidden" fontSize="12px" color="#000">
		// 			{gst_number}
		// 		</Text>
		// 	),
		// },
		// {
		// 	title: 'IRN',
		// 	dataIndex: 'irn',
		// 	key: 'irn',
		// 	sortId: 2,
		// 	overflow: 'hidden',
		// 	hidden: false,
		// 	render: (irn) => (
		// 		<Text
		// 			style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}
		// 			overFlow="hidden"
		// 			fontSize="12px"
		// 			color="#000"
		// 			maxWidth="150px"
		// 		>
		// 			{irn}
		// 		</Text>
		// 	),
		// },
		{
			title: 'Store',
			dataIndex: 'name',
			key: 'name',
			sortId: 5,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlowWrap="break-word" fontSize="12px" color="#000">
					{record?.Store?.name}({record?.Store?.code})
				</Text>
			),
		},
		{
			title: 'Brand',
			dataIndex: 'Brand',
			key: 'Brand',
			sortId: 6,
			overflow: 'hidden',
			hidden: false,
			// sorter: (a, b) => a?.Brand?.Name - b?.Brand?.Name,
			render: (Brand) => (
				<Text overFlowWrap="break-word" fontSize="12px" color="#000">
					{Brand?.name || ''}
				</Text>
			),
		},
		{
			title: 'Invoice Amt',
			dataIndex: 'total_net_amount',
			key: 'total_net_amount',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlowWrap="break-word" fontSize="12px" color="#000">
					<Rupee roundOff={0}>{record?.OutstandingInvoice?.invoice_amount}</Rupee>
				</Text>
			),
		},
		{
			title: 'FC',
			dataIndex: 'name',
			key: 'name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlowWrap="break-word" fontSize="12px" color="#000">
					{record?.FC?.name || ''}
				</Text>
			),
		},
		{
			title: 'Salesman',
			dataIndex: 'name',
			key: 'name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlowWrap="break-word" fontSize="12px" color="#000">
					{record?.Salesman?.name || ''}
				</Text>
			),
		},
		{
			title: '',
			dataIndex: 'actions',
			key: 'actions',
			sortId: 6,
			hidden: false,
			render: (_, record) => (
				<Flex alignItems="center">
					{record?.tag.toLowerCase().includes('eco') && (
						<Box fontSize="36px" style={{ marginTop: '10px' }}>
							<Image src={ecoIcon} alt="edit-icon" height={36} width={36} />
						</Box>
					)}
					{record?.bad_pay_master && (
						<Box
							fontSize="36px"
							style={{
								marginLeft: record?.tag === 'ECO' ? '-5px' : '0',
								marginTop: '10px',
							}}
						>
							<Image src={podIcon} alt="edit-icon" height={36} width={36} />
						</Box>
					)}
				</Flex>
			),
		},
	];
};
