import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Main = styled.div`
	padding: 1.5rem;
	width: 100%;
	padding-top: 90px;
`;

export default function MainWindow({ children }) {
	return <Main>{children}</Main>;
}

MainWindow.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
