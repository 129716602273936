/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Spin } from 'antd';
import { Select as FomikSelect } from 'formik-antd';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { toAsysnOption } from 'utils';
import { Box } from 'atoms';

const { Option } = Select;

/**
 * this componentType is to set the desired select component based on the prop component
 */
const ComponentsType = {
	normal: Select,
	formikSelect: FomikSelect,
};

/**
 * props.transformOptions is used for transforming options other than
 * the normal asyncOptions
 */
export function AsyncSelect({
	transformOptions = null,
	selectOptions = [],
	fetchOptions,
	debounceTimeout = 800,
	component = 'normal',
	apiParams = {},
	...props
}) {
	const dispatch = useDispatch();
	const Components = ComponentsType[component];
	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState(selectOptions);
	const fetchRef = useRef(0);

	useEffect(() => {
		setOptions(selectOptions);
	}, [selectOptions]);

	/**
	 * this function is to fire the api
	 * when option are changed.
	 */
	const debounceFetcher = useMemo(() => {
		const loadOptions = (value) => {
			fetchRef.current += 1;
			const fetchId = fetchRef.current;
			setOptions([]);
			setFetching(true);
			dispatch(fetchOptions({ q: `*${value || ''}*`, ...apiParams })).then((newOptions) => {
				if (fetchId !== fetchRef.current) {
					return;
				}

				const returnOptions = transformOptions
					? transformOptions(newOptions)
					: toAsysnOption(newOptions);

				setOptions(returnOptions);
				setFetching(false);
			});
		};

		return debounce(loadOptions, debounceTimeout);
	}, [dispatch, fetchOptions, debounceTimeout, transformOptions, apiParams]);

	return (
		<Components
			labelInValue
			filterOption={false}
			onSearch={debounceFetcher}
			notFoundContent={fetching ? <Spin size="small" /> : 'No data'}
			{...props}
			onChange={(value) => props?.onChange(value, options)}
		>
			{options.map((x) => (
				<Option value={x.value} key={x.value}>
					{x?.value?.toString()?.split('_')?.includes('all') &&
					component !== 'formikSelect' ? (
						<Box fontWeight="700">{x.label}</Box>
					) : (
						x.label
					)}
				</Option>
			))}
		</Components>
	);
}

AsyncSelect.propTypes = {
	selectOptions: PropTypes.array,
	fetchOptions: PropTypes.func,
	debounceTimeout: PropTypes.number,
	children: PropTypes.array,
	transformOptions: PropTypes.func,
	component: PropTypes.string,
	onChange: PropTypes.func,
	apiParams: PropTypes.object,
};
