/* eslint-disable sonarjs/no-identical-functions */
// import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { Box, Image } from 'atoms';
import { Rupee } from 'components';
import { date } from 'utils';
import attachmentIcon from 'assets/svg/attachment.svg';

export const BankColumns = ({ setattachmentmodal }, type) => [
	{
		title: (
			<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
				Date
			</Box>
		),
		dataIndex: 'date_time',
		key: 'date_time',
		render: (date_time) => (
			<Box textAlign="center" color="#000">
				{date(date_time)}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
				Time
			</Box>
		),
		dataIndex: 'date_time',
		key: 'date_time',
		render: (date_time) => (
			<Box textAlign="center" color="#000">
				{moment.utc(date_time).local().format('h:mm A')}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="right" fontWeight="400" fontSize="14px" color="#00000">
				Cheque Settled Count
			</Box>
		),
		dataIndex: 'cheque_settled_count',
		key: 'cheque_settled_count',
		className: type === 'cash' ? 'hide' : '',
		render: (cheque_settled_count) => (
			<Box textAlign="right" color="#000">
				{cheque_settled_count || 0}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="right" fontWeight="400" fontSize="14px" color="#00000">
				Verified Amount
			</Box>
		),
		dataIndex: 'verified_amount',
		key: 'verified_amount',
		render: (amt) => (
			<Box textAlign="right" color="#000">
				<Rupee>{amt || 0}</Rupee>
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="right" fontWeight="400" fontSize="14px" color="#00000">
				Settled Amount
			</Box>
		),
		dataIndex: 'settled_amount',
		key: 'settled_amount',
		render: (settleamt) => (
			<Box textAlign="right" color="#000">
				<Rupee>{settleamt || 0}</Rupee>
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="right" fontWeight="400" fontSize="14px" color="#00000">
				Balance after settlement
			</Box>
		),
		dataIndex: 'balance_after_settled_amount',
		key: 'balance_after_settled_amount',
		render: (balamt) => (
			<Box textAlign="right" color="#000">
				<Rupee>{balamt || 0}</Rupee>
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
				Reason
			</Box>
		),
		dataIndex: 'reason',
		key: 'reason',
		render: (reason) => (
			<Box textAlign="center" color="#000">
				{reason || '-'}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
				Attachment
			</Box>
		),
		dataIndex: 'attachment',
		key: 'attachment',
		render: (attachment, rec) => (
			<Box>
				{!isEmpty(rec.attachment) && (
					<Box
						cursor="pointer"
						textAlign="center"
						onClick={() =>
							setattachmentmodal({ modalVisisble: true, index: rec.index })
						}
						title={attachment}
					>
						<Image width={16} height={16} src={attachmentIcon} alt="totalBillBack" />
					</Box>
				)}
			</Box>
		),
	},
	// {
	// 	title: (
	// 		<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
	// 			Settlement Report
	// 		</Box>
	// 	),
	// 	dataIndex: 'report',
	// 	key: 'report',
	// 	render: () => (
	// 		<Box textAlign="center" color="#000">
	// 			<DownloadOutlined />
	// 		</Box>
	// 	),
	// },
];

export const BankInnerColumns = [
	{
		title: (
			<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
				Collected date
			</Box>
		),
		dataIndex: 'collection_date',
		key: 'collection_date',
		render: (date_time) => (
			<Box textAlign="center" color="#000">
				{date(date_time)}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
				Collected by
			</Box>
		),
		dataIndex: 'collected_by',
		key: 'collected_by',
		render: (collected_by) => (
			<Box textAlign="center" color="#000">
				{collected_by || '-'}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
				Invoice no
			</Box>
		),
		dataIndex: 'invoice_no',
		key: 'invoice_no',
		render: (invoice_no) => (
			<Box textAlign="center" color="#000">
				{invoice_no || '-'}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
				Cheque number
			</Box>
		),
		dataIndex: 'chequeNo',
		key: 'chequeNo',
		render: (chequeNo) => (
			<Box textAlign="center" color="#000">
				{chequeNo || '-'}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
				Due date
			</Box>
		),
		dataIndex: 'dueDate',
		key: 'dueDate',
		render: (dueDate) => (
			<Box textAlign="center" color="#000">
				{date(dueDate)}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
				Bank Name
			</Box>
		),
		dataIndex: 'bankName',
		key: 'bankName',
		render: (bankName) => (
			<Box textAlign="center" color="#000">
				{bankName || '-'}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="right" fontWeight="400" fontSize="14px" color="#00000">
				Amount
			</Box>
		),
		dataIndex: 'payment_amount',
		key: 'payment_amount',
		render: (payment_amount) => (
			<Box textAlign="right" color="#000">
				<Rupee>{payment_amount || 0}</Rupee>
			</Box>
		),
	},
];
