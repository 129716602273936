/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { Input, Modal, Radio, Table } from 'antd';
import { Box, Button, Flex, Text } from 'atoms';
import { LabelWrapper, RCTable } from 'components/common';
import { chequeSettlement, getBase64 } from 'utils';
import { useQueryParams } from 'hooks';
import {
	ChequeSettlementInsert,
	getAlChequeListForSettlement,
	getBanksList,
	getChequeListForSettlement,
	getPrintCheques,
} from 'store/invoiceSlice';
import {
	chequeSettlementTableConfig,
	BoxFlx,
	pdfimgformates,
	FlexWrap,
	UploadWrap,
	Btn,
	CommonButtons,
	CHEQUE_MAX_COUNT,
	TextHeader,
} from './chequeHelper';
import * as SVGICONS from 'utils/Svg';
export default function ChequeBanksettlementReg() {
	const dispatch = useDispatch();
	const [searchValue, setSearchValue] = useState(undefined);
	const [selectedFileList, setSelectedFileList] = useState([]);
	const [bankname, setBankname] = useState('');
	const [allCheque, setAllChq] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [paginateConfig, setpaginateConfig] = useQueryParams({ filterQuery: chequeSettlement });
	const [rowData, setRowData] = useState({});
	const [rowSelect, setrowSelect] = useState([]);
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [myArray, setmyArray] = useState([]);
	const [banks, setBank] = useState([]);
	useEffect(() => {
		console.log(myArray);
	}, [myArray]);
	const dummyRequest = ({ onSuccess }) => {
		setTimeout(() => {
			onSuccess('ok');
		}, 0);
	};
	useEffect(() => {
		dispatch(getBanksList({})).then((res) => {
			setBank(res?.data);
		});
		dispatch(getAlChequeListForSettlement({})).then((res) => {
			setAllChq(res?.rows);
		});
	}, []);
	const handleupload = (info) => {
		setSelectedFileList([info?.file]);
	};
	const handleRowData = (val) => {
		setRowData(val);
	};
	function SetFun(e) {
		if (e.target.value) {
			setSearchValue(e.target.value);
		}
		if (e.target.value === '') {
			setSearchValue('');
			dispatch(getChequeListForSettlement({ q: '' })).then((res) => {
				setRowData(res);
			});
		}
	}
	function onClickSearch() {
		if (searchValue) {
			dispatch(getChequeListForSettlement({ q: searchValue })).then((res) => {
				setRowData(res);
			});
		}
		if (searchValue === '' || searchValue === undefined) {
			dispatch(getChequeListForSettlement({ q: '', offset: 50 })).then((res) => {
				setRowData(res);
			});
		}
	}
	const handlePrint = () => {
		const selecteedPayIDs = [];
		if (rowSelect.length > 0) {
			allCheque.forEach((element) => {
				rowSelect.forEach((item) => {
					if (item === element?.id) {
						selecteedPayIDs.push(element?.payment_ids);
					}
				});
			});
			console.log(selecteedPayIDs);
			const payload = selecteedPayIDs;

			return dispatch(getPrintCheques(payload, bankname));
		}
		return true;
	};
	const props = {
		name: 'file',
		onChange(info) {
			if (info.file.status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (info.file.status === 'done') {
				console.log('done');
			} else if (info.file.status === 'error') {
				console.log('error');
			}
		},
	};
	const rowSelection = {
		onChange: (selectedRowKeys) => {
			console.log(selectedRowKeys);
			setrowSelect(() => [...selectedRowKeys]);
			setmyArray(() => allCheque.filter((x) => selectedRowKeys.includes(x.id)));
		},
	};
	const handleSettle = async (settleIt, reasons = '') => {
		const transactions_list = [];
		allCheque.forEach((element) => {
			console.log(element);
			rowSelect.forEach((item) => {
				if (item === element?.id) {
					transactions_list.push({
						payment_checked: true,
						payment_ids: element?.payment_ids,
						invoice_ids: element?.invoice_nos,
					});
				}
			});
		});
		console.log(transactions_list);
		await getBase64(selectedFileList?.[0]?.originFileObj).then((res) => {
			const payload = {
				reason: reasons,
				settled_bank: bankname,
				challan_copy_attachment: res,
				transactions: transactions_list,
			};
			console.log(payload);
			dispatch(ChequeSettlementInsert(payload)).then(() => {
				window.location.reload();
			});
		});
	};
	return (
		<>
			<Flex>
				<LabelWrapper label="Search" marginspace="-1px 0 5px 0">
					<Input
						placeholder="Cheque number"
						name="Cheque"
						maxLength={6}
						autoFocus
						allowClear
						value={searchValue}
						onChange={(val) => SetFun(val)}
					/>
				</LabelWrapper>
				<Btn type="button" onClick={onClickSearch}>
					Search
				</Btn>
			</Flex>
			{!isEmpty(rowSelect) && (
				<FlexWrap>
					<Box>{rowSelect.length} items selected</Box>
					<Flex flexDirection="column" alignItems="center">
						<Button
							disabled={rowSelect.length === 0}
							onClick={() => setModalVisible(true)}
						>
							View selected items
						</Button>
					</Flex>
				</FlexWrap>
			)}
			<Box mt={3} className="bankcheque-settlement">
				<RCTable
					rowKey="id"
					tableDatas={[rowData, handleRowData]}
					rowSelection={{
						type: 'checkbox',
						preserveSelectedRowKeys: true,
						hideSelectAll: rowSelect.length > 199,
						...rowSelection,
					}}
					dispatchAction={getChequeListForSettlement}
					columns={chequeSettlementTableConfig}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					disableAutoSearch
				/>
			</Box>
			<BoxFlx>
				<Flex padding="5px 50px 20px" justifyContent="space-between">
					<Flex>
						<Radio.Group
							onChange={({ target }) => setBankname(target?.value)}
							name="bankname"
							value={bankname}
						>
							{banks?.map((bank) => (
								<Radio key={bank?.id} value={bank?.id}>
									{bank?.bank_name}
								</Radio>
							))}
						</Radio.Group>
					</Flex>
					<Flex flexDirection="column" alignItems="center">
						<Button
							backgroundColor="#0094FF"
							borderRadius="4px"
							fontSize="12px"
							lineHeight="15px"
							color="#FFFFFF"
							onClick={() => handlePrint()}
							disabled={
								rowSelect.length === 0 ||
								rowSelect.length > CHEQUE_MAX_COUNT ||
								!bankname
							}
						>
							Print
						</Button>
					</Flex>
				</Flex>
				<Flex padding="5px 50px 30px" justifyContent="space-between">
					<Box minWidth="286px" className="uploadChallen">
						<Text color="#000000" fontSize="12px" lineHeight="15px" margin="0 0 8px">
							Upload Challan copy
						</Text>
						<UploadWrap
							{...props}
							customRequest={dummyRequest}
							fileList={selectedFileList}
							maxCount={1}
							accept={pdfimgformates}
							onChange={handleupload}
							onRemove={false}
						>
							<Flex
								width="100%"
								justifyContent="space-between"
								padding="0 10px"
								color="#000"
								fontSize="12px"
								lineHeight="15px"
							>
								<Box>Upload files</Box>
								<Box>
									<SVGICONS.AttachmentSvg />
								</Box>
							</Flex>
						</UploadWrap>
						<Box fontSize="12px" lineHeight="15px" color="#4B4B4B">
							( Supported format: PDF, JPEG, PNG, JPG )
						</Box>
					</Box>
					<CommonButtons
						backgroundColor="#CA5010"
						color="#fff"
						onClick={() => handleSettle(false)}
						disabled={
							!bankname ||
							isEmpty(selectedFileList) ||
							rowSelect.length === 0 ||
							rowSelect.length > CHEQUE_MAX_COUNT
						}
					>
						Settle
					</CommonButtons>
				</Flex>
			</BoxFlx>
			{modalVisible && (
				<Modal
					visible={modalVisible}
					title={
						<Flex justifyContent="space-between">
							<TextHeader>Total {rowSelect.length} Selected Items </TextHeader>
						</Flex>
					}
					onOk={() => setModalVisible(false)}
					onCancel={() => setModalVisible(false)}
					footer={null}
					width="70%"
				>
					<Box>
						<Table
							key="id"
							columns={chequeSettlementTableConfig}
							dataSource={myArray}
						/>
					</Box>
				</Modal>
			)}
		</>
	);
}
