import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Text } from 'atoms';
import { Rupee } from 'components';
import { RemarksIcon, Verification } from 'components/pages/segregator';
import { useURL } from 'hooks';
import { getcashierVerificationList } from 'store/cashierSlice';

export default function CashVerifyHeader({
	handleVerifyReject,
	record = {},
	colleted_pid,
	allocated_date,
	tab,
}) {
	console.log(colleted_pid);
	const urlData = useURL();
	const dispatch = useDispatch();
	const [cashierData, setcashierData] = useState({});
	const { cashierPersonHeaderDetails = {} } = useSelector((state) => state.invoice);
	useEffect(() => {
		if (colleted_pid && allocated_date && tab)
			dispatch(getcashierVerificationList(colleted_pid, allocated_date, tab)).then((res) => {
				console.log(res);
				setcashierData(res);
			});
	}, [dispatch, colleted_pid, allocated_date, tab]);

	console.log(record);
	console.log(cashierData);

	return (
		<Container>
			<Flex width="60%" alignItems="center">
				<Title>Total Cash Collected: </Title>
				<Text as="span" fontSize="16px" fontWeight="bold">
					<Rupee>{cashierData?.total_cash}</Rupee>
				</Text>
			</Flex>
			<Flex width="40%" justifyContent="space-between">
				<Flex alignItems="center">
					<Title>Verification</Title>
					<Verification
						record={record}
						handleVerifyReject={handleVerifyReject}
						showRejection
						remarksType="CASH_PAYMENT_REJECT"
						disabled={
							cashierPersonHeaderDetails?.data_summary?.payment_verification_status
								?.cash_verification_checked || false
						}
					/>
				</Flex>
				<Flex alignItems="center">
					<Title>Remarks</Title>
					<RemarksIcon record={{ ...urlData, ...record }} type="CASH_PAYMENT" />
				</Flex>
			</Flex>
		</Container>
	);
}

export const Container = styled(Flex)`
	justify-content: space-between;
	border: 1px solid ${(props) => props.theme.colors.Gainsboro};
	background-color: ${(props) => props.theme.colors.white};
	padding: 15px 25px;
	margin-bottom: 1.5rem;
`;

export const Title = styled(Text)`
	font-weight: 400;
	font-size: 14px;
	margin-right: 1.5rem;
`;

CashVerifyHeader.propTypes = {
	record: PropTypes.object,
	handleVerifyReject: PropTypes.func,
	colleted_pid: PropTypes.number,
	allocated_date: PropTypes.string,
	tab: PropTypes.number,
};
