import { Text } from 'atoms';
export const InventoryProductListColumns = [
	{
		title: 'FC',
		dataIndex: 'FC',
		key: 'FC',
		sortId: 1,
		overflow: 'hidden',
		hidden: false,
		render: (FC) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{FC?.name || ''}
			</Text>
		),
	},
	{
		title: 'Brand',
		dataIndex: 'Brand',
		key: 'Brand',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (Brand) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{Brand?.name || ''}
			</Text>
		),
	},
	{
		title: 'Product(code)',
		dataIndex: 'Product',
		key: 'Product',
		sortId: 4,
		overflow: 'hidden',
		hidden: false,
		render: (Product) => (
			<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
				{Product ? `${Product?.name}(${Product?.code})` : ''}
			</Text>
		),
	},
	{
		title: 'Available Qty',
		dataIndex: 'qty',
		key: 'qty',
		sortId: 5,
		overflow: 'hidden',
		hidden: false,
		render: (qty) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{qty || ''}
			</Text>
		),
	},
];
