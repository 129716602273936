import styled from 'styled-components';
import { SubmitButton } from 'formik-antd';
import { Flex, Box } from 'atoms';

export const BtnWrap = styled(Flex)`
	justify-content: flex-end;
`;

export const StyledSubmitButton = styled(SubmitButton)`
	border: 0;
	border-radius: 0;
	background-color: ${(props) => props.theme.colors.primary};
	color: ${(props) => props.theme.colors.white};
	display: flex;
	&:hover {
		background-color: ${(props) => props.theme.colors.primary};
		color: ${(props) => props.theme.colors.white};
	}
`;

export const renderBrandOptions = (options, onhandleClose = null, hasmultiple = false) => (
	<Box title={options.label} className="ant-select-selection-item" as="span">
		<Box as="span" className="ant-select-selection-item-content">
			{options.label}
		</Box>
		<Box
			className="ant-select-selection-item-remove"
			as="span"
			onClick={() => (hasmultiple ? onhandleClose(options.value) : onhandleClose())}
		>
			x
		</Box>
	</Box>
);

// #region User form Configs

export const SalesmanInitialValues = (userFormData, editMode, brandList) => {
	let filterBrands = [];
	if (editMode) {
		filterBrands = brandList?.filter(
			(x) => x.value === `${userFormData?.Brand?.id}_${userFormData?.FC?.id}`
		)?.[0];
	}

	return {
		name: userFormData?.name || undefined,
		code: userFormData?.code || undefined,
		mobile: userFormData?.mobile || undefined,
		fc_id: '',
		brand_id: editMode ? filterBrands : undefined,
	};
};

// #endregion
