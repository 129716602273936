import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DateFormat from 'components/DateFormat';

const CustomModal = styled(Modal)`
	width: 800px !important;
	color: #e6e6e6;
	.ant-modal-header {
		background: #434343;
		.ant-modal-title {
			color: #ffffff;
		}
		border-bottom: none;
	}
	.ant-modal-body {
		background: #4d4d4d;
	}
	.ant-modal-content {
		.ant-modal-close {
			color: #9b9b9b;
		}
		h3 {
			color: #ffffff;
		}
	}
	.no-logs-found {
		font-size: 16px;
		text-align: center;
		padding: 30px 0;
	}
`;

export default function ShowGrnUploadLogs({ visible, data, onClose, salesReturn }) {
	const [histroyList, setHistroyList] = useState(null);
	useEffect(() => {
		let histroyData;
		if (salesReturn) {
			histroyData = data?.credit_note_invoices.filter(
				(x) => x.is_active === 0 && getHistoryList(x?.created_at)
			);
		} else {
			histroyData = data?.GrnSignedInvoices.filter(
				(x) => x.is_active !== true && getHistoryList(x?.created_at)
			);
		}

		setHistroyList(histroyData);
	}, [data]);

	function getFileName(res) {
		return res?.split('/').pop();
	}

	function bytesToSize(bytes) {
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes === 0) return 'n/a';
		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
		if (i === 0) return `${bytes} ${sizes[i]})`;
		return `${(bytes / 1024 ** i).toFixed(0)} ${sizes[i]}`;
	}

	const getHistoryList = (res, date = 30) => {
		const currentTime = moment();
		const deleteLogDaysLeft = currentTime.diff(res, 'days');
		return deleteLogDaysLeft <= date;
	};

	const handleOk = () => {
		onClose();
	};

	const handleCancel = () => {
		onClose();
	};

	return (
		<CustomModal
			title={<div>Deleted Logs</div>}
			visible={visible}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={null}
		>
			<div>
				{histroyList &&
					histroyList.map((res, index) => (
						<Row
							style={
								index === 0
									? { borderTop: 'none', padding: '6px 0px' }
									: { borderTop: '1px solid #656565', padding: '6px 0px' }
							}
							key={res?.id}
							gutter={[32, 32]}
						>
							<Col span={12}>
								<h3>
									File name: {res?.file_name ? getFileName(res?.file_name) : '-'}{' '}
								</h3>
								<p
									style={{
										background: '#434343',
										padding: '3px 6px',
										borderRadius: '6px',
									}}
								>
									{res?.deleted_by_name || '-'} deleted this file on{' '}
									<DateFormat date={res?.updated_at} format="HMSA" />
								</p>
							</Col>
							<Col span={12}>
								<h3>Uploaded By:</h3>
								<p>
									{res?.uploaded_by_name || '-'}
									<br />
									<span style={{ color: '#9F9F9F' }}>
										{res?.updated_at ? (
											<DateFormat date={res?.created_at} format="HMSA" />
										) : (
											'-'
										)}
										<span style={{ marginLeft: 30 }}>
											{res?.file_size ? bytesToSize(res?.file_size) : null}
										</span>
									</span>
								</p>
							</Col>
						</Row>
					))}
				{histroyList?.length === 0 && (
					<p className="no-logs-found">No logs found in the last 30 days!</p>
				)}
			</div>
		</CustomModal>
	);
}

ShowGrnUploadLogs.propTypes = {
	visible: PropTypes.bool,
	data: PropTypes.object,
	onClose: PropTypes.func,
	salesReturn: PropTypes.string,
};
