import React from 'react';
import { Col, Row } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as SVGICONS from 'utils/Svg';
import { FormInput, CustomInput, Flex, Box } from 'atoms';
import { StyledSubmitButton, BankFormSchema, BankInitialValues } from './helper';
import { addBank } from 'store/masters';

function BankForm() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	function handleSubmit(values, { setErrors, resetForm, setSubmitting }) {
		dispatch(addBank(values, setErrors))
			.then(() => {
				setSubmitting(false);
				resetForm();
				navigate('/onboarding/masters/bank');
			})
			.catch(() => {
				setSubmitting(false);
			});
	}

	return (
		<Formik
			initialValues={BankInitialValues}
			validationSchema={BankFormSchema}
			onSubmit={handleSubmit}
			enableReinitialize
			initialTouched={{
				name: false,
				code: false,
				country: false,
			}}
		>
			{({ isSubmitting, isValid, dirty }) => (
				<Form layout="vertical">
					<Row gutter={8} justify="flex-start">
						<Col lg={8} sm={24}>
							<FormInput name="name" label="*Bank Name">
								<CustomInput name="name" />
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput name="code" label="*Bank Code">
								<CustomInput name="code" />
							</FormInput>
						</Col>

						<Col lg={8} sm={24}>
							<FormInput name="country" label="*Country">
								<CustomInput name="country" />
							</FormInput>
						</Col>
					</Row>
					<StyledSubmitButton
						disabled={isSubmitting || !isValid || !dirty}
						id="add-user-submit"
					>
						<Flex alignItems="center" justifyContent="center">
							<Box width={30}>
								<SVGICONS.SaveIcons />
							</Box>
							<Box>Save</Box>
						</Flex>
					</StyledSubmitButton>
				</Form>
			)}
		</Formik>
	);
}

export default BankForm;
