import * as Yup from 'yup';
// form schema
const FormSchema = Yup.object({
	invoice_date__gte: Yup.string().required().label('From date'),
	invoice_date__lte: Yup.date()
		.required()
		.label('To date')
		.when(
			'invoice_date__gte',
			(invoice_date__gte, yup) =>
				invoice_date__gte &&
				yup.min(invoice_date__gte, 'To date cannot be less than From date')
		),
});

export default FormSchema;
