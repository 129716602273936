import { Col, Row } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { LoginInput } from 'atoms';
import { IconLabel } from 'components/common';
import { forgotPassword } from 'store/authSlice';
import PasswordIcon from 'assets/password.svg';
import * as styled from './AuthFormStyle';
import { validateRipplrEmail } from 'utils/fieldValidation';

const { LoginContainer, NavigateText, StyledSubmitButton } = styled;

const FormSchema = Yup.object({
	email: Yup.string()
		.required()
		.label('Email')
		.test('is-RipplrEmail', 'Not a valid Email', (value) => validateRipplrEmail(value)),
});

const initialValues = {
	email: undefined,
};
function ForgotPasswordForm() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	function handleSubmit(values, { setErrors, resetForm, setSubmitting }) {
		dispatch(forgotPassword(values, setErrors))
			.then(() => {
				setSubmitting(false);
				resetForm();
				setTimeout(() => {
					navigate('/login');
				}, 0);
			})
			.catch((e) => {
				setSubmitting(false);
				console.log('forgot Password form catch', e);
			})
			.finally(() => {
				setSubmitting(false);
			});
	}

	return (
		<LoginContainer>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={FormSchema}
				onSubmit={handleSubmit}
			>
				{({ isSubmitting }) => (
					<Form layout="vertical">
						<Row gutter={8} justify="space-between">
							<Col span={24}>
								<LoginInput
									name="email"
									label={<IconLabel label="Email ID" icon={PasswordIcon} />}
									className="email-field"
								>
									<Input name="email" placeholder="Enter your email id" />
								</LoginInput>
							</Col>
						</Row>

						<Row gutter={8} justify="space-between">
							<Col span={24}>
								<StyledSubmitButton disabled={isSubmitting} id="forgot-pwd-btn">
									Request Reset Link
								</StyledSubmitButton>
							</Col>
						</Row>

						<NavigateText to="/login">Back To Login</NavigateText>
					</Form>
				)}
			</Formik>
		</LoginContainer>
	);
}

export default ForgotPasswordForm;
