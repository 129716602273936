import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Select, DatePicker, Input, message as toaster } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import moment from 'moment';
import { getReturnToFCList } from 'store/fulfilment/returnToFCSlice';
import { getFcListOnLoad, getFcOnSearchList } from 'store/masters';
import { Box, AsyncSelect, Button } from 'atoms';
import { RCTable, LoadAsyncSelectComponent, LabelWrapper } from 'components/common';
import { useQueryParams } from 'hooks';
import {
	RTCReturnToFCListColumns,
	returnToFCQueries,
	toAsysnOption,
	SelectFilterLabelOptions,
	handleFilterChange,
	initialPaginateConfigWithoutQ,
	searchBox,
	disableFutureDate,
	disableDateRangesNew,
	toAsysnPickersOption,
} from 'utils';
import { SearchField } from 'components/common/custom-table/Style';
import {
	getUserList,
	downloadRFC,
	downloadRFCFinal,
} from 'store/fulfilment/deliveryAllocationSlice';

const return_verification_status_options = [
	{ value: 'Complete', label: 'Complete' },
	{ value: 'Delivered', label: 'Delivered' },
	{ value: 'Pending', label: 'Pending' },
];

const returnToFcStatuses = [
	{ value: 'CO', label: 'Completed' },
	{ value: 'FC', label: 'FC' },
	{ value: 'DL', label: 'Delivered' },
	{ value: 'OD', label: 'Out for Delivery' },
	{ value: 'VA', label: 'Vehicle Allocated' },
];

export default function ReturnToFCPage() {
	const [rowData, setRowData] = useState({});
	const dispatch = useDispatch();
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [updatedRowData, setUpdatedRowData] = useState();

	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);
	const [deliveryBoyList, setDeliveryBoyList] = useState({ type: 'NotAsked' });
	const [paginateConfig, setpaginateConfig] = useQueryParams({
		filterQuery: returnToFCQueries,
	});

	useEffect(() => {
		setFcList({ type: 'Loading' });

		dispatch(
			getFcListOnLoad({ fcIds: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [] })
		).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		setDeliveryBoyList({ type: 'Loading' });
		dispatch(getUserList({})).then((res) => {
			setDeliveryBoyList({
				type: 'Success',
				data: toAsysnPickersOption(res),
			});
		});
	}, [dispatch]);

	const handleChangeSelect = (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value

		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		setpaginateConfig((prev) => ({
			...handleFilterChange(name, value?.value ? [value?.value] : [], prev),
			brands: [],
		}));
	};

	const handleGeneratePDF = (recordId) => {
		const downloadId = recordId.id;
		let downloadType = null;
		if (recordId?.downloadStatus === 'download') {
			return dispatch(downloadRFCFinal({ id: downloadId }));
		}

		return dispatch(downloadRFC({ id: downloadId })).then((res) => {
			if (res?.success) {
				downloadType = 'download';
				dispatch(downloadRFCFinal({ id: downloadId }));
			} else if (res?.success === false) {
				downloadType = 'sync';
				toaster.info(res?.message);
			} else {
				downloadType = 'generate';
			}
			const updatedData = rowData?.rows?.map((x) => {
				if (x.id === downloadId) {
					x.downloadStatus = downloadType;
				}
				return x;
			});
			setUpdatedRowData({ ...rowData, ...updatedData });
		});
	};

	return (
		<>
			<Box>
				<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
					<LoadAsyncSelectComponent
						loadFCs={paginateConfig?.fcs}
						setList={(val) => setBrandList(val)}
						options={fcsList}
					>
						<AsyncSelect
							style={searchBox}
							allowClear
							name="fcs"
							showSearch
							fetchOptions={getFcOnSearchList}
							transformOptions={(val) => toAsysnOption(val)}
							defaultValue={paginateConfig?.fcs}
							value={paginateConfig?.fcs}
							placeholder="Select FC(s)"
							onChange={(value, options) => {
								handleChangeSelect('fcs', value, options);
							}}
							selectOptions={[...(fcsList.data || [])]}
						/>
					</LoadAsyncSelectComponent>
				</LabelWrapper>
				<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
					<Select
						name="fields"
						style={searchBox}
						allowClear
						showSearch
						label="brands"
						mode="multiple"
						value={paginateConfig?.brands}
						filterOption={SelectFilterLabelOptions}
						options={brandsList}
						onChange={(value) =>
							setpaginateConfig((prev) => ({
								...prev,
								brands: value,
								...initialPaginateConfigWithoutQ,
							}))
						}
					/>
				</LabelWrapper>

				<LabelWrapper label="Search by Vehicle No" marginspace="0 0 5px 1rem">
					<SearchField width="200px" margin="0 1rem 1rem">
						<Input
							name="vehicle_no"
							placeholder="TN01AB1234"
							value={paginateConfig?.vehicle_no}
							onChange={(e) =>
								setpaginateConfig((prev) =>
									handleFilterChange('vehicle_no', e?.target?.value, prev)
								)
							}
						/>
					</SearchField>
				</LabelWrapper>

				<LabelWrapper label="Search by Driver Name" marginspace="0 0 5px 1rem">
					<SearchField width="200px" margin="0 1rem 1rem">
						<Input
							name="driver_name"
							placeholder="Search by Driver Name"
							value={paginateConfig?.driver_name}
							onChange={(e) =>
								setpaginateConfig((prev) =>
									handleFilterChange('driver_name', e?.target?.value, prev)
								)
							}
						/>
					</SearchField>
				</LabelWrapper>

				<LabelWrapper label="Return to FC Status" marginspace="3px 0 -1px 1rem">
					<Select
						name="return_verification_status"
						style={searchBox}
						allowClear
						label="Verification Status"
						showSearch
						filterOption={SelectFilterLabelOptions}
						value={paginateConfig?.store_verify_status}
						options={return_verification_status_options}
						placeholder="Return to FC Status"
						onChange={(value) =>
							setpaginateConfig((prev) =>
								handleFilterChange('return_verification_status', value, prev)
							)
						}
					/>
				</LabelWrapper>

				<LabelWrapper label="Delivery Status" marginspace="3px 0 -1px 1rem">
					<Select
						name="return_status"
						style={searchBox}
						allowClear
						label="Verification Status"
						showSearch
						filterOption={SelectFilterLabelOptions}
						value={paginateConfig?.store_verify_status}
						options={returnToFcStatuses}
						placeholder="Delivery Status"
						onChange={(value) =>
							setpaginateConfig((prev) =>
								handleFilterChange('return_status', value, prev)
							)
						}
					/>
				</LabelWrapper>
				<LabelWrapper label="Delivery Boy" marginspace="3px 0 -1px 1rem">
					<LoadAsyncSelectComponent options={deliveryBoyList}>
						<AsyncSelect
							showSearch
							style={searchBox}
							name="delivery_boy"
							value={paginateConfig?.delivery_boy}
							allowClear
							selectOptions={deliveryBoyList.data || []}
							fetchOptions={getUserList}
							transformOptions={(val) => toAsysnPickersOption(val)}
							placeholder="Delivery boy"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							onChange={(val) =>
								setpaginateConfig((prev) =>
									handleFilterChange('delivery_boy_id', val?.value, prev)
								)
							}
						/>
					</LoadAsyncSelectComponent>
				</LabelWrapper>
				<LabelWrapper label="Allocated From Date" marginspace="3px 0 -1px 1rem">
					<DatePicker
						name="date__gte"
						style={searchBox}
						label="From Date"
						value={paginateConfig?.date__gte ? moment(paginateConfig?.date__gte) : ''}
						disabledDate={disableFutureDate}
						onChange={(value) =>
							setpaginateConfig((prev) =>
								handleFilterChange('date__gte', value, prev)
							)
						}
						placeholder="From Date"
					/>
				</LabelWrapper>
				<LabelWrapper label="Allocated To Date" marginspace="3px 0 -1px 1rem">
					<DatePicker
						name="date__lte"
						style={searchBox}
						value={paginateConfig?.date__lte ? moment(paginateConfig?.date__lte) : ''}
						label="To Date"
						onChange={(value) =>
							setpaginateConfig((prev) =>
								handleFilterChange('date__lte', value, prev)
							)
						}
						disabled={!paginateConfig?.date__gte}
						disabledDate={disableDateRangesNew(paginateConfig?.date__gte)}
						placeholder="To Date"
					/>
				</LabelWrapper>
				<Button onClick={() => setBtnClickSearh(Math.random())}>Search</Button>
			</Box>
			<Box mt={3}>
				<RCTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					dispatchAction={getReturnToFCList}
					updateStatusAction={updatedRowData}
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'vehicle_no',
					}}
					columns={RTCReturnToFCListColumns({
						defaultSort: paginateConfig?.sort_direction || 'DESC',
						defaultsortField: paginateConfig?.sort_column || 'createdAt',
						handleGeneratePDF,
					})}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
		</>
	);
}
