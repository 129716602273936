import React from 'react';
import { Modal, Input, Table } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Flex, Box } from 'atoms';
import { Rupee } from 'components';

const InputSearch = styled(Input.Search)`
	input {
		border: 0;
		background: #f6f5f5;
		min-height: 32px;
		&:placeholder {
			color: #646464;
		}
		&:focus,
		&:hover {
			border: 0;
			box-shadow: none;
			outline: none;
		}
	}
	.ant-input-search-button {
		background: #f6f5f5;
		border: 0;
	}
`;

const columns = [
	{
		title: (
			<Box textAlign="center" fontWeight="300" fontSize="12px" color="#333333">
				Collected Date
			</Box>
		),
		dataIndex: 'collectionDate',
		key: 'collectionDate',
		render: (collectionDate) => (
			<Box textAlign="center" color="#000">
				{collectionDate ? moment(collectionDate).format('DD/MM/YYYY') : ''}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontWeight="300" fontSize="12px" color="#333333">
				Cheque Amount
			</Box>
		),
		dataIndex: 'amount',
		key: 'amount',
		render: (amt) => (
			<Box textAlign="center" color="#000">
				<Rupee>{amt || 0}</Rupee>
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontWeight="300" fontSize="12px" color="#333333">
				Due Date
			</Box>
		),
		dataIndex: 'dueDate',
		key: 'dueDate',
		render: (dueDate) => (
			<Box textAlign="center" color="#000">
				{dueDate ? moment(dueDate).format('DD/MM/YYYY') : ''}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontWeight="300" fontSize="12px" color="#333333">
				Cheque number
			</Box>
		),
		dataIndex: 'chequeNo',
		key: 'chequeNo',
		render: (chequeNo) => (
			<Box textAlign="center" color="#0094FF">
				{chequeNo || 0}
			</Box>
		),
	},
];

export function UnsettledChequeDetails({ toggleModal = [], chequelist = [], onChangeSearch }) {
	const [showModal, setShowModal] = toggleModal;
	const handleOk = () => {
		setShowModal(false);
	};

	const handleCancel = () => {
		setShowModal(false);
	};
	return (
		<Modal
			title="Unsettled cheque details"
			centered
			width="50%"
			visible={showModal}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={false}
			maskClosable={false}
		>
			<Box>
				<Flex padding="0px 24px" margin="0 0 20px" justifyContent="space-between">
					<Box alignSelf="center" fontSize="14px" lineHeight=" 18px" color="#023047">
						Unsettled cheque ({chequelist?.length || 0})
					</Box>
					<Box>
						<InputSearch onChange={onChangeSearch} placeholder="Cheque number" />
					</Box>
				</Flex>
				<Box margin="0 0 20px" maxHeight="500px" overflowY="auto">
					<Table
						rowKey={(record) => `${record?.key}`}
						columns={columns}
						dataSource={chequelist}
						pagination={false}
					/>
				</Box>
			</Box>
		</Modal>
	);
}

UnsettledChequeDetails.propTypes = {
	toggleModal: PropTypes.array,
	chequelist: PropTypes.array,
	onChangeSearch: PropTypes.func,
};
