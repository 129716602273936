import React from 'react';
import { message as toaster } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Box, Text } from 'atoms';

export default function VerifiedStatus({ record, data }) {
	const navigate = useNavigate();
	const { role } = useSelector((state) => state.auth);

	function handleClick() {
		const query = `?brand_id=${data?.brand_id}&collected_person_id=${data?.collected_person_id}&allocation_date_time=${data?.allocation_date_time}&verified_by_segregator_status=${data?.verified_by_segregator_status}&return_status=${data?.return_status}`;
		if (role === 'Segregator') {
			// if (data?.verified_by_segregator_status === 'Verified') {
			// 	toaster.info('collected invoices are already verified.');
			// } else if (data?.verified_by_segregator_status === 'Pending') {
			// 	toaster.error('collected invoices are in pending.');
			// } else {
			// 	navigate({
			// 		pathname: `/settlement/salesman-details`,
			// 		search: query,
			// 	});
			// }

			// Manoj asked to all to view the invoices for all status, so above logic commented out

			navigate({
				pathname: `/settlement/salesman-details`,
				search: query,
			});
		} else if (role === 'Cashier') {
			// if (data?.verified_by_cashier_status === 'Verified') {
			// 	toaster.info('collected invoices are already verified.');
			// } else if (data?.verified_by_segregator_status === 'Verified') {
			// 	navigate({
			// 		pathname: `/cashier/salesman-details`,
			// 		search: `${query}&verified_by_cashier_status=${data?.verified_by_cashier_status}`,
			// 	});
			// } else {
			// 	toaster.error('collected invoices are in pending.');
			// }

			// Manoj asked to all to view the invoices for all status, so above logic commented out

			navigate({
				pathname: `/cashier/salesman-details`,
				search: `${query}&verified_by_cashier_status=${data?.verified_by_cashier_status}`,
			});
		} else {
			toaster.error('Something went wrong');
		}
	}

	return (
		<Box cursor="pointer" onClick={() => handleClick()}>
			<Title record={record}>{record}</Title>
		</Box>
	);
}

const Title = styled(Text)`
	font-size: 12px;
	color: ${(props) => props.theme.colors.SlimyGreen};
	cursor: pointer;

	${({ record }) =>
		record === 'Ready for verification' &&
		css`
			color: ${(props) => props.theme.colors.CarrotOrange};
		`}
	${({ record }) =>
		record === 'Pending' &&
		css`
			color: ${(props) => props.theme.colors.TartOrange};
		`}
`;

VerifiedStatus.propTypes = {
	record: PropTypes.string,
	data: PropTypes.object,
};
