import { Input, Modal, Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClockCircleOutlined, CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';
import styled, { css } from 'styled-components';
import { setShowRemarks, getRemarksList, saveRemarks } from 'store/invoiceSlice';
import { Box, Button, Flex, Text } from 'atoms';
import { Line } from 'components';
import { useURL } from 'hooks';

const TabPane = styled(Text)`
	cursor: pointer;
	align-self: center;
	color: ${(props) => props.theme.colors.DimGray};
	${({ isActive }) =>
		isActive &&
		css`
			color: ${(props) => props.theme.colors.PrussianBlue};
			font-weight: bold;
		`}
`;

const LableView = styled(Box)`
	font-size: 12px;
	line-height: 18px;
`;

const CustomInput = styled(Input)`
	min-height: 48px;
	background: #f6f5f5;
	border-radius: 40px;
	border: 0;
	padding: 5px 140px 5px 20px;
	&:placeholder {
		color: #979797;
	}
	&:focus,
	&:hover {
		border: 0;
		box-shadow: none;
		outline: none;
	}
`;

const CustomButton = styled(Box)`
	position: absolute;
	top: 50%;
	right: 45px;
	transform: translateY(-50%);
	button {
		border-radius: 50px;
		min-height: 40px;
		background-color: ${(props) => props.theme.colors.DimGray};
		color: #fff;
		&:focus {
			outline: none;
		}
	}
`;

const Error = styled(Text)`
	color: ${(props) => props.theme.colors.SizzlingRed};
`;

const InputSearch = styled(Input.Search)`
	input {
		border: 0;
		background: #f6f5f5;
		min-height: 32px;
		&:placeholder {
			color: #646464;
		}
		&:focus,
		&:hover {
			border: 0;
			box-shadow: none;
			outline: none;
		}
	}
	.ant-input-search-button {
		background: #f6f5f5;
		border: 0;
	}
`;

function remarkType(type) {
	if (type.includes('REJECT')) {
		return <Error>Rejection</Error>;
	}
	return <div>&nbsp;</div>;
}

export default function RemarksList() {
	const {
		showRemarks = false,
		showRemarksRecord = {},
		remarkStatus = 'BULK',
	} = useSelector((state) => state.invoice);
	const [remarks, setRemarks] = useState('');
	const [search, setSearch] = useState('');
	const [selectedTab, setSelectedTab] = useState('All');
	const [remarksList, setRemarksList] = useState([]);
	const dispatch = useDispatch();
	const urlData = useURL();

	// console.log(urlData);

	const handleOk = () => {
		dispatch(setShowRemarks(false));
	};

	const handleCancel = () => {
		dispatch(setShowRemarks(false));
	};

	useEffect(() => {
		if (!showRemarks) {
			resetData();
		} else {
			triggerRemarksList();
		}

		return () => {
			resetData();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showRemarks]);

	// console.log(showRemarksRecord);

	function handleAddRemarks() {
		dispatch(
			saveRemarks({
				remark_status: remarkStatus,
				brand_id: showRemarksRecord?.brand_id || urlData?.brand_id,
				collected_person_id:
					showRemarksRecord?.collected_person_id || urlData?.collected_person_id,
				allocation_date_time:
					showRemarksRecord?.allocation_date_time || urlData?.allocation_date_time,
				remark: remarks,
				unique_key_code: showRemarksRecord?.unique_key || '',
				collection_invoice_id:
					showRemarksRecord?.invoice_ids || showRemarksRecord?.collection_invoice_id || 0,
				payment_ids: showRemarksRecord?.payment_ids || 0,
			})
		).then(() => {
			setRemarks('');
			triggerRemarksList();
		});
	}

	function triggerRemarksList() {
		if (showRemarks) {
			dispatch(
				getRemarksList({
					remark_status: remarkStatus,
					brand_id: showRemarksRecord?.brand_id || urlData?.brand_id,
					collected_person_id:
						showRemarksRecord?.collected_person_id || urlData?.collected_person_id,
					allocation_date_time:
						showRemarksRecord?.allocation_date_time || urlData?.allocation_date_time,
					unique_key_code: showRemarksRecord?.unique_key || '',
					collection_invoice_id: showRemarksRecord?.collection_invoice_id || 0,
					payment_ids: 0,
				})
			).then((res) => {
				setRemarksList(res.data);
			});
		}
	}

	function handleTabChange(type) {
		setSelectedTab(type);
	}

	function resetData() {
		setRemarksList([]);
		setRemarks('');
		setSelectedTab('All');
		setSearch('');
	}

	return (
		<Modal
			centered
			width="65%"
			title="Add Comment"
			visible={showRemarks}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={false}
			maskClosable={false}
		>
			<Box>
				<Flex
					padding="0 24px 16px"
					justifyContent="space-between"
					borderBottom="1px solid #D6D6D6"
				>
					<Flex flex="0 0 45%" justifyContent="space-between">
						<Text alignSelf="center">View comments:</Text>
						<TabPane
							isActive={selectedTab === 'All'}
							onClick={() => handleTabChange('All')}
						>
							All
						</TabPane>
						<TabPane
							isActive={selectedTab === 'Segregator'}
							onClick={() => handleTabChange('Segregator')}
						>
							Segregator
						</TabPane>
						<TabPane
							isActive={selectedTab === 'Cashier'}
							onClick={() => handleTabChange('Cashier')}
						>
							Cashier
						</TabPane>
					</Flex>
					<Box flex="0 0 30%">
						<InputSearch
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							placeholder="Search key word here..."
						/>
					</Box>
				</Flex>
				<Box padding="0 24px" height="300px" overflow="auto">
					{remarksList?.length === 0 && (
						<Flex height="100%" justifyContent="center" alignItems="center">
							<Empty description="No Remarks" />
						</Flex>
					)}
					{remarksList
						?.filter(
							(item) =>
								(item?.created_by_role === selectedTab || selectedTab === 'All') &&
								item?.remark?.includes(search)
						)
						?.map((x) => (
							<Flex
								key={x.id}
								padding="5px 10px"
								borderBottom="1px solid #CFCFCF"
								alignItems="center"
							>
								<Box flex=" 0 0 20%">
									<LableView>{x?.created_by_role}</LableView>
									<Box>{x?.created_by}</Box>
								</Box>
								<Box flex=" 0 0 45%">
									<LableView>{remarkType(x.remark_status) || ' '}</LableView>
									<Box>{x.remark}</Box>
								</Box>
								<Flex flex=" 0 0 25%" marginLeft="auto" alignItems="center">
									<Line height="25px" mx="1rem" />
									<LableView marginRight="20px">
										<CalendarOutlined style={{ marginRight: '5px' }} />
										{moment
											.utc(x?.createdAt_new_format)
											.local()
											.format('DD-MMMM-YYYY')}
									</LableView>
									<LableView>
										<ClockCircleOutlined style={{ marginRight: '5px' }} />
										{moment
											.utc(x?.createdAt_new_format)
											.local()
											.format('h:mm:ss a')}
									</LableView>
								</Flex>
							</Flex>
						))}
				</Box>
				<Box padding="0 40px" marginBottom="8px" mt="2rem">
					<CustomInput
						onChange={(e) => setRemarks(e.target.value)}
						value={remarks}
						placeholder="Type your comment here..."
						maxLength={400}
					/>
					<CustomButton>
						<Button disabled={remarks?.length < 5} onClick={handleAddRemarks}>
							Add
						</Button>
					</CustomButton>
				</Box>
			</Box>
		</Modal>
	);
}
