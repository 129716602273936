import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex } from 'atoms';
import { SuccussFullIcons, FailedIcons } from 'utils';

export function ErrorNotificationModal({
	modalErrorVisible,
	setModalErrorVisible,
	onLoadAsyncCall,
}) {
	return (
		<Modal
			centered
			destroyOnClose
			onCancel={() => {
				setModalErrorVisible({ errData: null, visible: false });
				onLoadAsyncCall();
			}}
			footer={null}
			width="40%"
			visible={modalErrorVisible.visible}
			className="nextDayErrorPopup"
		>
			<Box>
				<Box textAlign="center" marginBottom="1rem">
					{modalErrorVisible?.errData?.totalInvoicesCount !==
					modalErrorVisible?.errData?.unsuccessful_invoices_array?.length ? (
						<SuccussFullIcons />
					) : (
						<FailedIcons />
					)}
				</Box>
				<Box textAlign="center" marginBottom="1rem">
					<Box marginBottom="5px">
						{modalErrorVisible?.errData?.invoicesHandedOverCount} /{' '}
						{modalErrorVisible?.errData?.totalInvoicesCount}{' '}
					</Box>
					<Box width="60%" margin="auto">
						{modalErrorVisible?.errData?.message}
					</Box>
				</Box>
				{modalErrorVisible?.errData?.unsuccessfulMessageCount > 0 && (
					<Box>
						<Box fontSize="16px" marginBottom="1rem">
							Failed Invoice in Resubmission
						</Box>
						<Box
							height={
								modalErrorVisible?.errData?.unsuccessfulMessageCount > 2
									? '200px'
									: '110px'
							}
							overflow="auto"
						>
							<Flex width="100%" borderBottom="1px solid #E6E6E6" padding="10px 0px">
								<Box flex="0 0 30%">Invoice Number</Box>
								<Box flex="0 0 70%">Reason</Box>
							</Flex>
							{modalErrorVisible?.errData?.unsuccessful_invoices_array &&
								modalErrorVisible?.errData?.unsuccessful_invoices_array?.map(
									(x) => (
										<Flex
											width="100%"
											borderBottom="1px solid #E6E6E6"
											padding="10px 0px"
											key={x}
										>
											<Box flex="0 0 30%">{x?.invoice_no}</Box>
											<Box flex="0 0 70%">{x?.message}</Box>
										</Flex>
									)
								)}
						</Box>
					</Box>
				)}
			</Box>
		</Modal>
	);
}

ErrorNotificationModal.propTypes = {
	modalErrorVisible: PropTypes.object,
	setModalErrorVisible: PropTypes.func,
	onLoadAsyncCall: PropTypes.func,
};
