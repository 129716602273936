import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Select } from 'antd';
import { useDispatch } from 'react-redux';
import { Box, Flex, Button, AsyncSelect } from 'atoms';
import * as SVGICONS from 'utils/Svg';
import { SearchField } from 'components/common/custom-table/Style';
import { ACL, RCTable, LabelWrapper, LoadAsyncSelectComponent, SearchBar } from 'components/common';
import {
	userListColumns,
	UserPersmission,
	handleFilterChange,
	SelectFilterLabelOptions,
	toAsysnOption,
	UserQueries,
} from 'utils';
import { getFcOnSearchList, getUsersList } from 'store/masters';
import { getUserGroups } from 'store/userSlice';
import { useQueryParams } from 'hooks';

const CommonStyle = {
	width: '200px',
	alignSelf: 'center',
	margin: '0 1rem 1rem',
};

const transformOptions = (res, label, value) =>
	res?.rows?.map((x) => ({ label: x[label], value: x[value] })) || [];

export default function User() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [fcList, setFcList] = useState({ type: 'NotAsked' });
	const [userGroups, setuserGroups] = useState({ type: 'NotAsked' });
	const [rowData, setRowData] = useState({});
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [paginateConfig, setpaginateConfig] = useQueryParams({ filterQuery: UserQueries });

	useEffect(() => {
		setFcList({ type: 'Loading' });
		setuserGroups({ type: 'Loading' });

		dispatch(getUserGroups()).then((res) =>
			setuserGroups({ type: 'Success', data: transformOptions(res, 'name', 'id') })
		);

		dispatch(getFcOnSearchList({ q: '**' })).then((res) =>
			setFcList({ type: 'Success', data: toAsysnOption(res) })
		);
	}, [dispatch]);

	const handleChangeSelect = (name, value) => {
		const getValue = isEmpty(value) ? [] : value.map((y) => y.value);
		setpaginateConfig((prev) => handleFilterChange(name, getValue, prev));
	};

	return (
		<>
			<Flex alignItems="center" margin="0 0 1rem">
				<Flex width="100%" flexWrap="wrap" alignItems="center" gap="4px">
					<LabelWrapper label="Group(s)" marginspace="0 0 5px 1rem">
						<LoadAsyncSelectComponent options={userGroups}>
							<Select
								name="groups"
								style={CommonStyle}
								allowClear
								label="Select Groups"
								mode="multiple"
								showSearch
								defaultValue={userGroups?.data?.filter((x) =>
									paginateConfig?.groups?.includes(x.value)
								)}
								filterOption={SelectFilterLabelOptions}
								options={[...(userGroups?.data || [])]}
								placeholder="Search By"
								onChange={(value) =>
									setpaginateConfig((prev) =>
										handleFilterChange('groups', value, prev)
									)
								}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>

					<LabelWrapper label="Select FC(s)" marginspace="0 0 5px 1rem">
						<LoadAsyncSelectComponent options={fcList}>
							<AsyncSelect
								style={CommonStyle}
								allowClear
								name="fcs"
								mode="multiple"
								defaultValue={fcList?.data?.filter((x) =>
									paginateConfig?.fcs?.includes(x.value)
								)}
								fetchOptions={getFcOnSearchList}
								placeholder="Select FC(s)"
								onChange={(value) => handleChangeSelect('fcs', value)}
								selectOptions={[...(fcList?.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>
					<LabelWrapper label="Search by email" marginspace="0 0 5px 1rem">
						<SearchField margin="0px 1rem 1rem">
							<SearchBar
								placeholder="Search by email"
								queryValue={paginateConfig.user_mail}
								onSearch={(val) => {
									setpaginateConfig((prev) => ({
										...prev,
										user_mail: val,
										offset: 0,
										limit: 10,
										currentPage: 1,
									}));
								}}
							/>
						</SearchField>
					</LabelWrapper>
					<LabelWrapper label="Search by name" marginspace="0 0 5px 1rem">
						<SearchField margin="0px 1rem 1rem">
							<SearchBar
								placeholder="Search by name"
								queryValue={paginateConfig.name}
								onSearch={(val) => {
									setpaginateConfig((prev) => ({
										...prev,
										name: val,
										offset: 0,
										limit: 10,
										currentPage: 1,
									}));
								}}
							/>
						</SearchField>
					</LabelWrapper>
					<LabelWrapper label="Search by mobile number" marginspace="0 0 5px 1rem">
						<SearchField margin="0px 1rem 1rem">
							<SearchBar
								placeholder="Search by mobile number"
								queryValue={paginateConfig.mobile_no}
								onSearch={(val) => {
									setpaginateConfig((prev) => ({
										...prev,
										mobile_no: val,
										offset: 0,
										limit: 10,
										currentPage: 1,
									}));
								}}
							/>
						</SearchField>
					</LabelWrapper>

					<Button onClick={() => setBtnClickSearh(Math.random())}>Search</Button>
				</Flex>
				<ACL allowedGroups={UserPersmission.user_create}>
					<Button onClick={() => navigate('/onboarding/user/add')} type="variant">
						<Flex alignItems="center" justifyContent="center">
							<Box width={30}>
								<SVGICONS.PlusOutlinedIcons />
							</Box>
							<Box>Add User</Box>
						</Flex>
					</Button>
				</ACL>
			</Flex>
			<Box mt={3}>
				<RCTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					dispatchAction={getUsersList}
					columns={userListColumns}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					configs={{ defaultSort: 'DESC', defaultsortField: 'created_at' }}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
		</>
	);
}
