import React from 'react';
import { Table, Modal } from 'antd';
import PropTypes from 'prop-types';
import { Text } from 'atoms';

const columnsData = [
	{
		title: 'Store Code',
		dataIndex: 'store_code',
		key: 'store_code',
		render: (store_code) => <Text textTransform="uppercase">{store_code}</Text>,
	},
	{
		title: 'Store',
		dataIndex: 'store_name',
		key: 'store_name',
		render: (store_name) => <Text textTransform="uppercase">{store_name}</Text>,
	},
	{
		title: 'Invoice No.',
		dataIndex: 'invoice_no',
		key: 'invoice_no',
		render: (invoice_no) => <Text textTransform="uppercase">{invoice_no}</Text>,
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		key: 'amount',
	},
	{
		title: 'Payment Mode',
		dataIndex: 'payment_type',
		key: 'payment_type',
	},
	{
		title: 'Reference',
		dataIndex: 'reference_number',
		key: 'reference_number',
	},
	{
		title: 'Due Date',
		dataIndex: 'due_date',
		key: 'due_date',
	},
];

export default function RejectedListModal({ visible, rejectedList, onCancel, confirmRejection }) {
	return (
		<Modal
			title={<Text textAlign="center">Below list of payments will be rejected</Text>}
			visible={visible}
			onOk={confirmRejection}
			onCancel={onCancel}
			okText="Confirm"
			width={1000}
			maskClosable={false}
			bodyStyle={{
				maxHeight: '500px',
				overflow: 'auto',
			}}
		>
			<Table
				rowKey={(record) => `${record?.key}`}
				pagination={false}
				dataSource={rejectedList}
				columns={columnsData}
			/>
		</Modal>
	);
}

RejectedListModal.propTypes = {
	visible: PropTypes.bool,
	rejectedList: PropTypes.array,
	onCancel: PropTypes.func,
	confirmRejection: PropTypes.func,
};
