import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { Text } from 'atoms';
import { Rupee } from 'components';

const validateQty = (value) => (value >= 0 ? value : 0);

const columns = [
	{
		title: 'Product Name',
		dataIndex: 'product_name',
		key: 'product_name',
		render: (product_name) => <Text>{product_name}</Text>,
	},
	{
		title: 'Brand Return Qty',
		dataIndex: 'return_qty',
		key: 'return_qty',
		render: (return_qty) => <Text>{return_qty}</Text>,
	},
	{
		title: 'Unit Price',
		dataIndex: 'unit_price',
		key: 'unit_price',
		render: (unit_price) => <Rupee>{unit_price}</Rupee>,
	},
	{
		title: 'Gross Amount',
		dataIndex: 'gross_amount',
		key: 'gross_amount',
		render: (gross_amount) => <Rupee>{gross_amount}</Rupee>,
	},
	{
		title: 'Total Discount',
		dataIndex: 'total_discount',
		key: 'total_discount',
		render: (total_discount) => <Rupee>{total_discount}</Rupee>,
	},
	{
		title: 'Taxable Amount',
		dataIndex: 'taxable_amount',
		key: 'taxable_amount',
		render: (taxable_amount) => <Rupee>{taxable_amount}</Rupee>,
	},
	{
		title: 'Tax Amount',
		dataIndex: 'tax_amount',
		key: 'tax_amount',
		render: (tax_amount) => <Rupee>{tax_amount}</Rupee>,
	},
	{
		title: 'Net Amount',
		dataIndex: 'net_amount',
		key: 'net_amount',
		render: (net_amount) => <Rupee>{net_amount}</Rupee>,
	},
	{
		title: 'Free qty',
		dataIndex: 'free_qty',
		key: 'free_qty',
		render: (free_qty) => <Text>{validateQty(free_qty)}</Text>,
	},
];

const brandSalesReturnSummary = (data) => (
	<Table.Summary.Row>
		<Table.Summary.Cell index={0}>Total: {data?.count}</Table.Summary.Cell>
		<Table.Summary.Cell index={1}>{data?.total_return_qty}</Table.Summary.Cell>
		<Table.Summary.Cell index={2}></Table.Summary.Cell>
		<Table.Summary.Cell index={3}>
			<Rupee>{data?.total_gross_amount}</Rupee>
		</Table.Summary.Cell>
		<Table.Summary.Cell index={4}>
			<Rupee>{data?.total_discount_amount}</Rupee>
		</Table.Summary.Cell>
		<Table.Summary.Cell index={5}>
			<Rupee>{data?.total_taxable_amount}</Rupee>
		</Table.Summary.Cell>
		<Table.Summary.Cell index={6}>
			<Rupee>{data?.total_tax_amount}</Rupee>
		</Table.Summary.Cell>
		<Table.Summary.Cell index={7}>
			<Rupee>{data?.total_net_amount}</Rupee>
		</Table.Summary.Cell>
		<Table.Summary.Cell index={8}>{data?.total_free_qty}</Table.Summary.Cell>
	</Table.Summary.Row>
);

export default function BrandShowTable({ data }) {
	const tableData = data?.brand_return_details?.map((x, index) => ({
		key: index,
		product_name: x?.product_name,
		return_qty: x?.return_qty,
		unit_price: x?.unit_price,
		gross_amount: x?.gross_amount,
		total_discount: x?.total_discount,
		taxable_amount: x?.taxable_amount,
		tax_amount: x?.tax_amount,
		net_amount: x?.net_amount,
		free_qty: x?.free_qty,
		data: x,
	}));

	return (
		<Table
			columns={columns}
			dataSource={tableData}
			pagination={false}
			summary={() => brandSalesReturnSummary(data)}
			// This is for fixing header at the top in sales return detail view page
			// scroll={{ x: 950, y: 'calc(100vh - 220px)' }}
		/>
	);
}

BrandShowTable.propTypes = {
	data: PropTypes.object,
};
