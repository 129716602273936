import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const WrapperLabel = styled.label`
	display: inline-block;
`;

const Label = styled.p`
	color: ${(props) => props.theme.colors.primary};
	font-size: 10px;
	margin: ${(props) => props.marginspace};
`;

export default function LabelWrapper({ label = '', marginspace = '0', children, style }) {
	return (
		<WrapperLabel>
			<Label marginspace={marginspace} style={style}>
				{label}
			</Label>
			{children}
		</WrapperLabel>
	);
}

LabelWrapper.propTypes = {
	label: PropTypes.string,
	marginspace: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	style: PropTypes.object,
};
