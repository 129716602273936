import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { BrandForm } from 'components/forms';

export default function AddBrand() {
	const { id: brandId } = useParams();
	const { pathname } = useLocation();
	const isEditPage = pathname.includes('edit');
	return (
		<>
			<h1>{isEditPage ? 'Edit' : 'Add'} Brand Details</h1>
			<BrandForm isEditPage={isEditPage} brandId={brandId} />
		</>
	);
}
