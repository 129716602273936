import React, { useState } from 'react';
import { Tabs } from 'antd';
import { RCTable } from 'components/common';
import { getInventoryProductList } from 'store/inventory/InventoryProductSlice';
import { getInventoryBatchList } from 'store/inventory/InventoryBatchSlice';
import { InventoryProductListColumns, InventoryBatchListColumns } from 'utils';
import { useQueryParams } from 'hooks';

const { TabPane } = Tabs;

export default function CurrentInventory() {
	const [rowData, setRowData] = useState({});
	const [rowDataBatch, setRowDataBatch] = useState({});
	const [paginateConfig, setpaginateConfig] = useQueryParams({});
	const [paginateConfigBatch, setpaginateConfigBatch] = useQueryParams({});

	return (
		<Tabs defaultActiveKey="product">
			<TabPane tab="Product" key="product">
				<RCTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'id',
					}}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					dispatchAction={getInventoryProductList}
					columns={InventoryProductListColumns}
				/>
			</TabPane>
			<TabPane tab="Batch" key="batch">
				<RCTable
					rowKey="id"
					tableDatas={[rowDataBatch, setRowDataBatch]}
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'id',
					}}
					togglePaginate={[paginateConfigBatch, setpaginateConfigBatch]}
					dispatchAction={getInventoryBatchList}
					columns={InventoryBatchListColumns}
				/>
			</TabPane>
		</Tabs>
	);
}
