import React from 'react';
import { useSelector } from 'react-redux';
import LandingPage from 'components/common/LandingPage';
import { dashboardData } from 'utils';

export default function Dashboard() {
	const { me = {} } = useSelector((x) => x?.auth);
	return (
		<LandingPage
			subCatagories={dashboardData.filter((x) => {
				if (
					me?.is_superuser ||
					['System Admin', 'Support Team'].includes(me?.groups?.[0]?.name)
				)
					return !x.excludedroute;
				return true;
			})}
		/>
	);
}
