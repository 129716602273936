/* eslint-disable no-unneeded-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

// #region Store configs starts here
const validValue = 'Please enter a valid Value';

export const DOCUMENT_CURRENCY_CHOICE = [
	{ value: 'INR', label: 'Rupee' },
	{ value: 'USD', label: 'US Dollars' },
];

export const GENDER_CHOICE = [
	{ value: 'M', label: 'Male' },
	{ value: 'F', label: 'Female' },
	{ value: 'O', label: 'Other' },
];

export const ACTIVE_INACTIVE_CHOICE = [
	{ value: true, label: 'Active', color: '#ff4444' },
	{ value: false, label: 'Inactive', color: '#00C851' },
];

export const BRAND_CHOICE = [
	{ value: 'CLGT', label: 'CLGT : Colgate' },
	{ value: 'KBC', label: 'KBC : Kimberly Clark' },
];

export const UOM_CHOICE = [
	{ value: 'CS', label: 'CS' },
	{ value: 'DOZ', label: 'DOZ ' },
	{ value: 'PC', label: 'PC' },
];

export const ACTIVE_INACTIVE_CHOICE_STRING = [
	{ value: 'true', label: 'Active', color: '#ff4444' },
	{ value: 'false', label: 'Inactive', color: '#00C851' },
];

export const YES_NO_CHOICE = [
	{ value: true, label: 'Yes', color: '#ff4444' },
	{ value: false, label: 'No', color: '#00C851' },
];

export const yesNo_Choice = [
	{ value: 'yes', label: 'Yes', color: '#ff4444' },
	{ value: 'no', label: 'No', color: '#00C851' },
];

export const LEGAL_STRUCTURE_CHOICE = [
	{ value: 'C', label: 'Company' },
	{ value: 'L', label: 'LLP' },
	{ value: 'I', label: 'Individual' },
];

export const GST_REG_TYPE_CHOICE = [
	{ value: 'R', label: 'Registered' },
	{ value: 'UR', label: 'UnRegistered' },
];

// form schema
export const StoreFormSchema = Yup.object({
	name: Yup.string()
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
		.label('*Store Name')
		.required()
		.min(3)
		.max(100)
		.trim(),
	nick_name: Yup.string()
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
		.max(100)
		.trim(),
	code: Yup.string()
		.label('Store Code')
		.matches(/^[a-z A-Z0-9_-]+$/i, 'Please enter valid code')
		.max(50)
		.required(),
	client_id: Yup.object().label('Client').required(),
	fc_ids: Yup.array().min(1).label('FC(s)').required(),
	base_address_id: Yup.object().label('Pincode').required(),
	retail_category_id: Yup.object().label('Retail Category').nullable().required(),
	address: Yup.string().min(3).max(1000).label('Address').required().trim(),
	area: Yup.string().max(100).trim(),
	contact_person_fname: Yup.string()
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
		.min(2)
		.max(50)
		.trim(),
	contact_person_mname: Yup.string()
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
		.min(2)
		.max(50)
		.trim(),
	contact_person_lname: Yup.string()
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
		.min(2)
		.max(50)
		.trim(),
	email: Yup.string()
		.label('Contact Person Email')
		.matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+.+[a-zA-Z0-9-]+$/, 'Please enter valid email'),
	mobile: Yup.string()
		.label('Contact Person Phone')
		.matches(/^[0-9]{10}$/i, 'Please enter a valid phone number')
		.required(),
	alternate_phone: Yup.string()
		.label('Alternate Phone Number')
		.matches(/^[0-9]{10}$/i, 'Please enter a valid phone number'),
	gender: Yup.string()
		.label('Gender')
		.when('contact_person_fname', {
			is: (val) => val != null,
			then: Yup.string().required(),
		}),
	latitude: Yup.number()
		.min(-90)
		.max(90)
		.typeError('Not a valid latitude')
		.test('longitude', 'Not a valid latitude', function latitude(value) {
			return (Number.isFinite(value) && Math.abs(value) <= 90) || isEmpty(value);
		})
		.required(),
	longitude: Yup.number()
		.min(-180)
		.max(180)
		.typeError('Not a valid longitude')
		.test('longitude', 'Not a valid longitude', function longitude(value) {
			return (Number.isFinite(value) && Math.abs(value) <= 180) || isEmpty(value);
		})
		.required(),
	gst_reg_type: Yup.string().label('GST Registration Type').required(),
	gstNumber: Yup.string()
		.label('GSTIN Number')
		.matches(
			/^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/,
			'Please enter a valid GST Number'
		)
		.when('gst_reg_type', {
			is: (val) => val === 'R',
			then: Yup.string().required(),
		})
		.min(15)
		.max(15),
	aadhaar: Yup.string()
		.label('Aadhar Number')
		.matches(/^[0-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/, 'Please enter a valid Aadhar Number')
		.min(12)
		.max(12),
	pan_number: Yup.string()
		.label('PAN Number')
		.matches(/^[A-Z a-z]{5}[0-9]{4}[A-Z a-z]{1}/, 'Please enter a valid PAN Number')
		.max(10),
	account_code: Yup.string().max(50).trim(''),
	account_number: Yup.string()
		.matches(/^\d{9,18}$/, 'Please enter a valid Account Number')
		.max(20)
		.trim(),
	ifsc_code: Yup.string()
		.label('IFSC Code')
		.when('account_number', {
			is: (val) => val != null,
			then: Yup.string().required(),
		})
		.matches(/^[A-Za-z]{4}[0-9]{7}$/, 'Please enter a valid IFSC Code')
		.max(12)
		.min(6)
		.trim(),
	bank_name: Yup.string()
		.label('Bank Name')
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
		.when('account_number', {
			is: (val) => val != null,
			then: Yup.string().required(),
		})
		.min(3)
		.max(50)
		.trim(),
	bank_city: Yup.string()
		.label('Bank City')
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
		.when('account_number', {
			is: (val) => val != null,
			then: Yup.string().required(),
		})
		.min(3)
		.max(50)
		.trim(),
	bank_branch: Yup.string()
		.label('Bank Branch')
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
		.when('account_number', {
			is: (val) => val != null,
			then: Yup.string().required(),
		})
		.min(2)
		.max(50)
		.trim(),
	legal_structure: Yup.string().trim(),
	is_active: Yup.string().label('Status').required(),
	tdc_number: Yup.string().min(2).max(15).trim(),
	tdc_tds_section: Yup.string()
		.label('TDS Selection')
		.when('tdc_number', {
			is: (val) => val != null || val === '',
			then: Yup.string().required('Please enter a valid TDS Selection'),
		})
		.max(15),
	tdc_start_date: Yup.date()
		.label('Low TDS Start Date')
		.default(() => new Date())
		.when('tdc_number', {
			is: (val) => val != null || val === '',
			then: Yup.date().required('Please select a valid Low TDS Start Date'),
		}), // date
	tdc_valid_till: Yup.date()
		.label('Low TDS Valid Till')
		.when(
			'tdc_start_date',
			(tdc_start_date, schema) => tdc_start_date && schema.min(tdc_start_date)
		)
		.when('tdc_number', {
			is: (val) => val != null || val === '',
			then: Yup.date().required(validValue),
		}), // date
	tdc_tds_rate: Yup.number()
		.label('TDS Rate')
		.when('tdc_number', {
			is: (val) => val != null || val === '',
			then: Yup.number().required(validValue),
		}),
	tdc_amount_limit: Yup.number()
		.label('Amount Limit')
		.when('tdc_number', {
			is: (val) => val != null || val === '',
			then: Yup.number().required(validValue),
		}),
	is_related_party: Yup.string(),
	is_holding_payment: Yup.string(),
	has_discount_early_payment: Yup.string(),
	is_default_tds_applicable: Yup.string(),
	has_vendor_agreement: Yup.string(),
	vendor_agreement_start_date: Yup.date()
		.label('Agreement Start Date')
		.default(() => new Date())
		.when('tdc_number', {
			is: (val) => val != null || val === '',
			then: Yup.date().required(validValue),
		}), // date
	vendor_agreement_end_date: Yup.date()
		.label('Agreement Valid Till')
		.when(
			'vendor_agreement_start_date',
			(vendor_agreement_start_date, schema) =>
				vendor_agreement_start_date && schema.min(vendor_agreement_start_date)
		)
		.when('tdc_number', {
			is: (val) => val != null || val === '',
			then: Yup.date().required(validValue),
		}), // date
	document_currency: Yup.string().label('Document Currency').required(),
	credit_period: Yup.number(),
	credit_amount: Yup.number(),
	payment_terms: Yup.string().trim(),
	credit_bill_limit: Yup.number().min(0),
	scheme_eligibility: Yup.string().label('Scheme Eligibility').required(),
	special_rate_eligibility: Yup.string().label('Special Rate Eligibility').required(),
	bad_paymaster: Yup.string().label('Bad Paymaster').required(),
	is_black_listed: Yup.string().label('Black Listed').required(),
	cancelled_cheque: Yup.string().nullable(),
	verified_by_delivery_boy: Yup.boolean(),
	delivery_boy_id: Yup.string().nullable(),
	delivery_boy_verified_at: Yup.string().nullable(),
	verified_by_telecaller: Yup.boolean(),
	telecaller_id: Yup.string().nullable(),
	telecaller_verified_at: Yup.string().nullable(),
});

export const StoreinitialValues = ({
	data = {},
	fcData = [],
	clientdata = [],
	categorydata = [],
	pincodedata = [],
}) => {
	const FCLIST = data?.FCs?.map((y) => y?.id);

	return {
		name: data?.name || undefined,
		nick_name: data?.nick_name || undefined,
		code: data?.code || undefined,
		aadhaar: data?.aadhaar || undefined,
		account_code: data?.account_code || undefined,
		account_number: data?.account_number || undefined,
		address: data?.address || undefined,
		mobile: data?.mobile || undefined,
		alternate_phone: data?.alternate_phone || undefined,
		area: data?.area || undefined,
		bank_branch: data?.bank_branch || undefined,
		bank_city: data?.bank_city || undefined,
		bank_name: data?.bank_name || undefined,
		base_address_id:
			pincodedata?.filter((x) => data?.base_address_id === x?.value)[0] || undefined,
		client_id: clientdata?.filter((x) => data?.client_id === x?.value)[0] || undefined,
		contact_person_fname: data?.contact_person_fname || undefined,
		contact_person_lname: data?.contact_person_lname || undefined,
		contact_person_mname: data?.contact_person_mname || undefined,
		email: data?.email || undefined,
		fc_ids: fcData?.filter((x) => FCLIST.includes(x?.value)) || undefined,
		gender: data?.gender || undefined,
		gstNumber: data?.gstNumber || undefined,
		gst_reg_type: data?.gst_reg_type || undefined,
		ifsc_code: data?.ifsc_code || undefined,
		is_active: data?.is_active || undefined,
		latitude: data?.latitude || undefined,
		legal_structure: data?.legal_structure || undefined,
		longitude: data?.longitude || undefined,
		pan_number: data?.pan_number || undefined,
		phone: data?.phone || undefined,
		retail_category_id:
			categorydata?.filter((x) => data?.retail_category_id === x?.value)[0] || undefined,
		credit_amount: data?.credit_amount || undefined,
		credit_bill_limit: data?.credit_bill_limit || undefined,
		credit_period: data?.credit_period || undefined,
		document_currency: data?.document_currency || undefined,
		has_discount_early_payment: data?.has_discount_early_payment || undefined,
		has_vendor_agreement: data?.has_vendor_agreement || undefined,
		is_black_listed: data?.is_black_listed || false,
		is_default_tds_applicable: data?.is_default_tds_applicable || undefined,
		is_holding_payment: data?.is_holding_payment || undefined,
		is_related_party: data?.is_related_party || undefined,
		payment_terms: data?.payment_terms || undefined,
		scheme_eligibility: data?.scheme_eligibility || false,
		special_rate_eligibility: data?.special_rate_eligibility || false,
		tdc_amount_limit: data?.tdc_amount_limit || undefined,
		tdc_number: data?.tdc_number || undefined,
		tdc_start_date: data?.tdc_start_date || undefined,
		tdc_tds_rate: data?.tdc_tds_rate || undefined,
		tdc_tds_section: data?.tdc_tds_section || undefined,
		tdc_valid_till: data?.tdc_valid_till || undefined,
		vendor_agreement_end_date: data?.vendor_agreement_end_date || undefined,
		vendor_agreement_start_date: data?.vendor_agreement_start_date || undefined,
		bad_paymaster: data?.bad_paymaster || undefined,
		cancelled_cheque: 'CC',
		verified_by_delivery_boy: data?.verified_by_delivery_boy || undefined,
		delivery_boy_id: data?.delivery_boy_id || undefined,
		delivery_boy_verified_at: data?.delivery_boy_verified_at || undefined,
		verified_by_telecaller: data?.verified_by_telecaller || undefined,
		telecaller_id: data?.telecaller_id || undefined,
		telecaller_verified_at: data?.telecaller_verified_at || undefined,
		telecaller_name: data?.Telecaller?.full_name || undefined,
		delivery_boy_name: data?.DeliveryBoy?.full_name || undefined,
		wms_callback_enabled: data?.wms_callback_enabled || false,
	};
};

export const StoreRefinedPayload = (data) => ({
	name: data?.name || '',
	nick_name: data?.nick_name || '',
	code: data?.code || '',
	aadhaar: data?.aadhaar || '',
	account_code: data?.account_code || '',
	account_number: data?.account_number || '',
	address: data?.address || '',
	mobile: data?.mobile || '',
	alternate_phone: data?.alternate_phone || '',
	area: data?.area || '',
	bank_branch: data?.bank_branch || '',
	bank_city: data?.bank_city || '',
	bank_name: data?.bank_name || '',
	base_address_id: data?.base_address_id || '',
	client_id: data?.client_id || '',
	contact_person_fname: data?.contact_person_fname || '',
	contact_person_lname: data?.contact_person_lname || '',
	contact_person_mname: data?.contact_person_mname || '',
	email: data?.email || '',
	fc_ids: data?.fc_ids || '',
	gender: data?.gender || '',
	gstNumber: data?.gstNumber || '',
	gst_reg_type: data?.gst_reg_type || '',
	ifsc_code: data?.ifsc_code || '',
	is_active: data?.is_black_listed ? data?.is_active : true,
	latitude: data?.latitude?.toString() || '',
	legal_structure: data?.legal_structure || '',
	longitude: data?.longitude?.toString() || '',
	pan_number: data?.pan_number || '',
	phone: data?.phone || '',
	retail_category_id: data?.retail_category_id || '',
	credit_amount: +(data?.credit_amount || 0),
	credit_bill_limit: +(data?.credit_bill_limit || 0),
	credit_period: +(data?.credit_period || 0),
	document_currency: data?.document_currency || '',
	has_discount_early_payment: data?.has_discount_early_payment || false,
	has_vendor_agreement: data?.has_vendor_agreement || false,
	is_black_listed: data?.is_black_listed || false,
	is_default_tds_applicable: data?.is_default_tds_applicable || false,
	is_holding_payment: data?.is_holding_payment || false,
	is_related_party: data?.is_related_party || false,
	payment_terms: data?.payment_terms || '',
	scheme_eligibility: data?.scheme_eligibility || false,
	special_rate_eligibility: data?.special_rate_eligibility || false,
	tdc_amount_limit: +(data?.tdc_amount_limit || 0),
	tdc_number: data?.tdc_number || '',
	tdc_tds_rate: +(data?.tdc_tds_rate || 0),
	tdc_tds_section: data?.tdc_tds_section || '',
	bad_paymaster: data?.bad_paymaster || '',
	cancelled_cheque: 'CC',
	verified_by_delivery_boy: data?.verified_by_delivery_boy || false,
	delivery_boy_id: data?.delivery_boy_id || null,
	delivery_boy_verified_at: data?.delivery_boy_verified_at || null,
	verified_by_telecaller: data?.verified_by_telecaller || false,
	telecaller_id: data?.telecaller_id || null,
	telecaller_verified_at: data?.telecaller_verified_at || null,
	...(data?.tdc_start_date ? { tdc_start_date: data?.tdc_start_date } : {}),
	...(data?.tdc_valid_till ? { tdc_valid_till: data?.tdc_valid_till } : {}),
	...(data?.vendor_agreement_end_date
		? { vendor_agreement_end_date: data?.vendor_agreement_end_date }
		: {}),
	...(data?.vendor_agreement_start_date
		? { vendor_agreement_start_date: data?.vendor_agreement_start_date }
		: {}),
});

// #endregion

// #region company form configs starts here

export const CompanyFormSchema = Yup.object({
	name: Yup.string()
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for Company Name ')
		.label('Company Name')
		.required()
		.min(3)
		.max(100)
		.trim(''),
	// short_name: Yup.string().label('Company Nick Name').required().min(2).max(20).trim(''),
	code: Yup.string()
		.matches(/^[aA-zZ0-9\s-_]+$/, 'Please enter valid code')
		.label('Company Code')
		.required()
		.min(2)
		.max(50)
		.trim(''),
	phone: Yup.string()
		.matches(/^[+][0-9]{12}$/i, 'Please enter valid Phone Number')
		.label('Phone')
		.required(),
	email: Yup.string()
		.label('Email')
		.matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+.+[a-zA-Z0-9-]+$/, 'Please enter valid email')
		.email('Please enter valid email')
		.required(),
	address: Yup.string().label('Address').required().min(5).max(255).trim(''),
	pincode_id: Yup.string().label('Pincode').required(),
	gst_number: Yup.string()
		.label('GST No.')
		.required()
		.matches(
			/^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/,
			'Please enter valid GST Number'
		)
		.min(15)
		.max(15),
	bank_id: Yup.string().label('Bank Name'),
	account_number: Yup.string()
		.label('Account Number')
		.when('bank_id', {
			is: (val) => val != null,
			then: Yup.string().required(),
		})
		.matches(/^\d{9,18}$/, 'Please enter a valid Account Number')
		.max(20),
	ifsc_code: Yup.string()
		.label('IFSC Code')
		.when('bank_id', {
			is: (val) => val != null,
			then: Yup.string().required(),
		})
		.matches(/^[A-Za-z]{4}[0-9]{7}$/, 'Please enter a valid IFSC Code')
		.max(12),
	client_prefix: Yup.string()
		.label('Client Prefix')
		.matches(/^[a-z A-Z0-9_-]+$/i, 'Please enter valid Client Prefix')
		.required(),
});

export const CompanyInitialValues = (data) => ({
	name: data?.name || undefined,
	// short_name: data?.short_name || undefined,
	code: data?.code || undefined,
	phone: data?.phone || undefined,
	email: data?.email || undefined,
	address: data?.address || undefined,
	pincode_id: data?.pincode_id || undefined,
	gst_number: data?.gst_number || undefined,
	bank_id: data?.bank_id || undefined,
	account_number: data?.account_number || undefined,
	ifsc_code: data?.ifsc_code || undefined,
	client_prefix: data?.client_prefix || undefined,
	client_sequence_end: data?.client_sequence_end || '',
	client_sequence_start: data?.client_sequence_start || '',
});

// #endregion

// #region client form Configs starts here

export const ClientFormSchema = Yup.object({
	name: Yup.string()
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for Client Name ')
		.label('Client Name')
		.min(3)
		.required()
		.max(255)
		.trim(''),
	company_id: Yup.number().label('Company').required(),
	supplier_prefix: Yup.string()
		.label('Supplier Prefix')
		.matches(/^[a-z A-Z0-9_-]+$/i, 'Please enter valid Supplier Prefix')
		.required(),
	brand_prefix: Yup.string()
		.label('Brand Prefix')
		.matches(/^[a-z A-Z0-9_-]+$/i, 'Please enter valid Brand Prefix')
		.required(),
	product_prefix: Yup.string()
		.label('Product Prefix')
		.matches(/^[a-z A-Z0-9_-]+$/i, 'Please enter valid Product Prefix')
		.required(),
	uom_prefix: Yup.string()
		.label('UOM Prefix')
		.matches(/^[a-z A-Z0-9_-]+$/i, 'Please enter valid UOM Prefix')
		.required(),
	fc_prefix: Yup.string()
		.label('FC Prefix')
		.matches(/^[a-z A-Z0-9_-]+$/i, 'Please enter valid FC Prefix')
		.required(),
	category_prefix: Yup.string()
		.label('Category Prefix')
		.matches(/^[a-z A-Z0-9_-]+$/i, 'Please enter valid Category Prefix')
		.required(),
	store_prefix: Yup.string()
		.label('Store Prefix')
		.matches(/^[a-z A-Z0-9_-]+$/i, 'Please enter valid Store Prefi')
		.required(),
	scheme_prefix: Yup.string()
		.label('Store Prefix')
		.matches(/^[a-z A-Z0-9_-]+$/i, 'Please enter valid Store Prefi')
		.required(),
});

export const ClientInitialValues = (data) => ({
	name: data?.name || '',
	// nick_name: data?.nick_name || '',
	company_id: data?.company_id || '',
	brand_prefix: data?.brand_prefix || '',
	supplier_prefix: data?.supplier_prefix || '',
	category_prefix: data?.category_prefix || '',
	product_prefix: data?.product_prefix || '',
	uom_prefix: data?.uom_prefix || '',
	fc_prefix: data?.fc_prefix || '',
	store_prefix: data?.store_prefix || '',
	scheme_prefix: data?.scheme_prefix || '',
	code: data?.code || '',
});

// #endregion

// region pack master form configs starts here
export const PackMasterFormSchema = Yup.object({
	brand_code: Yup.string().label('Brand').required(),
	product_name: Yup.string().label('Product Name').min(3).max(255).trim(''),
	product_code: Yup.string()
		.matches(/^[aA-zZ0-9\s-_]+$/, 'Please enter valid Product code')
		.label('Product Code')
		.required()
		.min(2)
		.max(255)
		.trim(''),
	uom: Yup.string()
		.label('UOM')
		.matches(/^[^\d]+$/i, 'UOM must not include numbers')
		.max(31)
		.required(),
	uom_description: Yup.string()
		.label('UOM Description')
		.max(255, 'Maximum 255 characters are allowed'),
	conversion_qty: Yup.number().min(1).max(100000).label('UOM Conversion Qty').required(),
});

export const PackMasterInitialValues = (data) => ({
	brand_code: data?.brand_code || undefined,
	product_name: data?.product_name || undefined,
	product_code: data?.product_code || undefined,
	uom: data?.uom || undefined,
	uom_description: data?.uom_description || undefined,
	conversion_qty: data?.conversion_qty || undefined,
	is_active: data && data.is_active == 0 ? false : true,
	id: data?.id || undefined,
});
// #endregion

// #region brands configs starts here

export const BrandsFormSchema = Yup.object({
	name: Yup.string()
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
		.required()
		.label('Brand Name')
		.min(3)
		.max(75)
		.trim(),
	code: Yup.string()
		.matches(/^[aA-zZ]+$/, 'Please enter valid code')
		.required()
		.label('Brand Code')
		.min(1)
		.max(6)
		.trim(),
	client_id: Yup.number().label('Client').required(),
});

export const BrandsInitialValues = (data) => ({
	name: data?.name || undefined,
	code: data?.code || undefined,
	client_id: data?.client_id || undefined,
});

// #endregion

// #region FC starts here

Yup.addMethod(Yup.array, 'unique', function outer(message, mapper = (a) => a) {
	return this.test('unique', message, function inner(list) {
		return list.length === new Set(list.map(mapper)).size;
	});
});

export const FCFormSchema = Yup.object({
	name: Yup.string()
		.required()
		.label('FC Name')
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
		.min(3)
		.max(50)
		.trim(''),
	code: Yup.string()
		.matches(/^[aA-zZ]+$/, 'Please enter valid code')
		.required()
		.label('FC Code')
		.min(4)
		.max(4)
		.trim(''),
	latitude: Yup.number()
		.min(-90)
		.max(90)
		.typeError('Not a valid latitude')
		.test('longitude', 'Not a valid latitude', function latitude(value) {
			return (Number.isFinite(value) && Math.abs(value) <= 90) || isEmpty(value);
		})
		.required(),
	longitude: Yup.number()
		.min(-180)
		.max(180)
		.typeError('Not a valid longitude')
		.test('longitude', 'Not a valid longitude', function longitude(value) {
			return (Number.isFinite(value) && Math.abs(value) <= 180) || isEmpty(value);
		})
		.required(),
	brands: Yup.array()
		.of(
			Yup.object().shape({
				id: Yup.string().required('Brand Name').label('Brand Name'),
				non_picking: Yup.boolean().required('Non Picking').label('Non Picking'),
				warehouse_status: Yup.boolean().label('WMS Integration Enabled'),
				wms_callback_enabled: Yup.boolean().nullable().label('WMS Callback Enabled'),
			})
		)
		.unique('Duplicate Brand', (a) => a.id)
		.required()
		.min(1),
	proximityRadius: Yup.number()
		.integer()
		.test(
			'Is positive?',
			'Proximity Radius must be greater than or equal to 0 ',
			(value) => value >= 0
		)
		.label('Proximity Radius')
		.required()
		.min(0)
		.max(2000),
	address: Yup.string().label('Address').required(),
	pincode_id: Yup.number().label('Pincode').required(),
	client_id: Yup.number().label('Client').required(),
	wms_warehouse_code: Yup.string().label('WMS Warehouse Code').max(64),
	gst_number: Yup.string()
		.label('GSTIN Number')
		.matches(
			/^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/,
			'Please enter a valid GST Number'
		)
		.min(15)
		.max(15)
		.required(),
});

export const FCInitialValues = (data, editmode = false) => {
	const filterBrands = data?.Brands?.map((x) => ({
		id: x?.FcBrand?.brand_id,
		non_picking: x?.FcBrand?.non_picking,
		block_rfc: x?.FcBrand?.block_rfc ? x?.FcBrand?.block_rfc : false,
		crate_tracking: x?.FcBrand?.crate_tracking,
		/**
		 * if warehouse_status is stockone_enabled then make the toggle button as enable,
		 * if the warehouse_status is stockone_disabled, null or false make the toggle as disabled state
		 */
		warehouse_status: x?.FcBrand?.warehouse_status === 'stockone_enabled',
		wms_callback_enabled: x?.FcBrand?.wms_callback_enabled,
		uniqueKey: Math.random(),
	}));
	return {
		name: data?.name || undefined,
		code: data?.code || undefined,
		latitude: data?.latitude || undefined,
		longitude: data?.longitude || undefined,
		proximityRadius: editmode ? data?.proximityRadius || 0 : undefined,
		address: data?.address || undefined,
		pincode_id: data?.pincode_id || undefined,
		gst_number: data?.gst_number || undefined,
		client_id: data?.client_id || undefined,
		wms_warehouse_code: data?.wms_warehouse_code || undefined,
		brands: filterBrands || [
			{
				id: undefined,
				non_picking: false,
				warehouse_status: false,
				wms_callback_enabled: false,
				block_rfc: false,
				uniqueKey: Math.random(),
				crate_tracking: false,
			},
		],
	};
};

// #endregion
