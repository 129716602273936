/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import { useDispatch } from 'react-redux';
import { isEmpty, uniqBy } from 'lodash';
import { Button, Flex, Box, AsyncSelect } from 'atoms';
import * as SVGICONS from 'utils/Svg';
import { ACL, RCTable, LabelWrapper, LoadAsyncSelectComponent } from 'components/common';
import {
	fcListColumns,
	UserPersmission,
	handleFilterChange,
	toAsysnOption,
	SelectFilterLabelOptions,
	initialPaginateConfigWithoutQ,
	searchBox,
	FCQueries,
} from 'utils';
import { getFcListOnLoad, getFcOnSearchList, getFcListForTable } from 'store/masters';
import { useQueryParams } from 'hooks';

export default function Fc() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [rowData, setRowData] = useState({});
	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);
	const [btnClickSearh, setBtnClickSearh] = useState({});

	const [paginateConfig, setpaginateConfig] = useQueryParams({
		filterQuery: FCQueries,
	});

	useEffect(() => {
		setFcList({ type: 'Loading' });

		dispatch(getFcListOnLoad({})).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});
	}, [dispatch]);

	const handleFCChangeSelect = (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value

		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		setpaginateConfig((prev) => ({
			...handleFilterChange(name, value?.value, prev),
			brands: [],
		}));
	};

	return (
		<>
			<Flex justifyContent="end">
				<Flex width="100%" flexWrap="wrap" alignItems="center" gap="4px">
					<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
						<LoadAsyncSelectComponent
							loadFCs={[paginateConfig?.fcIds]}
							setList={(val) => setBrandList(val)}
							options={fcsList}
						>
							<AsyncSelect
								style={searchBox}
								allowClear
								name="fcIds"
								showSearch
								fetchOptions={getFcOnSearchList}
								transformOptions={(val) => toAsysnOption(val)}
								defaultValue={paginateConfig?.fcIds}
								placeholder="Select FC(s)"
								onChange={(value, options) => {
									handleFCChangeSelect('fcIds', value, options);
								}}
								selectOptions={[...(fcsList.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>
					<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
						<Select
							name="fields"
							style={searchBox}
							allowClear
							showSearch
							label="brand_ids"
							mode="multiple"
							value={paginateConfig?.brand_ids}
							filterOption={SelectFilterLabelOptions}
							options={brandsList}
							onChange={(value) =>
								setpaginateConfig((prev) => ({
									...prev,
									brand_ids: value,
									...initialPaginateConfigWithoutQ,
								}))
							}
						/>
					</LabelWrapper>
					<Box mt=".75rem">
						<Button onClick={() => setBtnClickSearh(Math.random())}>Search</Button>
					</Box>
				</Flex>
				<ACL allowedGroups={UserPersmission.fc_create}>
					<Button
						height="35px"
						alignSelf="center"
						onClick={() => navigate('/onboarding/fc/add')}
						type="variant"
					>
						<Flex alignItems="center" justifyContent="center">
							<Box width={30}>
								<SVGICONS.PlusOutlinedIcons />
							</Box>
							<Box>Add FC</Box>
						</Flex>
					</Button>
				</ACL>
			</Flex>
			<Box mt={3}>
				<RCTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					dispatchAction={getFcListForTable}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					columns={fcListColumns({
						defaultSort: paginateConfig?.sort_direction || 'DESC',
						defaultsortField: paginateConfig?.sort_column || 'createdAt',
					})}
					configs={{ defaultSort: 'DESC', defaultsortField: 'createdAt' }}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
		</>
	);
}
