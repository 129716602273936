import React from 'react';
import { BankForm } from 'components/forms';

export default function AddBank() {
	return (
		<>
			<h1>Add Bank Details</h1>
			<BankForm />
		</>
	);
}
