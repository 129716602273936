import React from 'react';
import { Modal, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PlusCircleTwoTone, MinusCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Box, Text, Flex } from 'atoms';
import DownloadButton from 'pages/segregator/allocation/DownloadButton';

const StyledButton = styled(Button)`
	width: 120px;
	height: 40px;
	border-radius: 4px;
`;

const StyledText = styled(Text)`
	font-size: 14px;
	font-weight: 500;
`;
const StyledSpan = styled.span`
	font-size: 12px;
	font-weight: 500;
	margin-left: 40px;
`;

const StyledTable = styled(Table)`
	thead {
		.ant-table-cell {
			font-weight: 500;
			background-color: #f5f5f5;
		}
	}
	.ant-table-cell {
		font-size: 12px;
		font-weight: 400;
		color: #1f1f1f;
		border-bottom: 1px solid #f0f0f0;
	}
	.ant-table-tbody > tr > td {
		border-bottom: transparent;
	}
`;

function RemoveInvoiceListModal({
	isModalOpen,
	setIsModalOpen,
	rowData,
	salesperson_name,
	salesman_id,
	collection_date,
	brand,
}) {
	const navigate = useNavigate();
	const columns = [
		{
			title: 'Invoice No.',
			dataIndex: 'invoice_number',
			key: 'invoice_number',
			render: (_, record) => (
				<Box>
					<Text>{record?.invoice_number}</Text>
					<Text style={{ fontSize: '10px' }}>{record?.assigned_date}</Text>
				</Box>
			),
		},
		{
			title: 'Store Name',
			dataIndex: 'store_name',
			key: 'store_name',
		},
		{
			title: 'Invoice Value',
			dataIndex: 'invoice_value',
			key: 'invoice_value',
			render: (invoice_value) => <Text>{Number(invoice_value).toFixed(2)}</Text>,
		},
		{
			title: 'Outstanding',
			dataIndex: 'outstanding_amount',
			key: 'outstanding_amount',
			render: (outstanding_amount) => <Text>{Number(outstanding_amount).toFixed(2)}</Text>,
		},
	];

	return (
		<Modal
			open={isModalOpen}
			onCancel={() => setIsModalOpen(false)}
			visible={isModalOpen}
			footer={null}
			width="650px"
			closable={false}
		>
			<Box
				display="flex"
				alignItems="center"
				marginBottom="20px"
				justifyContent="space-between"
			>
				<StyledText>
					Allocated Invoice Print Preview for {salesperson_name}
					<StyledSpan>Invoice Count: {rowData?.data?.length}</StyledSpan>
				</StyledText>

				<StyledText>
					<CloseOutlined onClick={() => setIsModalOpen(false)} />
				</StyledText>
			</Box>
			<StyledTable
				dataSource={rowData?.data}
				columns={columns}
				pagination={false}
				scroll={{
					y: 400,
				}}
			/>

			<Flex justifyContent="space-between" marginTop="20px">
				<Box>
					<StyledButton
						variant="outlined"
						onClick={() => setIsModalOpen(false)}
						style={{ marginRight: '10px' }}
					>
						Remove Inv <MinusCircleOutlined />
					</StyledButton>

					<StyledButton
						variant="outlined"
						onClick={() => {
							navigate(`/allocation?brand=${brand}&salesman=${salesman_id}`);
						}}
					>
						Add Inv
						<PlusCircleTwoTone twoToneColor="#CA5010" style={{ marginLeft: '5px' }} />
					</StyledButton>
				</Box>
				<Box>
					<DownloadButton record={{ salesman_id, collection_date }} />
				</Box>
			</Flex>
		</Modal>
	);
}
export default RemoveInvoiceListModal;

RemoveInvoiceListModal.propTypes = {
	isModalOpen: PropTypes.bool,
	setIsModalOpen: PropTypes.func,
	rowData: PropTypes.func,
	salesperson_name: PropTypes.string,
	salesman_id: PropTypes.string,
	collection_date: PropTypes.string,
	brand: PropTypes.string,
};
