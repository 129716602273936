/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Image, message } from 'antd';
import { CheckCircleFilled, CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DateFormat from 'components/DateFormat';
import { ACL, PdfViewer } from 'components/common';
import {
	verifyGRNSignedInvoice,
	deleteGRNSignedInvoice,
	deleteSalesReturnSignedInvoice,
} from 'store/masters';
import { UserPersmission } from 'utils';
import { Button } from 'atoms';
import ConfirmationModal from './ConfirmationModal';

const CustomModal = styled(Modal)`
	width: 800px !important;
	color: #e6e6e6;
	.ant-modal-header {
		background: #434343;
		.ant-modal-title {
			color: #ffffff;
		}
		border-bottom: none;
	}
	.ant-modal-body {
		background: #4d4d4d;
		padding: 0px;
	}
	.ant-modal-content {
		.ant-modal-close {
			color: #9b9b9b;
		}
		h3 {
			color: #ffffff;
		}
	}
	.no-logs-found {
		font-size: 16px;
		text-align: center;
		padding: 30px 0;
	}
`;

const ImageViewer = styled(Image)`
	height: 350px;
	background: #444444;
	object-fit: contain;
`;

const ActionBar = styled.div`
	height: 72px;
	background-color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0px;
	position: sticky;
	bottom: 0;
`;

const VerifyButton = styled(Button)`
	width: 140px;
	height: 40px;
	border-radius: 4px;
	background-color: #ff4747;
	color: white;
	margin-left: 5px;
	background-color: #5b7f2b;
`;
const DeleteButton = styled(Button)`
	width: 140px;
	height: 40px;
	margin-right: 5px;
	border-radius: 4px;
	background-color: #ff4747;
	color: white;
`;

export default function PdfModal({
	visible,
	data,
	onClose,
	getUpdatedData,
	salesReturn,
	grnUpload,
}) {
	const dispatch = useDispatch();

	const [activeData, setActiveData] = useState(null);
	const [showPDF, setShowPDF] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [modalType, setModalType] = useState(null);
	const { auth } = useSelector((state) => state.auth);

	useEffect(() => {
		let activeRes;
		if (salesReturn) {
			activeRes = data?.credit_note_invoices.find((x) => x.is_active);
		} else {
			activeRes = data?.GrnSignedInvoices.find((x) => x.is_active === true);
		}
		setActiveData(activeRes);
		if (activeRes) {
			showPdfViewer(activeRes?.s3_file_link);
		}
	}, [data]);

	const handleOk = () => {
		onClose();
	};

	const handleCancel = () => {
		onClose();
	};

	const showPdfViewer = (res) => {
		const fileExtension = res.split('.').pop();
		if (fileExtension.toLowerCase() === 'pdf') {
			setShowPDF(true);
		} else {
			setShowPDF(false);
		}
	};

	function getFileName(res) {
		return res?.split('/').pop();
	}

	function bytesToSize(bytes) {
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes === 0) return 'n/a';
		const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
		if (i === 0) return `${bytes} ${sizes[i]})`;
		return `${(bytes / 1024 ** i).toFixed(0)} ${sizes[i]}`;
	}

	const handleVerification = () => {
		setLoading(true);
		dispatch(verifyGRNSignedInvoice(activeData?.id))
			.then(() => {
				message.success('GRN File Verified Successfully');
			})
			.catch((error) => console.log('err', error))
			.finally(() => {
				setShowModal(false);
				setLoading(false);
				setTimeout(() => onClose(), 0);
				getUpdatedData();
			});
	};

	const confirmDelete = () => {
		setLoading(true);
		if (salesReturn) {
			dispatch(deleteSalesReturnSignedInvoice({ file_id: activeData?.id }))
				.then(() => {
					message.success('Sales Return File Deleted Successfully');
				})
				.catch((error) => console.log('err', error))
				.finally(() => {
					setShowModal(false);
					setLoading(false);
					setTimeout(() => onClose(), 0);
					getUpdatedData();
				});
		} else {
			dispatch(deleteGRNSignedInvoice({ file_id: activeData?.id }))
				.then(() => {
					message.success('GRN File Deleted Successfully');
				})
				.catch((error) => console.log('err', error))
				.finally(() => {
					setShowModal(false);
					setLoading(false);
					setTimeout(() => onClose(), 0);
					getUpdatedData();
				});
		}
	};

	function checkDeleteValidity() {
		let showIcon = false;
		let diff;
		const allowedUser = auth?.user?.groups[0].name;
		if (['System Admin'].includes(allowedUser) && activeData?.created_at) {
			diff = 90 - moment().diff(moment(activeData?.created_at), 'days');
			if (diff >= 0) {
				showIcon = true;
			}
		} else if (
			['Billing Executive', 'Transport Manager', 'FC Manager'].includes(allowedUser) &&
			activeData?.created_at
		) {
			diff = 3 - moment().diff(moment(activeData?.created_at), 'days');
			if (diff >= 0) {
				showIcon = true;
			}
		}
		return showIcon;
	}

	return (
		<CustomModal
			title={
				<div>
					<span>Upload</span>
					<span style={{ marginLeft: 50 }}>
						{activeData?.file_name ? getFileName(activeData?.file_name) : '-'}
					</span>
				</div>
			}
			visible={visible}
			onOk={handleOk}
			onCancel={handleCancel}
			footer={null}
		>
			<>
				<Row gutter={[32, 32]} style={{ padding: '16px 24px' }}>
					<Col span={8}>
						<h3>File name </h3>
						<p style={{ color: '#02F0FF' }}>
							{activeData?.file_name ? getFileName(activeData?.file_name) : '-'}
						</p>
						<span>
							{activeData?.file_size ? bytesToSize(activeData?.file_size) : '-'}
						</span>
					</Col>
					<Col span={8}>
						<h3>Uploaded By:</h3>
						<p>
							{activeData?.uploaded_by_name || '-'}

							<br />
							<span style={{ color: '#9F9F9F' }}>
								{activeData?.updated_at ? (
									<DateFormat date={activeData?.updated_at} format="HMSA" />
								) : (
									'-'
								)}
							</span>
						</p>
					</Col>
					{!salesReturn && activeData?.is_verified && (
						<Col span={8}>
							<h2 style={{ color: '#7EDA02', font: '12px', fontWeight: '600' }}>
								<CheckCircleFilled style={{ marginRight: '5px' }} />
								Verified
							</h2>
							<p>
								{activeData?.verified_by_name || '-'}

								<br />
								<span style={{ color: '#9F9F9F' }}>
									{activeData?.verified_at ? (
										<DateFormat date={activeData?.verified_at} format="HMSA" />
									) : (
										'-'
									)}
								</span>
							</p>
						</Col>
					)}
				</Row>
				<Row>
					<Col
						span={24}
						style={{
							borderTop: '1px solid rgb(101, 101, 101)',
							padding: '6px 0 6px 0',
							margin: '6px 0 0 0',
						}}
					>
						{showPDF
							? activeData?.s3_file_link && (
									<PdfViewer fileUrl={activeData?.s3_file_link} />
							  )
							: activeData?.s3_file_link && (
									<ImageViewer
										width="100%"
										src={activeData?.s3_file_link}
										alt="Alter Image"
									/>
							  )}
					</Col>
				</Row>
			</>
			{grnUpload &&
				!activeData?.is_verified &&
				(auth?.user?.groups[0].name === 'System Admin' ? (
					<ActionBar>
						{checkDeleteValidity() && (
							<DeleteButton
								onClick={() => {
									setModalType('delete');
									setShowModal(true);
								}}
								loading={loading}
							>
								<DeleteOutlined /> Delete
							</DeleteButton>
						)}

						<ACL allowedGroups={UserPersmission.grn_verify} loading={loading}>
							<VerifyButton
								onClick={() => {
									setModalType('verify');
									setShowModal(true);
								}}
							>
								<CheckCircleOutlined /> Verify
							</VerifyButton>
						</ACL>
					</ActionBar>
				) : (
					checkDeleteValidity() && (
						<ActionBar>
							<DeleteButton
								onClick={() => {
									setModalType('delete');
									setShowModal(true);
								}}
								loading={loading}
							>
								<DeleteOutlined /> Delete
							</DeleteButton>
						</ActionBar>
					)
				))}
			{salesReturn && checkDeleteValidity() && (
				<ActionBar>
					<DeleteButton
						onClick={() => {
							setModalType('delete');
							setShowModal(true);
						}}
						loading={loading}
					>
						<DeleteOutlined /> Delete
					</DeleteButton>
				</ActionBar>
			)}
			<ConfirmationModal
				handleVerification={handleVerification}
				confirmDelete={confirmDelete}
				modalType={modalType}
				showModal={showModal}
				setShowModal={setShowModal}
				salesReturn={salesReturn}
			/>
		</CustomModal>
	);
}

PdfModal.propTypes = {
	visible: PropTypes.bool,
	data: PropTypes.object,
	onClose: PropTypes.func,
	getUpdatedData: PropTypes.func,
	salesReturn: PropTypes.string,
	grnUpload: PropTypes.bool,
};
