import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { getCollectionList } from 'store/invoiceSlice';
import { Tab, Date } from 'components/pages/segregator';
import { CashierAllStats, CashierMenList } from 'components/pages/cashier';
import { Line, SearchBox } from 'components';
import { Flex, Box } from 'atoms';

export default function CashierDashboard() {
	const dispatch = useDispatch();
	const [persons, setPersons] = useState({});
	const { date, tab, dateTrigger } = useSelector((state) => state.invoice);

	const handleSearchChange = useMemo(
		() =>
			debounce((event) => {
				dispatch(getCollectionList({ q: event.target.value })).then((res) => {
					setPersons({ ...res });
				});
			}, 500),
		[dispatch]
	);

	useEffect(() => {
		setPersons({});
		dispatch(getCollectionList({})).then((res) => {
			setTimeout(() => {
				setPersons({ ...res });
			}, 0);
		});
	}, [dispatch, date, tab, dateTrigger]);

	return (
		<div>
			<Flex justifyContent="space-between" alignItems="center">
				<Box>
					<SearchBox
						onSearch={handleSearchChange}
						placeholder="Search by Name / Phone Number"
					/>
					<Flex alignItems="center">
						<Tab />
						<Line height="25px" mr="1rem" />
						<Date />
					</Flex>
				</Box>
				<CashierAllStats />
			</Flex>
			<CashierMenList persons={persons?.data || []} tab={tab} />
		</div>
	);
}
