import { createSlice } from '@reduxjs/toolkit';
import { API } from 'api';

export const uploadInvoiceSlice = createSlice({
	name: 'rfcInvoiceUpload',
	initialState: {},
	reducers: {},
});

const URL = process.env.REACT_APP_OMS;

export const deleteUploadedInvoice = (id) => async () => {
	const CREDENTIALS = {
		url: `${URL}api/delete-signed-invoice`,
		method: 'post',
		data: {
			file_id: id,
		},
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const getUploadedInvoiceList = (id) => async () => {
	const CREDENTIALS = {
		url: `champ/order/${id}`,
		method: 'get',
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const getUploadedItemsNumber = (id) => async () => {
	const CREDENTIALS = {
		url: `/champ/order/allocation/${id}`,
		method: 'get',
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export default uploadInvoiceSlice.reducer;
