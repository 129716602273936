/* eslint-disable sonarjs/prefer-single-boolean-return */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Table, Tag } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteUploadedInvoice } from 'store/uploadInvoiceSlice';
import { Text, Flex, Box } from 'atoms';
import UploadInvoiceButton from 'components/common/UploadInvoiceButton';
import { ConfirmBeforDelete } from './ConfirmBeforeDelete';

const StyledTable = styled(Table)`
	.ant-table-thead
		> tr
		> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{width: 0px}
		
		tr {
			height:60px;
			th.ant-table-cell {
				font-size: 16px;
				font-weight: 400;
			}
		}
	}
	tbody {
		td:nth-child(1) {
			font-size: 14px;
			font-weight: 400;
			color: #0091fb;
		}

		.ant-table-cell {
			font-size: 14px;
			font-weight: 400;
		}
	}
`;

const StyledTag = styled(Tag)`
	background-color: #f4f4f4;
	color: #969696;
	font-size: 14px;
	font-weight: 600;
	border-color: transparent;
	font-style: italic;
	padding: 10px;
	margin-top: 15px;
`;

export function ShowUploadListModal({
	toggleState,
	showHeader,
	handleUploadModalOpen,
	daysLeft,
	totalData,
	setTotalData,
	buttonDisabled,
	buttonHide,
	isVerified,
	orderId,
	statusExist,
	setListUpdated,
}) {
	const [modalVisible, setModalVisible] = toggleState;
	const dispatch = useDispatch();
	const [uploadedFiles, setUploadedFiles] = useState();
	const [actualFileCount, setActualFileCount] = useState();

	const DateFormat = 'DD-MM-YYYY';
	const TIME_FORMAT = 'hh:mm a';

	function getLocalTime(time) {
		return moment(time).add(0, 'minutes').format(TIME_FORMAT);
	}

	function getLocalDate(date) {
		return moment(date).add(0, 'minutes').format(DateFormat);
	}

	const getElapsedTime = (rfcdate) => {
		const currentTime = moment();
		const deleteLogDaysLeft = currentTime.diff(rfcdate, 'days');
		if (deleteLogDaysLeft <= 30) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		if (totalData?.file_download_link.length) {
			const { deletedLogFiles, fileLog } = totalData.file_download_link.reduce(
				(acc, file) => {
					if (!file?.is_active && getElapsedTime(file?.deleted_timestamp)) {
						acc.deletedLogFiles.push(file);
					} else if (file?.is_active) {
						acc.fileLog.push(file);
					}
					return acc;
				},
				{ deletedLogFiles: [], fileLog: [] }
			);

			setUploadedFiles([...fileLog, ...deletedLogFiles]);
		}
	}, [totalData?.file_download_link]);

	useEffect(() => {
		if (uploadedFiles?.length) {
			const realFiles = uploadedFiles.filter((file) => file.is_active);
			setActualFileCount(realFiles);
		}
	}, [totalData?.file_download_link, uploadedFiles]);

	const columns = [
		{
			title: 'File Name',
			key: 'file_name',
			dataIndex: 'file_name',
			render: (file_name, record) => (
				<Box>
					<a
						href={record?.is_active && record.file_link}
						target="_blank"
						style={{ color: record?.is_active ? '#1890ff' : '#969696' }}
					>
						{file_name}
					</a>
					{!record?.is_active && (
						<Box>
							<StyledTag color="default">
								{`${record?.deleted_by_name} deleted this file on ${getLocalDate(
									record?.deleted_timestamp
								)} | ${getLocalTime(record?.deleted_timestamp)}`}
							</StyledTag>
						</Box>
					)}
				</Box>
			),
		},
		{
			title: 'Format',
			key: 'file_type',
			dataIndex: 'file_type',
			align: 'center',
			render: (file_type, record) => (
				<Box style={{ color: record?.is_active ? '#000' : '#969696' }}>{file_type}</Box>
			),
		},
		{
			title: 'Date & Time',
			key: 'created_at',
			dataIndex: 'created_at',
			render: (created_at, record) => (
				<Box>
					<Text
						overFlow="hidden"
						style={{ color: record?.is_active ? '#000' : '#969696' }}
					>
						{`${getLocalDate(created_at)} | ${getLocalTime(created_at)}`}
					</Text>
				</Box>
			),
		},
		{
			title: 'Size',
			key: 'file_size',
			dataIndex: 'file_size',
			render: (size, record) => (
				<span style={{ color: record?.is_active ? '#000' : '#969696' }}>
					{(size / 1024 ** 2).toFixed(2)}mb
				</span>
			),
		},
		...(showHeader && (isVerified ? daysLeft : true)
			? [
					{
						title: 'Uploaded By',
						key: 'uploade_by',
						dataIndex: 'uploaded_by',
						render: (uploade_by, record) => (
							<Box style={{ color: record?.is_active ? '#000' : '#969696' }}>
								{uploade_by}
							</Box>
						),
					},
					{
						title: 'Action',
						key: 'action',
						dataIndex: 'action',
						align: 'center',
						render: (_, record) => (
							<ConfirmBeforDelete
								handleDelete={handleDelete}
								handleUploadModalOpen={handleUploadModalOpen}
								record={record}
								statusExist={statusExist}
								actualFileCount={actualFileCount}
							/>
						),
					},
			  ]
			: []),
	];

	const handleDelete = (key) => {
		const newData = {
			...totalData,
			file_download_link: totalData.file_download_link.filter((item) => item.id !== key),
			total_files_uploaded: totalData.total_files_uploaded - 1,
		};
		dispatch(deleteUploadedInvoice(key)).then(() => {
			setTotalData(newData);
			setListUpdated(true);
		});
	};

	return (
		<Modal
			destroyOnClose
			visible={modalVisible}
			onOk={() => setModalVisible(false)}
			onCancel={() => setModalVisible(false)}
			footer={null}
			width="1162px"
			maskClosable={false}
		>
			<Flex mb="40px" alignItems="center">
				<Text fontSize="20px" fontWeight="400" mr="20px">
					Uploaded Files
				</Text>
				{showHeader && !buttonHide && (
					<>
						<UploadInvoiceButton
							handleUploadModalOpen={handleUploadModalOpen}
							disabled={buttonDisabled}
						/>
						{daysLeft ? (
							<Text
								fontSize="12px"
								fontWeight="400"
								color="#6A6A6A"
								fontStyle="italic"
								ml="20px"
							>
								{`${daysLeft} days left to upload Document`}
							</Text>
						) : null}
					</>
				)}
			</Flex>
			<StyledTable columns={columns} dataSource={uploadedFiles} pagination={false} />
			{orderId && (
				<Text textAlign="right" mt="10px" fontSize="18px">
					To upload more files visit{' '}
					<Link
						to={`/logistics-management/return-to-fc-new/${orderId}/show`}
						style={{ textDecoration: 'underline' }}
					>
						{` Return to FC `}
					</Link>
					of this invoice
				</Text>
			)}
		</Modal>
	);
}

ShowUploadListModal.propTypes = {
	toggleState: PropTypes.array,
	showHeader: PropTypes.bool,
	handleUploadModalOpen: PropTypes.bool,
	daysLeft: PropTypes.string,
	totalData: PropTypes.object,
	setTotalData: PropTypes.func || undefined,
	buttonHide: PropTypes.bool,
	buttonDisabled: PropTypes.bool,
	isVerified: PropTypes.bool,
	orderId: PropTypes.number,
	statusExist: PropTypes.object,
	setListUpdated: PropTypes.func,
};
