import css from '@styled-system/css';
import React from 'react';
import styled from 'styled-components';
import { border, color, fontSize, layout, space, typography, variant } from 'styled-system';
import PropTypes from 'prop-types';
import { Text, Box } from 'atoms';

const SCALE = 'scale(1.01)';
const SECONDARY = 'secondary.0';

const variants = {
	primary: {
		color: 'white',
		backgroundColor: 'primary',
		transition: 'all 0.3s',
		border: 0,
		'&:hover': {
			transform: SCALE,
		},
		'&:focus': {
			outline: 0,
		},
		'&:disabled': {
			backgroundColor: 'white.300',
			borderColor: 'white.300',
			color: 'white.0',
		},
	},
	secondary: {
		color: 'primary',
		backgroundColor: 'white',
		border: '1px solid',
		borderColor: 'primary',
		transition: 'all 0.3s',
		'&:hover': {
			transform: SCALE,
		},
		'&:active': {
			backgroundColor: 'secondary.400',
			borderColor: 'secondary.400',
		},
		'&:disabled': {
			backgroundColor: 'white.300',
			borderColor: 'white.300',
			color: 'white.0',
		},
	},
	tertiary: {
		color: 'white.0',
		backgroundColor: 'transparent',
		border: '1px solid',
		borderColor: 'primary.500',
		transition: 'all 0.3s',
		'&:hover': {
			transform: SCALE,
			color: 'black.0',
			backgroundColor: 'white.0',
		},
	},
	danger: {
		color: '#FF4747',
		transition: 'all 0.3s',
		backgroundColor: 'red.900',
		border: '1px solid #FF4747',
		borderColor: 'red.900',
		'&:hover': {
			transform: SCALE,
		},
	},
	info: {
		color: '#000',
		transition: 'all 0.3s',
		backgroundColor: 'red.900',
		border: '1px solid #16697A',
		borderColor: 'red.900',
		'&:hover': {
			transform: SCALE,
		},
	},
	accept: {
		color: '#fff',
		transition: 'all 0.3s',
		backgroundColor: '#319901',
		border: '1px solid #319901',
		'&:hover': {
			transform: SCALE,
		},
	},
	reject: {
		color: '#fff',
		transition: 'all 0.3s',
		backgroundColor: '#B11500',
		border: '1px solid #B11500',
		'&:hover': {
			transform: SCALE,
		},
	},
	transparent: {
		color: SECONDARY,
		transition: 'all 0.3s',
		border: 0,
		backgroundColor: 'transparent',
		'&:disabled': {
			borderColor: 'white.300',
			color: 'white.300',
		},
	},
	outlined: {
		color: '#CA5010',
		transition: 'all 0.3s',
		border: '1px solid #CA5010',
		backgroundColor: 'transparent !important',
		'&:disabled': {
			borderColor: 'white.300',
			color: 'white.300',
		},
	},
	disabled: {
		color: 'white.0',
		backgroundColor: SECONDARY,
		border: '1px solid',
		borderColor: SECONDARY,
		transition: 'all 0.3s',
		h3: {
			textTransform: 'uppercase',
		},
		'&:hover': {
			transform: SCALE,
			color: 'white.0',
			backgroundColor: SECONDARY,
		},
	},
};

export const Container = styled(Box)`
	cursor: pointer;
	position: relative;
	white-space: nowrap;
	min-width: 125px;
	min-height: 35px;
	margin: 0;
	margin-left: 5px;
	padding: 6px 16px;
	text-transform: capitalize;
	font-size: 14px;
	${variant({
		variants,
	})}
	${({ disabled }) =>
		disabled &&
		css({
			backgroundColor: 'gray.200',
			boxShadow: 'none',
			cursor: 'not-allowed',
		})}
  ${({ whiteSpace }) =>
		whiteSpace &&
		css({
			whiteSpace,
		})}
  ${layout}
  ${color}
  ${typography}
  ${space}
  ${fontSize}
  ${border}
	background-color: ${(props) => props?.theme?.colors.secondary};
	&:disabled {
		opacity: 0.2;
	}
`;

export function Button({
	disabled,
	loading,
	children,
	variant: v = 'primary',
	fontFamily,
	...rest
}) {
	return (
		<Container
			variant={v}
			as="button"
			{...rest}
			disabled={disabled || loading}
			overflow="hidden"
		>
			<Text opacity={loading ? 0 : 1} fontFamily={fontFamily} id={rest.id}>
				{children}
			</Text>
		</Container>
	);
}

Button.propTypes = {
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	variant: PropTypes.string,
	fontFamily: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
