import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

export function WmsFailedLogs({ toggleState, rowData }) {
	const [modalVisible, setModalVisible] = toggleState;
	return (
		<Modal
			destroyOnClose
			centered
			title="Additional Information"
			visible={modalVisible}
			width="60%"
			maskClosable={false}
			onOk={() => setModalVisible(false)}
			onCancel={() => setModalVisible(false)}
		>
			{rowData}
		</Modal>
	);
}

WmsFailedLogs.propTypes = {
	toggleState: PropTypes.array,
	rowData: PropTypes.string,
};
