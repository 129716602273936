import { Modal, Table } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'atoms';

export function CollectionStatusModal({ toggleModal = [], tableProps = {} }) {
	const [modalVisible, setModalVisible] = toggleModal;
	return (
		<Modal
			centered
			destroyOnClose
			onCancel={() =>
				setModalVisible({
					orderStatus: null,
					visible: false,
				})
			}
			footer={null}
			width="76%"
			visible={modalVisible.visible}
			className="nextDayModalPopup"
			style={{ marginTop: '3rem' }}
		>
			<Box padding="20px 20px 15px">
				<Box fontWeight="600" fontSize="26px" lineHeight="35px" marginBottom="1rem">
					{tableProps.title}
				</Box>
				{tableProps.subtitle}
				<Box>
					<Table
						rowKey={tableProps.key}
						loading={tableProps?.loading}
						columns={tableProps?.columns}
						dataSource={tableProps?.dataSource}
						scroll={{
							y: 400,
						}}
					/>
				</Box>
			</Box>
		</Modal>
	);
}

CollectionStatusModal.propTypes = {
	toggleModal: PropTypes.array,
	tableProps: PropTypes.object,
};
