import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Row, Col, message } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormInput, AsyncSelect } from 'atoms';
import { toAsysnOption } from 'utils';
import { getBrandOnSearchList, getFcOnSearchList } from 'store/masters';
import { getCurrentUnSellableReport } from 'store/downloads/reportSlice';
import { BtnWrap, StyledSubmitButton } from '../masters/helper';
import { getOptions } from './helpers';

const initialValues = {
	fcs: undefined,
	brands: undefined,
};

export default function CurrentUnsellableFilters({ setShowModal }) {
	const dispatch = useDispatch();
	const [fcList, setFcList] = useState([]);
	const [brandList, setBrandList] = useState([]);

	useEffect(() => {
		dispatch(getFcOnSearchList({})).then((res) => setFcList(toAsysnOption(res)));
		dispatch(getBrandOnSearchList({})).then((res) => setBrandList(toAsysnOption(res)));
	}, [dispatch]);

	// on Select Event
	const handleSelect = (name, value, setFieldValue) => setFieldValue(name, value);

	const handleSubmit = (values, { resetForm, setSubmitting }) => {
		const data = {
			filter: {
				fcs: getOptions(values?.fcs),
				brands: getOptions(values?.brands),
			},
		};

		// delete key if length is 0
		if (data.filter?.brands?.length === 0) {
			delete data.filter?.brands;
		}

		if (data.filter?.fcs?.length === 0) {
			delete data.filter?.fcs;
		}

		const msgKey = Math.random().toString();
		const config = {
			key: msgKey,
			type: 'loading',
			content: 'Downloading Reports Current Unsaleable Inventory',
			duration: 0,
			className: 'customDownloadMsg',
		};
		message.open(config);

		dispatch(getCurrentUnSellableReport(data))
			.then(() => {
				resetForm();
				setShowModal(false);
			})
			.finally(() => {
				setSubmitting(false);
				message.destroy(msgKey);
			});
	};
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			enableReinitialize
			initialTouched={{
				fcs: true,
				brands: true,
			}}
		>
			{({ setFieldValue, values }) => (
				<Form layout="vertical">
					<Row gutter={24}>
						<Col span={8}>
							<FormInput name="fcs" label="FC(s)">
								<AsyncSelect
									className="form__field"
									mode="multiple"
									showSearch
									name="fcs"
									value={values?.fcs}
									onChange={(value) => handleSelect('fcs', value, setFieldValue)}
									placeholder="Fc Type"
									filterOption={(a, option) =>
										option.children.toLowerCase().indexOf(a.toLowerCase()) >= 0
									}
									allowClear
									selectOptions={fcList}
									fetchOptions={getFcOnSearchList}
								/>
							</FormInput>
						</Col>

						<Col span={8}>
							<FormInput name="brands" label="Brand(s)">
								<AsyncSelect
									mode="multiple"
									showSearch
									className="form__field"
									name="brands"
									value={values?.brands}
									onChange={(value) =>
										handleSelect('brands', value, setFieldValue)
									}
									placeholder="Brand Type"
									filterOption={(input, option) =>
										option.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									allowClear
									fetchOptions={getBrandOnSearchList}
									selectOptions={brandList}
								/>
							</FormInput>
						</Col>
					</Row>

					<BtnWrap>
						<StyledSubmitButton>Download </StyledSubmitButton>
					</BtnWrap>
				</Form>
			)}
		</Formik>
	);
}

CurrentUnsellableFilters.propTypes = {
	setShowModal: PropTypes.func,
};
