import React, { useState } from 'react';
import { Modal } from 'antd';
import styledComponents from 'styled-components';
import { useDispatch } from 'react-redux';
import { changePassword } from 'store/authSlice';
import { UserProfileForm, ChangePasswordForm } from 'components/forms';
import { Box, Button } from 'atoms';

const DetailsWrap = styledComponents(Box)`
  padding: 1rem;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 2px 1px -1px rgb(145 158 171 / 20%), 0px 1px 1px 0px rgb(145 158 171 / 14%), 0px 1px 3px 0px rgb(145 158 171 / 12%);
`;

export default function UserProfile() {
	const dispatch = useDispatch();
	const [modalVisible, setmodalVisible] = useState(false);

	const handleSubmit = (values, { setErrors, resetForm, setSubmitting }) => {
		dispatch(changePassword(values, setErrors))
			.then(() => {
				setSubmitting(false);
				setmodalVisible(false);
				resetForm();
			})
			.catch(() => {
				setSubmitting(false);
			});
	};

	return (
		<>
			<DetailsWrap>
				<Box position="absolute" right="15px" zIndex="5">
					<Button onClick={() => setmodalVisible(true)}>Reset Password</Button>
				</Box>
				<UserProfileForm />
			</DetailsWrap>
			{modalVisible && (
				<Modal
					title="Reset Password"
					centered
					onCancel={() => setmodalVisible(false)}
					destroyOnClose
					footer={false}
					width="60%"
					visible={modalVisible}
				>
					<Box mt={3}>
						<ChangePasswordForm handleSubmit={handleSubmit} />
					</Box>
				</Modal>
			)}
		</>
	);
}
