/* eslint-disable no-restricted-globals */
import { React, useState } from 'react';
import { Col, Row, message as toaster, Modal } from 'antd';
import styled from 'styled-components';
import { Field, Formik } from 'formik';
import { DatePicker, Form } from 'formik-antd';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Box, Flex, Text } from 'atoms';
import { getInvoiceMappings, createChequeBounceEntry } from 'store/financeTeamSlice';
import { chequeForminitialValues, ChequeBounceFormSchema } from 'components/forms/masters/helper';

export const BoxWrapper = styled.div`
	background: #fff;
	padding: 43px;
`;

export const Heading = styled.div`
	margin-bottom: 70px;
	display: flex;
`;
export const Input = styled(Field)`
	height: 40px;
	width: 300px;
	background: #faf9f8;
	border: 1px solid #edebe9;
	border-radius: 2px;
	margin-top: 7px;
	padding: 8px 16px 8px 16px;
	margin-right: 24px;
`;

export const TextHeading = styled.p`
	font-weight: 600;
	font-size: 14px;
`;
export const HeadingText = styled.p`
	font-weight: 400;
	font-size: 24px;
	margin-top: 27px;
`;

const InvoiceHeading = styled.h1`
	padding: 12px 0;
	font-size: 16px;
	font-weight: 600;
	border-bottom: 1px solid #efefef;
`;

const InvoiceTable = styled.table`
	min-width: 800px;
`;

const TableTR = styled.tr`
	border-bottom: 1px solid #efefef;
`;

const TableTH = styled.th`
	padding: 20px 20px;
	font-weight: 600;
	text-align: left;
`;

const TableTD = styled.td`
	padding: 10px 20px;
	text-align: left;
`;

const SelectCheckbox = styled.input.attrs({ type: 'checkbox' })`
	width: 16px;
	height: 16px;
	background: #fff;
	padding: 0;
`;

const BtnClear = styled.button`
	background: #e25e5e;
	border-radius: 4px;
	padding: 15px 30px 15px 30px;
	color: #ffff;
	border: 1px solid #e25e5e;
	margin-right: 16px;
	font-size: 13px;
	font-weight: 600;
	cursor: pointer;
`;
const BtnSubmit = styled.button`
	background: #fff;
	border-radius: 4px;
	padding: 15px 30px 15px 30px;
	color: #ca5010;
	border: 1px solid #ca5010;
	margin-right: 16px;
	font-size: 13px;
	font-weight: 600;
	&:disabled {
		opacity: 0.5;
	}
	cursor: pointer;
`;
const BtnSubmitAdd = styled.button`
	background: #ca5010;
	border-radius: 4px;
	padding: 15px 30px 15px 30px;
	color: #ffff;
	border: 1px solid #ca5010;
	margin-right: 16px;
	font-size: 13px;
	font-weight: 600;
	&:disabled {
		opacity: 0.5;
	}
	cursor: pointer;
`;

const ButtonGroup = styled(Flex)`
	justify-content: end;
	margin-top: 200px;
`;
const ButtonContainer = styled(Flex)`
	justify-content: center;
	margin-top: 2rem;
`;

export function BouncedChequeForm() {
	const navigate = useNavigate();
	const [fetchedInvoices, setFetchedInvoices] = useState();
	const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
	const [filteredData, setFilteredData] = useState(null);
	const [showPopup, setShowPopup] = useState(null);
	const [boun_date, setDate] = useState(undefined);

	console.log(boun_date);

	const handleOk = () => {
		createChequeBounceEntry(fetchedInvoices[selectedInvoiceId], filteredData, boun_date).then(
			() => {
				if (showPopup === 'no-close') clearPage();
				toaster.success('Successfully recorded Cheque Bounce.');
				if (showPopup === 'close') {
					navigate('/cheque-bounce/entry-list');
					clearPage();
				}
			}
		);
		handleCancel();
	};

	const submitInvoice = (data, closeAfter) => {
		console.log(data?.bounce_date);
		if (data.store_amount.toString() !== filteredData.cheque_value) {
			setShowPopup(closeAfter ? 'close' : 'no-close');
		} else {
			createChequeBounceEntry(data, filteredData, boun_date).then(() => {
				if (!closeAfter) {
					clearPage();
				}
				toaster.success('Successfully recorded Cheque Bounce.');
				if (closeAfter) {
					navigate('/cheque-bounce/entry-list');
					setFetchedInvoices(null);
				}
			});
		}
	};

	const handleCancel = () => {
		setShowPopup(null);
	};

	const clearPage = () => {
		location.reload();
	};

	const handleSubmit = (data, { setSubmitting }) => {
		console.log(data);
		const newFilteredData = { ...data };
		getInvoiceMappings(data)
			.then((r) => {
				console.log(r);
				setFetchedInvoices(r);
				setSelectedInvoiceId(null);
				setFilteredData(newFilteredData);
			})
			.finally(() => {
				setSubmitting(false);
			});
	};

	const handleRowSelect = (idx) => {
		if (idx === selectedInvoiceId) {
			setSelectedInvoiceId(null);
		} else {
			setSelectedInvoiceId(idx);
		}
	};

	const BoxCB = styled(Button)`
		margin: 30px;
	`;
	const BoxDate = styled(Box)`
		margin-top: 20px;
	`;

	const dateStyle = {
		fontWeight: '600',
		fontSize: '14px',
	};

	function getBoundDateValid(data) {
		return !data;
	}

	function setDateFun(val) {
		console.log(val);
		const bounce_dates = moment(val).format('YYYY-MM-DD');
		console.log(bounce_dates);
		setDate(bounce_dates);
	}

	return (
		<Box>
			<BoxWrapper>
				<Link to="/cheque-bounce/entry-list">
					<TextHeading>Back</TextHeading>
				</Link>
				<Heading>
					<HeadingText>Add Cheque Bounce</HeadingText>
				</Heading>
				<Formik
					initialValues={chequeForminitialValues}
					validationSchema={ChequeBounceFormSchema}
					onSubmit={handleSubmit}
				>
					{({ values, isValid, isSubmitting, dirty }) => (
						<Form layout="vertical">
							{console.log(!isValid, isSubmitting, dirty)}
							<Row>
								<Col>
									<TextHeading>*Cheque Number</TextHeading>
									<Input
										name="cheque_number"
										placeholder="last 6 digits"
										pattern="[0-9]*"
										inputMode="numeric"
										maxLength={6}
										onKeyPress={(e) => {
											if (!e.nativeEvent.key?.match(/[0-9]+/)) {
												e.nativeEvent.preventDefault();
											}
										}}
									/>
								</Col>
								<Col>
									<TextHeading>Cheque Value</TextHeading>
									<Input name="cheque_value" placeholder="cheque value" />
								</Col>
								<Col>
									<BoxCB
										type="submit"
										disabled={!isValid || isSubmitting || !dirty}
										mt={40}
									>
										Invoice Mapping
									</BoxCB>
								</Col>
							</Row>

							{fetchedInvoices && (
								<Box mt="15px">
									<InvoiceHeading>Fetched Details</InvoiceHeading>
									<InvoiceTable>
										<thead>
											<TableTR>
												<TableTH></TableTH>
												<TableTH>FC</TableTH>
												<TableTH>Brand</TableTH>
												<TableTH>Ratailer Name</TableTH>
												<TableTH>Invoice Number</TableTH>
												<TableTH>Invoice Date</TableTH>
												<TableTH>Amount</TableTH>
											</TableTR>
										</thead>
										<tbody>
											{fetchedInvoices?.map((i, idx) => (
												<TableTR key={i.invoice_no}>
													<TableTD>
														<SelectCheckbox
															checked={selectedInvoiceId === idx}
															onChange={() => handleRowSelect(idx)}
														/>
													</TableTD>
													<TableTD>{i.fc_name || '-'}</TableTD>
													<TableTD>{i.brand_name}</TableTD>
													<TableTD>{i.retailer_name}</TableTD>
													<TableTD>
														{i.invoice_details?.map((ins) => (
															<Text key={ins.invoice_no}>
																{ins.invoice_no}
															</Text>
														))}
													</TableTD>
													<TableTD>
														{i.invoice_details?.map((ins) => (
															<Text key={ins.invoice_no}>
																{ins.invoice_date}
															</Text>
														))}
													</TableTD>

													<TableTD>{i.store_amount || '-'}</TableTD>
												</TableTR>
											))}
										</tbody>
									</InvoiceTable>
									<BoxDate>
										<span style={dateStyle}>Bounce Date * </span>
										<DatePicker
											name="bounce_date"
											format="DD-MM-YYYY"
											disabledDate={(current) =>
												current.isAfter(moment().subtract(1, 'day'))
											}
											onChange={(eve) => {
												setDateFun(eve);
											}}
										/>
									</BoxDate>
									<ButtonGroup>
										<BtnClear type="button" onClick={clearPage}>
											Clear Details
										</BtnClear>

										<BtnSubmit
											type="button"
											disabled={
												selectedInvoiceId === null ||
												getBoundDateValid(values?.bounce_date)
											}
											onClick={() =>
												submitInvoice(
													fetchedInvoices[selectedInvoiceId],
													true
												)
											}
										>
											Submit and Close
										</BtnSubmit>
										<BtnSubmitAdd
											type="button"
											disabled={
												selectedInvoiceId === null ||
												getBoundDateValid(values?.bounce_date)
											}
											onClick={() =>
												submitInvoice(
													fetchedInvoices[selectedInvoiceId],
													false
												)
											}
										>
											Submit and Add more
										</BtnSubmitAdd>
									</ButtonGroup>
								</Box>
							)}
						</Form>
					)}
				</Formik>
			</BoxWrapper>
			<Modal
				centered
				visible={showPopup !== null}
				footer={null}
				onCancel={handleCancel}
				maskClosable={false}
			>
				<Box mt="3" textAlign="center">
					<Text id="logout-text">
						Selected Amount and the entered Cheque value does not match. Do you want to
						continue?
					</Text>
				</Box>
				<ButtonContainer>
					<Button
						color="#fff"
						border={0}
						variant="secondary"
						onClick={handleCancel}
						id="logout-cancel"
					>
						No
					</Button>
					<Button variant="primary" onClick={() => handleOk()} ml="2rem" id="logout-ok">
						Yes
					</Button>
				</ButtonContainer>
			</Modal>
		</Box>
	);
}
