import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Text, Box } from 'atoms';
import DateFormat from 'components/DateFormat';
import { ACL } from 'components/common';
import { UserPersmission } from 'utils/userPermission';

export const fcListColumns = ({ defaultSort, defaultsortField }) => {
	const sortDirection = defaultSort === 'ASC' ? 'ascend' : 'descend';

	return [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			sortId: 1,
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => a.name - b.name,
			sortOrder: defaultsortField === 'name' ? sortDirection : false,
			render: (name) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{name || ''}
				</Text>
			),
		},
		{
			title: 'Code',
			dataIndex: 'code',
			key: 'code',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (code) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{code || ''}
				</Text>
			),
		},
		{
			title: 'Brands',
			dataIndex: 'Brands',
			key: 'Brands',
			sortId: 3,
			overflow: 'hidden',
			hidden: false,
			render: (Brands) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" maxWidth="250px">
					{Brands ? Brands.map(({ name }) => name).join(', ') : ''}
				</Text>
			),
		},
		{
			title: 'Latitude',
			dataIndex: 'latitude',
			key: 'latitude',
			sortId: 4,
			overflow: 'hidden',
			hidden: false,
			render: (latitude, rec) => (
				<Box
					as="a"
					target="_blank"
					href={`http://maps.google.com/maps?z=12&t=m&q=loc:${latitude || ''},${
						rec?.longitude || ''
					}`}
					overFlow="hidden"
					fontSize="12px"
				>
					{latitude || ''}
				</Box>
			),
		},
		{
			title: 'Longitude',
			dataIndex: 'longitude',
			key: 'longitude',
			sortId: 5,
			overflow: 'hidden',
			hidden: false,
			render: (longitude, rec) => (
				<Box
					as="a"
					target="_blank"
					href={`http://maps.google.com/maps?z=12&t=m&q=loc:${rec?.latitude || ''},${
						longitude || ''
					}`}
					overFlow="hidden"
					fontSize="12px"
				>
					{longitude || ''}
				</Box>
			),
		},
		{
			title: 'Created At',
			dataIndex: 'createdAt',
			key: 'createdAt',
			sortId: 6,
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => a.createdAt - b.createdAt,
			sortOrder: defaultsortField === 'createdAt' ? sortDirection : false,
			render: (createdAt) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={createdAt} format="HMSA" />
				</Text>
			),
		},
		{
			title: '',
			dataIndex: 'id',
			key: 'id',
			sortId: 6,
			render: (id) => (
				<ACL allowedGroups={UserPersmission.fc_create}>
					<Link style={{ color: '#000' }} to={`/onboarding/fc/${id}/edit`}>
						<EditOutlined />
					</Link>
				</ACL>
			),
		},
	];
};
