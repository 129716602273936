import styled from 'styled-components';

export const Contaniner = styled.div`
	width: 100%;
	.ant-menu-submenu-selected {
		color: ${(props) => props.theme.colors.white};
	}
	.ant-menu.ant-menu-inline-collapsed {
		width: 40px;
	}
	.ant-menu {
		border-right: 0;
		background: transparent;

		a {
			color: ${(props) => props.theme.colors.white};
		}
	}

	li.ant-menu-item,
	.ant-menu-submenu-title {
		display: flex;
		align-items: center;
		max-height: 30px;
		padding-left: 10px !important;
		span:not(.ant-menu-title-content) {
			margin-right: 10px;
		}
	}

	.ant-menu-submenu-title {
		color: ${(props) => props.theme.colors.white};
		width: 99.99%;

		&:hover {
			color: ${(props) => props.theme.colors.white};
		}
	}
	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.ant-menu-item-only-child {
		background-color: transparent;
	}
	.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:not(.ant-menu-item-only-child) {
		background-color: ${(props) => props.theme.colors.secondary};
		a {
			color: ${(props) => props.theme.colors.white};
		}
	}
	.ant-menu-item.ant-menu-item-selected {
		a {
			color: ${(props) => props.theme.colors.sideBarlinkColour};
		}
	}

	.ant-menu-item.ant-menu-item-selected,
	.ant-menu-submenu-selected {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 0;
		}
	}

	.ant-menu-submenu-arrow,
	.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
	.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
		color: ${(props) => props.theme.colors.white};
	}

	.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
		flex: auto;
	}

	.ant-menu-sub.ant-menu-inline > .ant-menu-item .strokeIcons,
	.ant-menu-sub.ant-menu-inline > .ant-menu-item {
		flex: 0 0 22px;
	}

	.ant-menu-sub {
		padding-left: 10px !important;
	}

	.ant-menu-submenu-selected:before {
		background: none;
	}

	.ant-menu-inline .ant-menu-item::after {
		right: 0;
		border-right: 4px solid ${(props) => props.theme.colors.white};
	}

	.ant-menu-item.ant-menu-item-only-child:hover {
		background-color: transparent;
		a {
			color: ${(props) => props.theme.colors.sideBarlinkColour};
		}
	}
`;
