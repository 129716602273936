/* eslint-disable react/jsx-key */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
// import { Carousel } from 'react-responsive-carousel';
import { Divider, Image, Typography } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import { PdfViewer } from './common';
// import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Box, Flex } from 'atoms';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { PdfThumbnail } from 'components/common';
import { GRNPdfIcon, PdfUnVerified, PdfVerified } from 'utils';

const MultiContainer = styled.div`
	padding: 20px;
	background: #ffffff;
`;

const ImageDiv = styled(Flex)`
	gap: 16px;
	margin-bottom: 42px;
`;

const StyledThumbnail = styled(Image)`
	width: 150px;
	height: 150px;
	object-fit: cover;
`;

const ImageWrapper = styled.div`
	width: 150px;
	display: flex;
	position: relative;
`;

const PDFWrapper = styled.div`
	width: 250px;
	position: relative;
	background: #00000010;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.1);

	.ant-image-mask:hover {
		z-index: 2;
	}

	&:hover {
		z-index: 1;
	}
`;

const VerifyWrapper = styled.div`
	width: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	color: #52c41a;
	gap: 3px;
	margin-top: 15px;
	span {
		margin-right: 3px;
	}
`;

const ImagesContainer = styled(Box)`
	position: relative;
	display: flex;
	flex-wrap: wrap;
	gap: 16px;

	.carousel {
		.carousel-status {
			text-shadow: none !important;
			color: #000000;
			background: rgba(255, 255, 255, 0.5);
			border-radius: 2px;
			padding: 2px 5px;
			top: 16px;
			right: 16px;
		}
	}
`;

const PDFVerifyWrapper = styled.div`
	display: flex;
	color: #52c41a;
	align-items: center;
	justify-content: center;
	margin-left: 25px;
	span {
		margin-right: 3px;
	}
`;

const StyledDiv = styled.div`
	display: flex;
	align-items: center;
	padding: 16px;
`;

const PdfDiv = styled.span`
	position: relative;
	width: 250px;
	margin: 0 auto;
	height: 250px;
`;

const isPDFView = (res) => {
	const fileExtension = res.split('.').pop();
	if (fileExtension.toLowerCase() === 'pdf') {
		return 'pdf';
	}
	return 'image';
};

const { Title } = Typography;

function getFileName(res) {
	return res?.split('/').pop();
}

export default function MultipleImagePreview({ imageList }) {
	const [pdfLists, setPdfLists] = useState([]);
	const [imageLists, setimageLists] = useState([]);

	useEffect(() => {
		const getPDFLists = imageList.filter((res) => isPDFView(res?.image_path) === 'pdf' && res);
		const getImageLists = imageList.filter(
			(res) => isPDFView(res?.image_path) !== 'pdf' && res
		);
		if (getPDFLists.length) {
			setPdfLists(getPDFLists);
		}
		if (getImageLists.length) {
			setimageLists(getImageLists);
		}
	}, [imageList]);

	return (
		<MultiContainer>
			{imageLists.length > 0 && (
				<>
					<Title level={4}>{`Image Count: ${imageLists?.length}`}</Title>
					<ImageDiv>
						<Image.PreviewGroup>
							{imageLists?.map((image, index) => (
								<ImageWrapper
									className={image?.is_verified && 'verified'}
									key={index}
								>
									<div
										style={{
											position: 'relative',
											width: '100%',
											height: 'auto',
										}}
									>
										{isPDFView(image?.image_path) !== 'pdf' && (
											<>
												<StyledThumbnail
													src={image.image_path}
													alt={getFileName(image.image_path)}
												/>
												{image?.is_verified ? (
													<VerifyWrapper>
														<PdfVerified />
														Verified
													</VerifyWrapper>
												) : (
													<VerifyWrapper style={{ color: '#C97902' }}>
														<PdfUnVerified />
														Unverified
													</VerifyWrapper>
												)}
											</>
										)}
									</div>
								</ImageWrapper>
							))}
						</Image.PreviewGroup>
					</ImageDiv>
				</>
			)}
			{pdfLists.length > 0 && (
				<>
					<Divider />
					<Title level={4}>{`PDF Count: ${pdfLists?.length}`}</Title>
					<div style={{ display: 'flex' }}>
						<ImagesContainer>
							{pdfLists?.map((pdf, index) => (
								<div>
									<PDFWrapper
										className={pdf?.is_verified && 'verified'}
										key={index}
									>
										<PdfDiv>
											<PdfThumbnail fileUrl={pdf.image_path} />
										</PdfDiv>
										<StyledDiv>
											<GRNPdfIcon />
											{pdf?.is_verified ? (
												<PDFVerifyWrapper>
													<PdfVerified />
													Verified
												</PDFVerifyWrapper>
											) : (
												<PDFVerifyWrapper style={{ color: '#C97902' }}>
													<PdfUnVerified />
													Unverified
												</PDFVerifyWrapper>
											)}
										</StyledDiv>
									</PDFWrapper>
								</div>
							))}
						</ImagesContainer>
					</div>
				</>
			)}
		</MultiContainer>
	);
}

MultipleImagePreview.propTypes = {
	imageList: PropTypes.array,
};
