import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { Text } from 'atoms';
import { Rupee } from 'components';

const columns = [
	{
		title: 'Product Name (Product Code)',
		dataIndex: 'product_name',
		key: 'product_name',
		render: (product_name) => (
			<>
				<Text textTransform="uppercase">{product_name[0]}</Text>
				<Text textTransform="uppercase">({product_name[1]})</Text>
			</>
		),
	},
	{
		title: 'Batch No',
		dataIndex: 'batch_no',
		key: 'batch_no',
		render: (batch_no) => <Text textTransform="uppercase">{batch_no}</Text>,
	},
	{
		title: 'MRP',
		dataIndex: 'mrp',
		key: 'mrp',
		render: (mrp) => <Rupee>{mrp}</Rupee>,
	},
	{
		title: 'Brand GRN Qty',
		dataIndex: 'brand_grn_qty',
		key: 'brand_grn_qty',
	},
	{
		title: 'Buying Price',
		dataIndex: 'buying_price',
		key: 'buying_price',
		render: (buying_price) => <Rupee>{buying_price}</Rupee>,
	},
	{
		title: 'Gross Amt',
		dataIndex: 'gross_amt',
		key: 'gross_amt',
		render: (gross_amt) => <Rupee>{gross_amt}</Rupee>,
	},
	{
		title: 'Total Discount',
		dataIndex: 'total_discount',
		key: 'total_discount',
		render: (total_discount) => <Rupee>{total_discount}</Rupee>,
	},
	{
		title: 'Total Tax',
		dataIndex: 'total_tax_amt',
		key: 'total_tax_amt',
		render: (total_tax_amt) => <Rupee>{total_tax_amt}</Rupee>,
	},
	{
		title: 'Net Amt',
		dataIndex: 'net_amt',
		key: 'net_amt',
		render: (net_amt) => <Rupee>{net_amt}</Rupee>,
	},
	{
		title: 'Free Qty',
		dataIndex: 'free_qty',
		key: 'free_qty',
	},
];

export default function ShowTable({ data }) {
	const tableData = data?.PurchaseOrderDetails?.map((x, index) => ({
		key: index,
		product_name: [x?.Product?.name, x?.Product?.code],
		product_code: x?.Product?.code,
		batch_no: x?.batch_no,
		mrp: x?.ProductDetail?.mrp,
		brand_grn_qty: x?.brand_grn_qty,
		buying_price: x?.ProductDetail?.buying_price,
		gross_amt: x?.gross_amount,
		total_discount: x?.total_discount,
		total_tax_amt: x?.total_tax_amount,
		net_amt: x?.net_amount,
		free_qty: x?.free_qty,
	}));

	return <Table columns={columns} dataSource={tableData} pagination={false} />;
}

ShowTable.propTypes = {
	data: PropTypes.object,
};
