/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Button } from 'atoms';
import SalesmanHeader from './SalesmanHeader';
const ButtonContainer = styled.div`
	display: flex;
	margin-top: 1.4rem;
	justify-content: center;
`;
export default function UpdateSalesman({ modalVisible, modalClose }) {
	return (
		<Modal
			title={<SalesmanHeader />}
			centered
			visible={modalVisible}
			footer={null}
			onCancel={modalClose}
			className="salesman__modal"
			maskClosable={false}
		>
			<Box mt="3" textAlign="center"></Box>
			<ButtonContainer>
				<Button variant="secondary" onClick={modalClose}>
					Cancel
				</Button>
				<Button variant="primary" onClick={modalClose}>
					Add
				</Button>
			</ButtonContainer>
		</Modal>
	);
}

UpdateSalesman.propTypes = {
	modalVisible: PropTypes.bool,
	modalClose: PropTypes.func,
};
