/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect, useState } from 'react';
import { Col, Collapse, message, Row, Steps } from 'antd';
import { Formik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Box } from 'atoms';
import { getOrdersById, orderComplete, rfcBlockerStatus } from 'store/fulfilment/returnToFCSlice';
import { RTCCollectionForm, Debug } from 'components/forms';
import { getBankOnSearchFilter } from 'store/masters';
import { SelectOptions } from 'utils';
import { RTCSetInitialValues, RTCInitialValues, RTCFormSchema, RTCRefinedPayload } from '../helper';
import { ErrorText } from 'components/common/ErrorText';
import { getCurrency } from 'components/Rupee';
import MapHeaderPannel from './MapHeaderPannel';
import MapComponent from 'components/map/MapComponent';
import { retailerKYC } from 'store/fulfilment/salesOrderSlice';
import { AccordionHeader, PanelData } from 'components/common';
import { returnToFcShowPanelNew } from 'pages/fulfillment/sales-order/salesOrderRFCConfig';

const { Step } = Steps;
const { Panel } = Collapse;
const returnAmount = (data) => data?.reduce((a, c) => parseFloat(c?.amount || 0) + a, 0);

const ExtraAmount = 100;

function validate100Extra(values) {
	const enteredAmount =
		(parseFloat(values?.cash) || 0) +
		returnAmount(values?.cheque) +
		returnAmount(values?.upi) +
		returnAmount(values?.neft);

	return enteredAmount > (values?.delivered_amount || 0) + 100;
}

const rolesAllowedToUpload = ['Transport Manager', 'System Admin'];

export default function RTCCollectionPage() {
	const { id: orderId } = useParams();
	const locationRoutes = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { invoiceId } = useSelector((s) => s?.returnToFC);
	const searchParams = new URLSearchParams(locationRoutes.search);
	const searchVal = searchParams.get('filter') ?? '';
	const quid = searchParams.get('quid') ?? '';
	const [data, setData] = useState({ type: 'NotAsked' });
	const [bankList, setBankList] = useState([]);
	const [initialValues, setFormInitial] = useState(RTCInitialValues);
	const [invalidData, setInvalidData] = useState({ upis: [], neft: [] });
	const [rfcEditBlock, setRfcEditBlock] = useState(false);
	const { auth } = useSelector((state) => state?.auth);
	const [selectedLocation, setSelectedLocation] = useState({
		lat: null,
		lng: null,
	});
	const [newSelectedLocation, setNewSelectedLocation] = useState({
		lat: null,
		lng: null,
	});
	const [kycDetails, setKycDetails] = useState(null);

	const uploadAllowed = rolesAllowedToUpload.includes(auth?.user.groups[0].name);

	function getMapDetails() {
		dispatch(retailerKYC(orderId)).then((res1) => {
			console.log('res1 kyc details ', res1);
			setKycDetails(res1?.data);
			setSelectedLocation({
				// eslint-disable-next-line no-unsafe-optional-chaining
				lat: +res1?.data?.store_latitude,
				// eslint-disable-next-line no-unsafe-optional-chaining
				lng: +res1?.data?.store_longitude,
			});
			if (res1?.data?.outside_fence || res1?.data?.approval_status === 'rejected') {
				setNewSelectedLocation({
					// eslint-disable-next-line no-unsafe-optional-chaining
					lat: +res1?.data?.kyc_latitude || +res1?.data?.latitude,
					// eslint-disable-next-line no-unsafe-optional-chaining
					lng: +res1?.data?.kyc_longitude || +res1?.data?.longitude,
				});
			}
		});
	}

	useEffect(() => {
		if (!isEmpty(orderId)) {
			dispatch(rfcBlockerStatus(orderId)).then((res) => setRfcEditBlock(res?.rfc_edit_block));
		}
	}, [dispatch, orderId]);

	useEffect(() => {
		setData({ type: 'Loading' });
		dispatch(getOrdersById(orderId)).then((res) => {
			const { invoice, order } = res;

			Promise.all([dispatch(getBankOnSearchFilter({ limit: 1000 }))]).then(([bankRes]) => {
				setBankList(bankRes);

				setFormInitial(RTCSetInitialValues(invoice, { status: order?.status }, []));
				setData({ type: 'Success', data: res || {} });
				getMapDetails();
			});
		});
	}, [dispatch, orderId]);

	const validationOnUpdate = (values, { setErrors }, dataList) => {
		console.log('oohhhData', dataList?.data?.invoice?.current_outstanding_amount);
		const enteredAmount =
			(parseFloat(values?.cash) || 0) +
			returnAmount(values?.cheque) +
			returnAmount(values?.upi) +
			returnAmount(values?.neft);

		if (parseFloat(values?.cash) > 200000) {
			message.info('Cash payment more than 200000 is not allowed');
			return false;
		}

		if (enteredAmount > (values?.delivered_amount || 0) + ExtraAmount) {
			message.info('Entered Amount is greater than Delivered Amount');
			return false;
		}

		if (
			enteredAmount === values?.delivered_amount &&
			['Bill Back', 'Voucher'].includes(values?.invoice_status)
		) {
			setErrors({
				invoice_status:
					'Please select Invoice settled as entered Amount is equal to delivery Amount',
			});
			return false;
		}

		console.log(values, 'ErrorVal');

		if (
			dataList?.data?.invoice?.current_outstanding_amount > 0 &&
			!isEmpty(values?.short_settlement_reason) &&
			// eslint-disable-next-line sonarjs/no-duplicate-string
			values?.invoice_status === 'No Bill Back'
		) {
			setErrors({ short_settlement_reason: 'Short Settlement Reason is required' });
			return false;
		}

		// if (
		// 	enteredAmount > values?.delivered_amount &&
		// 	isEmpty(values?.remarks?.trim()) &&
		// 	values?.invoice_status === 'No Bill Back'
		// ) {
		// 	setErrors({ remarks: 'Remarks is required' });
		// 	return false;
		// }

		// if (
		// 	enteredAmount < values?.delivered_amount &&
		// 	values?.invoice_status === 'No Bill Back' &&
		// 	isEmpty(values?.remarks?.trim())
		// ) {
		// 	setErrors({ remarks: 'Remarks is required' });
		// 	return false;
		// }
		return true;
	};

	function addErrorData(upis, nefts, formToucher, errorData) {
		setInvalidData({ upis, neft: nefts });

		setTimeout(() => {
			for (let i = 0; i < errorData?.upi?.length; i += 1) {
				if (upis.find((v) => v === errorData?.upi[i]?.ref_no)) {
					formToucher(`upi[${i}].ref_no`);
				}
			}

			for (let i = 0; i < errorData?.neft?.length; i += 1) {
				if (nefts.find((v) => v === errorData?.neft[i]?.ref_no)) {
					formToucher(`neft[${i}].ref_no`);
				}
			}
		}, 500);
	}

	// eslint-disable-next-line consistent-return, sonarjs/cognitive-complexity
	const handleSubmit = async (values, { setSubmitting, setErrors, setFieldTouched }) => {
		try {
			setSubmitting(true);
			if (!validationOnUpdate(values, { setSubmitting, setErrors }, data)) {
				setSubmitting(false);
				return false;
			}

			if (!validationOnUpdate(values, { setSubmitting, setErrors }, data)) {
				setSubmitting(false);
				return false;
			}

			if (
				values?.invoice_status === 'Bill Back' ||
				values?.invoice_status === 'No Bill Back'
			) {
				values = { ...values, billtype: 'Bill' };
			}

			if (!validationOnUpdate(values, { setSubmitting, setErrors })) {
				setSubmitting(false);
				return false;
			}

			if (!isEmpty(orderId)) {
				// Dispatch rfcBlockerStatus and wait for the result using async/await
				const res = await dispatch(rfcBlockerStatus(orderId));
				setRfcEditBlock(res?.rfc_edit_block);

				if (res?.rfc_edit_block) {
					setSubmitting(false);
					message.error(
						'You cannot Edit or Update the details until Delivery Person completes Delivery activity in Mobile Application'
					);
					return false; // Stop further processing
				}
			}

			// Dispatch orderComplete and wait for the result using async/await
			const r = await dispatch(
				orderComplete(RTCRefinedPayload(values, 'collection'), orderId, setErrors)
			);

			if (r.status === 'error') {
				if (r.mode === 'NEFT') {
					addErrorData([], r.reference_numbers, setFieldTouched, values);
				} else if (r.mode === 'UPI') {
					addErrorData(r.reference_numbers, [], setFieldTouched, values);
				}
			} else {
				message.info('Collection details updated Successfully');
				navigate(
					`/logistics-management/return-to-fc-new/${+invoiceId}/show?quid=${quid}&filter=${searchVal}`
				);
			}
		} catch (e) {
			if ('response' in e && e?.response?.status === 422) {
				message.error('Error while updating the order data');
			}
		} finally {
			setSubmitting(false); // Ensure setSubmitting is called in case of any errors
		}
	};

	// const handleSubmit = (values, { setSubmitting, setErrors, setFieldTouched }) => {
	// 	setSubmitting(true);
	// 	if (values?.invoice_status === 'Bill Back' || values?.invoice_status === 'No Bill Back') {
	// 		values = { ...values, billtype: 'Bill' };
	// 	}
	// 	if (!validationOnUpdate(values, { setSubmitting, setErrors })) {
	// 		setSubmitting(false);
	// 		return false;
	// 	}

	// 	if (!isEmpty(orderId)) {
	// 		dispatch(rfcBlockerStatus(orderId)).then((res) => {
	// 			setRfcEditBlock(res?.rfc_edit_block);

	// 			if (res?.rfc_edit_block) {
	// 				setSubmitting(false);
	// 				message.error(
	// 					'You cannot Edit or Update the details until Delivery Person completes Delivery activity in Mobile Application'
	// 				);
	// 				return false;
	// 			}
	// 			return true;
	// 		});
	// 	}

	// 	return dispatch(orderComplete(RTCRefinedPayload(values, 'collection'), orderId, setErrors))
	// 		.then((r) => {
	// 			if (r.status === 'error') {
	// 				if (r.mode === 'NEFT') {
	// 					addErrorData([], r.reference_numbers, setFieldTouched, values);
	// 				} else if (r.mode === 'UPI') {
	// 					addErrorData(r.reference_numbers, [], setFieldTouched, values);
	// 				}
	// 			} else {
	// 				message.info('Collection details updated Successfully');
	// 				navigate(`/logistics-management/return-to-fc-new/${+invoiceId}/show`);
	// 			}
	// 		})
	// 		.catch((e) => {
	// 			if ('response' in e && e?.response?.status === 422) {
	// 				message.error('Error while updating the order data');
	// 			}
	// 		});
	// };

	if (data.type === 'Loading' || data.type === 'NotAsked') {
		return (
			<Box className="RTCDetailsPage" background="#F5F5F5" textAlign="center">
				Loading...
			</Box>
		);
	}

	console.log(data, 'datadata1');

	return (
		<Box className="RTCDetailsPage" background="#F5F5F5">
			<Box width="30%" margin="auto" marginBottom="30px">
				<Steps current={2} labelPlacement="vertical">
					<Step
						className="stepOrangeIcons"
						status="finish"
						description="Delivery Details"
						title=""
					/>
					<Step
						className="stepIcons"
						status="process"
						description="Collection Details"
						title=""
					/>
				</Steps>
			</Box>
			<Box padding="20px 30px">
				<Formik
					initialValues={initialValues}
					validationSchema={RTCFormSchema('collection', invalidData)}
					enableReinitialize
					onSubmit={handleSubmit}
				>
					{({ values, setFieldValue, errors, setErrors, isValid }) => (
						<Form layout="vertical">
							{console.log('selectedLocation ', selectedLocation)}
							{console.log('newSelectedLocation ', newSelectedLocation)}
							{((selectedLocation?.lat && selectedLocation?.lng) ||
								(newSelectedLocation?.lat && newSelectedLocation?.lng)) && (
								<Box marginBottom="30px">
									<Collapse
										bordered={false}
										expandIconPosition="end"
										className="custom"
									>
										<Panel
											header={
												<MapHeaderPannel
													HeaderData={{
														KycDetails: kycDetails,
													}}
												/>
											}
											key="mapDetails"
										>
											<div style={{ overflow: 'hidden' }}>
												<div
													style={{
														height: '300px',
														float: 'left',
														width: '100%',
													}}
												>
													<MapComponent
														selectedLocation={selectedLocation}
														newSelectedLocation={newSelectedLocation}
														kycDetails={kycDetails}
													/>
												</div>
											</div>
										</Panel>
									</Collapse>
								</Box>
							)}
							<Box className="RTCDetailsPageNoBorder" marginBottom="30px">
								<Collapse
									expandIconPosition="right"
									defaultActiveKey={['accoridon']}
									ghost
								>
									<Panel
										header={
											<AccordionHeader
												title="Order Details:"
												status={data?.data?.order?.status}
												invoice={data?.data?.invoice?.invoice_no}
												storeName={data?.data?.invoice?.store_name}
											/>
										}
										key="accoridon"
										className="site-collapse-custom-panel"
									>
										{/* {console.log(dat)} */}
										<Row gutter={6}>
											{returnToFcShowPanelNew(data?.data).map(
												(x) =>
													!x.hidden && (
														<Col key={x?.title} xs={6}>
															{console.log('data', data)}
															<PanelData
																title={x?.title}
																value={x?.value}
																additionalValue={
																	x?.additionalValue || ''
																}
																type={x?.type || ''}
																infoDetails={x?.infoDetails}
																brandInfo={data?.Brand}
															/>
															{x.hidden}
														</Col>
													)
											)}
										</Row>
									</Panel>
								</Collapse>
							</Box>
							<Box marginBottom="30px">
								<Collapse
									bordered={false}
									defaultActiveKey={['collection_details']}
									expandIconPosition="end"
								>
									<Panel header="Collection Details:" key="collection_details">
										<RTCCollectionForm
											values={values}
											data={data?.data}
											bankList={SelectOptions(bankList, 'name', 'id')}
											setFieldValue={setFieldValue}
											setErrors={setErrors}
											errors={errors}
											uploadAllowed={uploadAllowed}
											rfcEditBlock={rfcEditBlock}
										/>
									</Panel>
								</Collapse>
							</Box>

							<Box textAlign="center">
								<SubmitButton
									disabled={
										!uploadAllowed ||
										['VerifiedByCashier', 'PartialVerifiedByCashier']?.includes(
											data?.data?.invoice?.invoice_verification_status
										) ||
										validate100Extra(values) ||
										!isValid ||
										rfcEditBlock
									}
								>
									Update
								</SubmitButton>
								{validate100Extra(values) && (
									<ErrorText
										errorString={`Max allowed amount is ${getCurrency(
											0,
											Number(values?.delivered_amount) + Number(ExtraAmount)
										)} rupees`}
									/>
								)}
							</Box>

							<Debug />
						</Form>
					)}
				</Formik>
			</Box>
		</Box>
	);
}
