/* eslint-disable no-unneeded-ternary */
import React, { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Rupee } from 'components';
import DateFormat from 'components/DateFormat';
import { BouncedChequeList } from 'components/pages/finance';
import { Text } from 'atoms';

export function ChequeBounceData() {
	const [modalVisible, setModalVisible] = useState(false);
	const [chequeBounceRowData, setChequeBounceRowData] = useState();

	const getStatus = (status) => {
		let currentStatus = '';

		if (status === 'Received-at-hub') {
			currentStatus = 'Received At Hub';
		}
		if (status === 'Created') {
			currentStatus = 'Created';
		}
		if (status === 'VOID') {
			currentStatus = 'Deleted';
		}
		return currentStatus;
	};

	const columns = [
		{
			title: 'Cheque Number',
			dataIndex: 'cheque_number',
			key: 'cheque_number',
			render: (cheque_number) => <div>{cheque_number}</div>,
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: (amount) => <Rupee>{amount}</Rupee>,
		},
		{
			title: 'Due Date',
			dataIndex: 'due_date',
			key: 'due_date',
			render: (due_date) => <DateFormat date={due_date} format="DMY" />,
		},

		{
			title: 'FC',
			dataIndex: 'fc_name',
			key: 'fc_name',
			render: (fc_name) => <div>{fc_name}</div>,
		},
		{
			title: 'Brand',
			dataIndex: 'brand_name',
			key: 'brand_name',
			render: (brand_name) => <div>{brand_name}</div>,
		},
		{
			title: 'Store',
			dataIndex: 'store_name',
			key: 'store_name',
			render: (store_name) => <div>{store_name}</div>,
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (status) => (
				<Text color={status === 'VOID' ? '#CA5010' : '#0a9600'}>{getStatus(status)}</Text>
			),
		},
		{
			title: 'Action',
			key: 'action',
			dataIndex: 'action',
			align: 'center',
			render: (_, record) => (
				<span style={{ color: '#CA5010' }}>
					{record?.status === 'Created' ? (
						<DeleteOutlined
							color="orange"
							onClick={() => {
								setChequeBounceRowData(record);
								setModalVisible(true);
							}}
						/>
					) : null}
				</span>
			),
		},
	];

	return (
		<BouncedChequeList
			columns={columns}
			setModalVisible={setModalVisible}
			modalVisible={modalVisible}
			chequeBounceRowData={chequeBounceRowData}
		/>
	);
}
