import * as Yup from 'yup';

export const SalesManFormSchema = () =>
	Yup.object({
		name: Yup.string()
			.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
			.required()
			.label('Name')
			.min(3)
			.max(75)
			.trim(),
		code: Yup.string()
			.matches(/^[aA-zZ0-9\s-_]+$/, 'Please enter valid code')
			.required()
			.label('Code')
			.min(1)
			.max(25)
			.trim(),
		mobile: Yup.string()
			.matches(/^[0-9]{10}$/i, 'Please enter valid Mobile Number')
			.label('Mobile Number')
			.required(),
		fc_id: Yup.string(),
		brand_id: Yup.object().required().label('FC:Brands'),
	});
