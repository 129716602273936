import { createSlice } from '@reduxjs/toolkit';
import { message, message as toaster } from 'antd';
import { capitalize } from 'lodash';
import { API } from 'api';
import { redirectToLogin, setLocalData } from 'api/authentication';

const initialState = {
	me: {},
	auth: {},
	role: '',
};

export const authSlice = createSlice({
	name: 'auth', // name of the reducer
	initialState,
	reducers: {
		setUser: (state, { payload = {} }) => {
			state.me = payload;
			state.role = capitalize(payload?.groups?.[0]?.name);
		},
		setAuth: (state, { payload = {} }) => {
			state.auth = payload;
		},
		setUserProfile: (state, { payload = {} }) => {
			state.me = { ...state?.me, ...payload };
		},
	},
});

export const getUser = () => async () => {
	const CREDENTIALS = {
		url: `/v1/profile`,
	};
	return API.common(CREDENTIALS).then((response) => {
		console.log('profile api done');
		// dispatch(setUser(response));
		localStorage.setItem(process.env.REACT_APP_CDMS_APP_PROFILE, JSON.stringify(response));
		return response;
	});
};

export const getAuth = () => async () => {
	const CREDENTIALS = {
		url: `/champ/auth`,
	};

	return API.common(CREDENTIALS).then((response) => {
		console.log('auth api done', response);
		// dispatch(setAuth(response?.data));
		localStorage.setItem(process.env.REACT_APP_CDMS_APP_AUTH, JSON.stringify(response?.data));
		return response?.data;
	});
};

export const userLogin = (values, setErrors) => async (dispatch) => {
	const CREDENTIALS = {
		url: 'champ/login',
		method: 'post',
		data: {
			username: values?.email,
			password: values?.password,
		},
		setErrors,
	};

	return API.common(CREDENTIALS).then(async (response) => {
		const userRes = response?.data?.user;
		const tokeRes = response?.data?.token;

		localStorage.setItem(process.env.REACT_APP_CDMS_APP_AUTH, JSON.stringify(tokeRes));
		localStorage.setItem(process.env.REACT_APP_CDMS_APP_PROFILE, JSON.stringify(userRes));
		setLocalData(tokeRes);
		dispatch(setUser(userRes));
		dispatch(setAuth(response?.data));
		return response;
	});
};

export const forgotPassword = (values, setErrors) => async () => {
	const CREDENTIALS = {
		url: 'forgot-password',
		method: 'post',
		data: values,
		setErrors,
	};

	return API.common(CREDENTIALS).then((response) => {
		toaster.success(`${response?.message}`);
		return response;
	});
};

export const resetPassword = (values, setErrors) => async () => {
	const { password } = values;
	const CREDENTIALS = {
		url: 'reset-password',
		method: 'put',
		data: { password, token: values?.token },
		setErrors,
	};

	return API.common(CREDENTIALS).then((response) => {
		toaster.success(`${response?.message}`);
		return response;
	});
};

export const changePassword = (values, setErrors) => async () => {
	const CREDENTIALS = {
		url: 'champ/profile/reset_password',
		method: 'post',
		data: values,
		setErrors,
	};

	return API.common(CREDENTIALS).then((response) => {
		toaster.success('Password updated successfully');
		return response;
	});
};

export const changeUserPassword = (values, setErrors) => async () => {
	const CREDENTIALS = {
		url: 'champ/user/reset_password',
		method: 'post',
		data: values,
		setErrors,
	};

	return API.common(CREDENTIALS).then((response) => {
		toaster.success('User password updated successfully');
		return response;
	});
};

export const userLogout = () => async (dispatch) => {
	dispatch(setUser({}));
	redirectToLogin();
};

export const getUserProfile = () => async () => {
	const CREDENTIALS = {
		url: 'champ/profile',
	};

	return API.common(CREDENTIALS).then((response) => response?.data);
};

export const updateUserProfile = (data) => async () => {
	const CREDENTIALS = {
		url: 'champ/profile/edit',
		method: 'post',
		data,
	};

	return API.common(CREDENTIALS).then((response) => {
		message.info('User profile updated successfully');
		return response?.data;
	});
};

export const { setUser, setAuth, setUserProfile } = authSlice.actions;

export default authSlice.reducer;
