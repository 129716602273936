import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { getCollectionList } from 'store/invoiceSlice';
import { SalesMenList, Stats, Tab, Date, ColumnFilters } from 'components/pages/segregator';
import { Line, SearchBox } from 'components';
import { Flex, Box } from 'atoms';

export default function Dashboard() {
	const dispatch = useDispatch();
	const [persons, setPersons] = useState({});
	const [showColumns, setShowColumns] = useState([]);
	const { date, tab, dateTrigger } = useSelector((state) => state.invoice);

	const handleColumnHideShow = (checkedValues) => {
		setShowColumns([...checkedValues]);
	};

	const handleSearchChange = useMemo(
		() =>
			debounce((event) => {
				dispatch(getCollectionList({ q: event.target.value })).then((res) => {
					setPersons({ ...res });
				});
			}, 500),
		[dispatch]
	);

	useEffect(() => {
		setPersons({});
		dispatch(getCollectionList({})).then((res) => {
			setTimeout(() => {
				setPersons({ ...res });
			}, 0);
		});
	}, [dispatch, date, tab, dateTrigger]);

	return (
		<div>
			<Flex justifyContent="space-between">
				<Box>
					<SearchBox
						onSearch={handleSearchChange}
						placeholder="Search by Name / Phone Number"
					/>
					<Flex alignItems="center">
						<Tab />
						<Line height="25px" mr="1rem" />
						<Date />
					</Flex>
					<div>
						<ColumnFilters onChange={handleColumnHideShow} />
					</div>
				</Box>
				<Stats />
			</Flex>
			<SalesMenList tab={tab} showColumns={showColumns} persons={persons?.data || []} />
		</div>
	);
}
