import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { Text } from 'atoms';
import DateFormat from 'components/DateFormat';
import { getstatus } from 'utils';

const columns = [
	{
		title: 'Invoice No.',
		dataIndex: 'invoice_no',
		key: 'invoice_no',
		render: (invoice_no) => (
			<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
				{invoice_no}
			</Text>
		),
	},
	{
		title: 'Retailer Name',
		dataIndex: 'Store',
		key: 'Store',
		render: (Store) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{Store}
			</Text>
		),
	},
	{
		title: 'Delivery Status',
		dataIndex: 'status',
		key: 'status',
		render: (status) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{getstatus(status)}
			</Text>
		),
	},
	{
		title: 'Delivery Code',
		dataIndex: 'delivery_code',
		key: 'delivery_code',
		render: (delivery_code) => (
			<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
				{delivery_code}
			</Text>
		),
	},
	{
		title: 'Crates Count',
		dataIndex: 'crates',
		key: 'crates',
		render: (crates) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{crates}
			</Text>
		),
	},
	{
		title: 'Delivery Lat Long',
		dataIndex: 'StoreCoordinates',
		key: 'StoreCoordinates',
		render: (StoreCoordinates) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{StoreCoordinates}
			</Text>
		),
	},
	{
		title: 'Updated on',
		dataIndex: 'updatedAt',
		key: 'updatedAt',
		render: (updatedAt) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				<DateFormat date={updatedAt} format="DMY" />
			</Text>
		),
	},
];

export default function ShowAllocationTable({ data }) {
	const tableData = data?.rows?.map((x, index) => ({
		key: index,
		invoice_no: x?.invoice_no,
		Store: x?.Store?.name,
		status: x?.status,
		delivery_code: x?.delivery_code,
		crates: x?.crates,
		StoreCoordinates:
			x?.StoreCoordinates && x?.StoreCoordinates.length
				? `${x.StoreCoordinates[0].latitude}, ${x.StoreCoordinates[0].longitude}`
				: '',
		updatedAt: x?.updatedAt,
	}));

	return <Table columns={columns} dataSource={tableData} pagination={false} />;
}

ShowAllocationTable.propTypes = {
	data: PropTypes.object,
};
