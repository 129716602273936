import React from 'react';
import styled from 'styled-components';
import HomeBg from 'assets/home-bg.svg';
import { Box, Image, Flex, Text } from 'atoms';
import { LoginForm } from 'components/forms';
import RipplrLogoWhite from 'assets/ripplr-logo.svg';

export const Container = styled(Flex)`
	overflow: hidden;
`;

export const ImgContainer = styled(Box)`
	width: 65%;
	height: 100vh;
`;

export const FormContainer = styled(Flex)`
	width: 30%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const Title = styled(Text)`
	font-size: 48px;
	line-height: 75px;
	font-family: Work Sans;
	color: ${(props) => props.theme.colors.MidnightGreen};
`;

export const Img = styled(Image)`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export default function Login() {
	return (
		<Container>
			<ImgContainer>
				<Img src={HomeBg} alt="Home Bg image" />
			</ImgContainer>
			<FormContainer>
				<Image width={250} src={RipplrLogoWhite} alt="home" />
				<Title>CDMS</Title>
				<LoginForm />
				{process.env.REACT_APP_ENV !== 'production' && (
					<Text
						textTransform="capitalize"
						fontSize="10px"
						textAlign="center"
						px=".4rem"
						py="1rem"
						lineHeight="16px"
					>
						{/* {process.env.NODE_ENV} | {process.env.REACT_APP_ENV} |{window.location.href}{' '}
						| BuildCount - 44 */}
					</Text>
				)}
			</FormContainer>
		</Container>
	);
}
