import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Text } from 'atoms';
import { Line, Rupee } from 'components';

export default function SalesManStats({ data }) {
	return (
		<Container>
			<Card>
				<Flex flexDirection="column" pl="0.25rem">
					<Title>Salesperson</Title>
					<Count>{data?.collected_person || 'NA'}</Count>
					<Count mt="0.5rem">{data?.collected_person_mobile || 'NA'}</Count>
				</Flex>
			</Card>
			<Card>
				<Flex flexDirection="column">
					<Title>Brand</Title>
					<Count>{data?.brand_name || 'NA'}</Count>
				</Flex>
			</Card>
			<Line height="50px" mx="1rem" />
			<Card>
				<Flex flexDirection="column" pl="0.25rem">
					<Title maxWidth="50px">Total Invoice</Title>
					<Count>{data?.total_invoices || 0}</Count>
				</Flex>
			</Card>
			<Card>
				<Flex flexDirection="column" pl="0.25rem">
					<Title maxWidth="50px">Invoice Settled</Title>
					<Count>{data?.no_bill_back || 0}</Count>
				</Flex>
			</Card>
			<Card>
				<Flex flexDirection="column" pl="0.25rem">
					<Title maxWidth="50px">Invoice Returned</Title>
					<Count>{data?.bill_back || 0}</Count>
				</Flex>
			</Card>
			<Card>
				<Flex flexDirection="column" pl="0.25rem">
					<Title maxWidth="50px">Invoice Missing</Title>
					<Count>{data?.invoice_missing || 0}</Count>
				</Flex>
			</Card>
			<Card>
				<Flex flexDirection="column" pl="0.25rem">
					<Title minHeight="30px">Voucher</Title>
					<Count>{data?.voucher || 0}</Count>
				</Flex>
			</Card>
			<Line height="50px" mx="1rem" />
			<Card>
				<Flex flexDirection="column" pl="0.25rem">
					<Title>Total Collection</Title>
					<Count>
						<Rupee>{data?.total_collection || 0}</Rupee>
					</Count>
				</Flex>
			</Card>
			<Card>
				<Flex flexDirection="column" pl="0.25rem">
					<Title>Cash</Title>
					<Count>
						<Rupee>{data?.total_cash || 0}</Rupee>
					</Count>
				</Flex>
			</Card>
			<Card>
				<Flex flexDirection="column" pl="0.25rem">
					<Title>Cheque</Title>
					<Count>
						<Rupee>{data?.total_cheque || 0}</Rupee>({data?.total_cheque_count})
					</Count>
				</Flex>
			</Card>
			<Card>
				<Flex flexDirection="column" pl="0.25rem">
					<Title>UPI</Title>
					<Count>
						<Rupee>{data?.total_upi || 0}</Rupee>
					</Count>
				</Flex>
			</Card>
			<Card>
				<Flex flexDirection="column" pl="0.25rem">
					<Title>NEFT</Title>
					<Count>
						<Rupee>{data?.total_online || 0}</Rupee>
					</Count>
				</Flex>
			</Card>
		</Container>
	);
}

export const Container = styled(Flex)`
	padding: 25px;
	border: 1px solid ${(props) => props.theme.colors.Isabelline};
	border-left: 5px solid ${(props) => props.theme.colors.secondary};
	border-radius: 4px;
	margin-bottom: 1.5rem;
	background-color: ${(props) => props.theme.colors.white};
	width: 100%;
`;

export const Card = styled(Flex)`
	margin: 0 1rem;
	align-items: center;
`;

export const Title = styled(Text)`
	font-weight: 300;
	font-size: 12px;
	padding-bottom: 0.5rem;
	color: ${(props) => props.theme.colors.DimGray};
`;

export const Count = styled(Text)`
	font-size: 16px;
	color: ${(props) => props.theme.colors.black};
`;

SalesManStats.propTypes = {
	data: PropTypes.object,
};
