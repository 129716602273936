import { ORDER_STATUSES } from 'utils';

export const FILE_STATUS_CHOICE = [
	{
		id: 'file_validated',
		name: 'File Validated',
		color: '#17A2B8',
		background: '#E2F6F6',
	},
	{ id: 'processing_error', name: 'Processing Error', color: '#FF4500', background: '#FFF4F0' },
	{
		id: 'partially_processed',
		name: 'Partially Processed',
		color: '#CB7A00',
		background: '#FFF4F0',
	},
	{
		id: 'fully_processed',
		name: 'Fully Processed',
		color: '#107C10',
		background: '#F2FEF1',
	},
	{
		id: 'uploaded',
		name: 'Uploaded',
		color: '#FF00FF',
		background: '#FFE7FF',
	},
	{ id: 'started', name: 'Started', color: '#17A2B8', background: '#E2F6F6', type: 'started' },
	{
		id: 'processing',
		name: 'Processing',
		color: '#fff',
		background: '#4B0082',
	},
	{
		id: 'processed',
		name: 'Processed',
		color: '#17A2B8',
		background: '#E2F6F6',
	},
	{
		id: 'validation_error',
		name: 'Validation Error',
		color: '#FF4500',
		background: '#FFF4F0',
	},
	{
		id: 'execution_error',
		name: 'Execution Error',
		color: '#FF4500',
		background: '#FFF4F0',
	},
	{
		id: 'picking',
		name: 'Ready for Picking',
		color: '#0000FF',
		background: '#E2E2FF',
	},
	{
		id: 'put',
		name: 'Ready for Vehicle Allocation',
		color: '#0000FF',
		background: '#E2E2FF',
	},
	{
		id: 'out_for_delivery',
		name: 'Out for Delivery',
		color: '#FF00FF',
		background: '#FFE7FF',
	},
	{
		id: 'pending',
		name: 'Pending',
		color: '#FF00FF',
		background: '#FFE7FF',
	},
	...ORDER_STATUSES.map((x) => ({ id: x.value, name: x.label })),
];

export const hasErrorTypes = [
	'uploaded',
	'processing',
	'processed',
	'validation_error',
	'execution_error',
	'picking',
	'file_validated',
	'processing_error',
];
export const hasErrorTypesRefresh = [
	'uploaded',
	'processing',
	'file_validated',
	'partially_processed',
	'picking',
	'fully_processed',
];
export const hasDownloadErrorTypes = ['processing_error', 'partially_processed'];

export const srHideEyeTypes = ['validation_error', 'processing'];
