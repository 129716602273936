/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty, uniq, uniqBy, omit } from 'lodash';
import { Input, Switch, Select, message as toaster, Modal, Table } from 'antd';
import styledComponents from 'styled-components';
import { Box, Button, Flex, Text, AsyncSelect } from 'atoms';
import { RCTable, SearchBar, LabelWrapper, LoadAsyncSelectComponent } from 'components/common';
import {
	deliveryAllocationCreateColumns,
	toAsysnOption,
	handleSearch,
	handleFilterChange,
	DAQueries,
	SelectFilterLabelOptions,
	ORDER_STATUSES_ENUM,
	searchBox,
	initialPaginateConfigWithoutQ,
} from 'utils';
import { VehicleAllocateModal } from 'components/modals';
import { getSaleStoreList, postMarkOrders } from 'store/fulfilment/salesOrderSlice';
import {
	getSalesMenOnSearchList,
	viewDAList,
	getUserList,
} from 'store/fulfilment/deliveryAllocationSlice';
import { getFcOnSearchList, getFcListOnLoad } from 'store/masters';
import { SearchField } from 'components/common/custom-table/Style';
import { useQueryParams } from 'hooks';
import { getStoreDataOnLoad } from 'store/OnBoardSlice';

const FlexWrap = styledComponents(Flex)`
	padding: 10px 16px;
	margin: 10px 0;
	background: aliceblue;
	justify-content: space-between;
	align-items: center;
`;

const CommonStyle = {
	width: '200px',
	alignSelf: 'center',
	margin: '0 1rem 1rem',
};

const ConfirmationMessage = styledComponents(Text)`
	color: #000;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const ErrorText = styledComponents(Text)`
	color: #CA5010;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const BoxFlex = styledComponents(Box)`
display: flex;
position: relative;
`;

const AllBox = styledComponents(Box)`
    margin: 7px -3px;
	padding: 12px 20px;
    border-right: 1px solid #000;
	color: #fff;
	min-width: 112px;
	font-size: 12px;
    text-align: center;
    border: 1px solid #CA5010;
	margin-right:5px;
	`;

const Box35 = styledComponents(Box)`
    margin: 7px -3px;
	padding: 12px 20px;
	color: #fff;
	min-width: 112px;
	font-size: 12px;
    text-align: center;
    border: 1px solid #CA5010;
	
	`;

const BoxInvAge = styledComponents(Box)`
font-size: 12px;
position: absolute;
    top: -9px;
`;

const rolesAllowed = ['Billing Executive', 'System Admin', 'Transport Manager'];

export default function DeliveryAllocationCreate() {
	const dispatch = useDispatch();

	const [paginateConfig, setpaginateConfig] = useQueryParams({ filterQuery: DAQueries });
	const [rowData, setRowData] = useState({});
	const [totalrowData, settotalRowData] = useState([]);
	const [storeList, setStoreList] = useState({ type: 'NotAsked' });
	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);
	const [salesManList, setsalesManList] = useState({ type: 'NotAsked' });
	const [rowSelect, setrowSelect] = useState([]);
	const { auth } = useSelector((state) => state?.auth);
	const [userlist, setUserList] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [errorMsg, setErrorMsg] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [modalErrorVisible, setModalErrorVisible] = useState(false);
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [bg_color, setBg_color] = useState(true);

	const navigate = useNavigate();

	const showModal = () => {
		setIsModalOpen(true);
	};
	const handleOk = () => {
		setIsModalOpen(false);
		handleMarkPodBills();
	};
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const showModalError = () => {
		setModalErrorVisible(true);
	};
	const handleCancelError = () => {
		setModalErrorVisible(false);
		window.location.reload();
	};

	useEffect(() => {
		setStoreList({ type: 'Loading' });
		setsalesManList({ type: 'Loading' });
		setFcList({ type: 'Loading' });

		dispatch(
			getFcListOnLoad({ fcIds: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [] })
		).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});

		dispatch(
			getStoreDataOnLoad({
				fc_ids: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [],
				storeIds: paginateConfig?.stores?.length ? paginateConfig?.stores : [],
			})
		).then((res) => setStoreList({ type: 'Success', data: toAsysnOption(res) }));

		dispatch(
			getSalesMenOnSearchList({
				fc_ids: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [],
			})
		).then((res) => setsalesManList({ type: 'Success', data: toAsysnOption(res) }));

		dispatch(getUserList({})).then((res) => setUserList(res));
		// dispatch(viewDAList()).then((res)=> setRowData)
	}, [dispatch]);

	useEffect(() => {
		if (rowSelect?.length > 99) {
			toaster.error('Max 100 invoices selection completed');
		}
	}, [rowSelect]);

	const notAllowedSoTagging = !rolesAllowed.includes(auth?.user.groups[0].name);

	const rowSelection = {
		onChange: (selectedRowKeys) => {
			setrowSelect([...selectedRowKeys]);
		},
		getCheckboxProps: (record) => {
			console.log(record?.status);
			const rowsID = record?.is_aged;
			console.log(rowsID);
			return {
				disabled: rowsID,
			};
		},
	};

	const handleRowData = (val) => {
		if (val) {
			setRowData(val);
			settotalRowData((prev) => uniq([...(prev || []), ...val.rows], (e) => e.id));
		}
	};

	const handleChangeSelect = (name, value) => {
		// as this is asyncselect we need to destructure value from the option value
		const getValue = isEmpty(value) ? [] : value.map((y) => y.value);
		setpaginateConfig((prev) => handleFilterChange(name, getValue, prev));
	};

	const handleFCBrandsChangeSelect = async (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value

		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		const fcValues = value?.value ? [value?.value] : [];
		await Promise.all([
			dispatch(getSaleStoreList({ q: '**', fc_ids: fcValues })),
			dispatch(getSalesMenOnSearchList({ fc_ids: fcValues })),
		]).then((res) => {
			const [storeRes, salesManRes] = res;
			setStoreList({ type: 'Success', data: toAsysnOption(storeRes) });
			setsalesManList({ type: 'Success', data: toAsysnOption(salesManRes) });
			setpaginateConfig((prev) => ({
				...handleFilterChange(name, fcValues, prev),
				brands: [],
				salesmen: [],
				stores: [],
			}));
			return true;
		});
	};

	const selectedFC = rowData?.rows
		?.filter((data) => rowSelect.find((id) => id === data.id))
		.map((d) => d.fc_id)
		.every((v, i, arr) => v === arr[0]);

	const handleMarkPodBills = () => {
		console.log('EcoResInside');
		dispatch(postMarkOrders('POD', rowSelect)).then((res) => {
			if (isEmpty(res?.message)) {
				toaster.info('POD Marked Sucessfully');
				window.location.reload();
			} else {
				console.log('RES', res?.message);
				setErrorMsg(res?.message);
				showModalError();
			}
		});
		// .catch((er) => {
		// 	// toaster.error(`${er}`);
		// 	showModalError();
		// 	console.log('thiserrr', er?.response?.data?.message);
		// 	console.log('thiserrr', er);
		// 	setErrorMsg(er?.response?.data?.message);
		// });
	};

	const columns = [
		{
			title: 'Sr No.',
			dataIndex: 'sr_no',
			key: 'sr_no',
			render: (_, rec, index) => <Box>{index + 1}</Box>,
		},
		{
			title: 'Invoice No.',
			// eslint-disable-next-line sonarjs/no-duplicate-string
			dataIndex: 'Invoice No',
			key: 'Invoice No',
		},
		{
			title: 'Status',
			dataIndex: 'Status',
			key: 'Status',
			render: (status) => {
				const statusObject = ORDER_STATUSES_ENUM.find((item) =>
					// eslint-disable-next-line no-prototype-builtins
					item.hasOwnProperty(status)
				);
				const displayStatus = statusObject ? statusObject[status] : 'Unknown Status';
				return <Box>{displayStatus}</Box>;
			},
		},
	];

	const mainFIlter = (ele) => {
		console.log(ele === '60+', 'handleFCBrandsChangeSelecthandleFCBrandsChangeSelect');
		// setpaginateConfig((prev) => handleSearch(ele, prev));
		if (ele === '62+') {
			setpaginateConfig((prev) => handleFilterChange('expired_days__gte', 62, prev));
			setpaginateConfig((prev) => handleFilterChange('expired_days__lte', 100, prev));
			setBtnClickSearh(Math.random());
			setBg_color(false);
		}
		if (ele === 'All') {
			setBg_color(true);
			console.log(paginateConfig);
			const pagData = omit(paginateConfig, ['expired_days__gte', 'expired_days__lte']);
			setpaginateConfig(pagData);
			setpaginateConfig((prev) => handleFilterChange('', '', prev));
			setBtnClickSearh(Math.random());
		}
	};

	return (
		<>
			<Flex>
				<LabelWrapper label="Search" marginspace="-1px 0 5px 0">
					<SearchField>
						<SearchBar
							placeholder="Search by invoice number"
							queryValue={paginateConfig?.query?.replaceAll('*', '')}
							onSearch={(val) => setpaginateConfig((prev) => handleSearch(val, prev))}
						/>
					</SearchField>
				</LabelWrapper>
				<Flex width="100%" flexWrap="wrap" alignItems="center">
					<LabelWrapper label="Salesmen(s)" marginspace="0 0 5px 1rem">
						<LoadAsyncSelectComponent
							loadFCs={paginateConfig?.salesmen}
							setList={(val) => setsalesManList(val)}
							options={salesManList}
						>
							<AsyncSelect
								style={CommonStyle}
								allowClear
								name="salesmen"
								mode="multiple"
								defaultValue={paginateConfig?.salesmen}
								value={paginateConfig?.salesmen}
								apiParams={{ fc_ids: paginateConfig?.fcs || [] }}
								fetchOptions={getSalesMenOnSearchList}
								placeholder="Salesmen(s)"
								onChange={(value) => handleChangeSelect('salesmen', value)}
								selectOptions={[...(salesManList?.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>

					<LabelWrapper label="Store(s)" marginspace="0 0 5px 1rem">
						<LoadAsyncSelectComponent
							loadFCs={paginateConfig?.stores}
							setList={(val) => setStoreList(val)}
							options={storeList}
						>
							<AsyncSelect
								style={CommonStyle}
								allowClear
								name="stores"
								mode="multiple"
								fetchOptions={getSaleStoreList}
								apiParams={{ fc_ids: paginateConfig?.fcs || [] }}
								placeholder="Store(s)"
								defaultValue={paginateConfig?.stores}
								value={paginateConfig?.stores}
								onChange={(value) => handleChangeSelect('stores', value)}
								selectOptions={[...(storeList.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>

					<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
						<LoadAsyncSelectComponent
							loadFCs={paginateConfig?.fcs}
							setList={(val) => setBrandList(val)}
							options={fcsList}
						>
							<AsyncSelect
								style={searchBox}
								allowClear
								name="fcs"
								showSearch
								fetchOptions={getFcOnSearchList}
								transformOptions={(val) => toAsysnOption(val)}
								defaultValue={paginateConfig?.fcs}
								value={paginateConfig?.fcs}
								placeholder="Select FC(s)"
								onChange={(value, options) => {
									handleFCBrandsChangeSelect('fcs', value, options);
								}}
								selectOptions={[...(fcsList.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>

					<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
						<Select
							name="fields"
							style={searchBox}
							allowClear
							showSearch
							label="brands"
							mode="multiple"
							placeholder="Select Brand(s)"
							value={paginateConfig?.brands}
							filterOption={SelectFilterLabelOptions}
							options={brandsList}
							onChange={(value) =>
								setpaginateConfig((prev) => ({
									...prev,
									brands: value,
									...initialPaginateConfigWithoutQ,
								}))
							}
						/>
					</LabelWrapper>

					<LabelWrapper label="Search Tag(s)" marginspace="0 0 5px 1rem">
						<SearchField width="200px" margin="0 1rem 1rem">
							<Input
								name="tag__like"
								placeholder="Search Tag(s)"
								value={paginateConfig?.tag__like}
								onChange={(e) =>
									setpaginateConfig((prev) =>
										handleFilterChange('tag__like', e?.target?.value, prev)
									)
								}
							/>
						</SearchField>
					</LabelWrapper>
					<LabelWrapper label="Search GST" marginspace="0 0 5px 1rem">
						<SearchField width="200px" margin="0 1rem 1rem">
							<Input
								name="gst_number"
								placeholder="Search GST"
								value={paginateConfig?.gst_number}
								onChange={(e) =>
									setpaginateConfig((prev) =>
										handleFilterChange('gst_number', e?.target?.value, prev)
									)
								}
							/>
						</SearchField>
					</LabelWrapper>

					<LabelWrapper
						label={`Redelivery in future(count : ${rowData?.rd_count || 0})`}
						marginspace="0 0 5px 1rem"
					>
						<Box width="200px" margin="0 1rem 1rem" minHeight="37px">
							<Switch
								onChange={(checked) => {
									setpaginateConfig((prev) =>
										handleFilterChange(
											'statuses',
											checked ? ['RD'] : ['PU', 'RD'],
											prev
										)
									);
								}}
							/>
						</Box>
					</LabelWrapper>
					<Button onClick={() => setBtnClickSearh(Math.random())}>Search</Button>
					<Button
						onClick={() =>
							navigate('/logistics-management/delivery-allocation/missing-invoices')
						}
					>
						Invoices Missing?
					</Button>
				</Flex>
			</Flex>

			{/* <BoxFlex>
				<AllBox
					onClick={() => mainFIlter('All')}
					style={{
						cursor: 'pointer',
					}}
					background={bg_color ? '#CA5010 ' : '#FFB68B'}
				>
					<Box> All </Box>
				</AllBox>
				<Box35
					style={{
						cursor: 'pointer',
					}}
					onClick={() => mainFIlter('35+')}
					background={!bg_color ? '#CA5010 ' : '#FFB68B'}
				>
					{' '}
					<Box>35+ days </Box>{' '}
				</Box35>
			</BoxFlex> */}

			<FlexWrap>
				<BoxFlex>
					<BoxInvAge>Invoice Age </BoxInvAge>
					<AllBox
						onClick={() => mainFIlter('All')}
						style={{
							cursor: 'pointer',
						}}
						background={bg_color ? '#CA5010 ' : '#FFB68B'}
					>
						<Box> All </Box>
					</AllBox>
					<Box35
						style={{
							cursor: 'pointer',
						}}
						onClick={() => mainFIlter('62+')}
						background={!bg_color ? '#CA5010 ' : '#FFB68B'}
					>
						{' '}
						<Box>60+ days </Box>{' '}
					</Box35>
				</BoxFlex>
				{!isEmpty(rowSelect) && (
					<>
						<Box>{rowSelect.length} items selected</Box>
						<Flex alignItems="center">
							<Button
								onClick={showModal}
								style={{ marginBottom: 16, background: '#FE5722' }}
								disabled={isEmpty(rowSelect) || notAllowedSoTagging}
							>
								Mark Pay On Delivery
							</Button>
							<Flex flexDirection="column" alignItems="center">
								<Button
									disabled={!selectedFC || rowSelect.length > 100}
									onClick={() => setModalVisible(true)}
								>
									Allocate Vehicle
								</Button>
								<Text fontSize="10px" mt="0.5rem" color="red">
									* Max 100 Invoices
								</Text>
							</Flex>
						</Flex>
					</>
				)}
			</FlexWrap>

			<Box mt={3}>
				<RCTable
					rowKey="id"
					tableDatas={[rowData, handleRowData]}
					rowSelection={{
						type: 'checkbox',
						preserveSelectedRowKeys: true,
						// hideSelectAll: rowSelect.length > 99,
						hideSelectAll: 100 - rowSelect.length <= (paginateConfig?.pageSize || 10),
						...rowSelection,
					}}
					dispatchAction={viewDAList}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					columns={deliveryAllocationCreateColumns({
						defaultSort: paginateConfig?.sort_direction || 'DESC',
						defaultsortField: paginateConfig?.sort_column || 'createdAt',
					})}
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'createdAt',
					}}
					rowClassName={(record) => {
						if (rowSelect?.length > 99) {
							return rowSelect?.includes(record.id) ? '' : 'disabled-div';
						}
						return '';
					}}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
			{modalVisible && (
				<VehicleAllocateModal
					userlist={userlist}
					toggleState={[modalVisible, setModalVisible]}
					orders={rowSelect}
					onClick={showModal}
					rowData={totalrowData}
					selectedItem={rowSelect}
				/>
			)}
			<Modal visible={isModalOpen} footer={null} closable={false}>
				<ConfirmationMessage style={{ margin: '20px' }}>
					Do you want to mark selected invoices as Pay On Delivery?
				</ConfirmationMessage>
				<Button
					style={{
						borderRadius: 4,
						background: '#E1F4FF',
						color: '#1B4965',
						marginRight: 10,
						marginLeft: 16,
					}}
					onClick={handleCancel}
				>
					No
				</Button>
				<Button
					style={{
						borderRadius: 4,
						background: '#1B4965',
						color: '#FFF',
					}}
					onClick={handleOk}
				>
					Yes
				</Button>
			</Modal>
			<Modal
				visible={modalErrorVisible}
				footer={null}
				// closable={false}
				onCancel={handleCancelError}
			>
				<ErrorText>Error</ErrorText>

				<ConfirmationMessage style={{ margin: '20px' }}>
					<Box style={{ fontSize: 18 }}>Failed to Update </Box>
					<Box style={{ marginTop: '16px', marginBottom: '16px' }}>
						{`Looks like some of the order's status is changed:`}
					</Box>
					{/* {errorMsg?.length > 0 &&
						errorMsg?.map((msg) => (
							<Box style={{ marginTop: '16px' }}>
								Invoice no: <span>{msg?.['Invoice No']} </span>
								<span> Status: {msg?.Status}</span>
							</Box>
						))} */}

					<Table pagination={false} dataSource={errorMsg} columns={columns} />
				</ConfirmationMessage>
			</Modal>
		</>
	);
}
