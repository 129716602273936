import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Row, Col } from 'antd';
import { uniqBy } from 'lodash';
import { DatePicker, Input, Select } from 'formik-antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { LoadAsyncSelectComponent } from 'components/common';
import { FormInput, AsyncSelect } from 'atoms';
import {
	dateFormat,
	disableFutureDate,
	SelectFilterLabelOptions,
	toAsysnOption,
	disableDateRangesMonth,
} from 'utils';
import { getFcOnSearchList } from 'store/masters';
import { getCollectionDetailsReport, getCollectionDetailsList } from 'store/downloads/reportSlice';
import { BtnWrap, StyledSubmitButton } from '../masters/helper';
import { getSaleStoreList } from 'store/fulfilment/salesOrderSlice';
import { FormSchema, getOptions } from './helpers';

const initialValues = {
	invoice_date__gte: undefined,
	invoice_date__lte: undefined,
	fcs: undefined,
	brands: undefined,
	stores: undefined,
	vehicle_no: undefined,
	brandsOptions: [],
	fcsHiddenOptions: [],
};

const CommonStyle = {
	width: '250px',
	alignSelf: 'center',
};
export default function SalesOrderProductFilters({ setShowModal }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [fcIds, setfcIds] = useState([]);
	const [storeList, setStoreList] = useState({ type: 'NotAsked' });
	const [startDate, setStartDate] = useState({});
	const [fcList, setFcList] = useState({ type: 'NotAsked' });

	useEffect(() => {
		setFcList({ type: 'Loading' });
		dispatch(getFcOnSearchList({})).then((res) =>
			setFcList({ type: 'Success', data: toAsysnOption(res) })
		);
	}, [dispatch]);

	useEffect(() => {
		setStoreList({ type: 'Loading' });
		dispatch(getSaleStoreList({ fc_ids: fcIds })).then((res) =>
			setStoreList({ type: 'Success', data: toAsysnOption(res) })
		);
	}, [dispatch, fcIds]);

	// on Select Event
	const handleSelect = (name, value, setFieldValue) => setFieldValue(name, value);

	const handleSubmit = (values, { resetForm, setSubmitting }) => {
		const data = {
			filter: {
				reportType: 'sales_order_invoice_product_wise',
				date_gte: dateFormat(values?.invoice_date__gte),
				date_lte: dateFormat(values?.invoice_date__lte),
				brands: values?.brands,
				fcs: getOptions(values?.fcs),
				stores: getOptions(values?.stores),
				vehicle_no: values?.vehicle_no,
			},
		};

		// delete key if length is 0
		if (data.filter?.brands?.length === 0) {
			delete data.filter?.brands;
		}
		if (data.filter?.fcs?.length === 0) {
			delete data.filter?.fcs;
		}
		if (data.filter?.stores?.length === 0) {
			delete data.filter?.stores;
		}
		if (data.filter?.vehicle_no === '') {
			delete data.filter?.vehicle_no;
		}

		// const msgKey = Math.random().toString();
		// const config = {
		// 	key: msgKey,
		// 	type: 'loading',
		// 	content: 'Downloading Reports Sales Order (Product Wise)',
		// 	duration: 0,
		// 	className: 'customDownloadMsg',
		// };
		// message.open(config);

		dispatch(getCollectionDetailsReport(data))
			.then(() => {
				resetForm();
				setShowModal(false);
				dispatch(getCollectionDetailsList({}));
			})
			.then(() => navigate('/downloads?query=&offset=0&limit=10&currentPage=1'))
			.finally(() => {
				setSubmitting(false);
			});
	};
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={FormSchema}
			enableReinitialize
			initialTouched={{
				fcs: true,
				brands: true,
			}}
		>
			{({ setFieldValue, values, isValid, dirty }) => (
				<Form layout="vertical">
					<Row gutter={24} justify="space-between">
						<Col span={6}>
							<FormInput name="invoice_date__gte" label="*From - Invoice Date">
								<DatePicker
									name="invoice_date__gte"
									className="form__field"
									disabledDate={disableFutureDate}
									onChange={(val) => {
										setStartDate(val);
										setFieldValue('invoice_date__lte', undefined);
									}}
									allowClear={false}
									showToday={false}
								/>
							</FormInput>
						</Col>

						<Col span={6}>
							<FormInput name="invoice_date__lte" label="*To - Invoice Date">
								<DatePicker
									name="invoice_date__lte"
									className="form__field"
									disabledDate={disableDateRangesMonth(startDate)}
									allowClear={false}
									showToday={false}
								/>
							</FormInput>
						</Col>

						<Col span={8}>
							<FormInput name="stores" label="Store(s)">
								<LoadAsyncSelectComponent options={storeList}>
									<AsyncSelect
										mode="multiple"
										showSearch
										name="stores"
										className="form__field"
										apiParams={{ fc_ids: fcIds }}
										component="formikSelect"
										placeholder="Store Type"
										onChange={(value) =>
											handleSelect('stores', value, setFieldValue)
										}
										filterOption={(input, option) =>
											option.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
										allowClear
										selectOptions={[...(storeList?.data || [])]}
										fetchOptions={getSaleStoreList}
									/>
								</LoadAsyncSelectComponent>
							</FormInput>
						</Col>
					</Row>
					<Row gutter={24} justify="space-between">
						<Col span={6}>
							<FormInput name="fcs" label="FC(s)">
								<LoadAsyncSelectComponent options={fcList}>
									<AsyncSelect
										mode="single"
										showSearch
										name="fcs"
										style={CommonStyle}
										component="formikSelect"
										onChange={(value, options) => {
											const fvalue = [value];
											const fcValues = fvalue?.map((x) => x.value);

											const oldOptions = values?.fcsHiddenOptions?.filter(
												(x) => fcValues.includes(x.value)
											);

											const combineOptions = uniqBy(
												[...oldOptions, ...options],
												(x) => x.value
											);

											setFieldValue('fcs', fvalue);
											setfcIds(fvalue?.map((x) => x?.value));
											setFieldValue('stores', undefined);
											setFieldValue('brands', undefined);
											setFieldValue('brandsOptions', combineOptions);
											setFieldValue('fcsHiddenOptions', combineOptions);
										}}
										placeholder="Fc Type"
										selectOptions={[...(fcList?.data || [])]}
										fetchOptions={getFcOnSearchList}
									/>
								</LoadAsyncSelectComponent>
							</FormInput>
						</Col>
						<Col span={6}>
							<FormInput name="brands" label="Brand(s)">
								<Select
									showSearch
									className="customDropdown"
									name="brands"
									mode="multiple"
									style={CommonStyle}
									placeholder="Select Brand"
									filterOption={SelectFilterLabelOptions}
									options={
										uniqBy(
											values?.brandsOptions
												?.filter((x) =>
													values?.fcs
														?.map((y) => y.value)
														.includes(x.value)
												)
												?.map((x) => x?.brands || [])
												?.flat(),
											(x) => x.value
										) || []
									}
								/>
							</FormInput>
						</Col>
						<Col span={8}>
							<FormInput name="vehicle_no" label="Vehicle No">
								<Input name="vehicle_no" className="form__field" />
							</FormInput>
						</Col>
					</Row>
					<BtnWrap>
						<StyledSubmitButton disabled={!dirty || !isValid}>
							Request Report
						</StyledSubmitButton>
					</BtnWrap>
				</Form>
			)}
		</Formik>
	);
}

SalesOrderProductFilters.propTypes = {
	setShowModal: PropTypes.func,
};
