/* eslint-disable eqeqeq */
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Text } from 'atoms';
import DateFormat from 'components/DateFormat';

const DateContainer = styled(Text)`
	color: #737373;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export const PackMasterColums = () => [
	{
		title: 'Brand',
		dataIndex: 'brand_code',
		key: 'brand_code',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (brand_code) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{brand_code}
			</Text>
		),
	},
	{
		title: 'Product Code',
		dataIndex: 'product_code',
		key: 'product_code',
		overflow: 'hidden',
		width: '15%',
	},
	{
		title: 'Product Name',
		dataIndex: 'product_name',
		key: 'product_name',
		sortId: 2,
		overflow: 'hidden',
		width: '20%',
		hidden: false,
		render: (product_name) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{product_name}
			</Text>
		),
	},
	{
		title: 'UOM',
		dataIndex: 'uom',
		key: 'uom',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
	},
	{
		title: 'UOM Description',
		dataIndex: 'uom_description',
		key: 'uom_description',
		sortId: 2,
		width: '10%',
		overflow: 'hidden',
		hidden: false,
		render: (uom_description) => (
			<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
				{uom_description}
			</Text>
		),
	},
	{
		title: 'Conversion Qty',
		dataIndex: 'conversion_qty',
		key: 'conversion_qty',
		sortId: 2,
		overflow: 'hidden',
		width: '10%',
		hidden: false,
		render: (conversion_qty) => (
			<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
				{conversion_qty}
			</Text>
		),
	},
	{
		title: 'Status',
		dataIndex: 'is_active',
		key: 'is_active',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (is_active) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{is_active == 1 ? 'Active' : 'Inactive'}
			</Text>
		),
	},
	{
		title: 'Created by',
		dataIndex: 'created_by',
		key: 'created_by',
		overflow: 'hidden',
		render: (_, record) => (
			<>
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.created_by}
				</Text>
				<DateContainer overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={record?.created_at} />
				</DateContainer>
			</>
		),
	},
	{
		title: '',
		dataIndex: 'id',
		key: 'id',
		sortId: 6,
		hidden: false,
		render: (pack_master_id) => (
			<Link
				style={{ color: '#000' }}
				to={`/onboarding/masters/packmaster/${pack_master_id}/edit`}
			>
				<EditOutlined />
			</Link>
		),
	},
];
