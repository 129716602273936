import { createSlice } from '@reduxjs/toolkit';
import { message as toaster } from 'antd';
import { uniq, isArray } from 'lodash';
import { API } from 'api';
import { unScReplace, fcBrands } from 'utils';

export function getFcs(fcs) {
	/**
	 * array first value is FC ID
	 */
	/**
	 * For salesman(12) single array only will come,
	 * so return first value of that array as FC id
	 */
	if (!isArray(fcs[0])) {
		return [fcs[0]];
	}
	return uniq(fcs?.map((fc) => fc?.[0]));
}

export function getBrands(fcs, state) {
	/**
	 * For salesman(12) single array only will come,
	 * so return first value of that array as FC id, second value as Brand Id
	 */
	if (!isArray(fcs[0])) {
		return [{ fc_id: fcs[0], brand_id: fcs[1] }];
	}

	const brands = [];
	/**
	 * array first value is FC ID,
	 * array second value is Brand ID
	 */
	fcs?.forEach((fc) => {
		const [fc_id, brand_id] = fc;
		if (fc?.length === 1) {
			const selected = state?.user?.fcBrandsList?.find((item) => item?.value === fc_id);
			selected?.children?.forEach((i) => {
				brands.push(i?.value);
			});
		} else {
			brands.push(brand_id);
		}
	});
	return brands;
}

const initialState = {
	salesmanList: {
		data: [],
		meta: {},
	},
	filteredSalesmanList: {
		data: [],
		meta: {},
	},
	userDashboard: {},
	userTypes: [],
	filteredSalesman: [
		{
			first_name: '',
			last_name: '',
			mobile_no: '',
		},
	],
	fcList: [],
	fcBrandsList: [],
};

function labelOption(data = {}) {
	return `${unScReplace(`${data?.first_name} ${data?.last_name}`)} - ${data?.mobile_no}`;
}
export const userSlice = createSlice({
	name: 'salesman', // name of the reducer
	initialState,
	reducers: {
		setUserList: (state, { payload = {} }) => {
			console.log(state.salesmanList, 'curstate');
			const filterList = payload?.data?.rows.map((data) => ({
				...data,
				filterLabel: labelOption(data),
			}));

			state.salesmanList.data = filterList;
		},
		setFilteredUserList: (state, { payload = {} }) => {
			const filterList = payload?.data?.rows?.map((data) => ({
				...data,
				filterLabel: labelOption(data),
			}));

			state.filteredSalesmanList.data = filterList;
		},
		setUserDashboard: (state, { payload = {} }) => {
			state.userDashboard = payload;
		},

		setUserTypes: (state, { payload }) => {
			state.userTypes = payload;
		},
		setFcList: (state, { payload }) => {
			console.log('fcpayload', payload);
			state.fcList = payload;
			state.fcBrandsList = fcBrands(payload);
		},
	},
});

export const getSalesmanList =
	(queryParams = {}) =>
	async (dispatch) => {
		const CREDENTIALS = {
			url: `champ/salesman/list`,
			method: 'post',
			queryParams,
		};

		return API.common(CREDENTIALS).then((response) => {
			dispatch(setUserList(response));
			return response;
		});
	};

export const getFilteredUserList = () => async (dispatch) => {
	const CREDENTIALS = {
		url: `champ/salesman/list`,
		method: 'post',
		data: {
			sort: {
				sort_column: 'id',
				sort_direction: 'DESC',
			},
			page: {
				limit: 10,
				offset: 0,
			},
		},
	};

	return API.common(CREDENTIALS).then((response) => {
		console.log('filres', response);
		dispatch(setFilteredUserList(response));
		return response;
	});
};

export const getUser = (id) => async () => {
	const CREDENTIALS = {
		url: `/users/${id}`,
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export const addUser = (values, setErrors) => async (dispatch, getState) => {
	let method = 'post';
	let url = 'champ/user/create';

	const findSalesman = getState().user.salesmanList.data.find(
		(item) => item.filterLabel === values.salesman_id
	);

	console.log(findSalesman, 'checksalesman');
	const data = {
		username: values?.email,
		first_name: values?.first_name,
		last_name: values?.last_name,
		mobile_no: values?.mobile_no?.toString(),
		password: values?.password || '',
		groups: [values?.user_types],
		fcs: values?.fcs || [],
		brands: values?.brands || [],
		is_superuser: false,
	};

	if (values?.user_id) {
		/**
		 * On Edit Mode
		 */
		method = 'put';
		url = `users/${values?.user_id}`;
		delete data?.password;
	}

	const CREDENTIALS = {
		url,
		method,
		data,
		setErrors,
	};

	return API.common(CREDENTIALS).then((response) => {
		toaster.success('User added successfully');
		return response;
	});
};

export const getUserTypes = () => async (dispatch) => {
	const CREDENTIALS = {
		url: 'champ/groups/list',
		method: 'post',
	};

	return API.common(CREDENTIALS).then((response) => {
		dispatch(setUserTypes(response.data));
		return response;
	});
};

export const getUserGroups = () => async () => {
	const CREDENTIALS = {
		url: 'champ/groups/list',
		method: 'post',
	};

	return API.common(CREDENTIALS).then((response) => response.data);
};

export const getFcList = () => async (dispatch) => {
	const CREDENTIALS = {
		url: 'champ/fcs/list',
		method: 'post',
	};

	return API.common(CREDENTIALS).then((response) => {
		dispatch(setFcList(response?.data?.rows));
		return response;
	});
};

export const changeUserStatus =
	({ user_id, status }) =>
	async (dispatch, getState) => {
		const {
			app: {
				tableConfig: { offset, limit, query },
			},
		} = getState();

		const CREDENTIALS = {
			url: '/users/update/status',
			method: 'put',
			data: {
				user_id,
				status,
			},
		};

		return API.common(CREDENTIALS).then((response) => {
			toaster.success(`User status updated as ${status ? 'Active' : 'Inactive'} `);
			dispatch(
				getSalesmanList({
					query,
					offset,
					limit,
				})
			);
			return response;
		});
	};

export const { setUserList, setFilteredUserList, setUserDashboard, setUserTypes, setFcList } =
	userSlice.actions;

export default userSlice.reducer;
