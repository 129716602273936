/* eslint-disable no-shadow */
import { useEffect } from 'react';

export const useOffClick = (refs, callback, visible = true) => {
	const handleClickOutside = (e) => {
		refs.map((ref) => {
			try {
				const elem = ref.current;
				if (!elem.contains(e.target)) {
					return callback(ref);
				}
			} catch (e) {
				return false;
			}
			return true;
		});
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		if (!visible) document.removeEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	});
};
