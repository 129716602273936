import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import { API } from 'api';

const initialState = {};
const champClientList = '/champ/client/list';

export const OnBoardSlice = createSlice({
	name: 'onboard',
	initialState,
	reducers: {},
});

export const getOnBoardStoreList =
	({
		limit = 10,
		offset = 0,
		sort = { sort_column: 'createdAt', sort_direction: 'DESC' },
		currentPage,
		query,
		fields,
		fc_ids,
		stores,
		...rest
	}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/store/list',
			data: {
				sort,
				filter: {
					q: `*${query}*`,
					fc_ids: fc_ids?.length ? fc_ids : undefined,
					stores: stores?.length ? stores : undefined,
					...rest,
				},
				page: { limit, offset },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getStoreById =
	({ storeId }) =>
	async () => {
		const CREDENTIALS = {
			method: 'get',
			url: `champ/store/${storeId}`,
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getStoreDataOnLoad =
	({ storeIds = [], fc_ids = [], client_ids = [] }) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/store/list',
			data: {
				filter: {
					q: '**',
					fc_ids: fc_ids?.length ? fc_ids : undefined,
					client_ids: client_ids?.length ? client_ids : undefined,
				},
				sort: { sort_column: 'createdAt', sort_direction: 'DESC' },
				page: { offset: 0, limit: 25 },
			},
		};

		const [storeDatabyIds, storeOptions] = await Promise.all([
			storeIds.length
				? API.common({
						...CREDENTIALS,
						data: {
							...CREDENTIALS.data,
							filter: { ...CREDENTIALS.data.filter, store_id: storeIds },
						},
				  })
				: Promise.resolve({}),
			API.common(CREDENTIALS),
		]);

		const removeEmpty = [
			...(storeOptions?.data?.rows || []),
			...(storeDatabyIds?.data?.rows || []),
		].filter((x) => !isEmpty(x));

		return {
			...storeOptions?.data,
			rows: uniqBy(removeEmpty, (x) => x.id),
		};
	};

export const StoreUpdateCreate =
	({ data }) =>
	async () => {
		let method = 'post';
		let url = '/champ/store/create';
		let toastMsg = 'Store Created Successfully';

		if ('id' in data) {
			method = 'put';
			url = '/champ/store/update';
			toastMsg = 'Store updated Successfully';
		}

		const CREDENTIALS = {
			method,
			url,
			data,
		};
		return API.common(CREDENTIALS).then((response) => {
			message.info(toastMsg);
			return response;
		});
	};

export const getCompanyList =
	({ offset, limit, sort_column = 'createdAt', sort_direction = 'DESC', query, fields }) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: '/champ/company/list',
			data: {
				filter: { q: `*${query || ''}*`, ...(fields ? { fields: [fields] } : {}) },
				sort: { sort_column, sort_direction },
				page: { offset, limit },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getCompanyOnSearchList =
	({ q = '**' }) =>
	async () => {
		const CREDENTIALS = {
			url: '/champ/company/list',
			method: 'post',
			data: {
				filter: {
					q: q || '**',
				},
				page: {
					limit: 25,
					offset: 0,
				},
				sort: {
					sort_column: 'id',
					sort_direction: 'DESC',
				},
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getClientList =
	({ offset, limit, sort_column = 'createdAt', sort_direction = 'DESC', query, fields }) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: champClientList,
			data: {
				filter: { q: `*${query}*` || '**', ...(fields ? { fields: [fields] } : {}) },
				sort: { sort_column, sort_direction },
				page: { offset, limit },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getClientListonSearch =
	({ q }) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: champClientList,
			data: {
				filter: { q: q || '**' },
				sort: { sort_column: 'id', sort_direction: 'DESC' },
				page: { offset: 0, limit: 25 },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getClientDataOnLoad =
	({ clientId }) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: champClientList,
			data: {
				filter: { q: '**' },
				sort: { sort_column: 'id', sort_direction: 'DESC' },
				page: { offset: 0, limit: 25 },
			},
		};

		const [clientData, clientOptions] = await Promise.all([
			clientId
				? API.common({
						method: 'get',
						url: `/champ/client/${clientId}`,
				  })
				: Promise.resolve({}),
			API.common(CREDENTIALS),
		]);

		const removeEmpty = [...(clientOptions?.data?.rows || []), clientData?.data].filter(
			(x) => !isEmpty(x)
		);

		return {
			...clientOptions?.data,
			rows: uniqBy(removeEmpty, (x) => x.id),
		};
	};

export const CreateUpdateClient =
	({ data, setErrors = {} }) =>
	async () => {
		let method = 'post';
		let url = '/champ/client/create';
		let toastMsg = 'Client data created successfully';

		if ('id' in data) {
			method = 'put';
			url = '/champ/client/update';
			toastMsg = 'Client data updated successfully';
		}

		const CREDENTIALS = {
			method,
			url,
			data,
			setErrors,
		};
		return API.common(CREDENTIALS).then((res) => {
			message.info(toastMsg);
			return res;
		});
	};

export const getClientId =
	({ clientId }) =>
	async () => {
		const CREDENTIALS = {
			method: 'get',
			url: `/champ/client/${clientId}`,
		};
		return API.common(CREDENTIALS).then((res) => res);
	};

export const getCompanyById =
	({ companyId }) =>
	async () => {
		const CREDENTIALS = {
			method: 'get',
			url: `/champ/company/${companyId}`,
		};
		return API.common(CREDENTIALS).then((res) => res);
	};

export const CreateUpdateCompany =
	({ data, setErrors = {} }) =>
	async () => {
		let url = '/champ/company/create';
		let method = 'post';
		let toasterMessage = 'Company data created successfully';

		if ('id' in data) {
			url = '/champ/company/update';
			method = 'put';
			toasterMessage = 'Company data updated successfully';
		}

		const CREDENTIALS = {
			method,
			url,
			data,
			setErrors,
		};

		console.log(data);
		return API.common(CREDENTIALS).then((res) => {
			message.info(toasterMessage);
			return res;
		});
	};

export default OnBoardSlice.reducer;
