/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Table, Upload, Radio, Checkbox, Input, message } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { debounce, isEmpty } from 'lodash';
import { Box, Button, Flex, Text } from 'atoms';
import { Rupee } from 'components';
import { AttachmentSvg, getBase64, maxFileSize } from 'utils';
import {
	getChequeListForSettlement,
	ChequeSettlementInsert,
	getPrintCheques,
	getBanksList,
} from 'store/invoiceSlice';
import { ReasonModal } from './ReasonModal';

// const InputSearch = styled(Input.Search)`
// 	input {
// 		background: #fdfdfd;
// 		min-height: 40px;
// 		width: 100%;
// 		&:placeholder {
// 			color: #646464;
// 		}
// 		&:focus,
// 		&:hover {
// 			border: 0;
// 			box-shadow: none;
// 			outline: none;
// 		}
// 	}
// 	.ant-input-search-button {
// 		background: #fdfdfd;
// 		border: 0;
// 		min-height: 40px;
// 		box-shadow: none;
// 	}
// `;

const CommonButtons = styled(Button)`
	font-size: 12px;
	border-radius: 4px;
	line-height: 15px;
	align-self: center;
	height: 35px;
`;

const UploadWrap = styled(Upload)`
	display: flex;
	width: 100%;
	cursor: pointer;
	background: #f6f5f5;
	min-height: 35px;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 5px;
	margin-bottom: 8px;
	.ant-upload-list-item {
		min-height: 30px;
	}
	.ant-upload-list-item-name {
		word-break: break-all;
		white-space: normal;
		display: flex;
		line-height: 14px;
	}
`;

// function getIds(list = [], type = 'Regular') {
// 	return (
// 		list
// 			?.filter((item) => item?.collection_type === type)
// 			?.map((x) => x.payment_ids)
// 			?.join(',') || 0
// 	);
// }

const TableConfigs = ({ handleCheckbox }) => [
	{
		title: (
			<Box textAlign="center" fontSize="12px" color="#646464">
				Cheque number
			</Box>
		),
		dataIndex: 'referenceNumber',
		key: 'referenceNumber',
		render: (cheque_number) => (
			<Box textAlign="center" color="#000">
				{cheque_number}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontSize="12px" color="#646464">
				Bank
			</Box>
		),
		dataIndex: 'bankName',
		key: 'bankName',
		render: (bankName) => (
			<Box textAlign="center" color="#000">
				{bankName || ''}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontSize="12px" color="#646464">
				Amount
			</Box>
		),
		dataIndex: 'amount',
		key: 'amount',
		render: (amount) => (
			<Box textAlign="center" color="#000">
				<Rupee>{amount || 0}</Rupee>
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontSize="12px" color="#646464">
				Due Date
			</Box>
		),
		dataIndex: 'dueDate',
		key: 'dueDate',
		render: (dueDate) => (
			<Box textAlign="center" color="#000">
				{dueDate ? moment(dueDate).format('DD/MM/YYYY') : ''}
			</Box>
		),
	},
	// {
	// 	title: (
	// 		<Box textAlign="center" fontSize="12px" color="#646464">
	// 			Type
	// 		</Box>
	// 	),
	// 	dataIndex: 'collection_type',
	// 	key: 'collection_type',
	// 	render: (collection_type) => (
	// 		<Text textAlign="center" color="#000">
	// 			{collection_type}
	// 		</Text>
	// 	),
	// },
	{
		title: (
			<Box textAlign="center" fontSize="12px" color="#646464">
				Select
			</Box>
		),
		dataIndex: 'key',
		key: 'key',
		render: (key, rec) => (
			<Box textAlign="center" color="#000">
				<Checkbox
					onChange={({ target: { checked } }) => handleCheckbox(checked, key, rec)}
					defaultChecked={false}
					disabled={rec.hasDisabled}
					checked={rec.hasChecked}
				/>
			</Box>
		),
	},
];

export const getHandleupload = (info) => {
	const nextState = {};

	switch (info?.file?.status) {
		case 'uploading':
			nextState.selectedFileList = [info.file];
			break;
		case 'done':
			nextState.selectedFileList = [info.file];
			break;
		default:
			// error or removed
			nextState.selectedFileList = [];
	}

	return nextState?.selectedFileList;
};

export function getTransactions(list = [], collectionType = 'Regular') {
	/**
	 * From cheque list only filter the checked cheques
	 */
	return list
		?.filter((x) => x?.hasChecked === true && x?.collection_type === collectionType)
		.map((item) => ({
			payment_checked: true,
			payment_ids: item?.payment_ids,
			invoice_ids: item?.invoice_ids,
			collection_type: collectionType,
		}));
}

const CHEQUE_MAX_COUNT = 100;
export function SettleCheque({ toggleModal = [], amountDetials, asyncCall }) {
	const dispatch = useDispatch();
	const {
		app: { loading },
	} = useSelector((state) => state);

	const _containerRef = useRef();
	const [showModal, setShowModal] = toggleModal;
	const [innerModal, setInnerModal] = useState(false);
	const [disablebtn, setDisablebtn] = useState(false);
	const [resonModal, setReasonModal] = useState(false);
	const [chequeList, setChequeList] = useState([]);
	const [bankname, setBankname] = useState('');
	const [chequeCount, setchequeCount] = useState(0);
	const [prevY, setPrevY] = useState(0);
	const [pageoffset, setOffset] = useState(0);
	const [selectedFileList, setSelectedFileList] = useState([]);
	const [preserveSelected, setPreserveSelected] = useState([]);
	const [searchValue, setSearchValue] = useState();
	const { banks = [] } = useSelector((state) => state.invoice);
	const hasCheckedCount = chequeList.filter((x) => x.hasChecked).length;

	const AysnCallOnLoad = (q = '', offset = 0, selectedCheques = []) => {
		console.log('AsyncCOL', offset, prevY, pageoffset);
		setPrevY(0);
		const filterRefNos = selectedCheques.map((x) => x.referenceNumber);

		dispatch(getChequeListForSettlement({ q: q || '', offset })).then((res) => {
			const updatedResponse = res?.data?.chequelistSettlement?.map((x) => ({
				...x,
				key: `${x?.key}-${new Date().getMilliseconds()}`,
				hasChecked: filterRefNos.includes(x.referenceNumber),
				hasDisabled: hasCheckedCount >= CHEQUE_MAX_COUNT,
			}));
			setchequeCount(res?.count);

			return setChequeList(() => {
				if (q) {
					return [...chequeList, ...updatedResponse];
				}

				const filterSameCheque = [...chequeList, ...updatedResponse].filter(
					(x) => !filterRefNos.includes(x.referenceNumber)
				);

				return [...selectedCheques, ...filterSameCheque];
			});
		});
	};

	useEffect(() => {
		AysnCallOnLoad();
		dispatch(getBanksList());
	}, [dispatch]);

	const handleSearchChequeNumber = useMemo(
		() =>
			debounce((event, selectedRefNos) => {
				AysnCallOnLoad(event, 0, selectedRefNos);
			}, 500),
		[dispatch]
	);

	function onClickSearch() {
		if (searchValue) {
			return handleSearchChequeNumber(searchValue, preserveSelected);
		}
		return handleSearchChequeNumber('', preserveSelected);
	}

	const options = useMemo(
		() => ({
			root: null,
			rootMargin: '0px',
			threshold: 0.1,
		}),
		[]
	);

	useEffect(() => {
		let currenRef = _containerRef?.current;

		// used IntersectionObserver to track the visiblity of the containerRef Element
		const observer = new IntersectionObserver((entities) => {
			const currentY = entities[0].boundingClientRect.y;
			console.log(currentY, prevY);

			// Checking cheque list length should not equal to total chequeCount
			// and also this request should fire only once, so used !loading
			if (currentY < prevY + 50 && chequeList.length !== chequeCount && !loading) {
				AysnCallOnLoad('', pageoffset + 50, preserveSelected);
				setOffset(pageoffset + 50);
			}

			setPrevY(currentY);
		}, options);

		if (_containerRef.current) {
			observer.observe(_containerRef?.current);
		}

		return () => {
			if (currenRef) {
				observer.unobserve(currenRef);
				currenRef = null;
			}
		};
	}, [options, dispatch, prevY]);

	const handleOk = () => {
		setShowModal(false);
	};

	const handleCancel = () => {
		setShowModal(false);
	};

	const dummyRequest = ({ onSuccess }) => {
		setTimeout(() => {
			onSuccess('ok');
		}, 0);
	};

	const handleupload = (info) => {
		setSelectedFileList(getHandleupload(info));
	};

	const handleCheckbox = (val, key, refObj) => {
		// store the selected cheque over here
		setPreserveSelected((prev) => {
			let selectedCheques = [...prev];
			const checkChequeExists = prev.filter((x) => x.key === key);

			if (!checkChequeExists.length && val) {
				selectedCheques.push({ ...refObj, hasChecked: true });
			} else if (checkChequeExists.length && !val) {
				selectedCheques = selectedCheques.filter((x) => x.key !== key);
			}

			return selectedCheques;
		});

		// +1 / -1 based on the value to get the latest count
		if (hasCheckedCount + (val ? 1 : -1) >= CHEQUE_MAX_COUNT) {
			return setChequeList((prev) =>
				prev?.map((x) => {
					if (x.key === key) {
						return { ...x, hasChecked: val };
					}
					return { ...x, hasDisabled: !x.hasChecked };
				})
			);
		}

		return setChequeList((prev) =>
			prev?.map((x) =>
				x.key === key ? { ...x, hasChecked: val } : { ...x, hasDisabled: false }
			)
		);
	};

	const handleSettle = async (settleIt, reasons = '') => {
		/**
		 * From cheque list only filter the checked cheques
		 */
		// const transactions = [
		// 	...getTransactions(chequeList, 'Regular'),
		// 	...getTransactions(chequeList, 'Adhoc'),
		// ];

		// const filterDataChecked = chequeList
		// 	.filter((x) => x.hasChecked)
		// 	.map((x) => ({
		// 		payment_checked: true,
		// 		payment_ids: x.payment_ids,
		// 		invoice_ids: x.invoice_ids,
		// 	}));

		const filterDataChecked = preserveSelected.map((x) => ({
			payment_checked: true,
			payment_ids: x.payment_ids,
			invoice_ids: x.invoice_ids,
		}));

		await getBase64(selectedFileList?.[0]?.originFileObj).then((res) => {
			const payload = {
				reason: reasons,
				settled_bank: bankname,
				challan_copy_attachment: res,
				transactions: [...filterDataChecked],
			};

			dispatch(ChequeSettlementInsert(payload))
				.then(() => {
					handleOk();
					asyncCall();
					message.info('Cheque settlement Successfull');
				})
				.finally(() => setDisablebtn(false));
		});
	};

	const handlePrint = (printIt) => {
		// const filterDataChecked = chequeList.filter((x) => x.hasChecked);
		if (chequeList.length === preserveSelected.length || printIt) {
			// const regular = getIds(filterDataChecked, 'Regular');
			// const adhoc = getIds(filterDataChecked, 'Adhoc');

			// setInnerModal(false);
			// return dispatch(getPrintCheques(regular, adhoc, bankname));

			// const payload = filterDataChecked.map((x) => x.payment_ids).join(',');
			const payload = preserveSelected.map((x) => x.payment_ids).join(',');
			setInnerModal(false);
			return dispatch(getPrintCheques(payload, bankname));
		}
		return setInnerModal(true);
	};

	return (
		<>
			<Modal
				centered
				width="55%"
				visible={showModal}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={false}
				maskClosable={false}
				className="settleCash"
			>
				<Box>
					<Box padding="16px 24px" background="#352A35">
						<Flex>
							<Box flex="0 0 25%">
								<Box
									fontWeight="300"
									fontSize="12px"
									lineHeight="24px"
									color="#FDFCFC"
								>
									Cheque
								</Box>
								<Box fontSize="14px" lineHeight="18px" color="#FDFCFC">
									{amountDetials?.total_unsettled} (
									{amountDetials?.total_unsettle_cheque_count})
								</Box>
							</Box>
							<Flex flex="0 0 40%">
								<Input
									placeholder="Cheque number"
									allowClear
									pattern="[0-9]*"
									inputMode="numeric"
									maxLength={6}
									onChange={(event) => setSearchValue(event.target.value)}
								/>
								<Button type="button" onClick={onClickSearch}>
									Search
								</Button>
							</Flex>
						</Flex>
					</Box>
					<Box padding="5px 16px 20px" height="500px" overflowY="auto">
						<Box height="550px">
							<Table
								rowKey="keys"
								columns={TableConfigs({
									handleCheckbox,
								})}
								dataSource={[...chequeList] || []}
								pagination={false}
							/>
							<Box textAlign="center" height="50px" ref={_containerRef}>
								{chequeCount === chequeList.length
									? `Total ${chequeCount} cheques are Loaded`
									: `To load more cheques, scroll from top`}
							</Box>
						</Box>
					</Box>
					<Flex padding="5px 50px 20px" justifyContent="space-between">
						<Flex>
							<Radio.Group
								onChange={({ target }) => setBankname(target?.value)}
								name="bankname"
								value={bankname}
							>
								{banks?.map((bank) => (
									<Radio key={bank?.id} value={bank?.id}>
										{bank?.bank_name}
									</Radio>
								))}
							</Radio.Group>
						</Flex>
						<Flex flexDirection="column" alignItems="center">
							<Button
								backgroundColor="#0094FF"
								borderRadius="4px"
								fontSize="12px"
								lineHeight="15px"
								color="#FFFFFF"
								onClick={() => handlePrint(false)}
								// disabled={chequeList?.filter((x) => x.hasChecked)?.length === 0}
								disabled={
									hasCheckedCount === 0 ||
									hasCheckedCount > CHEQUE_MAX_COUNT ||
									!bankname
								}
							>
								Print
							</Button>
							{chequeList?.filter((x) => x.hasChecked)?.length > CHEQUE_MAX_COUNT && (
								<Text textAlign="center" color="red" fontSize="10px" pt=".5rem">
									No more than {CHEQUE_MAX_COUNT} cheques allowed
								</Text>
							)}
						</Flex>
					</Flex>
					<Flex padding="5px 50px 30px" justifyContent="space-between">
						<Box minWidth="286px" className="uploadChallen">
							<Text
								color="#000000"
								fontSize="12px"
								lineHeight="15px"
								margin="0 0 8px"
							>
								Upload Challan copy
							</Text>
							<UploadWrap
								fileList={selectedFileList}
								customRequest={dummyRequest}
								onChange={handleupload}
								beforeUpload={maxFileSize}
								onRemove={false}
								accept="application/pdf,image/jpeg,image/png"
							>
								<Flex
									width="100%"
									justifyContent="space-between"
									padding="0 10px"
									color="#626262"
									fontSize="12px"
									lineHeight="15px"
								>
									<Box>Upload files</Box>
									<Box>
										<AttachmentSvg />
									</Box>
								</Flex>
							</UploadWrap>
							<Box
								textAlign="center"
								fontSize="12px"
								lineHeight="15px"
								color="#4B4B4B"
							>
								Supported format: PDF, JPEG, PNG, JPG
							</Box>
						</Box>
						<CommonButtons
							backgroundColor="#CA5010"
							color="#fff"
							onClick={() => handleSettle(false)}
							disabled={
								!bankname ||
								isEmpty(selectedFileList) ||
								hasCheckedCount === 0 ||
								hasCheckedCount > CHEQUE_MAX_COUNT
							}
						>
							Settle
						</CommonButtons>
					</Flex>
				</Box>
			</Modal>
			<Modal
				centered
				width="35%"
				visible={innerModal}
				onOk={() => setInnerModal(false)}
				onCancel={() => setInnerModal(false)}
				footer={false}
				maskClosable={false}
			>
				<Box padding="5px 0 0" textAlign="center">
					<Box fontSize="14px" lineHeight="18px" marginBottom="10px">
						Few cheques are not selected yet!
					</Box>
					<Box fontSize="12px" lineHeight="15px" marginBottom="20px">
						Do you want to continue
					</Box>
					<Flex justifyContent="center">
						<CommonButtons onClick={() => handlePrint(true)}>Print</CommonButtons>
						<CommonButtons onClick={() => setInnerModal(false)}>Cancel</CommonButtons>
					</Flex>
				</Box>
			</Modal>
			{resonModal && (
				<ReasonModal
					toggleModal={[resonModal, setReasonModal]}
					handleSettle={handleSettle}
					toggleDisable={[disablebtn]}
				/>
			)}
		</>
	);
}

SettleCheque.propTypes = {
	toggleModal: PropTypes.array,
	amountDetials: PropTypes.object,
	asyncCall: PropTypes.func,
};
