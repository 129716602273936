import React, { useEffect } from 'react';
import lottie from 'lottie-web';
import { useDispatch, useSelector } from 'react-redux';
import loader from 'assets/vehicle-loader.json';
import { hideLoader } from 'store/appSlice';

// https://www.freecodecamp.org/news/how-to-animate-react-apps/

export default function Loader() {
	const { loading = false } = useSelector((state) => state.app);
	const dispatch = useDispatch();

	useEffect(() => {
		lottie.loadAnimation({
			container: document.querySelector('#loader'),
			animationData: loader,
			renderer: 'svg', // "canvas", "html"
			loop: true, // boolean
			autoplay: true, // boolean
		});

		return () => {
			dispatch(hideLoader());
		};
	}, [dispatch]);
	return <div id="loader" className={loading ? 'show' : 'hide'} />;
}
