import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Box, Button, Image } from 'atoms';
import Alert from 'assets/Alert.svg';
import { changeUserStatus } from 'store/userSlice';

const StyledModal = styled(Modal)`
	.ant-modal-content {
		width: 50%;
		margin: 0 auto;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1.4rem;
	width: 100%;

	button {
		width: 80%;
	}
`;

export default function UserStatusModal({ modalVisible, modalClose, status = '', userId }) {
	const dispatch = useDispatch();

	const changeStatus = (value) => {
		const data = {
			user_id: userId,
			status: value,
		};
		dispatch(changeUserStatus(data)).then(() => {
			modalClose();
		});
	};

	return (
		<StyledModal centered visible={modalVisible} footer={null} onCancel={modalClose}>
			<Box textAlign="center" cursor="pointer">
				<Image src={Alert} alt="alert" />
			</Box>

			<Box mt="3" textAlign="center">
				<h1>
					{' '}
					{`Do you want to make the user ${status === 'Active' ? 'Inactive' : 'Active'}`}
				</h1>
			</Box>
			<ButtonContainer>
				<Button variant="primary" onClick={() => changeStatus(status === 'Active' ? 0 : 1)}>
					{status === 'Active' ? 'Inactive' : 'Active'}
				</Button>

				<Button variant="secondary" onClick={modalClose}>
					{' '}
					Cancel
				</Button>
			</ButtonContainer>
		</StyledModal>
	);
}

UserStatusModal.propTypes = {
	modalVisible: PropTypes.bool,
	modalClose: PropTypes.func,
	status: PropTypes.string,
	userId: PropTypes.number,
};
