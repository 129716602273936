import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { Text, FormInput, CustomInput, Box, AsyncSelect } from 'atoms';
import { LoadAsyncSelectComponent } from 'components/common';
import { getSalesmanById, getBrandsSearchList, CreateUpdateSalesMan } from 'store/masters';
import { SalesManFormSchema } from './Validations';
import { StyledSubmitButton, SalesmanInitialValues } from './helper';
import { Debug } from 'components/forms';

const transformOptions = (data) =>
	uniqBy(
		data?.rows
			?.map((x) =>
				x?.fc?.map((y) => ({
					value: `${x.id}_${y.id}`,
					label: `${y?.code || ''}: ${x?.name || ''}`,
				}))
			)
			.flat(),
		(x) => x.value
	);

function AddSalesmanForm() {
	const { id: salesmanId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [formInitial, setFormInitial] = useState({});
	const [brandsList, setbrandsList] = useState({ type: 'NotAsked' });
	const editMode = pathname.includes('edit');

	console.log('salesmanId', salesmanId);

	useEffect(() => {
		setbrandsList({ type: 'Loading' });

		const hasEditpage = editMode
			? dispatch(getSalesmanById({ salesmanId }))
			: Promise.resolve({});

		hasEditpage.then((res) => {
			console.log('res resres ', res);
			dispatch(getBrandsSearchList({})).then((brandList) => {
				if (editMode) {
					const formbrandsFcs = {
						value: `${res?.Brand?.id}_${res?.FC?.id}`,
						label: `${res?.Brand?.name}:${res?.FC?.name}`,
					};

					const mergeBrandLists = uniqBy(
						[formbrandsFcs, ...transformOptions(brandList)],
						(x) => x.value
					);
					setFormInitial(SalesmanInitialValues(res, true, mergeBrandLists));
					setbrandsList({ type: 'Success', data: mergeBrandLists });
				} else {
					setbrandsList({ type: 'Success', data: transformOptions(brandList) });
					setFormInitial(SalesmanInitialValues());
				}
			});
		});
	}, [dispatch, editMode, salesmanId]);

	function handleSubmit(values, { setErrors, resetForm, setSubmitting }) {
		const brandIds = values?.brand_id?.value?.split('_')?.[0];
		const getFCsIds = values?.brand_id?.value?.split('_')?.[1];

		const postValues = {
			...values,
			fc_id: +getFCsIds,
			brand_id: +brandIds,
			...(editMode ? { user_id: salesmanId } : {}),
		};

		dispatch(CreateUpdateSalesMan({ data: postValues, setErrors, salesmanId }))
			.then(() => {
				setSubmitting(false);
				resetForm();
				navigate('/onboarding/salesman');
			})
			.catch(() => {
				setSubmitting(false);
			});
	}

	return (
		<Formik
			initialValues={formInitial}
			validationSchema={SalesManFormSchema(editMode)}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ values, setFieldValue, isSubmitting, isValid, dirty }) => (
				<Form layout="vertical">
					<Text fontSize="14px" fontWeight="700">
						{editMode ? 'Edit' : 'Add'} Salesman Details
					</Text>

					<Row className="user__form" gutter={16} justify="flex-start">
						<Col lg={8} sm={24}>
							<FormInput name="name" label="*Name" className="test-first-name">
								<CustomInput placeholder="Enter Name" name="name" />
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput name="code" label="*Code" className="test-last-name">
								<CustomInput placeholder="Enter Code" name="code" />
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput
								name="mobile"
								label="*Phone Number"
								className="test-mobile-number"
							>
								<CustomInput placeholder="Enter Phone Number" name="mobile" />
							</FormInput>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col lg={8}>
							<FormInput name="fcs" label="*FC:Brands(s)">
								<LoadAsyncSelectComponent options={brandsList}>
									<AsyncSelect
										showSearch
										name="brand_id"
										component="formikSelect"
										onChange={(val) => {
											console.log(val, 'asdasdads');
											setFieldValue('brand_id', val);
										}}
										placeholder="Select Brands"
										defaultValue={
											brandsList?.data?.filter(
												(x) => x?.value === values?.brand_id
											) || undefined
										}
										selectOptions={[...(brandsList?.data || [])]}
										fetchOptions={getBrandsSearchList}
										transformOptions={(val) => transformOptions(val)}
									/>
								</LoadAsyncSelectComponent>
							</FormInput>
						</Col>
					</Row>

					<Box>
						<StyledSubmitButton
							disabled={isSubmitting || !isValid || !dirty}
							id="add-user-submit"
						>
							<Box>{editMode ? 'Update' : 'Save'}</Box>
						</StyledSubmitButton>
					</Box>
					{process.env.NODE_ENV === 'development' && <Debug />}
				</Form>
			)}
		</Formik>
	);
}

export default AddSalesmanForm;
