/* eslint-disable sonarjs/no-identical-functions */
import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import { Modal, Select } from 'antd';
import PropTypes from 'prop-types';
import { Box } from 'atoms';
import { UPLOAD_FILE_TYPE_CHOICE, SelectFilterLabelOptions } from 'utils';
import { LabelWrapper } from 'components/common';
import { UploadPageForm } from 'components/forms';

// no need code
// in this page, we have removed brands list api and old component, hereafter based on fc selection brands change added
// after this current feature release if its working find later we can remove the api and component
// 07-07-2022

export function UploadPageModal({ toggleState, handleTogglePaginate }) {
	const [uploadType, setUploadType] = useState('');
	const [modalVisible, setModalVisible] = toggleState;

	const handleFilterChange = (selected) => {
		setUploadType(selected);
	};

	return (
		<Modal
			destroyOnClose
			title="Upload Excel"
			visible={modalVisible}
			onOk={() => setModalVisible(false)}
			onCancel={() => setModalVisible(false)}
			footer={null}
			width="60%"
			maskClosable={false}
		>
			<Box>
				<LabelWrapper label="Upload Type" marginspace="0px 0 3px 0">
					<Select
						style={{ width: '200px', marginBottom: '1.5rem' }}
						showSearch
						placeholder="Upload Type"
						options={UPLOAD_FILE_TYPE_CHOICE.map((x) => ({
							label: x.name,
							value: x.id,
						}))}
						filterOption={SelectFilterLabelOptions}
						onChange={handleFilterChange}
					/>
				</LabelWrapper>
				<UploadPageForm
					toggleState={toggleState}
					toggleuploadType={[uploadType, setUploadType]}
					handleTogglePaginate={handleTogglePaginate}
				/>
			</Box>
		</Modal>
	);
}

UploadPageModal.propTypes = {
	toggleState: PropTypes.array,
	handleTogglePaginate: PropTypes.func,
};
