import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Flex, Text } from 'atoms';
import { useURL } from 'hooks';

const links = [
	{ id: 1, number: 1, title: 'Cash verification', path: 'cash-verify', type: 'Cash' },
	{ id: 2, number: 2, title: 'Cheque verification', path: 'cheque-verify', type: 'Cheque' },
	{ id: 3, number: 3, title: 'UPI', path: 'upi-verify', type: 'UPI' },
	{ id: 4, number: 4, title: 'NEFT', path: 'neft-verify', type: 'NEFT' },
];

export default function CashierTopStatusTabs() {
	const navigate = useNavigate();
	const urlData = useURL();
	const location = useLocation();
	const { cashierPersonHeaderDetails = {} } = useSelector((state) => state.invoice);

	function handleNavigation(item) {
		navigate({
			pathname: `/cashier/${item?.path}`,
			search: urlData?.queryString,
		});
	}

	return (
		<Container>
			{links?.map((item, i) => (
				<Flex key={item?.id} alignItems="center">
					<Box onClick={() => handleNavigation(item)}>
						<Round
							isActive={
								location?.pathname === `/cashier/${item?.path}` ||
								cashierPersonHeaderDetails?.data_summary
									?.payment_verification_status?.[item?.type]
							}
							isSelected={location?.pathname === `/cashier/${item?.path}`}
						>
							{item?.number}
						</Round>
						<Title>{item?.title}</Title>
					</Box>
					{i !== links.length - 1 && <Line />}
				</Flex>
			))}
		</Container>
	);
}

const Container = styled(Flex)`
	justify-content: center;
	align-items: center;
	margin-bottom: 2rem;
`;

const Box = styled(Flex)`
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const Round = styled(Flex)`
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px solid ${(props) => props.theme.colors.SlimyGreen};
	font-weight: 600;
	font-size: 18px;
	line-height: 40px;

	${({ isActive }) =>
		isActive &&
		css`
			color: ${(props) => props.theme.colors.white};
			background-color: ${(props) => props.theme.colors.SlimyGreen};
		`}

	${({ isSelected }) =>
		isSelected &&
		css`
			background-color: ${(props) => props.theme.colors.CarrotOrange};
			border: none;
		`}
`;

const Title = styled(Text)`
	font-weight: 350;
	font-size: 12px;
	margin-top: 0.5rem;
`;

const Line = styled(Box)`
	width: 175px;
	border-top: 1px solid ${(props) => props.theme.colors.SlimyGreen};
`;
