import React from 'react';
import PropTypes from 'prop-types';
import { Input, Table } from 'antd';
import { Text } from 'atoms';
import { Rupee } from 'components';

const return_sales_info_columns = [
	{
		title: 'Product Name',
		dataIndex: 'name',
		key: 'name',
		render: (_, record) => (
			<>
				<Text>{record?.name}</Text>
				<Text>
					<Rupee>{Number(record?.mrp).toFixed(2)}</Rupee>
				</Text>
			</>
		),
	},
	{
		title: 'Returnable Quantity',
		dataIndex: 'returned_qty',
		key: 'returned_qty',
		align: 'center',
		render: (returned_qty) => (
			<Text textAlign="center">
				{returned_qty > 1 ? `${returned_qty} pcs` : `${returned_qty} pc`}
			</Text>
		),
	},
	{
		title: 'Sales Return Quantity',
		dataIndex: 'returned_qty',
		key: 'returned_qty',
		sortId: 1,
		overflow: 'hidden',
		align: 'center',
		render: (returned_qty) => (
			<Input disabled value={returned_qty} style={{ width: '150px' }} />
		),
	},
	{
		title: 'Bad Inventory',
		dataIndex: 'bad_qty',
		key: 'bad_qty',
		align: 'center',
		render: (bad_qty) => <Input disabled value={bad_qty} style={{ width: '150px' }} />,
	},
	{
		title: 'Good Inventory',
		dataIndex: 'good_qty',
		key: 'good_qty',
		align: 'center',
		render: (good_qty) => <Input disabled value={good_qty} style={{ width: '100px' }} />,
	},
];

// eslint-disable-next-line react/prop-types
export default function SalesOrderDetailsTable({ data }) {
	return (
		<div>
			<Table
				columns={return_sales_info_columns}
				dataSource={data}
				pagination={false}
				style={{ padding: '0px 30px' }}
			/>
		</div>
	);
}

SalesOrderDetailsTable.propTypes = {
	data: PropTypes.object,
};
