/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-nested-ternary */
/* eslint-disable sonarjs/cognitive-complexity */
import { Select, Tooltip } from 'antd';
import { DateFormat, Rupee } from 'components';
import { Box, Text, Flex } from 'atoms';
import * as SVGICONS from 'utils/Svg';
import {
	RTFEyeIcons,
	SelectFilterLabelOptions,
	DeliverStatusOptions,
	getstatus,
	RupeeSymbol,
	VerifyCircle,
	UserPersmission,
	StoireLocationIcons,
	NewLocationIcons,
	OutOfLocationIcons,
	LocationNotAvailableIcons,
	EPODUploadIcon,
	EPODUploadDisabledIcon,
	// EPODUploadDisabledIcon,
} from 'utils';
import { ACL } from 'components/common';

const invoiceStatusOptions = [
	{ label: 'Invoice Returned', value: 'Bill Back' },
	{ label: 'Voucher', value: 'Voucher' },
	// eslint-disable-next-line sonarjs/no-duplicate-string
	{ label: 'Invoice settled', value: 'No Bill Back' },
];

const invoiceVerificationStatses = [
	'VerifiedBySegregator',
	'RejectedByCashier',
	'VerifiedByCashier',
	'PartialVerifiedByCashier',
];
// const ERR_MESSAGE = 'Order should not be Vehicle Allocated or Out for delivery';

const invoiceStatusMap = {
	RejectedByCashier: 'Rejected by Cashier',
	VerifiedBySegregator: 'Verified by Segregator',
	VerifiedByCashier: 'Verified by Cashier',
	PartialVerifiedByCashier: 'Partially Verified by Cashier',
	RejectedBySegregator: 'Rejected by Segregator',
};

function getInvoiceStatusText(invoiceStatus) {
	return invoiceStatus && invoiceStatusMap[invoiceStatus] ? invoiceStatusMap[invoiceStatus] : '-';
}

export const RTFInvoiceListTableConfig = ({
	isVerifiyCompleted,
	handleStatus,
	// navigate,
	// handleVerifyOrder,
	handleViewOrders,
	uploadAllowed,
	rfcEditBlock,
	handleCollection,
	handleLocation,
	handleEpodUpload,
	epodEnabled,
	isSuperAdmin,
	handleVerifyButton,
}) => [
	{
		title: '',
		dataIndex: 'bnpl_status',
		key: 'bnpl_status',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (_, rec) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{rec?.bnpl_enabled ? <SVGICONS.QuidSalesOrderIcons /> : ''}
			</Text>
		),
	},
	{
		title: 'Salesman',
		dataIndex: 'salesman_id',
		key: 'salesman_id',
		overflow: 'hidden',
		hidden: false,
		render: (_, rec) => (
			<Text overFlow="hidden" fontSize="14px" color="#000">
				{rec?.Salesman?.name || ''}
			</Text>
		),
	},
	{
		title: 'Invoice',
		dataIndex: 'invoice_no',
		key: 'invoice_no',
		overflow: 'hidden',
		hidden: false,
		render: (invoice_no, record) => (
			<Text overFlow="hidden" fontSize="14px" color="#000">
				{invoice_no}
				<Box fontSize="12px" color="#474747">
					<DateFormat date={record?.invoice_date} />
				</Box>
			</Text>
		),
	},
	{
		title: 'Retailer Name',
		dataIndex: 'retailer_name',
		key: 'retailer_name',
		overflow: 'hidden',
		hidden: false,
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="14px" color="#000">
				{record?.Store?.name || ''}
				<Box fontSize="12px" color="#474747">
					{record?.Store?.code || ''}
				</Box>
			</Text>
		),
	},
	{
		title: 'Invoice Value',
		dataIndex: 'amount',
		key: 'amount',
		overflow: 'hidden',
		hidden: false,
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="14px" color="#000">
				<Rupee>{record?.CollectionInvoice?.invoice_amount}</Rupee>
			</Text>
		),
	},
	{
		title: 'Collectable Value',
		dataIndex: 'amount',
		key: 'amount',
		overflow: 'hidden',
		hidden: false,
		render: (_, rec) => (
			<Text overFlow="hidden" fontSize="14px" color="#000">
				<Rupee>
					{['DL', 'PD'].includes(rec?.status)
						? rec?.CollectionInvoice?.initial_outstanding_amount || 0
						: 0}
				</Rupee>
			</Text>
		),
	},
	{
		title: 'Collected Value',
		dataIndex: 'amount',
		key: 'amount',
		overflow: 'hidden',
		hidden: false,
		render: (_, rec) => (
			<Text overFlow="hidden" fontSize="14px" color="#000">
				<Rupee>
					{rec?.CollectionInvoice?.payments?.reduce((a, c) => a + (c?.amount || 0), 0) ||
						0}
				</Rupee>
			</Text>
		),
	},
	{
		title: 'Delivery Status',
		dataIndex: 'status',
		key: 'status',
		overflow: 'hidden',
		hidden: false,
		width: '15%',
		render: (delivery_status, rec) => (
			<Select
				style={{ width: '180px', fontSize: '12px' }}
				showSearch
				name="delivery_status"
				value={getstatus(delivery_status)}
				disabled={
					rec?.return_status === 'Complete' ||
					rec?.return_verified_by ||
					!uploadAllowed ||
					rfcEditBlock
				}
				filterOption={SelectFilterLabelOptions}
				onChange={(val) =>
					val !== rec?.status && handleStatus(rec, val, 'delivery', rec?.status)
				}
				options={DeliverStatusOptions}
			/>
		),
	},
	{
		title: 'Invoice Status',
		dataIndex: 'bill_status',
		key: 'bill_status',
		overflow: 'hidden',
		hidden: false,
		render: (_, rec) => {
			const statusVal = (() => {
				if (
					rec?.CollectionInvoice?.status === 'Bill Back' &&
					rec?.CollectionInvoice?.billtype === 'Bill'
				) {
					return 'Bill Back';
				}
				if (
					rec?.CollectionInvoice?.status === 'Bill Back' &&
					rec?.CollectionInvoice?.billtype === 'Voucher'
				) {
					return 'Voucher';
				}
				if (
					rec?.CollectionInvoice?.status === 'No Bill Back' &&
					rec?.CollectionInvoice?.billtype === 'Bill'
				) {
					return 'No Bill Back';
				}
				if (
					rec?.CollectionInvoice?.status === 'Void Bill' &&
					rec?.CollectionInvoice?.billtype === 'Bill'
				) {
					return 'Void Bill';
				}
				return 'Pending';
			})();

			// console.log('rec?.CollectionInvoice', rec?.CollectionInvoice);
			return (
				<Select
					style={{ width: '180px', fontSize: '12px' }}
					showSearch
					name="status"
					value={statusVal}
					disabled={
						!uploadAllowed ||
						invoiceVerificationStatses.includes(
							rec?.CollectionInvoice?.invoice_verification_status
						) ||
						rec?.return_verified_by ||
						['VA', 'OD', 'CA', 'DA', 'XD'].includes(rec?.status) ||
						rfcEditBlock
					}
					filterOption={SelectFilterLabelOptions}
					onChange={(val) => handleStatus(rec, val, 'invoice', rec?.status)}
					options={invoiceStatusOptions}
				/>
			);
		},
	},
	{
		title: 'Invoice Verification Status',
		dataIndex: 'invoice_verification_status',
		key: 'invoice_verification_status',
		overflow: 'hidden',
		hidden: false,
		render: (_, rec) => (
			<Text overFlow="hidden" fontSize="14px" color="#000">
				{getInvoiceStatusText(rec?.CollectionInvoice?.invoice_verification_status)}
			</Text>
		),
	},
	{
		title: 'Actions',
		dataIndex: 'id',
		key: 'id',
		overflow: 'hidden',
		hidden: false,
		render: (id, rec) => {
			// Handle view orders conditions
			const shouldHandleLocation =
				rec?.for_kyc && !['VA', 'OD', 'XD', 'RD'].includes(rec?.status);
			const shouldHandleEpodUpload =
				!shouldHandleLocation &&
				!rec?.is_invoice_copy_verified &&
				epodEnabled &&
				!rfcEditBlock;
			const shouldHandleEpodUploadStatus =
				shouldHandleEpodUpload &&
				['DL', 'PD'].includes(rec?.status) &&
				!['CA', 'DA', 'RD'].includes(rec?.status);
			// Handle view orders functions
			const handleClick = (handleView) => {
				// console.log(
				// 	'handleViewOrdershandleViewOrders',
				// 	handleView,
				// 	shouldHandleLocation,
				// 	shouldHandleEpodUpload,
				// 	shouldHandleEpodUploadStatus
				// );
				// console.log('uploadAllowed ', uploadAllowed, handleView);
				if (
					(rfcEditBlock && handleView !== 'handleVerifyButton') ||
					!uploadAllowed ||
					isVerifiyCompleted === 'CO' ||
					rec?.tag.toLowerCase() === 'eco'
				) {
					console.log('came insdie the handle view ', handleView);
					RedirectView(handleView);
				} else if (shouldHandleLocation) {
					handleLocation(rec, 'newLocation', handleView);
				} else if (['CA', 'DA'].includes(rec?.status)) {
					RedirectView(handleView);
				} else if (shouldHandleEpodUploadStatus) {
					handleEpodUpload(rec, handleView);
				} else if (shouldHandleEpodUpload) {
					handleEpodUpload(rec, handleView);
				} else {
					RedirectView(handleView);
				}
			};

			// Redirect View
			const RedirectView = (handleView) => {
				if (handleView === 'handleViewOrders') {
					handleViewOrders(rec);
				} else if (handleView === 'handleCollection') {
					handleCollection(rec);
				} else if (handleView === 'handleVerifyButton') {
					handleVerifyButton(rec);
				}
			};

			return (
				<Flex>
					{/* View Delivery Details */}
					{/* {console.log(rec, 'sdkjn')}
					<Box alignSelf="center">
						{rec?.bnpl_enabled ? <SVGICONS.QuidSalesOrderIcons /> : ''}
					</Box> */}

					<Box
						onClick={() => handleClick('handleViewOrders')}
						cursor="pointer"
						alignSelf="center"
					>
						<RTFEyeIcons />
					</Box>

					{/* View Collection Details */}
					<ACL allowedGroups={UserPersmission.returnToFC_create}>
						<Box
							onClick={() => handleClick('handleCollection')}
							cursor="pointer"
							alignSelf="center"
						>
							<RupeeSymbol />
						</Box>
					</ACL>

					{/* Store Location */}
					{!rec?.for_kyc &&
					!rec?.outside_fence &&
					rec?.Store?.latitude &&
					rec?.Store?.longitude &&
					['VA', 'OD', 'XD', 'RD'].includes(rec?.status) ? (
						<Box
							onClick={() => handleLocation(rec, 'storeLocation')}
							cursor="pointer"
							alignSelf="center"
						>
							<StoireLocationIcons />
						</Box>
					) : !rec?.for_kyc &&
					  !rec?.outside_fence &&
					  rec?.Store?.latitude &&
					  rec?.Store?.longitude &&
					  !epodEnabled ? (
						<Box
							onClick={() => handleLocation(rec, 'storeLocation')}
							cursor="pointer"
							alignSelf="center"
						>
							<StoireLocationIcons />
						</Box>
					) : !rec?.for_kyc &&
					  !rec?.outside_fence &&
					  rec?.Store?.latitude &&
					  rec?.Store?.longitude &&
					  rec?.is_invoice_copy_verified &&
					  epodEnabled ? (
						<Box
							onClick={() => handleLocation(rec, 'storeLocation')}
							cursor="pointer"
							alignSelf="center"
						>
							<StoireLocationIcons />
						</Box>
					) : !rec?.for_kyc &&
					  !rec?.outside_fence &&
					  rec?.Store?.latitude &&
					  rec?.Store?.longitude &&
					  !rec?.is_invoice_copy_verified &&
					  epodEnabled &&
					  rec?.CollectionInvoice?.status === 'No Bill Back' &&
					  !['CA', 'DA'].includes(rec?.status) ? (
						<Box
							onClick={() =>
								handleLocation(
									rec,
									'storeLocation',
									'handleEPODUpload',
									'No Bill Back'
								)
							}
							cursor="pointer"
							alignSelf="center"
						>
							<StoireLocationIcons />
						</Box>
					) : !rec?.for_kyc &&
					  !rec?.outside_fence &&
					  rec?.Store?.latitude &&
					  rec?.Store?.longitude &&
					  !rec?.is_invoice_copy_verified &&
					  epodEnabled &&
					  rec?.CollectionInvoice?.status !== 'No Bill Back' &&
					  !['CA', 'DA'].includes(rec?.status) ? (
						<Box
							onClick={() => handleLocation(rec, 'storeLocation', 'handleEPODUpload')}
							cursor="pointer"
							alignSelf="center"
						>
							<StoireLocationIcons />
						</Box>
					) : (
						!rec?.for_kyc &&
						!rec?.outside_fence &&
						rec?.Store?.latitude &&
						rec?.Store?.longitude &&
						!rec?.is_invoice_copy_verified &&
						epodEnabled &&
						['CA', 'DA'].includes(rec?.status) && (
							<Box
								onClick={() => handleLocation(rec, 'storeLocation')}
								cursor="pointer"
								alignSelf="center"
							>
								<StoireLocationIcons />
							</Box>
						)
					)}
					{/* New Locations */}
					{(rec?.for_kyc || (rec?.for_kyc && rec?.outside_fence)) &&
					!rec?.is_invoice_copy_verified &&
					epodEnabled &&
					['CA', 'DA', 'VA', 'OD', 'XD', 'RD'].includes(rec?.status) ? (
						<Box
							onClick={() => handleLocation(rec, 'newLocation')}
							cursor="pointer"
							alignSelf="center"
						>
							<NewLocationIcons />
						</Box>
					) : (rec?.for_kyc || (rec?.for_kyc && rec?.outside_fence)) &&
					  !rec?.is_invoice_copy_verified &&
					  epodEnabled &&
					  rec?.CollectionInvoice?.status === 'No Bill Back' &&
					  !['CA', 'DA'].includes(rec?.status) ? (
						<Box
							onClick={() =>
								handleLocation(
									rec,
									'newLocation',
									'handleEPODUpload',
									'No Bill Back'
								)
							}
							cursor="pointer"
							alignSelf="center"
						>
							<NewLocationIcons />
						</Box>
					) : (rec?.for_kyc || (rec?.for_kyc && rec?.outside_fence)) &&
					  !rec?.is_invoice_copy_verified &&
					  epodEnabled &&
					  rec?.CollectionInvoice?.status !== 'No Bill Back' &&
					  !['CA', 'DA'].includes(rec?.status) ? (
						<Box
							onClick={() => handleLocation(rec, 'newLocation', 'handleEPODUpload')}
							cursor="pointer"
							alignSelf="center"
						>
							<NewLocationIcons />
						</Box>
					) : (rec?.for_kyc || (rec?.for_kyc && rec?.outside_fence)) &&
					  rec?.is_invoice_copy_verified &&
					  epodEnabled ? (
						<Box
							onClick={() => handleLocation(rec, 'newLocation', 'newLocation')}
							cursor="pointer"
							alignSelf="center"
						>
							<NewLocationIcons />
						</Box>
					) : (
						(rec?.for_kyc || (rec?.for_kyc && rec?.outside_fence)) &&
						!epodEnabled && (
							<Box
								onClick={() => handleLocation(rec, 'newLocation', 'handleLocation')}
								cursor="pointer"
								alignSelf="center"
							>
								<NewLocationIcons />
							</Box>
						)
					)}
					{/* Store Location */}
					{rec?.outside_fence &&
					!rec?.for_kyc &&
					['VA', 'OD', 'XD', 'RD'].includes(rec?.status) ? (
						<Box
							onClick={() => handleLocation(rec, 'outOfLocation')}
							cursor="pointer"
							alignSelf="center"
						>
							<OutOfLocationIcons />
						</Box>
					) : rec?.outside_fence &&
					  !rec?.for_kyc &&
					  rec?.Store?.latitude &&
					  rec?.Store?.longitude &&
					  !epodEnabled ? (
						<Box
							onClick={() => handleLocation(rec, 'outOfLocation')}
							cursor="pointer"
							alignSelf="center"
						>
							<OutOfLocationIcons />
						</Box>
					) : rec?.outside_fence &&
					  !rec?.for_kyc &&
					  rec?.Store?.latitude &&
					  rec?.Store?.longitude &&
					  !rec?.is_invoice_copy_verified &&
					  epodEnabled &&
					  rec?.CollectionInvoice?.status === 'No Bill Back' &&
					  !['CA', 'DA'].includes(rec?.status) ? (
						<Box
							onClick={() =>
								handleLocation(
									rec,
									'outOfLocation',
									'handleEPODUpload',
									'No Bill Back'
								)
							}
							cursor="pointer"
							alignSelf="center"
						>
							<OutOfLocationIcons />
						</Box>
					) : rec?.outside_fence &&
					  !rec?.for_kyc &&
					  rec?.Store?.latitude &&
					  rec?.Store?.longitude &&
					  !rec?.is_invoice_copy_verified &&
					  epodEnabled &&
					  rec?.CollectionInvoice?.status !== 'No Bill Back' &&
					  !['CA', 'DA'].includes(rec?.status) ? (
						<Box
							onClick={() => handleLocation(rec, 'outOfLocation', 'handleEPODUpload')}
							cursor="pointer"
							alignSelf="center"
						>
							<OutOfLocationIcons />
						</Box>
					) : rec?.outside_fence &&
					  !rec?.for_kyc &&
					  rec?.Store?.latitude &&
					  rec?.Store?.longitude &&
					  !rec?.is_invoice_copy_verified &&
					  epodEnabled &&
					  ['CA', 'DA'].includes(rec?.status) ? (
						<Box
							onClick={() => handleLocation(rec, 'outOfLocation')}
							cursor="pointer"
							alignSelf="center"
						>
							<OutOfLocationIcons />
						</Box>
					) : (
						rec?.outside_fence &&
						!rec?.for_kyc &&
						rec?.Store?.latitude &&
						rec?.Store?.longitude &&
						rec?.is_invoice_copy_verified &&
						epodEnabled && (
							<Box
								onClick={() => handleLocation(rec, 'outOfLocation')}
								cursor="pointer"
								alignSelf="center"
							>
								<OutOfLocationIcons />
							</Box>
						)
					)}
					{!rec?.for_kyc && !rec?.Store?.latitude && !rec?.Store?.longitude && (
						<Tooltip placement="topRight" title="Location Not Available">
							<Box alignSelf="center">
								<LocationNotAvailableIcons />
							</Box>
						</Tooltip>
					)}

					{/* Epod Upload List */}
					<ACL allowedGroups={UserPersmission.returnToFC_create}>
						{epodEnabled &&
							(!rec?.CollectionInvoice?.verified_by_cashier_at || isSuperAdmin) &&
							((['CA', 'DA', 'VA', 'OD', 'XD'].includes(rec?.status) &&
								!rfcEditBlock) ||
							rec?.tag.toLowerCase() === 'eco' ? (
								<Tooltip
									placement="topRight"
									title={
										rec?.tag.toLowerCase() === 'eco'
											? 'EPOD is not available for ECO orders'
											: `EPOD is not available for ${
													rec?.status === 'CA'
														? 'Cancelled'
														: rec?.status === 'DA'
														? 'Delivery Attempted'
														: rec?.status === 'VA'
														? 'Vehicle Allocated'
														: rec?.status === 'RD'
														? 'Redeliver in Future'
														: 'Out for Delivery'
											  } Orders`
									}
								>
									<Box cursor="not-allowed" alignItem="center">
										<EPODUploadDisabledIcon />
									</Box>
								</Tooltip>
							) : rec?.for_kyc &&
							  !rec?.is_invoice_copy_verified &&
							  !rfcEditBlock &&
							  uploadAllowed &&
							  isVerifiyCompleted !== 'CO' ? (
								<Box
									onClick={() =>
										handleLocation(rec, 'newLocation', 'handleEPODUpload')
									}
									cursor="pointer"
									alignItem="center"
								>
									<EPODUploadIcon />
								</Box>
							) : rec?.is_invoice_copy_verified && !rfcEditBlock ? (
								<Box
									onClick={() => handleEpodUpload(rec, 'handleEPODUpload')}
									cursor="pointer"
									alignItem="center"
								>
									<EPODUploadIcon isUploaded />
								</Box>
							) : (
								<Box
									onClick={() => handleEpodUpload(rec, 'handleEPODUpload')}
									cursor="pointer"
									alignItem="center"
								>
									<EPODUploadIcon />
								</Box>
							))}
					</ACL>

					{/* View Collection Details */}
					<ACL allowedGroups={UserPersmission.returnToFC_create}>
						{rfcEditBlock ? (
							<Tooltip
								placement="topRight"
								title="You cannot Edit or Update the details until Delivery Person completes Delivery activity in Mobile Application."
							>
								<Box alignSelf="center">
									<VerifyCircle hasVerified={rec?.return_verified_by} />
								</Box>
							</Tooltip>
						) : (
							<Box
								onClick={() => handleClick('handleVerifyButton')}
								cursor="pointer"
								alignSelf="center"
							>
								<VerifyCircle hasVerified={rec?.return_verified_by} />
							</Box>
						)}
					</ACL>
				</Flex>
			);
		},
	},
];
