/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Box, Flex } from 'atoms';

const CommonStyle = {
	width: '200px',
	alignSelf: 'center',
	margin: '0 1rem 1rem',
	fontSize: '12px',
	lineHeight: '30px',
};

export function LoadAsyncSelectComponent({
	children,
	options,
	loadFCs = [],
	setList = () => null,
	hasLoadingValues = false,
}) {
	useEffect(() => {
		// this function has been created for setting
		// up for brands options
		if (!isEmpty(loadFCs) && options.type === 'Success') {
			setList(
				options?.data
					?.filter((x) => loadFCs.includes(x.value))
					.map((x) => x?.brands)
					.flat()
			);
		}
	}, [options]);

	if (options.type === 'Loading' || options.type === 'NotAsked' || hasLoadingValues) {
		return (
			<Flex>
				<Box style={CommonStyle}>Loading...</Box>
			</Flex>
		);
	}

	if (options.type === 'Error') {
		return (
			<Flex>
				<Box style={CommonStyle}> Error while fetching options </Box>
			</Flex>
		);
	}

	return children;
}

LoadAsyncSelectComponent.propTypes = {
	children: PropTypes.any,
	options: PropTypes.object,
	loadFCs: PropTypes.array,
	setList: PropTypes.func,
	hasLoadingValues: PropTypes.bool,
};
