import React, { useState } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Button, Image, Text } from 'atoms';
import { RejectModal } from '.';
import Alert from 'assets/Alert.svg';
import AccpetLeave from 'assets/leave-accept.svg';
import RejectLeave from 'assets/leave-reject.svg';
const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 1.4rem;
	width: 100%;

	button {
		width: 80%;
	}
`;

const DetailsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 0.9rem;

	img {
		cursor: pointer;
	}
`;
const MainTitle = styled.h1`
	font-size: 10px;
	font-weight: 400;
	line-height: 13px;
	color: ${(props) => props.theme.colors.Charcoal};
`;

const Title = styled.h2`
	font-size: 8px;
	font-weight: 300;
	font-family: Roboto;
	line-height: 9px;
`;

const LeaveDate = styled.p`
	font-size: 13px;
	line-height: 19px;
	font-weight: 400;
`;

export default function LeaveModal({ modalVisible, modalClose, width = '25%' }) {
	const [rejectModal, setRejectModal] = useState(false);

	const closeRejectModal = () => setRejectModal(false);
	return (
		<>
			<Modal
				centered
				width={width}
				visible={modalVisible}
				footer={null}
				onCancel={modalClose}
			>
				<Box textAlign="center" cursor="pointer">
					<Image src={Alert} alt="alert" />
				</Box>

				<Box mt="3" textAlign="center">
					<h1>Do you want to accept the users leave.</h1>
				</Box>
				<DetailsContainer>
					<MainTitle>Leave Dates</MainTitle>
					<Title>Accept</Title>
					<Title> Reject </Title>
				</DetailsContainer>

				<DetailsContainer>
					<LeaveDate>01-02-2022</LeaveDate>
					<Image src={AccpetLeave} alt="leave-accept" />
					<Image src={RejectLeave} alt="leave-reject" />
				</DetailsContainer>

				<DetailsContainer>
					<LeaveDate>01-02-2022</LeaveDate>
					<Image src={AccpetLeave} alt="leave-accept" />
					<Image src={RejectLeave} alt="leave-reject" />
				</DetailsContainer>

				<DetailsContainer>
					<LeaveDate>01-02-2022</LeaveDate>
					<Image src={AccpetLeave} alt="leave-accept" />
					<Image src={RejectLeave} alt="leave-reject" />
				</DetailsContainer>
				<Text textAlign="center">Add Text field</Text>
				<ButtonContainer>
					<Button variant="primary" onClick={modalClose}>
						{' '}
						Confirm
					</Button>
				</ButtonContainer>
			</Modal>
			<RejectModal modalVisible={rejectModal} modalClose={closeRejectModal} />
		</>
	);
}

LeaveModal.propTypes = {
	modalVisible: PropTypes.bool,
	modalClose: PropTypes.func,
	width: PropTypes.number,
};
