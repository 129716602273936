export default function getColor(props) {
	if (props?.status.match('/^[0-9]/')) {
		switch (props?.status) {
			case 'Delivered':
				return props.theme.colors.SlimyGreen;
			case 'Partial Delivery':
				return props.theme.colors.Byzantine;
			case 'Cancelled':
				return props.theme.colors.Rufous;
			case 'Active':
				return props.theme.colors.Ming;
			case 'Inactive':
				return props.theme.colors.FireOpal;
			case 'Pending':
				return props.theme.colors.Gold;
			case 'Absent':
				return props.theme.colors.Rufous;
			case 'Approved':
				return props.theme.colors.SlimyGreen;
			case 'Rejected':
				return props.theme.colors.Rufous;
			case 'Approved Partially':
				return props.theme.colors.DarkMagenta;
			default:
				return '#000';
		}
	}

	return false;
}
