import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

function getArrayData(count) {
	return [...Array(count).keys()];
}

export default function TableShimmer({ rowsCount, columns, loading }) {
	const rowData = useMemo(() => getArrayData(rowsCount), [rowsCount]);

	if (!loading) {
		return null;
	}

	return (
		<div className="table-shimmer">
			<table className="tableWrapper">
				<thead>
					<tr>
						{columns?.map((item) => (
							<th key={item?.title} className="table-head">
								{item?.title}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{rowData?.map((rowId, i) => (
						<tr key={rowId} className={i % 2 === 0 ? 'even-row' : ''}>
							{columns?.map((item) => (
								<td key={item?.title} className="table-cell">
									<div>&nbsp;</div>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

TableShimmer.propTypes = {
	rowsCount: PropTypes.number,
	columns: PropTypes.array,
	loading: PropTypes.bool,
};
