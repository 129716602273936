import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	CashierSalesManStatsHeader,
	CashVerificationTable,
	CashierTopStatusTabs,
	CashVerifyHeader,
} from 'components/pages/cashier';
import { getcashierVerificationList } from 'store/cashierSlice';

export function CashVerification() {
	const [headerData, setHeaderData] = useState(0);
	const dispatch = useDispatch();
	// const [colleted_pid, SetcolletedPid] = useState('');
	const allocated_date = headerData?.data_summary?.allocation_date_time;
	const collected_person_id = headerData?.data_summary?.collected_person_id;
	const tab = headerData?.data_summary?.tab;
	const [cashierData, setcashierData] = useState({});

	// const [collectedCashData, SetcollectedCashData] = useState({});
	const [rejectedReason, setRejectedReason] = useState(null);
	const [isCashVerified, setIsCashVerified] = useState({
		verification_checked: false,
		rejection_checked: false,
	});
	const { cashierPersonHeaderDetails = {} } = useSelector((state) => state.invoice);

	console.log(cashierPersonHeaderDetails);

	useEffect(() => {
		if (collected_person_id && allocated_date && tab)
			dispatch(getcashierVerificationList(collected_person_id, allocated_date, tab)).then(
				(res) => {
					console.log(res);
					if (res) {
						setcashierData(res);
					}
				}
			);
	}, [dispatch, allocated_date, tab, collected_person_id]);

	useEffect(() => {
		console.log(collected_person_id);
		console.log(allocated_date);
	}, [allocated_date, collected_person_id]);

	useEffect(() => {
		setIsCashVerified({
			verification_checked: cashierData?.verification_checked || false,
			rejection_checked: cashierData?.rejection_checked || false,
		});
	}, [cashierData?.rejection_checked, cashierData?.verification_checked]);

	function handleVerifyReject(_, type = 'verify', rejectedReasonData = null) {
		let greenTick = false;
		let redTick = false;
		if (type === 'verify' && !isCashVerified?.verification_checked) {
			greenTick = true;
		} else if (type === 'reject' && !isCashVerified?.rejection_checked) {
			redTick = true;
		}
		setIsCashVerified({
			verification_checked: greenTick,
			rejection_checked: redTick,
		});
		setRejectedReason(rejectedReasonData);
	}
	console.log(headerData, 'sdjv');

	console.log({
		...isCashVerified,
		remark_flag: headerData?.payment_remark_flag || false,
	});

	return (
		<div>
			<CashierTopStatusTabs />
			<CashierSalesManStatsHeader setHeaderData={setHeaderData} />
			<CashVerifyHeader
				record={{
					...isCashVerified,
					...cashierPersonHeaderDetails?.data_summary,
					remark_flag: headerData?.payment_remark_flag || false,
				}}
				handleVerifyReject={handleVerifyReject}
				totalCash={headerData?.data?.total_cash || 0}
				colleted_pid={collected_person_id}
				allocated_date={allocated_date}
				tab={tab}
			/>
			<CashVerificationTable
				isCashVerified={isCashVerified}
				rejectedReason={rejectedReason}
			/>
		</div>
	);
}
