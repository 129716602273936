/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Select, DatePicker } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import moment from 'moment';
import { getReturnsLogsList } from 'store/fulfilment/returnsLogsSlice';
import { Box, AsyncSelect, Button, Flex } from 'atoms';
import { RCTable, LoadAsyncSelectComponent, SearchBar, LabelWrapper, ACL } from 'components/common';
import { getFcListOnLoad, getFcOnSearchList } from 'store/masters';
import {
	returnsLogsTableConfig,
	handleFilterChange,
	ReturnsLogsQueries,
	toAsysnOption,
	searchBox,
	disableDateRangesNew,
	handleSearch,
	SelectFilterLabelOptions,
	RETURNS_TYPES,
	RETURNS_STATUSES,
	disableFutureDate,
	initialPaginateConfigWithoutQ,
	UserPersmission,
} from 'utils';
import { SearchField } from 'components/common/custom-table/Style';
import { useQueryParams } from 'hooks';
import { ReturnsLogsErrorAlert } from 'components/modals/ReturnsLogsErrorAlert';
// import { handleSearch } from 'utils/tableConfig';

// const DisabledSearch = {
// 	backgroundColor: '#f5f5f5',
// 	cursor: 'not-allowed',
// };

export default function ReturnsLogs() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);
	const [rowData, setRowData] = useState({});
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [paginateConfig, setpaginateConfig] = useQueryParams({
		filterQuery: ReturnsLogsQueries,
	});
	const [modalVisible, setModalVisible] = useState('');
	const [wmsErrorInfo, setWmsErrorInfo] = useState('');

	useEffect(() => {
		setFcList({ type: 'Loading' });

		dispatch(
			getFcListOnLoad({ fcIds: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [] })
		).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});
	}, [dispatch]);

	const showModal = () => {
		setModalVisible(true);
	};
	const handleOk = () => {
		setModalVisible(false);
	};

	const getErrorData = (data) => {
		// const parsedData = JSON.stringify(data.errors);
		setWmsErrorInfo(data?.errors?.error_msg);
	};

	const handleChangeSelect = (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value

		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		const fcValues = value?.value ? [value?.value] : [];
		setpaginateConfig((prev) => ({
			...handleFilterChange(name, fcValues, prev),
			brands: [],
		}));
	};

	return (
		<>
			<Flex
				style={{
					// alignItems: 'center',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
					alignContent: 'flex-start',
				}}
			>
				<Flex style={{ flexDirection: 'column' }}>
					<Box>
						<LabelWrapper
							label="Search (Fc & Brand selection required)"
							marginspace="-1px 0 5px 0"
						>
							<SearchField>
								<SearchBar
									placeholder="Search by Invoice No"
									allowClear
									queryValue={paginateConfig?.query?.replaceAll('*', '')}
									onSearch={(val) =>
										setpaginateConfig((prev) => handleSearch(val, prev))
									}
									// disabled={!(paginateConfig?.brands?.length > 0)}
									// style={paginateConfig?.brands?.length > 0 ? {} : DisabledSearch}
								/>
							</SearchField>
						</LabelWrapper>

						<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
							<LoadAsyncSelectComponent
								loadFCs={paginateConfig?.fcs}
								setList={(val) => setBrandList(val)}
								options={fcsList}
							>
								<AsyncSelect
									style={searchBox}
									allowClear
									name="fcs"
									showSearch
									fetchOptions={getFcOnSearchList}
									transformOptions={(val) => toAsysnOption(val)}
									defaultValue={paginateConfig?.fcs}
									placeholder="Select FC(s)"
									onChange={(value, options) => {
										handleChangeSelect('fcs', value, options);
									}}
									selectOptions={[...(fcsList.data || [])]}
								/>
							</LoadAsyncSelectComponent>
						</LabelWrapper>

						<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
							<Select
								name="fields"
								style={searchBox}
								allowClear
								showSearch
								label="brands"
								mode="multiple"
								value={paginateConfig?.brands}
								filterOption={SelectFilterLabelOptions}
								options={brandsList}
								onChange={(value) =>
									setpaginateConfig((prev) => ({
										...prev,
										brands: value,
										...initialPaginateConfigWithoutQ,
									}))
								}
							/>
						</LabelWrapper>

						<LabelWrapper label="Select Type" marginspace="0px 0 7px 0">
							<Select
								style={{ width: '200px', alignSelf: 'center' }}
								allowClear
								name="fileType"
								placeholder="Select Type"
								showSearch
								filterOption={SelectFilterLabelOptions}
								value={paginateConfig?.fileType}
								options={RETURNS_TYPES}
								onChange={(value) =>
									setpaginateConfig((prev) =>
										handleFilterChange('fileType', value, prev)
									)
								}
							/>
						</LabelWrapper>

						<LabelWrapper label="Status(s)" marginspace="3px 0 -1px 1rem">
							<Select
								name="statuses"
								style={searchBox}
								allowClear
								showSearch
								options={RETURNS_STATUSES}
								value={paginateConfig?.statuses}
								filterOption={SelectFilterLabelOptions}
								mode="multiple"
								placeholder="Status(s)"
								onChange={(value) =>
									setpaginateConfig((prev) =>
										handleFilterChange('statuses', value, prev)
									)
								}
							/>
						</LabelWrapper>
					</Box>
					<Box>
						<LabelWrapper label="From Date" marginspace="3px 0 -1px 1rem">
							<DatePicker
								name="invoice_date__gte"
								style={searchBox}
								label="From Date"
								value={
									paginateConfig?.invoice_date__gte
										? moment(paginateConfig?.invoice_date__gte)
										: ''
								}
								disabledDate={disableFutureDate}
								onChange={(value) =>
									setpaginateConfig((prev) =>
										handleFilterChange('invoice_date__gte', value, prev)
									)
								}
								placeholder="From Date"
							/>
						</LabelWrapper>

						<LabelWrapper label="To Date" marginspace="3px 0 -1px 1rem">
							<DatePicker
								name="invoice_date__lte"
								style={searchBox}
								value={
									paginateConfig?.invoice_date__lte
										? moment(paginateConfig?.invoice_date__lte)
										: ''
								}
								label="To Date"
								onChange={(value) =>
									setpaginateConfig((prev) =>
										handleFilterChange('invoice_date__lte', value, prev)
									)
								}
								disabled={!paginateConfig?.invoice_date__gte}
								disabledDate={disableDateRangesNew(
									paginateConfig?.invoice_date__gte
								)}
								placeholder="To Date"
							/>
						</LabelWrapper>
						<Button onClick={() => setBtnClickSearh(Math.random())}>Search</Button>
					</Box>
				</Flex>
				<Flex>
					<ACL allowedGroups={UserPersmission.salesReturn_create}>
						<Button
							mt={3}
							onClick={() =>
								navigate(`/order-management/returns/search-sales-return`)
							}
						>
							Add Sales return
						</Button>
					</ACL>
				</Flex>
			</Flex>
			<Box mt={3}>
				<RCTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'id',
					}}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					dispatchAction={getReturnsLogsList}
					columns={returnsLogsTableConfig({
						showModal,
						getErrorData,
						defaultSort: paginateConfig?.sort_direction || 'DESC',
						defaultsortField: paginateConfig?.sort_column || 'id',
					})}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>

			{modalVisible && (
				<ReturnsLogsErrorAlert
					title="Additional Information"
					visible={modalVisible}
					toggleState={[modalVisible, setModalVisible]}
					onCancel={handleOk}
					rowData={wmsErrorInfo}
				/>
			)}
		</>
	);
}
