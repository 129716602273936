import { React, useState } from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Flex, Text, Box } from 'atoms';
import { DateFormat, Rupee } from 'components';
import { getstatus } from 'utils/tableConfig/helpers';
import info from 'assets/svg/Info.svg';

const getValues = (type, value) => {
	switch (type) {
		case 'date':
			return <DateFormat date={value} />;
		case 'rupee':
			return <Rupee>{value}</Rupee>;
		case 'status':
			return getstatus(value);
		default:
			return value;
	}
};

const PanelValue = styled(Text)`
	font-size: 0.75rem;
	font-weight: 700;
	text-transform: ${(props) => props.upperCase && 'uppercase'};
`;

const InfoBox = styled.div`
	cursor: pointer;
	img {
		height: 15px;
	}
	margin-left: 50px;
`;

// const SchemeHeading = styled(Flex)`
// 	justify-content: space-between;
// 	margin-bottom: 25px;
// `;

// const Scheme = styled(Flex)`
// 	justify-content: space-between;
// `;

const BrandText = styled(Text)`
	font-weight: 600;
	font-size: 18px;
`;
const Headingtext = styled(Text)`
	font-size: 14px;
	padding-bottom: 10px;
`;
const FirstHeadingtext = styled(Text)`
	font-size: 14px;
	color: #484848;
`;
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function PanelData({
	title,
	value,
	type = '',
	additionalValue = '',
	infoDetails,
	brandInfo,
}) {
	const [showPopup, setShowPopup] = useState(null);
	const [showInvPopup, setShowInvPopup] = useState(null);

	const jsonKeys = showPopup?.brandAjustment?.jsonData
		? Object.keys(showPopup?.brandAjustment?.jsonData)
		: [];
	const jsonValues = showPopup?.brandAjustment?.jsonData
		? Object.values(showPopup?.brandAjustment?.jsonData)
		: [];

	return (
		<Flex flexDirection="column" my="10px">
			<Text fontSize="0.75rem">{title}</Text>
			<Flex>
				<PanelValue upperCase={type === 'upper'}>{getValues(type, value)}</PanelValue>
				<Flex>{additionalValue && <PanelValue>&nbsp;({additionalValue})</PanelValue>}</Flex>
				{infoDetails?.[1] === 'Brand Adjustment' &&
					infoDetails?.[0]?.brandAjustment?.hasPopup && (
						<InfoBox onClick={() => onClickBrandAdjustmentsInfo(infoDetails[0])}>
							<img src={info} alt="info" />
						</InfoBox>
					)}
				{/* {console.log('brandinfocode2', brandInfo?.code)} */}

				{infoDetails?.[1] === 'Invoice Amount' &&
					(brandInfo?.code?.includes('HUL') ||
						brandInfo?.code?.includes('BRIT') ||
						brandInfo?.code?.includes('DBR') ||
						brandInfo?.code?.includes('NVA') ||
						brandInfo?.code.includes('GDJ') ||
						brandInfo?.code.includes('ITC') ||
						brandInfo?.code.includes('NESL')) && (
						<InfoBox onClick={() => onClickInvAmount()}>
							<img src={info} alt="info" />
						</InfoBox>
					)}
			</Flex>
			<Modal
				centered
				visible={showPopup?.brandAjustment?.hasPopup}
				footer={null}
				onCancel={handleCancel}
				maskClosable={false}
			>
				<Box mt="3">
					<Box mb={25}>
						<FirstHeadingtext> {showPopup?.brandAjustment?.heading}</FirstHeadingtext>
						<BrandText>
							<Rupee>{showPopup?.brandAjustment?.valueData}</Rupee>
						</BrandText>
					</Box>
					{/* <SchemeHeading>
						<Headingtext>Scheme</Headingtext>
						<Headingtext>Amount</Headingtext>
					</SchemeHeading>
					<Scheme>
						<Text>Outlet Payout Discount</Text>
						<Text>
							<Rupee>{showPopup?.outpyt_adj || 0}</Rupee>
						</Text>
					</Scheme>
					<Scheme>
						<Text>BDPR Scheme Discount</Text>
						<Text>
							<Rupee>{showPopup?.btpr_schdisc || 0}</Rupee>
						</Text>
					</Scheme>
					<Scheme>
						<Text>QPS Adjustment</Text>
						<Text>
							<Rupee>{showPopup?.qps_adj || 0}</Rupee>
						</Text>
					</Scheme>
					<Scheme>
						<Text>Ushop Redemption</Text>
						<Text>
							<Rupee>{showPopup?.ushop_redemption || 0}</Rupee>
						</Text>
					</Scheme>
					<Scheme>
						<Text>Reversed Payouts Adjustment</Text>
						<Text>
							<Rupee>{showPopup?.reversed_payouts || 0}</Rupee>
						</Text>
					</Scheme>
					<Scheme>
						<Text>Pecom Adjustment</Text>
						<Text>
							<Rupee>{showPopup?.pecom_adj || 0}</Rupee>
						</Text>
					</Scheme>
					<Scheme>
						<Text>TC Adjustment</Text>
						<Text>
							<Rupee>{showPopup?.tc_adj || 0}</Rupee>
						</Text>
					</Scheme>
					<Scheme>
						<Text>Coupon Adjustment</Text>
						<Text>
							<Rupee>{showPopup?.coupon_adj || 0}</Rupee>
						</Text>
					</Scheme> */}
					{/* <Box>{JSON.stringify(showPopup?.brandAjustment?.jsonData)}</Box> */}
					{/* <Box>{Object.keys(showPopup?.brandAjustment?.jsonData)}</Box> */}
					{/* {console.log('showPopup?.brandAdjustment?.jsonData')} */}
					{/* {console.log('jsonKeys', jsonKeys)}
					{```console				```.log('jsonValues', jsonValues)} */}
					<Flex style={{ justifyContent: 'space-between' }}>
						<Box>
							<Headingtext>Scheme</Headingtext>

							{jsonKeys.length > 0 &&
								jsonKeys.map((item) => <Box key={item.toString()}> {item}</Box>)}
						</Box>
						<Box>
							<Headingtext>Amount</Headingtext>

							{jsonValues.length > 0 &&
								jsonValues.map((item) => <Box key={item.toString()}>₹ {item}</Box>)}
						</Box>
					</Flex>
				</Box>
			</Modal>
			<Modal
				centered
				visible={showInvPopup !== null}
				footer={null}
				onCancel={handleInvCancel}
				maskClosable={false}
			>
				<Box mt="3">
					{/* {console.log('brandinfocode', brandInfo?.code)} */}
					{/* {brandInfo?.code !== 'HUL' && brandInfo?.code !== 'BRIT' && (
						<>
							<BrandText>For Other Brands</BrandText>
							<FirstHeadingtext mb="20px" style={{ fontStyle: 'italic' }}>
								<span style={{ fontWeight: 600 }}> Invoice amount </span>=
								Order value
							</FirstHeadingtext>
						</>
					)} */}
					{brandInfo?.code?.includes('HUL') && (
						<>
							<BrandText>For HUL</BrandText>
							<FirstHeadingtext style={{ fontStyle: 'italic' }}>
								<span style={{ fontWeight: 600 }}> Invoice amount </span>= CDMS
								invoice amount - Credit Note + Debit Note - Outlet Payout adjustment
								- Reversed outlet adjustment - BDPR Scheme Discount - QPS Adjustment
								- Ushop Redemption - Pecon adjustment - TC adjustment -coupon
								Adjustment
							</FirstHeadingtext>
						</>
					)}

					{brandInfo?.code?.includes('BRIT') && (
						<>
							<BrandText>For Britannia</BrandText>
							<FirstHeadingtext style={{ fontStyle: 'italic' }}>
								<span style={{ fontWeight: 600 }}> Invoice amount </span>= CDMS
								invoice amount - Credit Note + Debit Note - Cash Discount
							</FirstHeadingtext>
						</>
					)}

					{brandInfo?.code?.includes('NVA') && (
						<>
							<BrandText>For Nivea</BrandText>
							<FirstHeadingtext style={{ fontStyle: 'italic' }}>
								<span style={{ fontWeight: 600 }}> Invoice amount </span>= Net
								amount - Trade allowance
							</FirstHeadingtext>
						</>
					)}

					{brandInfo?.code?.includes('DBR') && (
						<>
							<BrandText>For Dabur</BrandText>
							<FirstHeadingtext style={{ fontStyle: 'italic' }}>
								<span style={{ fontWeight: 600 }}> Invoice amount </span>= Gross
								Amount - Scheme Discount - Brand Special Discount + Tax Amount -
								Credit Adjustment
							</FirstHeadingtext>
						</>
					)}

					{brandInfo?.code?.includes('ITC') && (
						<>
							{brandInfo?.code.includes('ITC') && <BrandText>For ITC</BrandText>}

							<FirstHeadingtext style={{ fontStyle: 'italic' }}>
								<span style={{ fontWeight: 600 }}>Invoice Amount</span> = Net Amount
								- Credit Adjustment
							</FirstHeadingtext>
						</>
					)}

					{(brandInfo?.code?.includes('GDJ') || brandInfo?.code.includes('NESL')) && (
						<>
							{brandInfo?.code.includes('GDJ') && <BrandText>For Godrej</BrandText>}
							{brandInfo?.code.includes('NESL') && <BrandText>For Nestle</BrandText>}
							<FirstHeadingtext style={{ fontStyle: 'italic' }}>
								<span style={{ fontWeight: 600 }}> Invoice Amount</span> = Net
								Amount - Credit Adjustment
							</FirstHeadingtext>
						</>
					)}
				</Box>
			</Modal>
		</Flex>
	);

	function onClickBrandAdjustmentsInfo(data) {
		setShowPopup(data);
	}

	function handleCancel() {
		setShowPopup(null);
	}

	function onClickInvAmount() {
		setShowInvPopup(true);
	}

	function handleInvCancel() {
		setShowInvPopup(null);
	}
}

PanelData.propTypes = {
	title: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	additionalValue: PropTypes.string,
	type: PropTypes.string,
	infoDetails: PropTypes.any,
	brandInfo: PropTypes.any,
};
