import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Input, Form } from 'formik-antd';
import { Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import { FormInput, Box, AsyncSelect } from 'atoms';
import { LoadAsyncSelectComponent } from 'components/common';
import { StyledSubmitButton } from 'components/forms/masters/helper';
import { getCompanyById, CreateUpdateCompany } from 'store/OnBoardSlice';
import {
	getPincodeListOnSearch,
	getPincodeOnLoad,
	getBankListOnSearch,
	getBankNameOnLoad,
} from 'store/masters';
import { Debug } from 'components/forms';
import { CompanyFormSchema, CompanyInitialValues } from './helper';
import { SelectOptions } from 'utils';

export default function CompanyEditCreateForm() {
	const navigate = useNavigate();
	const { id: companyId } = useParams();
	const { pathname } = useLocation();
	const [companylist, setcompanylist] = useState({ type: 'NotAsked' });
	const [bankList, setbankList] = useState({ type: 'NotAsked' });
	const [formInitial, setFormInitial] = useState({});
	const dispatch = useDispatch();
	const isEditPage = pathname.includes('edit');

	useEffect(() => {
		setcompanylist({ type: 'Loading' });
		setbankList({ type: 'Loading' });

		const hasEditForm = isEditPage
			? dispatch(getCompanyById({ companyId }))
			: Promise.resolve();

		hasEditForm
			.then((resp) => {
				setFormInitial(
					isEditPage ? CompanyInitialValues(resp?.data) : CompanyInitialValues()
				);
				return resp;
			})
			.then((resp) => {
				dispatch(getPincodeOnLoad({ pinCodeId: resp?.data?.pincode_id })).then((res) => {
					setcompanylist({
						type: 'Success',
						data: SelectOptions(res, 'pincode', 'id'),
					});
				});
				dispatch(getBankNameOnLoad({ bankId: resp?.data?.bank_id })).then((res) => {
					setbankList({
						type: 'Success',
						data: SelectOptions(res, 'name', 'id'),
					});
				});
			});
	}, [dispatch, companyId, isEditPage]);

	const handleSubmit = (
		{ client_sequence_end, client_sequence_start, ...rest },
		{ setSubmitting, setErrors }
	) => {
		const removeEmpty = { ...rest };
		Object.keys(removeEmpty).forEach((x) => {
			if (!removeEmpty[x]) {
				console.log(removeEmpty[x]);
				delete removeEmpty[x];
			}
		});

		const payload = isEditPage ? { ...removeEmpty, id: +companyId } : { ...removeEmpty };

		dispatch(CreateUpdateCompany({ data: payload, setErrors }))
			.then(() => navigate('/onboarding/company'))
			.finally(() => {
				setSubmitting(false);
			});
	};

	const handleSelect = (val, name, setFieldValue, setFieldError = null) => {
		if (name === 'bank_id' && isEmpty(val)) {
			setFieldValue('bank_id', val?.value || '');
			setFieldValue('account_number', '');
			setFieldValue('ifsc_code', '');
			setFieldError('account_number', '');
			setFieldError('ifsc_code', '');
		}
		setFieldValue(name, val?.value || '');
	};

	return (
		<Box>
			<Formik
				initialValues={formInitial}
				validationSchema={CompanyFormSchema}
				enableReinitialize
				onSubmit={handleSubmit}
			>
				{({ setFieldValue, values, isValid, isSubmitting, dirty, setFieldError }) => (
					<Form layout="vertical">
						<Box fontSize="16px" fontWeight="700" padding="1rem 0">
							{isEditPage ? 'Edit' : 'Add'} Company Details
						</Box>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="name" label="*Company Name">
									<Input name="name" placeholder="Enter Company Name" />
								</FormInput>
							</Col>
							{/* <Col span={8}>
								<FormInput name="short_name" label="*Company Nick Name">
									<Input
										name="short_name"
										placeholder="Enter Company Nick Name"
									/>
								</FormInput>
							</Col> */}
							<Col span={8}>
								<FormInput name="code" label="*Company Code">
									<Input
										disabled={isEditPage}
										name="code"
										placeholder="Enter Company Code"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<Box>
									<FormInput name="phone" label="*Phone Number">
										<Input name="phone" placeholder="Enter Phone Number" />
										<Box fontSize="12px">Ex: +919876543210</Box>
									</FormInput>
								</Box>
							</Col>
							<Col span={8}>
								<FormInput name="email" label="*Email">
									<Input name="email" placeholder="Enter Email" />
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="address" label="*Address">
									<Input name="address" placeholder="Enter Address" />
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="pincode_id" label="*Pincode">
									<LoadAsyncSelectComponent options={companylist}>
										<AsyncSelect
											className="customDropdown"
											allowClear
											component="formikSelect"
											name="pincode_id"
											showSearch
											defaultValue={
												companylist?.data?.filter(
													(x) => x?.value === values?.pincode_id
												)[0] || null
											}
											fetchOptions={getPincodeListOnSearch}
											onChange={(val) =>
												handleSelect(val, 'pincode_id', setFieldValue)
											}
											placeholder="Select Pincode"
											transformOptions={(val) =>
												SelectOptions(val, 'pincode', 'id')
											}
											selectOptions={[...(companylist.data || [])]}
										/>
									</LoadAsyncSelectComponent>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="gst_number" label="*GST No.">
									<Input name="gst_number" placeholder="Enter GST No" />
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="bank_id" label="Bank Name">
									<LoadAsyncSelectComponent options={bankList}>
										<AsyncSelect
											className="customDropdown"
											allowClear
											name="bank_id"
											showSearch
											component="formikSelect"
											defaultValue={
												bankList?.data?.filter(
													(x) => x?.value === values?.bank_id
												)[0] || null
											}
											fetchOptions={getBankListOnSearch}
											onChange={(val) =>
												handleSelect(
													val,
													'bank_id',
													setFieldValue,
													setFieldError
												)
											}
											placeholder="Select Bank Name"
											transformOptions={(val) =>
												SelectOptions(val, 'name', 'id')
											}
											selectOptions={[...(bankList.data || [])]}
										/>
									</LoadAsyncSelectComponent>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="account_number" label="Account Number">
									<Input
										disabled={!values?.bank_id}
										name="account_number"
										placeholder="Enter Account Number"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="ifsc_code" label="IFSC Code">
									<Input
										disabled={!values?.bank_id}
										name="ifsc_code"
										placeholder="Enter IFSC Code"
									/>
								</FormInput>
							</Col>
						</Row>
						<Box borderBottom="1px solid rgba(0, 0, 0, 0.1)"></Box>
						<Box fontSize="16px" fontWeight="700" padding="1rem 0">
							{' '}
							Company Prefix Configurations
						</Box>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="client_prefix" label="*Client Prefix">
									<Input
										disabled={isEditPage}
										name="client_prefix"
										placeholder="Enter Client Prefix"
									/>
								</FormInput>
							</Col>
							{isEditPage && (
								<>
									<Col span={8}>
										<FormInput
											name="client_sequence_start"
											label="Client Increment Start"
										>
											<Input
												disabled={isEditPage}
												name="client_sequence_start"
											/>
										</FormInput>
									</Col>
									<Col span={8}>
										<FormInput
											name="client_sequence_end"
											label="Client Next Increment"
										>
											<Input
												disabled={isEditPage}
												name="client_sequence_end"
											/>
										</FormInput>
									</Col>
								</>
							)}
						</Row>
						<Box mt="16px">
							<StyledSubmitButton disabled={isSubmitting || !isValid || !dirty}>
								{isEditPage ? 'Update' : 'Save'}{' '}
							</StyledSubmitButton>
						</Box>
						<Debug />
					</Form>
				)}
			</Formik>
		</Box>
	);
}
