import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

export function formatDate(format, date = '') {
	if (date) {
		switch (format) {
			case 'HMS':
				return moment(date).format('DD/MM/YYYY, HH:mm:ss');
			case 'HMSA':
				return moment(date).format('DD/MM/YYYY, hh:mm A');
			default:
				return moment(date).format('DD/MM/YYYY');
		}
	} else return '';
}

export default function DateFormat({ date, format }) {
	if (moment(date).isValid()) {
		return <span>{formatDate(format, date)}</span>;
	}
	return '';
}

DateFormat.propTypes = {
	date: PropTypes.string,
	format: PropTypes.string,
};
