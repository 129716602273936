import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { Text } from 'atoms';
import { Rupee } from 'components';

const validateQty = (value) => (value >= 0 ? value : '');

const valueColumns = [
	{
		title: 'Product',
		dataIndex: 'product_name',
		key: 'product_name',
		render: (name) => (
			<>
				<Text>{name[0]}</Text>
				<Text>({name[1]})</Text>
			</>
		),
	},
	{
		title: 'Batch No',
		dataIndex: 'batch_no',
		key: 'batch_no',
	},
	{
		title: 'MRP',
		dataIndex: 'mrp',
		key: 'mrp',
		render: (mrp) => <Rupee>{mrp}</Rupee>,
	},
	{
		title: 'Ordered Qty',
		dataIndex: 'ordered_qty',
		key: 'ordered_qty',
	},
	{
		title: 'Selling Price(w/o tax)',
		dataIndex: 'selling_price',
		key: 'selling_price',
		render: (selling_price) => <Rupee>{selling_price}</Rupee>,
	},
	{
		title: 'Gross Amt',
		dataIndex: 'gross_amt',
		key: 'gross_amt',
		render: (gross_amt) => <Rupee>{gross_amt}</Rupee>,
	},
	{
		title: 'Total Discount',
		dataIndex: 'total_discount',
		key: 'total_discount',
		render: (total_discount) => <Rupee>{total_discount}</Rupee>,
	},
	{
		title: 'Total Tax',
		dataIndex: 'total_tax_amt',
		key: 'total_tax_amt',
		render: (total_tax_amt) => <Rupee>{total_tax_amt}</Rupee>,
	},
	{
		title: 'Net Amt',
		dataIndex: 'net_amt',
		key: 'net_amt',
		render: (net_amt) => <Rupee>{net_amt}</Rupee>,
	},
	{
		title: 'Free qty',
		dataIndex: 'free_qty',
		key: 'free_qty',
		render: (free_qty) => <Text>{validateQty(free_qty)}</Text>,
	},
];

const deliveryColumns = [
	{
		title: 'Product',
		dataIndex: 'product_name',
		key: 'product_name',
		render: (product_name) => (
			<>
				<Text>{product_name[0]}</Text>
				<Text>({product_name[1]})</Text>
			</>
		),
	},
	{
		title: 'MRP',
		dataIndex: 'mrp',
		key: 'mrp',
		render: (mrp) => <Rupee>{mrp}</Rupee>,
	},
	{
		title: 'Ordered Qty',
		dataIndex: 'data',
		key: 'data',
		render: (data) => (
			<>
				<Text>{data?.qty}</Text>
				{data?.free_qty !== 0 && (
					<Text>
						<span
							style={{
								backgroundColor: '#0a910d',
								color: '#fff',
								padding: '2px',
								borderRadius: '4px',
							}}
						>
							Free Qty: {data?.free_qty}
						</span>
					</Text>
				)}
			</>
		),
	},
	{
		title: 'Picked Qty',
		dataIndex: 'pickedQty',
		key: 'pickedQty',
		render: (pickedQty) => <Text>{validateQty(pickedQty)}</Text>,
	},
	{
		title: 'W/H Due',
		dataIndex: 'warehouse_due',
		key: 'warehouse_due',
		render: (warehouse_due) => <Text>{validateQty(warehouse_due)}</Text>,
	},
	{
		title: 'Delivered Qty',
		dataIndex: 'delivered_qty',
		key: 'delivered_qty',
		render: (delivered_qty) => <Text>{validateQty(delivered_qty)}</Text>,
	},
	{
		title: 'Returned Qty',
		dataIndex: 'returnedQty',
		key: 'returnedQty',
		render: (returnedQty) => <Text>{validateQty(returnedQty)}</Text>,
	},
];

const valueFooter = (orderDetails) => (
	<Table.Summary.Row>
		<Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
		<Table.Summary.Cell index={1}></Table.Summary.Cell>
		<Table.Summary.Cell index={2}></Table.Summary.Cell>
		<Table.Summary.Cell index={3}>{orderDetails?.totalOrderedQty}</Table.Summary.Cell>
		<Table.Summary.Cell index={4}></Table.Summary.Cell>
		<Table.Summary.Cell index={5}>
			<Rupee>{orderDetails?.total_gross_amount}</Rupee>
		</Table.Summary.Cell>
		<Table.Summary.Cell index={6}>
			<Rupee>{orderDetails?.total_discount}</Rupee>
		</Table.Summary.Cell>
		<Table.Summary.Cell index={7}>
			<Rupee>{orderDetails?.total_tax_amount}</Rupee>
		</Table.Summary.Cell>
		<Table.Summary.Cell index={8}>
			<Rupee>{orderDetails?.total_net_amount}</Rupee>
		</Table.Summary.Cell>
		<Table.Summary.Cell index={9}></Table.Summary.Cell>
	</Table.Summary.Row>
);

const deliveryFooter = (orderDetails) => (
	<Table.Summary.Row>
		<Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
		<Table.Summary.Cell index={1}></Table.Summary.Cell>
		<Table.Summary.Cell index={2}>{orderDetails?.totalOrderedQty}</Table.Summary.Cell>
		<Table.Summary.Cell index={3}>{orderDetails?.totalPickedQty}</Table.Summary.Cell>
		<Table.Summary.Cell index={4}></Table.Summary.Cell>
		<Table.Summary.Cell index={5}></Table.Summary.Cell>
		<Table.Summary.Cell index={5}></Table.Summary.Cell>
	</Table.Summary.Row>
);

// eslint-disable-next-line react/prop-types
export default function SalesOrderShowTable({ data, tab }) {
	const tableData = data?.OrderDetails?.map((x, index) => ({
		key: index,
		product_name: [x?.Product?.name, x?.Product?.code],
		product_code: x?.Product?.code,
		batch_no: x?.batch_no,
		mrp: x?.ProductDetail?.mrp,
		ordered_qty: x?.qty,
		selling_price: x?.selling_price,
		gross_amt: x?.gross_amount,
		total_discount: x?.total_discount,
		total_tax_amt: x?.total_tax_amount,
		net_amt: x?.net_amount,
		free_qty: x?.free_qty,
		delivered_qty: x?.delivered_qty,
		pickedQty: x?.picked_qty,
		warehouse_due: x?.warehouse_due,
		returnedQty: x?.return_qty,
		data: x,
	}));

	return (
		<Table
			columns={tab === 'value' ? valueColumns : deliveryColumns}
			dataSource={tableData}
			pagination={false}
			summary={() => (tab === 'value' ? valueFooter(data) : deliveryFooter(data))}
			// This is for fixing header at the top in salesorder view page
			scroll={{ x: 950, y: 'calc(100vh - 220px)' }}
		/>
	);
}

SalesOrderShowTable.propTypes = {
	data: PropTypes.object,
	tab: PropTypes.string,
};
