/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import { SearchField } from 'components/common/custom-table/Style';
import { getGRNList } from 'store/GRNSlice';
import { Box, AsyncSelect, Button } from 'atoms';
import { RCTable, LoadAsyncSelectComponent, LabelWrapper, SearchBar } from 'components/common';
import { getFcListOnLoad, getFcOnSearchList } from 'store/masters';
import {
	GRNListColumns,
	toAsysnOption,
	searchBox,
	SelectFilterLabelOptions,
	handleFilterChange,
	CommonFCBrandsQueries,
	initialPaginateConfigWithoutQ,
} from 'utils';
import { useQueryParams } from 'hooks';
import { UploadInvoiceModal } from 'components/modals/UploadInvoiceModal';
import PdfModal from 'components/modals/PdfModal';
import ShowGrnUploadLogs from 'components/modals/ShowGrnUploadsLogs';

export default function GRN() {
	const [rowData, setRowData] = useState({});
	const dispatch = useDispatch();
	const [btnClickSearh, setBtnClickSearh] = useState({});

	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);
	const [paginateConfig, setpaginateConfig] = useQueryParams({
		filterQuery: CommonFCBrandsQueries,
	});
	const [uploadInvoiceModalOpen, setUploadInvoiceModalOpen] = useState(false);
	const [totalData, setTotalData] = useState();
	const [pdfModalVisible, setPdfModalVisible] = useState({
		showModal: false,
		data: null,
		ShowLogs: null,
	});
	const [grnLogsModal, setGrnLogsModal] = useState({ grnLogs: null, visible: false });

	const handleInvocieView = (res, ShowLogs = false) => {
		setPdfModalVisible({ showModal: true, data: res, ShowLogs });
	};

	const { auth } = useSelector((state) => state?.auth);
	const [filesUploaded, setFilesUploaded] = useState(0);

	useEffect(() => {
		setFcList({ type: 'Loading' });

		console.log('filesUploaded ', filesUploaded);

		dispatch(
			getFcListOnLoad({ fcIds: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [] })
		).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});
	}, [dispatch]);

	function refreshData() {
		window.location.reload();
	}

	const handleChangeSelect = (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value

		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		setpaginateConfig((prev) => ({
			...handleFilterChange(name, value?.value ? [value?.value] : [], prev),
			brands: [],
		}));
	};

	const handleUploadModalOpen = (res) => {
		console.log('Modal window opened', res);
		console.log('purchase_order_id ', res?.PurchaseOrderDetails[0]?.purchase_order_id);
		if (res?.PurchaseOrderDetails[0]?.purchase_order_id) {
			setTotalData(res);
			setUploadInvoiceModalOpen(true);
		}
	};

	const handleGrnLogsModalOpen = (data) => {
		setGrnLogsModal({ grnLogs: data, visible: true });
	};

	const getUpdatedData = () => {
		setBtnClickSearh(Math.random());
	};

	return (
		<>
			<Box>
				<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
					<LoadAsyncSelectComponent
						loadFCs={paginateConfig?.fcs}
						setList={(val) => setBrandList(val)}
						options={fcsList}
					>
						<AsyncSelect
							style={searchBox}
							allowClear
							name="fcs"
							showSearch
							fetchOptions={getFcOnSearchList}
							transformOptions={(val) => toAsysnOption(val)}
							defaultValue={paginateConfig?.fcs}
							value={paginateConfig?.fcs}
							placeholder="Select FC(s)"
							onChange={(value, options) => {
								handleChangeSelect('fcs', value, options);
							}}
							selectOptions={[...(fcsList.data || [])]}
						/>
					</LoadAsyncSelectComponent>
				</LabelWrapper>

				<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
					<Select
						name="fields"
						style={searchBox}
						allowClear
						showSearch
						label="brands"
						mode="multiple"
						value={paginateConfig?.brands}
						filterOption={SelectFilterLabelOptions}
						options={brandsList}
						onChange={(value) =>
							setpaginateConfig((prev) => ({
								...prev,
								brands: value,
								...initialPaginateConfigWithoutQ,
							}))
						}
					/>
				</LabelWrapper>
				<LabelWrapper label="Search by invoice no." marginspace="0 0 4px 1rem">
					<SearchField style={{ height: '39px', width: '200px', marginLeft: '1rem' }}>
						<SearchBar
							style={{ height: '40px' }}
							placeholder="Search by invoice no."
							queryValue={paginateConfig.invoice_no}
							onSearch={(val) => {
								setpaginateConfig((prev) => ({
									...prev,
									invoice_no: val,
									offset: 0,
									limit: 10,
									currentPage: 1,
								}));
							}}
						/>
					</SearchField>
				</LabelWrapper>
				<Button
					onClick={() => setBtnClickSearh(Math.random())}
					style={{ marginLeft: '40px' }}
				>
					Search
				</Button>
			</Box>
			<Box mt={3}>
				<RCTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					dispatchAction={getGRNList}
					columns={GRNListColumns({
						handleGrnLogsModalOpen,
						handleUploadModalOpen,
						handleInvocieView,
						defaultSort: paginateConfig?.sort_direction || 'DESC',
						defaultsortField: paginateConfig?.sort_column || 'createdAt',
					})}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
			{uploadInvoiceModalOpen && (
				<UploadInvoiceModal
					toggleState={[uploadInvoiceModalOpen, setUploadInvoiceModalOpen]}
					handleUploadModalOpen={handleUploadModalOpen}
					rowData={totalData || {}}
					auth={auth}
					setTotalData={setTotalData}
					fileCount={1}
					refreshData={refreshData}
					grnUpload
					setFilesUploaded={setFilesUploaded}
				/>
			)}
			<PdfModal
				visible={pdfModalVisible?.showModal}
				data={pdfModalVisible?.data}
				showLogs={pdfModalVisible?.ShowLogs}
				onClose={() =>
					setPdfModalVisible({
						showModal: false,
						data: null,
						ShowLogs: null,
					})
				}
				getUpdatedData={getUpdatedData}
				grnUpload
			/>
			<ShowGrnUploadLogs
				visible={grnLogsModal.visible}
				onClose={() => setGrnLogsModal({ grnLogs: null, visible: false })}
				data={grnLogsModal?.grnLogs}
			/>
		</>
	);
}
