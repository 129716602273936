import React from 'react';
import { Form } from 'formik-antd';
import styled from 'styled-components';

export const Input = styled(Form.Item)`
	width: 336px;
	padding: 5px 20px 5px 25px;
	border-radius: 9px;
	background-color: ${(props) => props.theme.colors.white};
	.ant-form-item-label {
		padding: 0;
		label {
			font-size: 10px;
			line-height: 14px;
			color: ${(props) => props.theme.colors.EerieBlack};
		}
	}

	input {
		font-size: 14px;
		line-height: 19px;
	}
`;

export default function LoginInput(props) {
	return (
		<Input
			hasFeedback={false}
			showValidateSuccess={false}
			labelCol={{ span: 24 }}
			wrapperCol={{ span: 24 }}
			{...props}
		/>
	);
}
