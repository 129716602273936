import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authSlice from 'store/authSlice';
import userSlice from 'store/userSlice';
import salesManSlice from 'store/salesManSlice';
import appSlice from 'store/appSlice';
import { getPreloadedState, saveToLocalStorage } from 'store/localStorage';
import GRNSlice from 'store/GRNSlice';
import CategorySlice from 'store/categorySlice';
import InventoryBatchSlice from './inventory';
import {
	salesOrderSlice,
	deliveryAllocationSlice,
	returnToFCSlice,
	salesReturnSlice,
	wmslogsSlice,
	grnLogsSlice,
	brandSalesReturnSlice,
} from 'store/fulfilment';
import masterSlice from './masters';
import reportSlice from './downloads/reportSlice';
import OnBoardSlice from './OnBoardSlice';
import CollectionSlice from './collectionSlice';
import CashierSlice from './cashierSlice';
import invoiceSlice from 'store/invoiceSlice';
import uploadInvoiceSlice from './uploadInvoiceSlice';

const combinedReducer = combineReducers({
	app: appSlice,
	auth: authSlice,
	user: userSlice,
	salesman: salesManSlice,
	GRN: GRNSlice,
	Category: CategorySlice,
	// Inventory
	inventoryBatch: InventoryBatchSlice,
	// Fulfilment
	salesOrder: salesOrderSlice,
	deliveryAllocation: deliveryAllocationSlice,
	returnToFC: returnToFCSlice,
	salesReturn: salesReturnSlice,
	brandSalesReturn: brandSalesReturnSlice,
	// Masters
	masters: masterSlice,
	OnBoard: OnBoardSlice,
	// downloads
	report: reportSlice,
	cashier: CashierSlice,
	invoice: invoiceSlice,
	collection: CollectionSlice,
	wmsLogs: wmslogsSlice,
	grnLogs: grnLogsSlice,
	rfcInvoiceUpload: uploadInvoiceSlice,
});

const rootReducer = (state, action) => {
	/**
	 * to reset whole app state to initial state
	 */
	if (action.type === 'auth/setUser') {
		state = undefined;
	}

	return combinedReducer(state, action);
};

const store = configureStore({
	reducer: rootReducer,
	preloadedState: getPreloadedState(),
});

function onStateChange() {
	saveToLocalStorage(store.getState());
}

store.subscribe(onStateChange);

export default store;
