/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import moment from 'moment';
import { message } from 'antd';
import { capitalize, isEmpty } from 'lodash';
import { Box, Flex } from 'atoms';
import { OrderMissingIcons, NotInTerminalIcons, UndeliveredIcons } from 'utils';
import { LabelText, OrderRender } from './styles';

// #region Helper functions

export const CollectionOrderStatus = ({
	ordermissing,
	status,
	notinterminal,
	setCollectionStatusmodal,
	unverified,
	invoice_settled,
}) => [
	{
		label: 'Order Missing',
		icons: <OrderMissingIcons />,
		count: ordermissing?.data?.count || 0,
		onClick: () =>
			setCollectionStatusmodal({
				orderStatus: 'ordermissing',
				visible: true,
			}),
		hidden:
			ordermissing?.data?.count > 0 && !isEmpty(ordermissing?.data) && status !== 'assigned',
	},
	{
		label: 'Undelivered',
		icons: <UndeliveredIcons />,
		count: notinterminal?.data?.count || 0,
		onClick: () =>
			setCollectionStatusmodal({
				orderStatus: 'undelivered',
				visible: true,
			}),
		hidden:
			notinterminal?.data?.count > 0 &&
			!isEmpty(notinterminal?.data) &&
			status !== 'assigned',
	},
	{
		label: 'Unverified',
		icons: <NotInTerminalIcons />,
		count: unverified?.data?.count || 0,
		onClick: () =>
			setCollectionStatusmodal({
				orderStatus: 'unverified',
				visible: true,
			}),
		hidden: unverified?.data?.count > 0 && !isEmpty(unverified?.data) && status !== 'assigned',
	},
	{
		label: 'Invoice Settled',
		icons: <NotInTerminalIcons />,
		count: invoice_settled?.data?.count || 0,
		onClick: () =>
			setCollectionStatusmodal({
				orderStatus: 'invoice_settled',
				visible: true,
			}),
		hidden:
			invoice_settled?.data?.count > 0 &&
			!isEmpty(invoice_settled?.data) &&
			status !== 'assigned',
	},
];

export const invoiceVerificationStatus = {
	VerifiedBySegregator: 'VerifiedBySegregator',
	VerifiedByCashier: 'VerifiedByCashier',
	PartialVerifiedByCashier: 'PartialVerifiedByCashier',
	RejectedBySegregator: 'RejectedBySegregator',
	RejectedByCashier: 'RejectedByCashier',
};

export const DaysList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const DatePick = [
	{ key: 'all', value: 'All' },
	{ key: 'today', value: 'Today' },
	{ key: 'fut_dates', value: 'Future Dates' },
];
export const RefineDataByStatus = (key, countData = [], data = []) => {
	const totalData = data?.filter((x) => x?.collection_order_status === key);
	const totalCount = countData?.filter((x) => x?.collection_order_status === key)?.[0]
		?.order_count;

	return {
		data: totalData,
		count: totalCount,
	};
};

export const downloadExcel = (response, fileName) => {
	const blob = new Blob([response], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	});
	const url = URL.createObjectURL(blob);
	const element = document.createElement('a');

	element.setAttribute('href', url);
	element.setAttribute('download', fileName);
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);

	message.success('File downloaded successfully!');
};

export const calculateSummaryDatas = (data) =>
	data?.summary_data?.reduce(
		(acc, curr) => ({
			totalInvoice: (acc?.totalInvoice || 0) + parseFloat(curr?.invoice_record_count || 0),
			totalAssignedInvoice:
				(acc?.totalAssignedInvoice || 0) + parseFloat(curr?.assinged_invoice_count || 0),
			totalUnAssignedInvoice:
				(acc?.totalUnAssignedInvoice || 0) +
				parseFloat(curr?.unassigned_invoice_count || 0),
		}),
		{}
	);

// export const calculateSummaryDatasByDays = (data) =>
// 	data?.summary_data?.reduce((acc, curr) => {
// 		if (!acc[curr.summary_weekday_number]) {
// 			acc[curr.summary_weekday_number] = {
// 				totalAssignedInvoice: parseFloat(curr?.assinged_invoice_count || 0),
// 				totalUnAssignedInvoice: parseFloat(curr?.unassigned_invoice_count || 0),
// 			};
// 			return acc;
// 		}
// 		acc[curr.summary_weekday_number] = {
// 			totalAssignedInvoice:
// 				(acc[curr.summary_weekday_number]?.totalAssignedInvoice || 0) +
// 				parseFloat(curr?.assinged_invoice_count || 0),
// 			totalUnAssignedInvoice:
// 				(acc[curr.summary_weekday_number]?.totalUnAssignedInvoice || 0) +
// 				parseFloat(curr?.unassigned_invoice_count || 0),
// 		};
// 		return acc;
// 	}, {});

export const SelectFilterLabelOptions = (input, option) =>
	option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;

// label looks like  Friday - 12-12-2022
export const getdayOptions = () =>
	[...new Array(4)].map((x, i) => ({
		label: `${moment().add(i, 'days').format('dddd')} - ${moment()
			.add(i, 'days')
			.format('DD-MM-YYYY')}`,
		value: moment().add(i, 'days').format('YYYY-MM-DD'),
	}));

export function SelectFilterOption(res, salesmanList = [], isUnassigned = false) {
	if (isEmpty(res)) return [];

	let updatedList = [...res];
	if (isUnassigned) {
		updatedList = [...updatedList.filter((x) => x.id !== salesmanList[0]?.salesperson_id)];
	}

	return (
		updatedList.map((x) => ({
			label: `${capitalize(x?.name || '')} - ${x?.mobile || ''}`,
			value: x?.id,
		})) || []
	);
}

export const takeTotalNumber = (data, weekNumber, weekDate) =>
	data?.reduce((acc, cur) => {
		const key = `${cur[weekNumber]}_${cur[weekDate]}`;
		if (!acc[key]) {
			acc[key] = 1;
			return acc;
		}
		acc[key] += 1;
		return acc;
	}, {});

export const SelectFilterOptions = (input, option) =>
	option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

// #endregion

// #region Accordian Header Render

export function CollectionOrderStatusRender({ label, count, onClick }) {
	console.log(label, count);
	return (
		<OrderRender onClick={onClick}>
			{label}: <Box as="span">{count}</Box>
		</OrderRender>
	);
}

// #endregion

// Summary data dashboard

export function RenderTotalInvoiceDayWise({ data }) {
	return (
		<Box
			margin="auto"
			backgroundColor="#F6F6F6"
			marginBottom="1rem"
			padding="12px"
			textAlign="left"
		>
			<Box fontSize="12px" marginBottom="8px">
				{data?.week_day_name?.slice(0, 3)}
				&nbsp;{'  | '}&nbsp;
				{data?.collection_date || ''}
			</Box>
			<Flex flexWrap="wrap">
				<Box flex="0 0 50%">
					<LabelText as="span" isRed marginRight="8px">
						{data?.assinged_invoice_count || 0}
					</LabelText>
					<LabelText as="span" marginRight="8px">
						Assigned
					</LabelText>
				</Box>
				<Box flex="0 0 50%">
					<LabelText as="span" isRed marginRight="8px">
						{data?.unassigned_invoice_count || 0}
					</LabelText>
					<LabelText as="span">Unassigned</LabelText>
				</Box>
			</Flex>
		</Box>
	);
}

// ends here
