import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { DateFormat, Rupee } from 'components';

const TH = styled.th`
	text-align: left;
	font-weight: bold;
	font-size: 14px;
	padding: 0.5rem 0;
`;

const TD = styled.td`
	text-align: left;
	font-size: 14px;
	padding: 1.5rem 0;
`;
export default function CollectionStatus({ data = [] }) {
	return (
		<table style={{ width: '100%' }}>
			<thead>
				<tr>
					<TH>Payment Type</TH>
					<TH>Amount</TH>
					<TH>Refernce Number</TH>
					<TH>Due Date</TH>
					<TH>Verification Status</TH>
					<TH>Settlement Status</TH>
					<TH>Updated on</TH>
				</tr>
			</thead>
			<tbody>
				{data?.map((x) => (
					<tr key={x.id}>
						<TD>{x.payment_type ? x.payment_type : '-'}</TD>
						<TD>
							<Rupee>{x.amount ? x.amount : '-'}</Rupee>
						</TD>
						<TD>{x.reference_number ? x.reference_number : '-'}</TD>
						<TD>{x.due_date ? <DateFormat date={x.due_date}></DateFormat> : '-'}</TD>
						<TD>
							{x.payment_verification_status ? x.payment_verification_status : '-'}
						</TD>
						<TD>{x.payment_settlement_status ? x.payment_settlement_status : '-'}</TD>
						<TD>
							<DateFormat date={x.created_at ? x.created_at : '-'} format="HMSA" />
						</TD>
					</tr>
				))}
			</tbody>
		</table>
	);
}
CollectionStatus.propTypes = {
	data: PropTypes.array,
};
