import { createSlice } from '@reduxjs/toolkit';
import { message as toaster } from 'antd';
import { API } from 'api';

const initialState = {
	userList: [],
};

export const reportSlice = createSlice({
	name: 'report',
	initialState,
	reducers: {
		setUserList: (state, { payload }) => {
			state.userList = payload;
		},
	},
});

export const downloadExcel = (response, fileName) => {
	const blob = new Blob([response], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	});
	const url = URL.createObjectURL(blob);
	const element = document.createElement('a');

	element.setAttribute('href', url);
	element.setAttribute('download', fileName);
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);

	toaster.success('File downloaded successfully!');
};

export const getCollectionDetailsList =
	({
		limit = 10,
		offset = 0,
		sort_column = 'id',
		sort_direction = 'DESC',
		currentPage,
		query,
		fcs,
		brands,
		...rest
	}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/reports/list',
			data: {
				filter: {
					...rest,
					brands: brands?.length ? brands : undefined,
					fcs: fcs?.length ? fcs : undefined,
				},
				sort: { sort_column, sort_direction },
				page: { limit, offset },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getExportSalesOrderReport = (data) => async () => {
	const CREDENTIALS = {
		url: '/champ/reports/sales_order_invoice_wise',
		method: 'post',
		data,
		config: {
			responseType: 'blob',
		},
	};
	return API.common(CREDENTIALS).then((response) => downloadExcel(response, 'Sales Order'));
};

export const getCurrentInventoryReport = (data) => async () => {
	const headers = { 'Content-Type': 'blob' };
	const CREDENTIALS = {
		url: '/champ/reports/current_inventory',
		method: 'post',
		data,
		headers,
		config: {
			responseType: 'blob',
		},
	};

	return API.common(CREDENTIALS).then((response) => {
		downloadExcel(response, 'current_inventory');
	});
};
export const getCurrentUnSellableReport = (data) => async () => {
	const CREDENTIALS = {
		url: '/champ/reports/current_unsellable_inventory',
		method: 'post',
		data,
		config: {
			responseType: 'blob',
		},
	};
	return API.common(CREDENTIALS).then((response) =>
		downloadExcel(response, 'unsalable_inventory')
	);
};

export const getOutstandingInvoiceReport = (data) => async () => {
	const CREDENTIALS = {
		url: '/champ/reports/outstanding_invoice',
		method: 'post',
		data,
		config: {
			responseType: 'blob',
		},
	};
	return API.common(CREDENTIALS).then((response) =>
		downloadExcel(response, 'outstanding_invoice')
	);
};

export const getCurrentCashInHandReport = (data) => async () => {
	const CREDENTIALS = {
		url: '/champ/reports/current_cash_in_hand',
		method: 'post',
		data,
		config: {
			responseType: 'blob',
		},
	};

	return API.common(CREDENTIALS).then((response) =>
		downloadExcel(response, 'current_cash_in_hand')
	);
};

export const getGrnReport = (data) => async () => {
	const CREDENTIALS = {
		url: '/champ/reports/grn_invoice_product_wise',
		method: 'post',
		data,
		config: {
			responseType: 'blob',
		},
	};

	return API.common(CREDENTIALS).then((response) =>
		downloadExcel(response, 'grn_invoice_product_wise')
	);
};

export const getDeliveryInvoiceReport = (data) => async () => {
	const CREDENTIALS = {
		url: '/champ/reports/delivery_return_invoice_wise',
		method: 'post',
		data,
		config: {
			responseType: 'blob',
		},
	};

	return API.common(CREDENTIALS).then((response) =>
		downloadExcel(response, 'delivery_return_invoice_wise')
	);
};

export const getDeliveryVehicleReport = (data) => async () => {
	const CREDENTIALS = {
		url: '/champ/reports/delivery_return_vehicle_wise',
		method: 'post',
		data,
		config: {
			responseType: 'blob',
		},
	};

	return API.common(CREDENTIALS).then((response) =>
		downloadExcel(response, 'delivery_return_vehicle_wise')
	);
};

export const getCollectionSummaryReport = (data) => async () => {
	const CREDENTIALS = {
		url: '/champ/reports/collection_summary',
		method: 'post',
		data,
		config: {
			responseType: 'blob',
		},
	};

	return API.common(CREDENTIALS).then((response) =>
		downloadExcel(response, 'collection_summary')
	);
};

export const getCollectionDetailReport = (data) => async () => {
	const CREDENTIALS = {
		url: '/champ/reports/collection_detail',
		method: 'post',
		data,
		config: {
			responseType: 'blob',
		},
	};

	return API.common(CREDENTIALS).then((response) => downloadExcel(response, 'collection_detail'));
};

export const getSettledUserList = (values) => async (dispatch) => {
	const CREDENTIALS = {
		url: '/champ/user/list',
		method: 'post',
		data: values,
	};

	return API.common(CREDENTIALS).then((response) => {
		dispatch(setUserList(response?.data?.rows));
	});
};

export const getSalesOrderInvoiceReport = (data) => async () => {
	const CREDENTIALS = {
		url: '/champ/reports/sales_order_invoice_wise',
		method: 'post',
		data,
		config: {
			responseType: 'blob',
		},
	};

	return API.common(CREDENTIALS).then((response) =>
		downloadExcel(response, 'sales_order_invoice_wise')
	);
};

export const getSalesOrderProductReport = (data) => async () => {
	const CREDENTIALS = {
		url: '/champ/reports/sales_order_invoice_product_wise',
		method: 'post',
		data,
		config: {
			responseType: 'blob',
		},
	};
	return API.common(CREDENTIALS).then((response) =>
		downloadExcel(response, 'sales_order_invoice_product_wise')
	);
};

export const getCollectionDetailsReport = (data) => async () => {
	const CREDENTIALS = {
		url: '/champ/reports/download',
		method: 'post',
		data,
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export const { setUserList } = reportSlice.actions;
export default reportSlice.reducer;
