import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Arrow from 'assets/arrow.svg';
import { Image, Flex } from 'atoms';

const Container = styled(Flex)`
	align-items: center;
	flex-wrap: wrap;
`;

const StatsText = styled.p`
	font-size: 1rem;
	line-height: 1rem;
`;

const StatsContainer = styled.div`
	display: flex;
	justify-content: space-around;
	padding: ${(props) => (props.showDetails ? '1rem 2rem' : '1rem 0.75rem')};
	border-left: 4px solid ${(props) => props.theme.colors.Ming};
	background-color: ${(props) => props.theme.colors.Cultured};
	min-width: ${(props) => (props.showDetails ? '' : '25%')};
	transition: all 0.5s;
	flex-wrap: wrap;
	gap: 80px;
	div {
		visibility: ${(props) => !props.showDetails && 'hidden'};
	}
`;

const StatsControl = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	margin-left: 2rem;
	border-radius: 50%;
	background-color: ${(props) => props.theme.colors.Cultured};
	cursor: pointer;

	img {
		transform: ${(props) => (!props.show ? 'rotate(180deg)' : 'none')};
	}
`;

export default function UserDashboard({ children, statsText = 'View Details' }) {
	const [showDetails, setShowDetails] = useState(true);

	return (
		<Container>
			<StatsContainer showDetails={showDetails}>
				{showDetails ? children : <StatsText> {statsText}</StatsText>}
			</StatsContainer>
			<StatsControl onClick={() => setShowDetails(!showDetails)} show={showDetails}>
				<Image src={Arrow} alt="arrow" />
			</StatsControl>
		</Container>
	);
}
UserDashboard.propTypes = {
	children: PropTypes.node,
	statsText: PropTypes.string,
};
