/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Image, Flex } from 'atoms';
import { Line, Rupee } from 'components';
import { TotalCount } from 'components/pages/cashier';
import userIcon from 'assets/svg/user-icon.svg';
import cash from 'assets/svg/cash.svg';
import online from 'assets/svg/online.svg';
import utr from 'assets/svg/utr.svg';
import cheque from 'assets/svg/cheque.svg';
import roundDown from 'assets/svg/round-down.svg';
import roundUp from 'assets/svg/round-up.svg';

export default function CashierAllStats() {
	const { collectionList = {} } = useSelector((state) => state.invoice);
	const [viewMore, setViewMore] = useState(false);

	function handleShowMoreValues() {
		setViewMore(!viewMore);
	}

	return (
		<Container>
			<Flex p="25px">
				<Card>
					<Image width={36} height={36} src={userIcon} alt="totalBillBack" />
					<Flex flexDirection="column" pl="0.5rem">
						<Title>Ready For Verification</Title>
						<Count>
							{collectionList?.data_summary
								?.total_rows_ready_for_verification_by_cashier || 0}
						</Count>
					</Flex>
				</Card>
				<Line height="50px" mx="1rem" />
				<Card>
					<Image width={36} height={36} src={cash} alt="cash" mr="0.5rem" />
					<Flex flexDirection="column" pl="0.5rem">
						<Title>Total Cash</Title>
						<Count>
							<Rupee>{collectionList?.data_summary?.total_cash_amount || 0}</Rupee>
							<TotalCount count={collectionList?.data_summary?.total_cash_count} />
						</Count>
					</Flex>
				</Card>
				<Line height="50px" mx="1rem" />
				<Card>
					<Image width={36} height={36} src={cheque} alt="cheque" mr="0.5rem" />
					<Flex flexDirection="column" pl="0.5rem">
						<Title>Total Cheque</Title>
						<Count>
							<Rupee>{collectionList?.data_summary?.total_cheque_amount || 0}</Rupee>
							<TotalCount count={collectionList?.data_summary?.total_cheque_count} />
						</Count>
					</Flex>
				</Card>
				<Line height="50px" mx="1rem" />
				<Card>
					<Image width={36} height={36} src={utr} alt="utr" mr="0.5rem" />
					<Flex flexDirection="column" pl="0.5rem">
						<Title>Total UPI</Title>
						<Count>
							<Rupee>{collectionList?.data_summary?.total_upi_amount || 0}</Rupee>
							<TotalCount count={collectionList?.data_summary?.total_upi_count} />
						</Count>
					</Flex>
				</Card>
				<Line height="50px" mx="1rem" />
				<Card>
					<Image width={36} height={36} src={online} alt="online" mr="0.5rem" />
					<Flex flexDirection="column" pl="0.5rem">
						<Title>Total NEFT</Title>
						<Count>
							<Rupee>{collectionList?.data_summary?.total_online_amount || 0}</Rupee>
							<TotalCount count={collectionList?.data_summary?.total_online_count} />
						</Count>
					</Flex>
				</Card>
				<Card onClick={handleShowMoreValues} cursor="pointer">
					<Image
						width={36}
						height={36}
						src={viewMore ? roundUp : roundDown}
						alt="pending"
						mr="0.5rem"
					/>
				</Card>
			</Flex>

			{viewMore && (
				<Hide>
					<Row p="25px" justifyContent="space-between">
						<Card>
							<Flex flexDirection="column" pl="0.5rem">
								<Title>Pending:</Title>
								<Count>
									{collectionList?.data_summary?.total_rows_pending_by_cashier ||
										0}
								</Count>
							</Flex>
						</Card>
						<Card>
							<Flex flexDirection="column" pl="0.5rem">
								<Title>Cash Pending:</Title>
								<Count>
									<Rupee>
										{collectionList?.data_summary?.total_cash_pending_amount ||
											0}
									</Rupee>
								</Count>
							</Flex>
						</Card>
						<Card>
							<Flex flexDirection="column" pl="0.5rem">
								<Title>Cheque Pending:</Title>
								<Count>
									<Rupee>
										{collectionList?.data_summary
											?.total_cheque_pending_amount || 0}
									</Rupee>{' '}
									{/* ({collectionList?.data_summary?.total_cheque_count}) */}
								</Count>
							</Flex>
						</Card>
						<Card>
							<Flex flexDirection="column" pl="0.5rem">
								<Title>UPI Pending:</Title>
								<Count>
									<Rupee>
										{collectionList?.data_summary?.total_upi_pending_amount ||
											0}
									</Rupee>{' '}
									{/* ({collectionList?.data_summary?.total_upi_count}) */}
								</Count>
							</Flex>
						</Card>
						<Card>
							{/* <Flex flexDirection="column" pl="0.5rem">
								<Title>NEFT Pending:</Title>
								<Count>
									<Rupee>
										{collectionList?.data_summary?.total_online_amount || 0}
									</Rupee>{' '}
									({collectionList?.data_summary?.total_online_count})
								</Count>
							</Flex> */}
						</Card>
					</Row>
					<Row p="25px" justifyContent="space-between">
						<Card>
							<Flex flexDirection="column" pl="0.5rem">
								<Title>Verified:</Title>
								<Count>
									{collectionList?.data_summary?.total_rows_verified_by_cashier ||
										0}
								</Count>
							</Flex>
						</Card>
						<Card>
							<Flex flexDirection="column" pl="0.5rem">
								<Title>Cash Verified:</Title>
								<Count>
									<Rupee>
										{collectionList?.data_summary?.total_cash_verified_amount ||
											0}
									</Rupee>
								</Count>
							</Flex>
						</Card>
						<Card>
							<Flex flexDirection="column" pl="0.5rem">
								<Title>Cheque Verified:</Title>
								<Count>
									<Rupee>
										{collectionList?.data_summary
											?.total_cheque_verified_amount || 0}
									</Rupee>{' '}
									{/* ({collectionList?.data_summary?.total_cheque_count}) */}
								</Count>
							</Flex>
						</Card>
						<Card>
							<Flex flexDirection="column" pl="0.5rem">
								<Title>UPI Verified:</Title>
								<Count>
									<Rupee>
										{collectionList?.data_summary?.total_upi_verified_amount ||
											0}
									</Rupee>{' '}
									{/* ({collectionList?.data_summary?.total_upi_count}) */}
								</Count>
							</Flex>
						</Card>
						<Card>
							{/* <Flex flexDirection="column" pl="0.5rem">
								<Title>NEFT Verified:</Title>
								<Count>
									<Rupee>
										{collectionList?.data_summary?.total_online_amount || 0}
									</Rupee>{' '}
									({collectionList?.data_summary?.total_online_count})
								</Count>
							</Flex> */}
						</Card>
					</Row>
					<Row p="25px" justifyContent="space-between">
						<Card>
							<Flex flexDirection="column" pl="0.5rem">
								<Title>Partially Verified:</Title>
								<Count>
									{collectionList?.data_summary
										?.total_rows_partial_verified_by_cashier || 0}
								</Count>
							</Flex>
						</Card>
					</Row>
				</Hide>
			)}
		</Container>
	);
}

export const Container = styled(Flex)`
	flex-direction: column;
	justify-content: flex-end;
	border: 1px solid ${(props) => props.theme.colors.Isabelline};
	border-radius: 7px 7px 10px 10px;
	margin-bottom: 1.5rem;
	background-color: ${(props) => props.theme.colors.white};
`;

export const Hide = styled(Flex)`
	flex-direction: column;
	position: absolute;
	top: 100px;
	z-index: 4;
	background: #fff;
	width: 100%;
	border: 1px solid #edebe9;
	border-top: 0;
	box-shadow: 0 0 0;
`;

export const Row = styled(Flex)`
	padding: 25px;
	justify-content: space-between;
	border-top: 1px solid ${(props) => props.theme.colors.Isabelline};
`;

export const Card = styled(Flex)`
	margin: 0 1rem;
	align-items: center;
`;

export const Title = styled.div`
	font-weight: 400;
	font-size: 12px;
	padding-bottom: 0.5rem;
	color: ${(props) => props.theme.colors.Silver};
`;

export const Count = styled.div`
	font-size: 16px;
	font-weight: 600;
`;
