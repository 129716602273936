export const DOWNLOADS_CHOICE = [
	{ value: 'sales_order_invoice_wise', label: 'Sales Order (Invoice Wise)' },
	{ value: 'sales_order_invoice_product_wise', label: 'Sales Order (Product Wise)' },
	{ value: 'sales_return_report', label: 'Sales Return Report' },
	{ value: 'delivery_return_invoice_wise', label: 'Delivery Return (Invoice Wise)' },
	{ value: 'delivery_return_vehicle_wise', label: 'Delivery Return (Vehicle Wise)' },
	{ value: 'collection_detail', label: 'Collection Detail' },
	{ value: 'stockone_rfc_report', label: 'Stockone RFC Report' },
	// { value: 'grn', label: 'GRN' },
	// { value: 'current_inventory', label: 'Current Inventory' },
	// { value: 'current_unsellable_inventory', label: 'Current Unsaleable Inventory' },
	// { value: 'collection_summary', label: 'Collection Summary' },
	// { value: 'collection_detail', label: 'Collection Detail' },
	// { value: 'outstanding_invoice', label: 'Outstanding Invoice' },
	// { value: 'settlement', label: 'Settlement' },
	// { value: 'bank_settlement', label: 'Bank Settlement' },
	// { value: 'current_cash_in_hand', label: 'Current Cash In Hand' },
];
