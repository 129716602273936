/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Modal } from 'antd';
import { Form, Select, DatePicker } from 'formik-antd';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FormInput, Box, AsyncSelect, Button, CustomInput } from 'atoms';
import { StyledSubmitButton } from 'components/forms/masters/helper';
import { Debug } from 'components/forms';
import { LoadAsyncSelectComponent } from 'components/common';
import {
	StoreinitialValues,
	StoreFormSchema,
	ACTIVE_INACTIVE_CHOICE,
	GENDER_CHOICE,
	GST_REG_TYPE_CHOICE,
	LEGAL_STRUCTURE_CHOICE,
	YES_NO_CHOICE,
	DOCUMENT_CURRENCY_CHOICE,
	yesNo_Choice,
	StoreRefinedPayload,
} from './helper';
import {
	disableFutureDate,
	toAsysnBankOption,
	toAsysnOption,
	SelectFilterLabelOptions,
	disablePastCurrentDate,
	SelectOptions,
} from 'utils';
import {
	getClientListonSearch,
	getStoreById,
	StoreUpdateCreate,
	getClientDataOnLoad,
} from 'store/OnBoardSlice';
import {
	getFcOnSearchList,
	getPincodeOnLoad,
	getPincodeListOnSearch,
	getFcListOnLoad,
} from 'store/masters';
import { getCategoryListOnSearch, getCategoryDataOnLoad } from 'store/categorySlice';

const unverifyEmptyData = {
	verified_by_delivery_boy: false,
	delivery_boy_id: null,
	delivery_boy_verified_at: null,
	verified_by_telecaller: false,
	telecaller_id: null,
	telecaller_verified_at: null,
	contact_person_fname: null,
	contact_person_mname: null,
	contact_person_lname: null,
	gstNumber: null,
	gst_reg_type: null,
	latitude: null,
	longitude: null,
	mobile: null,
	alternate_phone: null,
};

export default function StoreCreateEditForm() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id: storeId } = useParams();
	const { pathname } = useLocation();
	const { auth } = useSelector((state) => state?.auth);

	const [formInitial, setFormInitial] = useState(StoreinitialValues({}));
	const [fcList, setFcList] = useState({ type: 'NotAsked' });
	const [clientList, setClientList] = useState({ type: 'NotAsked' });
	const [pincodelist, setPincodelist] = useState({ type: 'NotAsked' });
	const [categoryList, setCategoryList] = useState({ type: 'NotAsked' });
	const [modalVisible, setModalVisible] = useState({
		unverify: false,
		modalVisible: false,
		telecallerverification: false,
		makeunverifyData: false,
	});
	const isEditPage = pathname.includes('edit');

	useEffect(() => {
		setFcList({ type: 'Loading' });
		setClientList({ type: 'Loading' });
		setPincodelist({ type: 'Loading' });
		setCategoryList({ type: 'Loading' });

		const hasEditForm = isEditPage ? dispatch(getStoreById({ storeId })) : Promise.resolve({});

		hasEditForm.then((editResponse) =>
			Promise.all([
				dispatch(getFcListOnLoad({ fcIds: editResponse?.FCs?.map((y) => y?.id) })),
				dispatch(getClientDataOnLoad({ clientId: editResponse?.client_id })),
				dispatch(getCategoryDataOnLoad({ categoryId: editResponse?.retail_category_id })),
				dispatch(getPincodeOnLoad({ pinCodeId: editResponse?.base_address_id })),
			]).then((res) => {
				const [fcdata, clientdata, categorydata, pincodedata] = res;

				if (isEditPage) {
					setFormInitial(
						StoreinitialValues({
							data: editResponse,
							fcData: toAsysnOption(fcdata),
							clientdata: toAsysnBankOption(clientdata),
							categorydata: SelectOptions(categorydata, 'retail_category', 'id'),
							pincodedata: SelectOptions(pincodedata, 'pincode', 'id'),
						})
					);
				}

				setFcList({ type: 'Success', data: toAsysnOption(fcdata) });
				setClientList({ type: 'Success', data: toAsysnBankOption(clientdata) });
				setCategoryList({
					type: 'Success',
					data: SelectOptions(categorydata, 'retail_category', 'id'),
				});
				setPincodelist({
					type: 'Success',
					data: SelectOptions(pincodedata, 'pincode', 'id'),
				});
			})
		);
	}, [dispatch, isEditPage, storeId]);

	const handleSubmit = (values, { setSubmitting }) => {
		const payload = {
			...values,
			base_address_id: values?.base_address_id?.value || '',
			client_id: values?.client_id?.value || '',
			fc_ids: values?.fc_ids?.map((x) => x?.value) || [],
			retail_category_id: values?.retail_category_id?.value || '',
			...(modalVisible?.makeunverifyData ? unverifyEmptyData : {}),
		};

		const refinedpayload = isEditPage
			? { data: { ...StoreRefinedPayload(payload), id: storeId } }
			: { data: StoreRefinedPayload(payload) };

		dispatch(StoreUpdateCreate(refinedpayload))
			.then(() => {
				navigate('/onboarding/store');
			})
			.finally(() => {
				setSubmitting(false);
			});
	};

	return (
		<Box>
			<Formik
				initialValues={formInitial}
				validationSchema={StoreFormSchema}
				enableReinitialize
				initialTouched={{ fc_ids: true, gender: true }}
				onSubmit={handleSubmit}
			>
				{({ setFieldValue, values, isSubmitting, isValid, dirty }) => (
					<Form layout="vertical">
						<Box padding="1rem 0">{!isEditPage ? 'Add' : 'Edit'} Store Details</Box>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="name" label="*Store Name">
									<CustomInput name="name" placeholder="Enter Store Name" />
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="nick_name" label="Store Nick Name">
									<CustomInput
										name="nick_name"
										placeholder="Enter Store Nick Name"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="code" label="*Store Code">
									<CustomInput
										disabled={isEditPage}
										name="code"
										placeholder="Enter Store Code"
									/>
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="client_id" label="*Client">
									<LoadAsyncSelectComponent options={clientList}>
										<AsyncSelect
											showSearch
											name="client_id"
											onChange={(value) => setFieldValue('client_id', value)}
											placeholder="Select Client"
											component="formikSelect"
											disabled={isEditPage}
											defaultValue={
												clientList?.data?.filter(
													(x) => x?.value === values?.client_id
												)[0] || undefined
											}
											selectOptions={[...(clientList?.data || [])]}
											fetchOptions={getClientListonSearch}
											transformOptions={(val) =>
												SelectOptions(val, 'name', 'id')
											}
										/>
									</LoadAsyncSelectComponent>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="fc_ids" label="*FC(s)">
									<LoadAsyncSelectComponent options={fcList}>
										<AsyncSelect
											mode="multiple"
											showSearch
											name="fc_ids"
											component="formikSelect"
											onChange={(value) => setFieldValue('fc_ids', value)}
											placeholder="Fc Type"
											defaultValue={
												fcList?.data?.filter((x) =>
													values?.fc_ids?.includes(x?.value)
												) || undefined
											}
											selectOptions={[...(fcList?.data || [])]}
											fetchOptions={getFcOnSearchList}
										/>
									</LoadAsyncSelectComponent>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="base_address_id" label="*Pincode">
									<LoadAsyncSelectComponent options={pincodelist}>
										<AsyncSelect
											className="customDropdown"
											component="formikSelect"
											name="base_address_id"
											showSearch
											defaultValue={
												pincodelist?.data?.filter(
													(x) => x?.value === values?.base_address_id
												)[0] || null
											}
											fetchOptions={getPincodeListOnSearch}
											onChange={(val) =>
												setFieldValue('base_address_id', val)
											}
											placeholder="Select Pincode"
											transformOptions={(val) =>
												SelectOptions(val, 'pincode', 'id')
											}
											selectOptions={[...(pincodelist.data || [])]}
										/>
									</LoadAsyncSelectComponent>
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="retail_category_id" label="*Retail Category">
									<LoadAsyncSelectComponent options={categoryList}>
										<AsyncSelect
											className="customDropdown"
											component="formikSelect"
											name="retail_category_id"
											showSearch
											defaultValue={
												categoryList?.data?.filter(
													(x) => x?.value === values?.retail_category_id
												)[0] || null
											}
											fetchOptions={getCategoryListOnSearch}
											onChange={(val) =>
												setFieldValue('retail_category_id', val)
											}
											placeholder="Select Pincode"
											transformOptions={(val) =>
												SelectOptions(val, 'retail_category', 'id')
											}
											selectOptions={[...(categoryList.data || [])]}
										/>
									</LoadAsyncSelectComponent>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="address" label="*Address">
									<CustomInput name="address" placeholder="Enter Address" />
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="area" label="Area">
									<CustomInput name="area" placeholder="Enter Area" />
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="contact_person_fname" label="Contact First Name">
									<CustomInput
										name="contact_person_fname"
										placeholder="Enter Contact First Name"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="contact_person_mname" label="Contact Middle Name">
									<CustomInput
										name="contact_person_mname"
										placeholder="Enter Contact Middle Name"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="contact_person_lname" label="Contact Last Name">
									<CustomInput
										name="contact_person_lname"
										placeholder="Enter Contact Last Name"
									/>
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="gender" label="Gender">
									<Select
										name="gender"
										allowClear
										options={GENDER_CHOICE}
										placeholder="Select Gender"
										showSearch
										filterOption={SelectFilterLabelOptions}
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<Box>
									<FormInput name="mobile" label="*Contact Phone Number">
										<CustomInput
											name="mobile"
											placeholder="Enter Contact Phone Number"
										/>
										<Box fontSize="12px">Ex: 9876543210</Box>
									</FormInput>
								</Box>
							</Col>
							<Col span={8}>
								<Box>
									<FormInput
										name="alternate_phone"
										label="Alternate Phone Number"
									>
										<CustomInput
											name="alternate_phone"
											placeholder="Enter Alternate Phone Number"
										/>
										<Box fontSize="12px">Ex: 9876543210</Box>
									</FormInput>
								</Box>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="email" label="Contact Person Email">
									<CustomInput
										name="email"
										placeholder="Enter Contact Person Email"
									/>
								</FormInput>
							</Col>

							<Col span={8}>
								<FormInput name="latitude" label="*Latitude">
									<CustomInput
										type="number"
										name="latitude"
										placeholder="Enter Latitude"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="longitude" label="*Longitude">
									<CustomInput
										type="number"
										name="longitude"
										placeholder="Enter Longitude"
									/>
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="gst_reg_type" label="*GST Registration Type">
									<Select
										name="gst_reg_type"
										allowClear
										showSearch
										filterOption={SelectFilterLabelOptions}
										options={GST_REG_TYPE_CHOICE}
										placeholder="Select GST Registration Type"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="gstNumber" label="GSTIN Number">
									<CustomInput
										disabled={values.gst_reg_type === 'UR'}
										name="gstNumber"
										placeholder="Enter GSTIN Number"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="aadhaar" label="Aadhar Number">
									<CustomInput name="aadhaar" placeholder="Enter Aadhar Number" />
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="pan_number" label="PAN Number">
									<CustomInput name="pan_number" placeholder="Enter PAN Number" />
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="account_code" label="Account Code">
									<CustomInput
										name="account_code"
										placeholder="Enter Account Code"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="account_number" label="Account Number">
									<CustomInput
										name="account_number"
										placeholder="Enter Account Number"
									/>
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="ifsc_code" label="IFSC Code">
									<CustomInput name="ifsc_code" placeholder="Enter IFSC Code" />
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="bank_name" label="Bank Name">
									<CustomInput name="bank_name" placeholder="Enter Bank Name" />
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="bank_city" label="Bank City">
									<CustomInput name="bank_city" placeholder="Enter Bank City" />
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="bank_branch" label="Bank Branch">
									<CustomInput
										name="bank_branch"
										placeholder="Enter Bank Branch"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="legal_structure" label="Legal Structure">
									<Select
										name="legal_structure"
										allowClear
										showSearch
										filterOption={SelectFilterLabelOptions}
										options={LEGAL_STRUCTURE_CHOICE}
										placeholder="Select Legal Structure"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="is_active" label="*Status">
									<Select
										name="is_active"
										allowClear
										showSearch
										filterOption={SelectFilterLabelOptions}
										options={ACTIVE_INACTIVE_CHOICE}
										placeholder="Select Status"
									/>
								</FormInput>
							</Col>
						</Row>
						<Box padding="1rem 0" borderTop="1px solid rgba(0, 0, 0, 0.1)">
							Additional details
						</Box>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="tdc_number" label="Certificate Number">
									<CustomInput
										name="tdc_number"
										placeholder="Enter Certificate Number"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="tdc_tds_section" label="TDS Selection">
									<CustomInput
										name="tdc_tds_section"
										placeholder="Enter TDS Selection"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="tdc_start_date" label="Low TDS Start Date">
									<DatePicker
										name="tdc_start_date"
										style={{ width: '100%' }}
										disabledDate={disableFutureDate}
										placeholder="Select Low TDS Start Date"
									/>
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="tdc_valid_till" label="Low TDS Valid Till">
									<DatePicker
										name="tdc_valid_till"
										style={{ width: '100%' }}
										disabledDate={disablePastCurrentDate}
										placeholder="Select Low TDS Valid Till"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="tdc_tds_rate" label="TDS Rate">
									<CustomInput
										type="number"
										name="tdc_tds_rate"
										placeholder="Enter TDS Rate"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="tdc_amount_limit" label="Amount Limit">
									<CustomInput
										type="number"
										name="tdc_amount_limit"
										placeholder="Enter Amount Limit"
									/>
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="is_related_party" label="Related Party">
									<Select
										name="is_related_party"
										allowClear
										showSearch
										filterOption={SelectFilterLabelOptions}
										options={YES_NO_CHOICE}
										placeholder="Select Related Party"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="is_holding_payment" label="Holding Statement">
									<Select
										showSearch
										filterOption={SelectFilterLabelOptions}
										name="is_holding_payment"
										allowClear
										options={YES_NO_CHOICE}
										placeholder="Select Holding Statement"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput
									name="has_discount_early_payment"
									label="Discount Early Payment"
								>
									<Select
										name="has_discount_early_payment"
										allowClear
										options={YES_NO_CHOICE}
										showSearch
										filterOption={SelectFilterLabelOptions}
										placeholder="Select Discount Early Payment"
									/>
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput
									name="is_default_tds_applicable"
									label="Default TDS Applicable"
								>
									<Select
										name="is_default_tds_applicable"
										allowClear
										options={YES_NO_CHOICE}
										showSearch
										filterOption={SelectFilterLabelOptions}
										placeholder="Select Default TDS Applicable"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="has_vendor_agreement" label="Agreement Available">
									<Select
										name="has_vendor_agreement"
										allowClear
										showSearch
										filterOption={SelectFilterLabelOptions}
										options={YES_NO_CHOICE}
										placeholder="Select Agreement Available"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput
									name="vendor_agreement_start_date"
									label="Agreement Start Date"
								>
									<DatePicker
										name="vendor_agreement_start_date"
										style={{ width: '100%' }}
										disabledDate={disableFutureDate}
										placeholder="Select Agreement Start Date"
									/>
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput
									name="vendor_agreement_end_date"
									label="Agreement Valid Till"
								>
									<DatePicker
										name="vendor_agreement_end_date"
										style={{ width: '100%' }}
										disabledDate={disablePastCurrentDate}
										placeholder="Select Agreement Valid Till"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="document_currency" label="*Document Currency">
									<Select
										name="document_currency"
										showSearch
										filterOption={SelectFilterLabelOptions}
										allowClear
										options={DOCUMENT_CURRENCY_CHOICE}
										placeholder="Select Document Currency"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="credit_period" label="Credit Period">
									<CustomInput
										type="number"
										name="credit_period"
										placeholder="Enter Credit Period"
									/>
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="credit_amount" label="Credit Amount Limit">
									<CustomInput
										type="number"
										name="credit_amount"
										placeholder="Enter Credit Amount Limit"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="payment_terms" label="Payment Terms & Conditions">
									<CustomInput
										name="payment_terms"
										placeholder="Enter Payment Terms & Conditions"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="credit_bill_limit" label="Credit Bill Limit">
									<CustomInput
										type="number"
										name="credit_bill_limit"
										placeholder="Enter Credit Bill Limit"
									/>
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="scheme_eligibility" label="*Scheme Eligibility">
									<Select
										name="scheme_eligibility"
										allowClear
										showSearch
										filterOption={SelectFilterLabelOptions}
										options={YES_NO_CHOICE}
										placeholder="Select Scheme Eligibility"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput
									name="special_rate_eligibility"
									label="*Special Rate Eligibility"
								>
									<Select
										name="special_rate_eligibility"
										allowClear
										showSearch
										filterOption={SelectFilterLabelOptions}
										options={YES_NO_CHOICE}
										placeholder="Select Special Rate Eligibility"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="is_black_listed" label="*Black Listed">
									<Select
										name="is_black_listed"
										allowClear
										showSearch
										filterOption={SelectFilterLabelOptions}
										options={YES_NO_CHOICE}
										placeholder="Select Black Listed"
									/>
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="bad_paymaster" label="*Bad Paymaster">
									<Select
										name="bad_paymaster"
										allowClear
										showSearch
										filterOption={SelectFilterLabelOptions}
										options={yesNo_Choice}
										placeholder="Select Black Listed"
									/>
								</FormInput>
							</Col>
						</Row>
						{isEditPage &&
							values?.verified_by_delivery_boy &&
							!modalVisible.makeunverifyData && (
								<>
									<Row gutter={20} style={{ pointerEvents: 'none' }}>
										<Col span={8}>
											<FormInput
												name="contact_persoe"
												label="Delivery boy Verification"
											>
												<CustomInput
													value={
														values?.verified_by_delivery_boy
															? 'Done'
															: 'Not yet done'
													}
													name="contact_persoe"
													placeholder="Enter Contact First Name"
												/>
											</FormInput>
										</Col>
										<Col span={8}>
											<FormInput
												name="contact_persoe"
												label="Delivery boy Name"
											>
												<CustomInput
													value={values?.delivery_boy_name || '-'}
													name="contact_persoe"
													placeholder="Enter Contact First Name"
												/>
											</FormInput>
										</Col>
										<Col span={8}>
											<FormInput
												name="contact_persoe"
												label="Delivery boy Verification Done at"
											>
												<CustomInput
													value={moment(
														values?.delivery_boy_verified_at
													).format('DD/MM/YYYY, hh:mm A')}
													name="contact_persoe"
													placeholder="Enter Contact First Name"
												/>
											</FormInput>
										</Col>
									</Row>
									<Row gutter={20} style={{ pointerEvents: 'none' }}>
										<Col span={8}>
											<FormInput
												name="contact_persoe"
												label="Telecaller Verification"
											>
												<CustomInput
													value={
														values?.verified_by_telecaller
															? 'Done'
															: 'Not yet done'
													}
													name="contact_persoe"
													placeholder="Enter Contact First Name"
												/>
											</FormInput>
										</Col>
										{(values?.verified_by_telecaller ||
											modalVisible?.telecallerverification) && (
											<>
												<Col span={8}>
													<FormInput
														name="contact_persoe"
														label="Telecaller Name"
													>
														<CustomInput
															value={
																modalVisible?.telecallerverification
																	? `${
																			auth?.user
																				?.first_name || ''
																	  } ${
																			auth?.user?.last_name ||
																			''
																	  }`
																	: values?.telecaller_name || '-'
															}
															name="contact_persoe"
															placeholder="Enter Contact First Name"
														/>
													</FormInput>
												</Col>
												<Col span={8}>
													<FormInput
														name="contact_persoe"
														label="Telecaller Verification Done at"
													>
														<CustomInput
															value={moment(
																modalVisible?.telecallerverification
																	? new Date()
																	: values?.telecaller_verified_at
															).format('DD/MM/YYYY, hh:mm A')}
															name="contact_persoe"
															placeholder="Enter Contact First Name"
														/>
													</FormInput>
												</Col>
											</>
										)}
									</Row>
									<Box>
										{!values?.verified_by_telecaller && (
											<Button
												onClick={() =>
													setModalVisible({
														unverify: false,
														modalVisible: true,
														telecallerverification: false,
														makeunverifyData: false,
													})
												}
												margin="0 0 1rem"
												minWidth="auto"
												type="button"
											>
												Telecaller Verify
											</Button>
										)}
									</Box>

									<Button
										onClick={() =>
											setModalVisible({
												unverify: true,
												modalVisible: true,
												telecallerverification: false,
												makeunverifyData: false,
											})
										}
										margin="0"
										minWidth="auto"
										type="button"
									>
										Unverify
									</Button>
								</>
							)}
						<Box mt="2rem">
							<StyledSubmitButton disabled={isSubmitting || !isValid || !dirty}>
								{isEditPage ? 'Update' : 'Save'}
							</StyledSubmitButton>
						</Box>
						<Debug />
						{modalVisible.modalVisible && (
							<Modal
								centered
								destroyOnClose
								onCancel={() =>
									setModalVisible({
										unverify: false,
										modalVisible: false,
										telecallerverification: false,
										makeunverifyData: false,
									})
								}
								footer={null}
								width="50%"
								visible={modalVisible}
								maskClosable={false}
							>
								<Box>
									{modalVisible?.unverify ? (
										<Box textAlign="center">
											Are you sure you want to unverify?
										</Box>
									) : (
										<Box textAlign="center">
											Are you sure you want to verify telecaller?
										</Box>
									)}
									<Box px="22%" mt="20px" textAlign="center">
										<Button onClick={() => setModalVisible(false)}>No</Button>
										<Button
											onClick={
												modalVisible?.unverify
													? () => {
															setModalVisible({
																unverify: false,
																makeunverifyData: true,
																modalVisible: false,
																telecallerverification: false,
															});
													  }
													: () => {
															setFieldValue(
																'verified_by_telecaller',
																true
															);
															setFieldValue(
																'telecaller_id',
																auth?.user?.id
															);
															setModalVisible({
																unverify: false,
																makeunverifyData: false,
																modalVisible: false,
																telecallerverification: true,
															});
													  }
											}
										>
											Yes
										</Button>
									</Box>
								</Box>
							</Modal>
						)}
					</Form>
				)}
			</Formik>
		</Box>
	);
}
