import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Text } from 'atoms';
import { ACL } from 'components/common';
import { UserPersmission } from 'utils/userPermission';

export const ClientColumns = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		render: (name) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{name || ''}
			</Text>
		),
	},
	{
		title: 'Client Code',
		dataIndex: 'code',
		key: 'code',
		render: (code) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{code || ''}
			</Text>
		),
	},
	{
		title: '',
		dataIndex: 'id',
		key: 'id',
		sortId: 6,
		render: (id) => (
			<ACL allowedGroups={UserPersmission.client_create}>
				<Link style={{ color: '#000' }} to={`/onboarding/client/${id}/edit`}>
					<EditOutlined />
				</Link>
			</ACL>
		),
	},
];
