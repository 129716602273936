import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Row, Col } from 'antd';
import { Select, Input, Form } from 'formik-antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Text, FormInput, Box } from 'atoms';
import {
	StyledSubmitButton,
	StoreCategoryFormSchema,
	StoreCatinitialValues,
	STORE_CLASS_CHOICE,
} from 'components/forms/masters/helper';
import { CreateUpdateCategory, getCategorybyId } from 'store/categorySlice';
import { SelectFilterLabelOptions } from 'utils';

export default function CategoryEditCreateForm() {
	const { id: categoryId } = useParams();
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const [formInitial, setFormInitial] = useState({});
	const isEditPage = pathname.includes('edit');

	useEffect(() => {
		const hasEditForm = isEditPage
			? dispatch(getCategorybyId({ categoryId }))
			: Promise.resolve();

		hasEditForm.then((resp) => {
			setFormInitial(
				isEditPage ? StoreCatinitialValues(resp?.data) : StoreCatinitialValues()
			);
			return resp;
		});
	}, [dispatch, isEditPage, categoryId]);

	const handleSubmit = (values, { resetForm, setSubmitting }) => {
		const payload = isEditPage ? { data: { ...values, id: categoryId } } : { data: values };
		dispatch(CreateUpdateCategory(payload)).then(() => {
			navigate('/onboarding/retail-category');
			resetForm();
			setSubmitting(false);
		});
	};

	return (
		<Box>
			<Text>{`${isEditPage ? 'Edit' : 'Add'} Store Category Details`}</Text>
			<Formik
				initialValues={formInitial}
				validationSchema={StoreCategoryFormSchema}
				onSubmit={handleSubmit}
				enableReinitialize
				initialTouched={{
					retail_category: true,
					retailer_class: true,
				}}
			>
				{({ isSubmitting, isValid, dirty }) => (
					<Form layout="vertical">
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="retail_category" label="Store Category Name">
									<Input
										name="retail_category"
										placeholder="Enter Category Name"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="retailer_class" label="Store Class">
									<Select
										name="retailer_class"
										allowClear
										showSearch
										filterOption={SelectFilterLabelOptions}
										options={STORE_CLASS_CHOICE}
										placeholder="Enter Store Class"
									/>
								</FormInput>
							</Col>
						</Row>
						<Box mt="16px">
							<StyledSubmitButton disabled={isSubmitting || !isValid || !dirty}>
								Save{' '}
							</StyledSubmitButton>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
}
