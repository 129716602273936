import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Text, Box } from 'atoms';
import DateFormat from 'components/DateFormat';
import { UserPersmission } from 'utils/userPermission';
import { ACL } from 'components/common';

export const userListColumns = [
	{
		title: 'Full Name',
		dataIndex: 'full_name',
		key: 'full_name',
		sortId: 1,
		overflow: 'hidden',
		hidden: false,
		render: (full_name) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{full_name || ''}
			</Text>
		),
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (email) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{email || ''}
			</Text>
		),
	},
	{
		title: 'Mobile No',
		dataIndex: 'mobile_no',
		key: 'mobile_no',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (mobile_no) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{mobile_no || ''}
			</Text>
		),
	},
	{
		title: 'FC',
		dataIndex: 'FCs',
		key: 'FCs',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (FCs) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{FCs?.map((item) => <div key={item?.id}>{item?.name}</div>) || ''}
			</Text>
		),
	},
	{
		title: 'Brands',
		dataIndex: 'Brands',
		key: 'Brands',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (Brands) => (
			<Text
				overFlow="hidden"
				fontSize="12px"
				color="#000"
				wordBreak="break-all"
				whiteSpace="normal"
			>
				{Brands?.map((item) => (
					<Box maxWidth="200px" key={item?.id}>
						{item?.name}
					</Box>
				)) || ''}
			</Text>
		),
	},
	{
		title: 'Groups',
		dataIndex: 'groups',
		key: 'groups',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (groups) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{groups?.map((item) => <div key={item?.id}>{item?.name}</div>) || ''}
			</Text>
		),
	},
	{
		title: 'Created At',
		dataIndex: 'created_at',
		key: 'created_at',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (created_at) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				<DateFormat date={created_at} format="HMSA" />
			</Text>
		),
	},
	{
		title: '',
		dataIndex: 'id',
		key: 'id',
		sortId: 6,
		render: (id) => (
			<ACL allowedGroups={UserPersmission.user_create}>
				<Link style={{ color: '#000' }} to={`/onboarding/user/${id}/edit`}>
					<EditOutlined />
				</Link>
			</ACL>
		),
	},
];
