import { createSlice } from '@reduxjs/toolkit';
import { API } from 'api';

const initialState = {
	GRNList: [],
	showGRN: [],
	grnPaginate: {
		query: '',
		offset: 0,
		limit: 10,
		currentPage: 1,
	},
};

export const GRNSlice = createSlice({
	name: 'GRN',
	initialState,
	reducers: {
		setGRNList: (state, { payload }) => {
			state.GRNList = { ...payload };
		},
		setShowGRN: (state, { payload }) => {
			state.showGRN = { ...payload };
		},
	},
});

export const getGRNList =
	({
		limit = 10,
		offset = 0,
		sort_column = 'createdAt',
		sort_direction = 'DESC',
		fcs,
		brands,
		invoice_no,
	}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/grn/list',
			data: {
				sort: { sort_column, sort_direction },
				filter: {
					user_fcs_only: true,
					user_brands_only: true,
					brands: brands?.length ? brands : undefined,
					fcs: fcs?.length ? fcs : undefined,
					invoice_no: invoice_no || undefined,
				},
				page: { limit, offset },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const GRNShowId = (id) => async () => {
	const CREDENTIALS = {
		url: `champ/grn/${id}`,
	};
	return API.common(CREDENTIALS).then((response) => response.data);
};

export const { setGRNList, setShowGRN } = GRNSlice.actions;

export default GRNSlice.reducer;
