import { createSlice } from '@reduxjs/toolkit';
import { message, message as toaster } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import { API } from 'api';

const initialState = {};
const champRetailCategoryList = 'champ/retailCategory/list';

export const CategorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: {},
});

export const getCategoryList =
	({
		limit = 10,
		offset = 0,
		sort = { sort_column: 'created_at', sort_direction: 'DESC' },
		query,
		currentPage,
		...rest
	}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: champRetailCategoryList,
			data: {
				sort,
				filter: {
					fields: ['retail_category'],
					...rest,
					...(query ? { q: `*${query || ''}*` } : {}),
				},
				page: { limit, offset },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getCategoryListOnSearch =
	({ q }) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: champRetailCategoryList,
			data: {
				sort: { sort_column: 'id', sort_direction: 'DESC' },
				filter: { q: `${q || ''}`, fields: ['retail_category'] },
				page: { limit: 25, offset: 0 },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getCategoryDataOnLoad =
	({ categoryId }) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: '/champ/retailCategory/list',
			data: {
				sort: { sort_column: 'id', sort_direction: 'DESC' },
				page: { limit: 25, offset: 0 },
			},
		};

		const [categoryData, categoryOption] = await Promise.all([
			categoryId
				? API.common({
						method: 'get',
						url: `/champ/retailcategory/${categoryId}`,
				  })
				: Promise.resolve({}),
			API.common(CREDENTIALS),
		]);

		const removeEmpty = [...(categoryOption?.data?.rows || []), categoryData?.data].filter(
			(x) => !isEmpty(x)
		);

		return {
			...categoryOption?.data,
			rows: uniqBy(removeEmpty, (x) => x.id),
		};
	};

export const getCategorybyId =
	({ categoryId }) =>
	async () => {
		const CREDENTIALS = {
			method: 'get',
			url: `/champ/retailcategory/${categoryId}`,
		};
		return API.common(CREDENTIALS).then((res) => res);
	};

export const CreateUpdateCategory =
	({ data }) =>
	async () => {
		let method = 'post';
		let url = 'champ/retailCategory/create';
		let toastMsg = 'Store Category Created Successfully';

		if ('id' in data) {
			method = 'put';
			url = 'champ/retailCategory/update';
			toastMsg = 'Store Category Updated Successfully';
		}

		const CREDENTIALS = {
			method,
			url,
			data,
		};
		return API.common(CREDENTIALS).then((res) => {
			message.info(toastMsg);
			return res;
		});
	};

const downloadExcel = (response, fileName) => {
	const blob = new Blob([response], {
		type: 'csv',
	});
	const url = URL.createObjectURL(blob);
	const element = document.createElement('a');

	element.setAttribute('href', url);
	element.setAttribute('download', fileName);
	document.body.appendChild(element);
	element.click();
	document.body.removeChild(element);

	toaster.success('File downloaded successfully!');
};

export const getExportStoreCategory = (data) => async () => {
	const CREDENTIALS = {
		url: champRetailCategoryList,
		method: 'post',
		data,
		config: {
			responseType: 'blob',
		},
	};
	return API.common(CREDENTIALS).then((response) => downloadExcel(response, 'Store Category'));
};

export default CategorySlice.reducer;
