import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Table, Select } from 'antd';
import { getstatus } from 'utils/tableConfig';
import { SelectFilterOptions, SelectFilterLabelOptions } from 'utils';
import { Rupee, DateFormat } from 'components';
import { Flex, Text } from 'atoms';
import { getReturnToFCUpdate } from 'store/fulfilment/returnToFCSlice';

const { Option } = Select;

const columns = [
	{
		title: 'Invoice Date',
		dataIndex: 'invoice_date',
		key: 'invoice_date',
		render: (invoice_date) => <DateFormat date={invoice_date} />,
	},
	{
		title: 'Invoice No',
		dataIndex: 'invoice_no',
		key: 'invoice_no',
	},
	{
		title: 'Store',
		dataIndex: 'store',
		key: 'store',
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		render: (status) => <Text>{getstatus(status)}</Text>,
	},
	{
		title: 'Reason',
		dataIndex: 'reason',
		key: 'reason',
	},
	{
		title: 'Invoice Amt',
		dataIndex: 'invoice_amt',
		key: 'invoice_amt',
		render: (invoice_amt) => <Rupee>{invoice_amt}</Rupee>,
	},
	{
		title: 'Collec Amt',
		dataIndex: 'coll_amt',
		key: 'coll_amt',
		render: (coll_amt) => <Rupee>{coll_amt}</Rupee>,
	},
	{
		title: 'Collec Mode',
		dataIndex: 'coll_mode',
		key: 'coll_mode',
	},
	{
		title: 'Chq No',
		dataIndex: 'chq_no',
		key: 'chq_no',
	},
	{
		title: 'Chq Due Date',
		dataIndex: 'chq_date',
		key: 'chq_date',
		render: (chq_date) => <DateFormat date={chq_date} />,
	},
	{
		title: 'Bank',
		dataIndex: 'bank',
		key: 'bank',
	},
	{
		title: 'Ref No',
		dataIndex: 'ref_no',
		key: 'ref_no',
	},
];

const billStatusOption = [
	{ label: 'Invoice Returned', value: 'Bill Back' },
	{ label: 'Invoice Settled', value: 'No Bill Back' },
	{ label: 'Pending', value: 'Pending' },
	{ label: 'Voucher', value: 'Voucher' },
];

export default function ReturnToFCShowTable({
	data,
	editMode = false,
	handleSelect = () => {},
	ReturnableCompleted,
}) {
	const dispatch = useDispatch();
	const [billStatus, setBillStatus] = useState('');

	const editColumns = columns.map((obj) => {
		if (obj.key === 'status') {
			return {
				key: 'status',
				title: 'Status',
				dataIndex: 'status',
				width: '20%',
				render: (_, tableData) => (
					<Flex flexDirection="column">
						<Select
							showSearch
							filterOption={SelectFilterOptions}
							style={{ minWidth: '100%' }}
							onChange={(value) => handleSelect(value, tableData.key)}
							value={getstatus(tableData.status)}
							disabled={
								ReturnableCompleted ||
								tableData?.return_status.toLowerCase() === 'complete'
							}
						>
							<Option value="DL">Delivered</Option>
							<Option value="PD">Partial Delivered</Option>
							<Option value="CA">Cancelled</Option>
							<Option value="DA">Delivery Attempted</Option>
						</Select>
						<Text fontSize="10px" ml="20px" mt="5px">
							{getstatus(tableData.status)}
						</Text>
					</Flex>
				),
			};
		}
		if (obj.key === 'reason' && ReturnableCompleted) {
			return {
				key: 'bill_status',
				title: 'Bill Status',
				dataIndex: 'bill_status',
				width: '20%',
				render: (_, tableData) => (
					<Select
						showSearch
						filterOption={SelectFilterLabelOptions}
						style={{ minWidth: '100%' }}
						value={billStatus || tableData?.bill_status}
						disabled={tableData.status !== ('PD' || 'DL')}
						onChange={(value) => handleBillStatus(value)}
						options={billStatusOption}
					>
						{' '}
					</Select>
				),
			};
		}
		return obj;
	});

	function handleBillStatus(value) {
		setBillStatus(value);
		const Body = {
			bill_status: value,
			id: data?.Orders?.[0]?.id,
			status: data?.Orders?.[0]?.status,
		};

		dispatch(getReturnToFCUpdate(data?.Orders?.[0]?.id, Body)).then();
	}

	const tableData = data?.Orders?.map((x, index) => ({
		key: index,
		invoice_date: x?.invoice_date,
		invoice_no: x?.invoice_no,
		store: x?.Store?.name,
		status: x?.status,
		reason: x?.UnfulfilledReason?.name,
		invoice_amt: x?.total_net_amount,
		coll_amt: x?.payment_amount,
		coll_mode: x?.payment_type,
		chq_no: x?.cheque_no,
		chq_date: x?.cheque_due_date,
		bank: x?.bank,
		ref_no: x?.ref_no,
		bill_status: x?.OutstandingInvoice?.bill_status,
		order_id: x?.id,
		return_status: data?.return_status,
	}));

	return (
		<Table
			columns={editMode ? editColumns : columns}
			dataSource={tableData}
			pagination={false}
		/>
	);
}

ReturnToFCShowTable.propTypes = {
	data: PropTypes.object,
	editMode: PropTypes.bool,
	handleSelect: PropTypes.func,
	ReturnableCompleted: PropTypes.bool,
};
