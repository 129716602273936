import { createSlice } from '@reduxjs/toolkit';
import { API } from 'api';

const ORDERADAPTER_URL = `${process.env.REACT_APP_API_WMS_GRN_OA_URL}filestatus/`;

export const UploadSlice = createSlice({
	name: 'Uploads',
	initialState: {},
	reducers: {},
});

export const getUploadList =
	({
		offset = 0,
		limit = 10,
		sort_column = 'id',
		sort_direction = 'DESC',
		currentPage,
		...rest
	}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/file/list',
			data: {
				sort: { sort_column, sort_direction },
				filter: {
					user_fcs_only: true,
					user_brands_only: true,
					fileType: 'picklite_not_order_adapter',
					...rest,
				},
				page: { offset, limit },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getUploadListNew =
	({
		offset = 0,
		limit = 10,
		sort_column = 'id',
		sort_direction = 'DESC',
		currentPage,
		...rest
	}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/file/list',
			data: {
				sort: { sort_column, sort_direction },
				filter: {
					user_fcs_only: true,
					user_brands_only: true,
					fileType: 'picklite_grn_order_adapter',
					...rest,
				},
				page: { offset, limit },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

const OMS_fileList = process.env.REACT_APP_OMS;

export const getOMSFcList =
	({
		offset = 0,
		limit = 10,
		sort_column = 'id',
		sort_direction = 'DESC',
		currentPage,
		...rest
	}) =>
	async () => {
		console.log('Ia m getting called', { ...rest });
		const CREDENTIALS = {
			method: 'post',
			url: `${OMS_fileList}file/list/`,

			data: {
				sort: { sort_column, sort_direction },
				filter: {
					user_fcs_only: true,
					user_brands_only: true,
					fileType: 'picklite_order_adapter',
					...rest,
				},
				page: { offset, limit },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

// export const retryStockOneAPI =
// 	({ fileId }) =>
// 	async () => {
// 		const CREDENTIALS = {
// 			url: `${OMS_fileList}file/repush-stockone/`,
// 			data: { file_id: fileId },
// 			method: 'post',
// 		};
// 		return API.common(CREDENTIALS).then((response) => response);

// 	};

export function retryStockOneAPI({ id }) {
	const CREDENTIALS = {
		url: `${OMS_fileList}file/repush-stockone/`,
		data: { file_id: id },
		method: 'post',
	};
	return API.common(CREDENTIALS).then((res) => {
		console.log(res);
		window.location.reload();
	});
}

export const downloadExcelsUsingUrl =
	({ url, data = {}, method = 'post' }) =>
	async () => {
		const CREDENTIALS = {
			method,
			url,
			data: { ...data },
		};
		return API.common(CREDENTIALS).then((response) => response?.data);
	};

export const downloadCollectionformat =
	({
		url,
		method,
		data = {},
		type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		fileName = 'CollectionFormat.xlsx',
	}) =>
	async () => {
		const CREDENTIALS = {
			method,
			url,
			data: { ...data },
			config: {
				responseType: 'blob',
			},
		};
		return API.common(CREDENTIALS).then((response) => {
			const blob = new Blob([response], {
				type,
			});
			const excelURL = window.URL.createObjectURL(blob);
			const elem = document.createElement('a');
			elem.setAttribute('href', excelURL);
			elem.setAttribute('download', fileName);
			elem.click();
		});
	};

export const uploadFiles =
	({ url, data }) =>
	async () => {
		const CREDENTIALS = {
			url,
			data,
			method: 'post',
		};
		return API.common(CREDENTIALS).then((response) => response);
	};

export const getUploadViewDetails =
	({ id }) =>
	async () => {
		const CREDENTIALS = {
			method: 'get',
			url: `champ/file/${id}`,
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getdownloadFile =
	({ file_name }) =>
	async (dispatch, getState) => {
		const {
			auth: { me },
		} = getState();

		const CREDENTIALS = {
			method: 'get',
			url: `${ORDERADAPTER_URL}?user_id=${me?.id}&file_name=${file_name}&generate_error_file=true`,
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getUploadViewList =
	({
		offset,
		limit,
		sort = { sort_column: 'id', sort_direction: 'DESC' },
		currentPage,
		...rest
	}) =>
	async () => {
		console.log(rest);
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/order/list',
			data: {
				filter: { ...rest },
				page: { offset, limit },
				sort,
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getGrnUploadViewList =
	({ offset, limit, currentPage, ...rest }) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/grn/list',
			data: {
				filter: { ...rest },
				page: { offset, limit },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};
export const failedViewDetails =
	({ id, offset, limit, sort = { sort_column: 'createdAt', sort_direction: 'DESC' }, ...rest }) =>
	async () => {
		console.log(id, rest);
		const CREDENTIALS = {
			method: 'post',
			data: {
				filter: {
					files: [Number(id)],
					query: '',
				},
				page: { offset, limit },
				sort,
			},
			url: `${OMS_fileList}file/returns?failed=true`,
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const filterObject = (me) => ({
	user_id: me.id,
	user_brands_only: true,
	user_fcs_only: true,
});
export const getPaginatedlogsFailedInvoices =
	({ offset, limit, sort = { sort_column: 'createdAt', sort_direction: 'DESC' } }) =>
	async (dispatch, getState) => {
		const {
			auth: { me },
		} = getState();

		const CREDENTIALS = {
			method: 'POST',
			body: JSON.stringify({
				filter: filterObject(me),
				page: { offset, limit },
				sort,
			}),
			url: `${OMS_fileList}file/returns?failed=true`,
		};
		console.log(CREDENTIALS);
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getBrandSalesReturnFileUploadList =
	({ id, currentPage, ...rest }) =>
	async () => {
		console.log(rest);
		const CREDENTIALS = {
			method: 'post',
			url: `${OMS_fileList}file/returns`,
			data: {
				filter: { files: [Number(id)], query: '' },
				page: { offset: 0, limit: 10 },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export default UploadSlice.reducer;
