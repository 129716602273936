/* eslint-disable no-nested-ternary */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Collapse, Tabs, Table, DatePicker, message, Input } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { debounce, isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Image } from 'atoms';
import { Rupee } from 'components';
import { UnsettledChequeDetails } from './UnsettledChequeDetails';
import { SettleCashModal } from './CashSettlement';
import { AttachmentModal } from './AttachmentModal';
import { SettleCheque } from './SettleCheque';
import { BankColumns, BankInnerColumns } from './helper';
import {
	getCashChequeUnsettleAmt,
	getCashChequeSettlementList,
	getUnSettleChequelist,
} from 'store/invoiceSlice';
import { disableFutureDate, SettleCheckSvg, UserPersmission } from 'utils';
import cashSettled from 'assets/svg/cash-settled.svg';
import { ACL } from 'components/common';

const { Panel } = Collapse;
const { TabPane } = Tabs;

const DATE_FORMAT = 'YYYY-MM-DD';
const TODAY = moment().format(DATE_FORMAT);
const YESTERDAY = moment().subtract(6, 'day').format(DATE_FORMAT);

const InputSearch = styled(Input.Search)`
	input {
		background: #f6f5f5;
		border: 0;
		border-radius: 2px;
		min-height: 35px;
		&:placeholder {
			color: #646464;
		}
		&:focus,
		&:hover {
			border: 0;
			box-shadow: none;
			outline: none;
		}
	}
	.ant-input-search-button {
		min-height: 35px;
		background: #f6f5f5;
		border: 0;
	}
`;

const InnerTable = styled(Table)`
	tr.ant-table-expanded-row > td {
		background: #fff;
	}
	.ant-table-tbody,
	.ant-table-thead {
		.ant-table-cell {
			background: #f7f7f7;
		}
	}
	.ant-table-thead {
		.ant-table-cell {
			background-color: #ededed;
		}
	}
`;

const DateLabel = styled(Box)`
	font-size: 12px;
	margin-left: 10px;
	margin-bottom: -8px;
	position: relative;
	z-index: 2;
	background: #fff;
	width: fit-content;
`;

const BoxOuter = styled(Box)`
	background: #ffffff;
	border: 1px solid #edebe9;
	min-height: 120px;
	min-width: 250px;
	padding: 15px 20px;
	border-top: 5px solid #352a35;
`;

const LabelText = styled(Box)`
	font-weight: 300;
	font-size: 12px;
	line-height: 15px;
	color: #585858;
	margin-bottom: 8px;
`;

const TotalAmount = styled(Box)`
	font-weight: 400;
	font-size: 24px;
	line-height: 40px;
	color: #000000;
	margin-bottom: 8px;
`;

const ViewDetails = styled(Box)`
	margin: auto 0 5px;
	font-weight: '300';
	font-size: 12px;
	line-height: 15px;
	color: #0094ff;
	cursor: pointer;
`;

// const Loading = styled.p`
// 	font-weight: 400;
// 	font-size: 24px;
// 	line-height: 40px;
// 	color: #000000;
// 	margin: 0px;
// 	padding-bottom: 27px;
// `;

export function BankSettlementPage() {
	const dispatch = useDispatch();

	const navigate = useNavigate();
	function onNaviGateCheqe() {
		navigate('/bank-settlement/cheque-settlements');
	}

	const [unsettleAmts, setunsettleAmts] = useState({});
	const [unsettleCheque, setunsettleCheque] = useState({});
	const [tabs, setTabs] = useState('cashSettlementlist');
	const [tableList, setTableList] = useState([]);
	const [getDates, setDates] = useState({
		to_date: TODAY,
		from_date: YESTERDAY,
	});

	const [unsettledModal, setunsettledModal] = useState(false);
	const [settlecashModal, setSettleCashModal] = useState(false);
	const [settleChequeModal, setsettleChequeModal] = useState(false);
	const [attachmentmodal, setattachmentmodal] = useState({
		modalVisisble: false,
		index: 0,
	});

	const onLoadAsyncCall = (tab, q = '') => {
		dispatch(
			getCashChequeSettlementList({
				tab: tabs,
				from_date: getDates.from_date,
				to_date: getDates.to_date,
				search_info: q,
			})
		)
			.then((res) => {
				let i = 0;
				/**
				 * on Carousel goTo Index key add only if attachment is available
				 */
				setTableList(
					res?.map((x) => ({
						...x,
						...(!isEmpty(x?.attachment) && { index: i++ }),
					}))
				);
			})
			.catch(() => setTableList([]));
	};

	const onRefreshData = () => {
		onLoadAsyncCall();
		dispatch(getCashChequeUnsettleAmt()).then((res) => setunsettleAmts(res));
		dispatch(getUnSettleChequelist({})).then((res) => setunsettleCheque(res));
	};

	useEffect(() => {
		onRefreshData();
	}, [dispatch, tabs, getDates]);

	const TabOnChange = (key) => {
		setTableList([]);
		setTabs(key);
	};
	const onFromDateChange = (value) =>
		setDates(() => ({
			from_date: value ? moment(value).format(DATE_FORMAT) : '',
			to_date: value
				? moment(value).add(6, 'day') > moment()
					? moment().format(DATE_FORMAT)
					: moment(value).add(6, 'day').format(DATE_FORMAT)
				: '',
		}));

	const onToDateChange = (value) =>
		setDates(() => ({
			to_date: value ? moment(value).format(DATE_FORMAT) : '',
			from_date: value ? moment(value).subtract(6, 'day').format(DATE_FORMAT) : '',
		}));

	const handleSearchChequeNumber = useMemo(
		() =>
			debounce((event) => {
				dispatch(getUnSettleChequelist({ q: event.target.value })).then((res) =>
					setunsettleCheque(res)
				);
			}, 500),
		[dispatch]
	);

	const handleChequeNumberList = useMemo(
		() =>
			debounce((event) => {
				onLoadAsyncCall('chequeSettlementlist', event.target.value);
			}, 500),
		[dispatch]
	);

	const renderDatePicker = (
		<Flex paddingRight="30px" margin="0 0 10px">
			{tabs === 'chequeSettlementlist' && (
				<Box alignSelf="center">
					<InputSearch onChange={handleChequeNumberList} placeholder="Cheque number" />
				</Box>
			)}
			<Box marginLeft="30px">
				<DateLabel>From date</DateLabel>
				<DatePicker
					name="from_date"
					disabledDate={disableFutureDate}
					onChange={(e) => onFromDateChange(e)}
					value={moment(getDates?.from_date)}
					allowClear={false}
				/>
			</Box>
			<Box marginLeft="30px">
				<DateLabel>To date</DateLabel>
				<DatePicker
					name="to_date"
					disabledDate={disableFutureDate}
					onChange={(e) => onToDateChange(e)}
					value={moment(getDates?.to_date)}
					allowClear={false}
				/>
			</Box>
		</Flex>
	);

	console.log(unsettleAmts);

	return (
		<>
			<Box className="bankSettlement">
				<Flex justifyContent="space-between" marginBottom="20px">
					<Flex>
						<BoxOuter display="flex" flexDirection="column">
							<LabelText>Unsettled Cheque till date</LabelText>
							{unsettleCheque?.total_unsettle_cheque_amount_on_dashboard ? (
								<TotalAmount>
									<Rupee>
										{unsettleCheque?.total_unsettle_cheque_amount_on_dashboard}
									</Rupee>{' '}
									({unsettleCheque?.total_unsettle_cheque_count_on_dashboard})
								</TotalAmount>
							) : (
								<Rupee mr="5px">0</Rupee>
							)}

							<ViewDetails
								onClick={
									unsettleCheque?.total_unsettle_cheque_count
										? () => setunsettledModal(true)
										: () =>
												message.info(
													'There is no unsettled cheque till date'
												)
								}
							>
								View details
							</ViewDetails>
						</BoxOuter>
						{unsettleAmts?.cash?.is_settled && (
							<Box alignSelf="end" m="2rem">
								<Image
									width={136}
									height={87}
									src={cashSettled}
									alt="cashSettled"
								/>
							</Box>
						)}
						{Number(unsettleAmts?.cheque?.todays_no_of_settlement) > 0 && (
							<Box m="2rem">
								<SettleCheckSvg
									count={unsettleAmts?.cheque?.todays_no_of_settlement}
								/>
							</Box>
						)}
					</Flex>
					<Flex>
						<ACL allowedGroups={UserPersmission.bankSettlement_create}>
							<BoxOuter marginRight="20px">
								<LabelText>Cash to be settled</LabelText>
								{unsettleAmts?.cash?.total_unsettled ? (
									<TotalAmount>
										<Rupee>{unsettleAmts.cash.total_unsettled}</Rupee>
									</TotalAmount>
								) : (
									<Rupee mr="5px">0</Rupee>
								)}

								<Box textAlign="right">
									<Button
										disabled={unsettleAmts?.cash?.is_settled}
										onClick={() => setSettleCashModal(true)}
									>
										Settle cash
									</Button>
								</Box>
							</BoxOuter>
							<BoxOuter>
								<LabelText>Cheque to be settled</LabelText>
								{unsettleAmts?.cheque?.total_unsettled ? (
									<TotalAmount>
										<Rupee mr="5px">
											{unsettleAmts.cheque.total_unsettled}
										</Rupee>{' '}
										({unsettleAmts?.cheque?.total_unsettle_cheque_count})
									</TotalAmount>
								) : (
									<Rupee mr="5px">0</Rupee>
								)}

								<Box textAlign="right">
									<Button
										// hereafter we can settle cheques multiple times on single day
										disabled={unsettleAmts?.cheque?.is_settled}
										onClick={
											(unsettleAmts?.cheque?.total_unsettle_cheque_count ||
												0) === 0
												? () => message.info('No cheques to settle')
												: () => onNaviGateCheqe()
										}
									>
										Settle cheque
									</Button>
								</Box>
							</BoxOuter>
						</ACL>
					</Flex>
				</Flex>
				<Box>
					<Collapse bordered={false} defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header={<Box alignSelf="center">Settlement History</Box>} key="1">
							<Tabs
								tabBarExtraContent={renderDatePicker}
								onChange={TabOnChange}
								defaultActiveKey="cashSettlementlist"
							>
								<TabPane tab="Cash Settlement" key="cashSettlementlist">
									<Table
										rowKey={(record) => `${record?.id}  ${record?.date_time}`}
										columns={BankColumns({ setattachmentmodal }, 'cash')}
										dataSource={tableList}
									/>
								</TabPane>
								<TabPane tab="Cheque Settlement" key="chequeSettlementlist">
									<Table
										rowKey="id"
										columns={BankColumns({ setattachmentmodal }, 'cheque')}
										dataSource={tableList}
										expandable={{
											expandedRowRender: (record) => (
												<Box marginBottom="10px">
													<InnerTable
														rowKey={(inner) =>
															`${inner?.invoice_id}  ${inner?.payment_id}`
														}
														pagination={false}
														columns={BankInnerColumns}
														dataSource={record?.chequeSettledlist || []}
													/>
												</Box>
											),
										}}
									/>
								</TabPane>
							</Tabs>
						</Panel>
					</Collapse>
				</Box>
			</Box>
			{unsettledModal && (
				<UnsettledChequeDetails
					onChangeSearch={handleSearchChequeNumber}
					chequelist={unsettleCheque?.chequelist || []}
					toggleModal={[unsettledModal, setunsettledModal]}
				/>
			)}
			{settlecashModal && (
				<SettleCashModal
					amountDetials={unsettleAmts?.cash}
					asyncCall={onRefreshData}
					toggleModal={[settlecashModal, setSettleCashModal]}
				/>
			)}
			{settleChequeModal && (
				<SettleCheque
					asyncCall={onRefreshData}
					amountDetials={unsettleAmts?.cheque}
					toggleModal={[settleChequeModal, setsettleChequeModal]}
				/>
			)}
			{attachmentmodal?.modalVisisble && (
				<AttachmentModal
					tableData={tableList}
					toggleModal={[attachmentmodal, setattachmentmodal]}
				/>
			)}
		</>
	);
}
