import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { FCForm } from 'components/forms';

export default function AddColour() {
	const { id: fcId } = useParams();
	const { pathname } = useLocation();
	const isEditPage = pathname.includes('edit');
	return (
		<>
			<h1>{isEditPage ? 'Edit' : 'Add'} FC Details</h1>
			<FCForm isEditPage={isEditPage} fcId={fcId} />
		</>
	);
}
