import React, { useEffect } from 'react';
import styled from 'styled-components';
import lottie from 'lottie-web';
import { Box, Text, Image, Flex } from 'atoms';
import RipplrLogo from 'assets/ripplr-logo.svg';
import NoNetwork from 'assets/NoNetwork.json';

const Content = styled(Text)`
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
`;

export function OfflinePage() {
	useEffect(() => {
		lottie.loadAnimation({
			container: document.querySelector('#noNetwork'),
			animationData: NoNetwork,
			renderer: 'svg', // "canvas", "html"
			loop: true, // boolean
			autoplay: true, // boolean
		});
	}, []);

	return (
		<Flex minHeight="100vh">
			<Box margin="auto">
				<Box textAlign="center">
					<Image src={RipplrLogo} alt="Ripplr Cartoon" />
				</Box>
				<Box padding="2rem 0">
					<Content>Seems like you are offline &#128577;</Content>
					<Content>Please check your network</Content>
				</Box>
				<Box id="noNetwork"></Box>
			</Box>
		</Flex>
	);
}
