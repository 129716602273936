export function redirectToLogin() {
	/**
	 * Session token expired, so clear the local storage
	 * and redirect the user to login page
	 */
	localStorage.clear();
	window.location = `${window.origin}/login`;
}

export function setLocalData(UserData) {
	localStorage.setItem(process.env.REACT_APP_CDMS_APP_TOKENS, JSON.stringify(UserData));
}
