import { Button, Result } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { redirectToLogin } from 'api/authentication';

export default function NotFound() {
	const navigate = useNavigate();
	const {
		app: { recentlyVisitedUrl },
		auth,
	} = useSelector((state) => state);

	function handleGoBack() {
		console.log('recentlyVisitedUrl', recentlyVisitedUrl);
		navigate('/dashboard');
	}

	if (isEmpty(auth?.auth)) {
		return redirectToLogin();
	}

	return (
		<Result
			status="404"
			title="404"
			subTitle="Sorry, the page you visited does not exist."
			extra={
				<>
					<Button type="primary" onClick={handleGoBack}>
						Back to Dashboard
					</Button>
					<Button type="primary" onClick={redirectToLogin}>
						Logout
					</Button>
				</>
			}
		/>
	);
}
