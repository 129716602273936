/* eslint-disable no-nested-ternary */

import React from 'react';
import { Steps } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from 'atoms';
import { Rupee } from 'components';
import { OrderJourneyKeys } from 'utils/journey/orderJourneyHelper';
import { getLocalDateAndTime } from 'utils';
import OrderJourneySummary from './OrderJourneySummary';

const { Step } = Steps;
const CustomStep = styled(Step)`
	.ant-steps-item-title {
		font-size: 18px;
		color: #000 !important;
		font-weight: 500;
	}
	.ant-steps-item-subtitle {
		font-size: 14px;
		color: #352a35;
		font-weight: 500;
		display: block;
		margin-left: 0px;
		margin-bottom: 10px;
	}
	.ant-steps-item-content {
		background-color: white;
		margin-bottom: 20px;
		padding: 5px 20px;
		.ant-steps-item-description {
			padding-bottom: 0px;
		}
	}

	.ant-steps-item-icon {
		background-color: #d9d9d9;
		border-color: transparent;
	}
	.ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: #d9d9d9;
	}
`;
const Container = styled.div`
	.ant-steps > .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
		background-color: green;
		svg {
			color: white;
		}
	}
	.ant-steps > .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-icon {
		background-color: red;
		svg {
			color: white;
		}
	}
	.ant-steps > .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: green;
	}
	.ant-steps > .ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: red;
	}
	.ant-steps > .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
		background-color: green;
		svg {
			color: white;
		}
	}

	.addExtraDiv > .ant-steps-item-container::after {
		display: block;
		content: ' ';
		width: 1px;
		height: 150px;
	}
	.addExtraDiv > .ant-steps-item-container > .ant-steps-item-tail::after {
		background-color: transparent !important;
		background-image: linear-gradient(rgba(0, 0, 0, 0.25) 33%, rgba(255, 255, 255, 0) 0%);
		background-position: right;
		background-size: 1px 16px;
		background-repeat: repeat-y;
	}
`;

const StyledWrapper = styled.div`
	display: flex;
	margin-bottom: 10px;
	align-items: center;
	label {
		color: #6c757d;
		font-size: 12px;
		font-weight: 600;
		width: 20%;
	}

	.value {
		color: #000;
		font-size: 14px;
		font-weight: 500;
	}
`;

function OrderJourney({ data = [] }) {
	const getDeliveryTypeValue = (status) => {
		let fullStatusName = '';
		if (status === 'CA') {
			fullStatusName = 'Cancelled';
		} else if (status === 'PD') {
			fullStatusName = 'Partially Delivered';
		} else if (status === 'DL') {
			fullStatusName = 'Fully Delivered';
		}
		return fullStatusName;
	};

	const getCurrentStatus = (item, stepKey) => {
		let status = 'wait';
		if (stepKey === 'CA' || stepKey === 'BL') {
			status = 'error';
		} else if (Object.keys(item[stepKey]).length > 0) {
			status = 'finish';
		}
		return status;
	};

	const renderStep = (item, idx) => {
		const stepKey = Object.keys(item);
		const getLabels = OrderJourneyKeys[stepKey];
		let showBlank = false;
		const checkRfcData = data?.journey.at(-1);
		if (
			data?.journey.length > 2 &&
			data.journey.length - 2 === idx &&
			checkRfcData.CO &&
			Object.keys(checkRfcData.CO).length === 0
		) {
			showBlank = true;
		}

		return (
			<CustomStep
				className={showBlank ? 'addExtraDiv' : null}
				title={getLabels.status}
				key={getLabels?.key}
				status={getCurrentStatus(item, stepKey[0])}
				subTitle={
					item[stepKey][getLabels.date] &&
					getLocalDateAndTime(item[stepKey][getLabels.date])
				}
				description={
					<div>
						{getLabels?.list?.map(
							(listItem) =>
								item[stepKey[0]][listItem?.key] && (
									<StyledWrapper key={listItem?.key}>
										<label>{listItem.value}</label>
										<Text className="value">
											{listItem?.unit === 'rupees' ? (
												<Rupee>{item[stepKey[0]][listItem?.key]}</Rupee>
											) : ['PD', 'CA', 'DL'].includes(stepKey[0]) &&
											  listItem?.key === 'type' ? (
												getDeliveryTypeValue(stepKey[0])
											) : (
												item[stepKey[0]][listItem?.key]
											)}

											{listItem?.quantity &&
												(item[stepKey[0]][listItem?.key] > 1
													? ' pcs'
													: ' pc')}
										</Text>
									</StyledWrapper>
								)
						)}
					</div>
				}
			/>
		);
	};

	return (
		<Container>
			{data?.days_to_deliver > 0 &&
				data?.difference_amount > 0 &&
				data?.warehouse_due > 0 &&
				data?.pd_amount > 0 && <OrderJourneySummary data={data} />}

			<Steps direction="vertical" size="small">
				{data?.journey?.map((item, idx) => renderStep(item, idx))}
			</Steps>
		</Container>
	);
}
export default OrderJourney;
OrderJourney.propTypes = {
	data: PropTypes.object,
};
