/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Box, Button, Flex, AsyncSelect } from 'atoms';
import {
	StoreListConfigs,
	handleFilterChange,
	toAsysnOption,
	StoreListQueries,
	toAsysnBankOption,
	SelectFilterLabelOptions,
	searchBox,
	UserPersmission,
} from 'utils';
import { RCTable, LabelWrapper, LoadAsyncSelectComponent, ACL } from 'components/common';
import * as SVGICONS from 'utils/Svg';
import { getSaleStoreList } from 'store/fulfilment/salesOrderSlice';
import { getClientListonSearch, getOnBoardStoreList, getStoreDataOnLoad } from 'store/OnBoardSlice';
import { getFcOnSearchList, getFcListOnLoad } from 'store/masters';
import { useQueryParams } from 'hooks';

const verification_options = [
	{ value: 1, label: 'Pending' },
	{ value: 2, label: 'Verified by Delivery Boy' },
	{ value: 3, label: 'Verification Completed' },
];

const StoreTable = styled(Box)`
	.ant-table-footer {
		position: absolute;
		background: #fff;
		width: ${(props) => (props?.rowCount === 0 ? '100%' : 'auto')};
	}
`;

export function StoreListPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [rowData, setRowData] = useState({});
	const [storeList, setStoreList] = useState({ type: 'NotAsked' });
	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [clientList, setClientList] = useState({ type: 'NotAsked' });
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [paginateConfig, setpaginateConfig] = useQueryParams({ filterQuery: StoreListQueries });

	const dispatchFcs = async (fc_ids, client_ids) =>
		dispatch(
			getFcListOnLoad({
				fcIds: fc_ids?.length ? fc_ids : [],
				client_ids: client_ids || [],
			})
		).then((res) => setFcList({ type: 'Success', data: toAsysnOption(res) }));

	const dispatchStores = async (fc_ids, storesIds, client_ids) =>
		dispatch(
			getStoreDataOnLoad({
				fc_ids: fc_ids?.length ? fc_ids : [],
				storeIds: storesIds || [],
				client_ids: client_ids || [],
			})
		).then((res) => setStoreList({ type: 'Success', data: toAsysnOption(res) }));

	useEffect(() => {
		setClientList({ type: 'Loading' });
		setFcList({ type: 'Loading' });
		setStoreList({ type: 'Loading' });
		dispatchFcs(paginateConfig?.fc_ids, paginateConfig?.client_ids);
		dispatchStores(paginateConfig?.fc_ids, paginateConfig?.stores, paginateConfig?.client_ids);

		dispatch(getClientListonSearch({})).then((res) =>
			setClientList({ type: 'Success', data: toAsysnBankOption(res) })
		);
	}, [dispatch]);

	const handleChangeSelect = async (name, value) => {
		// as this is asyncselect we need to destructure value from the option value
		const getValue = isEmpty(value) ? [] : value.map((y) => y.value);

		if (name === 'client_ids') {
			await Promise.all([
				dispatchFcs(paginateConfig?.fc_ids, getValue),
				dispatchStores(paginateConfig?.fc_ids, paginateConfig?.stores, getValue),
			]).then(() =>
				setpaginateConfig((prev) => ({
					...handleFilterChange(name, getValue, prev),
					fc_ids: [],
					stores: [],
				}))
			);
		}

		return setpaginateConfig((prev) => handleFilterChange(name, getValue, prev));
	};

	const handleFCChangeSelect = (name, value) => {
		// as this is asyncselect we need to destructure value from the option value
		const values = value?.value ? [value?.value] : [];
		dispatchStores(values, paginateConfig?.stores, paginateConfig?.client_ids).then(() =>
			setpaginateConfig((prev) => ({
				...handleFilterChange(name, values, prev),
				stores: [],
			}))
		);
	};

	return (
		<Box mt={3}>
			<Flex alignItems="center" marginBottom="1rem">
				<Flex width="100%" flexWrap="wrap" alignItems="center" gap="4px">
					<LabelWrapper label="Client" marginspace="3px 0 -1px 1rem">
						<LoadAsyncSelectComponent options={clientList}>
							<AsyncSelect
								className="form__field"
								mode="multiple"
								showSearch
								style={searchBox}
								name="client_ids"
								onChange={(value) => handleChangeSelect('client_ids', value)}
								placeholder="Select Client"
								defaultValue={paginateConfig?.client_ids}
								value={paginateConfig?.client_ids}
								allowClear
								selectOptions={[...(clientList?.data || [])]}
								fetchOptions={getClientListonSearch}
								transformOptions={(val) => toAsysnBankOption(val)}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>
					<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
						<LoadAsyncSelectComponent options={fcsList}>
							<AsyncSelect
								style={searchBox}
								allowClear
								name="fc_ids"
								showSearch
								fetchOptions={getFcOnSearchList}
								transformOptions={(val) => toAsysnOption(val)}
								value={paginateConfig?.fc_ids}
								defaultValue={paginateConfig?.fc_ids}
								apiParams={{ client_ids: paginateConfig?.client_ids || null }}
								placeholder="Select FC(s)"
								onChange={(value) => {
									handleFCChangeSelect('fc_ids', value);
								}}
								selectOptions={[...(fcsList.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>

					<LabelWrapper label="Store(s)" marginspace="3px 0 -1px 1rem">
						<LoadAsyncSelectComponent options={storeList}>
							<AsyncSelect
								style={searchBox}
								allowClear
								name="stores"
								mode="multiple"
								fetchOptions={getSaleStoreList}
								apiParams={{ fc_ids: paginateConfig?.fcs || null }}
								placeholder="Store(s)"
								defaultValue={paginateConfig?.stores}
								value={paginateConfig?.stores}
								onChange={(value) => handleChangeSelect('stores', value)}
								selectOptions={[...(storeList.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>

					<LabelWrapper label="Verification Status" marginspace="3px 0 -1px 1rem">
						<Select
							name="store_verify_status"
							style={searchBox}
							allowClear
							label="Verification Status"
							showSearch
							filterOption={SelectFilterLabelOptions}
							value={paginateConfig?.store_verify_status}
							options={verification_options}
							placeholder="Verification Status"
							onChange={(value) =>
								setpaginateConfig((prev) =>
									handleFilterChange('store_verify_status', value, prev)
								)
							}
						/>
					</LabelWrapper>
					<Box mt=".75rem">
						<Button onClick={() => setBtnClickSearh(Math.random())}>Search</Button>
					</Box>
				</Flex>
				<ACL allowedGroups={UserPersmission.store_create}>
					<Button
						style={{ minWidth: '150px' }}
						onClick={() => navigate('/onboarding/store/create')}
					>
						<Flex alignItems="center" justifyContent="center">
							<Box width={36}>
								<SVGICONS.PlusOutlinedIcons />
							</Box>
							<Box>Add Store</Box>
						</Flex>
					</Button>
				</ACL>
			</Flex>
			<StoreTable rowCount={rowData?.count || 0}>
				<RCTable
					rowKey="id"
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'createdAt',
					}}
					footer={() => <Box>Total Count : {rowData?.count || 0}</Box>}
					tableDatas={[rowData, setRowData]}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					dispatchAction={getOnBoardStoreList}
					columns={StoreListConfigs}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</StoreTable>
		</Box>
	);
}
