/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import { getSalesReturnList } from 'store/fulfilment/salesReturnSlice';
import { Box, AsyncSelect, Button } from 'atoms';
import { RCTable, LoadAsyncSelectComponent, LabelWrapper } from 'components/common';
import { getFcListOnLoad, getFcOnSearchList } from 'store/masters';
import {
	salesReturnListColumns,
	handleFilterChange,
	CommonFCBrandsQueries,
	toAsysnOption,
	searchBox,
	SelectFilterLabelOptions,
	initialPaginateConfigWithoutQ,
} from 'utils';
import { useQueryParams } from 'hooks';

export default function SalesReturn() {
	const dispatch = useDispatch();

	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);
	const [rowData, setRowData] = useState({});
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [paginateConfig, setpaginateConfig] = useQueryParams({
		filterQuery: CommonFCBrandsQueries,
	});

	useEffect(() => {
		setFcList({ type: 'Loading' });

		dispatch(
			getFcListOnLoad({ fcIds: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [] })
		).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});
	}, [dispatch]);

	const handleChangeSelect = (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value

		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		const fcValues = value?.value ? [value?.value] : [];
		setpaginateConfig((prev) => ({
			...handleFilterChange(name, fcValues, prev),
			brands: [],
		}));
	};

	return (
		<>
			<Box>
				<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
					<LoadAsyncSelectComponent
						loadFCs={paginateConfig?.fcs}
						setList={(val) => setBrandList(val)}
						options={fcsList}
					>
						<AsyncSelect
							style={searchBox}
							allowClear
							name="fcs"
							showSearch
							fetchOptions={getFcOnSearchList}
							transformOptions={(val) => toAsysnOption(val)}
							defaultValue={paginateConfig?.fcs}
							placeholder="Select FC(s)"
							onChange={(value, options) => {
								handleChangeSelect('fcs', value, options);
							}}
							selectOptions={[...(fcsList.data || [])]}
						/>
					</LoadAsyncSelectComponent>
				</LabelWrapper>

				<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
					<Select
						name="fields"
						style={searchBox}
						allowClear
						showSearch
						label="brands"
						mode="multiple"
						value={paginateConfig?.brands}
						filterOption={SelectFilterLabelOptions}
						options={brandsList}
						onChange={(value) =>
							setpaginateConfig((prev) => ({
								...prev,
								brands: value,
								...initialPaginateConfigWithoutQ,
							}))
						}
					/>
				</LabelWrapper>
				<Button onClick={() => setBtnClickSearh(Math.random())}>Search</Button>
			</Box>
			<Box mt={3}>
				<RCTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'id',
					}}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					dispatchAction={getSalesReturnList}
					columns={salesReturnListColumns({
						defaultSort: paginateConfig?.sort_direction || 'DESC',
						defaultsortField: paginateConfig?.sort_column || 'id',
					})}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
		</>
	);
}
