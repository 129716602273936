import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Collapse, Row, Col } from 'antd';
import { ShowReturnsLogsId } from 'store/fulfilment/returnsLogsSlice';
import { PanelData } from 'components/common';
import { Flex } from 'atoms';
import ReturnsLogShowTable from 'pages/fulfillment/returns-logs/ReturnsLogsShowTable';
import { returnsLogsShowPanel } from './ReturnsLogConfig';

const { Panel } = Collapse;

export default function ReturnLogsShow() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [showData, setShowData] = useState({});

	useEffect(() => {
		dispatch(ShowReturnsLogsId(id)).then((res) => setShowData(res));
	}, [dispatch, id]);

	return (
		<>
			{console.log('showData', showData)}
			<Collapse expandIconPosition="right" bordered={false}>
				<Panel header="Sales Return Details:" key="1">
					<Row gutter={32}>
						{returnsLogsShowPanel({ showData }).map((x) => (
							<Col key={x.title} xs={6}>
								<PanelData
									title={x.title}
									value={x.value}
									additionalValue={x?.additionalValue || ''}
									type={x?.type || ''}
								/>
							</Col>
						))}
					</Row>
				</Panel>
			</Collapse>
			<Flex mt="30px">
				<ReturnsLogShowTable data={showData?.return_details} />
			</Flex>
		</>
	);
}
