import {
	CashierSalesManStatsHeader,
	CashierTopStatusTabs,
	UPIList,
} from 'components/pages/cashier';

export function UpiVerification() {
	return (
		<div>
			<CashierTopStatusTabs />
			<CashierSalesManStatsHeader />
			<UPIList />
		</div>
	);
}
