/* eslint-disable sonarjs/cognitive-complexity */
import { Link } from 'react-router-dom';
import { Select, InputNumber } from 'antd';
import PaymentIcon from 'assets/icons/collection_charcoal.svg';
import viewIcon from 'assets/icons/eye.svg';
import { Text, Image, Flex } from 'atoms';
import { getstatus } from 'utils/tableConfig';
import { Rupee, DateFormat } from 'components';
import ModalSelect from 'pages/fulfillment/returnToFC/ModalSelect';
import { SelectFilterOptions, SelectFilterLabelOptions, UserPersmission } from 'utils';

const { Option } = Select;

const statusColor = (status) => {
	switch (status) {
		case 'Complete':
			return '#70e000';
		case 'Pending':
			return '#ffaa00';
		default:
			return '#000';
	}
};

export const returnToFCListColumns = ({ defaultSort, defaultsortField }) => {
	const sortDirection = defaultSort === 'ASC' ? 'ascend' : 'descend';
	return [
		{
			title: 'Allocated Date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => a.createdAt - b.createdAt,
			sortOrder: defaultsortField === 'createdAt' ? sortDirection : false,
			render: (createdAt) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={createdAt} format="HMSA" />
				</Text>
			),
		},
		{
			title: 'FC',
			dataIndex: 'name',
			key: 'name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.FC?.name}
				</Text>
			),
		},
		{
			title: 'Vehicle No',
			dataIndex: 'vehicle_no',
			key: 'vehicle_no',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.vehicle_no}
				</Text>
			),
		},
		{
			title: 'Driver Name',
			dataIndex: 'driver_name',
			key: 'driver_name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.driver_name}
				</Text>
			),
		},
		{
			title: 'Delivery Boy',
			dataIndex: 'first_name',
			key: 'first_name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.DeliveryExecutive?.first_name}
				</Text>
			),
		},
		{
			title: 'Return process',
			dataIndex: 'return_status',
			key: 'return_status',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text color={statusColor(record?.return_status)} overFlow="hidden" fontSize="12px">
					{record?.return_status}
				</Text>
			),
		},
		{
			title: 'Delivery completed at',
			dataIndex: 'delivery_completed_at',
			key: 'delivery_completed_at',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={record?.delivery_completed_at} format="HMSA" />
				</Text>
			),
		},
		{
			title: 'Return to FC at',
			dataIndex: 'return_to_fc_at',
			key: 'return_to_fc_at',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={record?.return_to_fc_at} format="HMSA" />
				</Text>
			),
		},
		{
			title: 'Return process completed at',
			dataIndex: 'return_process_completed_at',
			key: 'return_process_completed_at',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={record?.return_process_completed_at} format="HMSA" />
				</Text>
			),
		},
		{
			title: 'Return process completed by',
			dataIndex: 'attemptedOrders',
			key: 'attemptedOrders',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.ReturnCompletedBy?.first_name} {record?.ReturnCompletedBy?.last_name}
				</Text>
			),
		},
		{
			title: '',
			dataIndex: 'actions',
			key: 'actions',
			sortId: 6,
			hidden: false,
			render: (_, record) => (
				<Link to={`/logistics-management/return-to-fc/${record?.id}/show`}>
					<Image src={viewIcon} alt="edit-icon" height={15} width={20} />
				</Link>
			),
		},
	];
};

const billStatusOption = [
	{ label: 'Invoice Returned', value: 'Bill Back' },
	{ label: 'Invoice Settled', value: 'No Bill Back' },
	{ label: 'Pending', value: 'Pending' },
	{ label: 'Voucher', value: 'Voucher' },
];

export const returnToFcShowTableConfig = ({
	editmode = false,
	handleSelect,
	ReturnableCompleted,
	handleBillStatus,
	reasonList,
	returnStatus,
	setCollectionModalVisible,
	invoice_id,
	userGroups,
	is_superuser,
	showData,
}) => [
	{
		title: 'Invoice Date',
		dataIndex: 'invoice_date',
		key: 'invoice_date',
		overflow: 'hidden',
		hidden: false,
		render: (invoice_date) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				<DateFormat date={invoice_date} />
			</Text>
		),
	},
	{
		title: 'Invoice No',
		dataIndex: 'invoice_no',
		key: 'invoice_no',
		overflow: 'hidden',
		hidden: false,
		render: (invoice_no) => (
			<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
				{invoice_no || ''}
			</Text>
		),
	},
	{
		title: 'Store',
		dataIndex: 'store',
		key: 'store',
		overflow: 'hidden',
		hidden: false,
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{record?.Store?.name || ''}
			</Text>
		),
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		overflow: 'hidden',
		hidden: false,
		width: '18%',
		render: (status, tableData) =>
			editmode ? (
				<Flex flexDirection="column">
					<Select
						style={{ minWidth: '100%', width: '160px' }}
						onChange={(value) => handleSelect(value, tableData.id)}
						value={getstatus(tableData.status)}
						showSearch
						filterOption={SelectFilterOptions}
						disabled={
							ReturnableCompleted ||
							showData?.return_status?.toLowerCase() === 'complete' ||
							!(
								UserPersmission.returnToFC_validate.includes(userGroups) ||
								is_superuser
							)
						}
					>
						<Option value="DL">Delivered</Option>
						<Option value="PD">Partial Delivered</Option>
						<Option value="CA">Cancelled</Option>
						<Option value="DA">Delivery Attempted</Option>
					</Select>
					<Text fontSize="10px" ml="20px" mt="5px">
						{getstatus(tableData.status)}
					</Text>
				</Flex>
			) : (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<Text>{getstatus(status)}</Text>
				</Text>
			),
	},
	{
		title: ReturnableCompleted ? 'Bill Status' : 'Reason',
		dataIndex: 'unfulfilled_reason',
		key: 'unfulfilled_reason',
		overflow: 'hidden',
		hidden: false,
		width: '15%',
		render: (unfulfilled_reason, tableData) =>
			editmode && ReturnableCompleted ? (
				<Select
					style={{ minWidth: '100%', width: '160px' }}
					showSearch
					filterOption={SelectFilterLabelOptions}
					defaultValue={
						tableData?.OutstandingInvoice?.bill_status === 'Void Bill'
							? ''
							: tableData?.OutstandingInvoice?.bill_status
					}
					value={tableData?.OutstandingInvoice?.bill_status}
					disabled={!['PD', 'DL'].includes(tableData.status)}
					onChange={(value) => handleBillStatus(value, tableData)}
					options={billStatusOption}
				/>
			) : (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{reasonList?.find((r) => r?.id === unfulfilled_reason)?.name || ''}
				</Text>
			),
	},
	{
		title: 'Invoice Amt',
		dataIndex: 'total_net_amount',
		key: 'total_net_amount',
		overflow: 'hidden',
		hidden: false,
		render: (total_net_amount) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				<Rupee>{total_net_amount || 0}</Rupee>
			</Text>
		),
	},
	{
		title: 'Collec Amt',
		dataIndex: 'payment_amount',
		key: 'payment_amount',
		overflow: 'hidden',
		hidden: false,
		render: (payment_amount) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				<Rupee>{payment_amount || 0}</Rupee>
			</Text>
		),
	},
	{
		title: 'Collec Mode',
		dataIndex: 'payment_type',
		key: 'payment_type',
		overflow: 'hidden',
		hidden: false,
		render: (payment_type) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{payment_type || ''}
			</Text>
		),
	},
	{
		title: 'Chq No.',
		dataIndex: 'cheque_no',
		key: 'cheque_no',
		overflow: 'hidden',
		hidden: false,
		render: (cheque_no) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{cheque_no || ''}
			</Text>
		),
	},
	{
		title: 'Chq Due Date',
		dataIndex: 'cheque_due_date',
		key: 'cheque_due_date',
		overflow: 'hidden',
		hidden: false,
		render: (cheque_due_date) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				<DateFormat date={cheque_due_date} />
			</Text>
		),
	},
	{
		title: 'Bank',
		dataIndex: 'bank',
		key: 'bank',
		overflow: 'hidden',
		hidden: false,
		render: (bank, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{record?.Bank?.name || ''}
			</Text>
		),
	},
	{
		title: 'Ref No.',
		dataIndex: 'ref_no',
		key: 'ref_no',
		overflow: 'hidden',
		hidden: false,
		render: (ref_no) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{ref_no || ''}
			</Text>
		),
	},
	{
		title: '',
		dataIndex: 'actions',
		key: 'actions',
		hidden: false,
		render: (_, record) =>
			ReturnableCompleted &&
			returnStatus !== 'Complete' &&
			!['OD', 'DA', 'VA', 'CA'].includes(record?.status) && (
				<Text
					cursor="pointer"
					onClick={() =>
						setCollectionModalVisible({
							invoice_id,
							id: record?.id,
							visible: true,
							status: record?.status,
							record,
						})
					}
				>
					<Image src={PaymentIcon} alt="rupee-icon" height={20} width={20} />
				</Text>
			),
	},
];

export const ReturnProductTableConfig = ({
	mismatchChange,
	handleDeliveredQty,
	selectChange,
	handleActualQty,
	error,
}) => [
	{
		title: 'Product',
		dataIndex: 'Product',
		key: 'Product',
		overflow: 'hidden',
		hidden: false,
		render: (Product) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{Product?.name || ''}
			</Text>
		),
	},
	{
		title: 'MRP',
		dataIndex: 'Product',
		key: 'Product',
		overflow: 'hidden',
		hidden: false,
		render: (Product) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{Product?.mrp || ''}
			</Text>
		),
	},
	{
		title: 'Order Qty',
		dataIndex: 'qty',
		key: 'qty',
		overflow: 'hidden',
		hidden: false,
		render: (qty) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{qty || ''}
			</Text>
		),
	},
	{
		title: 'Delivered Qty',
		dataIndex: 'delivered_qty',
		key: 'delivered_qty',
		overflow: 'hidden',
		hidden: false,
		render: (delivered_qty, record) => (
			<InputNumber
				min={0}
				max={record.order_qty}
				defaultValue={delivered_qty}
				name="delivered_qty"
				disabled={delivered_qty === 0}
				onChange={(value) => handleDeliveredQty(value, record)}
			/>
		),
	},
	{
		title: 'Return Qty',
		dataIndex: 'return_qty',
		key: 'return_qty',
		overflow: 'hidden',
		hidden: false,
		render: (return_qty, rec) => {
			let returnValue = 0;

			if (rec?.status === 'CA' || rec?.status === 'DA') {
				returnValue = rec?.qty;
			}
			if (rec?.status === 'PD') {
				returnValue = (rec?.qty || 0) - (rec?.delivered_qty || 0);
			}
			return (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{returnValue}
				</Text>
			);
		},
	},
	{
		title: 'Return Reason',
		dataIndex: 'return_reason',
		key: 'return_reason',
		width: '20%',
		render: (return_reason, record) => {
			if (record.return_qty > 0 || return_reason) {
				const filter = {
					is_attempt: record.status === 'DA',
					is_cancel: record.status === 'CA',
					is_partial: record.status === 'PD',
					is_return: true,
				};
				return (
					<>
						<ModalSelect
							returnReason={return_reason}
							width="100%"
							filter={filter}
							selectChange={(value) => selectChange(value, record)}
						/>
						{error && (
							<Text mt="0" fontSize="10px" color="#d00000">
								Required
							</Text>
						)}
					</>
				);
			}
			return <Text></Text>;
		},
	},
	{
		title: 'Actual Return Qty',
		dataIndex: 'actual_return_qty',
		key: 'actual_return_qty',
		render: (actual_return_qty, record) => {
			if (record.return_qty) {
				return (
					<InputNumber
						min={0}
						max={record.return_qty}
						defaultValue={record.return_qty}
						name="actual_return_qty"
						onChange={(value) => handleActualQty(value, record)}
					/>
				);
			}
			return <Text></Text>;
		},
	},
	{
		title: 'Mismatch Qty',
		dataIndex: 'mismatch_qty',
		key: 'mismatch_qty',
		render: (q) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{q || 0}
			</Text>
		),
	},
	{
		title: 'Mismatch Reason',
		dataIndex: 'return_mismatch_reason',
		key: 'return_mismatch_reason',
		render: (_, record) => {
			if (record.mismatch_qty > 0) {
				const filter = { is_mismatch: true, user_fcs_only: true };
				return (
					<>
						<ModalSelect
							width="100%"
							filter={filter}
							tableData={record}
							mismatchChange={(value) => mismatchChange(value, record)}
							mismatch
						/>
						{error && (
							<Text mt="5px" fontSize="10px" color="#d00000">
								Required
							</Text>
						)}
					</>
				);
			}
			return <Text></Text>;
		},
	},
];
