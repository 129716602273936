/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { initialPaginateConfig } from 'utils';

const fetchQueries = (searchParams, filterQuery) => {
	let params = {};

	searchParams.forEach((value, key) => {
		const filterFn = filterQuery?.filter((x) => x.query === key) || [];
		if (filterFn.length > 0) {
			params = {
				...params,
				[key]: filterFn[0].transformFn(value),
			};
		} else {
			params = {
				...params,
				[key]: value,
			};
		}
	});

	/**
	 * If you need any default value while loading the page,
	 * assign some values in filterQuery.value
	 */
	const defaultValue = filterQuery.reduce((acc, curr) => {
		if (!acc[curr?.query] && curr?.value) {
			acc[curr?.query] = curr?.value;
		}
		return acc;
	}, {});

	return { ...initialPaginateConfig, ...defaultValue, ...params };
};

/**
 *  props.filterQuery is for transforming query params into your desired data type
 */
export default function useSearchQuery({ filterQuery = [] }) {
	const [searchParams, setSearchParams] = useSearchParams();
	const [paginateConfig, setpaginateConfig] = useState(fetchQueries(searchParams, filterQuery));

	useEffect(() => {
		// Here we are adding the params in the URL while the paginateConfig changes
		const { sort, ...rest } = paginateConfig;

		const newSearchParams = new URLSearchParams({ ...sort, ...rest });
		setSearchParams(newSearchParams);
	}, [paginateConfig]);

	return [paginateConfig, setpaginateConfig];
}
