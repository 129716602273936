import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Col, Row, Form, Input, Select } from 'antd';
import { LabelWrapper } from 'components/common';
import { Flex } from 'atoms';
import { ErrorText } from 'components/common/ErrorText';

const StyledInput = styled(Input)`
	margin-top: 8px;
	background: '#FAF9F8';
	font-size: 13px;
	font-weight: 500;
	border: 1px solid #dbdbdb;
`;
const StyledCol = styled(Col)`
	label {
		width: 100%;
	}
`;

export default function CrateTracking({
	crateData,
	crateFieldValues,
	isVerified,
	setCrateFieldValues,
}) {
	return crateData?.crate_log?.dispatched_crate > 0 ? (
		<Flex>
			<Form layout="horizontal">
				<Row gutter={24}>
					<Col style={{ width: '165px' }}>
						<LabelWrapper
							label="Dispatched Crate Count"
							style={{ fontSize: '13px', fontWeight: 500 }}
						>
							<StyledInput
								type="number"
								name="dispatched_crate"
								value={crateData?.crate_log?.dispatched_crate}
								disabled
							/>
						</LabelWrapper>
					</Col>
					<Col style={{ width: '160px' }}>
						<LabelWrapper
							label="Received Crate Count"
							placeholder="Enter here"
							style={{ fontSize: '13px', fontWeight: 500 }}
						>
							<StyledInput
								disabled={
									isVerified || crateData?.crate_log?.dispatched_crate === null
								}
								type="number"
								min="0"
								name="received_crate_count"
								defaultValue={crateData?.crate_log?.received_crate}
								onChange={(e) => {
									const inputValue = e.target.value;
									const isValidInput = /^\d*$/.test(inputValue);
									if (!isValidInput) {
										setCrateFieldValues({
											...crateFieldValues,
											error: true,
										});
										e.preventDefault();
									} else {
										setCrateFieldValues({
											...crateFieldValues,
											received_crate_count: inputValue,
											error: false,
											edit: true,
										});
									}
								}}
								status={
									(crateFieldValues?.received_crate_count >
										crateData?.crate_log?.dispatched_crate ||
										crateFieldValues?.error) &&
									'error'
								}
							/>

							{crateFieldValues?.received_crate_count >
								crateData?.crate_log?.dispatched_crate && (
								<ErrorText
									errorString="Received crate count cannot be greater than Dispatched crate count"
									style={{ fontSize: '8px', textAlign: 'left' }}
								/>
							)}
							{crateFieldValues?.error && (
								<ErrorText
									errorString="Received crate count cannot be a negative number"
									style={{ fontSize: '8px', textAlign: 'left' }}
								/>
							)}
						</LabelWrapper>
					</Col>
					{crateFieldValues?.received_crate_count <
						crateData?.crate_log?.dispatched_crate && (
						<StyledCol style={{ width: '160px' }}>
							<LabelWrapper
								label="Reason"
								style={{
									fontSize: '13px',
									fontWeight: 500,
									marginBottom: '8px',
									width: '100%',
								}}
							>
								<Select
									disabled={
										isVerified ||
										crateFieldValues.received_crate_count >=
											crateData?.crate_log?.dispatched_crate ||
										crateData?.crate_log?.dispatched_crate === null
									}
									allowClear
									showSearch
									className="customDropdown"
									name="reason"
									placeholder="Select Reason"
									defaultValue={crateData?.crate_log?.mismatch_reason}
									style={{ width: '100%' }}
									onChange={(value) =>
										setCrateFieldValues({
											...crateFieldValues,
											reason: value,
											edit: true,
										})
									}
									options={crateData?.mismatch_reason.map((option) => ({
										value: option?.id,
										label: <span>{option?.label}</span>,
									}))}
								/>
							</LabelWrapper>
						</StyledCol>
					)}
					{crateData?.crate_log?.dispatched_box ? (
						<Col style={{ width: '165px' }}>
							<LabelWrapper
								label="Dispatched Case Count"
								style={{ fontSize: '13px', fontWeight: 500 }}
							>
								<StyledInput
									type="text"
									name="case_count"
									value={crateData?.crate_log?.dispatched_box}
									disabled
								/>
							</LabelWrapper>
						</Col>
					) : null}
				</Row>
			</Form>
		</Flex>
	) : (
		<Flex></Flex>
	);
}

CrateTracking.propTypes = {
	isVerified: PropTypes.bool,
	crateFieldValues: PropTypes.object,
	crateData: PropTypes.object,
	setCrateFieldValues: PropTypes.func,
};
