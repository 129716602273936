import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'atoms';

const StyledButton = styled(Button)`
	font-size: 14px;
	border-radius: 4px;
	background-color: #107c10;
	width: 200px;
	height: 40px;
	padding: 10px;
	${({ disabled }) =>
		disabled &&
		css({
			backgroundColor: 'transparent',
			color: '#87BD87',
			border: '1px solid #87BD87',
			opacity: '0.7 !important',
		})}
`;

function UploadInvoiceButton({ handleUploadModalOpen, disabled, style }) {
	return (
		<StyledButton onClick={handleUploadModalOpen} disabled={disabled} style={style}>
			<UploadOutlined style={{ marginRight: '10px' }} />
			Upload Inv & Other Doc
		</StyledButton>
	);
}

export default UploadInvoiceButton;

UploadInvoiceButton.propTypes = {
	handleUploadModalOpen: PropTypes.func,
	disabled: PropTypes.bool,
	style: PropTypes.object,
};
