import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Input, Form } from 'formik-antd';
import { Row, Col } from 'antd';
import { FormInput, Box, AsyncSelect } from 'atoms';
import { LoadAsyncSelectComponent } from 'components/common';
import { StyledSubmitButton } from 'components/forms/masters/helper';
import { getCompanyOnSearchList, CreateUpdateClient, getClientId } from 'store/OnBoardSlice';
import { ClientFormSchema, ClientInitialValues } from './helper';
import { Debug } from 'components/forms';
import { SelectOptions } from 'utils';

export default function ClientEditCreateForm() {
	const navigate = useNavigate();
	const { id: clientId } = useParams();
	const { pathname } = useLocation();
	const [companylist, setcompanylist] = useState({ type: 'NotAsked' });
	const [formInitial, setFormInitial] = useState({});
	const dispatch = useDispatch();
	const isEditPage = pathname.includes('edit');

	useEffect(() => {
		setcompanylist({ type: 'Loading' });

		const hasEditForm = isEditPage ? dispatch(getClientId({ clientId })) : Promise.resolve();

		hasEditForm
			.then((resp) => {
				setFormInitial(
					isEditPage ? ClientInitialValues(resp?.data) : ClientInitialValues()
				);
				return true;
			})
			.then(() => {
				dispatch(getCompanyOnSearchList({})).then((res) => {
					setcompanylist({
						type: 'Success',
						data: SelectOptions(res, 'name', 'id'),
					});
				});
			});
	}, [dispatch, clientId, isEditPage]);

	const handleSubmit = (values, { setSubmitting, setErrors }) => {
		const { code, ...rest } = values;
		const payload = isEditPage ? { ...rest, id: +clientId } : { ...rest };
		dispatch(CreateUpdateClient({ data: payload, setErrors }))
			.then(() => navigate('/onboarding/client'))
			.finally(() => {
				setSubmitting(false);
			});
	};

	const handleSelect = (val, name, setFieldValue) => setFieldValue(name, val?.value);

	return (
		<Box>
			<Formik
				initialValues={formInitial}
				validationSchema={ClientFormSchema}
				enableReinitialize
				onSubmit={handleSubmit}
			>
				{({ setFieldValue, values, isValid, isSubmitting, dirty }) => (
					<Form layout="vertical">
						<Box fontSize="16px" fontWeight="700" padding="1rem 0">
							{isEditPage ? 'Edit Client Details' : 'Add Client Details'}
						</Box>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="name" label="*Client Name">
									<Input name="name" placeholder="Enter Client Name" />
								</FormInput>
							</Col>
							{/* <Col span={8}>
								<FormInput name="nick_name" label="*Client Nick Name">
									<Input name="nick_name" placeholder="Enter Client Nick Name" />
								</FormInput>
							</Col> */}
							{isEditPage && (
								<Col span={8}>
									<FormInput name="code" label="Client Code">
										<Input disabled={isEditPage} name="code" />
									</FormInput>
								</Col>
							)}
							<Col span={8}>
								<FormInput name="company_id" label="*Company">
									<LoadAsyncSelectComponent options={companylist}>
										<AsyncSelect
											className="customDropdown"
											disabled={isEditPage}
											allowClear
											name="company_id"
											showSearch
											component="formikSelect"
											defaultValue={
												companylist?.data?.filter(
													(x) => x?.value === values?.company_id
												)[0] || null
											}
											fetchOptions={getCompanyOnSearchList}
											onChange={(val) =>
												handleSelect(val, 'company_id', setFieldValue)
											}
											placeholder="Select Company"
											transformOptions={(val) =>
												SelectOptions(val, 'name', 'id')
											}
											selectOptions={[...(companylist.data || [])]}
										/>
									</LoadAsyncSelectComponent>
								</FormInput>
							</Col>
						</Row>
						<Box borderBottom="1px solid rgba(0, 0, 0, 0.1)"></Box>
						<Box fontSize="16px" fontWeight="700" padding="1rem 0">
							Client Prefix Configurations
						</Box>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="supplier_prefix" label="*Supplier Prefix">
									<Input
										disabled={isEditPage}
										name="supplier_prefix"
										placeholder="Enter Supplier Prefix"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="brand_prefix" label="*Brand Prefix">
									<Input
										disabled={isEditPage}
										name="brand_prefix"
										placeholder="Enter Brand Prefix"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="product_prefix" label="*Product Prefix">
									<Input
										disabled={isEditPage}
										name="product_prefix"
										placeholder="Enter Product Prefix"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="uom_prefix" label="*UOM Prefix">
									<Input
										disabled={isEditPage}
										name="uom_prefix"
										placeholder="Enter UOM Prefix"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="category_prefix" label="*Category Prefix">
									<Input
										disabled={isEditPage}
										name="category_prefix"
										placeholder="Enter Category Prefix"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="fc_prefix" label="*FC Prefix">
									<Input
										disabled={isEditPage}
										name="fc_prefix"
										placeholder="Enter FC Prefix"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="store_prefix" label="*Store Prefix">
									<Input
										disabled={isEditPage}
										name="store_prefix"
										placeholder="Enter Store Prefix"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="scheme_prefix" label="*Scheme Prefix">
									<Input
										disabled={isEditPage}
										name="scheme_prefix"
										placeholder="Enter Scheme Prefix"
									/>
								</FormInput>
							</Col>
						</Row>
						<Box mt="16px">
							<StyledSubmitButton disabled={isSubmitting || !isValid || !dirty}>
								{isEditPage ? 'Update' : 'Save'}{' '}
							</StyledSubmitButton>
						</Box>
						<Debug />
					</Form>
				)}
			</Formik>
		</Box>
	);
}
