import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { InvoicesList } from 'components/pages/segregator';
import { Modal, Table } from 'antd';
// import { columnsData } from 'RejectedListModal';
import { Flex, Button, Text } from 'atoms';
import { getInvoicesList, triggerAutoVerification, getRejectedList } from 'store/invoiceSlice';
import { CashierSalesManStatsHeader } from 'components/pages/cashier';
import { useURL } from 'hooks';
import { InvoiceCardView } from 'components';

const columnsData = [
	{
		title: 'Store Code',
		dataIndex: 'storeCode',
		key: 'storeCode',
		render: (storeCode) => <Text textTransform="uppercase">{storeCode}</Text>,
	},
	{
		title: 'Store',
		dataIndex: 'storeName',
		key: 'storeName',
		render: (storeName) => <Text textTransform="uppercase">{storeName}</Text>,
	},
	{
		title: 'Invoice No.',
		dataIndex: 'invoice_no',
		key: 'invoice_no',
		render: (invoice_no) => <Text textTransform="uppercase">{invoice_no}</Text>,
	},
	{
		title: 'Amount',
		dataIndex: 'amount',
		key: 'amount',
	},
	{
		title: 'Payment Mode',
		dataIndex: 'payment_type',
		key: 'payment_type',
	},
	{
		title: 'Reference',
		dataIndex: 'reference_number',
		key: 'reference_number',
	},
	{
		title: 'Due Date',
		dataIndex: 'due_date',
		key: 'due_date',
	},
];
export function InvoicesVerifiedList() {
	const dispatch = useDispatch();
	const [invoices, setInvoices] = useState([]);
	const [isInValid, setIsInValid] = useState(false);
	const [allVerified, setAllVerified] = useState(false);
	const [dataSummary, setDataSummary] = useState({});
	const [headerList, setHeaderList] = useState(0);
	const [rejectedPaymentsList, setRejectedPaymentsList] = useState();
	const navigate = useNavigate();
	const urlData = useURL();
	const { role } = useSelector((state) => state.auth);
	const { cashierPersonHeaderDetails = {}, dateTrigger } = useSelector((state) => state.invoice);

	useEffect(() => {
		/**
		 * On cashier login, show verification icon based on verified_by_segregator_status
		 */
		dispatch(getInvoicesList(urlData)).then((res) => {
			setDataSummary(res?.data_summary);
			setIsInValid(
				!res?.data?.every(
					(item) => item?.verified_by_segregator_status === 'VerifiedBySegregator'
				)
			);
			res?.data?.forEach((element) => {
				element.verification_checked =
					element?.verified_by_segregator_status === 'VerifiedBySegregator';
			});
			const isAllCompleted = res?.data?.every(
				(element) => element?.verified_by_cashier_status === 'VerifiedByCashier'
			);
			setInvoices(res?.data);
			setAllVerified(isAllCompleted);
		});
	}, [dispatch, urlData, role, dateTrigger]);

	function handleAutoVerification() {
		const cid = invoices?.map((item) => item?.collection_invoice_id)?.toString();
		dispatch(
			triggerAutoVerification(
				cid,
				headerList.data_summary.allocation_date_time,
				headerList.data_summary.collected_person_id,
				headerList.data_summary.tab
			)
		).then(() => {
			startVerification();
		});
	}

	function onClickShowRejectedList() {
		console.log(headerList);

		dispatch(getRejectedList(headerList?.data_summary?.cid)).then((r) => {
			if (r.data.length !== 0) {
				setRejectedPaymentsList(r.data);
			} else {
				handleAutoVerification();
			}
		});
		// .catch((d) => {
		// 	if (d.status === 400 && d.data.length === 0) {
		// 		handleAutoVerification();
		// 	}
		// });
	}

	function onCancel() {
		setRejectedPaymentsList(null);
	}

	function startVerification() {
		const { total_cash, total_cheque, total_upi, total_neft } = dataSummary;
		const { payment_verification_status } = cashierPersonHeaderDetails;
		let link = `/cashier/cash-verify`;

		if (total_cash === 0 && total_cheque === 0 && total_upi === 0 && total_neft === 0) {
			/**
			 * if no amount is collected, then by default verify all section using api call
			 */
			link = `/cashier`;
		} else if (total_cash > 0 && !payment_verification_status?.Cash) {
			link = `/cashier/cash-verify`;
		} else if (total_cheque > 0 && !payment_verification_status?.Cheque) {
			/**
			 * if no cash, no UPI available only cheque avaiable then, redirect to cheque verification page, skip cash page
			 */
			link = `/cashier/cheque-verify`;
		} else if (total_upi > 0 && !payment_verification_status?.UPI) {
			/**
			 * if no cash, no cheque available only UPI available then, redirect to UPI verification page, skip cash and cheque pages
			 */
			link = `/cashier/upi-verify`;
		} else if (total_neft > 0) {
			link = `/cashier/neft-verify`;
		}
		navigate({
			pathname: link,
			search: urlData?.queryString,
		});
	}

	return (
		<div>
			<CashierSalesManStatsHeader setHeaderData={setHeaderList} />
			{/* <InvoicesList invoices={invoices} /> */}
			<InvoiceCardView invoices={invoices} />
			<Flex justifyContent="flex-end" mt="2rem">
				<Button
					// onClick={handleAutoVerification}
					onClick={onClickShowRejectedList}
					disabled={invoices?.length === 0 || isInValid}
				>
					{allVerified ? 'Verified Click to view details' : 'Start Verification'}
				</Button>
			</Flex>

			<Modal
				centered
				visible={rejectedPaymentsList}
				title={<Text textAlign="center">List of rejected Payments</Text>}
				onOk={handleAutoVerification}
				onCancel={onCancel}
				okText="OK"
				width={1000}
				maskClosable={false}
				bodyStyle={{
					maxHeight: '500px',
					overflow: 'auto',
				}}
			>
				<Table
					rowKey={(record) => `${record?.key}`}
					pagination={false}
					dataSource={rejectedPaymentsList}
					columns={columnsData}
				/>
			</Modal>
		</div>
	);
}
