import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import { Flex } from 'atoms';
import UserInfo from 'components/common/sidebar/UserInfo';
import { BreadCrumbs } from 'components/common';
import { orangeTheme, oldTheme } from 'styles/theme';

const Container = styled(Flex)`
	align-items: center;
	justify-content: space-between;
	height: 64px;
	background: white;
	padding-right: 1rem;
	box-shadow: 0px 2px 4px -1px rgb(145 158 171 / 20%), 0px 4px 5px 0px rgb(145 158 171 / 14%),
		0px 1px 10px 0px rgb(145 158 171 / 12%);
	min-height: 64px;
	position: fixed;
	width: ${(props) => (props?.sideBarStatus ? 'calc(100% - 40px)' : 'calc(100% - 210px)')};
	z-index: 1000;
	top: 0;
	left: ${(props) => (props?.sideBarStatus ? '40px' : '210px')};
	transition: all 0.3s;
`;

const Box = styled.span`
	display: inline-block;
	width: 20px;
	height: 20px;
	background-color: ${(props) => props.color};
	margin: 5px;
	cursor: pointer;
`;

export default function Header({ sideBarStatus = false, urls = [], settoggleTheme = () => {} }) {
	return (
		<Container sideBarStatus={sideBarStatus}>
			<BreadCrumbs urls={urls} />
			<Flex>
				<Radio.Group
					onChange={(e) => settoggleTheme(e.target.value)}
					defaultValue="oldtheme"
					style={{ alignSelf: 'center' }}
				>
					<Box
						title="New Theme"
						color={oldTheme?.colors?.secondary}
						onClick={() => settoggleTheme('oldtheme')}
					/>
					<Box
						title="Orange Theme"
						color={orangeTheme?.colors?.secondary}
						onClick={() => settoggleTheme('orangetheme')}
					/>
				</Radio.Group>
				<UserInfo />
			</Flex>
		</Container>
	);
}

Header.propTypes = {
	urls: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			text: PropTypes.string,
			link: PropTypes.string,
		})
	),
	sideBarStatus: PropTypes.bool,
	settoggleTheme: PropTypes.func,
};
