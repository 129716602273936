import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Text } from 'atoms';
import DateFormat from 'components/DateFormat';
import { ACL } from 'components/common';
import { UserPersmission } from 'utils/userPermission';

export const salesmanListColumns = [
	{
		title: 'Salesman ID',
		dataIndex: 'id',
		key: 'id',
		sortId: 1,
		overflow: 'hidden',
		hidden: false,
		render: (id) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{id || ''}
			</Text>
		),
	},
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		sortId: 1,
		overflow: 'hidden',
		hidden: false,
		render: (name) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{name || ''}
			</Text>
		),
	},
	{
		title: 'Code',
		dataIndex: 'code',
		key: 'code',
		sortId: 1,
		overflow: 'hidden',
		hidden: false,
		render: (code) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{code || ''}
			</Text>
		),
	},
	{
		title: 'Mobile No',
		dataIndex: 'mobile',
		key: 'mobile',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (mobile) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{mobile || ''}
			</Text>
		),
	},
	{
		title: 'FC',
		dataIndex: 'FC',
		key: 'FC',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (FC) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{FC?.name || ''}
			</Text>
		),
	},
	{
		title: 'Brands',
		dataIndex: 'Brand',
		key: 'Brand',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (Brand) => (
			<Text
				overFlow="hidden"
				fontSize="12px"
				color="#000"
				wordBreak="break-all"
				whiteSpace="normal"
			>
				{Brand?.name || ''}
			</Text>
		),
	},
	{
		title: 'Created At',
		dataIndex: 'createdAt',
		key: 'createdAt',
		sortId: 2,
		overflow: 'hidden',
		hidden: false,
		render: (createdAt) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				<DateFormat date={createdAt} format="HMSA" />
			</Text>
		),
	},
	{
		title: '',
		dataIndex: 'id',
		key: 'id',
		sortId: 6,
		render: (id) => (
			<ACL allowedGroups={UserPersmission.salesman_create}>
				<Link style={{ color: '#000' }} to={`/onboarding/salesman/${id}/edit`}>
					<EditOutlined />
				</Link>
			</ACL>
		),
	},
];
