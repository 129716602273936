/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isEmpty, uniqBy } from 'lodash';
import { Select } from 'antd';
import { useDispatch } from 'react-redux';
import { Box, Flex, Button, AsyncSelect } from 'atoms';
import * as SVGICONS from 'utils/Svg';
import { SearchField } from 'components/common/custom-table/Style';
import { ACL, RCTable, LabelWrapper, LoadAsyncSelectComponent, SearchBar } from 'components/common';
import {
	salesmanListColumns,
	UserPersmission,
	handleFilterChange,
	SelectFilterLabelOptions,
	toAsysnOption,
	SalesmanQueries,
	initialPaginateConfigWithoutQ,
	searchBox,
} from 'utils';
import { getSalesMenOnSearchList } from 'store/fulfilment/deliveryAllocationSlice';
import { getFcOnSearchList, getSalesmanList, getFcListOnLoad } from 'store/masters';
import { useQueryParams } from 'hooks';
const CommonStyle = {
	width: '200px',
	alignSelf: 'center',
	margin: '0 1rem 1rem',
};

export default function SalesManOnboarding() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [salesManList, setsalesManList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);
	const [rowData, setRowData] = useState({});
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [paginateConfig, setpaginateConfig] = useQueryParams({
		filterQuery: SalesmanQueries,
	});

	const onLoadFcs = (fcsIds = []) =>
		dispatch(getFcListOnLoad({ fcIds: fcsIds })).then((res) =>
			setFcList({ type: 'Success', data: toAsysnOption(res) })
		);

	const onLoadSalesMan = async (fc_ids = []) =>
		dispatch(
			getSalesMenOnSearchList({
				fc_ids: fc_ids || [],
			})
		).then((res) => setsalesManList({ type: 'Success', data: toAsysnOption(res) }));

	useEffect(() => {
		setFcList({ type: 'Loading' });
		setsalesManList({ type: 'Loading' });
		onLoadFcs(paginateConfig?.fcs);
		onLoadSalesMan(paginateConfig?.fcs);
	}, [dispatch]);

	const handleChangeSelect = (name, value) => {
		const getValue = isEmpty(value) ? [] : value.map((y) => y.value);
		setpaginateConfig((prev) => handleFilterChange(name, getValue, prev));
	};

	const handleFCChangeSelect = (name, value, brandsOptions = []) => {
		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		const fcValues = value?.value ? [value?.value] : [];
		onLoadSalesMan(fcValues).then(() =>
			setpaginateConfig((prev) => ({
				...handleFilterChange(name, fcValues, prev),
				brands: [],
				salesmen: [],
			}))
		);
	};

	return (
		<>
			<Flex alignItems="center" margin="0 0 1rem">
				<Flex width="100%" flexWrap="wrap" alignItems="center" gap="4px">
					<LabelWrapper label="Search by Mobile Number" marginspace="-1px 0 5px 0">
						<SearchField>
							<SearchBar
								placeholder="Search by Mobile Number"
								queryValue={paginateConfig?.mobile_q?.replaceAll('*', '')}
								onSearch={(val) =>
									setpaginateConfig((prev) => ({
										...prev,
										limit: 10,
										offset: 0,
										currentPage: 1,
										mobile_q: val,
									}))
								}
							/>
						</SearchField>
					</LabelWrapper>

					<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
						<LoadAsyncSelectComponent
							loadFCs={[paginateConfig?.fcs]}
							setList={(val) => setBrandList(val)}
							options={fcsList}
						>
							<AsyncSelect
								style={searchBox}
								allowClear
								name="fcs"
								showSearch
								fetchOptions={getFcOnSearchList}
								transformOptions={(val) => toAsysnOption(val)}
								defaultValue={paginateConfig?.fcs}
								placeholder="Select FC(s)"
								onChange={(value, options) => {
									handleFCChangeSelect('fcs', value, options);
								}}
								selectOptions={[...(fcsList.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>

					<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
						<Select
							name="fields"
							style={searchBox}
							allowClear
							showSearch
							label="brands"
							mode="multiple"
							value={paginateConfig?.brands}
							filterOption={SelectFilterLabelOptions}
							options={brandsList}
							onChange={(value) =>
								setpaginateConfig((prev) => ({
									...prev,
									brands: value,
									...initialPaginateConfigWithoutQ,
								}))
							}
						/>
					</LabelWrapper>

					<LabelWrapper label="Salesmen(s)" marginspace="0 0 5px 1rem">
						<LoadAsyncSelectComponent
							loadFCs={paginateConfig?.salesmen}
							setList={(val) => setsalesManList(val)}
							options={salesManList}
						>
							<AsyncSelect
								style={CommonStyle}
								allowClear
								name="salesmen"
								mode="multiple"
								defaultValue={paginateConfig?.salesmen}
								value={paginateConfig?.salesmen}
								apiParams={{ fc_ids: paginateConfig?.fcs || [] }}
								fetchOptions={getSalesMenOnSearchList}
								placeholder="Salesmen(s)"
								onChange={(value) => handleChangeSelect('salesmen', value)}
								selectOptions={[...(salesManList?.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>
					<Button onClick={() => setBtnClickSearh(Math.random())}>Search</Button>
				</Flex>

				<ACL allowedGroups={UserPersmission.salesman_create}>
					<Button onClick={() => navigate('/onboarding/salesman/add')} type="variant">
						<Flex alignItems="center" justifyContent="center">
							<Box width={30}>
								<SVGICONS.PlusOutlinedIcons />
							</Box>
							<Box>Add Salesman</Box>
						</Flex>
					</Button>
				</ACL>
			</Flex>
			<Box mt={3}>
				<RCTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					dispatchAction={getSalesmanList}
					columns={salesmanListColumns}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					configs={{ defaultSort: 'DESC', defaultsortField: 'created_at' }}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
		</>
	);
}
