import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'antd';
import { uniqBy } from 'lodash';
import { DatePicker, Select } from 'formik-antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { LoadAsyncSelectComponent } from 'components/common';
import { FormInput, AsyncSelect } from 'atoms';
import {
	dateFormat,
	disableFutureDate,
	SelectFilterLabelOptions,
	toAsysnOption,
	disableDateRangesMonth,
} from 'utils';
import { getFcOnSearchList } from 'store/masters';
import { getCollectionDetailsReport } from 'store/downloads/reportSlice';
import { BtnWrap, StyledSubmitButton } from '../masters/helper';
import { getOptions } from './helpers';

const FormSchema = Yup.object({
	// invoice_date__gte: Yup.date(),
	// invoice_date__lte: Yup.date().when(
	// 	'invoice_date__gte',
	// 	(invoice_date__gte, yup) =>
	// 		invoice_date__gte &&
	// 		yup
	// 			.min(invoice_date__gte, 'To date cannot be less than From date')
	// 			.max(
	// 				moment(invoice_date__gte).add(31, 'days').format(),
	// 				'To date cannot be greater than 31 days'
	// 			)
	// ),
	verification_date_gte: Yup.date().when(['invoice_date__gte', 'invoice_date__lte'], {
		is: (invoiceDateGte, invoiceDateLte) => !invoiceDateGte && !invoiceDateLte,
		then: () =>
			Yup.date().required('From date is required').label('From - Cashier Verification Date'),
		otherwise: Yup.date().nullable().label('To - Cashier Verification Date'),
	}),
	verification_date_lte: Yup.date().when(['invoice_date__gte', 'invoice_date__lte'], {
		is: (invoiceDateGte, invoiceDateLte) => !invoiceDateGte && !invoiceDateLte,
		then: () =>
			Yup.date().required('To date is required').label('To - Cashier Verification Date'),
		otherwise: Yup.date().nullable().label('From - Cashier Verification Date'),
	}),
});

// const FormSchema = Yup.object({
// 	invoice_date__gte: Yup.date(),
// 	invoice_date__lte: Yup.date().when(
// 		'invoice_date__gte',
// 		(invoice_date__gte, yup) =>
// 			invoice_date__gte &&
// 			yup
// 				.min(invoice_date__gte, 'To date cannot be less than From date')
// 				.max(
// 					moment(invoice_date__gte).add(31, 'days').format(),
// 					'To date cannot be greater than 31 days'
// 				)
// 	),
// 	// verification_date_gte: Yup.date(),
// 	// verification_date_lte: Yup.date(),
// 	// verification_date_lte: Yup.date().when(
// 	// 	'verification_date_gte',
// 	// 	(verification_date_gte, yup) =>
// 	// 		verification_date_gte &&
// 	// 		yup
// 	// 			.min(verification_date_gte, 'To date cannot be less than From date')
// 	// 			.max(
// 	// 				moment(verification_date_gte).add(31, 'days').format(),
// 	// 				'To date cannot be greater than 31 days'
// 	// 			)
// 	// ),
// 	verification_date_gte: Yup.date().when(['invoice_date__gte', 'invoice_date__lte'], {
// 		is: (invoiceDateGte, invoiceDateLte) => !invoiceDateGte && !invoiceDateLte,
// 		then: Yup.date()
// 			.required('From date is required')
// 			.label('From - Cashier Verification Date'),
// 		otherwise: Yup.date().label('From - Cashier Verification Date'),
// 	}),
// 	verification_date_lte: Yup.date().when(['invoice_date__gte', 'invoice_date__lte'], {
// 		is: (invoiceDateGte, invoiceDateLte) => !invoiceDateGte && !invoiceDateLte,
// 		then: Yup.date().required('To date is required').label('To - Cashier Verification Date'),
// 		otherwise: Yup.date().label('To - Cashier Verification Date'),
// 	}),
// });

const initialValues = {
	invoice_date__gte: undefined,
	invoice_date__lte: undefined,
	verification_date_gte: undefined,
	verification_date_lte: undefined,
	fcs: undefined,
	brands: undefined,
	brandsOptions: [],
	fcsHiddenOptions: [],
};

const CommonStyle = {
	width: '250px',
	alignSelf: 'center',
};
export default function CollectionDetails({ setShowModal }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [startDate, setStartDate] = useState({});
	const [fcList, setFcList] = useState({ type: 'NotAsked' });

	useEffect(() => {
		setFcList({ type: 'Loading' });
		dispatch(getFcOnSearchList({})).then((res) =>
			setFcList({ type: 'Success', data: toAsysnOption(res) })
		);
	}, [dispatch]);

	const handleSubmit = (values, { resetForm, setSubmitting }) => {
		console.log('values', values);
		const data = {
			filter: {
				reportType: 'collection_detail',
				date_gte: values?.invoice_date__gte ? dateFormat(values?.invoice_date__gte) : null,
				date_lte: values?.invoice_date__lte ? dateFormat(values?.invoice_date__lte) : null,
				verification_date_gte: values?.verification_date_gte
					? dateFormat(values?.verification_date_gte)
					: null,
				verification_date_lte: values?.verification_date_lte
					? dateFormat(values?.verification_date_lte)
					: null,
				brands: values?.brands,
				fcs: getOptions(values?.fcs),
			},
		};

		// delete key if length is 0
		if (!data.filter?.date_gte) {
			delete data.filter?.date_gte;
		}
		if (!data.filter?.date_lte) {
			delete data.filter?.date_lte;
		}
		if (!data.filter?.verification_date_lte) {
			delete data.filter?.verification_date_lte;
		}
		if (!data.filter?.verification_date_gte) {
			delete data.filter?.verification_date_gte;
		}
		if (data.filter?.brands?.length === 0 || !data.filter?.brands) {
			delete data.filter?.brands;
		}
		if (data.filter?.fcs?.length === 0 || !data.filter?.fcs) {
			delete data.filter?.fcs;
		}

		dispatch(getCollectionDetailsReport(data))
			.then(() => {
				resetForm();
				setShowModal(false);
			})
			.then(() => navigate('/downloads?query=&offset=0&limit=10&currentPage=1'))
			.finally(() => {
				setSubmitting(false);
				window.location.reload();
			});
	};
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={FormSchema}
			enableReinitialize
			initialTouched={{
				fcs: true,
				brands: true,
			}}
		>
			{({ setFieldValue, values, isValid, dirty, errors }) => (
				<Form layout="vertical">
					<Row gutter={24} justify="space-between">
						<Col span={8}>
							<FormInput
								name="invoice_date__gte"
								label="*From - Collection Date"
								errorMessage={errors.invoice_date__gte}
							>
								<DatePicker
									name="invoice_date__gte"
									className="form__field"
									disabledDate={disableFutureDate}
									onChange={(val) => {
										setStartDate(val);
										setFieldValue('invoice_date__lte', undefined);
									}}
									showToday={false}
									disabled={values?.verification_date_gte}
								/>
							</FormInput>
						</Col>

						<Col span={8}>
							<FormInput
								name="invoice_date__lte"
								label="*To - Collection Date"
								errorMessage={errors.invoice_date__lte}
							>
								<DatePicker
									name="invoice_date__lte"
									className="form__field"
									disabledDate={disableDateRangesMonth(startDate)}
									showToday={false}
									disabled={values?.verification_date_gte}
								/>
							</FormInput>
						</Col>

						<Col span={8}>
							<FormInput
								name="verification_date_gte"
								label="*From - Cashier Verification Date"
								errorMessage={errors.verification_date_gte}
							>
								<DatePicker
									name="verification_date_gte"
									className="form__field"
									disabledDate={disableFutureDate}
									onChange={(val) => {
										setStartDate(val);
										setFieldValue('verification_date_lte', undefined);
									}}
									showToday={false}
									disabled={values?.invoice_date__gte}
								/>
							</FormInput>
						</Col>

						<Col span={8}>
							<FormInput
								name="verification_date_lte"
								label="*To - Cashier Verification Date"
								errorMessage={errors.verification_date_lte}
							>
								<DatePicker
									name="verification_date_lte"
									className="form__field"
									disabledDate={disableDateRangesMonth(startDate)}
									showToday={false}
									disabled={values?.invoice_date__gte}
								/>
							</FormInput>
						</Col>
						<Col span={8}>
							<FormInput name="fcs" label="FC(s)">
								<LoadAsyncSelectComponent options={fcList}>
									<AsyncSelect
										mode="single"
										showSearch
										name="fcs"
										style={CommonStyle}
										component="formikSelect"
										onChange={(value, options) => {
											const fvalue = [value];
											const fcValues = fvalue?.map((x) => x.value);
											const oldOptions = values?.fcsHiddenOptions?.filter(
												(x) => fcValues.includes(x.value)
											);

											const combineOptions = uniqBy(
												[...oldOptions, ...options],
												(x) => x.value
											);
											console.log(combineOptions);

											setFieldValue('fcs', fvalue);
											setFieldValue('brands', undefined);
											setFieldValue('brandsOptions', combineOptions);
											setFieldValue('fcsHiddenOptions', combineOptions);
										}}
										placeholder="Fc Type"
										selectOptions={[...(fcList?.data || [])]}
										fetchOptions={getFcOnSearchList}
									/>
								</LoadAsyncSelectComponent>
							</FormInput>
						</Col>
						<Col span={8}>
							<FormInput name="brands" label="Brand(s)">
								<Select
									showSearch
									className="customDropdown"
									name="brands"
									mode="multiple"
									style={CommonStyle}
									placeholder="Select Brand"
									filterOption={SelectFilterLabelOptions}
									options={
										uniqBy(
											values?.brandsOptions
												?.filter((x) =>
													values?.fcs
														?.map((y) => y.value)
														.includes(x.value)
												)
												?.map((x) => x?.brands || [])
												?.flat(),
											(x) => x.value
										) || []
									}
								/>
							</FormInput>
						</Col>
					</Row>
					<BtnWrap>
						<StyledSubmitButton
							disabled={
								!dirty ||
								!isValid ||
								((!values.invoice_date__gte || !values.invoice_date__lte) &&
									(!values.verification_date_gte ||
										!values.verification_date_lte))
							}
						>
							Request Report
						</StyledSubmitButton>
					</BtnWrap>
				</Form>
			)}
		</Formik>
	);
}

CollectionDetails.propTypes = {
	setShowModal: PropTypes.func,
};
