import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Text } from 'atoms';
import { ACL } from 'components/common';
import { UserPersmission } from 'utils/userPermission';

export const StoreListConfigs = [
	{
		title: 'Store Code',
		dataIndex: 'code',
		key: 'code',
		overflow: 'hidden',
		render: (code) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{code || ''}
			</Text>
		),
	},
	{
		title: 'Store Name',
		dataIndex: 'name',
		key: 'name',
		overflow: 'hidden',
		render: (name) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{name || ''}
			</Text>
		),
	},
	{
		title: 'GST Number',
		dataIndex: 'gstNumber',
		key: 'gstNumber',
		overflow: 'hidden',
		render: (gstNumber) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{gstNumber || ''}
			</Text>
		),
	},
	{
		title: 'PAN Number',
		dataIndex: 'pan_number',
		key: 'pan_number',
		overflow: 'hidden',
		render: (pan_number) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{pan_number || ''}
			</Text>
		),
	},
	{
		title: 'Contact Person Name',
		dataIndex: 'contact_person_fname',
		key: 'contact_person_fname',
		overflow: 'hidden',
		render: (_, record) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{`${record.contact_person_fname || ''} ${record.contact_person_lname || ''}`}
			</Text>
		),
	},
	{
		title: 'Contact Person Email',
		dataIndex: 'email',
		key: 'email',
		overflow: 'hidden',
		render: (email) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{email || ''}
			</Text>
		),
	},
	{
		title: 'Contact Phone Number',
		dataIndex: 'mobile',
		key: 'mobile',
		overflow: 'hidden',
		render: (phone) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{phone || ''}
			</Text>
		),
	},
	{
		title: '',
		dataIndex: 'id',
		key: 'id',
		sortId: 6,
		hidden: false,
		render: (id) => (
			<ACL allowedGroups={UserPersmission.store_create}>
				<Link style={{ color: '#000' }} to={`/onboarding/store/${id}/edit`}>
					<EditOutlined />
				</Link>
			</ACL>
		),
	},
];
