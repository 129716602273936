import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Modal, Radio, Space, message } from 'antd';
import PropTypes from 'prop-types';
import { Button, Flex } from 'atoms';
import { getChequeBounceDeleteOptions, markChequeVoid } from 'store/financeTeamSlice';

const StyledModal = styled(Modal)`
	.ant-modal-header {
		border-bottom: 1px transparent;
	}
	.ant-modal-title {
		font-size: 16px;
		font-weight: 400;
	}
	.ant-radio-checked .ant-radio-inner {
		border-color: #ca5010;
	}
	.ant-radio-inner::after {
		background-color: #ca5010;
	}
	.customStyle {
		width: 100%;
		padding: 10px;
		border-radius: 4px;
		color: #000;
		border: 1px solid #d7d7d7;
	}
	.highlighted {
		color: #ca5010;
		border: 1px solid #ca5010;
	}
`;

function ChequeBounceDeleteModal({
	modalVisible,
	setModalVisible,
	chequeBounceRowData,
	setListUpdated,
}) {
	const dispatch = useDispatch();
	const [chequeBounceDeleteReasons, setChequeBounceDeleteReasons] = useState();
	const [value, setValue] = useState();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		dispatch(getChequeBounceDeleteOptions()).then((res) => {
			setChequeBounceDeleteReasons(res);
		});
	}, [dispatch]);

	const markBouncedChequeVoid = () => {
		if (value) {
			dispatch(markChequeVoid(chequeBounceRowData, value))
				.then((res) => {
					setLoading(true);
					if (res.status === 'ok') {
						setLoading(false);
						setModalVisible(false);
						setListUpdated(true);
						message.success('List updated successfully');
					}
				})
				.catch((error) => {
					setLoading(false);
					message.error('Please try again, some error occured', error);
				});
		}
	};

	const onChange = (e) => {
		setValue(e.target.value);
	};

	return (
		<StyledModal
			width="500px"
			title="Please select reason for deleting Cheque Bounce Entry"
			visible={modalVisible}
			onCancel={() => setModalVisible(false)}
			maskClosable={false}
			footer={null}
		>
			<Flex style={{ width: '100%' }}>
				<Radio.Group onChange={onChange} value={value} style={{ width: '100%' }}>
					<Space direction="vertical" style={{ width: '100%' }}>
						{chequeBounceDeleteReasons?.length > 0 &&
							chequeBounceDeleteReasons.map((option) => (
								<Radio
									key={option.reason}
									value={option.reason}
									className={
										value && value === option.reason
											? 'customStyle highlighted'
											: 'customStyle'
									}
								>
									{option.info}
								</Radio>
							))}
					</Space>
				</Radio.Group>
			</Flex>
			<Button
				width="100%"
				borderRadius="6px"
				marginTop="40px"
				marginLeft="0px"
				onClick={markBouncedChequeVoid}
				loading={loading}
				disabled={!value}
			>
				Submit
			</Button>
		</StyledModal>
	);
}

export default ChequeBounceDeleteModal;

ChequeBounceDeleteModal.propTypes = {
	modalVisible: PropTypes.bool,
	chequeBounceRowData: PropTypes.object,
	setModalVisible: PropTypes.func,
	setListUpdated: PropTypes.func,
};
