/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
import * as Yup from 'yup';
import { uniqWith } from 'lodash';
import moment from 'moment';
import { DateFormat, Rupee } from 'components';
import { ValueTextIn, ValueTextInvdetails } from './style';

// #region Invoice Details

export const InvoiceDetails = (invoice, order) => [
	{
		label: 'Retailer Name:',
		value: <ValueTextInvdetails>{invoice?.store_name || '-'}</ValueTextInvdetails>,
	},
	{
		label: 'Invoice Amount:',
		value: (
			<ValueTextInvdetails>
				<Rupee roundOff={0}>{invoice?.invoice_amount || 1}</Rupee>
			</ValueTextInvdetails>
		),
	},
	{
		label: 'Order Number:',
		value: <ValueTextInvdetails>{order?.order_no || '-'}</ValueTextInvdetails>,
	},
	{
		label: `${
			order?.status === 'PD' || order?.status === 'Dl'
				? 'Delivered Amount:'
				: 'Deliverable Amount'
		}`,
		value: (
			<ValueTextInvdetails>
				<Rupee roundOff={0}>{parseFloat(invoice?.initial_outstanding_amount || 0)}</Rupee>
			</ValueTextInvdetails>
		),
	},
	{
		label: 'Invoice Number:',
		value: <ValueTextInvdetails>{invoice?.invoice_no || '-'}</ValueTextInvdetails>,
	},
	{
		label: 'Collected Value:',
		value: (
			<ValueTextInvdetails>
				<Rupee roundOff={0}>{invoice?.collected_amount || 0}</Rupee>
			</ValueTextInvdetails>
		),
	},
	{
		label: 'Invoice Date:',
		value: (
			<ValueTextInvdetails>
				<DateFormat date={invoice?.invoice_date} />
			</ValueTextInvdetails>
		),
	},
	{
		label: 'Outstanding Value:',
		value: (
			<ValueTextInvdetails>
				<Rupee roundOff={0}>{invoice?.current_outstanding_amount || 0}</Rupee>
			</ValueTextInvdetails>
		),
	},
];

// #endregion

// #region form related configs

export const RTCReturnfilter = (val) => ({
	is_attempt: val === 'DA' || undefined,
	is_cancel: val === 'CA' || undefined,
	is_partial: val === 'PD' || undefined,
	is_return: false,
});

export const RTCInitialValues = {
	billtype: undefined,
	remarks: undefined,
	status: undefined,
	short_settlement_reason: undefined,
	cash: undefined,
	cheque: [
		{
			cheque_amount: undefined,
			bank_id: undefined,
			cheque_due_date: undefined,
			ref_no: undefined,
		},
	],
	upi: [{ amount: undefined, ref_no: undefined }],
	neft: [{ amount: undefined, ref_no: undefined }],
};

export const RTCSetInitialValues = (invoice, order, orderDetail, whDue, status) => {
	console.log('POPO', orderDetail);
	// refine values for Collections
	const payments = [];

	console.log('invv', whDue);

	invoice?.payment?.forEach((x) => {
		const fetchs = invoice?.payment?.filter((y) => y.payment_type === x.payment_type);
		if (fetchs.length !== payments[x.payment_type?.toLowerCase()]?.length) {
			payments[x.payment_type] = [...fetchs];
		}
	});

	const refinedCollections = {
		cash: payments?.Cash?.[0]?.amount || undefined,
		cheque: payments?.Cheque?.map((x) => ({
			id: x?.id || undefined,
			bank_id: x?.bank_id || undefined,
			amount: x?.amount || undefined,
			cheque_due_date: x?.due_date || undefined,
			ref_no: x?.reference_number || undefined,
		})) || [
			{
				bank_id: undefined,
				amount: undefined,
				cheque_due_date: undefined,
				ref_no: undefined,
			},
		],
		upi: payments?.UPI?.map((x) => ({
			id: x?.id || undefined,
			amount: x?.amount || undefined,
			ref_no: x?.reference_number || undefined,
		})) || [{ amount: undefined, ref_no: undefined }],
		neft: payments?.NEFT?.map((x) => ({
			id: x?.id || undefined,
			amount: x?.amount || undefined,
			ref_no: x?.reference_number || undefined,
		})) || [{ amount: undefined, ref_no: undefined }],
	};

	const currentStatus = status;

	console.log('currentStatus', status);

	const getOrders = (currentStatuss) => {
		let ordersVar = orderDetail?.map((x) => ({
			id: x?.id || undefined,
			product_name: x?.product_name || undefined,
			product_code: x?.product_code || undefined,
			product_MRP: x?.product_MRP || 0,
			ordered_qty: x?.qty || 0,
			return_qty: x?.returned_qty || x?.return_qty || 0,
			good_qty:
				x?.good_qty || (x?.returned_qty || x?.return_qty || 0) - (x?.bad_qty || 0) || 0,
			bad_qty: x?.bad_qty || 0,
			missing_qty: x?.missing_qty || 0,
			free_qty: x?.free_qty || 0,
			warehouseDue: x?.warehouseDue || 0,
			picked_qty: x?.pickedQty || 0,
			delivered_qty: x?.delivered_qty || 0,
			order_id: x?.order_id || undefined,
			return_reason: x?.returned_reason || undefined,
		}));

		switch (currentStatuss) {
			case 'DL':
			case 'PD':
				ordersVar = orderDetail?.map((x) => ({
					id: x?.id || undefined,
					product_name: x?.product_name || undefined,
					product_code: x?.product_code || undefined,
					product_MRP: x?.product_MRP || 0,
					ordered_qty: x?.qty || 0,
					return_qty: 0,
					good_qty: 0,
					bad_qty: 0,
					missing_qty: 0,
					free_qty: x?.free_qty || 0,
					warehouseDue: x?.warehouseDue || 0,
					picked_qty: x?.pickedQty || 0,
					delivered_qty: x?.pickedQty || 0,
					return_reason: undefined,
					order_id: x?.order_id || undefined,
				}));
				break;

			case 'CA':
			case 'DA':
				ordersVar = orderDetail?.map((x) => ({
					id: x?.id || undefined,
					product_name: x?.product_name || undefined,
					product_code: x?.product_code || undefined,
					product_MRP: x?.product_MRP || 0,
					ordered_qty: x?.qty || 0,
					return_qty: x?.pickedQty || 0,
					good_qty: x?.pickedQty || 0,
					// good_qty: x?.pickedQty || 0,
					bad_qty: 0,
					missing_qty: 0,
					free_qty: x?.free_qty || 0,
					warehouseDue: x?.warehouseDue || 0,
					picked_qty: x?.pickedQty || 0,
					delivered_qty: 0,
					return_reason: undefined,
					order_id: x?.order_id || undefined,
				}));
				break;

			default:
				ordersVar = orderDetail?.map((x) => ({
					id: x?.id || undefined,
					product_name: x?.product_name || undefined,
					product_code: x?.product_code || undefined,
					product_MRP: x?.product_MRP || 0,
					ordered_qty: x?.qty || 0,
					return_qty: x?.returned_qty || x?.return_qty || 0,
					good_qty:
						x?.good_qty ||
						(x?.returned_qty || x?.return_qty || 0) - (x?.bad_qty || 0) ||
						0,
					bad_qty: x?.bad_qty || 0,
					missing_qty: x?.missing_qty || 0,
					free_qty: x?.free_qty || 0,
					warehouseDue: x?.warehouseDue || 0,
					picked_qty: x?.pickedQty || 0,
					delivered_qty: x?.delivered_qty || 0,
					order_id: x?.order_id || undefined,
					return_reason: x?.returned_reason || undefined,
				}));
				break;
		}
		return ordersVar;
	};
	// for order details
	console.log('orderDetail', orderDetail);

	const orders = getOrders(currentStatus);
	// const orders = orderDetail?.map((x) => {
	// 	console.log('MAPPP', x);
	// 	return {
	// 		id: x?.id || undefined,
	// 		product_name: x?.product_name || undefined,
	// 		product_MRP: x?.product_MRP || 0,
	// 		ordered_qty: x?.qty || 0,
	// 		return_qty: x?.returned_qty || 0,
	// 		good_qty: (x?.returned_qty || 0) - (x?.bad_qty || 0) - (x?.missing_qty || 0) || 0,
	// 		bad_qty: x?.bad_qty || 0,
	// 		missing_qty: x?.missing_qty || 0,
	// 		free_qty: x?.free_qty || 0,
	// 		warehouseDue: x?.warehouseDue || 0,
	// 		picked_qty: x?.pickedQty || 0,
	// 		return_reason: x?.returned_reason,
	// 		delivered_qty: (x?.pickedQty || 0) - (x?.return_qty || 0) || 0,
	// 		delivered_returned_qty: x?.return_qty || (x?.qty || 0) - (x?.delivered_qty || 0) || 0,
	// 		delivered_returned_reason: x?.return_reason || undefined,
	// 		actual_returned_qty: x?.actual_return_qty || x?.return_qty || 0,
	// 		actual_returned_reason: x?.return_mismatch_reason || undefined,
	// 		order_id: x?.order_id || undefined,
	// 	};
	// });

	console.log('pleaseinvoice', invoice);

	const getInvoiceStatusFunc = (() => {
		if (invoice?.status === 'Bill Back' && invoice?.billtype === 'Bill') {
			return 'Bill Back';
		}
		if (invoice?.status === 'Bill Back' && invoice?.billtype === 'Voucher') {
			return 'Voucher';
		}
		if (invoice?.status === 'No Bill Back' && invoice?.billtype === 'Bill') {
			return 'No Bill Back';
		}
		if (invoice?.status === 'Void Bill' && invoice?.billtype === 'Bill') {
			return 'Void Bill';
		}
		return 'Pending';
	})();

	return {
		warehouseDueFlag: whDue || false,
		billtype: ['Bill', 'Voucher'].includes(invoice?.billtype) ? invoice?.billtype : undefined,
		remarks: invoice?.remarks || undefined,
		short_settlement_reason: invoice?.reason || undefined,
		invoice_date: moment(invoice?.invoice_date).isValid()
			? moment(invoice?.invoice_date).format()
			: undefined,
		reason: order?.unfulfilled_reason || undefined,
		invoice_amount: parseFloat(invoice?.invoice_amount || 0) || undefined,
		return_verified_by: order?.return_verified_by || undefined,
		delivered_amount: parseFloat(invoice?.initial_outstanding_amount || 0) || 0,
		collected_amount:
			parseFloat(invoice?.initial_outstanding_amount || 0) -
				parseFloat(invoice?.current_outstanding_amount || 0) || 0,
		collection_date: invoice?.collection_date || undefined,
		invoice_status: ['Bill Back', 'No Bill Back', 'Voucher'].includes(invoice?.status)
			? getInvoiceStatusFunc
			: undefined,
		order_status: order?.status || undefined,
		orderDetails: orders,
		searchOrderDetails: orders,
		cashLimit: invoice?.cashLimit,
		...refinedCollections,
	};
};

export const RTCUpdateOrderDetails = (status, initialValues, caReason = null) => {
	console.log('caReason', caReason);
	console.log('initialValues', initialValues);
	const initialOrderDetails = RTCSetInitialValues(
		undefined,
		initialValues?.order,
		initialValues?.orderDetail,
		initialValues?.warehouseDueFlag,
		status
	).orderDetails;

	switch (status) {
		case 'DL':
		case 'PD':
			return initialOrderDetails?.map((x) => ({
				id: x?.id || undefined,
				product_name: x?.product_name || undefined,
				product_code: x?.product_code || undefined,
				product_MRP: x?.product_MRP || 0,
				ordered_qty: x?.ordered_qty,
				delivered_qty: x?.picked_qty || 0,
				delivered_returned_qty: 0,
				return_qty: 0,
				good_qty: 0,
				bad_qty: 0,
				missing_qty: 0,
				free_qty: x?.free_qty || 0,
				warehouseDue: x?.warehouseDue || 0,
				picked_qty: x?.picked_qty || 0,
				// delivered_returned_reason: undefined,
				// actual_returned_qty: 0,
				// actual_returned_reason: undefined,
				order_id: x?.order_id,
			}));
		case 'CA':
		case 'DA':
			return initialOrderDetails?.map((x) => ({
				id: x?.id || undefined,
				product_name: x?.product_name || undefined,
				product_code: x?.product_code || undefined,
				product_MRP: x?.product_MRP || 0,
				ordered_qty: x?.ordered_qty || 0,
				delivered_qty: 0,
				delivered_returned_qty: x?.ordered_qty || 0,
				return_qty: x?.return_qty || 0,
				good_qty: (x?.return_qty || 0) - (x?.bad_qty || 0) || 0,
				bad_qty: 0,
				missing_qty: 0,
				free_qty: x?.free_qty || 0,
				warehouseDue: x?.warehouseDue || 0,
				picked_qty: x?.picked_qty || 0,
				// delivered_returned_reason: caReason || undefined,
				// actual_returned_qty: x?.ordered_qty || 0,
				// actual_returned_reason: undefined,
				order_id: x?.order_id || undefined,
			}));

		default:
			return initialOrderDetails;
	}
};

Yup.addMethod(Yup.array, 'uniqueCheque', function testUnique(message) {
	return this.test('unique', message, function uniques(list) {
		// https://stackoverflow.com/a/52525557/3882241
		const uniqueCheques = uniqWith(
			list,
			(chequeA, chequeAB) =>
				chequeA.bank_id === chequeAB.bank_id &&
				chequeA.ref_no === chequeAB.ref_no &&
				chequeA.bank_id &&
				chequeA.ref_no
		);

		return list.length === uniqueCheques.length;
	});
});

Yup.addMethod(Yup.array, 'uniqueRefNumber', function testUnique(message) {
	return this.test('unique', message, function uniques(list) {
		// https://stackoverflow.com/a/52525557/3882241
		const uniqueCheques = uniqWith(list, (a, b) => a.ref_no === b.ref_no);

		return list.length === uniqueCheques.length;
	});
});

export const RTCFormSchema = (type, invalidData) => {
	console.log(invalidData);
	return Yup.object({
		billtype: Yup.string().nullable(true),
		remarks: Yup.string().trim(),
		invoice_amount: Yup.number().nullable(),
		collected_amount: Yup.number().nullable(),
		delivered_amount: Yup.number().nullable(),
		invoice_date: Yup.string().nullable(),
		collection_date: Yup.string().nullable(),
		invoice_status:
			type === 'collection'
				? Yup.string()
						// .when(['collected_amount', 'delivered_amount'], {
						// 	is: (ca, da) => da === ca,
						// 	then: Yup.string()
						// 		.matches(
						// 			/^(?!Bill Back|Voucher\b)/i,
						// 			'Please select No Bill back as collected and delivered amount are equal'
						// 		)
						// 		.required(),
						// 	otherwise: Yup.string(),
						// })
						.label('Status')
						.required()
				: Yup.string().nullable(),
		short_settlement_reason: Yup.number().label('Short Settlement Reason'),
		cash: Yup.number().label('Cash').positive().min(0).max(199999),
		cheque: Yup.array()
			.of(
				Yup.object().shape({
					cheque_amount: Yup.number().label('Cheque'),
					ref_no: Yup.string().when('amount', {
						is: (amount) => amount > 0,
						then: Yup.string()
							.label('*Cheque Ref no')
							.matches(
								/^(?=\d{6}$)\d*[1-9]\d*/,
								'Please enter valid last 6 digits of Cheque Ref no'
							)
							.required('*Cheque Ref no last 6 digits: is required'),
						otherwise: Yup.string(),
					}),
					bank_id: Yup.number().when('amount', {
						is: (cheque) => cheque > 0,
						then: Yup.number().label('*Bank Name').required('*Bank Name is required'),
						otherwise: Yup.number(),
					}),
					cheque_due_date: Yup.string().when('amount', {
						is: (cheque) => cheque > 0,
						then: Yup.string('*Due Date')
							.label('*Due Date')
							.required('*Due Date is required')
							.nullable(),
						otherwise: Yup.string('Due Date'),
					}),
				})
			)
			.uniqueCheque(
				'Cheque reference number and bank name must not be same, please change that'
			),
		upi: Yup.array()
			.of(
				Yup.object().shape({
					amount: Yup.number().label('UPI'),
					ref_no: Yup.string().when('amount', {
						is: (amount) => amount > 0,
						then: Yup.string()
							.label('*UPI Ref no')
							.matches(
								/^(?!0+$)[0-9]{12,16}$/,
								'Please enter valid last 12-16 digits of UPI Ref no (only numerics allowed)'
							)
							.required('*UPI Ref no last 12-16 digits: is required')
							.min(12)
							.max(16)
							// eslint-disable-next-line
							.test(function (val) {
								console.log(invalidData, 'invalid data here');
								if (invalidData.upis.find((v) => v === val)) {
									return this.createError({
										message: `${val} is not a valid upi reference number`,
									});
								}

								return true;
							}),
						otherwise: Yup.string(),
					}),
				})
			)
			.uniqueRefNumber('Reference number must not be same, please change that'),

		neft: Yup.array()
			.of(
				Yup.object().shape({
					amount: Yup.number().label('NEFT'),
					ref_no: Yup.string().when('amount', {
						is: (amount) => amount > 0,
						then: Yup.string()
							.label('*NEFT Ref no')
							.matches(
								/^(?!0+$)[a-zA-Z0-9]{6,22}$/,
								'Please enter valid 6 - 22 digits of NEFT Ref no'
							)
							.required('*NEFT Ref no 6 - 22 digits is required')
							.min(6)
							.max(22)
							// eslint-disable-next-line
							.test(function (val) {
								if (invalidData.neft.find((v) => v === val)) {
									return this.createError({
										message: `${val} is not a valid NEFT reference number`,
									});
								}

								return true;
							}),
						otherwise: Yup.string(),
					}),
				})
			)
			.uniqueRefNumber('Reference number must not be same, please change that'),
		order_status: type === 'delivery' ? Yup.string().required() : Yup.string().nullable(),
		reason: Yup.string().when('order_status', {
			is: (val) => ['CA', 'DA'].includes(val),
			then: Yup.string().required('Reason is required'),
			otherwise: Yup.string(),
		}),
		orderDetails: Yup.array().of(
			Yup.object().shape({
				product_name: Yup.string(),
				ordered_qty: Yup.number(),
				delivered_qty: Yup.number()
					.when('order_status', {
						is: (val) => val === 'PD',
						then: Yup.number().label('Delivered Qty').required(),
						otherwise: Yup.number(),
					})
					.test('Is positive?', 'Please Enter valid number', (value) => value >= 0),
				// delivered_returned_qty: Yup.number().test(
				// 	'Is positive?',
				// 	'Please Enter valid number',
				// 	(value) => value >= 0
				// ),
				// delivered_returned_reason: Yup.number().when(['delivered_returned_qty'], {
				// 	// is: (val, order_status) => val > 0 && !['CA', 'DA'].includes(order_status),
				// 	is: (val) => val > 0,
				// 	then: Yup.number().label('Reason').required(),
				// 	otherwise: Yup.number(),
				// }),
				// actual_returned_qty: Yup.number().test(
				// 	'Is positive?',
				// 	'Please Enter valid number',
				// 	(value) => value >= 0
				// ),
				// actual_returned_reason: Yup.number().when(
				// 	['actual_returned_qty', 'delivered_returned_qty'],
				// 	{
				// 		is: (val, val1) => val !== val1,
				// 		then: Yup.number().label('Reason').required(),
				// 		otherwise: Yup.number(),
				// 	}
				// ),
			})
		),
	});
};
export const RTCRefinedPayload = (data, type, tableData) => {
	console.log('tableData', tableData);
	console.log('oldData', data);
	const refinedPayload = {
		status: data?.invoice_status,
		order_status: data?.order_status,
		latitude: null,
		longitude: null,
		accuracy: null,
		billtype: data?.billtype,
		short_settlement_reason: data?.short_settlement_reason,
		remarks: data?.remarks ? data?.remarks?.trim() : data?.remarks,
		reason: data?.reason,
		is_return_to_fc: true,
	};

	if (type === 'delivery') {
		console.log('delValll', tableData);
		if (data?.order_status === 'DL') {
			// for order details
			const orderPayload = tableData?.map((x) => ({
				delivered_qty: x?.picked_qty || 0,
				order_detail_id: x?.id || undefined,
				// return_qty: x?.delivered_returned_qty || 0,
				return_reason: 0,
				return_qty: 0,
				good_qty: 0,
				bad_qty: 0,
				missing_qty: 0,
				free_qty: x?.free_qty || 0,
				// warehouseDue: x?.warehouseDue || 0,
				picked_qty: x?.picked_qty || 0,
				// actual_return_qty: x?.actual_returned_qty || 0,
				// return_mismatch_reason: x?.actual_returned_reason || 0,
			}));

			return {
				...refinedPayload,
				details: [...orderPayload],
			};
		}
		if (data?.order_status === 'PD') {
			// for order details
			const orderPayload = tableData?.map((x) => ({
				delivered_qty: x?.delivered_qty || 0,
				order_detail_id: x?.id || undefined,
				// return_qty: x?.delivered_returned_qty || 0,
				return_reason: x?.return_reason || 0,
				return_qty: x?.return_qty || 0,
				good_qty: x?.good_qty || 0,
				bad_qty: x?.bad_qty || 0,
				missing_qty: x?.missing_qty || 0,
				free_qty: x?.free_qty || 0,
				// warehouseDue: x?.warehouseDue || 0,
				picked_qty: x?.picked_qty || 0,
				// actual_return_qty: x?.actual_returned_qty || 0,
				// return_mismatch_reason: x?.actual_returned_reason || 0,
			}));

			return {
				...refinedPayload,
				details: [...orderPayload],
			};
		}
		if (data?.order_status === 'CA') {
			// for order details
			const orderPayload = tableData?.map((x) => ({
				delivered_qty: x?.delivered_qty || 0,
				order_detail_id: x?.id || undefined,
				// return_qty: x?.delivered_returned_qty || 0,
				return_reason: data?.reason || 0,
				return_qty: x?.return_qty || 0,
				good_qty: (x?.return_qty || 0) - (x?.bad_qty || 0) || 0,
				bad_qty: x?.bad_qty || 0,
				missing_qty: x?.missing_qty || 0,
				free_qty: x?.free_qty || 0,
				// warehouseDue: x?.warehouseDue || 0,
				picked_qty: x?.picked_qty || 0,
				// actual_return_qty: x?.actual_returned_qty || 0,
				// return_mismatch_reason: x?.actual_returned_reason || 0,
			}));

			return {
				...refinedPayload,
				details: [...orderPayload],
			};
		}
		if (data?.order_status === 'DA') {
			// for order details
			const orderPayload = tableData?.map((x) => ({
				delivered_qty: 0,
				order_detail_id: x?.id || undefined,
				// return_qty: x?.delivered_returned_qty || 0,
				return_reason: data?.reason,
				return_qty: x?.picked_qty || 0,
				good_qty: x?.picked_qty,
				bad_qty: 0,
				missing_qty: 0,
				free_qty: x?.free_qty || 0,
				// warehouseDue: x?.warehouseDue || 0,
				picked_qty: x?.picked_qty || 0,
				// actual_return_qty: x?.actual_returned_qty || 0,
				// return_mismatch_reason: x?.actual_returned_reason || 0,
			}));

			return {
				...refinedPayload,
				details: [...orderPayload],
			};
		}
	}

	if (type === 'collection') {
		// for invoice payload
		const invoiceCash =
			data?.cash > 0
				? [
						{
							payment_type: 'Cash',
							reference_number: '',
							due_date: null,
							amount: +(data?.cash || 0),
							bank_id: null,
							bank_statement_id: null,
						},
				  ]
				: [];

		const invoiceCheque = data?.cheque?.map((x) =>
			x?.amount > 0
				? {
						payment_type: 'Cheque',
						reference_number: x?.ref_no,
						due_date: moment(x?.cheque_due_date).format('YYYY-MM-DD'),
						amount: +(x?.amount || 0),
						bank_id: x?.bank_id,
						bank_statement_id: null,
				  }
				: false
		);

		const invoiceNeft = data?.neft?.map((x) =>
			x?.amount > 0
				? {
						payment_type: 'NEFT',
						reference_number: x?.ref_no,
						due_date: null,
						amount: +(x?.amount || 0),
						bank_id: null,
						bank_statement_id: null,
				  }
				: false
		);

		const invoiceUpi = data?.upi?.map((x) =>
			x?.amount > 0
				? {
						payment_type: 'UPI',
						reference_number: x?.ref_no,
						due_date: null,
						amount: +(x?.amount || 0),
						bank_id: null,
						bank_statement_id: null,
				  }
				: false
		);
		return {
			...refinedPayload,
			payment: [...invoiceCash, ...invoiceCheque, ...invoiceNeft, ...invoiceUpi].filter(
				(x) => x
			),
		};
	}

	return refinedPayload;
};

// #endregion

// #region Invoice list pages

const TakeTotal = (data, params, type) =>
	data?.Orders?.filter(
		(x) => x?.CollectionInvoice.status === params && x?.CollectionInvoice.bill_type === type
	).length || 0;

export const DetialsConfig = (data) => [
	{
		label: 'Total Collection',
		value: (
			<ValueTextIn>
				<Rupee>{data?.total_collection || 0}</Rupee>{' '}
			</ValueTextIn>
		),
	},
	{ label: 'Total Invoice', value: <ValueTextIn>{data?.total_orders || 0}</ValueTextIn> },
	{
		label: 'Invoice Returned',
		value: <ValueTextIn>{TakeTotal(data, 'Bill Back', 'Bill')}</ValueTextIn>,
	},
	{
		label: 'Voucher',
		value: <ValueTextIn>{TakeTotal(data, 'Bill Back', 'Voucher')}</ValueTextIn>,
	},
	{
		label: 'Invoice settled',
		value: <ValueTextIn>{TakeTotal(data, 'No Bill Back', 'Bill')}</ValueTextIn>,
	},
	{ label: 'Complete delivery', value: <ValueTextIn>{data?.total_delivered || 0}</ValueTextIn> },
	{
		label: 'Partial Delivery',
		value: <ValueTextIn>{data?.total_partial_delivered || 0}</ValueTextIn>,
	},
	{ label: 'Delivery Attempt', value: <ValueTextIn>{data?.total_attempted || 0}</ValueTextIn> },
	{ label: 'Delivery Cancelled', value: <ValueTextIn>{data?.total_cancelled || 0}</ValueTextIn> },
];

// #endregion
