import React from 'react';
import styled from 'styled-components';
import HomeBg from 'assets/home-bg.svg';
import { Box, Image, Flex, Text } from 'atoms';
import { ResetPasswordForm } from 'components/forms';

export const Container = styled(Flex)`
	overflow: hidden;
`;

export const ImgContainer = styled(Box)`
	width: 65%;
	height: 100vh;
`;

export const FormContainer = styled(Flex)`
	width: 30%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const Title = styled(Text)`
	font-size: 42px;
	line-height: 75px;
	font-family: Work Sans;
	color: ${(props) => props.theme.colors.MidnightGreen};
`;

export const Img = styled(Image)`
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export default function ResetPassword() {
	return (
		<Container>
			<ImgContainer>
				<Img src={HomeBg} alt="Home Bg" />
			</ImgContainer>
			<FormContainer>
				<Title>Reset Password</Title>
				<ResetPasswordForm />
			</FormContainer>
		</Container>
	);
}
