import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Skeleton } from 'antd';
import { SalesManStats } from 'components/pages/segregator';
import { Flex } from 'atoms';
import { getCashierHeader } from 'store/invoiceSlice';
import { useURL } from 'hooks';

export default function CashierSalesManStatsHeader({ setHeaderData }) {
	const [statsData, setStatsData] = useState({});
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const urlData = useURL();

	useEffect(() => {
		setLoading(true);
		dispatch(getCashierHeader(urlData))
			.then((res) => {
				console.log(res.data_summary);
				setStatsData(res?.data_summary);
				if (setHeaderData) {
					setHeaderData(res);
				}
			})
			.finally(() => {
				setTimeout(() => {
					setLoading(false);
				}, 500);
			});
	}, [dispatch, setHeaderData, urlData]);

	return (
		<Flex justifyContent="space-between" alignItems="center">
			{loading ? (
				<Skeleton style={{ marginBottom: '1.5rem' }} />
			) : (
				<SalesManStats data={statsData} />
			)}
		</Flex>
	);
}

CashierSalesManStatsHeader.propTypes = {
	setHeaderData: PropTypes.func,
};
