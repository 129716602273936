/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Select } from 'formik-antd';
import styled from 'styled-components';
import { Box, Flex } from 'atoms';

export const ErrorMessage = styled(Box)`
	line-height: 11px;
	font-size: 11px;
	color: #ff4d4f;
	margin-top: 4px;
`;

export const SelectBox = styled(Flex)`
	font-family: 'Poppins', sans-serif;
	display: flex;
	flex-direction: column;
	/* Input top label */
	.ant-form-item-label {
		top: 15px;
		left: 10px;
		z-index: 10;
		padding: 0;
		label {
			padding: 5px 10px 0 10px;
			height: 12px;
			font-size: 12px;
			color: ${(props) => props.theme.colors.primary};
			background-color: ${(props) => props.theme.colors.white};
			top: -5px;
		}
	}

	.ant-input-affix-wrapper {
		border: 1px solid ${(props) => props.theme.colors.primary};
	}

	/* input Field */
	input {
		z-index: 5;
		height: 45px;
		border: 1px solid ${(props) => props.theme.colors.primary};
		font-size: 15px;
		font-weight: 300;
		line-height: 22px;
		color: ${(props) => props.theme.colors.black};
	}

	/* Password Field */
	.ant-input-password {
		padding: 0px 11px;
	}

	/* Select Field */
	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border: 1px solid ${(props) => props.theme.colors.primary};
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		padding-top: 7px;
	}

	.ant-select-selection-overflow-item > span {
		display: flex;
	}

	.ant-select-selection-item {
		font-size: 15px;
		font-weight: 300;
		color: ${(props) => props.theme.colors.black};
	}
`;

export default function FormSelect({ label = '', errors = null, ...rest }) {
	return (
		<SelectBox>
			{label && (
				<Box className="ant-form-item-label">
					<Box as="label">{label}</Box>
				</Box>
			)}
			<Select status={errors ? 'error' : false} {...rest} />
			{errors && <ErrorMessage>{errors}</ErrorMessage>}
		</SelectBox>
	);
}
