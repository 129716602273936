/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Popover, Select, DatePicker, Input, Switch } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import * as SVGICONS from 'utils/Svg';
import {
	getSalesOrderList,
	getSaleStoreList,
	getStockOneCallBack,
} from 'store/fulfilment/salesOrderSlice';
import { Box, Flex, Button, AsyncSelect } from 'atoms';
import {
	RCTable,
	SearchBar,
	LoadAsyncSelectComponent,
	WMSMessage,
	ACL,
	LabelWrapper,
} from 'components/common';
import { salesOrderListColumns, handleFilterChange, handleSearch } from 'utils/tableConfig';
import {
	toAsysnOption,
	ORDER_SEARCH_FIELDS,
	disableFutureDate,
	disableDateRangesNew,
	ORDER_STATUSES,
	SalesOrderQueries,
	SelectFilterLabelOptions,
	searchBox,
	initialPaginateConfigWithoutQ,
	UserPersmission,
} from 'utils';
import { getFcListOnLoad, getFcOnSearchList } from 'store/masters';
// import { getExportSalesOrderReport } from 'store/downloads/reportSlice';
import { SearchField } from 'components/common/custom-table/Style';
import { useQueryParams } from 'hooks';
import { getStoreDataOnLoad } from 'store/OnBoardSlice';

const CheckboxGroup = Checkbox.Group;

const StatusLabelWrapper = styled(LabelWrapper)`
	text-align: center;
	margin-right: 20px;
`;

const QuidText = styled.div`
	color: #352a35;
	font-size: 10px;
	margin-bottom: 11px;
`;

export const FilterByOptions = [
	{ value: 'fields', label: 'Search By' },
	{ value: 'statuses', label: 'Status(s)' },
	// { value: 'invoice_date__gte', label: 'From Date' },
	// { value: 'invoice_date__lte', label: 'To Date' },
	{ value: 'gst_number', label: 'GST' },
];

const rolesAllowed = ['Billing Executive', 'System Admin', 'Transport Manager'];

export default function SalesOrder() {
	const { auth: userDefinitions } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [storeList, setStoreList] = useState({ type: 'NotAsked' });
	const [rowData, setRowData] = useState({});
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const { auth } = useSelector((state) => state?.auth);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [wmsSuccessMessage, setWmsSuccessMessage] = useState(null);

	const [paginateConfig, setpaginateConfig] = useQueryParams({ filterQuery: SalesOrderQueries });
	const [filtersEnabled, setSelectFilter] = useState([]);
	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);

	const handleClick = (id, bnpl_enabled) =>
		navigate(`/order-management/sales-order/${id}?quid-enabled=${bnpl_enabled}`);
	// const handleTaggingClick = () => navigate(`/order-management/sales-order/mark-eco-bills`);

	const notAllowedSoTagging = !rolesAllowed?.includes(auth?.user.groups[0].name);

	useEffect(() => {
		setStoreList({ type: 'Loading' });
		setFcList({ type: 'Loading' });

		dispatch(
			getFcListOnLoad({ fcIds: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [] })
		).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});

		dispatch(
			getStoreDataOnLoad({
				fc_ids: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [],
				storeIds: paginateConfig?.stores?.length ? paginateConfig?.stores : [],
			})
		).then((res) => setStoreList({ type: 'Success', data: toAsysnOption(res) }));
	}, [dispatch]);

	useEffect(() => {
		// Load the options onLoad
		setSelectFilter((prev) => [
			...prev,
			...FilterByOptions.filter((x) => !isEmpty(paginateConfig[x.value])).map((x) => x.value),
		]);
	}, [paginateConfig]);

	// const handleExport = () => {
	// 	const msgKey = Math.random().toString();
	// 	const config = {
	// 		key: msgKey,
	// 		type: 'loading',
	// 		content: 'Downloading Reports Sales Order',
	// 		duration: 0,
	// 		className: 'customDownloadMsg',
	// 	};
	// 	message.open(config);

	// 	const { offset, limit, sort, currentPage, ...rest } = paginateConfig;
	// 	dispatch(
	// 		getExportSalesOrderReport({
	// 			filter: { ...rest },
	// 		})
	// 	)
	// 		.then(() => message.destroy(msgKey))
	// 		.catch(() => message.destroy(msgKey));
	// };

	const handleChangeSelect = (name, value) => {
		// as this is asyncselect we need to destructure value from the option value
		const getValue = isEmpty(value) ? [] : value.map((y) => y.value);
		setpaginateConfig((prev) => handleFilterChange(name, getValue, prev));
	};

	const handleFCChangeSelect = (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value
		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		const fcValues = value?.value ? [value?.value] : [];
		dispatch(getSaleStoreList({ q: '**', fc_ids: fcValues })).then((res) => {
			setStoreList({ type: 'Success', data: toAsysnOption(res) });
			setpaginateConfig((prev) => ({
				...handleFilterChange(name, fcValues, prev),
				brands: [],
				stores: [],
			}));
		});
	};

	const handleFilterByOptions = (val) => {
		const checkFilterRemoved = filtersEnabled.filter((x) => !val.includes(x));
		// check any value is removed and removed value has any value or not
		if (checkFilterRemoved.length > 0 && !isEmpty(paginateConfig[checkFilterRemoved[0]])) {
			const { [checkFilterRemoved[0]]: RemoveEmpty, ...rest } = paginateConfig;
			setpaginateConfig({
				...rest,
				...(checkFilterRemoved[0] === 'fields' ? { query: '' } : {}), // if fields is removed then query has to be empty
			});
		}
		setSelectFilter(val);
	};

	function handleStockOneCallBack(record) {
		dispatch(getStockOneCallBack(record)).then((res) => {
			setWmsSuccessMessage([res]);
			setIsModalOpen(true);
		});
	}

	function hideModal() {
		setIsModalOpen(false);
		window.location.reload();
	}

	const handleChangeSwitch = (name, value, option) => {
		console.log(name, value, option);
		setpaginateConfig((prev) => ({
			...handleFilterChange(name, value, prev),
		}));
	};

	return (
		<>
			<Flex alignItems="center">
				<Flex width="100%" flexWrap="wrap" alignItems="center" gap="4px">
					{filtersEnabled.includes('fields') && (
						<LabelWrapper label="Search By" marginspace="3px 0 -1px 1rem">
							<Select
								name="fields"
								style={searchBox}
								allowClear
								showSearch
								label="Search By"
								mode="multiple"
								value={paginateConfig?.fields}
								options={ORDER_SEARCH_FIELDS}
								filterOption={SelectFilterLabelOptions}
								placeholder="Search By"
								onChange={(value) =>
									setpaginateConfig((prev) => ({
										...handleFilterChange('fields', value, prev),
										...(isEmpty(value) ? { query: '' } : {}),
									}))
								}
							/>
						</LabelWrapper>
					)}

					{filtersEnabled.includes('fields') && !isEmpty(paginateConfig?.fields) && (
						<LabelWrapper label="Search" marginspace="-1px 0 5px 0">
							<SearchField>
								<SearchBar
									placeholder="Search"
									queryValue={paginateConfig?.query?.replaceAll('*', '')}
									onSearch={(val) =>
										setpaginateConfig((prev) => handleSearch(val, prev))
									}
								/>
							</SearchField>
						</LabelWrapper>
					)}
					<Box marginLeft="1rem">
						<LabelWrapper label="Search by Invoice Number" marginspace="-1px 0 5px 0">
							<SearchField>
								<SearchBar
									placeholder="Search by Invoice Number"
									queryValue={paginateConfig?.queryInvoice?.replaceAll('*', '')}
									onSearch={(val) =>
										setpaginateConfig((prev) => ({
											...prev,
											limit: 10,
											offset: 0,
											currentPage: 1,
											queryInvoice: val,
										}))
									}
								/>
							</SearchField>
						</LabelWrapper>
					</Box>

					<LabelWrapper label="Store(s)" marginspace="3px 0 -1px 1rem">
						<LoadAsyncSelectComponent options={storeList}>
							<AsyncSelect
								style={searchBox}
								allowClear
								name="stores"
								mode="multiple"
								fetchOptions={getSaleStoreList}
								apiParams={{ fc_ids: paginateConfig?.fcs || [] }}
								placeholder="Store(s)"
								defaultValue={paginateConfig?.stores}
								value={paginateConfig?.stores}
								onChange={(value) => handleChangeSelect('stores', value)}
								selectOptions={[...(storeList.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>

					<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
						<LoadAsyncSelectComponent
							loadFCs={paginateConfig?.fcs}
							setList={(val) => setBrandList(val)}
							options={fcsList}
						>
							<AsyncSelect
								style={searchBox}
								allowClear
								name="fcs"
								showSearch
								fetchOptions={getFcOnSearchList}
								transformOptions={(val) => toAsysnOption(val)}
								defaultValue={paginateConfig?.fcs}
								placeholder="Select FC(s)"
								onChange={(value, options) => {
									handleFCChangeSelect('fcs', value, options);
								}}
								selectOptions={[...(fcsList.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>

					<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
						<Select
							name="fields"
							style={searchBox}
							allowClear
							showSearch
							label="brands"
							mode="multiple"
							value={paginateConfig?.brands}
							filterOption={SelectFilterLabelOptions}
							options={brandsList}
							onChange={(value) =>
								setpaginateConfig((prev) => ({
									...prev,
									brands: value,
									...initialPaginateConfigWithoutQ,
								}))
							}
						/>
					</LabelWrapper>

					{filtersEnabled.includes('statuses') && (
						<LabelWrapper label="Status(s)" marginspace="3px 0 -1px 1rem">
							<Select
								name="statuses"
								style={searchBox}
								allowClear
								showSearch
								options={ORDER_STATUSES}
								value={paginateConfig?.statuses}
								filterOption={SelectFilterLabelOptions}
								mode="multiple"
								placeholder="Status(s)"
								onChange={(value) =>
									setpaginateConfig((prev) =>
										handleFilterChange('statuses', value, prev)
									)
								}
							/>
						</LabelWrapper>
					)}

					{/* {filtersEnabled.includes('invoice_date__gte') && ()} */}
					<LabelWrapper label="From Date" marginspace="3px 0 -1px 1rem">
						<DatePicker
							name="invoice_date__gte"
							style={searchBox}
							label="From Date"
							value={
								paginateConfig?.invoice_date__gte
									? moment(paginateConfig?.invoice_date__gte)
									: ''
							}
							disabledDate={disableFutureDate}
							onChange={(value) =>
								setpaginateConfig((prev) =>
									handleFilterChange('invoice_date__gte', value, prev)
								)
							}
							placeholder="From Date"
						/>
					</LabelWrapper>

					{/* {filtersEnabled.includes('invoice_date__lte') && ()} */}
					<LabelWrapper label="To Date" marginspace="3px 0 -1px 1rem">
						<DatePicker
							name="invoice_date__lte"
							style={searchBox}
							value={
								paginateConfig?.invoice_date__lte
									? moment(paginateConfig?.invoice_date__lte)
									: ''
							}
							label="To Date"
							onChange={(value) =>
								setpaginateConfig((prev) =>
									handleFilterChange('invoice_date__lte', value, prev)
								)
							}
							disabled={!paginateConfig?.invoice_date__gte}
							disabledDate={disableDateRangesNew(paginateConfig?.invoice_date__gte)}
							placeholder="To Date"
						/>
					</LabelWrapper>

					<StatusLabelWrapper>
						<QuidText>Quid Deliveries</QuidText>
						<Switch
							checkedChildren="On"
							unCheckedChildren="Off"
							onChange={(value, options) => {
								handleChangeSwitch('bnpl_enabled', value, options);
								// setpaginateConfig((prev) => ({
								// 	...prev,
								// 	brands: value,
								// 	...initialPaginateConfigWithoutQ,
								// }))
							}}
						/>
					</StatusLabelWrapper>

					{filtersEnabled.includes('gst_number') && (
						<LabelWrapper label="Search GST" marginspace="0 0 5px 1rem">
							<SearchField width="200px" margin="0 1rem 1rem">
								<Input
									name="gst_number"
									placeholder="Search GST"
									value={paginateConfig?.gst_number}
									onChange={(e) =>
										setpaginateConfig((prev) =>
											handleFilterChange('gst_number', e?.target?.value, prev)
										)
									}
								/>
							</SearchField>
						</LabelWrapper>
					)}
				</Flex>
				<Flex style={{ gap: 16 }}>
					<Box style={{ width: '10rem' }}>
						<Button
							style={{ marginBottom: 16 }}
							onClick={() => setBtnClickSearh(Math.random())}
						>
							Search
						</Button>
						{/* <Button type="variant" onClick={handleExport}>
					<Flex alignItems="center" justifyContent="center">
						<Box width={32}>
							<SVGICONS.DownloadsStrokeIcons />
						</Box>
						<Box>Export</Box>
					</Flex>
				</Button> */}
						<ACL allowedGroups={UserPersmission.salesOrder_create}>
							<Button
								style={{ background: '#1B4965' }}
								disabled={notAllowedSoTagging}
								onClick={() =>
									navigate(`/order-management/sales-order/mark-eco-bills`)
								}
							>
								Mark ECO Bills
							</Button>
						</ACL>
					</Box>
					<Box>
						<Popover
							content={
								<CheckboxGroup
									options={FilterByOptions}
									value={filtersEnabled}
									onChange={handleFilterByOptions}
									className="vertical-checkbox"
								/>
							}
							title="Add Filters"
							className="hide__show--columns"
						>
							<Button style={{ marginBottom: 16 }}>
								<Flex alignItems="center" justifyContent="center">
									<Box width={32}>
										<SVGICONS.FilterStrokeIcons />
									</Box>
									<Box>Add Filter</Box>
								</Flex>
							</Button>
						</Popover>
						<ACL allowedGroups={UserPersmission.salesOrder_create}>
							<Button
								style={{ background: '#FE5722' }}
								disabled={notAllowedSoTagging}
								onClick={() =>
									navigate(`/order-management/sales-order/mark-pod-bills`)
								}
							>
								Mark Pay On Delivery
							</Button>
						</ACL>
						{!userDefinitions?.user?.is_superuser && (
							<ACL allowedGroups={UserPersmission.salesOrderunblock_list}>
								<Button
									style={{ background: '#FE5722' }}
									onClick={() =>
										navigate(`/order-management/sales-order/blocked-order`)
									}
								>
									Blocked Order
								</Button>
							</ACL>
						)}
					</Box>
				</Flex>
			</Flex>
			<Box mt={3}>
				<RCTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'id',
					}}
					dispatchAction={getSalesOrderList}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					columns={salesOrderListColumns({
						handleClick,
						defaultSort: paginateConfig?.sort_direction || 'DESC',
						defaultsortField: paginateConfig?.sort_column || 'id',
						handleStockOneCallBack,
					})}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
			<WMSMessage visible={isModalOpen} handleHide={hideModal} data={wmsSuccessMessage} />
		</>
	);
}
