import { Tabs as TabsBox } from 'antd';
import styled from 'styled-components';

export const Tabs = styled(TabsBox)`
	.ant-tabs-tab {
		&:hover {
			color: ${(props) => props.theme.colors.primary};
		}
	}
	.ant-tabs-tab.ant-tabs-tab-active {
		.ant-tabs-tab-btn {
			color: ${(props) => props.theme.colors.primary};
		}
	}

	.ant-tabs-ink-bar {
		background-color: ${(props) => props.theme.colors.primary};
	}
`;
