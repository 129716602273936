/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Select, DatePicker } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import moment from 'moment';
import { getDAList } from 'store/fulfilment/deliveryAllocationSlice';
import { Box, Flex, Button, AsyncSelect } from 'atoms';
import * as SVGICONS from 'utils/Svg';
import { RCTable, SearchBar, LabelWrapper, ACL, LoadAsyncSelectComponent } from 'components/common';
import {
	deliveryAllocationListColumns,
	DAListQueries,
	disableFutureDate,
	handleFilterChange,
	handleSearch,
	UserPersmission,
	toAsysnOption,
	searchBox,
	SelectFilterLabelOptions,
	initialPaginateConfigWithoutQ,
} from 'utils';
import { getFcListOnLoad, getFcOnSearchList } from 'store/masters';
import { SearchField } from 'components/common/custom-table/Style';
import { useQueryParams } from 'hooks';

export default function DeliveryAllocation() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);
	const [btnClickSearh, setBtnClickSearh] = useState({});

	const [rowData, setRowData] = useState({});
	const [paginateConfig, setpaginateConfig] = useQueryParams({ filterQuery: DAListQueries });

	useEffect(() => {
		setFcList({ type: 'Loading' });

		dispatch(
			getFcListOnLoad({ fcIds: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [] })
		).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});
	}, [dispatch]);

	const handleChangeSelect = (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value

		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		const fcValues = value?.value ? [value?.value] : [];
		setpaginateConfig((prev) => ({
			...handleFilterChange(name, fcValues, prev),
			brands: [],
		}));
	};

	return (
		<>
			<Flex justifyContent="space-between">
				<Flex flexDirection="column">
					<Flex>
						<LabelWrapper label="Search" marginspace="-1px 0 5px 0">
							<SearchField>
								<SearchBar
									placeholder="Search by Vehicle No"
									queryValue={paginateConfig?.query?.replaceAll('*', '')}
									onSearch={(val) =>
										setpaginateConfig((prev) => handleSearch(val, prev))
									}
								/>
							</SearchField>
						</LabelWrapper>
						<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
							<LoadAsyncSelectComponent
								loadFCs={paginateConfig?.fcs}
								setList={(val) => setBrandList(val)}
								options={fcsList}
							>
								<AsyncSelect
									style={searchBox}
									allowClear
									name="fcs"
									showSearch
									fetchOptions={getFcOnSearchList}
									transformOptions={(val) => toAsysnOption(val)}
									defaultValue={paginateConfig?.fcs}
									placeholder="Select FC(s)"
									onChange={(value, options) => {
										handleChangeSelect('fcs', value, options);
									}}
									selectOptions={[...(fcsList.data || [])]}
								/>
							</LoadAsyncSelectComponent>
						</LabelWrapper>
						<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
							<Select
								name="fields"
								style={searchBox}
								allowClear
								showSearch
								label="brands"
								mode="multiple"
								value={paginateConfig?.brands}
								filterOption={SelectFilterLabelOptions}
								options={brandsList}
								onChange={(value) =>
									setpaginateConfig((prev) => ({
										...prev,
										brands: value,
										...initialPaginateConfigWithoutQ,
									}))
								}
							/>
						</LabelWrapper>
					</Flex>
					<Flex alignItems="center">
						<LabelWrapper label="Select Date" marginspace="0 0 5px 0">
							<DatePicker
								name="date"
								style={{
									width: '200px',
									margin: '0 1rem 0 0',
									alignSelf: 'center',
								}}
								disabledDate={disableFutureDate}
								value={paginateConfig?.date ? moment(paginateConfig?.date) : ''}
								onChange={(value) =>
									setpaginateConfig((prev) =>
										handleFilterChange('date', value, prev)
									)
								}
								placeholder="Select Date"
							/>
						</LabelWrapper>
						<Button mt="0.75rem" onClick={() => setBtnClickSearh(Math.random())}>
							Search
						</Button>
					</Flex>
				</Flex>
				<Flex flexWrap="wrap" alignItems="center" justifyContent="space-between">
					<ACL allowedGroups={UserPersmission.deliveryAllocation_create}>
						<Button
							onClick={() =>
								navigate('/logistics-management/delivery-allocation/create')
							}
						>
							<Flex alignItems="center" justifyContent="center">
								<Box width={36}>
									<SVGICONS.PlusOutlinedIcons />
								</Box>
								<Box>Create Delivery Allocation</Box>
							</Flex>
						</Button>
					</ACL>
				</Flex>
			</Flex>
			<Box mt={3}>
				<RCTable
					rowKey="id"
					dispatchAction={getDAList}
					tableDatas={[rowData, setRowData]}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					columns={deliveryAllocationListColumns}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
		</>
	);
}
