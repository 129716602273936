import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
// import { FileAddOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { Button, Flex, Box, Text } from 'atoms';
import { SearchBar, LabelWrapper } from 'components/common';
import { SearchField } from 'components/common/custom-table/Style';
import { getSearchSalesReturn } from 'store/fulfilment/returnsLogsSlice';
import * as SVGICONS from 'utils/Svg';

const NoInvFound = styled(Text)`
	color: #808080;
	text-align: center;
	font-family: Inter;
	font-size: 26px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 92.308% */
	margin-left: '100px';
`;

const InvLink = styled(Link)`
	color: #000;
	&:hover {
		color: #ca5010;
	}
`;

export default function SearchSalesReturn() {
	const [rowData, setRowData] = useState([]);
	const [searchVal, setSearchVal] = useState('');
	const dispatch = useDispatch();
	const [searched, setSearched] = useState(false);

	function triggerAPICall() {
		dispatch(
			getSearchSalesReturn({
				invoice_no: searchVal,
			})
		)
			.then((res) => setRowData(res))
			.finally(() => {
				console.log('success');
				setSearched(true);
			});
	}

	return (
		<>
			<Flex alignItems="center">
				<Box>
					<LabelWrapper label="Search" marginspace="-1px 0 5px 0">
						<SearchField style={{ width: '60vw' }}>
							<SearchBar
								style={{
									width: '60vw',
									justifyContent: 'left',
									paddingLeft: '10px',
								}}
								placeholder="Search by Invoice No"
								onSearch={(val) => setSearchVal(val)}
							/>
						</SearchField>
					</LabelWrapper>
				</Box>
				<Box>
					<Button mt="12px" onClick={triggerAPICall} disabled={searchVal === ''}>
						Search
					</Button>
				</Box>
			</Flex>

			{rowData?.map((data) => (
				<Flex
					key={data?.order_id}
					mt={1}
					style={{
						justifyContent: 'space-between',
						background: '#fff',
						padding: '20px',
						width: '60vw',
						border: '1px solid #EDEDED',
					}}
				>
					<InvLink to={`/order-management/returns/search-sales-return/${data?.order_id}`}>
						<Flex
							style={{
								gap: '8px',
							}}
						>
							<Text>{data?.invoice_no}</Text>

							<Text>{data?.store_name}</Text>
						</Flex>
					</InvLink>

					{/* <Link to={`/order-management/returns/search-sales-return/${data?.order_id}`}>
						<FileAddOutlined />
					</Link> */}
				</Flex>
			))}

			{isEmpty(rowData) && !isEmpty(searchVal) && searched && (
				<Flex
					style={{
						flexDirection: 'column',
						alignItems: 'center',
						height: '70vh',
						width: '60vw',
						justifyContent: 'center',
						gap: '2rem',
					}}
				>
					<Box width={30}>
						<SVGICONS.NoSalesReturnInvSvg />
					</Box>
					<NoInvFound ml="100px"> Sorry Invoice Not Available in CDMS</NoInvFound>
				</Flex>
			)}

			{console.log('rowData', rowData)}
		</>
	);
}
