import styled from 'styled-components';
import { Table } from 'antd';
import { Box } from 'atoms';

export const ErrorMessage = styled(Box)`
	line-height: 11px;
	position: absolute;
	font-size: 11px;
	color: #ff0000;
	margin-top: 4px;
`;

export const RFCTable = styled(Table)`
	.ant-table-cell {
	}
	.border {
		border-right: 1px solid #ccc;
		padding-right: 0;
	}
	.pl-increase {
		padding-left: 1.5rem;
	}
`;

export const Badge = styled.span`
	font-size: 12px;
	color: #fff;
	background-color: #e2585d;
	padding: 0 5px;
	border-radius: 4px;
	margin-right: 8px;
	background: #fff;
	${(props) =>
		props.free && {
			background: '#0a910d',
		}}
	${(props) =>
		props.due && {
			background: '#e2585d',
		}}
`;
