import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Loader } from 'components';
import { AuthRoute, PrivateRoute } from 'routes';
import { checkAssignedGroups } from 'utils/userPermission';
import * as Pages from 'pages';
import { unAuthPages, privatePages, getThemes, GlobalStyle } from 'utils/router-static-data';
import { RemarksList } from 'components/pages/segregator';

export default function Router() {
	const state = useSelector((x) => x);
	const [toggleTheme, settoggleTheme] = useState('orangetheme');

	return (
		<>
			<GlobalStyle />
			<ThemeProvider theme={getThemes(toggleTheme)}>
				<Loader />
				<RemarksList />
				<Routes>
					{/* Unauth routes start here */}
					{unAuthPages?.map((page) => (
						<Route
							key={page?.key}
							path={page?.path}
							element={<AuthRoute>{page?.element}</AuthRoute>}
						/>
					))}
					{/* Unauth routes ends here */}

					{/* authenticated pages starts */}
					{privatePages(state)
						.filter((x) =>
							checkAssignedGroups(
								x.includedGroups,
								state?.auth?.auth?.user,
								x?.excludedroute || false
							)
						)
						.map((page) => (
							<Route
								key={page?.key}
								path={page?.path}
								element={
									<PrivateRoute
										settoggleTheme={settoggleTheme}
										breadCrumbsList={page.breadCrumbs}
									>
										{page?.element}
									</PrivateRoute>
								}
							/>
						))}
					{/* authenticated pages ends */}
					<Route path="*" element={<Pages.NotFound />} />
				</Routes>
			</ThemeProvider>
		</>
	);
}
