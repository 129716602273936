import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Image, Box } from 'atoms';
import { setShowRemarks, setShowRemarksRecord, setRemarksStatus } from 'store/invoiceSlice';
import addRemarks from 'assets/svg/add-remarks.svg';
import message from 'assets/svg/message.svg';

export default function RemarksIcon({ record, type = 'BULK' }) {
	const dispatch = useDispatch();

	function handleClick() {
		console.log(record);
		dispatch(setShowRemarks(true));
		dispatch(setShowRemarksRecord(record));
		dispatch(setRemarksStatus(type));
	}

	return (
		<Box cursor="pointer" onClick={() => handleClick()}>
			<Image
				width={24}
				height={24}
				src={record?.remark_flag ? message : addRemarks}
				alt="addRemarks"
			/>
		</Box>
	);
}

RemarksIcon.propTypes = {
	type: PropTypes.string,
	record: PropTypes.any,
};
