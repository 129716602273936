/* eslint-disable no-plusplus */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { InputNumber, message, Modal } from 'antd';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import deleteButton from 'assets/icons/deleteButton.svg';
import { SearchBar, LabelWrapper, ACL, ACLBoolean } from 'components/common';
import { SearchField } from 'components/common/custom-table/Style';
import { Box, Text, Flex, Button, Image } from 'atoms';
import Rupee from 'components/Rupee';
import { Thead, RFCTable } from 'components/forms/return-to-fc';
import {
	ShowReturnsLogsId,
	updateSalesReturnsList,
	getProductList,
} from 'store/fulfilment/returnsLogsSlice';
import { UserPersmission } from 'utils';

const DisabledSearch = {
	backgroundColor: '#f5f5f5',
	cursor: 'not-allowed',
	width: '30vw',
	justifyContent: 'left',
	paddingLeft: '10px',
};

const ValueText = styled(Text)`
	color: #343434;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 550;
	line-height: 24px; /* 120% */
	margin-top: 16px;
`;

const TitleText = styled(Text)`
	color: #000;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 171.429% */
`;

const ButtonContainer = styled(Flex)`
	margin-top: 40px;
	margin-bottom: 40px;
	gap: 14px;
	justify-content: right;
`;

const CancelBtn = styled(Button)`
	border-radius: 4px;
	border: 1px solid #ca5010;
	background: #fff;
	color: #ca5010;
`;

const SaveBtn = styled(Button)`
	border-radius: 4px;
`;

const MarkAsReceivedBtn = styled(Button)`
	border-radius: 4px;
	border: 1px solid #0a6e03;
	background: #0a9600;
`;

const ProductItem = styled(Flex)`
	&:hover {
		color: #ca5010;
	}
`;

const ModalTitleConatiner = styled(Flex)`
	align-items: center;
	gap: 10px;
	color: #ca5010;
`;

export default function UpdateSalesReturn() {
	const [rowData, setRowData] = useState({});
	const [tableData, setTableData] = useState([]);
	const [searchVal, setSearchVal] = useState('');
	const [showList, setShowList] = useState(false);
	const [searchedList, setSearchedList] = useState([]);
	const [allProductsList, setAllProductsList] = useState([]);
	// const [productIdsList, setProductIdsList] = useState([]);
	const [deleteId, setDeleteId] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [disableSave, setDisableSave] = useState(false);
	const [disableInputSave, setDisableInputSave] = useState(false);

	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleOk = (pId) => {
		setIsModalOpen(false);
		DeleteProductOnClick(pId);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};
	const disableInput = ACLBoolean(UserPersmission.salesReturn_create);

	useEffect(() => {
		dispatch(ShowReturnsLogsId(id))
			.then((res) => {
				setRowData(res);
				setTableData(res?.return_details);
				// setProductIdsList(res?.return_details?.map((item) => item?.id));
				setAllProductsList(res?.all_product_details);
			})
			.catch((e) => {
				console.log('e', e);
				if ('response' in e && e?.response?.status === 422) {
					message.error(`${e?.response?.data?.message}`);
					setDisableSave(true);
					navigate(`/order-management/returns/`);
				} else {
					message.error('Error while loading the Sales Return');
				}
			});
		setDisableInputSave(disableInput);
	}, [dispatch, id, setDisableInputSave]);

	useEffect(() => {
		if (rowData?.order_id) {
			dispatch(
				getProductList({
					query: searchVal,
					order_id: rowData?.order_id,
				})
			)
				.then((res) => {
					const uniqueList = [];
					if (res.length) {
						for (let i = 0; i < res.length; i++) {
							const isIn2ndArr = tableData.find((x) => x.id === res[i].id);
							if (!isIn2ndArr) uniqueList.push(res[i]);
						}
					}
					setSearchedList(uniqueList);
				})
				.finally(() => {
					console.log('success');
				});
		}
	}, [searchVal, tableData, dispatch, rowData?.order_id]);

	const salesReturnTable = [
		{
			title: 'Product Name',
			dataIndex: 'product_name',
			key: 'product_name',
			render: (product_name, record) => (
				<>
					<Text>
						{product_name}({record?.product_code})
					</Text>
					<Rupee>{record?.mrp || 0}</Rupee>
				</>
			),
		},
		{
			title: <Thead>Returnable Qty</Thead>,
			dataIndex: 'returnable_qty',
			key: 'returnable_qty',
			render: (returnable_qty) => <Text>{returnable_qty}</Text>,
			// hidden: rowData?.state === 'mark_as_received',
		},
		{
			title: <Thead>Sales Return Qty</Thead>,
			dataIndex: 'sales_returned_qty',
			key: 'sales_returned_qty',
			render: (sales_returned_qty, record, index) => {
				const returnableQty = record?.returnable_qty || 0;
				return (
					<InputNumber
						min={1}
						max={returnableQty}
						disabled={rowData?.state === 'mark_as_received' || !disableInputSave}
						value={record?.sales_returned_qty || 0}
						onChange={(value) => {
							const goodQty = value - (record?.bad_qty || 0);

							setTableData(
								tableData?.map((roww, i) =>
									i === index
										? {
												...record,
												sales_returned_qty: value,
												good_qty: goodQty,
										  }
										: roww
								)
							);
						}}
					/>
				);
			},
		},

		{
			title: <Thead>Bad Qty</Thead>,
			// dataIndex: 'bad_qty',
			key: 'bad_qty',
			render: (bad_qty, record, index) => {
				const returnedQty = record?.sales_returned_qty || 0;
				return (
					<InputNumber
						min={0}
						max={returnedQty}
						disabled={rowData?.state === 'mark_as_received' || !disableInputSave}
						value={record?.bad_qty || 0}
						onChange={(value) => {
							const goodQty = returnedQty - value;
							setTableData(
								tableData?.map((roww, i) =>
									i === index
										? {
												...record,
												bad_qty: value,
												good_qty: goodQty,
										  }
										: roww
								)
							);
						}}
					/>
				);
			},
		},
		{
			title: <Thead>Good Qty</Thead>,
			// dataIndex: 'good_qty',
			key: 'good_qty',
			render: (good_qty, record) => (
				<InputNumber min={0} disabled value={record?.good_qty || 0} />
			),
		},
		{
			title: '',
			className: 'border',
			dataIndex: 'actions',
			key: 'actions',
			render: (_, record) => {
				if (rowData?.state === 'mark_as_received') {
					return <Text></Text>;
				}
				return (
					<Flex justifyContent="center">
						<ACL allowedGroups={UserPersmission.salesReturn_create}>
							<Image
								src={deleteButton}
								alt="delete-icon"
								onClick={() => {
									showModal();
									setDeleteId(record?.id);
								}}
								style={{
									cursor: 'pointer',
								}}
							/>
						</ACL>
						{/* <Modal
							destroyOnClose
							title={
								<ModalTitleConatiner>
									<Image
										src={deleteButton}
										alt="edit-icon"
										height={25}
										width={25}
									/>
									<Text>Confirmation</Text>
								</ModalTitleConatiner>
							}
							visible={isModalOpen}
							// onOk={() => {
							// 	handleOk(record?.id);
							// }}
							onCancel={handleCancel}
							footer={[
								<Button
									key="Close"
									onClick={handleCancel}
									style={{
										borderRadius: '4px',
										background: '#fff',
										color: '#CA5010',
										// eslint-disable-next-line sonarjs/no-duplicate-string
										border: '1px solid #CA5010',
									}}
								>
									No
								</Button>,
								<Button
									key="Ok"
									onClick={() => {
										handleOk(deleteId);
									}}
									style={{
										borderRadius: '4px',
										border: '1px solid #CA5010',
									}}
								>
									Yes
								</Button>,
							]}
						>
							<Text>Are you sure you want to delete?</Text>
						</Modal> */}
					</Flex>
				);
			},
		},
	];

	const salesReturnTableMarkAsRec = [
		{
			title: 'Product Name',
			dataIndex: 'product_name',
			key: 'product_name',
			render: (product_name, record) => (
				<>
					<Text>
						{product_name}({record?.product_code})
					</Text>
					<Rupee>{record?.mrp || 0}</Rupee>
				</>
			),
		},
		{
			title: <Thead>Sales Return Qty</Thead>,
			dataIndex: 'sales_returned_qty',
			key: 'sales_returned_qty',
			render: (sales_returned_qty, record, index) => {
				const returnableQty = record?.returnable_qty || 0;
				console.log(returnableQty);
				return (
					<InputNumber
						min={1}
						// max={returnableQty}
						disabled={rowData?.state === 'mark_as_received' || !disableInputSave}
						value={record?.sales_returned_qty || 0}
						onChange={(value) => {
							const goodQty = value - (record?.bad_qty || 0);

							setTableData(
								tableData?.map((roww, i) =>
									i === index
										? {
												...record,
												sales_returned_qty: value,
												good_qty: goodQty,
										  }
										: roww
								)
							);
						}}
					/>
				);
			},
		},

		{
			title: <Thead>Bad Qty</Thead>,
			// dataIndex: 'bad_qty',
			key: 'bad_qty',
			render: (bad_qty, record, index) => {
				const returnedQty = record?.sales_returned_qty || 0;
				return (
					<InputNumber
						min={0}
						max={returnedQty}
						disabled={rowData?.state === 'mark_as_received'}
						value={record?.bad_qty || 0}
						onChange={(value) => {
							const goodQty = returnedQty - value;
							setTableData(
								tableData?.map((roww, i) =>
									i === index
										? {
												...record,
												bad_qty: value,
												good_qty: goodQty,
										  }
										: roww
								)
							);
						}}
					/>
				);
			},
		},
		{
			title: <Thead>Good Qty</Thead>,
			// dataIndex: 'good_qty',
			key: 'good_qty',
			render: (good_qty, record) => (
				<InputNumber min={0} disabled value={record?.good_qty || 0} />
			),
		},
		{
			title: '',
			className: 'border',
			dataIndex: 'actions',
			key: 'actions',
			render: (_, record) => {
				if (rowData?.state === 'mark_as_received') {
					return <Text></Text>;
				}
				return (
					<Flex justifyContent="center">
						<Image
							src={deleteButton}
							alt="delete-icon"
							onClick={() => {
								showModal();
								setDeleteId(record?.id);
							}}
							style={{
								cursor: 'pointer',
							}}
						/>
						{/* <Modal
							destroyOnClose
							title={
								<ModalTitleConatiner>
									<Image
										src={deleteButton}
										alt="edit-icon"
										height={25}
										width={25}
									/>
									<Text>Confirmation</Text>
								</ModalTitleConatiner>
							}
							visible={isModalOpen}
							// onOk={() => {
							// 	handleOk(record?.id);
							// }}
							onCancel={handleCancel}
							footer={[
								<Button
									key="Close"
									onClick={handleCancel}
									style={{
										borderRadius: '4px',
										background: '#fff',
										color: '#CA5010',
										border: '1px solid #CA5010',
									}}
								>
									No
								</Button>,
								<Button
									key="Ok"
									onClick={() => {
										handleOk(deleteId);
									}}
									style={{
										borderRadius: '4px',
										border: '1px solid #CA5010',
									}}
								>
									Yes
								</Button>,
							]}
						>
							<Text>Are you sure you want to delete?</Text>
						</Modal> */}
					</Flex>
				);
			},
		},
	];

	const handleSaveClick = (btnType) => {
		if (tableData.length > 0) {
			const payload = {
				brand: rowData?.brand,
				invoice_no: rowData?.invoice_no,
				return_id: id,
				salesman: rowData?.salesman,
				store_name: rowData?.store,
				return_details: tableData,
				state: btnType === 'save' ? 'save' : 'mark_as_received',
			};

			return dispatch(updateSalesReturnsList(payload, id))
				.then(() => {
					if (btnType === 'save') {
						message.info('Sales Return Updated Successfully!');
						window.location.reload();
					}
					if (btnType === 'mark_as_received') {
						message.info('Sales Return Received Successfully!');
						navigate(`/order-management/returns/`);
					}

					// navigate(`/order-management/returns/`);
				})
				.catch((e) => {
					if ('response' in e && e?.response?.status === 422) {
						// message.error('Error while updating the Sales Return');
						message.error(`${e?.response?.data?.message}`);
					} else {
						message.error('Error while updating the Sales Return');
					}
				});
		}
		return message.error('Please add some products to create a Sales Return');
	};

	// const removeDuplicates = (arr) => arr?.filter((item, index) => arr?.indexOf(item) === index);

	const AddProductOnClick = (productId, data) => {
		// const latestIds = removeDuplicates([productId, ...productIdsList]);
		// setProductIdsList(latestIds);
		// const arr = allProductsList?.filter((item) => latestIds?.includes(item?.id));
		// setTableData(arr);

		if (!tableData?.find((obj) => obj.id === productId)) {
			message.info(`${data?.product_name} added sucessfully`);

			const arr = allProductsList?.filter((item) => [productId]?.includes(item?.id));
			const uniqueArr = [...tableData, ...arr];
			setTableData(uniqueArr);
		} else {
			message.info(`Product already present`);
			const updatedTableData = tableData.map((item) =>
				item.id === productId ? { ...item, sales_returned_qty: 0 } : item
			);
			setTableData(updatedTableData);
		}
	};

	const DeleteProductOnClick = (productId) => {
		// const productIdsArray = productIdsList;
		// const index = productIdsArray.indexOf(productId);
		// productIdsArray.splice(index, 1);
		// setProductIdsList(productIdsArray);
		// const arr = tableData?.filter((item) => productIdsArray?.includes(item?.id));
		// setTableData(arr);

		const finalArray = tableData?.filter((e) => ![productId].includes(e.id));
		console.log('finalArray', finalArray);
		setTableData(finalArray);
	};

	return (
		<>
			<Flex
				style={{
					background: '#fff',
					gap: '34px',
					padding: '40px 16px',
					borderRadius: '4px',
					marginBottom: '30px',
					border: '1px solid rgb(237, 237, 237)',
				}}
			>
				<Box ml={3}>
					<TitleText>Invoice Number</TitleText>
					<ValueText>{rowData?.invoice_no}</ValueText>
				</Box>
				<Box>
					<TitleText>Brand</TitleText>
					<ValueText>{rowData?.brand}</ValueText>
				</Box>
				<Box>
					<TitleText>Salesperson</TitleText>
					<ValueText>{rowData?.salesman}</ValueText>
				</Box>
				<Box>
					<TitleText>Store Name</TitleText>
					<ValueText>{rowData?.store}</ValueText>
				</Box>
			</Flex>
			{rowData?.state !== 'mark_as_received' && (
				<Flex style={{ flexDirection: 'column', marginBottom: '30px' }}>
					<Flex alignItems="center">
						<ACL allowedGroups={UserPersmission.salesReturn_create}>
							<LabelWrapper label="" marginspace="-1px 0 5px 0">
								<SearchField style={{ width: '30vw' }}>
									<SearchBar
										// style={{
										// 	width: '30vw',
										// 	justifyContent: 'left',
										// 	paddingLeft: '10px',
										// }}
										placeholder="Search to add Product"
										onSearch={(val) => {
											setSearchVal(val);
											if (isEmpty(val)) {
												setShowList(false);
											}
										}}
										onFocus={() => setSearchVal(' ')}
										disabled={rowData?.status === 'cancelled'}
										style={
											rowData?.status === 'cancelled'
												? DisabledSearch
												: {
														width: '30vw',
														justifyContent: 'left',
														paddingLeft: '10px',
												  }
										}

										// onBlur={() => setSearchVal('')}
									/>
								</SearchField>
							</LabelWrapper>
						</ACL>
						{/* <Button mt="4px" onClick={triggerAPICall} disabled={searchVal === ''}>
						Search
					</Button> */}
					</Flex>
					{!isEmpty(searchedList) &&
						(!isEmpty(searchVal) || (isEmpty(searchVal) && showList)) && (
							<Box
								style={{
									width: '30vw',
									background: '#fff',
									zIndex: 99,
									// eslint-disable-next-line sonarjs/no-duplicate-string
									border: '1px solid #E5E4E4',
									height: '200px',
									overflowY: 'scroll',
									overflowX: 'hidden',
								}}
							>
								{searchedList?.map((data) => (
									<ProductItem
										key={data?.id}
										style={{
											background: '#fff',
											width: '30vw',
											gap: '8px',
											cursor: 'pointer',
											padding: '12px',
											borderBottom: '1px solid #E5E4E4',
										}}
										onClick={() => {
											AddProductOnClick(data?.id, data);
										}}
									>
										<Text>{data?.product_name}</Text>
										<Rupee>{data?.mrp}</Rupee>
									</ProductItem>
								))}
							</Box>
						)}

					{!isEmpty(searchVal) && isEmpty(searchedList) && (
						<Flex
							style={{
								width: '30vw',
								color: 'rgba(0, 0, 0, 0.25)',
								background: '#fff',
								zIndex: 99,
								border: '1px solid #E5E4E4',
								height: '200px',
								overflowY: 'scroll',
								overflowX: 'hidden',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							No Data Available
						</Flex>
					)}
				</Flex>
			)}
			<Box mt={3}>
				<RFCTable
					rowKey={(record) => `${record?.id}`}
					columns={
						rowData?.state === 'mark_as_received'
							? salesReturnTableMarkAsRec
							: salesReturnTable
					}
					dataSource={tableData}
					pagination={false}
				/>

				<Modal
					destroyOnClose
					title={
						<ModalTitleConatiner>
							<Image src={deleteButton} alt="edit-icon" height={25} width={25} />
							<Text>Confirmation</Text>
						</ModalTitleConatiner>
					}
					visible={isModalOpen}
					// onOk={() => {
					// 	handleOk(record?.id);
					// }}
					onCancel={handleCancel}
					footer={[
						<Button
							key="Close"
							onClick={handleCancel}
							style={{
								borderRadius: '4px',
								background: '#fff',
								color: '#CA5010',
								border: '1px solid #CA5010',
							}}
						>
							No
						</Button>,
						<Button
							key="Ok"
							onClick={() => {
								handleOk(deleteId);
							}}
							style={{
								borderRadius: '4px',
								border: '1px solid #CA5010',
							}}
						>
							Yes
						</Button>,
					]}
				>
					<Text>Are you sure you want to delete?</Text>
				</Modal>
			</Box>
			<ACL allowedGroups={UserPersmission.salesReturn_create}>
				<ButtonContainer>
					<CancelBtn onClick={() => navigate(`/order-management/returns/`)}>
						{rowData?.state === 'mark_as_received' || rowData?.status === 'cancelled'
							? 'Back'
							: 'Cancel'}
					</CancelBtn>
					<SaveBtn
						disabled={
							rowData?.state === 'mark_as_received' ||
							rowData?.status === 'cancelled' ||
							disableSave
						}
						onClick={() => handleSaveClick('save')}
					>
						Save
					</SaveBtn>
					<MarkAsReceivedBtn
						disabled={
							rowData?.state === 'mark_as_received' ||
							rowData?.disable_received_button ||
							rowData?.status === 'cancelled' ||
							disableSave
						}
						onClick={() => handleSaveClick('mark_as_received')}
					>
						Mark as received
					</MarkAsReceivedBtn>
				</ButtonContainer>
			</ACL>
		</>
	);
}
