import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { getReturnToFCReasonList } from 'store/fulfilment/returnToFCSlice';
import { SelectFilterOptions } from 'utils';

const { Option } = Select;
export default function ModalSelect({
	filter,
	placeholder = '',
	width = '',
	returnReason = '',
	selectChange = () => {},
	mismatch = false,
	mismatchChange = () => {},
	status = '',
}) {
	const dispatch = useDispatch();
	const [reasonList, setReasonList] = useState([]);

	useEffect(() => {
		dispatch(getReturnToFCReasonList(filter)).then((res) => setReasonList(res));
	}, [dispatch, filter]);

	return (
		<Select
			placeholder={placeholder}
			name="reason"
			showSearch
			filterOption={SelectFilterOptions}
			disabled={status === 'CA'}
			style={{ maxWidth: width || '50%', minWidth: width || '50%' }}
			onChange={mismatch ? mismatchChange : selectChange}
			defaultValue={returnReason || null}
		>
			{reasonList.map((reason) => (
				<Option key={reason.id} value={reason.id}>
					{reason.name}
				</Option>
			))}
		</Select>
	);
}

ModalSelect.propTypes = {
	filter: PropTypes.object,
	placeholder: PropTypes.string,
	width: PropTypes.string,
	returnReason: PropTypes.number,
	selectChange: PropTypes.func,
	mismatch: PropTypes.bool,
	mismatchChange: PropTypes.func,
	status: PropTypes.string,
};
