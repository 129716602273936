import { Text } from 'atoms';

export const pincodeColumns = [
	{
		title: 'Pincode',
		dataIndex: 'pincode',
		key: 'pincode',
		render: (pincode) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{pincode || ''}
			</Text>
		),
	},
	{
		title: 'Area',
		dataIndex: 'area',
		key: 'area',
		render: (area) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{area || ''}
			</Text>
		),
	},

	{
		title: 'City',
		dataIndex: 'city',
		key: 'city',
		render: (city) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{city || ''}
			</Text>
		),
	},
	{
		title: 'State',
		dataIndex: 'state',
		key: 'state',
		render: (state) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{state || ''}
			</Text>
		),
	},
	{
		title: 'Country',
		dataIndex: 'country',
		key: 'country',
		render: (country) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{country || ''}
			</Text>
		),
	},
];
