import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setDate, TODAY } from 'store/invoiceSlice';
import { Image } from 'atoms';
import dateIcon from 'assets/svg/date-icon.svg';

export default function Date() {
	const dispatch = useDispatch();
	const { date = TODAY } = useSelector((state) => state.invoice);

	const onDateChange = (_, dateString) => {
		dispatch(setDate(dateString));
	};

	return (
		<DatePicker
			disabledDate={(current) => current && current > moment().endOf('day')}
			className="datepicker"
			allowClear={false}
			format="DD-MMM-YYYY"
			onChange={onDateChange}
			defaultValue={moment(date)}
			suffixIcon={<Image width={14} height={14} src={dateIcon} alt="dateIcon" />}
		/>
	);
}

const DatePicker = styled(AntDatePicker)`
	border: 0;
	outline: none;
	background-color: transparent;

	.ant-picker-input {
		display: flex;
		flex-direction: row-reverse;
	}
	input {
		color: ${(props) => props.theme.colors.PrussianBlue};
		margin-left: 1rem;
	}
`;
