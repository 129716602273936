import React from 'react';
import ReactDOM from 'react-dom/client';
import 'styles/index.scss';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';
import Router from 'routes/Router';
import { ErrorBoundary } from 'components';
import { useNavigatorOnLine } from 'hooks';
import reportWebVitals from './reportWebVitals';
import { OfflinePage } from 'pages';
import packageJson from '../package.json';

console.log(
	`%cWelcome to Ripplr CDMS App - ${process.env.NODE_ENV} - ${process.env.REACT_APP_ENV} - ${packageJson.version}`,
	'background: #46C2B6; color: #113F5F; font-size: 12px'
);

console.log(
	`%cS3 Details - ${process.env.REACT_APP_BUCKET_NAME} - ${process.env.REACT_APP_S3_IDENTITYPOOL_ID} - ${process.env.REACT_APP_BUCKET_REGION} `,
	'background: #46C2B6; color: #113F5F; font-size: 12px'
);

function AppRender() {
	const networkStatus = useNavigatorOnLine();
	return (
		<ErrorBoundary>
			<Provider store={store}>
				{networkStatus ? (
					<BrowserRouter basename={process.env.PUBLIC_URL}>
						<Router />
					</BrowserRouter>
				) : (
					<OfflinePage />
				)}
			</Provider>
		</ErrorBoundary>
	);
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<AppRender />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
