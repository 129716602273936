/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { Container, AntdTable } from './Style';
import { TableShimmer } from 'components/common/custom-table';
/**
 * dispatchAction - props which triggers api call
 * pagination({}) - send pagination config like currentpage, limit, showPager: false
 * configs({}) - used while api call needs static params(staticPayload, check onBoarding -> Brand page for reference)
 * togglePaginate - local state to get/Set the filter values([paginateConfig, setPaginateConfig])
 * tableDatas - local state for storing api response
 */
export default function RCTable(props) {
	const {
		dispatchAction,
		updateStatusAction,
		columns = [],
		hasPagination = true,
		pagination: paginationConfig,
		configs = { defaultSort: 'DESC', defaultsortField: 'createdAt' },
		togglePaginate = [{}, () => null],
		tableDatas = [{}, () => null],
		disableAutoSearch = false,
		triggerBtnSearch = [null, null],
		pageSizeOptions,
	} = props;

	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const [rowData, setRowData] = tableDatas;
	const [paginateConfig, setpaginateConfig] = togglePaginate;
	const [btnClickSearh, setBtnClickSearh] = triggerBtnSearch;

	// Table onchange event
	const handleChange = (pagination, filters, sorter, rest) => {
		if (hasPagination) {
			const pageoffset = pagination.current * pagination.pageSize - pagination.pageSize;
			const resetSort = rest?.action === 'sort';
			const resetPaginate = Number(paginateConfig.limit) !== pagination.pageSize;
			const sendAscDesc = sorter?.column
				? sorter?.order === 'ascend'
					? 'ASC'
					: 'DESC'
				: configs?.defaultSort;
			const sortField = !isEmpty(sorter?.column) ? sorter?.field : configs?.defaultsortField;

			setpaginateConfig((prev) => ({
				...prev,
				limit: resetSort ? Number(paginateConfig.limit) : pagination.pageSize,
				offset: resetSort || resetPaginate ? 0 : pageoffset,
				currentPage:
					resetSort || resetPaginate
						? 1
						: Math.ceil(pageoffset / pagination.pageSize) + 1,
				sort_column: sortField,
				sort_direction: sendAscDesc,
			}));

			if (disableAutoSearch && setBtnClickSearh) {
				setBtnClickSearh(Math.random());
			}
		}
	};

	// api call for getting the total data/Filter data
	useEffect(() => {
		if (dispatchAction && !isEmpty(paginateConfig) && !disableAutoSearch) {
			triggerAPICall();
		}
	}, [dispatch, dispatchAction, paginateConfig, disableAutoSearch]);

	useEffect(() => {
		setRowData(updateStatusAction);
	}, [updateStatusAction]);

	useEffect(() => {
		if (disableAutoSearch && btnClickSearh) {
			triggerAPICall();
		}
	}, [btnClickSearh]);

	function triggerAPICall() {
		if (dispatchAction && !isEmpty(paginateConfig)) {
			const { offset, limit, query, ...rest } = paginateConfig;
			setIsLoading(true);
			dispatch(
				dispatchAction({
					offset: Number(offset),
					limit: Number(limit),
					query,
					...rest,
					...(configs?.staticPayload || {}),
				})
			)
				.then((res) => setRowData(res))
				.finally(() => {
					setIsLoading(false);
				});
		}
	}
	useEffect(() => {
		console.log('rowData is', rowData);
	}, [dispatchAction, rowData]);

	return (
		<Container>
			<TableShimmer
				rowsCount={Number(paginateConfig?.limit) || 10}
				columns={columns}
				loading={isLoading}
			/>
			<AntdTable
				{...props}
				onChange={handleChange}
				pagination={{
					...paginationConfig,
					total: rowData?.count,
					showSizeChanger: true,
					current: Number(paginateConfig?.currentPage) || 1,
					pageSize: Number(paginateConfig?.limit) || 10,
					pageSizeOptions,
				}}
				columns={columns}
				dataSource={rowData?.rows?.slice(0, paginateConfig?.limit || 10) || 0}
				className={isLoading ? 'hide' : 'show'}
			/>
		</Container>
	);
}

RCTable.propTypes = {
	columns: PropTypes.array,
	dispatchAction: PropTypes.func,
	hasPagination: PropTypes.bool,
	pagination: PropTypes.object,
	configs: PropTypes.object,
	tableDatas: PropTypes.array,
	updateStatusAction: PropTypes.any,
	togglePaginate: PropTypes.array,
	disableAutoSearch: PropTypes.bool,
	triggerBtnSearch: PropTypes.any,
	pageSizeOptions: PropTypes.any,
};
