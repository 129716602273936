/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-multi-comp */
import { Modal, Carousel } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';
import moment from 'moment';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Box, Flex } from 'atoms';
import { Rupee } from 'components';
import { date } from 'utils';
import { getUrlExtension } from 'utils/helper';

const LabelText = styled(Box)`
	font-weight: 300;
	font-size: 12px;
	line-height: 15px;
	color: #ffffff;
`;
const ValueText = styled(Box)`
	font-size: 15px;
	line-height: 28px;
`;
const LoadedPDF = styled(Box)`
	img {
		margin: auto;
		width: 50%;
		height: 400px;
	}
	.react-pdf__Page__canvas {
		margin: 0 auto;
		width: 60% !important;
		height: 400px !important;
	}
`;

function LoadPdf({ attachment }) {
	pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	const [pageNumber, setPageNumber] = useState(1);
	function onDocumentLoadSuccess({ numPages }) {
		setPageNumber(numPages);
	}

	return (
		<Document file={attachment} onLoadSuccess={onDocumentLoadSuccess}>
			<Page pageNumber={pageNumber} />
		</Document>
	);
}

export function AttachmentModal({ toggleModal = [], tableData = [] }) {
	const [{ modalVisisble, index = 0 }, setShowModal] = toggleModal;
	const CarouselEl = useRef(null);
	const handleOk = () => {
		setShowModal({ modalVisisble: false, index: 0 });
	};

	useEffect(() => {
		if (CarouselEl?.current?.goTo) {
			CarouselEl?.current?.goTo(index);
		}
	}, [index, CarouselEl]);

	return (
		<Modal
			centered
			width="60%"
			visible={modalVisisble}
			onOk={handleOk}
			onCancel={handleOk}
			footer={false}
			className="attachmentModal"
		>
			<Box style={{ backgroundColor: '#000' }}>
				<Carousel
					ref={CarouselEl}
					arrows
					prevArrow={
						<Box zIndex="11" fontSize="12px" lineHeight="14px" color="#3C3C3C">
							<Flex height="100%" justifyContent="center" alignItems="center">
								Previous
							</Flex>
						</Box>
					}
					nextArrow={
						<Box zIndex="11" fontSize="12px" lineHeight="14px" color="#3C3C3C">
							<Flex height="100%" justifyContent="center" alignItems="center">
								Next
							</Flex>
						</Box>
					}
				>
					{tableData
						.filter((x) => !isEmpty(x.attachment))
						.map((x) => (
							<Box padding="24px" key={x?.id}>
								<Box>
									<Box color="#fff" textAlign="center" marginBottom="10px">
										<LabelText as="span">Attachment:</LabelText>{' '}
										<Box as="span" fontSize="14px" lineHeight="17px">
											{x.attachment || '-'}
										</Box>
									</Box>
									<LoadedPDF
										marginBottom="15px"
										minHeight="400px"
										textAlign="center"
										color="#fff"
									>
										{x?.attachment ? (
											getUrlExtension(x?.attachment) === 'pdf' ? (
												<LoadPdf attachment={x.attachment} />
											) : (
												<img src={x.attachment} alt="challen-copy" />
											)
										) : (
											'No Attachment found'
										)}
									</LoadedPDF>
								</Box>
								<Flex
									justifyContent="space-between"
									padding="16px 25px"
									color="#FFFFFF"
									borderTop="2px solid #fff"
								>
									<Box>
										<LabelText>Date</LabelText>
										<ValueText>{date(x?.date_time)}</ValueText>
									</Box>
									<Box>
										<LabelText>Time</LabelText>
										<ValueText>
											{moment(x?.date_time).format('HH:mm:ss A')}
										</ValueText>
									</Box>
									<Box>
										<LabelText>Verified Amount</LabelText>
										<ValueText>
											<Rupee>{x?.verified_amount || 0}</Rupee>
										</ValueText>
									</Box>
									<Box>
										<LabelText>Settled Amount</LabelText>
										<ValueText>
											<Rupee>{x?.settled_amount || 0}</Rupee>
										</ValueText>
									</Box>
									<Box>
										<LabelText>Balance after settlement</LabelText>
										<ValueText>
											<Rupee>{x?.balance_after_settled_amount || 0}</Rupee>
										</ValueText>
									</Box>
									<Box>
										<LabelText>Reason</LabelText>
										<ValueText>{x?.reason || '-'}</ValueText>
									</Box>
								</Flex>
							</Box>
						))}
				</Carousel>
			</Box>
		</Modal>
	);
}

AttachmentModal.propTypes = {
	toggleModal: PropTypes.array,
	tableData: PropTypes.array,
};

LoadPdf.propTypes = {
	attachment: PropTypes.any,
};
