/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-identical-functions */
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Upload, message } from 'antd';
import { config, S3, CognitoIdentityCredentials } from 'aws-sdk';
import { Switch, Select as FormikSelect } from 'formik-antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { FormInput, Flex, Box, Text, Button, AsyncSelect } from 'atoms';
import {
	UPLOAD_FILE_TYPE_CHOICE,
	toAsysnOption,
	toAsysnOrderAdapterOption,
	toAsysnPickersOption,
	SelectFilterLabelOptions,
} from 'utils';
import { getFcOnSearchList, getPickersOnSearchList } from 'store/masters';
import { downloadCollectionformat, uploadFiles } from 'store/UploadSlice';
import { hideLoader, showLoader } from 'store/appSlice';
import { StyledSubmitButton } from 'components/forms/masters/helper';
import { Debug } from 'components/forms/Debug';
import { theme } from 'styles/theme';

const BtnWrap = styled(Flex)`
	justify-content: flex-end;
`;

const UploadWrap = styled(Upload)`
	display: flex;
	width: 100%;
	background: aliceblue;
	min-height: 70px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

// form schema
const FormSchema = Yup.object({
	upload_type: Yup.string(),
	fc: Yup.string().required().label('FC(s)'),
	brand: Yup.string().required().label('Brand(s)'),
	excel: Yup.array().required().min(1).label('Excel'),
	non_picking: Yup.boolean().when('upload_type', {
		is: (upload_type) => upload_type === 'picklite_order',
		then: Yup.boolean(),
	}),
	pickers: Yup.array().when(['non_picking', 'upload_type'], {
		is: (non_picker, upload_type) => !non_picker && upload_type === 'picklite_order',
		then: Yup.array().required('Picker(s) is requires'),
		otherwise: Yup.array(),
	}),
});

const GetEnvironmentString = (data) => {
	switch (data) {
		case 'development':
			return 'dev';
		case 'staging':
			return 'dev';
		case 'training':
			return 'training';
		case 'preprod':
			return 'training';
		case 'production':
			return 'prod-v1';
		case 'testing':
			return 'test';
		default:
			return 'dev';
	}
};

// export const FcListOptions = [
// 	'BTM',
// 	'BZA-VGA1',
// 	'CHN1',
// 	'CHN2',
// 	'CJB',
// 	'HMR',
// 	'HUBLI',
// 	'HYDA',
// 	'HYDM',
// 	'HYDMPST',
// 	'PUNE1',
// 	'PYA',
// 	'YPR',
// 	'GDY',
// 	'TLB',
// ];

// export const BrandListBasedFcs = {
// 	BTM: ['BRIT', 'DBR', 'LOREAL', 'NVA', 'RKT', 'GDJGT'],
// 	CHN1: ['BRIT'],
// 	CJB: ['NVA'],
// 	HMR: ['BRIT', 'DBR', 'GDJGT', 'KBC', 'LOREAL', 'HUL'],
// 	HUBLI: ['GDJ', 'RKT'],
// 	HYDA: ['BRIT', 'HUL'],
// 	PYA: ['ITC', 'TATA'],
// 	YPR: ['NVA', 'BRIT', 'HUL'],
// 	HYDM: ['BRIT', 'GDJ'],
// 	CHN2: ['GDJ'],
// 	PUNE1: ['RB', 'BRIT'],
// 	'BZA-VGA1': ['BRIT'],
// 	HYDMPST: ['BRIT', 'RB', 'DBR'],
// 	GDY: ['CLGT'],
// 	TLB: ['HUL'],
// 	PNY: ['HUL'],
// };

// no need code
// in this page, we have removed brands list api and old component, hereafter based on fc selection brands change added
// after this current feature release if its working find later we can remove the api and component
// 07-07-2022

const formInitialValues = (uploadType, auth, isAdmin, me) => ({
	upload_type: uploadType,
	fc: isAdmin
		? undefined
		: uploadType !== 'picklite_order_adapter'
		? auth?.user?.FCs?.[0]?.id
		: auth?.user?.FCs?.[0]?.code,
	user_id: me?.id.toString(),
	brand: isAdmin
		? undefined
		: uploadType !== 'picklite_order_adapter'
		? auth?.user?.Brands?.[0]?.id
		: auth?.user?.Brands?.[0]?.code,
	non_picking: false,
	excel: undefined,
	pickers: undefined,
	brandsOptions: [],
});

export default function UploadPageForm({ toggleState, handleTogglePaginate, toggleuploadType }) {
	const getBaseEnv = process.env?.REACT_APP_ENV
		? GetEnvironmentString(process.env?.REACT_APP_ENV)
		: 'dev';
	const dispatch = useDispatch();
	const uploadType = toggleuploadType[0];
	const [fcList, setFcList] = useState({});
	// const [brandList, setBrandList] = useState({});
	const [pickersList, setPickersList] = useState({});
	const [, setModalVisible] = toggleState;
	const { auth = {} } = useSelector((state) => state.auth);
	const isAdmin = auth?.user?.is_superuser || false;
	const { me } = useSelector((state) => state.auth);
	const [uploadOrderAdapter, setUploadOrderAdapter] = useState(true);
	const [initialValues, setInitialValues] = useState(
		formInitialValues(uploadType, auth, isAdmin, me)
	);

	// const BUCKET_NAME = 'ripplrwave-test';
	const BUCKET_NAME = process.env?.REACT_APP_BUCKET_NAME;
	const BUCKET_REGION = process.env?.REACT_APP_BUCKET_REGION;
	const IDENTITYPOOL_ID = process.env?.REACT_APP_S3_IDENTITYPOOL_ID;

	useEffect(() => {
		setInitialValues(formInitialValues(uploadType, auth, isAdmin, me));
	}, [uploadType]);

	useEffect(() => {
		setUploadOrderAdapter(true);
		dispatch(getFcOnSearchList({})).then((res) => {
			setFcList(res);
		});
		// dispatch(getBrandOnSearchFilter({})).then((res) => setBrandList(toAsysnOption(res)));
		dispatch(getPickersOnSearchList({})).then((res) =>
			setPickersList(toAsysnPickersOption(res))
		);
	}, [dispatch]);

	const handleDownload = () => {
		dispatch(downloadCollectionformat({ url: 'champ/file/format/collection' }));
	};

	// this dummy request is to override antd upload component action request
	const dummyRequest = ({ onSuccess }) => {
		setTimeout(() => {
			onSuccess('ok');
		}, 0);
	};

	// File events
	const handleupload = ({ file }, setFieldValue) => {
		if (file.status === 'uploading' || file.status === 'done') {
			return setFieldValue('excel', [file]);
		}
		return setFieldValue('excel', []);
	};

	const handleuploadOrder = ({ file }, setFieldValue) => {
		// console.log('file ', file);
		if (file.type === 'text/csv') {
			setFieldValue('excel', [file]);
		} else {
			if (file.status !== 'removed') message.error(`Please upload only .CSV Format`);
			setFieldValue('excel', []);
		}
	};

	// on Select Event
	const handleSelect = (name, value, setFieldValue) => {
		const getValue = isEmpty(value) ? '' : value?.value;
		return setFieldValue(name, getValue);
	};

	const handleMultipleSelect = (name, value, setFieldValue) => {
		const getValue = isEmpty(value) ? [] : value.map((y) => y.value);
		return setFieldValue(name, getValue);
	};

	const handleSalesOrderSubmit = async (values, { resetForm, setSubmitting }) => {
		if (uploadOrderAdapter) {
			setUploadOrderAdapter(false);
			try {
				dispatch(showLoader());
				const { excel = [] } = values;
				const fc_value = values?.fc;
				const brand_value = values?.brand;
				const path = 'salesorder';
				// const timeStamp = new Date().getTime();
				const timeStamp = moment().format('YYYY/MMM/DD/hhA').toLowerCase();
				const timeSeconds = moment().format('HHmmss');

				// const generateFilePath = fileName.reduce((acc, _, index) => {
				// 	const filepath = `dev/${path}/${fc_value}/${brand_value}/${timeStamp}/${fileName[index].name}`;
				// 	acc[filepath] = 0;
				// 	return acc;
				// }, {});

				await excel?.reduce(
					(accPromise, nextfile) =>
						accPromise.then(() => {
							const filePath = `${getBaseEnv}/${path}/${fc_value}/${brand_value}/${timeStamp}/${timeSeconds}_${nextfile.name}`;
							// const isMultipleLastFile =
							// 	fileName.length - 1 === currentIndex && fileName.length > 1;

							config.update({
								region: BUCKET_REGION,
								credentials: new CognitoIdentityCredentials({
									IdentityPoolId: IDENTITYPOOL_ID,
								}),
							});

							new S3({
								apiVersion: '2006-03-01',
								params: { Bucket: BUCKET_NAME },
							}).upload(
								{
									Key: filePath,
									Body: nextfile,
									ACL: 'public-read',
									Metadata: {
										...{ indb: '1' },
										// ...(fileName.length > 1
										// 	? { multifile: JSON.stringify(generateFilePath) }
										// 	: {}),
										hub_code: fc_value.toString(),
										user_id: values.user_id,
										brd_code: brand_value,
										path,
										file_type: path,
										file_ext: 'csv',
										filename: filePath,
										org_file_name: nextfile.name,
									},
								},
								(err) => {
									console.log(err, 'error');
									if (err) {
										message.info(`${nextfile.name}: ${err}`);
										setUploadOrderAdapter(true);
									} else {
										message.info(`${nextfile.name}  Uploaded Successfully`);
										setSubmitting(false);
										resetForm();
										setModalVisible(false);
										handleTogglePaginate();
										setUploadOrderAdapter(true);
										dispatch(hideLoader());
									}
								}
							);
						}),
					Promise.resolve()
				);
				setSubmitting(false);
				// setUploadOrderAdapter(true);
			} catch (e) {
				// resetUpload();
				// setUploadOrderAdapter(true);
				message.error(e?.response);
			}
		}
	};

	const handleSubmit = (values, { resetForm, setSubmitting }) => {
		const formData = new FormData();
		console.log('values', values);
		formData.append('fc', values.fc);
		formData.append('brand', values?.brand);
		formData.append('excel', values.excel[0]?.originFileObj);
		formData.append('fileType', uploadType);

		if (uploadType === 'picklite_order') {
			if (values.non_picking) formData.append('non_picking', values.non_picking);
			else {
				const picker = JSON.stringify(values.pickers);
				formData.append('pickers', picker);
			}
		}

		return dispatch(
			uploadFiles({
				url: UPLOAD_FILE_TYPE_CHOICE.filter((x) => x.id === uploadType)[0].url,
				data: formData,
			})
		)
			.then(() => {
				setSubmitting(false);
				resetForm();
				setModalVisible(false);
				handleTogglePaginate();
				message.info(`File Uploaded Successfully`);
			})
			.catch((error) => {
				console.log('error', error);
				message.error(`Error on file upload`);
			})
			.finally(() => setSubmitting(false));
	};

	return (
		<Formik
			validationSchema={FormSchema}
			initialValues={initialValues}
			initialTouched={{
				pickers: true,
			}}
			onSubmit={
				uploadType === 'picklite_order_adapter' ? handleSalesOrderSubmit : handleSubmit
			}
			enableReinitialize
		>
			{({ values, setFieldValue, errors }) => {
				console.log(errors, values);
				return (
					<Form layout="vertical">
						{!isEmpty(uploadType) && (
							<Box>
								<Box marginBottom="1rem">
									<Text>Upload Parameter</Text>
									<Flex style={{ gap: '24px' }}>
										<FormInput name="fc" label="*FC(s)" style={{ flex: 1 }}>
											<>
												{uploadType === 'picklite_order_adapter' &&
													!isEmpty(fcList) && (
														<AsyncSelect
															showSearch
															name="fc"
															transformOptions={(value) =>
																toAsysnOrderAdapterOption(value)
															}
															className="customDropdown"
															placeholder="Fc Type"
															filterOption={(input, option) =>
																option.children
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >=
																0
															}
															onChange={(value, options) => {
																handleSelect(
																	'fc',
																	value,
																	setFieldValue
																);
																setFieldValue('brand', undefined);
																setFieldValue(
																	'brandsOptions',
																	options?.filter(
																		(x) =>
																			x.value === value.value
																	)[0]?.brands || []
																);
															}}
															fetchOptions={getFcOnSearchList}
															selectOptions={toAsysnOrderAdapterOption(
																fcList
															)}
															value={values?.fc}
															defaultValue={
																isAdmin
																	? undefined
																	: auth?.user?.FCs?.[0]?.code
															}
														/>
													)}
												{uploadType !== 'picklite_order_adapter' &&
													!isEmpty(fcList) && (
														<AsyncSelect
															showSearch
															name="fc"
															className="customDropdown"
															placeholder="Fc Type"
															filterOption={(input, option) =>
																option.children
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >=
																0
															}
															onChange={(value, options) => {
																handleSelect(
																	'fc',
																	value,
																	setFieldValue
																);
																setFieldValue('brand', undefined);
																setFieldValue(
																	'brandsOptions',
																	options?.filter(
																		(x) =>
																			x.value === value.value
																	)[0]?.brands || []
																);
															}}
															value={values?.fc}
															fetchOptions={getFcOnSearchList}
															selectOptions={toAsysnOption(fcList)}
															defaultValue={
																isAdmin
																	? undefined
																	: auth?.user?.FCs?.[0]?.id
															}
														/>
													)}
											</>
										</FormInput>

										<FormInput name="brand" label="*Brand" style={{ flex: 1 }}>
											<FormikSelect
												showSearch
												className="customDropdown"
												name="brand"
												placeholder="Select Brand"
												filterOption={SelectFilterLabelOptions}
												options={
													isAdmin
														? values?.brandsOptions || []
														: auth?.user?.Brands?.map((x) => ({
																label: `${x?.code || ''}: ${
																	x?.name || ''
																}`,
																value:
																	uploadType ===
																	'picklite_order_adapter'
																		? x?.code
																		: x?.id,
														  })) || []
												}
											/>
										</FormInput>
									</Flex>
								</Box>
								<>
									{uploadType === 'picklite_order' && (
										<>
											<Flex alignItems="center" style={{ gap: '24px' }}>
												<FormInput name="non_picking" style={{ flex: 1 }}>
													<Text>Non-Pickers</Text>
													<Switch name="non_picking" />
												</FormInput>

												{!values.non_picking && !isEmpty(pickersList) && (
													<FormInput
														name="pickers"
														label="*Picker(s)"
														style={{ flex: 1 }}
													>
														<AsyncSelect
															showSearch
															mode="multiple"
															name="pickers"
															className="customDropdown"
															placeholder="Picker(s)"
															filterOption={(input, option) =>
																option.children
																	.toLowerCase()
																	.indexOf(input.toLowerCase()) >=
																0
															}
															onChange={(value) =>
																handleMultipleSelect(
																	'pickers',
																	value,
																	setFieldValue
																)
															}
															disabled={values.non_picking}
															fetchOptions={getPickersOnSearchList}
															allowClear
															selectOptions={pickersList}
														/>
													</FormInput>
												)}

												{/* <FormInput
													name="pickers"
													label="*Pickers"
													hidden={!values?.non_picking}
												>
													<Input name="pickers" />
												</FormInput> */}
											</Flex>
											{errors?.pickers && (
												<Text color="red" mb="1rem">
													{errors.pickers}
												</Text>
											)}
										</>
									)}

									{uploadType === 'picklite_collection' && (
										<Box margin="0 0 1rem">
											<Button type="button" onClick={handleDownload}>
												collection Format
											</Button>
										</Box>
									)}
								</>
								<Text marginBottom="1rem">Upload Section</Text>
								<Flex>
									{uploadType === 'picklite_order_adapter' && (
										<UploadWrap
											name="excel"
											fileList={values?.excel}
											beforeUpload={() => false}
											onChange={(file) =>
												handleuploadOrder(file, setFieldValue)
											}
											className="uploadspage-antd"
											// multiple={hasMultiple}
											accept="text/csv"
										>
											<Flex className="uploadspage-antd__textname">
												Upload files Order
											</Flex>
										</UploadWrap>
									)}
									{uploadType !== 'picklite_order_adapter' && (
										<UploadWrap
											name="excel"
											customRequest={dummyRequest}
											onChange={(e) => handleupload(e, setFieldValue)}
											className="uploadspage-antd"
											accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
										>
											<Flex className="uploadspage-antd__textname">
												Upload files
											</Flex>
										</UploadWrap>
									)}
								</Flex>
								{errors?.excel && (
									<Text
										mt="0.5rem"
										textAlign="center"
										style={{ color: theme.colors.error }}
									>
										File is required
									</Text>
								)}
								<Box mt="16px">
									<BtnWrap>
										<StyledSubmitButton disabled={!uploadOrderAdapter}>
											Upload
										</StyledSubmitButton>
									</BtnWrap>
								</Box>
							</Box>
						)}
						<Debug />
					</Form>
				);
			}}
		</Formik>
	);
}

UploadPageForm.propTypes = {
	toggleState: PropTypes.array,
	handleTogglePaginate: PropTypes.func,
	toggleuploadType: PropTypes.array,
};
