/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable */
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { Box, Flex, Text } from 'atoms';
import { Rupee } from 'components';
import {
	SegregatorVerifyIcons,
	SegregatorRejectionIcons,
	CashierVerifyIcons,
	CashierRejectedIcons,
	CashierVerifiedpartialIcons,
	getOnlyDate,
	RTFEyeIconSmall,
} from 'utils';
import { getunique } from 'utils/text';
import { invoiceVerificationStatus } from './helpers';
import DownloadButton from './DownloadButton';

const BoxToolTip = styled(Box)`
	color: red;
	margin-left: 10px;
`;

const StyledSpan = styled.span`
	span {
		display: flex;
		justify-content: flex-end;
		width: 100%;
	}
`;

const returnStatusIcons = (status, rec) => {
	const hasVerified =
		!isEmpty(rec?.verified_by_segregator_id?.toString()) &&
		!isEmpty(rec?.verified_by_cashier_id?.toString());
	const verifiedBySegregator =
		!isEmpty(rec?.verified_by_segregator_id?.toString()) &&
		isEmpty(rec?.verified_by_cashier_id?.toString()) &&
		status === invoiceVerificationStatus.VerifiedBySegregator;
	const rejectedBySegregator =
		!isEmpty(rec?.verified_by_segregator_id?.toString()) &&
		isEmpty(rec?.verified_by_cashier_id?.toString()) &&
		status === invoiceVerificationStatus.RejectedBySegregator;
	const verifiedByCashier = hasVerified && status === invoiceVerificationStatus.VerifiedByCashier;
	const rejectedByCashier = hasVerified && status === invoiceVerificationStatus.RejectedByCashier;
	const partialVerifiedByCashier =
		hasVerified && status === invoiceVerificationStatus.PartialVerifiedByCashier;

	if (verifiedBySegregator) {
		return (
			<Flex>
				<Tooltip title={status}>
					<Box>
						<SegregatorVerifyIcons />
					</Box>
				</Tooltip>
			</Flex>
		);
	}

	if (rejectedBySegregator) {
		return (
			<Flex>
				<Tooltip title={status}>
					<Box>
						<SegregatorRejectionIcons />
					</Box>
				</Tooltip>
			</Flex>
		);
	}

	if (verifiedByCashier) {
		return (
			<Flex>
				<Tooltip title={invoiceVerificationStatus.VerifiedBySegregator}>
					<Box>
						<SegregatorVerifyIcons />
					</Box>
				</Tooltip>
				<Tooltip title={status}>
					<Box>
						<CashierVerifyIcons />
					</Box>
				</Tooltip>
			</Flex>
		);
	}

	if (rejectedByCashier) {
		return (
			<Flex>
				<Tooltip title={invoiceVerificationStatus.VerifiedBySegregator}>
					<Box>
						<SegregatorVerifyIcons />
					</Box>
				</Tooltip>
				<Tooltip title={status}>
					<Box>
						<CashierRejectedIcons />
					</Box>
				</Tooltip>
			</Flex>
		);
	}

	if (partialVerifiedByCashier) {
		return (
			<Flex>
				<Tooltip title={invoiceVerificationStatus.VerifiedBySegregator}>
					<Box>
						<SegregatorVerifyIcons />
					</Box>
				</Tooltip>
				<Tooltip title={status}>
					<Box>
						<CashierVerifiedpartialIcons />
					</Box>
				</Tooltip>
			</Flex>
		);
	}

	return <Flex></Flex>;
};

export const ColumnsList = (status = '') => {
	const changeKey =
		status !== 'assigned'
			? {
					salesMan: ['salesperson_name', 'salesperson_phone_number'],
					oldSalesMan: [
						'old_collection_salesperson_name',
						'old_collection_salesperson_phone_number',
					],
			  }
			: {
					salesMan: [
						'old_collection_salesperson_name',
						'old_collection_salesperson_phone_number',
					],
					oldSalesMan: ['salesperson_name', 'salesperson_phone_number'],
			  };

	return [
		{
			title: 'Salespersons',
			dataIndex: changeKey?.salesMan[0],
			key: changeKey?.salesMan[0],
			render: (name, rec) => (
				<Box>
					<Box>{name}</Box>
					<Box>{rec[changeKey?.salesMan[1]] || '-'}</Box>
				</Box>
			),
		},
		{
			title: 'Beat name',
			dataIndex: 'beat_name',
			key: 'beat_name',
			render: (beat_name) => <Box>{beat_name}</Box>,
		},
		{
			title: 'Assigned To',
			dataIndex: changeKey?.oldSalesMan[0],
			key: changeKey?.oldSalesMan[0],
			status: 'assigned',
			render: (name, rec) => (
				<Box>
					<Box>{name || '-'}</Box>
					<Box>{rec[changeKey?.oldSalesMan[1]] || ''}</Box>
				</Box>
			),
		},
		{
			title: 'Brand',
			dataIndex: 'brand_name',
			key: 'brand_name',
			render: (brand) => <Box>{brand}</Box>,
		},
		{
			title: 'Collectable Day',
			dataIndex: 'collection_date',
			key: 'collection_date',
			render: (data, rec) => (
				<Box>
					<Box>{data || ''}</Box>
					<Box>{rec?.collection_weekday_name}</Box>
				</Box>
			),
		},
		{
			title: 'Assigned Day',
			dataIndex: 'assigned_date',
			key: 'assigned_date',
			status: 'assigned',
			render: (assigned_date, rec) => (
				<Box>
					<Box>{assigned_date || ''}</Box>
					<Box>{rec?.assigned_weekday_name}</Box>
				</Box>
			),
		},
		{
			title: 'Invoice Number',
			dataIndex: 'invoice_number',
			key: 'invoice_number',
			render: (data) => <Box>{data}</Box>,
		},
		{
			title: 'Invoice Date',
			dataIndex: 'invoice_date',
			key: 'invoice_date',
			render: (data) => <Box>{data}</Box>,
		},
		{
			title: 'Age',
			dataIndex: 'invoice_age',
			key: 'invoice_age',
			render: (data, rec) => (
				<Flex>
					{!data ? '0 days' : `${data} days`}{' '}
					{rec.late_fee_flag === 1 && (
						<Tooltip title="Late collection indication">
							<BoxToolTip>
								<WarningOutlined />
							</BoxToolTip>
						</Tooltip>
					)}
				</Flex>
			),
		},
		{
			title: 'Store Name',
			dataIndex: 'store_name',
			key: 'store_name',
			render: (data) => <Box>{data}</Box>,
		},
		{
			title: 'Invoice Value',
			dataIndex: 'invoice_value',
			key: 'invoice_value',
			render: (data) => (
				<Box>
					<Rupee>{data || 0}</Rupee>
				</Box>
			),
		},
		{
			title: 'Outstanding',
			dataIndex: 'outstanding_amount',
			key: 'outstanding_amount',
			status: 'assigned',
			render: (outstanding_amount) => (
				<Box>
					<Rupee>{outstanding_amount || 0}</Rupee>
				</Box>
			),
		},
		{
			title: <Box>Collection Details</Box>,
			dataIndex: 'outstanding_amount',
			key: 'outstanding_amount',
			status: 'assigned',
			render: (_, rec) => (
				<Box>
					<Rupee>{rec?.collected_amount || 0}</Rupee>
				</Box>
			),
		},
		{
			title: 'New Outstanding',
			dataIndex: 'new_outstanding',
			key: 'new_outstanding',
			status: 'assigned',
			render: (new_outstanding) => (
				<Box>
					<Rupee>{new_outstanding || 0}</Rupee>
				</Box>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			status: 'assigned',
			render: (status1) => <Box>{status1 || '-'}</Box>,
		},
		{
			title: 'Verification',
			dataIndex: 'invoice_verification_status',
			key: 'invoice_verification_status',
			status: 'assigned',
			render: (verifyStatus, rec) => (
				<Box style={{ cursor: 'pointer' }}>{returnStatusIcons(verifyStatus, rec)}</Box>
			),
		},
	].filter((x) => isEmpty(x?.status) || x?.status === status);
};

export const getHandoverColumns = () => [
	{
		title: 'Salesperson Details',
		dataIndex: 'salesperson_name',
		key: 'salesperson_name',
		render: (_, record) => (
			<Box>
				{record?.salesman_name}
				<Text>{record?.salesman_mob}</Text>
			</Box>
		),
	},
	{
		title: 'Brand',
		dataIndex: 'brand_name',
		key: 'brand_name',
		render: (brand_name) => <Box>{brand_name}</Box>,
	},
	{
		title: 'Collectable Date',
		dataIndex: 'collection_date',
		key: 'collection_date',
		align: 'center',
		render: (collection_date) => <Box>{collection_date}</Box>,
	},

	{
		title: 'Assigned Inv Count',
		dataIndex: 'allocated_invoice_count',
		key: 'allocated_invoice_count',
		align: 'center',
		render: (allocated_invoice_count) => <Box>{allocated_invoice_count}</Box>,
	},
	{
		title: 'Total Outstanding',
		dataIndex: 'total_outstanding',
		key: 'total_outstanding',
		align: 'center',
		render: (total_outstanding) => (
			<Box>
				<Rupee>{Number(total_outstanding).toFixed()}</Rupee>
			</Box>
		),
	},
	{
		title: '',
		dataIndex: 'salesperson_id',
		key: 'salesperson_id',
		align: 'right',
		render: (_, record) => (
			<Link
				to={`/allocation/salesman-allocation-details?salesperson_id=${record?.salesman_id}&salesperson_name=${record?.salesman_name}&collection_date=${record?.collection_date}&brand_id=${record?.brand_id}`}
			>
				<StyledSpan>
					<RTFEyeIconSmall />
				</StyledSpan>
			</Link>
		),
	},
	{
		title: 'Action',
		dataIndex: 'action',
		key: 'action',
		align: 'center',
		render: (_, record) => <DownloadButton record={record} />,
	},
];
export const AccordianColumns = (sourceData = [], status = '') =>
	[
		{
			title: 'FC',
			dataIndex: 'FC_name',
			key: 'FC_name',
			render: (fc) => (
				<Box>
					<Box>{fc || ''}</Box>
				</Box>
			),
		},
		{
			title: 'Salesperson',
			dataIndex: 'salesperson_name',
			key: 'salesperson_name',
			render: (name, rec) => (
				<Box>
					<Box>{name}</Box>
					<Box>{rec?.salesperson_phone_number || '-'}</Box>
				</Box>
			),
		},
		{
			title: 'Brand',
			dataIndex: 'brand_name',
			key: 'brand_name',
			filters: getunique(sourceData, 'brand_name'),
			onFilter: (value, record) => record?.brand_name?.startsWith(value),
			render: (brand_name) => (
				<Box>
					<Box>{brand_name || ''}</Box>
				</Box>
			),
		},
		{
			title: 'Bill Status',
			dataIndex: 'bill_status',
			key: 'bill_status',
			render: (bill_status) => (
				<Box>
					<Box>{bill_status || ''}</Box>
				</Box>
			),
		},
		{
			title: 'Current Oustanding',
			dataIndex: 'current_outstanding_amount',
			key: 'current_outstanding_amount',
			render: (current_outstanding_amount) => (
				<Box>
					<Rupee>{current_outstanding_amount || 0}</Rupee>
				</Box>
			),
		},
		{
			title: 'OBC Oustanding',
			dataIndex: 'obc_outstanding_amount',
			key: 'obc_outstanding_amount',
			render: (obc_outstanding_amount) => (
				<Box>
					<Rupee>{obc_outstanding_amount || 0}</Rupee>
				</Box>
			),
		},
		{
			title: 'Invoice Number',
			dataIndex: 'invoice_number',
			key: 'invoice_number',
			render: (invoice_number) => (
				<Box>
					<Box>{invoice_number || ''}</Box>
				</Box>
			),
		},
		{
			title: ' Invoice Date',
			dataIndex: 'invoice_date',
			key: 'invoice_date',
			render: (invoice_date) => (
				<Box>
					<Box>{invoice_date || '-'}</Box>
				</Box>
			),
		},
		{
			title: 'Age',
			dataIndex: 'invoice_age',
			key: 'invoice_age',
			render: (invoice_age) => (
				<Box>
					<Box>{invoice_age || '0'} days</Box>
				</Box>
			),
		},
		{
			title: 'Collection Date',
			dataIndex: 'collection_date',
			key: 'collection_date',
			status: ['unverified'],
			render: (collection_date) => (
				<Box>
					<Box>{collection_date || '-'}</Box>
				</Box>
			),
		},
		{
			title: 'Order Status',
			dataIndex: 'order_status_description',
			key: 'order_status_description',
			status: ['undelivered', 'unverified'],
			render: (order_status_description) => (
				<Box>
					<Box>{order_status_description || '-'}</Box>
				</Box>
			),
		},
		{
			title: 'Verification Status',
			dataIndex: 'vc_invoice_verification_status',
			key: 'vc_invoice_verification_status',
			status: ['unverified'],
			render: (vc_invoice_verification_status) => (
				<Box>
					<Box>{vc_invoice_verification_status || 'Pending'}</Box>
				</Box>
			),
		},
	].filter((x) => x?.status?.includes(status) || isEmpty(x?.status));

const invsettled = {
	color: 'green',
};

const getStatus = (status) => {
	console.log(status);
	if (status === 'invoice_settled') {
		return <div style={invsettled}>Invoice Settled</div>;
	}
	return '';
};
export const InvoiceSettledColumns = (sourceData = [], status = '') =>
	[
		{
			title: 'FC',
			dataIndex: 'FC_name',
			key: 'FC_name',
			width: '5%',
			render: (fc) => (
				<Box>
					<Box>{fc || ''}</Box>
					<Box></Box>
				</Box>
			),
		},
		{
			title: 'Brand',
			dataIndex: 'brand_name',
			key: 'brand_name',
			width: '6%',
			filters: getunique(sourceData, 'brand_name'),
			onFilter: (value, record) => record?.brand_name?.startsWith(value),
			render: (brand_name) => (
				<Box>
					<Box>{brand_name || ''}</Box>
					<Box></Box>
				</Box>
			),
		},
		{
			title: 'Salesperson',
			dataIndex: 'salesperson_name',
			key: 'salesperson_name',
			width: '9%',
			render: (name, rec) => (
				<Box>
					<Box>{name}</Box>
					<Box>{rec?.salesperson_phone_number || '-'}</Box>
					<Box></Box>
				</Box>
			),
		},
		{
			title: 'Invoice Number',
			dataIndex: 'invoice_number',
			key: 'invoice_number',
			width: '15%',
			render: (invoice_number, record) => (
				<Box>
					<Box>{invoice_number || ''}</Box>
					<Box>{getStatus(record?.collection_order_status)}</Box>
				</Box>
			),
		},
		{
			title: 'Current Oustanding',
			dataIndex: 'current_outstanding_amount',
			key: 'current_outstanding_amount',
			width: '6%',
			render: (current_outstanding_amount) => (
				<Box>
					<Rupee>{current_outstanding_amount || 0}</Rupee>
					<Box></Box>
				</Box>
			),
		},
		{
			title: 'OBC Oustanding',
			dataIndex: 'obc_outstanding_amount',
			key: 'obc_outstanding_amount',
			width: '10%',
			render: (obc_outstanding_amount) => (
				<Box>
					<Rupee>{obc_outstanding_amount || 0}</Rupee>
					<Box></Box>
				</Box>
			),
		},
		{
			title: 'Last Collection Date',
			dataIndex: 'collection_date',
			key: 'collection_date',
			width: '10%',
			render: (collection_date) => (
				<Box>
					<Box>{collection_date || '-'}</Box>
					<Box></Box>
				</Box>
			),
		},
		{
			title: 'Verification Status',
			dataIndex: 'invoice_verification_status',
			key: 'invoice_verification_status',
			width: '10%',
			render: (invoice_verification_status) => (
				<Box>
					<Box>{invoice_verification_status || '-'}</Box>
					<Box></Box>
				</Box>
			),
		},
		{
			title: 'Verification Date',
			dataIndex: 'verified_by_cashier_at',
			key: 'verified_by_cashier_at',
			width: '6%',
			render: (verified_by_cashier_at, record) => (
				<Box>
					<Box>{getOnlyDate(verified_by_cashier_at, record)}</Box>
					<Box></Box>
				</Box>
			),
		},
	].filter((x) => x?.status?.includes(status) || isEmpty(x?.status));
