/* eslint-disable sonarjs/cognitive-complexity */
export const salesReturnShowPanel = ({ showData = {} }) => [
	{
		title: 'FC',
		value: showData?.FC?.name || '',
		additionalValue: showData?.FC?.code || '',
	},
	{
		title: 'Return No',
		value: showData?.return_no || '',
		type: 'upper',
	},
	{
		title: 'Return Date',
		type: 'date',
		value: showData?.return_date || '',
	},
	{
		title: 'Invoice No',
		value: showData?.Order?.invoice_no || '',
		type: 'upper',
	},
	{
		title: 'Return Value',
		type: 'rupee',
		value: showData?.total_net_amount || '',
	},
	{
		title: 'Uploaded by',
		value: showData?.File?.FileUploadedBy?.full_name || '',
	},
	{
		title: 'Uploaded Date',
		value: showData?.updatedAt || '',
		type: 'date',
	},
];
