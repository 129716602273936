import { Col, Row } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Link, useNavigate, useSearchParams, useLocation, Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Flex, LoginInput } from 'atoms';
import { IconLabel } from 'components/common';
import PasswordIcon from 'assets/password.svg';
import { resetPassword } from 'store/authSlice';

export const LoginContainer = styled(Flex)`
	height: 450px;
	width: 100%;
	background-color: ${(props) => props.theme.colors.Cultured};
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(34px);
`;

const NavigateText = styled(Link)`
	display: block;
	color: ${(props) => props.theme.colors.primary};
	font-size: 0.75rem;
	margin-top: 3rem;
	text-align: center;
`;

export const StyledSubmitButton = styled(SubmitButton)`
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	font-size: 24px;
	line-height: 60px;
	border: 0;
	width: 337px;
	height: 60px;
	margin-top: 50px;
	background-color: ${(props) => props.theme.colors.primary};
	color: ${(props) => props.theme.colors.white};
	&:hover,
	&:active {
		background-color: ${(props) => props.theme.colors.primary};
		color: ${(props) => props.theme.colors.white};
	}
`;

const FormSchema = Yup.object({
	password: Yup.string().min(6).required().label('Password'),
	confirmPassword: Yup.string().when('password', {
		is: (val) => !!(val && val.length > 0),
		then: Yup.string().oneOf(
			[Yup.ref('password')],
			'password and confirm password need to be the same'
		),
	}),
});

const initialValues = {
	password: undefined,
	confirmPassword: undefined,
};
function ResetPasswordForm() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [searchParams] = useSearchParams();

	if (!searchParams.get('id')) {
		/**
		 * on reset page, in url if we didn't get id param, then redirect to login page
		 */
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	function handleSubmit(values, { setErrors, resetForm, setSubmitting }) {
		dispatch(
			resetPassword({ password: values.password, token: searchParams.get('id') }, setErrors)
		)
			.then(() => {
				setSubmitting(false);
				resetForm();
				setTimeout(() => {
					navigate('/login');
				}, 0);
			})
			.catch((e) => {
				setSubmitting(false);
				console.log('reset Password form catch', e);
			})
			.finally(() => {
				setSubmitting(false);
			});
	}

	return (
		<LoginContainer>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={FormSchema}
				onSubmit={handleSubmit}
			>
				{({ isSubmitting }) => (
					<Form layout="vertical">
						<Row gutter={8} justify="space-between">
							<Col span={24}>
								<LoginInput
									name="password"
									label={<IconLabel label="Password" icon={PasswordIcon} />}
									className="password-field"
								>
									<Input.Password name="password" placeholder="New Password" />
								</LoginInput>
							</Col>
						</Row>

						<Row gutter={8} justify="space-between">
							<Col span={24}>
								<LoginInput
									name="confirmPassword"
									label={
										<IconLabel label="Confirm Password" icon={PasswordIcon} />
									}
									className="password-field"
								>
									<Input.Password
										name="confirmPassword"
										placeholder="Confirm Password"
									/>
								</LoginInput>
							</Col>
						</Row>

						<Row gutter={8} justify="space-between">
							<Col span={24}>
								<StyledSubmitButton disabled={isSubmitting}>
									Reset Password
								</StyledSubmitButton>
							</Col>
						</Row>

						<NavigateText to="/login">Back To Login</NavigateText>
					</Form>
				)}
			</Formik>
		</LoginContainer>
	);
}

export default ResetPasswordForm;
