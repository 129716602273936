import { React } from 'react';
import { WarningOutlined } from '@ant-design/icons';
import { Box, Text } from 'atoms';
import DateFormat from 'components/DateFormat';

const add_info_function = (info) =>
	info.status === 'FAILED' ? (
		<Box style={{ color: 'red' }} className="yes">
			<WarningOutlined />
		</Box>
	) : (
		''
	);

export const WmsLogsTableConfig = ({ showModal, getErrorData }) => [
	{
		title: 'Invoice Id',
		dataIndex: 'invoice_id',
		key: 'invoice_id',
	},
	{
		title: 'Fc Code',
		dataIndex: 'fc_code',
		key: 'fc_code',
		render: (fc_code) => <Text textTransform="uppercase">{fc_code}</Text>,
	},
	{
		title: 'Brand Code',
		dataIndex: 'brand_code',
		key: 'brand_code',
		render: (brand_code) => <Text textTransform="uppercase">{brand_code}</Text>,
	},
	{
		title: 'Invoice Date',
		key: 'invoice_date',
		dataIndex: 'invoice_date',
		sorter: (a, b) => a.x - b.x,
		render: (x) => (
			<Text overFlow="hidden" fontSize="12px" color="#6B6B6B" padding="5px">
				<DateFormat date={x} format="HMSA" />
			</Text>
		),
	},
	{
		title: 'WMS Order Id',
		key: 'stockone_order_id',
		dataIndex: 'stockone_order_id',
	},
	{
		title: 'WMS Status',
		key: 'status',
		dataIndex: 'status',
	},
	{
		title: 'Additional Info',
		key: 'status',
		dataIndex: 'status',
		render: (_, record) => (
			<Box
				onClick={() => {
					showModal();
					getErrorData(record);
				}}
			>
				{add_info_function(record)}
			</Box>
		),
	},
];
