import { createSlice } from '@reduxjs/toolkit';
import { API } from 'api';
const initialState = {};

export const inventoryBatchSlice = createSlice({
	name: 'inventoryBatch',
	initialState,
	reducers: {},
});

export const getInventoryBatchList =
	({ offset, limit, sort = { sort_column: 'id', sort_direction: 'DESC' } }) =>
	async () => {
		const method = 'post';
		const url = '/champ/inventory/current_saleable_inventory_batch_wise';

		const CREDENTIALS = {
			url,
			method,
			data: {
				sort,
				filter: { user_fcs_only: true, user_brands_only: true },
				page: { offset, limit },
			},
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getInventoryProductList =
	({ offset, limit, sort = { sort_column: 'id', sort_direction: 'DESC' } }) =>
	async () => {
		const method = 'post';
		const url = '/champ/inventory/current_saleable_inventory_product_wise';

		const CREDENTIALS = {
			url,
			method,
			data: {
				sort,
				filter: { user_fcs_only: true, user_brands_only: true },
				page: { offset, limit },
			},
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getInventoryUnsaleableBatchList =
	({ offset, limit, sort = { sort_column: 'id', sort_direction: 'DESC' } }) =>
	async () => {
		const method = 'post';
		const url = '/champ/inventory/current_unsaleable_inventory_batch_wise';

		const CREDENTIALS = {
			url,
			method,
			data: {
				sort,
				filter: { user_fcs_only: true, user_brands_only: true },
				page: { offset, limit },
			},
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getInventoryUnsaleableProductList =
	({ offset, limit, sort = { sort_column: 'id', sort_direction: 'DESC' } }) =>
	async () => {
		const method = 'post';
		const url = '/champ/inventory/current_unsaleable_inventory_product_wise';

		const CREDENTIALS = {
			url,
			method,
			data: {
				sort,
				filter: { user_fcs_only: true, user_brands_only: true },
				page: { offset, limit },
			},
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export default inventoryBatchSlice.reducer;
