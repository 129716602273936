import styled from 'styled-components';
import { SubmitButton, Select as AntSelect } from 'formik-antd';
import PropTypes from 'prop-types';
import { Box, FormInput, Flex, Text } from 'atoms';

const StyledSubmitButton = styled(SubmitButton)`
	border: 0;
	background-color: ${(props) => props.theme.colors.primary};
	color: ${(props) => props.theme.colors.white};
	width: 140px;
	height: 40px;
	&:hover {
		background-color: ${(props) => props.theme.colors.primary};
		color: ${(props) => props.theme.colors.white};
	}
`;

const LabelTextInv = styled(Box)`
	font-size: 12px;
	line-height: 24px;
	margin-bottom: 5px;
	color: #352a35;
	text-decoration: underline;
	padding: 0 5px 0 15px;
	align-self: center;
	margin-left: auto;
	cursor: pointer;
`;

const ValueTextInv = styled(Box)`
	font-size: 14px;
	line-height: 19px;
	color: #000000;
	align-self: center;
	flex: 0 0 8%;
`;

const DatePickerWrapper = styled(FormInput)`
	input {
		border: 0;
		height: 40px;
	}
`;

const Select = styled(AntSelect)`
	.ant-select-selector {
		border: 1px solid #edebe9 !important;
		background-color: #faf9f8 !important;
		border-radius: 4px !important;
		padding-top: 5px !important;
		min-height: 40px;
	}
`;

function DuplicateError({ error = '' }) {
	return (
		<Flex justifyContent="center" alignItems="center">
			<Text as="p" color="#ff4d4f" fontSize="12px" maxWidth="250px" textAlign="center">
				{error}
			</Text>
		</Flex>
	);
}

export {
	StyledSubmitButton,
	LabelTextInv,
	ValueTextInv,
	DatePickerWrapper,
	Select,
	DuplicateError,
};

DuplicateError.propTypes = {
	error: PropTypes.string,
};
