import React from 'react';
import PropTypes from 'prop-types';
import { Image, Flex, Text } from 'atoms';

export default function IconLabel({ icon, label }) {
	return (
		<Flex justifyContent="center" alignItems="center">
			<Image src={icon} alt={label} width={10} height={10} />
			<Text as="span" pl="5px">
				{label}
			</Text>
		</Flex>
	);
}

IconLabel.propTypes = {
	label: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
};
