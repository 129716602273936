import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'atoms';
import { theme } from 'styles/theme';

export function ErrorText({ errorString, style }) {
	return (
		<Text mt="0.5rem" textAlign="center" color={theme.colors.error} style={style}>
			{errorString}
		</Text>
	);
}

ErrorText.propTypes = {
	errorString: PropTypes.string,
	style: PropTypes.object,
};
