import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Box, Flex, Image } from 'atoms';
import { LogoutModal } from 'components/modals';
import { useUnAuthRoute } from 'hooks';
import { NavLinks } from 'components/common/sidebar';
import { toggleSidebar } from 'store/appSlice';
import RipplrLogoWhite from 'assets/ripplr-logo-white.svg';
import RipplrWhiteLogo from 'assets/ripplr-white-logo.svg';

export const Nav = styled.nav`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 111;
	max-width: ${(props) => (props?.sideBarStatus ? '40px' : '210px')};
	width: 100%;
	height: 100vh;
	background-color: ${(props) => props.theme.colors.primary};
	transition: 0.5s;
	overflow-y: auto;
	overflow-x: hidden;
`;

export const Upload = styled(Box)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 120px;
	height: 40px;
	margin: 25px 0;
	border: 1px solid ${(props) => props.theme.colors.EagleGreen};
	background-color: ${(props) => props.theme.colors.MidnightGreen};
	font-size: 14px;
	font-weight: 300;
	color: ${(props) => props.theme.colors.white};
	line-height: 40px;
	text-align: center;
	cursor: pointer;
`;

const SideBarToggle = styled(Button)`
	font-size: 20px;
	margin-bottom: 6px;
	color: #ffffff !important;
	background: rgba(0, 0, 0, 0) !important;
	border: none;
	margin-right: 5px;
	&:hover {
		transform: scale(1.1);
	}
`;

export const Close = styled(Box)`
	display: flex;
	position: fixed;
	bottom: 30px;
`;

export default function SideBar() {
	const isUnAuth = useUnAuthRoute();
	const dispatch = useDispatch();
	const { sideBarStatus } = useSelector((state) => state.app);
	if (isUnAuth) {
		return null;
	}

	return (
		<Nav sideBarStatus={sideBarStatus}>
			<Flex justifyContent="center" width="100%" mt="1rem">
				<Image
					width={150}
					src={sideBarStatus ? RipplrWhiteLogo : RipplrLogoWhite}
					alt="home"
				/>
			</Flex>
			<Flex
				position="relative"
				justifyContent="center"
				flexDirection="column"
				alignItems="center"
				height="90%"
			>
				<Box height="calc(90vh - 37px)" width="100%" borderTop="1px solid #457b9d">
					<NavLinks />
				</Box>
				<Close>
					<SideBarToggle
						type="link"
						onClick={() => dispatch(toggleSidebar(!sideBarStatus))}
					>
						{sideBarStatus ? <RightOutlined /> : <LeftOutlined />}
						{!sideBarStatus && <span>Collapse</span>}
					</SideBarToggle>
				</Close>
			</Flex>
			<LogoutModal />
		</Nav>
	);
}
