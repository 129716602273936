import { Text, Flex } from 'atoms';
import Rupee from 'components/Rupee';
import DateFormat from 'components/DateFormat';
import { getstatus } from 'utils/tableConfig/helpers';

// const taxableAmountField = (invoiceAmount = 0, totalTaxAmount = 0) => {
// 	const amount = parseInt(invoiceAmount, 10) - parseInt(totalTaxAmount, 10);
// 	return amount ? Math.round(amount) : 0;
// };

const statusDeliveryAmount = ['PD', 'DL'];

const showDeliveryAmount = (netamt, status) => (statusDeliveryAmount.includes(status) ? netamt : 0);

// const outStandingAmt = ['CA'];

// const showoutStandingAmt = (outstandingamt, status) => {
// 	console.log(outstandingamt, status);

// 	return outStandingAmt.includes(status) === true
// 		? 0
// 		: showDeliveryAmount(outstandingamt, status);
// };

// const invoiceDisplayValue = (salesOrderList) =>
// 	statusDeliveryAmount.includes(salesOrderList?.status)
// 		? salesOrderList?.OutstandingInvoice?.invoice_amount
// 		: 0;

export const unBlockOrderColumns = ({ defaultSort, defaultsortField }) => {
	const sortDirection = defaultSort === 'ASC' ? 'ascend' : 'descend';
	return [
		{
			title: 'Invoice Date',
			dataIndex: 'invoice_date',
			key: 'invoice_date',
			sortId: 1,
			overflow: 'hidden',
			hidden: false,
			sortOrder: defaultsortField === 'invoice_date' ? sortDirection : false,
			sorter: (a, b) => a.invoice_date - b.invoice_date,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={record?.invoice_date} />
				</Text>
			),
		},
		{
			title: 'FC',
			dataIndex: 'FC.name',
			key: 'FC.name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Flex flexDirection="column">
					<Text overFlow="hidden" fontSize="12px" color="#000">
						{record?.FC?.name}
					</Text>
					<Text overFlow="hidden" fontSize="10px" color="#000">
						({record?.FC?.code}: {record?.Brand?.name})
					</Text>
				</Flex>
			),
		},
		{
			title: 'Invoice No',
			dataIndex: 'invoice_no',
			key: 'invoice_no',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{record?.invoice_no}
				</Text>
			),
		},
		// https://ripplr.atlassian.net/browse/RPC-1076 - removed GST, IRN columns
		// {
		// 	title: 'GST No.',
		// 	dataIndex: 'gst_number',
		// 	key: 'gst_number',
		// 	sortId: 2,
		// 	overflow: 'hidden',
		// 	hidden: false,
		// 	render: (gst_number) => (
		// 		<Text
		// 			overFlowWrap="break-word"
		// 			fontSize="12px"
		// 			color="#000"
		// 			textTransform="uppercase"
		// 		>
		// 			{gst_number || ''}
		// 		</Text>
		// 	),
		// },
		// {
		// 	title: 'IRN',
		// 	dataIndex: 'irn',
		// 	key: 'irn',
		// 	sortId: 2,
		// 	overflow: 'hidden',
		// 	hidden: false,
		// 	render: (irn) => (
		// 		<Text
		// 			style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}
		// 			overFlowWrap="break-word"
		// 			fontSize="12px"
		// 			color="#000"
		// 			maxWidth="150px"
		// 			textTransform="uppercase"
		// 		>
		// 			{irn || ''}
		// 		</Text>
		// 	),
		// },
		{
			title: 'Store',
			dataIndex: 'name',
			key: 'name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text
					overFlowWrap="break-word"
					fontSize="12px"
					color="#000"
					textTransform="uppercase"
				>
					{record?.Store?.name}
				</Text>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => {
				const getAstatus = getstatus(a?.status);
				const getBstatus = getstatus(b?.status);
				if (getAstatus < getBstatus) {
					return -1;
				}
				if (getAstatus > getBstatus) {
					return 1;
				}
				return 0;
			},
			sortOrder: defaultsortField === 'status' ? sortDirection : false,
			render: (_, record) => (
				<Text overFlowWrap="break-word" fontSize="12px" color="#000">
					{getstatus(record?.status)}
				</Text>
			),
		},
		// {
		// 	title: 'Gross Amt',
		// 	dataIndex: 'total_gross_amount',
		// 	key: 'total_gross_amount',
		// 	sortId: 2,
		// 	overflow: 'hidden',
		// 	hidden: false,
		// 	render: (_, record) => (
		// 		<Text overFlow="hidden" fontSize="12px" color="#000">
		// 			<Rupee roundOff={0}>{record?.total_gross_amount}</Rupee>
		// 		</Text>
		// 	),
		// },
		// {
		// 	title: 'Total Disc.',
		// 	dataIndex: 'total_discount',
		// 	key: 'total_discount',
		// 	sortId: 2,
		// 	overflow: 'hidden',
		// 	hidden: false,
		// 	render: (_, record) => (
		// 		<Text overFlow="hidden" fontSize="12px" color="#000">
		// 			<Rupee roundOff={0}>{record?.total_discount}</Rupee>
		// 		</Text>
		// 	),
		// },
		// {
		// 	title: 'Tax Amt',
		// 	dataIndex: 'total_tax_amount',
		// 	key: 'total_tax_amount',
		// 	sortId: 2,
		// 	overflow: 'hidden',
		// 	hidden: false,
		// 	render: (_, record) => (
		// 		<Text overFlow="hidden" fontSize="12px" color="#000">
		// 			<Rupee roundOff={0}>{record?.total_tax_amount}</Rupee>
		// 		</Text>
		// 	),
		// },
		// {
		// 	title: 'Taxable Amt',
		// 	dataIndex: 'total_net_amount',
		// 	key: 'total_net_amount',
		// 	sortId: 2,
		// 	overflow: 'hidden',
		// 	hidden: false,
		// 	render: (_, record) => (
		// 		<Text overFlow="hidden" fontSize="12px" color="#000">
		// 			<Rupee roundOff={0}>
		// 				{taxableAmountField(
		// 					record?.OutstandingInvoice?.invoice_amount,
		// 					record?.total_tax_amount
		// 				)}
		// 			</Rupee>
		// 		</Text>
		// 	),
		// },
		// {
		// 	title: 'Net Amt',
		// 	dataIndex: 'total_net_amount',
		// 	key: 'total_net_amount',
		// 	sortId: 2,
		// 	overflow: 'hidden',
		// 	hidden: false,
		// 	render: (_, record) => (
		// 		<Text overFlow="hidden" fontSize="12px" color="#000">
		// 			<Rupee roundOff={0}>{record?.total_net_amount}</Rupee>
		// 		</Text>
		// 	),
		// },
		{
			title: 'Invoice Amt',
			dataIndex: 'invoice_amount',
			key: 'invoice_amount',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<Rupee roundOff={0}>{record?.OutstandingInvoice?.invoice_amount}</Rupee>
				</Text>
			),
		},
		{
			title: 'Delivered Amt',
			dataIndex: 'total_net_amount',
			key: 'total_net_amount',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<Rupee roundOff={0}>
						{showDeliveryAmount(
							record?.OutstandingInvoice?.initial_outstanding_amount,
							record?.status
						)}
						{/* {record?.OutstandingInvoice?.initial_outstanding_amount || '0'} */}
					</Rupee>
				</Text>
			),
		},
		{
			title: 'Collected Amt',
			dataIndex: 'payment_amount',
			key: 'payment_amount',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<Rupee roundOff={0}>
						{record?.OutstandingInvoice?.collected_amount || '0'}
					</Rupee>
				</Text>
			),
		},
		{
			title: 'Outstanding Amt',
			dataIndex: 'current_outstanding_amount',
			key: 'current_outstanding_amount',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<Rupee roundOff={0}>
						{showDeliveryAmount(
							record?.OutstandingInvoice?.current_outstanding_amount,
							record?.status
						)}
						{/* {record?.OutstandingInvoice?.current_outstanding_amount || 0} */}
					</Rupee>
				</Text>
			),
		},
		{
			title: 'Status',
			dataIndex: 'tags',
			key: 'tags',
			sortId: 6,
			hidden: false,
			render: () => (
				<div
					style={{
						background: '#E9E9E9',
						color: '#2F2F2F',
						borderRadius: 4,
						padding: '6px 16px',
						textAlign: 'center',
					}}
					disabled
				>
					Unblock
				</div>
			),
		},
	];
};
