import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SubmitButton } from 'formik-antd';
import { Flex } from 'atoms';

export const LoginContainer = styled(Flex)`
	height: 450px;
	width: 100%;
	background-color: ${(props) => props.theme.colors.Cultured};
	border-radius: 20px;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(34px);
`;

export const StyledSubmitButton = styled(SubmitButton)`
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	font-size: 24px;
	line-height: normal;

	border: 0;
	width: 337px;
	height: 60px;
	margin-top: 50px;
	background-color: ${(props) => props.theme.colors.primary};
	color: ${(props) => props.theme.colors.white};
	&:hover,
	&:active {
		background-color: ${(props) => props.theme.colors.primary};
		color: ${(props) => props.theme.colors.white};
	}
`;

export const ForgotPassword = styled(Link)`
	display: block;
	color: ${(props) => props.theme.colors.primary};
	font-size: 0.75rem;
	margin-top: 0.6rem;
	text-align: right;
`;

export const NavigateText = styled(Link)`
	display: block;
	color: ${(props) => props.theme.colors.primary};
	font-size: 0.75rem;
	margin-top: 3rem;
	text-align: center;
`;
