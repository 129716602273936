import styled from 'styled-components';
import * as Yup from 'yup';
import moment from 'moment';
import { SubmitButton } from 'formik-antd';
import { Flex } from 'atoms';

export const BtnWrap = styled(Flex)`
	margin-top: 1.5rem;
`;

export const StyledSubmitButton = styled(SubmitButton)`
	border: 0;
	background-color: ${(props) => props.theme.colors.primary};
	color: ${(props) => props.theme.colors.white};
	&:hover {
		background-color: ${(props) => props.theme.colors.primary};
		color: ${(props) => props.theme.colors.white};
	}
`;

export const StyledCloseButton = styled.button`
	border: 0;
	cursor: pointer;
	background-color: ${(props) => props.theme.colors.white};
	color: ${(props) => props.theme.colors.primary};
	border: 1px solid ${(props) => props.theme.colors.primary};
	min-width: 78px;
	min-height: 33px;
`;

// #region banks config starts here

export const BankFormSchema = Yup.object({
	name: Yup.string()
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
		.required()
		.label('Bank Name')
		.min(3)
		.max(75)
		.trim(''),
	code: Yup.string()
		.matches(/^[aA-zZ0-9\s-_]+$/, 'Please enter valid code')
		.required()
		.label('Bank Code')
		.min(1)
		.max(25)
		.trim(''),
	country: Yup.string()
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
		.required()
		.label('Country')
		.min(2)
		.max(25)
		.trim(''),
});

export const BankInitialValues = {
	name: undefined,
	code: undefined,
	country: undefined,
};

// #chequeBounce
export const chequeForminitialValues = {
	cheque_number: undefined,
	cheque_value: undefined,
	bounce_date: moment().subtract(1, 'days'),
};

export const ChequeBounceFormSchema = Yup.object({
	cheque_number: Yup.string()
		.required()
		.test('len', 'Must be exactly 6 characters', (val) => {
			console.log(val);
			return val?.toString()?.length === 6;
		})
		.label('Cheque Number'),

	cheque_value: Yup.number().label('Cheque Value'),
	bounce_date: Yup.date().required(),
});
// #endregion

// #region storeCategory start here

export const StoreCatinitialValues = (data) => ({
	retail_category: data?.retail_category || undefined,
	retailer_class: data?.retailer_class || undefined,
});

export const STORE_CLASS_CHOICE = [
	{ value: 'A', label: 'Class A' },
	{ value: 'B', label: 'Class B' },
	{ value: 'C', label: 'Class C' },
	{ value: 'D', label: 'Class D' },
];

// form schema
export const StoreCategoryFormSchema = Yup.object({
	retail_category: Yup.string()
		.matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
		.required()
		.min(3)
		.max(255)
		.label('Store Category Name')
		.trim(''),
	retailer_class: Yup.string().required().label('Store Class'),
});

// #endregion
