import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'atoms';
const Container = styled(Flex)`
	width: 85%;
	justify-content: space-around;
`;

const KeyText = styled.p`
	font-size: 10px;
	font-weight: 400;
	line-height: 13px;
	color: ${(props) => props.theme.colors.SonicSilver};
	text-align: center;
`;

const ValueText = styled.p`
	font-size: 14px;
	font-weight: 400;
	line-height: 19px;
	margin-top: 0.4rem;
`;

const SubValue = styled(KeyText)`
	margin-top: 0.2rem;
	text-align: left;
	color: ${(props) => props.theme.colors.SoftGray};
`;
export default function SalesmanHeader() {
	return (
		<Container>
			<Box>
				<KeyText>Store Name</KeyText>
				<ValueText>Sri Manjunatha Store</ValueText>
				<SubValue>STR1234</SubValue>
			</Box>

			<Box width="50%">
				<KeyText>Address</KeyText>
				<ValueText>13th cross 8th Main HSR layout Sector 1 Bengaluru-560029</ValueText>
			</Box>

			<Box>
				<KeyText>Salesman</KeyText>
				<ValueText>-------</ValueText>
			</Box>
		</Container>
	);
}
