import React from 'react';
import styled from 'styled-components';

const Arrow = styled.span`
	margin: 0 0.3rem;
	font-size: '14px';
	color: ${(props) => props.theme.colors.Indigo};
`;
export function BreadArrow() {
	return <Arrow>{'>'}</Arrow>;
}
