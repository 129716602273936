import styled from 'styled-components';
import { Text, Flex } from 'atoms';
import Rupee from 'components/Rupee';
import DateFormat from 'components/DateFormat';
import { getstatus } from 'utils/tableConfig/helpers';

const handleColorType = (status) => {
	switch (status) {
		case 'OP':
			return 'color: #713592; background: #fbf3ff;';
		case 'OC':
			return 'color: #036db9; background: #eef8ff;';
		case 'PP':
			return 'color: #f09000; background: #fff6ea;';
		default:
			return 'color: #000; background: #fff;';
	}
};

const ColoredBox = styled(Text)`
	display: flex;
	justify-content: center;
	width: 150px;
	padding: 0.5rem;
	font-size: 12px;
	text-align: center;
	${({ status }) => handleColorType(status)};
`;

export const missingInvoicesColumns = ({ defaultSort, defaultsortField }) => {
	const sortDirection = defaultSort === 'ASC' ? 'ascend' : 'descend';
	return [
		{
			title: 'Invoice Date',
			dataIndex: 'invoice_date',
			key: 'invoice_date',
			sortId: 1,
			overflow: 'hidden',
			hidden: false,
			sortOrder: defaultsortField === 'invoice_date' ? sortDirection : false,
			sorter: (a, b) => a.invoice_date - b.invoice_date,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={record?.invoice_date} />
				</Text>
			),
		},
		{
			title: 'FC',
			dataIndex: 'FC.name',
			key: 'FC.name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Flex flexDirection="column">
					<Text overFlow="hidden" fontSize="12px" color="#000">
						{record?.FC?.name}
					</Text>
					<Text overFlow="hidden" fontSize="10px" color="#000">
						({record?.FC?.code}: {record?.Brand?.name})
					</Text>
				</Flex>
			),
		},
		{
			title: 'Invoice Number',
			dataIndex: 'invoice_no',
			key: 'invoice_no',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{record?.invoice_no}
				</Text>
			),
		},
		{
			title: 'Store Name',
			dataIndex: 'name',
			key: 'name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text
					overFlowWrap="break-word"
					fontSize="12px"
					color="#000"
					textTransform="uppercase"
				>
					{record?.Store?.name}
				</Text>
			),
		},
		{
			title: 'Invoice Amt',
			dataIndex: 'invoice_amount',
			key: 'invoice_amount',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<Rupee roundOff={0}>{record?.OutstandingInvoice?.invoice_amount}</Rupee>
				</Text>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => {
				const getAstatus = getstatus(a?.status);
				const getBstatus = getstatus(b?.status);
				if (getAstatus < getBstatus) {
					return -1;
				}
				if (getAstatus > getBstatus) {
					return 1;
				}
				return 0;
			},
			sortOrder: defaultsortField === 'status' ? sortDirection : false,
			render: (_, record) => (
				<ColoredBox status={record?.status} overFlowWrap="break-word">
					{getstatus(record?.status)}
				</ColoredBox>
			),
		},
	];
};
