import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Text } from 'atoms';

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	button {
		width: 120px;
		height: 40px;
		border-radius: 4px;
		font-size: 14px;
		font-weight: 400;
	}
`;

const OutlinedButton = styled(Button)`
	margin-right: 10px;
	border: 1px solid ${(props) => (props.modalType === 'verify' ? '#5B7F2B' : '#FF4747')};
	color: ${(props) => (props.modalType === 'verify' ? '#5B7F2B' : '#FF4747')};
	background-color: transparent;
`;
const SolidButton = styled(Button)`
	background-color: ${(props) => (props.modalType === 'verify' ? '#5B7F2B' : '#FF4747')};
	color: #fff;
	margin-left: 10px;
`;
const StyledText = styled(Text)`
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 20px;
`;
export default function ConfirmationModal({
	showModal,
	setShowModal,
	modalType,
	handleVerification,
	confirmDelete,
	salesReturn,
}) {
	return (
		<Modal visible={showModal} footer={null} width="432px" closable={false}>
			<StyledText textAlign="center">
				{`Are you sure you want to ${modalType} this ${
					salesReturn ? 'Sales Return' : 'GRN'
				} File?`}
			</StyledText>

			<ButtonContainer>
				<OutlinedButton onClick={() => setShowModal(false)} modalType={modalType}>
					No
				</OutlinedButton>
				<SolidButton
					modalType={modalType}
					onClick={() => {
						if (modalType === 'verify') {
							handleVerification();
						} else {
							confirmDelete();
						}
					}}
				>
					Yes
				</SolidButton>
			</ButtonContainer>
		</Modal>
	);
}

ConfirmationModal.propTypes = {
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
	modalType: PropTypes.string,
	handleVerification: PropTypes.func,
	confirmDelete: PropTypes.func,
	salesReturn: PropTypes.string,
};
