/* eslint-disable no-empty-pattern */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import './PdfThumbnail.css';

// eslint-disable-next-line react/prop-types
export default function PdfThumbnail({ fileUrl }) {
	const defaultLayoutPluginInstance = defaultLayoutPlugin({
		renderToolbar: (Toolbar) => (
			<Toolbar>
				{(toolbarSlot) => {
					const { EnterFullScreen } = toolbarSlot;
					return (
						<>
							<EnterFullScreen />
						</>
					);
				}}
			</Toolbar>
		),
		sidebarTabs: (tabs) => tabs.filter(() => null), // Exclude the bookmark tab
	});

	return (
		<div className="salesorder" style={{ height: '250px' }}>
			<Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
				<Viewer
					fileUrl={fileUrl}
					theme="dark"
					plugins={[defaultLayoutPluginInstance]}
					defaultScale={1.0}
				/>
			</Worker>
		</div>
	);
}
