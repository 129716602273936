import { Input } from 'formik-antd';
import PropTypes from 'prop-types';

export default function CustomInput({ type = 'text', name, onChange = () => {}, ...rest }) {
	return (
		<Input
			{...rest}
			type={type}
			name={name}
			onChange={onChange}
			onFocus={(e) => {
				e.target.autocomplete = 'new-password';
			}}
		/>
	);
}

CustomInput.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
};
