/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { UpCircleFilled } from '@ant-design/icons';
import { message, Collapse } from 'antd';
import styledComponents from 'styled-components';
import * as SVGICONS from 'utils/Svg';
import { Box, Flex, Text } from 'atoms';
import { RCTable } from 'components/common';
import { getUploadViewList, getGrnUploadViewList, getUploadViewDetails } from 'store/UploadSlice';
import {
	UploadViewListColumnsNew,
	GrnUploadViewListColumnsNew,
	UploadShowDetails,
	getFileId,
	BrandSalesReturnListColumns,
} from 'utils';

// const DetailsWrap = styledComponents(Box)`
//   padding: 1rem;
//   background: #ffffff;
//   box-shadow: 0px 2px 1px -1px rgb(145 158 171 / 20%), 0px 1px 1px 0px rgb(145 158 171 / 14%), 0px 1px 3px 0px rgb(145 158 171 / 12%);
// `;

const TextWrap = styledComponents(Box)`
	flex: 0 0 12%;
`;

const CollapseStyling = {
	border: '1px solid #B9B9B9',
	borderLeft: '2px solid #CA5010',
	borderRadius: '2px',
	marginTop: '1rem',
};

const iconPosition = 'right';
const { Panel } = Collapse;

const collapseIcon = ({ isActive }) => (
	<UpCircleFilled
		style={{
			fontSize: '32px',
			color: '#FAF9F8',
			backgroundColor: '#CA5010',
			borderRadius: '50%',
		}}
		rotate={isActive ? 0 : 180}
	/>
);
const onChange = (key) => {
	console.log(key);
};

export function UploadShowPageNew() {
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();

	const [rowData, setRowData] = useState({});
	const [data, setData] = useState({});
	const toggleState = useState({
		query: '',
		offset: 0,
		limit: 10,
		currentPage: 1,
	});

	useEffect(() => {
		if (!isEmpty(id)) {
			dispatch(getUploadViewDetails({ id })).then((res) => {
				setData(res);
				console.log('res ', res);
				// if (res?.fileType === 'sales_return') {
				// 	setShowFailed(true);
				// }
			});
		} else {
			message.error('Something went wrong!');
			navigate('/uploads');
		}
	}, [dispatch, id]);

	console.log(data.fileType, 'toggleState');

	// eslint-disable-next-line consistent-return
	const displayData = () => {
		if (data.fileType === 'picklite_order_adapter') {
			return getUploadViewList;
		}
		if (data.fileType === 'picklite_grn') {
			return getGrnUploadViewList;
		}
	};

	const displayColumns = () => {
		if (data.fileType === 'picklite_order_adapter') {
			return UploadViewListColumnsNew;
		}
		if (data.fileType === 'picklite_grn') {
			return GrnUploadViewListColumnsNew;
		}
		return BrandSalesReturnListColumns;
	};

	// const handleClick = () =>
	// 	dispatch(
	// 		downloadCollectionformat({
	// 			url: 'champ/order/download',
	// 			data: {
	// 				filter: {
	// 					files: [+(id || 0)],
	// 				},
	// 				sort: {
	// 					sort_column: 'id',
	// 					sort_direction: 'ASC',
	// 				},
	// 				page: {
	// 					limit: 100,
	// 					offset: 0,
	// 				},
	// 			},
	// 		})
	// 	);

	return (
		<>
			{/* Upload View - details box */}
			<Collapse
				bordered={false}
				expandIconPosition={iconPosition}
				expandIcon={collapseIcon}
				defaultActiveKey={['1']}
				onChange={onChange}
				style={CollapseStyling}
			>
				<Panel
					header={
						<Flex m={3} style={{ alignItems: 'center' }}>
							<SVGICONS.CsvIcons style={{ marginRight: '0' }} />
							<Box mr={3}>{data?.fileName || ''}</Box>
							<Box>
								{/* <Button onClick={handleClick}>Download</Button> */}
								{getFileId({ data }).map((x) => (
									<TextWrap key={x.id}>
										<Flex alignItems="center" justifyContent="space-around">
											<Text
												color="#5B5B5B"
												fontWeight="600"
												fontSize="12px"
												mr={1}
											>
												{x.label}:{' '}
											</Text>
											<Text fontWeight="400" fontSize="16px">
												{x.value}
											</Text>
										</Flex>
										{/* <Text fontSize="12px">{x.value}</Text> */}
									</TextWrap>
								))}
							</Box>
						</Flex>
					}
					key="1"
				>
					<Flex ml={3} flexWrap="wrap" style={{ gap: '24px' }}>
						{UploadShowDetails({ data }).map((e) => (
							<TextWrap mb={2} key={e.id}>
								<Text mb={3} color="#5B5B5B" fontWeight="600" fontSize="12px">
									{e.label}
								</Text>
								<Text fontWeight="400" fontSize="16px">
									{e.value}
								</Text>
							</TextWrap>
						))}
					</Flex>
				</Panel>
			</Collapse>

			{/* Upload View - Succesfully Uploaded box */}
			<Collapse
				bordered={false}
				expandIconPosition={iconPosition}
				expandIcon={collapseIcon}
				defaultActiveKey={['1']}
				onChange={onChange}
				style={CollapseStyling}
			>
				<Panel
					header={
						<Flex m={3} style={{ alignItems: 'center' }}>
							<SVGICONS.SuccesfullyUploadedIcon />
							<Text style={{ color: '#107C10' }}>Successfully Uploaded</Text>
						</Flex>
					}
					key="1"
				>
					<Box ml={3}>
						{/* <Text mb={3}>Invoice vs Location vs Delivery Code</Text> */}
						<RCTable
							rowKey="id"
							configs={{
								defaultSort: 'DESC',
								defaultsortField: 'id',
								staticPayload: { files: [+(id || 0)] },
							}}
							tableDatas={[rowData, setRowData]}
							togglePaginate={toggleState}
							dispatchAction={displayData()}
							columns={displayColumns()}
						/>
						{/* <Button mt="1rem" onClick={() => navigate(-1)}>
							Back
						</Button> */}
					</Box>
				</Panel>
			</Collapse>
		</>
	);
}
