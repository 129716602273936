import { React } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Box, Flex, Text } from 'atoms';
import { Rupee } from 'components';

export const StyledText = styled(Text)`
	font-size: 12px;
	font-weight: 500;
`;
export const StyledSpan = styled.span`
	font-size: 12px;
	font-weight: 600;
	margin-left: 20px;
`;

const HeadingBox = styled(Flex)`
	flex-direction: column;
	align-items: flex-start;
	flex: 0 0 auto;
`;

const InnerData = styled.div`
	padding: 0 20px;
	border-right: 1px solid #dedede;
`;
const InnerText = styled(Text)`
	font-size: 12px;
	font-weight: 600;
`;

const DateFormat = 'DD-MM-YYYY';
const TIME_FORMAT = 'hh:mm a';

export default function ShowExpandableData({ record }) {
	function getTime(time) {
		const date = moment(time).add(0, 'minutes').format(DateFormat);
		const localTime = moment(time).add(0, 'minutes').format(TIME_FORMAT);
		return (
			<span>
				<StyledSpan>{date}</StyledSpan>
				<StyledSpan style={{ marginLeft: '20px' }}>{localTime}</StyledSpan>
			</span>
		);
	}

	return (
		<Flex style={{ alignItems: 'center' }}>
			<Flex>
				<HeadingBox>
					<StyledText>Invoice No:</StyledText>
					<StyledText>Settled Amt:</StyledText>
				</HeadingBox>
				{record?.invoice_details?.map((ins) => (
					<Flex key={ins.invoice_no}>
						<InnerData>
							<InnerText>{ins.invoice_no}</InnerText>
							<InnerText>
								<Rupee>{ins.settled_amount}</Rupee>
							</InnerText>
						</InnerData>
					</Flex>
				))}
			</Flex>
			{record?.void_cheque_detail?.void_marked_by && (
				<>
					<Box
						style={{
							borderRight: '1px solid #dedede',
							padding: '0px 20px',
						}}
					>
						<StyledText>
							Created On:
							{getTime(record?.void_cheque_detail?.created_at)}
						</StyledText>
						<StyledText>
							Deleted On:
							{getTime(record?.void_cheque_detail?.deleted_at)}
						</StyledText>
					</Box>
					<Box
						style={{
							paddingLeft: '20px',
						}}
					>
						<StyledText>
							Deleted By:
							<StyledSpan>{record?.void_cheque_detail?.void_marked_by}</StyledSpan>
						</StyledText>
						<StyledText>
							Reason to Delete:
							<StyledSpan>
								{record?.void_cheque_detail?.void_cheque_reason}
							</StyledSpan>
						</StyledText>
					</Box>
				</>
			)}
		</Flex>
	);
}

ShowExpandableData.propTypes = {
	record: PropTypes.object,
};
