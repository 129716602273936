import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { BreadArrow, Image } from 'atoms';
import Home from 'assets/Home.svg';
import { toggleSidebar } from 'store/appSlice';

const BreadContainer = styled.div`
	display: flex;
	padding: 0 24px;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;
	font-size: 14px;
`;

const LinkText = styled(Link)`
	color: ${(props) => props.theme.colors.breadcrumb};
	&:hover {
		color: ${(props) => props.theme.colors.breadcrumbHover};
	}
`;

const Menu = styled.div`
	.menuIcon {
		margin: 0 12px;
		display: none;
	}
`;

export default function BreadCrumbs({ urls = [] }) {
	const dispatch = useDispatch();
	const { sideBarStatus } = useSelector((state) => state.app);

	return (
		<BreadContainer>
			<LinkText className="menuText" to="/dashboard">
				<Image src={Home} alt="home" className="home_img" />
			</LinkText>
			<Menu>
				<MenuOutlined
					className="menuIcon"
					onClick={() => dispatch(toggleSidebar(!sideBarStatus))}
				/>
			</Menu>
			{urls?.map((url) => (
				<Fragment key={url?.id}>
					<BreadArrow />
					<LinkText className="menuText" to={url?.link}>
						{url?.text}
					</LinkText>
				</Fragment>
			))}
		</BreadContainer>
	);
}

BreadCrumbs.propTypes = {
	urls: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			text: PropTypes.string,
			link: PropTypes.string,
		})
	),
};
