import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'antd';
import { DatePicker, Select } from 'formik-antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { LoadAsyncSelectComponent } from 'components/common';
import { FormInput, AsyncSelect } from 'atoms';
import { dateFormat, disableFutureDate, SelectFilterLabelOptions, toAsysnOption } from 'utils';
import { getFcOnSearchList } from 'store/masters';
import { getCollectionDetailsReport } from 'store/downloads/reportSlice';
import { BtnWrap, StyledSubmitButton } from '../masters/helper';

const FormSchema = Yup.object({
	fcs: Yup.object().required().nullable(),
	brands: Yup.number().required().nullable(),
	va_date: Yup.date().required().nullable(),
});

const initialValues = {
	va_date: undefined,
	fcs: undefined,
	brands: undefined,
	brandsOptions: [],
};

const CommonStyle = {
	width: '250px',
	alignSelf: 'center',
};
export default function StockoneRfcFilters({ setShowModal }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [fcList, setFcList] = useState({ type: 'NotAsked' });

	useEffect(() => {
		setFcList({ type: 'Loading' });
		dispatch(getFcOnSearchList({})).then((res) =>
			setFcList({ type: 'Success', data: toAsysnOption(res) })
		);
	}, [dispatch]);

	const handleSubmit = (values, { resetForm, setSubmitting }) => {
		const data = {
			filter: {
				reportType: 'stockone_rfc_report',
				date_gte: dateFormat(values?.va_date),
				date_lte: dateFormat(values?.va_date),
				brands: [values?.brands],
				fcs: [values?.fcs?.value],
			},
		};

		// delete key if length is 0
		if (data.filter?.brands?.length === 0) {
			delete data.filter?.brands;
		}
		if (data.filter?.fcs?.length === 0) {
			delete data.filter?.fcs;
		}

		dispatch(getCollectionDetailsReport(data))
			.then(() => {
				resetForm();
				setShowModal(false);
			})
			.then(() => navigate('/downloads?query=&offset=0&limit=10&currentPage=1'))
			.finally(() => {
				setSubmitting(false);
			});
	};
	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={FormSchema}
			enableReinitialize
		>
			{({ setFieldValue, values, isValid, dirty }) => (
				<Form layout="vertical">
					<Row gutter={24} justify="space-between">
						<Col span={8}>
							<FormInput name="va_date" label="*Vehicle Allocation Date">
								<DatePicker
									name="va_date"
									className="form__field"
									disabledDate={disableFutureDate}
									showToday={false}
								/>
							</FormInput>
						</Col>
						<Col span={8}>
							<FormInput name="fcs" label="*FC">
								<LoadAsyncSelectComponent options={fcList} name="fcs">
									<AsyncSelect
										showSearch
										name="fcs"
										style={CommonStyle}
										component="formikSelect"
										onChange={(value, options) => {
											const selected = options?.find(
												(x) => x.value === value?.value
											);
											setFieldValue('fcs', value);
											setFieldValue('brands', undefined);
											setFieldValue('brandsOptions', selected?.brands || []);
										}}
										placeholder="Fc Type"
										selectOptions={[...(fcList?.data || [])]}
										fetchOptions={getFcOnSearchList}
									/>
								</LoadAsyncSelectComponent>
							</FormInput>
						</Col>
						<Col span={8}>
							<FormInput name="brands" label="*Brand">
								<Select
									showSearch
									className="customDropdown"
									name="brands"
									style={CommonStyle}
									placeholder="Select Brand"
									filterOption={SelectFilterLabelOptions}
									options={values.brandsOptions || []}
								/>
							</FormInput>
						</Col>
					</Row>

					<BtnWrap>
						<StyledSubmitButton disabled={!dirty || !isValid}>
							Request Report
						</StyledSubmitButton>
					</BtnWrap>
				</Form>
			)}
		</Formik>
	);
}

StockoneRfcFilters.propTypes = {
	setShowModal: PropTypes.func,
};
