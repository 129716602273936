import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import { Formik } from 'formik';
import { Form, Input, SubmitButton, Select } from 'formik-antd';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Text, Flex, FormInput } from 'atoms';
import { getUserProfile, setUserProfile, updateUserProfile } from 'store/authSlice';
import { validateMobileNumber, validateRipplrEmail } from 'utils/fieldValidation';
import { SelectFilterLabelOptions } from 'utils';
const BtnWrap = styled(Flex)`
	overflow: hidden;
`;

export const StyledSubmitButton = styled(SubmitButton)`
	border: 0;
	border-radius: 0;
	background-color: ${(props) => props.theme.colors.primary};
	color: ${(props) => props.theme.colors.white};
	&:hover,
	&:active {
		background-color: ${(props) => props.theme.colors.primary};
		color: ${(props) => props.theme.colors.white};
	}
`;
const FormSchema = Yup.object({
	first_name: Yup.string()
		.required()
		.min(4)
		.max(30)
		.label('First Name')
		.matches(/^[a-zA-Z ]*$/, 'Please enter valid first name')
		.trim(),
	last_name: Yup.string()
		.required()
		.label('Last Name')
		.matches(/^[a-zA-Z ]*$/, 'Please enter valid last name')
		.trim(),
	mobile_no: Yup.number()
		.label('Phone Number')
		.test('Not a valid phone number', (value) => validateMobileNumber(value)),
	email: Yup.string()
		.required()
		.email('Must be a valid email')
		.label('Email')
		.test('is-RipplrEmail', 'Not a valid Email', (value) => validateRipplrEmail(value)),
});

const initialValues = (data = undefined) => ({
	user_name: data?.username || undefined,
	first_name: data?.first_name || undefined,
	last_name: data?.last_name || undefined,
	mobile_no: data?.mobile_no || undefined,
	email: data?.email || undefined,
	user_id: data?.id || undefined,
	is_superuser: data?.is_superuser ? data?.id : undefined,
	fcs: data?.FCs?.map((x) => ({ value: x?.id, label: `${x?.code} : ${x?.name}` })),
	brands: data?.Brands?.map((x) => ({ value: x?.id, label: `${x?.code} : ${x?.name}` })),
});

function UserProfileForm() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [formValues, setFormValues] = useState(initialValues());

	useEffect(() => {
		dispatch(getUserProfile()).then((res) => setFormValues(initialValues(res?.user)));
	}, [dispatch]);

	function handleSubmit(values, { setErrors, resetForm, setSubmitting }) {
		dispatch(
			updateUserProfile(
				{
					first_name: values?.first_name,
					last_name: values?.last_name,
					mobile_no: values?.mobile_no?.toString(),
					user_id: values?.is_superuser || undefined,
				},
				setErrors
			)
		)
			.then(() => {
				setSubmitting(false);
				setFormValues(initialValues(values));
				resetForm();
				dispatch(
					setUserProfile({
						full_name: `${values?.first_name} ${values?.last_name}`,
						first_name: values?.first_name,
						last_name: values?.last_name,
						mobile_no: values?.mobile_no,
					})
				);
				navigate('/dashboard');
			})
			.catch(() => {
				setSubmitting(false);
			});
	}

	const handleOnKeyPress = (evt) => {
		if (!evt.code.includes('Key') && !evt.code.includes('Space')) {
			evt.preventDefault();
			return false;
		}
		return true;
	};

	return (
		<Formik
			initialValues={formValues}
			validationSchema={FormSchema}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{() => (
				<Form layout="vertical">
					<Text fontSize="14px" marginBottom="1rem" fontWeight="300">
						Personal Details
					</Text>
					<Row gutter={8} justify="flex-start">
						<Col lg={8} sm={24}>
							<FormInput name="user_name" label="*User Name">
								<Input name="user_name" disabled />
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput name="first_name" label="*First Name">
								<Input
									onKeyPress={handleOnKeyPress}
									type="text"
									name="first_name"
								/>
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput
								name="last_name"
								label="*Last Name"
								className="test-last-name"
							>
								<Input onKeyPress={handleOnKeyPress} type="text" name="last_name" />
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput
								name="mobile_no"
								label="*Phone Number"
								className="test-mobile-number"
							>
								<Input
									onKeyPress={(evt) => {
										if (
											evt.code.includes('Digit') &&
											evt?.target?.value?.toString()?.length < 10
										) {
											return true;
										}
										evt.preventDefault();
										return false;
									}}
									type="number"
									maxLength="10"
									name="mobile_no"
								/>
							</FormInput>
						</Col>

						<Col lg={8} sm={24}>
							<FormInput name="email" label="*Email" className="test-email">
								<Input name="email" disabled />
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput name="fcs" label="Fcs(s)">
								<Select
									disabled
									className="customDropdown"
									name="fcs"
									mode="multiple"
									placeholder="Select Fc"
									filterOption={SelectFilterLabelOptions}
									options={[]}
								/>
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput name="brands" label="Brand(s)">
								<Select
									disabled
									className="customDropdown"
									name="brands"
									mode="multiple"
									placeholder="Select Brand"
									filterOption={SelectFilterLabelOptions}
									options={[]}
								/>
							</FormInput>
						</Col>
					</Row>

					<BtnWrap>
						<StyledSubmitButton id="add-user-submit">Update</StyledSubmitButton>
					</BtnWrap>
				</Form>
			)}
		</Formik>
	);
}
export default UserProfileForm;
