import { useSearchParams } from 'react-router-dom';
import { omitBy, isNil } from 'lodash';
import { useMemo } from 'react';
import qs from 'qs';

export default function useURL() {
	const [searchParams] = useSearchParams();

	return useMemo(() => {
		const data = {
			collected_person_id: searchParams.get('collected_person_id'),
			brand_id: searchParams.get('brand_id'),
			allocation_date_time: searchParams.get('allocation_date_time'),
			verified_by_segregator_status: searchParams.get('verified_by_segregator_status'),
			verified_by_cashier_status: searchParams.get('verified_by_cashier_status'),
			return_status: searchParams.get('return_status'),
		};

		const onlyValid = omitBy(data, isNil);
		const queryString = qs.stringify(onlyValid, { addQueryPrefix: true });
		return { ...onlyValid, queryString };
	}, [searchParams]);
}
