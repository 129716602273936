/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, uniqBy } from 'lodash';
import { Modal, Select, List, Card, Space, Tag, message, Collapse, Typography } from 'antd';
import { Box, Flex, Button, AsyncSelect } from 'atoms';
import { RCTable, LabelWrapper, LoadAsyncSelectComponent, ACL } from 'components/common';
import { UploadPageModalNew } from 'components/pages/';
import { downloadExcelsUsingUrl, getOMSFcList } from 'store/UploadSlice';
import { getSaleStoreList } from 'store/fulfilment/salesOrderSlice';
import { getFcOnSearchList, getFcListOnLoad, getDownloadAdapterUploadsFile } from 'store/masters';
import {
	toAsysnOption,
	UploadListColumnsNew,
	DownloadFile,
	handleFilterChange,
	searchBox,
	ORDER_ADAPTER_STATUSES,
	SelectFilterLabelOptions,
	initialPaginateConfig,
	UploadQueries,
	initialPaginateConfigWithoutQ,
	selectOptionsFunc,
	ADAPTER_UPLOAD_FILE_TYPE_CHOICE,
	UserPersmission,
} from 'utils';
import { useQueryParams } from 'hooks';
import { showLoader, hideLoader } from 'store/appSlice';

const { Panel } = Collapse;
const { Text } = Typography;
const selectOptions = selectOptionsFunc(ADAPTER_UPLOAD_FILE_TYPE_CHOICE);
const orderAdaptorApi = `${process.env.REACT_APP_API_WMS_GRN_OA_URL}filestatus/`;

const CollapseStyling = {
	marginTop: '.5rem',
	marginBottom: '1rem',
};
const CollapseHeadStyling = {
	background: '#fafafa',
};

// const s3Bucket = `https://` + `${process.env?.REACT_APP_BUCKET_NAME}.s3.ap-south-1.amazonaws.com`;

const createItemList = (dataList) => {
	const listItems = dataList.map((resVal) => (
		<Card
			key={resVal.invoice}
			style={{ marginBottom: '10px' }}
			title={`Invoice No: ${resVal.invoice}`}
		>
			<Text type="danger">
				<strong>Error: </strong> {resVal?.status?.error?.error}
			</Text>
			<Collapse expandIconPosition="right" style={CollapseStyling}>
				<Panel header="Details:" style={CollapseHeadStyling}>
					{resVal?.status?.error?.details}
				</Panel>
			</Collapse>
		</Card>
	));
	return <div>{listItems}</div>;
};

const getInvoiceList = (val) => {
	const invalidInvString = JSON.stringify(val);
	return (
		<Box mt="12px" key={invalidInvString.invoice}>
			<Text>
				<strong>Invoice no: </strong> {val.invoice}
			</Text>
			<br />
			<Text type="danger">
				<strong>Error: </strong> {val.status.error.error}
			</Text>
			<Collapse expandIconPosition="right" style={CollapseStyling}>
				<Panel header="Details:" style={CollapseHeadStyling}>
					{val.status.error.details}
				</Panel>
			</Collapse>
		</Box>
	);
};

const createItemCount = (countList) => {
	const listCountItems = countList.map((countRes, index) => (
		// eslint-disable-next-line no-nested-ternary
		<Tag key={countRes?.label} color={index === 0 ? 'green' : index === 1 ? 'red' : 'blue'}>
			{countRes?.label}: <strong>{countRes?.value}</strong>
		</Tag>
	));
	return (
		<Space style={{ width: '100%' }} size="large">
			{listCountItems}
		</Space>
	);
};

export function UploadListPageNew() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		auth: { me },
	} = useSelector((state) => state);
	const [rowData, setRowData] = useState({});
	const [modalVisible, setModalVisible] = useState(false);
	const [errormodalVisible, setErrorModalVisible] = useState({ show: false, message: '' });
	const [statusModalVisibles, setStatusModalVisibles] = useState({ show: false });
	const [statusModalData, setStatusModalData] = useState(null);
	const [statusData, setStatusData] = useState(null);
	const [paginateConfig, setpaginateConfig] = useQueryParams({ filterQuery: UploadQueries });
	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);
	const [btnClickSearh, setBtnClickSearh] = useState({});

	const handleStatusModalVisibles = (data) => {
		setStatusData(null);
		setStatusModalData(data);
		setStatusModalVisibles({
			show: true,
		});
		if (data.user_id && data.filename && orderAdaptorApi) {
			fetch(
				`${orderAdaptorApi}?user_id=${data.user_id}&file_name=${data.filename}`
				// `https://mf42giwodl.execute-api.ap-south-1.amazonaws.com/Prod/filestatus?user_id=41&file_name=dev/salesorder/PUNE1/HUL/2022/sep/09/11amE_SALES.csv`
			)
				.then((res) => {
					if (!res.ok) {
						setStatusData([{ title: 'error', value: `Error Status: ${res.status}` }]);
						throw Error(res.status);
					}
					return res.json();
				})
				.then((res1) => {
					if (isEmpty(res1)) {
						setStatusData([{ title: 'empty' }]);
						return;
					}
					if (res1) {
						setStatusData(Object.values(res1)[0]?.status);
					}
				});
		}
	};

	useEffect(() => {
		setFcList({ type: 'Loading' });

		dispatch(
			getFcListOnLoad({ fcIds: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [] })
		).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});
	}, [dispatch]);

	const handleClick = (id, fileType) => {
		if (fileType === 'sales_return') navigate(`/adapter-uploads-sales-return/${id}`);
		if (fileType !== 'sales_return') navigate(`/adapter-uploads/${id}`);

		// if(fileType === 'sales_return') {
		// 	navigate(`/adapter-uploads-sales-return/${id}`
		// }
		// 	navigate(`/adapter-uploads/${id}`
	};

	// const refreshPage = async () => {
	// 	window.location.reload();

	// 	// try {
	// 	// 	const tableList = rowData?.rows
	// 	// 		// ?.filter((res) => res?.fileType === 'picklite_order_adapter')
	// 	// 		?.map((res) => res.fileName);

	// 	// 	dispatch(showLoader());
	// 	// 	const getOrderAdaptorFileList =
	// 	// 		tableList.length > 0
	// 	// 			? Promise.allSettled(
	// 	// 					tableList.map((x) =>
	// 	// 						fetch(`${orderAdaptorApi}?user_id=${me?.id}&file_name=${x}`)
	// 	// 					)
	// 	// 			  )
	// 	// 			: Promise.resolve({});
	// 	// 	// fetch(`${orderAdaptorApi}?file_list=[${getOrderAdaptorFileList}]`)

	// 	// 	await getOrderAdaptorFileList.then(() => window.location.reload());
	// 	// } catch (e) {
	// 	// 	console.log(e);
	// 	// }
	// };

	const downloadFiles = (rec) => {
		dispatch(showLoader());
		fetch(`${orderAdaptorApi}?user_id=${me?.id}&file_name=${rec?.fileName}&get_error_file=true`)
			.then((res) => res.json())
			.then((res) => {
				dispatch(hideLoader());
				if (res?.data) {
					DownloadFile({ name: rec?.fileName, url: res?.data });
					return message.info('File Downloaded Successfully');
				}
				return message.info('No File Found');
			});
	};

	// res1 = {};
	const handleDownloadExcel = (id) => {
		dispatch(downloadExcelsUsingUrl({ url: `champ/file/${id}/download`, data: {} })).then(
			(res) => DownloadFile(res)
		);
	};

	const downloadErrorFiles = (id) => {
		dispatch(showLoader());
		dispatch(getDownloadAdapterUploadsFile(id)).then((res) => {
			dispatch(hideLoader());
			if (res?.success) {
				/* eslint-disable-next-line */
				DownloadFile({ name: res.url.match(/([^\/]+)(?=\.\w+$)/)[0], url: res.url });
				return message.info('File Downloaded Successfully');
			}
			return message.info(res?.message);
		});
	};

	const handleFCChangeSelect = (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value
		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		const fcValues = value?.value ? [value?.value] : [];
		dispatch(getSaleStoreList({ q: '**', fc_ids: fcValues })).then(() => {
			setpaginateConfig((prev) => ({
				...handleFilterChange(name, fcValues, prev),
				brands: [],
			}));
		});
	};

	return (
		<>
			<Box mt={3}>
				<Box>
					<Flex
						width="100%"
						flexWrap="wrap"
						alignItems="center"
						justifyContent="space-between"
						marginBottom="1rem"
					>
						<Flex alignItems="center">
							<LabelWrapper label="Select File Types" marginspace="0px 0 7px 0">
								<Select
									style={{ width: '200px', alignSelf: 'center' }}
									allowClear
									name="fileType"
									placeholder="Select File Type"
									showSearch
									filterOption={SelectFilterLabelOptions}
									value={paginateConfig?.fileType}
									options={selectOptions}
									onChange={(value) =>
										setpaginateConfig((prev) =>
											handleFilterChange('fileType', value, prev)
										)
									}
								/>
							</LabelWrapper>

							<LabelWrapper label="FC" marginspace="3px 0 -1px 1rem">
								<LoadAsyncSelectComponent
									loadFCs={paginateConfig?.fcs}
									setList={(val) => setBrandList(val)}
									options={fcsList}
								>
									<AsyncSelect
										style={searchBox}
										allowClear
										name="fcs"
										showSearch
										fetchOptions={getFcOnSearchList}
										transformOptions={(val) => toAsysnOption(val)}
										defaultValue={paginateConfig?.fcs}
										placeholder="Select FC"
										onChange={(value, options) => {
											handleFCChangeSelect('fcs', value, options);
										}}
										selectOptions={[...(fcsList.data || [])]}
									/>
								</LoadAsyncSelectComponent>
							</LabelWrapper>

							<LabelWrapper label="Brand(s)" marginspace="3px 0 -1px 1rem">
								<Select
									name="fields"
									style={searchBox}
									allowClear
									showSearch
									label="brands"
									mode="multiple"
									value={paginateConfig?.brands}
									filterOption={SelectFilterLabelOptions}
									placeholder="Select Brand(s)"
									options={brandsList}
									onChange={(value) =>
										setpaginateConfig((prev) => ({
											...prev,
											brands: value,
											...initialPaginateConfigWithoutQ,
										}))
									}
								/>
							</LabelWrapper>

							<LabelWrapper label="Status(s)" marginspace="3px 0 -1px 1rem">
								<Select
									name="statuses"
									style={searchBox}
									allowClear
									showSearch
									options={ORDER_ADAPTER_STATUSES}
									value={paginateConfig?.statuses}
									filterOption={SelectFilterLabelOptions}
									mode="multiple"
									placeholder="Status(s)"
									onChange={(value) =>
										setpaginateConfig((prev) =>
											handleFilterChange('statuses', value, prev)
										)
									}
								/>
							</LabelWrapper>
							<Box>
								<Button onClick={() => setBtnClickSearh(Math.random())}>
									Search
								</Button>
							</Box>
						</Flex>

						<ACL allowedGroups={UserPersmission.upload_list_create}>
							<Flex>
								{/* {rowData?.rows && (
								<Button onClick={refreshPage}>
									<Flex alignItems="center" justifyContent="center">
										<Box>
											<SVGICONS.RefreshIcons />
											Refresh
										</Box>
									</Flex>
								</Button>
							)} */}

								<Button onClick={() => setModalVisible(true)}>
									<Flex alignItems="center" justifyContent="center">
										<Box>Upload</Box>
									</Flex>
								</Button>
							</Flex>
						</ACL>
					</Flex>
				</Box>

				<RCTable
					rowKey="id"
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'id',
					}}
					tableDatas={[rowData, setRowData]}
					dispatchAction={getOMSFcList}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					columns={UploadListColumnsNew({
						handleClick,
						handleDownloadExcel,
						downloadFiles,
						downloadErrorFiles,
						setErrorModalVisible,
						handleStatusModalVisibles,
						defaultSort: paginateConfig?.sort_direction || 'DESC',
						defaultsortField: paginateConfig?.sort_column || 'id',
						checkFileType: paginateConfig?.fileType,
					})}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
			{modalVisible && (
				<UploadPageModalNew
					toggleState={[modalVisible, setModalVisible]}
					handleTogglePaginate={() => setpaginateConfig({ ...initialPaginateConfig })}
				/>
			)}
			{errormodalVisible.show && (
				<Modal
					className={errormodalVisible?.title && 'errorHeader'}
					destroyOnClose
					title={errormodalVisible?.title ? errormodalVisible.title : 'Error Message'}
					visible={errormodalVisible.show}
					onOk={() => setErrorModalVisible({ message: '', show: false })}
					onCancel={() => setErrorModalVisible({ message: '', show: false })}
					footer={null}
					width="60%"
				>
					<Box>{errormodalVisible.message}</Box>
				</Modal>
			)}
			{statusModalVisibles.show && (
				<Modal
					maskClosable={false}
					title={statusModalData?.title}
					visible={statusModalVisibles.show}
					onOk={() => {
						setStatusModalVisibles({ show: false });
						setStatusData(null);
						setRowData(null);
					}}
					onCancel={() => {
						setStatusModalVisibles({ show: false });
						setStatusData(null);
						setRowData(null);
						// setpaginateConfig((prev) =>
						// 	handleFilterChange('fileType', undefined, prev)
						// );
						setBtnClickSearh(Math.random());
					}}
					footer={null}
					width="60%"
				>
					{statusData && (
						<Box>
							<Button
								style={{ marginBottom: '10px' }}
								onClick={() =>
									handleStatusModalVisibles({
										title: statusModalData.title,
										filename: statusModalData.filename,
										user_id: statusModalData.user_id,
									})
								}
							>
								<Flex alignItems="center" justifyContent="center">
									<Box>Refresh</Box>
								</Flex>
							</Button>
						</Box>
					)}
					<Box>
						{!statusData ? (
							<p>Fetching Details...</p>
						) : (
							<List
								itemLayout="horizontal"
								dataSource={statusData}
								renderItem={(item) => (
									<List.Item>
										<>
											{item?.title === 'empty' && <p>No Error Found</p>}
											{item?.title === 'error' && <p>{item?.value}</p>}
											{item?.label === 'cdms_invalid_invoices' && (
												<Card
													style={{ width: '100%' }}
													type="inner"
													extra={
														item?.count?.length > 0 && (
															<p key={item?.label}>
																{createItemCount(item?.count)}
															</p>
														)
													}
													title="CDMS Invalid Invoices"
												>
													{item?.value?.length > 0 &&
														createItemList(item?.value)}
												</Card>
											)}
											{item?.label === 'invalid_invoices' && (
												<Card
													style={{ width: '100%' }}
													type="inner"
													extra={
														item?.count?.length > 0 && (
															<p key={item?.label}>
																{createItemCount(item?.count)}
															</p>
														)
													}
													title="Invalid Invoices"
												>
													{console.log('working ', item?.value)}
													{item?.value.map(getInvoiceList)}
												</Card>
											)}
											{item?.label === 'existing_invoices' &&
												item?.value?.length > 0 && (
													<Card
														style={{ width: '100%' }}
														type="inner"
														title="Existing Invoices"
														extra={
															<Space
																style={{ width: '100%' }}
																size="large"
															>
																<Tag key="count" color="green">
																	Count:{' '}
																	<strong>
																		{item?.value?.length}
																	</strong>
																</Tag>
															</Space>
														}
													>
														{console.log('working ', item?.value)}
														{item?.value
															.map((e) => e)
															?.join(', ')
															?.toString()}
													</Card>
												)}
										</>
									</List.Item>
								)}
							/>
						)}
					</Box>
				</Modal>
			)}
		</>
	);
}
