import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Flex, Box, Text } from 'atoms';
import { Rupee } from 'components';
import { getunique, unScReplace } from 'utils/text';
import { theme } from 'styles/theme';

export const ColoredStatus = styled.div`
	text-align: ${(props) => props.align || 'center'};
	${(props) => {
		if (props.type === 'Settled' || props.type === 'FullySettled') {
			return `
        color: ${theme.colors.SlimyGreen};
    `;
		}
		if (props.type === 'Pending') {
			return `
        color: ${theme.colors.CarrotOrange};
    `;
		}
		if (props.type === 'Partially Settled') {
			return `
        color: ${theme.colors.SteelPink};
    `;
		}
		return `
        color: ${theme.colors.black};
    `;
	}}
`;

export const AdditionalSettlementColumns = ({ adhocCollections, setsummarymodal }) => [
	{
		title: (
			<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
				FC
			</Box>
		),
		dataIndex: 'fc_name',
		key: 'fc_name',
		filters: getunique(adhocCollections, 'fc_name'),
		onFilter: (value, record) => record.fc_name.startsWith(value),
		filterSearch: true,
		render: (fc_name) => (
			<Box textAlign="center" color="#000">
				{unScReplace(fc_name)}
			</Box>
		),
	},
	{
		title: (
			<Flex justifyContent="center" alignItems="center">
				<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
					Date
				</Box>
			</Flex>
		),
		dataIndex: 'created_at',
		key: 'created_at',
		render: (created_at) => (
			<Box textAlign="center" color="#000">
				{moment.utc(created_at).local().format('DD-MM-YYYY, hh:mm A')}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
				Added By
			</Box>
		),
		dataIndex: 'added_by',
		key: 'added_by',
		filters: getunique(adhocCollections, 'added_by'),
		onFilter: (value, record) => record.added_by.startsWith(value),
		filterSearch: true,
		render: (added_by) => (
			<Text textAlign="center" color="#000" textTransform="capitalize">
				{added_by || ''}
			</Text>
		),
	},
	{
		title: (
			<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
				Source
			</Box>
		),
		dataIndex: 'source',
		key: 'source',
		filters: getunique(adhocCollections, 'source'),
		onFilter: (value, record) => record.source.startsWith(value),
		filterSearch: true,
		render: (source) => (
			<Box textAlign="center" color="#000">
				{source || ''}
			</Box>
		),
	},
	{
		title: (
			<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
				Settlement Status
			</Box>
		),
		dataIndex: 'settlement',
		key: 'settlement',
		filters: getunique(adhocCollections, 'settlement'),
		onFilter: (value, record) => record.settlement.startsWith(value),
		filterSearch: true,
		render: (settlement) => <ColoredStatus type={settlement}>{settlement || ''}</ColoredStatus>,
	},
	{
		title: (
			<Box textAlign="center" fontWeight="400" fontSize="14px" color="#00000">
				Amount
			</Box>
		),
		dataIndex: 'amount',
		key: 'amount',
		render: (amount) => (
			<Box textAlign="center" color="#000">
				<Rupee>{amount || 0}</Rupee>
			</Box>
		),
	},
	{
		title: (
			<Text
				textAlign="center"
				fontWeight="400"
				fontSize="14px"
				color="#00000"
				title="Click here to view details"
			>
				Details
			</Text>
		),
		dataIndex: 'details',
		key: 'details',
		render: (details, record) => (
			<Box
				onClick={() => setsummarymodal({ visible: true, data: record })}
				textAlign="center"
				color="#000"
				title="Click here to view details"
			>
				<InfoCircleOutlined />
			</Box>
		),
	},
];
