import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Row, Col, message } from 'antd';
import * as Yup from 'yup';
import { Select, DatePicker } from 'formik-antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormInput, AsyncSelect } from 'atoms';
import { unScReplace, disableFutureDate, toAsysnOption, SelectFilterOptions } from 'utils';
import { getFcOnSearchList } from 'store/masters';
import { getGrnReport, getSettledUserList } from 'store/downloads/reportSlice';
import { BtnWrap, StyledSubmitButton } from '../masters/helper';

const { Option } = Select;
const initialValues = {
	grn_date__gte: undefined,
	grn_date__lte: undefined,
	fcs: undefined,
	brands: undefined,
};

// form schema
const FormSchema = Yup.object({
	grn_date__gte: Yup.string().required().label('From date'),
	grn_date__lte: Yup.date()
		.required()
		.label('To date')
		.when(
			'grn_date__gte',
			(grn_date__gte, yup) =>
				grn_date__gte && yup.min(grn_date__gte, 'To date cannot be less than From date')
		),
});

export default function SettlementFilters({ setShowModal }) {
	const dispatch = useDispatch();
	const [fcList, setFcList] = useState([]);
	const { userList = [] } = useSelector((state) => state.report);
	useEffect(() => {
		dispatch(getFcOnSearchList({})).then((res) => setFcList(toAsysnOption(res)));

		dispatch(
			getSettledUserList({
				filter: {
					user_fcs_only: true,
					q: '**',
					fields: ['first_name', 'last_name'],
					groups: [1],
					user_brands_only: true,
				},
				page: { limit: 25, offset: 0 },
				sort: { sort_column: 'id', sort_direction: 'DESC' },
			})
		);
	}, [dispatch]);

	// on Select Event
	const handleSelect = (name, value, setFieldValue) => setFieldValue(name, value);

	const handleSubmit = (values, { resetForm, setSubmitting }) => {
		const msgKey = Math.random().toString();
		const config = {
			key: msgKey,
			type: 'loading',
			content: 'Downloading Reports Settlement',
			duration: 0,
			className: 'customDownloadMsg',
		};
		message.open(config);

		dispatch(getGrnReport(values))
			.then(() => {
				resetForm();
				setShowModal(false);
			})
			.finally(() => {
				setSubmitting(false);
				message.destroy(msgKey);
			});
	};

	const UsersLists = userList?.map((data) => (
		<Option key={data?.id} value={data?.id}>
			{unScReplace(data?.full_name)}
		</Option>
	));

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={FormSchema}
			enableReinitialize
			initialTouched={{
				fcs: true,
				brands: true,
			}}
		>
			{({ setFieldValue }) => (
				<Form layout="vertical">
					<Row gutter={32} justify="space-between">
						<Col span={8}>
							<FormInput name="grn_date__gte" label="*From-Settlement Date">
								<DatePicker
									className="form__field"
									disabledDate={disableFutureDate}
									name="grn_date__gte"
								/>
							</FormInput>
						</Col>

						<Col span={8}>
							<FormInput name="grn_date__lte" label="*To-Settlement Date">
								<DatePicker
									className="form__field"
									disabledDate={disableFutureDate}
									name="grn_date__lte"
								/>
							</FormInput>
						</Col>

						<Col span={8}>
							<FormInput name="fcs" label="*FC(s)">
								<AsyncSelect
									className="form__field"
									mode="multiple"
									showSearch
									name="fcs"
									onChange={(value) => handleSelect('fcs', value, setFieldValue)}
									placeholder="Fc Type"
									filterOption={(input, option) =>
										option.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									allowClear
									selectOptions={fcList}
									fetchOptions={getFcOnSearchList}
								/>
							</FormInput>
						</Col>
					</Row>
					<Row gutter={32}>
						<Col span={8}>
							<FormInput name="brands" label="*Settled By">
								<Select
									mode="multiple"
									className="form__field"
									showSearch
									name="brands"
									placeholder="Settled By"
									filterOption={SelectFilterOptions}
									allowClear
								>
									{UsersLists}
								</Select>
							</FormInput>
						</Col>

						<Col span={8}>
							<FormInput name="brands" label="*Settled To">
								<Select
									mode="multiple"
									className="form__field"
									showSearch
									name="brands"
									placeholder="Settled To"
									filterOption={SelectFilterOptions}
									allowClear
								>
									{UsersLists}
								</Select>
							</FormInput>
						</Col>
					</Row>
					<BtnWrap>
						<StyledSubmitButton>Download </StyledSubmitButton>
					</BtnWrap>
				</Form>
			)}
		</Formik>
	);
}

SettlementFilters.propTypes = {
	setShowModal: PropTypes.func,
};
