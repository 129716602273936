import { createSlice } from '@reduxjs/toolkit';
import { API } from 'api';
const initialState = {};

export const inventoryUnsaleableProductSlice = createSlice({
	name: 'inventoryunsaleableproduct',
	initialState,
	reducers: {},
});

export const getInventoryUnsaleableProductList =
	({ offset, limit, sort_column = 'id', sort_direction = 'DESC', currentPage, query, ...rest }) =>
	async () => {
		const method = 'post';
		const url = '/champ/inventory/current_unsaleable_inventory_product_wise';

		const CREDENTIALS = {
			url,
			method,
			data: {
				sort: { sort_column, sort_direction },
				filter: { user_fcs_only: true, user_brands_only: true, ...rest },
				page: { offset, limit },
			},
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export default inventoryUnsaleableProductSlice.reducer;
