import React from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import { Flex } from 'atoms';

const plainOptions = ['Cash', 'Cheque', 'UPI', 'NEFT', 'Total'];

export default function ColumnFilters({ onChange }) {
	return (
		<Flex alignItems="center" cursor="pointer" padding="10px 0" margin="0.5rem 0">
			<Checkbox.Group options={plainOptions} onChange={onChange} />
		</Flex>
	);
}

ColumnFilters.propTypes = {
	onChange: PropTypes.func,
};
