import { React, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Table, Select } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import { Box, Button, Flex, AsyncSelect } from 'atoms';
import { getChequeBounceList } from 'store/financeTeamSlice';
import { useQueryParams } from 'hooks';
import { LoadAsyncSelectComponent, LabelWrapper, ACL } from 'components/common';
import { getFcListOnLoad, getFcOnSearchList } from 'store/masters';
import {
	handleFilterChange,
	chequeBounceQueries,
	toAsysnOption,
	searchBox,
	SelectFilterLabelOptions,
	initialPaginateConfigWithoutQ,
	UserPersmission,
} from 'utils';
import { chequeBounceStatuses } from 'utils/consts/cheque';
import ChequeBounceDeleteModal from 'components/modals/ChequeBounceDeleteModal';
import ShowExpandableData from './ShowExpandableData';

export const ButtonBox = styled.div`
	float: left;
`;

export const BoxContent = styled.div`
	overflow-y: scroll;
	height: 70%;
`;

export const ButtonTwo = styled.button`
	padding: 15.5px 10px 15.5px 10px;
	background: #ca5010;
	color: #fff;
	border: 2px solid #ca5010;
	border-radius: 4px;
	font-size: 14px;
	cursor: pointer;
	margin: 10px 0px 20px 0px;
	display: flex;
	align-items: right;
`;

export const Input = styled.input`
	height: 40px;
	width: 300px;
	background: #faf9f8;
	border: 1px solid #edebe9;
	border-radius: 2px;
	margin-top: 7px;
	padding: 8px 16px 8px 16px;
	margin-right: 24px;
`;

export const LeftInnerTwo = styled(Flex)`
	border-left: 1px solid #d0d0d0;
	height: 70px;
	padding-left: 50px;
`;
export const ExpandedData = styled.div`
	margin-right: 20px;
	padding: 5px 50px 5px 50px;
`;

export default function BouncedChequeList({
	columns,
	modalVisible,
	setModalVisible,
	chequeBounceRowData,
}) {
	const dispatch = useDispatch();

	const [chequeBounceList, setChequeBounceList] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);
	const [paginateConfig, setpaginateConfig] = useQueryParams({
		filterQuery: chequeBounceQueries,
	});
	const [listUpdated, setListUpdated] = useState(false);

	function loadCheques(text, filters) {
		getChequeBounceList(text, filters, false).then((r) => {
			setChequeBounceList(r);
		});
	}
	useEffect(() => {
		loadCheques();
	}, [listUpdated]);

	useEffect(() => {
		setFcList({ type: 'Loading' });

		dispatch(
			getFcListOnLoad({ fcIds: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [] })
		).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});
	}, [dispatch, paginateConfig?.fcs]);

	const handleChangeSelect = (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value

		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		const fcValues = value?.value ? [value?.value] : [];
		setpaginateConfig((prev) => ({
			...handleFilterChange(name, fcValues, prev),
			brands: [],
		}));
	};

	return (
		<Box>
			<Box>
				<Box>
					<Input
						onChange={(e) => setSearchText(e.target.value)}
						placeholder="Search by Cheque Number"
						pattern="[0-9]*"
						inputMode="numeric"
						maxLength={6}
						value={searchText}
					/>
					<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
						<LoadAsyncSelectComponent
							loadFCs={paginateConfig?.fcs}
							setList={(val) => setBrandList(val)}
							options={fcsList}
						>
							<AsyncSelect
								style={searchBox}
								allowClear
								name="fcs"
								showSearch
								fetchOptions={getFcOnSearchList}
								transformOptions={(val) => toAsysnOption(val)}
								defaultValue={paginateConfig?.fcs}
								placeholder="Select FC(s)"
								onChange={(value, options) => {
									handleChangeSelect('fcs', value, options);
								}}
								selectOptions={[...(fcsList.data || [])]}
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>

					<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
						<Select
							name="fields"
							style={searchBox}
							allowClear
							showSearch
							label="brands"
							mode="multiple"
							value={paginateConfig?.brands}
							filterOption={SelectFilterLabelOptions}
							options={brandsList}
							onChange={(value) =>
								setpaginateConfig((prev) => ({
									...prev,
									brands: value,
									...initialPaginateConfigWithoutQ,
								}))
							}
						/>
					</LabelWrapper>

					<LabelWrapper label="Status" marginspace="3px 0 -1px 1rem">
						<Select
							name="fields"
							style={searchBox}
							allowClear
							showSearch
							label="Status"
							placeholder="Select Status"
							value={paginateConfig?.statuses}
							filterOption={SelectFilterLabelOptions}
							options={
								chequeBounceStatuses?.map((x) => ({
									label: x.label,
									value: x?.value,
								})) || []
							}
							onChange={(value) =>
								setpaginateConfig((prev) => ({
									...prev,
									statuses: value,
									...initialPaginateConfigWithoutQ,
								}))
							}
						/>
					</LabelWrapper>

					<Button type="button" onClick={() => loadCheques(searchText, paginateConfig)}>
						Search
					</Button>
				</Box>
				<ACL allowedGroups={UserPersmission.cheque_create}>
					<ButtonBox>
						<Link to="/cheque-bounce/cheque-bounce-form">
							<ButtonTwo>Add Cheque Bounce</ButtonTwo>
						</Link>
					</ButtonBox>
				</ACL>
			</Box>
			<Table
				rowKey={(record) => `${record?.cheque_bounce_unique_column_hash}`}
				dataSource={chequeBounceList}
				expandable={{
					// eslint-disable-next-line react/no-unstable-nested-components
					expandedRowRender: (record) => (
						<Box>
							<Flex>
								<ExpandedData>
									<ShowExpandableData record={record} />
								</ExpandedData>
							</Flex>
						</Box>
					),
				}}
				columns={columns}
				pagination={{
					...paginateConfig,
					total: chequeBounceList?.length,
					showSizeChanger: true,
					page: Number(paginateConfig?.currentPage) || 1,
					item_per_page: Number(paginateConfig?.limit) || 10,
				}}
			/>
			{modalVisible && (
				<ChequeBounceDeleteModal
					modalVisible={modalVisible}
					setModalVisible={setModalVisible}
					chequeBounceRowData={chequeBounceRowData}
					setListUpdated={setListUpdated}
				/>
			)}
		</Box>
	);
}

BouncedChequeList.propTypes = {
	columns: PropTypes.array,
	modalVisible: PropTypes.bool,
	setModalVisible: PropTypes.func,
	chequeBounceRowData: PropTypes.object,
};
