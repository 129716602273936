/* eslint-disable sonarjs/cognitive-complexity */
import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Select, Form, SubmitButton } from 'formik-antd';
import { message, Upload, Col, Row } from 'antd';
import { config, S3, CognitoIdentityCredentials } from 'aws-sdk';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Flex, Box, Text, FormInput } from 'atoms';
import { SelectFilterLabelOptions } from 'utils';

const BtnWrap = styled(Flex)`
	justify-content: flex-end;
`;

const UploadWrap = styled(Upload)`
	display: flex;
	width: 100%;
	background: #153e6021;
	min-height: 70px;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`;

export const FcListOptions = [
	'BTM',
	'BZA-VGA1',
	'CHN1',
	'CHN2',
	'CJB',
	'HMR',
	'HUBLI',
	'HYDA',
	'HYDM',
	'HYDMPST',
	'PUNE1',
	'PYA',
	'YPR',
	'GDY',
	'TLB',
];
export const PathTypeList = ['salesorder', 'GRN'];

export const getExtension = (type) => {
	switch (type) {
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			return 'xlsx';
		case 'application/vnd.ms-excel':
			return 'xls';
		case 'text/csv':
			return 'csv';
		default:
			return 'txt';
	}
};

export const BrandListBasedFcs = {
	BTM: ['BRIT', 'DBR', 'LOREAL', 'NVA', 'RKT', 'GDJGT'],
	CHN1: ['BRIT'],
	CJB: ['NVA'],
	HMR: ['BRIT', 'DBR', 'GDJGT', 'KBC', 'LOREAL', 'HUL'],
	HUBLI: ['GDJ', 'RKT'],
	HYDA: ['BRIT', 'HUL'],
	PYA: ['ITC', 'TATA'],
	YPR: ['NVA', 'BRIT', 'HUL'],
	HYDM: ['BRIT', 'GDJ'],
	CHN2: ['GDJ'],
	PUNE1: ['RB', 'BRIT'],
	'BZA-VGA1': ['BRIT'],
	HYDMPST: ['BRIT', 'RB', 'DBR'],
	GDY: ['CLGT'],
	TLB: ['HUL'],
	PNY: ['HUL'],
};

export const StyledSubmitButton = styled(SubmitButton)`
	border: 0;
	height: 35px;
	background-color: ${(props) => props.theme.colors.primary};
	color: ${(props) => props.theme.colors.white};
	margin-top: 1.3rem;
	&:hover {
		background-color: ${(props) => props.theme.colors.primary};
		color: ${(props) => props.theme.colors.white};
	}
`;

const initialValues = {
	path: undefined,
	fc_value: undefined,
	brand_value: undefined,
	fileName: undefined,
};
// form schema
const orderAdapterFormSchema = Yup.object({
	path: Yup.string().required().label('Type'),
	fc_value: Yup.string().required().label('FC(s)'),
	brand_value: Yup.string().required().label('Brand(s)'),
	fileName: Yup.array().required().label('Excel'),
});

const BUCKET_NAME = process.env?.REACT_APP_BUCKET_NAME;
const BUCKET_REGION = process.env?.REACT_APP_BUCKET_REGION;
const IDENTITYPOOL_ID = process.env?.REACT_APP_S3_IDENTITYPOOL_ID;

export default function OrderAdapterForm() {
	const [brandValue, setBrandValue] = useState([]);

	const handleChangeSelect = (name, value, setFieldValue) => {
		setFieldValue(name, value);
		setFieldValue('brand_value', '');
		setBrandValue(value ? BrandListBasedFcs[value]?.map((x) => ({ label: x, value: x })) : []);
	};

	// File events
	const handleupload = ({ file, fileList }, setFieldValue, hasMultiple) => {
		setFieldValue('fileName', [...(hasMultiple ? fileList : [file])]);
		return false;
	};

	const handleSubmit = async (values, { resetForm, setSubmitting }) => {
		try {
			const { fileName = [], fc_value, brand_value, path } = values;
			const timeStamp = new Date().getTime();

			const generateFilePath = fileName.reduce((acc, _, index) => {
				const filepath = `${fc_value}/${brand_value}/${path}/${timeStamp}_${fileName[index].name}`;
				acc[filepath] = 0;
				return acc;
			}, {});

			await fileName?.reduce(
				(accPromise, nextfile, currentIndex) =>
					accPromise.then(() => {
						const filePath = `${fc_value}/${brand_value}/${path}/${timeStamp}_${nextfile.name}`;
						const isMultipleLastFile =
							fileName.length - 1 === currentIndex && fileName.length > 1;

						config.update({
							region: BUCKET_REGION,
							credentials: new CognitoIdentityCredentials({
								IdentityPoolId: IDENTITYPOOL_ID,
							}),
						});

						new S3({
							apiVersion: '2006-03-01',
							params: { Bucket: BUCKET_NAME },
						}).upload(
							{
								Key: filePath,
								Body: fileName.length > 1 ? nextfile.originFileObj : nextfile,
								ACL: 'public-read',
								Metadata: {
									...(isMultipleLastFile ? { indb: '1' } : {}),
									...(fileName.length > 1
										? { multifile: JSON.stringify(generateFilePath) }
										: {}),
									hub_code: fc_value,
									brd_code: brand_value,
									path,
									file_type: path,
									file_ext: getExtension(nextfile.type),
									filename: filePath,
									org_file_name: nextfile.name,
								},
							},
							(err) => {
								console.log(err, 'error');
								if (err) {
									message.info(`${nextfile.name}: ${err}`);
								} else {
									message.info(`${nextfile.name}  Uploaded Successfully`);
								}
							}
						);
					}),
				Promise.resolve()
			);
		} catch (e) {
			message.error(e?.response);
		} finally {
			resetForm();
			setSubmitting(false);
		}
	};

	return (
		<Box>
			<Formik
				validationSchema={orderAdapterFormSchema}
				initialValues={initialValues}
				onSubmit={handleSubmit}
				enableReinitialize
			>
				{({ values, setFieldValue }) => {
					const hasMultiple =
						values?.brand_value === 'HUL' || values?.brand_value === 'ITC';

					return (
						<Form layout="vertical">
							<Box>
								<Row gutter={8} justify="flex-start">
									<Col lg={6}>
										<FormInput name="fc_value" label="FC(s)">
											<Select
												name="fc_value"
												className="form__field"
												showSearch
												filterOption={SelectFilterLabelOptions}
												allowClear
												label="FC(s)"
												options={FcListOptions.map((x) => ({
													label: x,
													value: x,
												}))}
												onChange={(value) =>
													handleChangeSelect(
														'fc_value',
														value,
														setFieldValue
													)
												}
												placeholder="Select FC"
											/>
										</FormInput>
									</Col>
									<Col lg={6}>
										<FormInput name="brand_value" label="Brand(s)">
											<Select
												name="brand_value"
												className="form__field"
												allowClear
												label="Brand(s)"
												disabled={isEmpty(brandValue)}
												showSearch
												filterOption={SelectFilterLabelOptions}
												options={brandValue || []}
												placeholder="Select Brands"
											/>
										</FormInput>
									</Col>
									<Col lg={6}>
										<FormInput name="path" label="Type">
											<Select
												name="path"
												className="form__field"
												allowClear
												label="Type"
												showSearch
												filterOption={SelectFilterLabelOptions}
												options={PathTypeList.map((x) => ({
													label: x,
													value: x,
												}))}
												placeholder="Select Type"
											/>
										</FormInput>
									</Col>
								</Row>
								<Text margin="1rem 0">Upload Section</Text>
								<FormInput name="fileName">
									<Flex>
										<UploadWrap
											name="fileName"
											fileList={values?.fileName}
											beforeUpload={() => false}
											onChange={(file) =>
												handleupload(file, setFieldValue, hasMultiple)
											}
											className="uploadspage-antd"
											multiple={hasMultiple}
											accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
										>
											<Flex className="uploadspage-antd__textname">
												Upload files
											</Flex>
										</UploadWrap>
									</Flex>
								</FormInput>

								<Box mt="16px">
									<BtnWrap>
										<StyledSubmitButton>Upload </StyledSubmitButton>
									</BtnWrap>
								</Box>
							</Box>
						</Form>
					);
				}}
			</Formik>
		</Box>
	);
}
