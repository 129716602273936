import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Box, Text } from 'atoms';
import { ACL } from 'components/common';
import { UserPersmission } from 'utils/userPermission';

export const CompanyColumns = [
	{
		title: 'Name',
		dataIndex: 'name',
		key: 'name',
		render: (name) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{name || ''}
			</Text>
		),
	},
	{
		title: 'Code',
		dataIndex: 'code',
		key: 'code',
		render: (code) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{code || ''}
			</Text>
		),
	},
	{
		title: 'Address',
		dataIndex: 'address',
		key: 'address',
		render: (address) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{address || ''}
			</Text>
		),
	},
	{
		title: 'Phone',
		dataIndex: 'phone',
		key: 'phone',
		render: (phone) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{phone || ''}
			</Text>
		),
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
		render: (email) => (
			<Box as="a" href={`mailto:${email}`} overFlow="hidden" fontSize="12px">
				{email || ''}
			</Box>
		),
	},
	{
		title: 'GST No.',
		dataIndex: 'gst_number',
		key: 'gst_number',
		render: (gst_number) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{gst_number || ''}
			</Text>
		),
	},
	{
		title: 'Account number	',
		dataIndex: 'account_number',
		key: 'account_number',
		render: (account_number) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{account_number || ''}
			</Text>
		),
	},
	{
		title: 'Ifsc code',
		dataIndex: 'ifsc_code',
		key: 'ifsc_code',
		render: (ifsc_code) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{ifsc_code || ''}
			</Text>
		),
	},
	{
		title: '',
		dataIndex: 'id',
		key: 'id',
		sortId: 6,
		render: (id) => (
			<ACL allowedGroups={UserPersmission.company_create}>
				<Link style={{ color: '#000' }} to={`/onboarding/company/${id}/edit`}>
					<EditOutlined />
				</Link>
			</ACL>
		),
	},
];
