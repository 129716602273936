/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Modal, Upload, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { config, S3, CognitoIdentityCredentials } from 'aws-sdk';
import { CloseCircleFilled, FilePdfFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import { Box, Button, Flex, Text } from 'atoms';
import './uploadModal.scss';
import { getUploadfilePathEpodTwo } from 'pages/uploads/uploadsController';
import { uploadImagesList } from 'store/fulfilment/returnToFCSlice';

const { confirm } = Modal;
const { Dragger } = Upload;

const BUCKET_NAME = process.env?.REACT_APP_CDMS_EPOD_BUCKET;
const BUCKET_REGION = process.env?.REACT_APP_BUCKET_REGION;
const IDENTITYPOOL_ID = process.env?.REACT_APP_S3_IDENTITYPOOL_ID;

const StyledCard = styled(Box)`
	border-radius: 4px;
	background-color: #faf9f8;
	padding: 10px 0;
	font-size: 16px;
	position: relative;
	width: 400px;
	height: 66px;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const UploadButton = styled(Button)`
	font-size: 16px;
	border-radius: 4px;
	background: #ca5010;
	width: 200px;
	height: 40px;
`;

const findDuplicates = (arr) => arr.length !== new Set(arr).size;

export function UploadEpodModal({ toggleState, epodUploadModalData, uploadFileSize = 5 }) {
	const dispatch = useDispatch();
	const { auth } = useSelector((state) => state?.auth);
	const [modalVisible, setModalVisible] = toggleState;
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [uploading, setUploading] = useState(false);

	console.log('uploadFileSize ', uploadFileSize);

	const fileSize = uploadFileSize;
	const acceptableTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];

	const handleRemoveFile = (file) => {
		setSelectedFiles(selectedFiles.filter((f) => f.uid !== file.uid));
	};

	const handleBeforeUpload = (file) => {
		if (!acceptableTypes.includes(file.type)) {
			message.error({
				key: 'extensions',
				content: 'Please upload document with image (jpeg,jpg,png) or pdf type',
			});
		} else if ((file.size / 1024 ** 2).toFixed(2) > fileSize) {
			message.error({
				key: 'sizeLimit',
				content: `Please upload document less than ${fileSize} MB`,
			});
		} else {
			setSelectedFiles([file]); // Allow only one file at a time
		}
		return false;
	};

	const uploadProps = {
		name: 'file',
		multiple: false,
		accept: acceptableTypes.join(', '),
		showUploadList: false,
		beforeUpload: handleBeforeUpload,
	};

	const uploadFile = async (fileList) => {
		setUploading(true);
		try {
			const uploadResults = await handleS3Upload(fileList);
			const uploadData = getUploadData(uploadResults);
			await dispatch(uploadImagesList(uploadData));
			message.info(`Uploaded Successfully`);
		} catch (error) {
			console.error('Upload error:', error);
			message.error('Upload failed');
		} finally {
			setUploading(false);
			setModalVisible(false);
		}
	};

	const handleS3Upload = async (fileList) => {
		const promises = fileList.map(async (file) => {
			const metafile = getUploadfilePathEpodTwo(
				file,
				fileList,
				epodUploadModalData.allocation_id,
				epodUploadModalData.id
			);

			config.update({
				region: BUCKET_REGION,
				credentials: new CognitoIdentityCredentials({
					IdentityPoolId: IDENTITYPOOL_ID,
				}),
			});

			const s3 = new S3({
				apiVersion: '2006-03-01',
				params: { Bucket: BUCKET_NAME },
			});

			const uploadMetaData = {
				allocation_id: `${epodUploadModalData.allocation_id}`,
				created_by_id: String(auth.user.id),
				filename: metafile.filenamePath,
				file_type: file.type.split('/')[1],
				filesize: String(file.size),
			};

			const data = await s3
				.upload({
					Key: metafile.filenamePath,
					Body: file,
					ACL: 'public-read',
					Metadata: uploadMetaData,
					ContentDisposition: 'inline',
					ContentType: file.type,
				})
				.promise();

			if (data) {
				// message.info(`${file.name} Uploaded Successfully`);
			}
			return data;
		});

		const results = await Promise.all(promises);
		return results.map((result) => result.Location);
	};

	const getUploadData = (uploadResults) => ({
		order_id: epodUploadModalData?.id,
		allocation_id: epodUploadModalData?.allocation_id,
		image_path: uploadResults[0],
		is_verified:
			auth?.user?.groups[0]?.name === 'System Admin' ||
			auth?.user?.groups[0]?.name === 'Transport Manager',
	});

	return (
		<Modal
			centered
			className="upload-modal-epod"
			destroyOnClose
			visible={modalVisible}
			onOk={() => setModalVisible(false)}
			onCancel={() => setModalVisible(false)}
			footer={null}
			width={500}
			maskClosable={false}
		>
			{selectedFiles.length === 0 && (
				<Dragger {...uploadProps} textAlign="center" width="500px" height="300px">
					<Text fontSize="15px" fontWeight="400">
						Drag and Drop Image / PDF to Upload
					</Text>
				</Dragger>
			)}
			{selectedFiles.length > 0 && (
				<Flex flexDirection="column" alignItems="center">
					<Text mt="18px" mb="18px" fontSize="16px" fontWeight="400">
						Selected File List
					</Text>
					{selectedFiles.map((fileName) => (
						<StyledCard key={fileName.uid}>
							<Text>
								<FilePdfFilled style={{ marginRight: '15px', color: '#F40F02' }} />
								{fileName.name}
							</Text>
							<CloseCircleFilled
								style={{
									position: 'absolute',
									right: '-6px',
									top: '-8px',
									color: '#CA5010',
								}}
								onClick={() => handleRemoveFile(fileName)}
							/>
						</StyledCard>
					))}
					<UploadButton
						textAlign="center"
						mt="16px"
						mb="16px"
						disabled={selectedFiles.length === 0}
						onClick={() => uploadFile(selectedFiles)}
						loading={uploading}
					>
						{uploading ? 'Loading' : 'Upload'}
					</UploadButton>
				</Flex>
			)}
		</Modal>
	);
}

UploadEpodModal.propTypes = {
	toggleState: PropTypes.array.isRequired,
	epodUploadModalData: PropTypes.object.isRequired,
	uploadFileSize: PropTypes.number,
};
