import { Input, Modal, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { saveRemarks } from 'store/invoiceSlice';
import { Box, Button, Flex } from 'atoms';
import { useURL } from 'hooks';

const CustomInput = styled(Input)`
	min-height: 48px;
	background: #f6f5f5;
	border-radius: 40px;
	border: 0;
	padding: 5px 140px 5px 20px;
	&:placeholder {
		color: #979797;
	}
	&:focus,
	&:hover {
		border: 0;
		box-shadow: none;
		outline: none;
	}
`;

const CustomButton = styled(Box)`
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	button {
		border-radius: 50px;
		min-height: 40px;
		background-color: ${(props) => props.theme.colors.DimGray};
		&:focus {
			outline: none;
		}
	}
`;

export default function RemarksForm({
	successCallBack,
	onHide,
	record = {},
	remarksType = '',
	show = false,
}) {
	const [remarks, setRemarks] = useState('');
	const [reason, setReason] = useState('');
	const dispatch = useDispatch();
	const urlData = useURL();
	const { role } = useSelector((state) => state.auth);

	console.log(record);

	useEffect(() => {
		setRemarks('');
		if (!show) {
			resetData();
		}
		return () => {
			resetData();
		};
	}, [show]);

	useEffect(() => {
		/**
		 * On Cashier Login don't show the Reason radio buttons
		 */
		if (role === 'Cashier') {
			setReason('Other');
		}
	}, [role, show]);

	function resetData() {
		setRemarks('');
		setReason('');
	}

	function handleAddRemarks() {
		dispatch(
			saveRemarks({
				remark_status: remarksType,
				brand_id: record?.brand_id || urlData?.brand_id,
				collected_person_id: record?.collected_person_id || urlData?.collected_person_id,
				allocation_date_time: record?.allocation_date_time || urlData?.allocation_date_time,
				remark: remarks || reason,
				unique_key_code: record?.unique_key || '',
				collection_invoice_id: record?.collection_invoice_id
					? record?.collection_invoice_id
					: record?.invoice_ids || 0,
				payment_ids: record?.payment_ids || 0,
			})
		).then(() => {
			setRemarks('');
			onHide();
			successCallBack(remarks || reason);
		});
	}

	const onChange = (e) => {
		const data = e.target.value;
		setReason(data);
		if (data === 'Other') {
			setRemarks('');
		}
	};

	return (
		<Modal
			centered
			width="65%"
			title="Add Comment"
			visible={show}
			onOk={onHide}
			onCancel={onHide}
			footer={false}
			maskClosable={false}
		>
			<Box>
				{/* On Cashier Login don't show the Reason radio buttons */}
				{role === 'Segregator' && (
					<Box mb="1rem">
						<Flex marginBottom="8px" my="2rem" justifyContent="space-between">
							<Radio.Group onChange={onChange} value={reason}>
								<Radio value="Invoice missing">Invoice missing</Radio>
								<Radio value="Data Correction">Data Correction</Radio>
								<Radio value="Other">Other</Radio>
							</Radio.Group>
							{reason !== 'Other' && (
								<CustomButton>
									<Button onClick={handleAddRemarks}>Add</Button>
								</CustomButton>
							)}
						</Flex>
					</Box>
				)}
				{reason === 'Other' && (
					<Flex marginBottom="8px" mt="2rem" justifyContent="space-between">
						<CustomInput
							onChange={(e) => setRemarks(e.target.value)}
							value={remarks}
							placeholder="Type your comment here..."
							maxLength={400}
						/>
						<CustomButton>
							<Button disabled={remarks?.length < 5} onClick={handleAddRemarks}>
								Add
							</Button>
						</CustomButton>
					</Flex>
				)}
			</Box>
		</Modal>
	);
}

RemarksForm.propTypes = {
	record: PropTypes.any,
	show: PropTypes.bool,
	successCallBack: PropTypes.func,
	onHide: PropTypes.func,
	remarksType: PropTypes.string,
};
