import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { DateFormat } from 'components';
import { Text, Box, Flex, Image, Button } from 'atoms';
import { RTFEyeIcons, getstatus } from 'utils';
import DownloadIcon from 'assets/download.svg';
import * as SVGICONS from 'utils/Svg';

const statusColor = (status) => {
	switch (status) {
		case 'Complete':
			return '#319901';
		case 'Pending':
			return '#FFA800';
		case 'Delivered':
			return '#319901';
		default:
			return '#000';
	}
};

function getReturnToFcTime(return_status, record) {
	return_status = return_status?.toLowerCase();

	if (return_status === 'complete') {
		return record?.return_process_completed_at;
	}
	return '';
}

const StatusContainer = styled(Box)`
	position: relative;
`;

const AbsoluteContainer = styled(Box)`
	position: absolute;
    background: #CA3D10;
    color: #fff;
    width: 23px;
    height: 16px;
    text-align: center;
    border-radius: 5px;
    top: -10px;
    left: 16px;
    font-size: 10px;
    z-index: revert-layer;
}
`;

function getQuidCOunt(bnpl_orders_count) {
	console.log(bnpl_orders_count);
	return (
		<StatusContainer>
			<AbsoluteContainer>{bnpl_orders_count}</AbsoluteContainer>
			<SVGICONS.QuidSalesOrderIcons />
		</StatusContainer>
	);
}

export const RTCReturnToFCListColumns = ({ defaultSort, defaultsortField, handleGeneratePDF }) => {
	const sortDirection = defaultSort === 'ASC' ? 'ascend' : 'descend';
	return [
		{
			title: '',
			dataIndex: 'bnpl_status',
			key: 'bnpl_status',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.bnpl_orders_count > 0 ? getQuidCOunt(record?.bnpl_orders_count) : ''}
				</Text>
			),
		},
		{
			title: 'Allocated Date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => a.createdAt - b.createdAt,
			sortOrder: defaultsortField === 'createdAt' ? sortDirection : false,
			render: (createdAt) => (
				<Text overFlow="hidden" fontSize="14px" color="#000">
					<DateFormat date={createdAt} />
				</Text>
			),
		},
		{
			title: 'Vehicle No',
			dataIndex: 'vehicle_no',
			key: 'vehicle_no',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="14px" color="#000">
					{record?.vehicle_no}
				</Text>
			),
		},
		{
			title: 'Driver Name',
			dataIndex: 'driver_name',
			key: 'driver_name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="14px" color="#000">
					{record?.driver_name}
					<Box fontSize="12px" color="#474747">
						{record?.driver_mobile}
					</Box>
				</Text>
			),
		},
		{
			title: 'Deliverer',
			dataIndex: 'first_name',
			key: 'first_name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="14px" color="#000">
					{record?.DeliveryExecutive?.first_name}
					<Box fontSize="12px" color="#474747">
						{record?.DeliveryExecutive?.mobile_no}
					</Box>
				</Text>
			),
		},
		{
			title: 'Delivery Status',
			dataIndex: 'status',
			key: 'status',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (status, record) => (
				<Text color={statusColor(status)} fontSize="12px">
					<Box>{status ? getstatus(status) : '-'}</Box>
					{status?.toLowerCase() !== 'pending' && getstatus(status) && (
						<Box fontSize="12px" color="#000">
							<DateFormat date={record?.status_updated_at} format="HMSA" />
						</Box>
					)}
				</Text>
			),
		},
		{
			title: 'Return to FC',
			dataIndex: 'return_status',
			key: 'return_status',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (return_status, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<Box>{return_status || ''}</Box>
					<DateFormat date={getReturnToFcTime(return_status, record)} format="HMSA" />
				</Text>
			),
		},
		{
			title: '',
			dataIndex: 'return_status',
			key: 'actions',
			sortId: 6,
			hidden: false,
			render: (return_status, record) => (
				<Flex>
					<Link
						to={`/logistics-management/return-to-fc-new/${record?.id}/show?quid=${record.bnpl_orders_count}`}
					>
						<RTFEyeIcons />
					</Link>
					{return_status?.toLowerCase() === 'complete' && (
						<Box
							ml="8px"
							style={{ cursor: 'pointer' }}
							onClick={() => handleGeneratePDF(record)}
						>
							{record?.downloadStatus === 'generate' && <Button>Generate PDF</Button>}
							{record?.downloadStatus === 'download' && (
								<Tooltip placement="top" title="Download">
									<Image
										src={DownloadIcon}
										alt="edit-icon"
										height={18}
										width={20}
									/>
								</Tooltip>
							)}
							{record?.downloadStatus === 'sync' && (
								<Tooltip placement="top" title="Sync">
									<Box ml="8px" style={{ cursor: 'pointer' }}>
										<SyncOutlined />
									</Box>
								</Tooltip>
							)}
						</Box>
					)}
				</Flex>
			),
		},
	];
};
