/* eslint-disable sonarjs/no-duplicate-string */
// https://coolors.co/ffffff

export const theme = {
	colors: {
		primary: '#153e60',
		Indigo: '#1b4965',
		EerieBlack: '#1C1C1C',
		Silver: '#777777',
		Floral: '#FFFAF3',
		SoftBlue: '#F6FDFF',
		SonicSilver: '#707070',
		Charcoal: '#374A56',
		Gold: '#CB8703',
		Rufous: '#B11500',
		SoftGray: '#808080',
		GrayWeb: '#838383',
		RichBlack: '#051215',
		Snow: '#FFF9F8',
		Azure: '#D4E8ED',
		Oynx: '#3F3F3F',
		SlimyGreen: '#319901',
		CarrotOrange: '#f09000',
		TartOrange: '#FF4747',
		Byzantine: '#D100B0',
		Ivory: '#F7FFF3',
		Green: '#267003',
		Bismark: '#506e88',
		MidnightGreen: '#0C4C59',
		EagleGreen: '#09424E',
		onyx: '#36313D',
		Capri: '#33B8F6',
		IndigoDye: '#113f5f',
		Cultured: '#f8f8f8',
		CulturedDark: '#eeeeee',
		SpaceCadet: '#17224D',
		YellowGreen: '#50AC08',
		YellowOrange: '#ffb056',
		white: '#fff',
		black: '#000',
		Onyx: '#454545',
		BeauBlue: '#C6E2FF',
		Jet: '#303030',
		GreenPantone: '#10a744',
		SizzlingRed: '#F74356',
		DodgerBlue: '#3298FF',
		Bisque: '#FFE7CB',
		Nyanza: '#E7FFD3',
		AliceBlue: '#DEEEFF',
		DavysGrey: '#545454',
		Ming: '#16697a',
		FireOpal: '#EE5743',
		platinum: '#e5e5e5',
		DarkMagenta: '#990189',
		PageBackground: '#F4F6F8',
		WarningRed: '#B93E5C',
		CeladonBlue: '#457b9d',
		error: '#ff4d4f',
		Gainsboro: '#d8d8d8',
		SteelPink: '#DD01C7',
		Isabelline: '#edebe9',
		BlackCoffee: '#352a35',
	},
	fontSizes: {
		small: '1em',
		medium: '2em',
		large: '3em',
		subText: '0.875em',
	},
};

export const orangeTheme = {
	colors: {
		...theme.colors,
		white: '#FFF',
		secondary: '#CA5010',
		primary: '#352A35',
		sideBarlinkColour: '#CA5010',
		breadcrumb: '#605E5C',
		breadcrumbHover: '#1A1A1A',
	},
};

export const oldTheme = {
	colors: {
		...theme.colors,
		white: '#FFF',
		secondary: 'rgb(21, 62, 96)',
		primary: '#153e60',
		sideBarlinkColour: '#fff',
		breadcrumb: 'rgb(27, 73, 101)',
		breadcrumbHover: 'rgb(21, 62, 96)',
	},
};
