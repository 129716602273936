import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const RupeeSymbol = styled.span`
	padding-right: 1px;
`;

export function getCurrency(roundOff, data = 0) {
	return new Intl.NumberFormat('en-IN', {
		style: 'currency',
		currency: 'INR',
		minimumFractionDigits: 0,
		maximumFractionDigits: roundOff,
	}).format(data);
}

// https://stackoverflow.com/a/54547602/3882241

export default function Rupee({ children, roundOff = 2, style = {} }) {
	return <span style={style}>{getCurrency(roundOff, children)}</span>;
}

Rupee.propTypes = {
	children: PropTypes.node,
	roundOff: PropTypes.number,
	style: PropTypes.object,
};
