import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { Text } from 'atoms';
import DateFormat from 'components/DateFormat';
import { UserPersmission } from 'utils/userPermission';
import { ACL } from 'components/common';

export const RetailCategoryColumns = [
	{
		title: 'Store Category Name',
		dataIndex: 'retail_category',
		key: 'retail_category',
		overflow: 'hidden',
		render: (retail_category) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				{retail_category || ''}
			</Text>
		),
	},
	{
		title: 'Store Class',
		dataIndex: 'retailer_class',
		key: 'retailer_class',
		overflow: 'hidden',
		sorter: (a, b) => a.retailer_class - b.retailer_class,
		render: (retailer_class) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				Class {retailer_class}
			</Text>
		),
	},
	{
		title: 'Created At',
		dataIndex: 'created_at',
		key: 'created_at',
		overflow: 'hidden',
		render: (created_at) => (
			<Text overFlow="hidden" fontSize="12px" color="#000">
				<DateFormat date={created_at} format="HMS" />
			</Text>
		),
	},
	{
		title: '',
		dataIndex: 'id',
		key: 'id',
		sortId: 6,
		hidden: false,
		render: (id) => (
			<ACL allowedGroups={UserPersmission.storeCategory_create}>
				<Link style={{ color: '#000' }} to={`/onboarding/retail-category/${id}/edit`}>
					<EditOutlined />
				</Link>
			</ACL>
		),
	},
];
