/* eslint-disable sonarjs/cognitive-complexity */

const removedUnderscore = (word) => word?.replace(/_/g, ' ');

export const returnsLogsShowPanel = ({ showData = {} }) => [
	{
		title: 'Return Number',
		value: showData?.return_no || '',
		type: 'upper',
	},
	{
		title: 'Return Date',
		type: 'date',
		value: showData?.return_date || '',
	},
	{
		title: 'Return Type',
		type: 'upper',
		value: (showData?.return_type === 'DR' ? 'Delivery Return' : 'Sales Return') || '',
	},
	{
		title: 'Return Value',
		type: 'rupee',
		value: showData?.return_value || '',
	},
	{
		title: 'FC',
		value: showData?.fc || '',
		additionalValue: showData?.FC?.code || '',
	},
	{
		title: 'Brand',
		value: showData?.brand || '',
		additionalValue: showData?.FC?.code || '',
	},
	{
		title: 'Status',
		type: 'upper',
		value: removedUnderscore(showData?.status) || '',
	},
	{
		title: 'Invoice No',
		value: showData?.invoice_no || '',
		type: 'upper',
	},

	{
		title: 'Store Name by',
		value: showData?.store || '',
	},

	{
		title: showData?.wms_return_number ? 'Wms Return Number' : '',
		value: showData?.wms_return_number || '',
	},

	{
		title: showData?.wms_credit_note_number ? 'Wms Credit Note Number' : '',
		value: showData?.wms_credit_note_number || '',
	},
];
