import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { message, Modal } from 'antd';
import { uniqBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { Box, Button, Flex, Text } from 'atoms';
import { postVehicleAllocate } from 'store/fulfilment/deliveryAllocationSlice';
import { VehicleAllocateForm } from 'components/forms';
import { Rupee } from 'components';

const E_BILL_AMOUNT = 50000;

function getEWayBillData(data, orders = []) {
	const onlySelected = uniqBy(
		data?.filter((item) => orders.includes(item?.id)),
		(x) => x.id
	);
	const moreThan50K = onlySelected?.filter(
		(item) => Number(item?.total_net_amount) >= E_BILL_AMOUNT
	);
	const totalTruckLoadValue =
		onlySelected?.reduce((sum, item) => sum + Number(item?.total_net_amount), 0) ?? 0;
	return { onlySelected, moreThan50K, totalTruckLoadValue, orders };
}

export function VehicleAllocateModal({
	toggleState,
	userlist = {},
	orders = [],
	rowData = [],
	selectedItem,
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [modalVisible, setModalVisible] = toggleState;
	const [innerPopup, setinnerPopup] = useState(false);
	const [deliverCount, setdeliverCount] = useState({ count: 0 });
	const selectedRow = rowData.find((data) => orders.find((o) => o === data.id));

	const handlePostAllocate = (res) => {
		const { delivery_boy_temperature, driver_temperature, count, ...rest } = res;
		return dispatch(
			postVehicleAllocate({
				data: {
					orders,
					...rest,
					fc_id: selectedRow?.fc_id,
					delivery_boy_temperature: +delivery_boy_temperature,
					driver_temperature: +driver_temperature,
				},
			})
		).then(() => {
			setModalVisible(false);
			message.info('Orders have been allocated');
			navigate('/logistics-management/delivery-allocation');
		});
	};

	return (
		<Modal
			destroyOnClose
			centered
			title={
				<Flex justifyContent="space-between">
					<Text>Vehicle Allocation</Text>
					<Flex pr="2rem" fontSize="14px">
						<Text pr="0.5rem">Vehicle Load Value : </Text>
						<Rupee>{getEWayBillData(rowData, orders)?.totalTruckLoadValue}</Rupee>
					</Flex>
				</Flex>
			}
			visible={modalVisible}
			onOk={() => setModalVisible(false)}
			onCancel={() => setModalVisible(false)}
			footer={null}
			width="70%"
			maskClosable={false}
		>
			<VehicleAllocateForm
				toggleInnerpopup={[innerPopup, setinnerPopup]}
				toggleState={[modalVisible, setModalVisible]}
				toggledeliverCount={[deliverCount, setdeliverCount]}
				userlist={userlist}
				eWayBillData={getEWayBillData(rowData, orders)}
				selectedItem={selectedItem}
			/>
			{innerPopup && (
				<Modal
					centered
					destroyOnClose
					visible={innerPopup}
					onOk={() => setinnerPopup(false)}
					onCancel={() => setinnerPopup(false)}
					footer={null}
					width="50%"
					maskClosable={false}
				>
					<Box>
						<Box textAlign="center">
							{`Delivery boy already has been assigned to ${deliverCount.count} delivery(s), Do you want to continue!`}
						</Box>
						<Box px="22%" mt="20px" textAlign="center">
							<Button onClick={() => setModalVisible(false)}>No</Button>
							<Button onClick={() => handlePostAllocate()}>Yes</Button>
						</Box>
					</Box>
				</Modal>
			)}
		</Modal>
	);
}

VehicleAllocateModal.propTypes = {
	toggleState: PropTypes.array,
	userlist: PropTypes.object,
	orders: PropTypes.array,
	rowData: PropTypes.array,
	selectedItem: PropTypes.array,
};
