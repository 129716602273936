import React, { useState } from 'react';
import { Select } from 'antd';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Button } from 'atoms';
import * as SVGICONS from 'utils/Svg';
import { RCTable, ACL, LabelWrapper, SearchBar } from 'components/common';
import { SearchField } from 'components/common/custom-table/Style';
import {
	bankListColumns,
	UserPersmission,
	handleFilterChange,
	handleSearch,
	SelectFilterLabelOptions,
	searchBox,
	BankQueries,
} from 'utils';
import { getBankList } from 'store/masters';
import { useQueryParams } from 'hooks';

export default function Bank() {
	const navigate = useNavigate();
	const [rowData, setRowData] = useState({});
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [paginateConfig, setpaginateConfig] = useQueryParams({
		filterQuery: BankQueries,
	});
	return (
		<>
			<Flex justifyContent="flex-end">
				<Flex width="100%" flexWrap="wrap" alignItems="center" gap="4px">
					<LabelWrapper label="Search By" marginspace="3px 0 -1px 1rem">
						<Select
							name="fields"
							style={searchBox}
							allowClear
							label="Search By"
							showSearch
							filterOption={SelectFilterLabelOptions}
							value={paginateConfig?.fields}
							options={[
								{ value: 'name', label: 'Name' },
								{ value: 'code', label: 'Code' },
							]}
							placeholder="Search By"
							onChange={(value) =>
								setpaginateConfig((prev) => {
									const fieldsValue = handleFilterChange('fields', value, prev);
									return {
										...fieldsValue,
										...(isEmpty(value) ? { query: '' } : {}),
									};
								})
							}
						/>
					</LabelWrapper>
					{!isEmpty(paginateConfig?.fields) && (
						<LabelWrapper label="Search" marginspace="-1px 0 5px 0">
							<SearchField>
								<SearchBar
									placeholder="Search"
									queryValue={paginateConfig?.query?.replaceAll('*', '')}
									onSearch={(val) => ({
										...setpaginateConfig((prev) => handleSearch(val, prev)),
										...(isEmpty(val) ? { query: '' } : {}),
									})}
								/>
							</SearchField>
						</LabelWrapper>
					)}
					<Box mt=".75rem">
						<Button onClick={() => setBtnClickSearh(Math.random())}>Search</Button>
					</Box>
				</Flex>
				<ACL allowedGroups={UserPersmission.bank_create}>
					<Button
						height="35px"
						alignSelf="center"
						onClick={() => navigate('/onboarding/masters/bank/add')}
						type="variant"
					>
						<Flex alignItems="center" justifyContent="center">
							<Box width={30}>
								<SVGICONS.PlusOutlinedIcons />
							</Box>
							<Box>Add Bank</Box>
						</Flex>
					</Button>
				</ACL>
			</Flex>
			<Box mt={3}>
				<RCTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					dispatchAction={getBankList}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					columns={bankListColumns({
						defaultSort: paginateConfig?.sort_direction || 'ASC',
						defaultsortField: paginateConfig?.sort_column || 'name',
					})}
					configs={{ defaultSort: 'ASC', defaultsortField: 'name' }}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
		</>
	);
}
