import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from 'antd';
import { Text, Image, Flex, Button } from 'atoms';
import errorIcon from 'assets/icons/error-alert.svg';

const ModalTitleConatiner = styled(Flex)`
	align-items: center;
	gap: 10px;
	color: #ca5010;
`;

export function ReturnsLogsErrorAlert({ toggleState, rowData, onCancel }) {
	const [modalVisible, setModalVisible] = toggleState;

	return (
		<Modal
			destroyOnClose
			centered
			title={
				<ModalTitleConatiner>
					<Image src={errorIcon} alt="edit-icon" height={15} width={20} />
					<Text>Error</Text>
				</ModalTitleConatiner>
			}
			visible={modalVisible}
			width="60%"
			maskClosable={false}
			onCancel={() => setModalVisible(false)}
			footer={[
				<Button key="Close" onClick={onCancel} style={{ borderRadius: '2px' }}>
					Close
				</Button>,
			]}
		>
			{rowData}
		</Modal>
	);
}

ReturnsLogsErrorAlert.propTypes = {
	toggleState: PropTypes.array,
	rowData: PropTypes.string,
	onCancel: PropTypes.any,
};
