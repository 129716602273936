/* eslint-disable sonarjs/cognitive-complexity */
import { Rupee } from 'components';
import { utcToLocDT } from 'utils';

export const billStatusOption = (type) => {
	console.log(type);
	switch (type) {
		case 'Bill Back':
			return 'Invoice Returned';
		case 'No Bill Back':
			return 'Invoice Settled';
		case 'Pending':
			return 'Pending';
		case 'Voucher Back':
			return 'Voucher Returned';
		case 'No Voucher Back':
			return 'Voucher Settled';
		case 'Voucher':
			return 'Voucher';
		case 'Void Bill':
			return 'Void Bill';
		case 'Unattended':
			return 'Unattended';
		default:
			return '';
	}
};

export const returnToFcShowPanelNew = ({ invoice = {}, order = {} }) => [
	{
		title: 'FC',
		value: order?.fc_name || '-',
		additionalValue: order?.fc_code || '',
	},
	{
		title: 'Brand',
		value: order?.brand_name || '',
	},
	{
		title: 'Salesman',
		value: order?.salesman_name || '',
	},
	{
		title: 'Created At',
		value: utcToLocDT(order?.createdAt),
	},
	{
		title: 'Order No',
		value: order?.order_no.toUpperCase() || '',
	},
	{
		title: 'Ordered Date',
		type: 'date',
		value: order?.order_date || '',
	},
	{
		title: 'Order value',
		type: 'rupee',
		value: invoice?.invoice_amount || 0,
	},
	{
		title: 'Invoice Date',
		type: 'date',
		value: invoice?.invoice_date || '',
	},
	{
		title: 'Additional Discount',
		type: 'rupee',
		value: order?.brandAjustment?.valueData || 0,
		infoDetails: [order, 'Brand Adjustment'],
	},
	{
		title: 'Credit Adjustment',
		type: 'rupee',
		value: order?.brandAjustment?.credit_note || 0,
	},
	{
		title: 'Delivered Amount',
		value: <Rupee roundOff={0}>{order?.delivered_qty_amount || 0}</Rupee>,
	},
	{
		title: 'Collectable Amount',
		value:
			order?.status === 'DL' || order?.status === 'PD' ? (
				<Rupee roundOff={0}>{invoice?.initial_outstanding_amount || 0}</Rupee>
			) : (
				0
			),
	},
	{
		title: 'Collected Amount',
		value: <Rupee roundOff={0}>{invoice?.collected_amount || 0}</Rupee>,
	},
	{
		title: 'Outstanding Amount',
		value: <Rupee roundOff={0}>{invoice?.current_outstanding_amount || 0}</Rupee>,
	},
	{
		title: 'Invoice Status',
		value: billStatusOption(invoice?.status) || '0',
	},
];
