import { nanoid } from 'nanoid';
import { AccordianColumns, InvoiceSettledColumns } from './TableConfigs';
import { OrderStatusSubtitle } from './styles';

export const downloadPdf = (downloadUrl) => {
	const downloadLink = document.createElement('a');
	downloadLink.href = downloadUrl;
	downloadLink.setAttribute('download', 'filename.ext'); // Set the filename here
	// Simulate a click on the anchor element to trigger the download
	document.body.appendChild(downloadLink);
	downloadLink.click();
	// Clean up
	document.body.removeChild(downloadLink);
};

export const getModalTableProps = (
	orderStatus,
	ordermissing,
	notinterminal,
	unverified,
	invoice_settled
) => {
	switch (orderStatus) {
		case 'ordermissing':
			return {
				title: 'Order Missing',
				key: 'id_nanoid',
				subtitle: (
					<OrderStatusSubtitle>
						There are <strong>{ordermissing?.data?.count}</strong> orders that are
						missing in CDMS please connect with Billing Executive for further process.
					</OrderStatusSubtitle>
				),
				loading: ['Loading', 'NotAsked'].includes(ordermissing.type),
				columns: AccordianColumns(ordermissing?.data?.data, 'order_missing'),
				dataSource: ordermissing?.data?.data?.map((item) => ({
					...item,
					id_nanoid: nanoid(),
					/**
					 * From BE they don't send any unqiue id, so from FE we are generating that
					 */
				})),
			};
		case 'undelivered':
			return {
				title: 'Undelivered',
				key: 'id_nanoid',
				subtitle: (
					<OrderStatusSubtitle>
						There are <strong>{notinterminal?.data?.count}</strong> Invoices that are in
						Undelivered Status please connect with RTO for further process.
					</OrderStatusSubtitle>
				),
				loading: ['Loading', 'NotAsked'].includes(notinterminal.type),
				columns: AccordianColumns(notinterminal?.data?.data, 'undelivered'),
				dataSource: notinterminal?.data?.data?.map((item) => ({
					...item,
					id_nanoid: nanoid(),
					/**
					 * From BE they don't send any unqiue id, so from FE we are generating that
					 */
				})),
			};
		case 'unverified':
			return {
				key: 'id_nanoid',
				title: 'Unverified',
				subtitle: (
					<OrderStatusSubtitle>
						There are <strong>{unverified?.data?.count}</strong> Invoices for which
						cashier verification is pending, please connect with Segregator or Cashier
						Team for further process.
					</OrderStatusSubtitle>
				),
				loading: ['Loading', 'NotAsked'].includes(unverified.type),
				columns: AccordianColumns(unverified?.data?.data, 'unverified'),
				dataSource: unverified?.data?.data?.map((item) => ({
					...item,
					id_nanoid: nanoid(),
					/**
					 * From BE they don't send any unqiue id, so from FE we are generating that
					 */
				})),
			};
		case 'invoice_settled':
			return {
				key: 'id_nanoid',
				title: 'Invoice Settled',
				subtitle: (
					<OrderStatusSubtitle>
						There are <strong>{invoice_settled?.data?.count}</strong> Invoices Settled
					</OrderStatusSubtitle>
				),
				loading: ['Loading', 'NotAsked'].includes(invoice_settled.type),
				columns: InvoiceSettledColumns(invoice_settled?.data?.data, 'invoice_settled'),
				dataSource: invoice_settled?.data?.data?.map((item) => ({
					...item,
					id_nanoid: nanoid(),
					/**
					 * From BE they don't send any unqiue id, so from FE we are generating that
					 */
				})),
			};
		default:
			return '';
	}
};
