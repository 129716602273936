/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Input, Form, Select as FormikSelect } from 'formik-antd';
import { Row, Col } from 'antd';
import { FormInput, Box } from 'atoms';
// import { StyledSubmitButton } from 'components/forms/masters/helper';
import { Debug } from 'components/forms';
import {
	ACTIVE_INACTIVE_CHOICE,
	BRAND_CHOICE,
	PackMasterFormSchema,
	PackMasterInitialValues,
	UOM_CHOICE,
} from '../onboarding/helper';
import { SelectFilterLabelOptions } from 'utils';
// import { CreateUpdatePackMaster, getPackMasterId } from 'store/PackMasterSlice';
import { StyledSubmitButton } from './helper';
import { CreateUpdatePackMaster, getPackMasterId } from 'store/masters';

export default function PackMasterEditCreateForm() {
	const navigate = useNavigate();
	const { id: packmasterId } = useParams();
	const { pathname } = useLocation();
	const [formInitial, setFormInitial] = useState({});
	const [brandValue, setBrandValue] = useState([]);
	const dispatch = useDispatch();
	const isEditPage = pathname.includes('edit');
	// const { auth = {} } = useSelector((state) => state.auth);

	useEffect(() => {
		const hasEditForm = isEditPage
			? dispatch(getPackMasterId(packmasterId))
			: Promise.resolve();
		hasEditForm.then((resp) => {
			setFormInitial(
				isEditPage ? PackMasterInitialValues(resp?.data) : PackMasterInitialValues()
			);
			return true;
		});
	}, [dispatch, packmasterId, isEditPage]);
	const handleSubmit = (values, { setSubmitting, setErrors }) => {
		const { code, ...rest } = values;
		const payload = isEditPage ? { ...rest, id: +packmasterId } : { ...rest };

		dispatch(CreateUpdatePackMaster({ data: payload, setErrors }))
			.then(() => {
				navigate('/onboarding/masters/packmaster');
			})
			.finally(() => {
				setSubmitting(false);
			});
		setBrandValue(values ? brandValue[values]?.map((x) => ({ label: x, value: x })) : []);
	};

	return (
		<Box>
			<Formik
				initialValues={formInitial}
				validationSchema={PackMasterFormSchema}
				enableReinitialize
				onSubmit={handleSubmit}
			>
				{() => (
					<Form layout="vertical">
						<Box fontSize="16px" fontWeight="700" padding="1rem 0">
							{isEditPage ? 'Edit Details' : 'Add Details'}
						</Box>
						<Row gutter={20}>
							<Col span={8}>
								{/* <FormInput name="brand" label="*Brand" style={{ flex: 1 }}>
									<FormikSelect
										showSearch
										className="customDropdown"
										name="brand_code"
										placeholder="Select Brand"
										filterOption={SelectFilterLabelOptions}
										disabled={isEditPage}
										options={
											auth?.user?.Brands?.map((x) => ({
												label: `${x?.code || ''}: ${x?.name || ''}`,
												value: x?.code,
											})) || []
										}
										// value={formInitial.brand}
										defaultValue={
											auth?.user?.Brands?.data?.filter(
												(x) => x?.code === x?.brand_code
											)[0] || null
										}
									/>
								</FormInput> */}
								<FormInput name="brand_code" label="*Brand">
									<FormikSelect
										name="brand_code"
										// allowClear
										// showSearch
										disabled={isEditPage}
										filterOption={SelectFilterLabelOptions}
										options={BRAND_CHOICE}
										placeholder="Select Brand"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="product_name" label="Product Name">
									<Input
										disabled={isEditPage}
										name="product_name"
										placeholder="Enter Product Name"
										value={formInitial.product_name}
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="product_code" label="*Product Code">
									<Input
										disabled={isEditPage}
										name="product_code"
										placeholder="Enter Product Code"
									/>
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="uom" label="*UOM">
									<FormikSelect
										disabled={isEditPage}
										name="uom"
										placeholder="Select UOM"
										filterOption={SelectFilterLabelOptions}
										options={UOM_CHOICE}
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="uom_description" label="UOM Description">
									<Input.TextArea
										disabled={isEditPage}
										name="uom_description"
										placeholder="Enter UOM Description"
									/>
								</FormInput>
							</Col>
							<Col span={8}>
								<FormInput name="conversion_qty" label="*Conversion Qty">
									<Input
										disabled={isEditPage}
										name="conversion_qty"
										placeholder="Enter Conversion Qty"
									/>
								</FormInput>
							</Col>
						</Row>
						<Row gutter={20}>
							<Col span={8}>
								<FormInput name="is_active" label="*Status">
									<FormikSelect
										name="is_active"
										allowClear
										showSearch
										disabled={!isEditPage}
										filterOption={SelectFilterLabelOptions}
										options={ACTIVE_INACTIVE_CHOICE}
										defaultValue={
											isEditPage
												? formInitial.is_active == 1
													? 'Active'
													: 'Inactive'
												: 'Active'
										}
										placeholder="Select Status"
									/>
								</FormInput>
							</Col>
						</Row>
						{/* <Button type="submit">Save </Button> */}
						<StyledSubmitButton>Submit</StyledSubmitButton>
						<Debug show />
					</Form>
				)}
			</Formik>
		</Box>
	);
}
