import React from 'react';
import { Col, Row } from 'antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Form, Input, SubmitButton } from 'formik-antd';
import styled from 'styled-components';
import * as Yup from 'yup';
import { FormInput, Flex } from 'atoms';
const BtnWrap = styled(Flex)`
	justify-content: flex-end;
`;

export const StyledSubmitButton = styled(SubmitButton)`
	border: 0;
	border-radius: 0;
	background-color: ${(props) => props.theme.colors.primary};
	color: ${(props) => props.theme.colors.white};
	&:hover,
	&:active {
		background-color: ${(props) => props.theme.colors.primary};
		color: ${(props) => props.theme.colors.white};
	}
`;

const initialValues = {
	current_password: undefined,
	password: undefined,
	confirm_password: undefined,
};
const FormSchema = (onlyChangePassword) =>
	Yup.object({
		current_password: !onlyChangePassword
			? Yup.string().required().label('Current Password')
			: Yup.string(),
		password: Yup.string()
			.required()
			.label('New Password')
			.min(6)
			.max(16)
			.matches(
				'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$])(?![!@#$])[a-zA-Z0-9@#$!]+$',
				'Password should contain upper case letter[A-Z], lower case letter[a-z],number[0-9] and special characters[!@#$] (password should not start with a special character)'
			),
		confirm_password: Yup.string()
			.label('Confirm Password')
			.min(6)
			.max(16)
			.matches(
				'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$])(?![!@#$])[a-zA-Z0-9@#$!]+$',
				'Password should contain upper case letter[A-Z], lower case letter[a-z],number[0-9] and special characters[!@#$] (password should not start with a special character)'
			)
			.required()
			.when('password', {
				is: (val) => !!(val && val.length > 0),
				then: Yup.string().oneOf(
					[Yup.ref('password')],
					'New Password and Confirm Password need to be the same'
				),
			}),
	});

export function ChangePasswordForm({ handleSubmit, onlyChangePassword = false }) {
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={FormSchema(onlyChangePassword)}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{({ isSubmitting }) => (
				<Form layout="vertical">
					<Row gutter={24}>
						<Col lg={8} sm={24} hidden={onlyChangePassword}>
							<FormInput
								name="current_password"
								label="*Current Password"
								className="test-password"
							>
								<Input.Password type="password" name="current_password" />
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput
								name="password"
								label="*New Password"
								className="test-password"
							>
								<Input.Password type="password" name="password" />
							</FormInput>
						</Col>
						<Col lg={8} sm={24}>
							<FormInput
								name="confirm_password"
								label="*Confirm Password"
								className="test-password"
							>
								<Input.Password type="password" name="confirm_password" />
							</FormInput>
						</Col>
					</Row>

					<BtnWrap>
						<StyledSubmitButton disabled={isSubmitting}>Update</StyledSubmitButton>
					</BtnWrap>
				</Form>
			)}
		</Formik>
	);
}

ChangePasswordForm.propTypes = {
	handleSubmit: PropTypes.func,
	onlyChangePassword: PropTypes.bool,
};
