/* eslint-disable sonarjs/cognitive-complexity */
import { isEmpty } from 'lodash';
import { EyeOutlined, SyncOutlined, WarningOutlined, DownloadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Text, Box, Flex } from 'atoms';
import { theme } from 'styles/theme';
import DateFormat from 'components/DateFormat';
import { ORDER_STATUSES, UPLOAD_FILE_TYPE_CHOICE } from 'utils';

export const FILE_STATUS_CHOICE = [
	{ id: 'file_validated', name: 'File Validated', color: '#17A2B8', background: '#E2F6F6' },
	{ id: 'processing_error', name: 'Processing Error', color: '#FF0000', background: '#FCE4E4' },
	{
		id: 'partially_processed',
		name: 'Partially Processed',
		color: '#b3730f',
		background: '#ffe7c1',
	},
	{ id: 'fully_processed', name: 'Fully Processed', color: '#0A9600', background: '#DFFFDD' },
	{ id: 'uploaded', name: 'Uploaded', color: '#FF00FF', background: '#FFE7FF' },
	{ id: 'started', name: 'Started', color: '#17A2B8', background: '#E2F6F6' },
	{ id: 'processing', name: 'Processing', color: '#fff', background: '#4B0082' },
	{ id: 'processed', name: 'Processed', color: '#17A2B8', background: '#E2F6F6' },
	{ id: 'validation_error', name: 'Validation Error', color: '#FF0000', background: '#FCE4E4' },
	{ id: 'execution_error', name: 'Execution Error', color: '#FF0000', background: '#FCE4E4' },
	{ id: 'picking', name: 'Ready for Picking', color: '#0000FF', background: '#E2E2FF' },
	{ id: 'put', name: 'Ready for Vehicle Allocation', color: '#0000FF', background: '#E2E2FF' },
	{ id: 'out_for_delivery', name: 'Out for Delivery', color: '#FF00FF', background: '#FFE7FF' },
	...ORDER_STATUSES.map((x) => ({ id: x.value, name: x.label })),
];

const hasErrorTypes = [
	'uploaded',
	'processing',
	'processed',
	'validation_error',
	'execution_error',
	'picking',
	'file_validated',
	'processing_error',
];

const hasErrorTypesRefresh = [
	'uploaded',
	'processing',
	'file_validated',
	'partially_processed',
	'picking',
	'fully_processed',
];

export const UploadListColumns = ({
	handleClick,
	handleDownloadExcel,
	setErrorModalVisible,
	handleStatusModalVisibles,
	defaultSort,
	downloadFiles,
	defaultsortField,
}) => {
	const sortDirection = defaultSort === 'ASC' ? 'ascend' : 'descend';
	return [
		{
			title: 'Uploaded File',
			dataIndex: 'fileName',
			key: 'fileName',
			overflow: 'hidden',
			width: '30%',
			render: (fileName, record) => {
				let executionStatusJSON = {};
				try {
					executionStatusJSON =
						record && record.executionStatus ? JSON.parse(record.executionStatus) : {};
				} catch (error) {
					executionStatusJSON = {
						error: 'Unable to parse error.',
						details: 'Error is too large. Please contact support team for more details',
					};
				}

				const error_message = `${executionStatusJSON.error} \n ${executionStatusJSON.details}`;
				const hideEye = hasErrorTypes.indexOf(record?.fileStatus) !== -1;
				const hideRefresh = hasErrorTypesRefresh.indexOf(record?.fileStatus) === -1;

				return (
					<Flex
						style={{
							whiteSpace: 'normal',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
						overFlow="hidden"
						fontSize="12px"
						color="#000"
					>
						<Box
							cursor="pointer"
							color="#0077b6"
							onClick={() => {
								if (record?.fileType !== 'picklite_order_adapter') {
									handleDownloadExcel(record?.id);
								}
							}}
						>
							{fileName ? fileName.substr(fileName.lastIndexOf('/') + 1) : ''}
						</Box>
						<Flex>
							{record?.fileType === 'picklite_order_adapter' && (
								<Box
									fontSize="18px"
									padding="5px"
									cursor="pointer"
									onClick={() => downloadFiles(record)}
								>
									<DownloadOutlined />
								</Box>
							)}
							<Box
								fontSize="18px"
								padding="5px"
								style={{ color: theme.colors.error }}
								hidden={isEmpty(executionStatusJSON.error)}
							>
								<Tooltip
									title={
										error_message.length > 100
											? `${error_message?.slice(0, 75)}...`
											: error_message
									}
								>
									<Box
										onClick={() =>
											setErrorModalVisible({
												show: true,
												message: error_message,
											})
										}
									>
										<WarningOutlined />
									</Box>
								</Tooltip>
							</Box>
							<Box
								fontSize="18px"
								padding="5px"
								cursor="pointer"
								hidden={hideEye}
								onClick={() => handleClick(record?.id)}
							>
								<EyeOutlined />
							</Box>
							{record?.fileType === 'picklite_order_adapter' && hasErrorTypesRefresh && (
								<Box
									fontSize="18px"
									padding="5px"
									cursor="pointer"
									hidden={hideRefresh}
									onClick={() =>
										handleStatusModalVisibles({
											title: record?.fileName
												? `File: ${record?.fileName.substr(
														// eslint-disable-next-line no-unsafe-optional-chaining
														record?.fileName?.lastIndexOf('/') + 1
												  )}`
												: '',
											filename: record?.fileName,
											user_id: record?.uploadedById,
										})
									}
								>
									<SyncOutlined />
								</Box>
							)}
						</Flex>
					</Flex>
				);
			},
		},
		{
			title: 'Status',
			dataIndex: 'fileStatus',
			key: 'fileStatus',
			overflow: 'hidden',
			width: '15%',
			render: (fileName) => {
				const getStatusName = FILE_STATUS_CHOICE.filter((x) => x.id === fileName);
				const statusName = isEmpty(getStatusName)
					? { name: 'unknown status', color: '#33b5e5', background: '#FFE7FF' }
					: getStatusName[0];

				return (
					<Flex
						style={{
							background: statusName?.background || '#FFE7FF',
							borderRadius: '6px',
							padding: '8px',
							justifyContent: 'center',
						}}
					>
						<Flex marginRight="5px">
							<Box
								as="span"
								width="10px"
								height="10px"
								borderRadius="50%"
								margin="auto"
								background={statusName?.color || '#FFE7FF'}
							/>
						</Flex>
						<Text
							style={{ whiteSpace: 'normal' }}
							overFlow="hidden"
							fontSize="12px"
							lineHeight="14px"
							color={statusName.color || '#33b5e5'}
						>
							{statusName.name}
						</Text>
					</Flex>
				);
			},
		},
		{
			title: 'Uploaded For',
			dataIndex: 'fileType',
			key: 'fileType',
			overflow: 'hidden',
			render: (fileType, record) => {
				const count = record
					? record.totalGRNs +
					  record.totalOrders +
					  record.totalSalesReturns +
					  record.totalStockAdjustments
					: null;
				return (
					<Text overFlow="hidden" fontSize="12px" color="#000">
						{UPLOAD_FILE_TYPE_CHOICE.filter((x) => x.id === fileType)[0]?.name || ''}
						{record?.fileType !== 'picklite_order_adapter' && (
							<span>&nbsp;{count ? `(${count || ''})` : ''}</span>
						)}
					</Text>
				);
			},
		},
		{
			title: 'Pickers',
			dataIndex: 'Pickers',
			key: 'Pickers',
			overflow: 'hidden',
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.Pickers?.map((x) => `${x.full_name || ''}`).join(', ') || ''}
				</Text>
			),
		},
		{
			title: 'FC',
			dataIndex: 'FC',
			key: 'FC',
			overflow: 'hidden',
			render: (fc) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{fc?.name || ''}
				</Text>
			),
		},
		{
			title: 'Brand',
			dataIndex: 'Brand',
			key: 'Brand',
			overflow: 'hidden',
			render: (x) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{x?.name || ''}
				</Text>
			),
		},
		{
			title: 'Created at',
			dataIndex: 'createdAt',
			key: 'createdAt',
			overflow: 'hidden',
			sorter: (a, b) => a.x - b.x,
			sortOrder: defaultsortField === 'createdAt' ? sortDirection : false,
			render: (x) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={x} format="HMSA" />
				</Text>
			),
		},
	];
};

export const UploadViewListColumns = [
	{
		title: 'Invoice no',
		dataIndex: 'invoice_no',
		key: 'invoice_no',
		overflow: 'hidden',
		sorter: (a, b) => a.invoice_no - b.invoice_no,
		render: (invoice_no) => (
			<Box overFlow="hidden" fontSize="12px" color="#000">
				{invoice_no || ''}
			</Box>
		),
	},
	{
		title: 'Store',
		dataIndex: 'Store',
		key: 'Store',
		overflow: 'hidden',
		width: '30%',
		render: (Store) => (
			<Box style={{ whiteSpace: 'normal' }} overFlow="hidden" fontSize="12px" color="#000">
				{Store?.name || ''} ({Store?.code ? `${Store?.code}` : ''})
			</Box>
		),
	},
	{
		title: 'Brand',
		dataIndex: 'Brand',
		key: 'Brand',
		overflow: 'hidden',
		width: '30%',
		render: (Brand) => (
			<Box style={{ whiteSpace: 'normal' }} overFlow="hidden" fontSize="12px" color="#000">
				{Brand?.name || ''}
			</Box>
		),
	},
	{
		title: 'Location',
		dataIndex: 'location',
		key: 'location',
		overflow: 'hidden',
		render: (location) => (
			<Box overFlow="hidden" fontSize="12px" color="#000">
				{location || ''}
			</Box>
		),
	},
	{
		title: 'Delivery code',
		dataIndex: 'delivery_code',
		key: 'delivery_code',
		overflow: 'hidden',
		render: (delivery_code) => (
			<Box overFlow="hidden" fontSize="12px" color="#000">
				{delivery_code || ''}
			</Box>
		),
	},
	{
		title: 'Crate Count',
		dataIndex: 'crates',
		key: 'crates',
		overflow: 'hidden',
		render: (crates) => (
			<Box overFlow="hidden" fontSize="12px" color="#000">
				{crates || 0}
			</Box>
		),
	},
];
