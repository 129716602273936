/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { InputNumber, message, Modal } from 'antd';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import deleteButton from 'assets/icons/deleteButton.svg';
import { SearchBar, LabelWrapper } from 'components/common';
import { Box, Text, Flex, Button, Image } from 'atoms';
import { SearchField } from 'components/common/custom-table/Style';
import Rupee from 'components/Rupee';
import { Thead, RFCTable } from 'components/forms/return-to-fc';
import {
	ShowSalesReturns,
	getSalesReturnsList,
	getProductList,
} from 'store/fulfilment/returnsLogsSlice';

const ValueText = styled(Text)`
	color: #343434;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 550;
	line-height: 24px; /* 120% */
	margin-top: 16px;
`;

const TitleText = styled(Text)`
	color: #000;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 171.429% */
`;

const ButtonContainer = styled(Flex)`
	margin-top: 40px;
	margin-bottom: 40px;
	gap: 14px;
	justify-content: right;
`;

const CancelBtn = styled(Button)`
	border-radius: 4px;
	border: 1px solid #ca5010;
	background: #fff;
	color: #ca5010;
`;

const SaveBtn = styled(Button)`
	border-radius: 4px;
`;

const ProductItem = styled(Flex)`
	&:hover {
		color: #ca5010;
	}
`;

const ModalTitleConatiner = styled(Flex)`
	align-items: center;
	gap: 10px;
	color: #ca5010;
`;

export default function AddSalesReturn() {
	// console.log('addes sales return ');

	const [rowData, setRowData] = useState({});
	const [searchVal, setSearchVal] = useState('');
	const [tableData, setTableData] = useState([]);
	const [showList, setShowList] = useState(false);
	const [searchedList, setSearchedList] = useState([]);
	const [fullSalesReturnLists, setFullSalesReturnLists] = useState([]);
	const [deleteId, setDeleteId] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [disableSave, setDisableSave] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const showModal = () => {
		// console.log('came inside the show modal');
		if (!isModalOpen) {
			setIsModalOpen(true);
		}
	};

	const handleOk = (pId) => {
		setIsModalOpen(false);
		DeleteProductOnClick(pId);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		// console.log('came inside first');
		dispatch(ShowSalesReturns(id))
			.then((res) => setRowData(res))
			.catch((e) => {
				// console.log('e', e);
				if ('response' in e && e?.response?.status === 422) {
					message.error(`${e?.response?.data?.message}`);
					setDisableSave(true);
					navigate(`/order-management/returns/`);
				} else {
					// eslint-disable-next-line sonarjs/no-duplicate-string
					message.error('Error while creating the Sales Return');
				}
			});
	}, [dispatch, id]);

	useEffect(() => {
		// console.log('came inside first');
		dispatch(
			getProductList({
				query: searchVal,
				order_id: id,
			})
		)
			.then((res) => {
				// console.log('res ', res);
				const uniqueList = [];
				if (res.length) {
					for (let i = 0; i < res.length; i++) {
						const isIn2ndArr = tableData.find((x) => x.id === res[i].id);
						if (!isIn2ndArr) uniqueList.push(res[i]);
					}
					setFullSalesReturnLists(res);
				}
				setSearchedList(uniqueList);
				if (res.length === tableData.length || !uniqueList.length) {
					setDisableButton(true);
				} else {
					setDisableButton(false);
				}
			})
			.finally(() => {
				console.log('success');
			});
	}, [dispatch, tableData, id, searchVal]);

	// const deleteItems = (ids) => {
	// 	console.log('id ', ids);
	// 	showModal();
	// 	setDeleteId(ids);
	// };

	const salesReturnTable = [
		{
			title: 'Product Name',
			dataIndex: 'product_name',
			key: 'product_name',
			render: (product_name, record) => (
				<>
					<Text>
						{product_name}({record?.product_code})
					</Text>
					<Rupee>{record?.mrp || 0}</Rupee>
				</>
			),
		},
		{
			title: <Thead>Returnable Qty</Thead>,
			dataIndex: 'returnable_qty',
			key: 'returnable_qty',
			render: (returnable_qty) => <Text>{returnable_qty}</Text>,
		},
		{
			title: <Thead>Sales Return Qty</Thead>,
			dataIndex: 'sales_returned_qty',
			key: 'sales_returned_qty',
			render: (sales_returned_qty, record, index) => {
				const returnableQty = record?.returnable_qty || 0;
				return (
					<InputNumber
						min={1}
						max={returnableQty}
						disabled={rowData?.state === 'mark_as_received'}
						value={record?.sales_returned_qty || 0}
						onChange={(value) => {
							const goodQty = value - (record?.bad_qty || 0);

							setTableData(
								tableData?.map((roww, i) =>
									i === index
										? {
												...record,
												sales_returned_qty: value,
												good_qty: goodQty,
										  }
										: roww
								)
							);
						}}
					/>
				);
			},
		},

		{
			title: <Thead>Bad Qty</Thead>,
			// dataIndex: 'bad_qty',
			key: 'bad_qty',
			render: (bad_qty, record, index) => {
				const returnedQty = record?.sales_returned_qty || 0;
				return (
					<InputNumber
						min={0}
						max={returnedQty}
						disabled
						value={record?.bad_qty || 0}
						onChange={(value) => {
							const goodQty = returnedQty - value;
							setTableData(
								tableData?.map((roww, i) =>
									i === index
										? {
												...record,
												bad_qty: value,
												good_qty: goodQty,
										  }
										: roww
								)
							);
						}}
					/>
				);
			},
		},
		{
			title: <Thead>Good Qty</Thead>,
			// dataIndex: 'good_qty',
			key: 'good_qty',
			render: (good_qty, record) => (
				<InputNumber min={0} disabled value={record?.good_qty || 0} />
			),
		},
		{
			title: '',
			className: 'border',
			dataIndex: 'actions',
			key: 'actions',
			render: (_, record) => (
				<Flex justifyContent="center">
					<Image
						src={deleteButton}
						alt="delete-icon"
						onClick={() => {
							showModal();
							setDeleteId(record?.id);
						}}
						style={{
							cursor: 'pointer',
						}}
					/>
					{/* <Modal
							destroyOnClose
							title={
								<ModalTitleConatiner>
									<Image
										src={deleteButton}
										alt="edit-icon"
										height={25}
										width={25}
									/>
									<Text>Confirmation</Text>
								</ModalTitleConatiner>
							}
							visible={isModalOpen}
							// onOk={() => {
							// 	handleOk(record?.id);
							// }}
							onCancel={handleCancel}
							footer={[
								<Button
									key="Close"
									onClick={handleCancel}
									style={{
										borderRadius: '4px',
										background: '#fff',
										color: '#CA5010',
										border: '1px solid #CA5010',
									}}
								>
									No
								</Button>,
								<Button
									key="Ok"
									onClick={() => {
										handleOk(deleteId);
									}}
									style={{
										borderRadius: '4px',
										border: '1px solid #CA5010',
									}}
								>
									Yes
								</Button>,
							]}
						>
							<Text>Are you sure you want to delete?</Text>
						</Modal> */}
				</Flex>
			),
		},
	];

	const handleSaveClick = () => {
		console.log('length', tableData.length);
		console.log('Table Data', tableData);

		if (tableData.length > 0) {
			const payload = {
				brand: rowData?.brand,
				invoice_no: rowData?.invoice_no,
				order_id: rowData?.order_id,
				salesman: rowData?.salesman,
				store_name: rowData?.store_name,
				return_details: tableData,
				state: 'save',
			};

			return dispatch(getSalesReturnsList(payload, id))
				.then(() => {
					message.info('Sales Return Created Successfully!');
					navigate(`/order-management/returns/`);
				})
				.catch((e) => {
					if ('response' in e && e?.response?.status === 422) {
						// message.error('Error while creating the Sales Return');
						message.error(`${e?.response?.data?.message}`);
						console.log(e?.response);
					} else {
						message.error('Error while creating the Sales Return');
					}
				});
		}
		return message.error('Please add some products to create a Sales Return');
	};

	// const removeDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) === index);

	const AddProductOnClick = (productId, data) => {
		if (!tableData?.find((obj) => obj.id === productId)) {
			message.info(`${data?.product_name} added sucessfully`);

			const arr = rowData?.return_details?.filter((item) => [productId]?.includes(item?.id));
			const uniqueArr = [...tableData, ...arr];
			setTableData(uniqueArr);
			// getButtonStatus(searchedList, uniqueArr);
			// getButtonStatus();
		} else {
			message.info(`Product already present`);
		}
	};

	const DeleteProductOnClick = (productId) => {
		const finalArray = tableData.filter((e) => ![productId].includes(e.id));
		// console.log('product ID', productId);
		// console.log('Final array', finalArray);
		setTableData(finalArray);
		// getButtonStatus(searchedList, finalArray);
		// getButtonStatus();
	};

	// const getButtonStatus = () => {
	// 	if (searchedList.length === tableData.length) {
	// 		console.log('came inside true');
	// 		setDisableButton(true); // Enable the button if all products are not added
	// 	} else {
	// 		console.log('came inside else');
	// 		setDisableButton(false);
	// 	}
	// };

	// Onclick of the Take full sales return button
	const showFullSalesReturnConfirmation = () => {
		Modal.confirm({
			title: 'Take Full Sales Return',
			icon: <ExclamationCircleOutlined />,
			content: 'Are you sure you want to take full sales return?',
			okText: 'Yes',
			cancelText: 'No',
			onOk: handleFullSalesReturnConfirmation,
		});
	};
	const handleFullSalesReturnConfirmation = () => {
		// Add all products with returnable quantity > 0 to the return list
		// console.log('searchedList ', searchedList);
		const productsToAdd = fullSalesReturnLists.filter((product) => product.returnable_qty > 0);
		const updatedProducts = productsToAdd.map((product) => ({
			...product,
			sales_returned_qty: product.returnable_qty,
		}));
		// console.log('Length', searchedList.length);
		// console.log('searchedList', searchedList);
		// // setTableData(productsToAdd);
		// console.log('product Length', productsToAdd.length);
		// console.log('ProductsToAdd', productsToAdd);

		setTableData(updatedProducts);
		// getButtonStatus(searchedList, productsToAdd);
		// getButtonStatus();
	};

	return (
		<>
			<Flex
				style={{
					background: '#fff',
					gap: '34px',
					padding: '40px 16px',
					marginBottom: '30px',
					borderRadius: '4px',
					border: '1px solid rgb(237, 237, 237)',
				}}
			>
				<Box ml={3}>
					<TitleText>Invoice Number</TitleText>
					<ValueText>{rowData?.invoice_no}</ValueText>
				</Box>
				<Box>
					<TitleText>Brand</TitleText>
					<ValueText>{rowData?.brand}</ValueText>
				</Box>
				<Box>
					<TitleText>Salesperson</TitleText>
					<ValueText>{rowData?.salesman}</ValueText>
				</Box>
				<Box>
					<TitleText>Store Name</TitleText>
					<ValueText>{rowData?.store_name}</ValueText>
				</Box>
			</Flex>

			{!disableSave && (
				<Flex style={{ flexDirection: 'column', marginBottom: '30px' }}>
					<Flex alignItems="center">
						<LabelWrapper label="" marginspace="-1px 0 5px 0">
							<SearchField style={{ width: '30vw' }}>
								<SearchBar
									style={{
										width: '30vw',
										justifyContent: 'left',
										paddingLeft: '10px',
									}}
									placeholder="Search to add Product"
									onFocus={() => setShowList(true)}
									onSearch={(val) => {
										setSearchVal(val);
										if (isEmpty(val)) {
											setShowList(false);
										}
									}}
									disabled={disableSave}
								/>
							</SearchField>
						</LabelWrapper>
						{/* {console.log('disableButton ', disableButton)} */}
						<Button onClick={showFullSalesReturnConfirmation} disabled={disableButton}>
							Add Full Sales Return
						</Button>
						{/* <Button mt="4px" onClick={triggerAPICall} disabled={searchVal === ''}>
						Search
					</Button> */}
					</Flex>
					{(!isEmpty(searchVal) || (isEmpty(searchVal) && showList)) && (
						<Box
							style={{
								width: '30vw',
								zIndex: 99,
								background: '#fff',
								border: '1px solid #E5E4E4',
								height: '200px',
								overflowY: 'scroll',
								overflowX: 'hidden',
							}}
						>
							{searchedList?.map((data) => (
								<ProductItem
									key={data?.id}
									style={{
										background: '#fff',
										width: '30vw',
										gap: '8px',
										padding: '12px',
										cursor: 'pointer',
										borderBottom: '1px solid #E5E4E4',
									}}
									onClick={() => {
										AddProductOnClick(data?.id, data);
									}}
								>
									<Text>{data?.product_name}</Text>
									<Rupee>{data?.mrp}</Rupee>
								</ProductItem>
							))}
						</Box>
					)}
				</Flex>
			)}

			<Box mt={3}>
				<RFCTable
					rowKey={(record) => `${record?.id}`}
					columns={salesReturnTable}
					dataSource={tableData}
					pagination={false}
				/>
				<Modal
					destroyOnClose
					title={
						<ModalTitleConatiner>
							<Image src={deleteButton} alt="edit-icon" height={25} width={25} />
							<Text>Confirmation</Text>
						</ModalTitleConatiner>
					}
					visible={isModalOpen}
					// onOk={() => {
					// 	handleOk(record?.id);
					// }}
					onCancel={handleCancel}
					footer={[
						<Button
							key="Close"
							onClick={handleCancel}
							style={{
								borderRadius: '4px',
								background: '#fff',
								color: '#CA5010',
								border: '1px solid #CA5010',
							}}
						>
							No
						</Button>,
						<Button
							key="Ok"
							onClick={() => {
								handleOk(deleteId);
							}}
							style={{
								borderRadius: '4px',
								border: '1px solid #CA5010',
							}}
						>
							Yes
						</Button>,
					]}
				>
					<Text>Are you sure you want to delete?</Text>
				</Modal>
			</Box>
			<ButtonContainer>
				<CancelBtn
					onClick={() => navigate(`/order-management/returns/search-sales-return`)}
				>
					Cancel
				</CancelBtn>
				<SaveBtn
					disabled={rowData?.state === 'mark_as_received' || disableSave}
					onClick={handleSaveClick}
				>
					Save
				</SaveBtn>
				{/* <MarkAsReceivedBtn onClick={() => handleSaveClick('mark_as_received')}>
					Mark as received
				</MarkAsReceivedBtn> */}
			</ButtonContainer>
		</>
	);
}
