/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { Checkbox, Radio } from 'antd';
import { Box, Button, Flex, AsyncSelect } from 'atoms';

// list page style

export const CheckBoxWrapper = styled(Checkbox.Group)`
	background: #faf9f8;
	border: 1px solid #edebe9;
	border-radius: 2px;
	display: flex;
	span.ant-checkbox {
		display: none;
	}
	.ant-checkbox-wrapper + .ant-checkbox-wrapper {
		margin: 0;
	}
	.ant-checkbox + span {
		padding: 0;
	}
	.ant-checkbox-wrapper-checked {
		background: #f6fff1;
		color: #0a9600;
		border-color: #0a9600;
	}
`;
export const RadioWrapper = styled(Radio.Group)`
	background: #faf9f8;
	border: 1px solid #edebe9;
	border-radius: 2px;
	.ant-radio-button-checked {
		background: #f6fff1;
		color: #0a9600;
		border-color: #0a9600;
	}
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		z-index: 1;
		color: #0a9600;
		background: #f6fff1;
		border-color: #0a9600;
	}
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
		border-color: #0a9600;
	}
	.ant-radio-button-wrapper:hover {
		position: relative;
		color: #0a9600;
	}
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
		background: #0a9600;
	}
`;

export const ButtonWrapper = styled(Button)`
	background: #faf9f8;
	border: ${(props) => (!props?.status ? '1px solid #edebe9' : '1px solid #CA5010')};
	color: ${(props) => (!props?.status ? '#454545' : '#CA5010')};
`;

export const LabelText = styled(Box)`
	font-size: 14px;
	line-height: 16px;
	color: ${(props) => (props.isRed ? '#38AC01' : '#3b3b3b')};
`;

export const DaysListWrapper = styled(Checkbox)`
	padding: 6px;
	flex: 1;
	border: 1px solid #edebe9;
	display: flex;
	justify-content: center;
	cursor: pointer;
`;

export const RadioButton = styled(Radio.Button)`
	background: #faf9f8;
`;

export const ModalLabel = styled(Flex)`
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
`;

export const CustomAsyncSelect = styled(AsyncSelect)`
	margin-bottom: 5px;
	.ant-select-selector {
		background: #faf9f8 !important;
		min-height: 35px;
	}
`;

export const ViewMoreWrapper = styled(Box)`
	font-size: 12px;
	line-height: 16px;
	text-align: right;
	padding: ${(props) => (props?.isClickedMore ? '0 1rem 1rem 0' : '0 1rem 0 0')};
	cursor: pointer;
	right: 0;
`;

export const ViewSummaryWrap = styled(Box)`
	position: ${(props) => (props.isClickedMore ? 'absolute' : 'static')};
	z-index: 5;
	width: 100%;
	background: white;
	z-index: 6;
	padding: 0 5px;
	height: ${(props) =>
		props.isClickedMore ? '400px' : props?.summaryLen > 100000 ? '500px' : '100px'};
	overflow: auto;
`;

export const StatusIcons = styled(Flex)`
	width: 40px;
	height: 40px;
	background: #faf9f8;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	align-self: center;
	margin-right: 15px;
`;

export const OverlayShadow = styled(Box)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #0006;
`;

export const SummaryHeader = styled(Box)`
	align-self: ${(props) => (props?.hasData ? 'start' : 'center')};
	text-align: center;
	flex: 0 0 20%;
	padding: 12px 0;
	z-index: 6;
	background: #fff;
`;

export const ClearFilter = styled(Box)`
	display: flex;
	justify-content: end;
	padding-top: 5px;
	font-size: 12px;
	font-weight: 700;
	text-decoration: underline;
	cursor: pointer;
	right: 0;
	position: absolute;
`;

export const OrderStatusSubtitle = styled(Box)`
	font-size: 16px;
	line-height: 21px;
	color: #2a2a2a;
	margin-bottom: 1.25rem;
	strong {
		font-weight: 700;
		font-size: 18px;
	}
`;

export const OrderRender = styled(Box)`
	margin: 0;
	border: 1px solid #edebe9;
	padding: 8px;
	background: #edebe94a;
	font-size: 14px;
	line-height: 19px;
	cursor: pointer;
	span {
		font-weight: 600;
	}
`;

// ends here
