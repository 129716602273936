import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Collapse, Row, Col } from 'antd';
import { ShowSalesReturnId } from 'store/fulfilment/brandSalesReturnSlice';
import { PanelData } from 'components/common';
import { Flex } from 'atoms';
import BrandShowTable from 'pages/fulfillment/brandSalesReturn/BrandShowTable';
import { brandSalesReturnShowPanel } from './brandSalesReturnConfig';

const { Panel } = Collapse;

export default function BrandSalesReturnShow() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [showData, setShowData] = useState({});

	useEffect(() => {
		dispatch(ShowSalesReturnId(id)).then((res) => setShowData(res));
	}, [dispatch, id]);

	return (
		<>
			<Collapse expandIconPosition="right" bordered={false}>
				<Panel header="Brand Sales Return Details:" key="1">
					<Row gutter={32}>
						{brandSalesReturnShowPanel({ showData }).map((x) => (
							<Col key={x.title} xs={6}>
								<PanelData
									title={x.title}
									value={x.value}
									additionalValue={x?.additionalValue || ''}
									type={x?.type || ''}
								/>
							</Col>
						))}
					</Row>
				</Panel>
			</Collapse>
			<Flex mt="30px">
				<BrandShowTable data={showData} />
			</Flex>
		</>
	);
}
