import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Text, Flex } from 'atoms';
import { checkAssignedGroups } from 'utils';

const IconWrap = styled(Flex)`
	justify-content: flex-start;
	flex-wrap: wrap;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 150px;
	width: 200px;
	border-radius: 8px;
	background: #ffffff;
	box-shadow: 0px 11px 15px -7px rgb(145 158 171 / 20%), 0px 24px 38px 3px rgb(145 158 171 / 14%),
		0px 9px 46px 8px rgb(145 158 171 / 12%);
	margin: 10px;
	cursor: pointer;
	svg {
		transition: transform 250ms;
	}
	&:hover svg {
		transform: translateY(-10px);
	}
`;

const WrapperIcon = styled.div`
	margin: auto;

	span {
		height: 60px;
		width: 60px;
		margin-right: 0;
	}

	.strokeIcons svg {
		fill: none;
		stroke: ${(props) => props.theme?.colors?.primary};
	}
`;

const WrapperTitle = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props) => props.theme?.colors?.secondary};
	min-height: 50px;
	width: 100%;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
`;

export default function LandingPage({ subCatagories }) {
	const navigate = useNavigate();
	const { auth } = useSelector((state) => state?.auth);
	return (
		<IconWrap>
			{subCatagories
				.filter((x) => checkAssignedGroups(x.allowedgroups, auth?.user))
				.map((data) => (
					<Wrapper onClick={() => navigate(data?.to)} key={data?.id}>
						<WrapperIcon>{data?.icon}</WrapperIcon>
						<WrapperTitle>
							<Text color="#fff">{data?.title}</Text>
						</WrapperTitle>
					</Wrapper>
				))}
		</IconWrap>
	);
}

LandingPage.propTypes = {
	subCatagories: PropTypes.array,
};
