/* eslint-disable react/no-array-index-key */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { Select, Table, Tooltip, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { isEmpty, uniqBy, capitalize } from 'lodash';
import { nanoid } from 'nanoid';
import { Box, Button, Flex, Text } from 'atoms';
import { SearchBox } from 'components';
import {
	getNextDayCollectionList,
	getSalesManList,
	assignSalesMan,
	getBrands,
	getHandoverColumnsData,
} from 'store/collectionSlice';
import { NDCModalPopup } from './NDCModal';
import { useOffClick } from 'hooks';
import {
	SelectFilterOption,
	DaysList,
	DatePick,
	CollectionOrderStatus,
	CollectionOrderStatusRender,
	RefineDataByStatus,
	downloadExcel,
} from './helpers';
import { ColumnsList, getHandoverColumns } from './TableConfigs';
import { CollectionStatusModal } from './CollectionStatusModal';
import {
	CheckBoxWrapper,
	ButtonWrapper,
	DaysListWrapper,
	ModalLabel,
	CustomAsyncSelect,
	OverlayShadow,
	ClearFilter,
	SummaryHeader,
	LabelText,
	RadioButton,
	RadioWrapper,
} from './styles';
import { ErrorNotificationModal } from 'components/modals/ErrorNotificatonModal';
import { getModalTableProps } from './AllocationHelper';

const PAGE_OPTIONS = [50, 100, 150, 200];
export default function NextDayCollectionListPage() {
	let navigate = useNavigate();
	let [sParams] = useSearchParams();
	let brand = sParams.get('brand');
	const salesman_id = sParams.get('salesman');
	const dispatch = useDispatch();
	const _domRef = useRef();
	const [modalVisible, setModalVisible] = useState(false);
	const [collectionStatusmodal, setCollectionStatusmodal] = useState({
		orderStatus: null,
		visible: false,
	});
	const [modalErrorVisible, setModalErrorVisible] = useState({ errData: null, visible: false });
	const [searchText, setSearchText] = useState('');
	const [salesman, setsalesMan] = useState(undefined);
	const [ordermissing, setordermissing] = useState({ type: 'NotAsked' }); // order_missing will be stored in this state
	const [totalData, setTotalData] = useState({ type: 'NotAsked' }); // data will be stored in this state
	const [notinterminal, setnotinterminal] = useState({ type: 'NotAsked' }); // undelivered will be stored in this state
	const [unverified, setunverified] = useState({ type: 'NotAsked' }); // unverified will be stored in this state
	const [invoice_settled, setInvoiceSettled] = useState({ type: 'NotAsked' });
	const [dayRange, setDayRange] = useState([]);
	const [day, setDay] = useState('today');
	const [rowSelect, setrowSelect] = useState([]);
	const [rowSelectKeys, setrowSelectKeys] = useState([]);
	const [salesManList, setsalesManList] = useState([]);
	const [status, setStatus] = useState('unassigned');
	const [isClickedMore, setisClickedMore] = useState(false);
	const [filterBrands, setFilterBrands] = useState([]);
	const [brandOptions, setbrandOptions] = useState([]);
	const [rowData, setRowData] = useState({ type: 'NotAsked' });
	const [pageoffset, setOffset] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [counts, setCount] = useState({});
	const [pagelimit, setpagelimit] = useState(PAGE_OPTIONS[0]);

	useEffect(() => {
		dispatch(getBrands()).then((res) => {
			setbrandOptions(res?.map((y) => ({ value: y.id, label: capitalize(y.name) })));
		});
	}, [dispatch]);

	useEffect(() => {
		if (brandOptions.length && Number(brand)) {
			setFilterBrands(Number(brand));
		}
	}, [brandOptions]);

	useEffect(() => {
		if (salesManList.length && Number(salesman_id)) {
			setsalesMan({ value: Number(salesman_id) });
		}
	}, [salesManList]);

	const onLoadAsyncCall = (isDownload = false, offset = 0, limit = PAGE_OPTIONS[0]) => {
		if ((salesman?.value && filterBrands) || searchText?.length > 3) {
			dispatch(
				getNextDayCollectionList({
					status,
					dayRange,
					searchText,
					salesman: salesman?.value,
					isDownload,
					filterBrands,
					offset,
					limit,
				})
			).then((x) => {
				if (isDownload) {
					return downloadExcel(x, `Invoice Handover ${moment().format('DD-MM-YYYY')}`);
				}

				setCount(x);

				setRowData({
					type: 'Success',
					data: x?.data?.map((item) => ({
						...item,
						id_nanoid: nanoid(),
						/**
						 * From BE they don't send any unqiue id, so from FE we are generating that
						 */
					})),
				});

				setTotalData((prev) => ({
					type: 'Success',
					data: {
						...x,
						data: uniqBy(
							[...(prev?.data?.data || []), ...(x?.data || [])],
							(z) => z.collection_invoice_id
						),
					},
				}));

				setnotinterminal({
					type: 'Success',
					data: {
						...RefineDataByStatus(
							'undelivered',
							x.order_condition_count_summary,
							x.order_condition_data
						),
					},
				});

				setordermissing({
					type: 'Success',
					data: {
						...RefineDataByStatus(
							'order_missing',
							x.order_condition_count_summary,
							x.order_condition_data
						),
					},
				});

				setunverified({
					type: 'Success',
					data: {
						...RefineDataByStatus(
							'unverified',
							x.order_condition_count_summary,
							x.order_condition_data
						),
					},
				});

				setInvoiceSettled({
					type: 'Success',
					data: {
						...RefineDataByStatus(
							'invoice_settled',
							x.order_condition_count_summary,
							x.order_condition_data
						),
					},
				});
				return true;
			});
		} else {
			console.log('user name not availalbe', salesman?.value, filterBrands);
		}
	};

	const getInvoicesHandOverData = () => {
		if (status === 'unassigned') {
			setRowData({ type: 'Loading' });
			setCount({});
			onLoadAsyncCall(false, pageoffset, pagelimit);
		} else {
			setRowData({ type: 'Loading' });
			setCount({});
			dispatch(
				getHandoverColumnsData(
					day,
					salesman?.value,
					filterBrands,
					pageoffset,
					pagelimit,
					searchText
				)
			).then((res) => {
				setRowData({
					type: 'Success',
					data: res?.data,
					count: res?.allocation_summary?.total_allocations,
				});
			});
		}
	};

	const handleInvoiceSearch = () => {
		getInvoicesHandOverData();
	};

	useEffect(() => {
		getInvoicesHandOverData();
	}, [dispatch, status, dayRange, pageoffset, pagelimit, day]);

	useEffect(() => {
		dispatch(getSalesManList({ brand_id: filterBrands ? [filterBrands] : [] })).then((x) => {
			resetRowSelect();
			setsalesManList(SelectFilterOption(x));
		});
	}, [dispatch, filterBrands]);

	useEffect(() => {
		document.body.style.overflow = isClickedMore ? 'hidden' : 'auto';
	}, [isClickedMore]);

	// to handle click outside
	const handleOutsideClick = (ref) => {
		if (isClickedMore && _domRef.current === ref.current) {
			setisClickedMore(false);
		}
	};

	useOffClick([_domRef], handleOutsideClick);

	const rowSelection = {
		onChange: (keys) => {
			setrowSelectKeys(keys); // added the rowselectkeys to reset the table selection.
			setrowSelect(totalData?.data?.data?.filter((x) => keys.includes(x.invoice_number)));
		},
		selectedRowKeys: rowSelectKeys,
		preserveSelectedRowKeys: true,
		getCheckboxProps: (record) => ({
			disabled: isEmpty(salesman),
			name: record.salesperson_id,
			defaultValue: rowSelectKeys.includes(record?.invoice_number),
		}),
		renderCell: (_, __, ___, ele) =>
			isEmpty(salesman) ? (
				<Tooltip
					placement="right"
					title="Please select the salesman in the above filter to assign the invoices"
				>
					{ele}
				</Tooltip>
			) : (
				ele
			),
	};

	const handleSubmit = (invoice_array, newSalesMan) => {
		dispatch(
			assignSalesMan({
				data: { invoice_array, invoice_ids: invoice_array?.map((x) => x.invoice_id) },
			})
		).then((res) => {
			setModalVisible(false);
			setrowSelect([]);
			setModalErrorVisible({ errData: { ...res, newSalesMan }, visible: true });
			resetRowSelect();
		});
	};

	const handleModalVisible = () => {
		setModalVisible(true);
		dispatch(
			getSalesManList({
				fc_id: [rowSelect[0]?.collection_fc_id],
				brand_id: [rowSelect[0]?.brand_id],
			})
		).then((x) => setsalesManList(SelectFilterOption(x)));
	};

	const handleChange = (pagination) => {
		const pageoffsets = pagination.current * pagination.pageSize - pagination.pageSize;
		setOffset(pageoffsets);
		setpagelimit(pagination.pageSize);
		setCurrentPage(Math.ceil(pageoffsets / pagination.pageSize) + 1);
	};

	const resetRowSelect = () => {
		setrowSelect([]);
		setrowSelectKeys([]);
	};

	return (
		<>
			<Box className="nextDayPage">
				<Box backgroundColor="#fff">
					<Flex borderBottom="1px solid #EDEBE9">
						<Box flex="0 0 80%" borderRight="1px solid #EDEBE9">
							<Text p="15px 25px 5px 25px" fontSize="14px" fontWeight="600">
								Select Brand & Salesman combination to find the Invoices or Search a
								particular Invoice directly
							</Text>
							<Flex padding="15px 25px" borderBottom="1px solid #EDEBE9">
								<Box
									flex="0 0 40%"
									paddingRight="15px"
									border="1px solid #EDEBE9"
									p="1rem"
								>
									<Box>
										<Box fontWeight="600" fontSize="14px" lineHeight="24px">
											Brands
										</Box>
										<Select
											name="brands"
											allowClear
											className="customDropDown"
											style={{ width: '100%', background: '#faf9f8' }}
											placeholder="Select Brands"
											filterOption={(input, option) =>
												option.label
													.toLowerCase()
													.indexOf(input.toLowerCase()) >= 0
											}
											value={filterBrands}
											onChange={(val) => {
												setFilterBrands(val);
												setsalesMan(undefined);
												resetRowSelect();
											}}
											defaultValue={filterBrands}
											options={brandOptions}
											onClear={() => {
												setFilterBrands([]);
												navigate('/allocation');
											}}
										/>
									</Box>
									<Box flex="0 0 33%" paddingRight="0">
										<Box mt="1rem">
											<Box fontWeight="600" fontSize="14px" lineHeight="24px">
												Salesman
											</Box>
											<CustomAsyncSelect
												showSearch
												style={{ width: '100%', background: '#faf9f8' }}
												selectOptions={salesManList}
												fetchOptions={getSalesManList}
												onChange={(val) => {
													setsalesMan(val);
												}}
												value={salesman}
												apiParams={{
													brand_id: filterBrands ? [filterBrands] : [],
												}}
												allowClear
												transformOptions={(val) =>
													SelectFilterOption(val, rowSelect)
												}
												placeholder="Search Salesman/Mobile Number"
												onClear={() => {
													setsalesMan(undefined);
													navigate('/allocation');
												}}
											/>
										</Box>
										<Box fontSize="12px" lineHeight="14px">
											Please search by salesman or by mobile number to assign
											the invoices
										</Box>
									</Box>
								</Box>

								<Box
									flex="0 0 35%"
									paddingRight="15px"
									ml="2rem"
									p="1rem"
									border="1px solid #EDEBE9"
								>
									<SearchBox
										name="invoice_no_query"
										label="Invoice Search"
										onSearch={(e) =>
											setSearchText(e?.target?.value?.toLowerCase())
										}
										placeholder="Invoice Number"
									/>
									<Box fontSize="12px" lineHeight="14px">
										Please enter at least four character
									</Box>
								</Box>

								<Box ml="2rem" p="1rem" justifyContent="center">
									<Button
										disabled={
											searchText?.length < 4 &&
											status === 'unassigned' &&
											!(salesman?.value && filterBrands)
										}
										onClick={handleInvoiceSearch}
										ml="0"
										mt="25px"
									>
										Click here to Search
									</Button>
								</Box>
							</Flex>
							<Flex padding="10px 25px 20px">
								<Box flex="0 0 33%">
									<Box fontWeight="600" fontSize="14px" lineHeight="24px">
										{status === 'unassigned'
											? 'Day Filter'
											: 'Collectable Day Filter'}
									</Box>
									{status === 'unassigned' && (
										<CheckBoxWrapper
											value={dayRange}
											onChange={(val) => {
												setDayRange(val);
												resetRowSelect();
											}}
										>
											{DaysList.map((x, index) => (
												<DaysListWrapper
													isLastIndex={index === DaysList.length}
													value={index + 1}
													key={x}
												>
													{x}
												</DaysListWrapper>
											))}
										</CheckBoxWrapper>
									)}
									{status === 'unassigned' && !isEmpty(dayRange) && (
										<ClearFilter onClick={() => setDayRange([])}>
											Clear day filter
										</ClearFilter>
									)}
									{status === 'handover' && (
										<RadioWrapper
											onChange={(e) => {
												setOffset(0);
												setCurrentPage(1);
												setDay(e.target.value);
											}}
											defaultValue="today"
										>
											{DatePick.map((item) => (
												<RadioButton value={item.key} key={item.key}>
													{item.value}
												</RadioButton>
											))}
										</RadioWrapper>
									)}
								</Box>
								<Box flex="0 0 66%">
									{/* Status of the invoices starts here */}
									<Box
										padding="0 20px"
										fontWeight="600"
										fontSize="14px"
										lineHeight="24px"
										opacity="0"
									>
										Others
									</Box>
									<Flex>
										{CollectionOrderStatus({
											ordermissing,
											notinterminal,
											status,
											unverified,
											invoice_settled,
											setCollectionStatusmodal,
										}).map((x) => (
											<Box
												paddingLeft="20px"
												key={x?.label}
												hidden={!x?.hidden}
											>
												<CollectionOrderStatusRender {...x} />
											</Box>
										))}
									</Flex>
									{/* Ends Here */}
								</Box>
							</Flex>
						</Box>
						<SummaryHeader>
							<Box minHeight="60px" padding="15px">
								<LabelText>
									{status === 'unassigned' ? 'Total Invoice' : 'Total Allocation'}
								</LabelText>
								<Box fontSize="24px" lineHeight="30px" color="#000000">
									{status === 'unassigned'
										? counts?.invoice_count || 0
										: rowData?.data?.length || 0}
								</Box>

								{status === 'unassigned' && (
									<Flex flexWrap="wrap" minHeight="50px" alignItems="center">
										<Box flex="1">
											<LabelText as="span" isRed marginRight="8px">
												{counts?.assigned_count || 0}
											</LabelText>
											<LabelText as="span" marginRight="8px">
												Assigned
											</LabelText>
										</Box>
										<Box flex="1">
											<LabelText as="span" isRed marginRight="8px">
												{counts?.unassigned_count || 0}
											</LabelText>
											<LabelText as="span">Unassigned</LabelText>
										</Box>
									</Flex>
								)}
							</Box>
						</SummaryHeader>
					</Flex>
					<Flex padding="15px 25px">
						<Box flex="0 0 33%">
							<Box>
								<ButtonWrapper
									status={status === 'unassigned'}
									onClick={() => {
										resetRowSelect();
										setStatus('unassigned');
									}}
								>
									Unassigned
								</ButtonWrapper>
								<ButtonWrapper
									status={status === 'handover'}
									onClick={() => {
										resetRowSelect();
										setStatus('handover');
									}}
								>
									Handover
								</ButtonWrapper>
							</Box>
						</Box>

						{!isEmpty(rowSelect) && !isEmpty(salesman) && (
							<Flex justifyContent="space-between">
								<ModalLabel alignSelf="center" marginRight="20px">
									Selected {rowSelect?.length} / {totalData?.data?.count}
								</ModalLabel>
								<Box>
									{status === 'assigned' && (
										<Button
											disabled={rowSelect.length > 50}
											marginBottom="4px"
											onClick={handleModalVisible}
										>
											Re-Assign
										</Button>
									)}
									{status === 'unassigned' && (
										<Button
											disabled={rowSelect.length > 50}
											marginBottom="4px"
											onClick={handleModalVisible}
										>
											Assign
										</Button>
									)}
									<Box fontSize="14px" textAlign="center">
										Invoice limit: 50{' '}
									</Box>
								</Box>
							</Flex>
						)}
					</Flex>

					{/* Table data component here */}
					{rowData?.data?.length > 0 && (
						<Box padding="5px 15px">
							{['Loading', 'NotAsked'].includes(totalData?.data) ? (
								<Flex height="300px" alignItems="center" justifyContent="center">
									Fetching Invoices...
								</Flex>
							) : (
								<Box className="invoice-handover" padding="5px 16px 20px">
									<Table
										rowKey="invoice_number"
										onChange={handleChange}
										rowSelection={status !== 'handover' && rowSelection}
										loading={{
											spinning: ['Loading', 'NotAsked'].includes(
												rowData.type
											),
											indicator: (
												<Spin
													tip="Fetching Invoices..."
													indicator={<LoadingOutlined />}
												/>
											),
											size: 'large',
										}}
										columns={
											status === 'handover'
												? getHandoverColumns()
												: ColumnsList(status)
										}
										dataSource={rowData?.data}
										pagination={{
											total:
												status === 'unassigned'
													? totalData?.data?.count
													: rowData?.count,
											current: currentPage,
											showSizeChanger: true,
											pageSizeOptions: PAGE_OPTIONS,
											pageSize: pagelimit,
										}}
										scroll={{
											x: status === 'assigned' ? 2500 : 500,
											y: 500,
										}}
									/>
								</Box>
							)}
						</Box>
					)}

					{/* Assign/Reassign ModalPopup */}
					{modalVisible && (
						<NDCModalPopup
							rowSelect={rowSelect}
							totalData={totalData?.data}
							toggleSalesMan={[salesManList, setsalesManList]}
							toggleModal={[modalVisible, setModalVisible]}
							assignstatus={status}
							handleSubmit={handleSubmit}
						/>
					)}

					{collectionStatusmodal.visible && (
						<CollectionStatusModal
							toggleModal={[collectionStatusmodal, setCollectionStatusmodal]}
							tableProps={getModalTableProps(
								collectionStatusmodal.orderStatus,
								ordermissing,
								notinterminal,
								unverified,
								invoice_settled
							)}
						/>
					)}

					{/* Error Notification Modal */}
					{modalErrorVisible.visible && (
						<ErrorNotificationModal
							modalErrorVisible={modalErrorVisible}
							setModalErrorVisible={setModalErrorVisible}
							onLoadAsyncCall={onLoadAsyncCall}
						/>
					)}
				</Box>
			</Box>
			{isClickedMore && <OverlayShadow />}
		</>
	);
}
