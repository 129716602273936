import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { userLogout } from 'store/authSlice';
import { setShowLogout } from 'store/appSlice';
import { Box, Button } from 'atoms';

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 2rem;
`;

export default function LogoutModal() {
	const dispatch = useDispatch();
	const { showLogout } = useSelector((state) => state.app);

	const handleOk = () => {
		handleCancel();
		dispatch(userLogout());
	};

	const handleCancel = () => {
		dispatch(setShowLogout(false));
	};

	return (
		<Modal
			centered
			visible={showLogout}
			footer={null}
			onCancel={handleCancel}
			maskClosable={false}
		>
			<Box mt="3" textAlign="center">
				<p id="logout-text">Are you sure want to Logout?</p>
			</Box>
			<ButtonContainer>
				<Button
					color="#fff"
					border={0}
					variant="secondary"
					onClick={handleCancel}
					id="logout-cancel"
				>
					No
				</Button>
				<Button variant="primary" onClick={handleOk} ml="2rem" id="logout-ok">
					Yes
				</Button>
			</ButtonContainer>
		</Modal>
	);
}
