import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Box, Flex, Text } from 'atoms';
import { theme } from 'styles/theme';
import { setShowLogout } from 'store/appSlice';
import { getFirstChar } from 'utils/text';

export const Round = styled(Box)`
	width: 42px;
	height: 42px;
	border-radius: 50%;
	background-color: ${(props) => props.theme.colors.Bismark};
	color: ${(props) => props.theme.colors.white};
	border: 1px solid white;
	font-size: 12px;
	line-height: 42px;
	text-align: center;
	text-transform: uppercase;
	flex: 0 0 42px;
`;

export const FlexBox = styled(Flex)`
	position: relative;
	align-items: center;
	margin: 10px 0;
	padding-left: 15px;
	cursor: pointer;
`;

export const Logout = styled(Box)`
	border: 1px solid white;
	font-size: 13px;
	line-height: 18px;
	color: #fff;
	text-align: center;
	cursor: pointer;
	padding: 5px 1.5rem;
	background-color: ${(props) => props.theme.colors.primary};
`;

// const previousVersion =
// 	process.env.REACT_APP_ENV === 'production'
// 		? 'https://cdms-old.ripplr.in/#/login'
// 		: 'http://65.1.96.239/champ/#/login';

export default function UserInfo() {
	const { me = {} } = useSelector((state) => state.auth);
	const dispatch = useDispatch();

	const menu = (
		<Menu
			items={[
				{
					key: '1',
					label: (
						<Link to="/profile">
							<UserOutlined /> Profile
						</Link>
					),
				},
				{
					key: '2',
					label: (
						<Logout id="logout-btn" onClick={() => dispatch(setShowLogout(true))}>
							<LogoutOutlined /> Logout
						</Logout>
					),
				},
			]}
		/>
	);

	return (
		<Dropdown overlay={menu}>
			<FlexBox>
				<Flex color={theme.colors.primary} flexDirection="column" pl="10px" pr="10px">
					<Text textTransform="capitalize" fontSize="12px" pb="5px">
						{me?.first_name} {me?.last_name}
					</Text>
					<Text wordBreak="break-all" fontSize="10px">
						{me?.email}
					</Text>
				</Flex>
				<Round>
					{getFirstChar(me?.first_name)}
					{getFirstChar(me?.last_name)}
				</Round>
			</FlexBox>
		</Dropdown>
	);
}
