import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { unparse as convertToCSV } from 'papaparse';
import downloadCsv from 'download-csv';
import { Button, Flex, Box } from 'atoms';
import * as SVGICONS from 'utils/Svg';
import { viewDAListView } from 'store/fulfilment/deliveryAllocationSlice';
import ShowAllocationTable from './ShowAllocationTable';

export default function DeliveryAllocationShow() {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [dataList, setDataList] = useState({});

	useEffect(() => {
		dispatch(viewDAListView({ allocations: [+(id || 0)] })).then((res) => {
			setDataList(res);
		});
	}, [dispatch, id]);

	const exportDeliveryAllocation = async () => {
		const postsForExport = dataList.rows.map((allocation) => {
			// add a field from an embedded resource
			allocation['Invoice No'] = allocation.invoice_no;
			allocation['Retailer Name'] = allocation.Store ? allocation.Store.name : null;
			allocation['Delivery Code'] = allocation.delivery_code;
			allocation['Crates Count'] = allocation.crates;
			return allocation;
		});
		const csv = convertToCSV({
			data: postsForExport,
			// select and order fields in the export
			fields: ['Invoice No', 'Retailer Name', 'Delivery Code', 'Crates Count'],
		});
		downloadCsv(csv, 'delivery_allocation'); // download as 'delivery_allocation.csv` file
	};

	return (
		<>
			<Flex justifyContent="end">
				<Button onClick={exportDeliveryAllocation} variant="primary" id="add-add">
					<Flex alignItems="center" justifyContent="center">
						<SVGICONS.DownloadsIcons /> <span>Delivery Allocation</span>
					</Flex>
				</Button>
			</Flex>
			<Box mt={3}>{dataList && <ShowAllocationTable data={dataList} />}</Box>
		</>
	);
}
