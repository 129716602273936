/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { message } from 'antd';
import styledComponents from 'styled-components';
import { Box, Button, Flex, Text } from 'atoms';
import { RCTable } from 'components/common';
import {
	getUploadViewList,
	getUploadViewDetails,
	downloadCollectionformat,
} from 'store/UploadSlice';
import { UploadViewListColumns, UploadShowDetails, initialPaginateConfig } from 'utils';

const DetailsWrap = styledComponents(Box)`
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 2px 1px -1px rgb(145 158 171 / 20%), 0px 1px 1px 0px rgb(145 158 171 / 14%), 0px 1px 3px 0px rgb(145 158 171 / 12%);
`;

const TextWrap = styledComponents(Box)`
	flex: 0 0 25%;
`;

export function UploadShowPage() {
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();

	const [rowData, setRowData] = useState({});
	const [data, setData] = useState({});
	const toggleState = useState({ ...initialPaginateConfig });

	useEffect(() => {
		if (!isEmpty(id)) {
			dispatch(getUploadViewDetails({ id })).then((res) => setData(res));
		} else {
			message.error('Something went wrong!');
			navigate('/uploads');
		}
	}, [dispatch, id]);

	const handleClick = () =>
		dispatch(
			downloadCollectionformat({
				url: 'champ/order/download',
				data: {
					filter: {
						files: [+(id || 0)],
					},
					sort: {
						sort_column: 'id',
						sort_direction: 'ASC',
					},
					page: {
						limit: 100,
						offset: 0,
					},
				},
			})
		);

	return (
		<>
			<Box mt={3}>
				<DetailsWrap mb={4}>
					<Flex mb={3} justifyContent="space-between">
						<Box fontWeight="700" alignSelf="center">
							{data?.fileName || ''}
						</Box>
						<Button onClick={handleClick}>Download</Button>
					</Flex>
					<Flex flexWrap="wrap" style={{ gap: '24px' }}>
						{UploadShowDetails({ data }).map((x) => (
							<TextWrap key={x.id}>
								<Text fontSize="12px" fontWeight="700" mb="2">
									{x.label}
								</Text>
								<Text fontSize="12px">{x.value}</Text>
							</TextWrap>
						))}
					</Flex>
				</DetailsWrap>
			</Box>
			<Box>
				<Text mb={3}>Invoice vs Location vs Delivery Code</Text>
				<RCTable
					rowKey="id"
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'id',
						staticPayload: { files: [+(id || 0)] },
					}}
					tableDatas={[rowData, setRowData]}
					togglePaginate={toggleState}
					dispatchAction={getUploadViewList}
					columns={UploadViewListColumns}
				/>
				<Button mt="1rem" onClick={() => navigate(-1)}>
					Back
				</Button>
			</Box>
		</>
	);
}
