import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { checkAssignedGroups } from 'utils/userPermission';

export default function ACL({ allowedGroups, children }) {
	const { auth: userDefinitions } = useSelector((state) => state.auth);

	const hasAccess = allowedGroups
		? checkAssignedGroups(allowedGroups, userDefinitions?.user)
		: null;

	if (userDefinitions?.user?.is_superuser) {
		return <> {children} </>;
	}

	if (
		(userDefinitions?.user?.is_challenger && !userDefinitions?.user?.is_champion) ||
		(!userDefinitions?.user?.is_challenger && !userDefinitions?.user?.is_champion)
	) {
		return null;
	}

	if (hasAccess) {
		return <> {children} </>;
	}

	return null;
}

ACL.propTypes = {
	allowedGroups: PropTypes.array,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
