/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'atoms';
import { Rupee } from 'components';
import { ValueTextIn, SummaryValues } from '../style';
import { SalesManIcons, TruckIcons } from 'utils';

const TakeTotal = (data, params, type) =>
	data?.Orders?.filter(
		(x) => x?.CollectionInvoice.status === params && x?.CollectionInvoice.billtype === type
	).length || 0;

const TotalCompleted = (data) =>
	(data?.total_delivered || 0) +
	(data?.total_partial_delivered || 0) +
	(data?.total_attempted || 0) +
	(data?.total_cancelled || 0);

export function RTFInvoiceSummary({ data }) {
	return (
		<Box backgroundColor="#fff" marginBottom="1rem" border="1px solid #EDEBE9" padding="20px 0">
			<Flex padding="5px 20px 15px">
				<Box as="span" fontSize="14px" marginRight="15px">
					<Box verticalAlign="middle" as="span" marginRight="5px">
						<TruckIcons />
					</Box>
					{data?.vehicle_no || ''}
				</Box>
				<Box as="span" fontSize="14px">
					<Box verticalAlign="middle" as="span" marginRight="5px">
						<SalesManIcons />
					</Box>
					{data?.driver_name || ''}
				</Box>
			</Flex>
			<Flex justifyContent="space-between">
				<Flex padding="0 20px" flex="0 0 33%">
					<Box flex="1">
						<ValueTextIn>Total Collection</ValueTextIn>
						<SummaryValues isHead>
							<Rupee>{data?.total_collection || 0}</Rupee>
						</SummaryValues>
					</Box>
					<Box flex="1" padding="0 15px">
						<Box>
							<ValueTextIn>Cash</ValueTextIn>
							<SummaryValues>
								<Rupee>{data?.total_cash_collected || 0}</Rupee>
								{data?.total_cash_collected && (
									<Box as="span">({data?.total_cash_count || 0})</Box>
								)}
							</SummaryValues>
						</Box>
						<Box>
							<ValueTextIn>UPI</ValueTextIn>
							<SummaryValues>
								<Rupee>{data?.total_upi_collected || 0}</Rupee>
								{data?.total_upi_collected && (
									<Box as="span">({data?.total_upi_count || 0})</Box>
								)}
							</SummaryValues>
						</Box>
					</Box>
					<Box flex="1">
						<Box>
							<ValueTextIn>Cheque</ValueTextIn>
							<SummaryValues>
								<Rupee>{data?.total_cheque_collected || 0}</Rupee>
								{data?.total_cheque_collected && (
									<Box as="span">({data?.total_cheque_count || 0})</Box>
								)}
							</SummaryValues>
						</Box>
						<Box>
							<ValueTextIn>NEFT</ValueTextIn>
							{console.log('data', data)}
							<SummaryValues>
								<Rupee>{data?.total_neft_collected || 0}</Rupee>
								{data?.total_neft_collected && (
									<Box as="span"> ({data?.total_neft_count || 0})</Box>
								)}
							</SummaryValues>
						</Box>
					</Box>
				</Flex>
				<Flex
					padding="0 20px"
					flex="0 0 33%"
					border="1px solid #EDEBE9"
					borderTop="0"
					borderBottom="0"
				>
					<Box flex="1">
						<ValueTextIn>Total Invoice</ValueTextIn>
						<SummaryValues isHead>{data?.total_orders || 0}</SummaryValues>
					</Box>
					<Box flex="1" padding="0 15px">
						<Box>
							<ValueTextIn>Invoice Returned</ValueTextIn>
							<SummaryValues>{TakeTotal(data, 'Bill Back', 'Bill')}</SummaryValues>
						</Box>
						<Box>
							<ValueTextIn>Voucher Settled</ValueTextIn>
							<SummaryValues>{TakeTotal(data, 'Bill Back', 'Voucher')}</SummaryValues>
						</Box>
					</Box>
					<Box flex="1">
						<Box>
							<ValueTextIn>Invoice Settled</ValueTextIn>
							<SummaryValues>{TakeTotal(data, 'No Bill Back', 'Bill')}</SummaryValues>
						</Box>
					</Box>
				</Flex>
				<Flex padding="0 20px" flex="0 0 33%">
					<Box flex="1">
						<ValueTextIn>Completed</ValueTextIn>
						<SummaryValues isHead>
							{TotalCompleted(data)}/{data?.total_orders}
						</SummaryValues>
					</Box>
					<Box flex="1" padding="0 15px">
						<Box>
							<ValueTextIn>Full Delivery</ValueTextIn>
							<SummaryValues>{data?.total_delivered || 0}</SummaryValues>
						</Box>
						<Box>
							<ValueTextIn>Delivery Attempt</ValueTextIn>
							<SummaryValues>{data?.total_attempted || 0}</SummaryValues>
						</Box>
					</Box>
					<Box flex="1">
						<Box>
							<ValueTextIn>Partial Delivery</ValueTextIn>
							<SummaryValues>{data?.total_partial_delivered || 0}</SummaryValues>
						</Box>
						<Box>
							<ValueTextIn>Delivery Cancel</ValueTextIn>
							<SummaryValues>{data?.total_cancelled || 0}</SummaryValues>
						</Box>
					</Box>
				</Flex>
			</Flex>
		</Box>
	);
}

RTFInvoiceSummary.propTypes = {
	data: PropTypes.object,
};
