import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Button, Flex } from 'atoms';
import { redirectToLogin } from 'api/authentication';

const BoxWrap = styled(Flex)`
	justify-content: center;
	align-items: center;
	min-height: 100vh;
`;

const ButtonWrap = styled(Button)`
	display: block;
	max-width: 200px;
	width: 100%;
	margin: auto;
	background: rgb(40, 43, 105);
	color: #fff;
`;

export default class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		console.log('error', error);
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.log('error', error);
		console.log('errorInfo', errorInfo);
	}

	render() {
		const { hasError } = this.state;
		const { children } = this.props;
		if (hasError) {
			// You can render any custom fallback UI
			return (
				<BoxWrap>
					<Box>
						<h1>Something went wrong.</h1>
						<ButtonWrap onClick={redirectToLogin}>Logout</ButtonWrap>
					</Box>
				</BoxWrap>
			);
		}
		return children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
