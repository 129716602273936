/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Select, DatePicker, message as toaster } from 'antd';
import { isEmpty, isFinite } from 'lodash';
import moment from 'moment';
import {
	getSalesOrderList,
	getSaleStoreList,
	getStockOneCallBack,
} from 'store/fulfilment/salesOrderSlice';
import { Box, Flex, Button, AsyncSelect } from 'atoms';
import { SearchBar, LabelWrapper, LoadAsyncSelectComponent, WMSMessage } from 'components/common';
import { salesOrderListColumns, handleFilterChange } from 'utils/tableConfig';
import {
	toAsysnOption,
	disableFutureDate,
	disableDateRangesNew,
	ORDER_STATUSES,
	SalesOrderQueries,
	SelectFilterLabelOptions,
	searchBox,
} from 'utils';
import { SearchField } from 'components/common/custom-table/Style';
import { useQueryParams } from 'hooks';
import { getStoreDataOnLoad } from 'store/OnBoardSlice';
import { Rupee } from 'components';
import TLTable from './TLTable';

export default function TransactionLedger() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [storeList, setStoreList] = useState({ type: 'NotAsked' });
	const [rowData, setRowData] = useState({});
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [wmsSuccessMessage, setWmsSuccessMessage] = useState(null);
	const [paginateConfig, setpaginateConfig] = useQueryParams({ filterQuery: SalesOrderQueries });
	const [outstandingAmount, setOutstandingAmount] = useState(null);

	const handleClick = (id) => navigate(`/order-management/sales-order/${id}`);

	useEffect(() => {
		setStoreList({ type: 'Loading' });

		dispatch(
			getStoreDataOnLoad({
				fc_ids: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [],
				storeIds: paginateConfig?.stores?.length ? paginateConfig?.stores : [],
			})
		).then((res) => setStoreList({ type: 'Success', data: toAsysnOption(res) }));
	}, [dispatch]);

	const handleChangeSelect = (name, value) => {
		const arr = value ? [value] : [];
		// as this is asyncselect we need to destructure value from the option value
		const getValue = isEmpty(arr) ? [] : arr.map((y) => y.value);
		setpaginateConfig((prev) => handleFilterChange(name, getValue, prev));
	};

	function handleStockOneCallBack(record) {
		dispatch(getStockOneCallBack(record)).then((res) => {
			setWmsSuccessMessage([res]);
			setIsModalOpen(true);
		});
	}

	function hideModal() {
		setIsModalOpen(false);
		window.location.reload();
	}

	const getOutstandingAmountStore = () => {
		const allowedStatusOutstandingAmt = ['DL', 'PD'];
		const validStatus = paginateConfig?.statuses?.every((status) =>
			allowedStatusOutstandingAmt.includes(status)
		);
		if (paginateConfig?.stores?.length && validStatus) {
			let sum = 0;
			rowData.rows.forEach((element) => {
				const val = Number(element.OutstandingInvoice.current_outstanding_amount);
				sum += val;
			});
			setOutstandingAmount(Number(sum.toFixed(2)));
		} else {
			setOutstandingAmount(null);
		}
	};

	useEffect(() => {
		if (rowData?.rows?.length && paginateConfig?.stores?.length) {
			getOutstandingAmountStore();
		}
	}, [rowData]);

	return (
		<>
			<Flex alignItems="center">
				<Flex width="100%" flexWrap="wrap" alignItems="center" gap="4px">
					<Box marginLeft="1rem">
						<LabelWrapper label="Search by Invoice Number" marginspace="-1px 0 5px 0">
							<SearchField>
								<SearchBar
									placeholder="Search by Invoice Number"
									queryValue={paginateConfig?.queryInvoice?.replaceAll('*', '')}
									onSearch={(val) =>
										setpaginateConfig((prev) => ({
											...prev,
											limit: 50,
											offset: 0,
											currentPage: 1,
											queryInvoice: val,
										}))
									}
								/>
							</SearchField>
						</LabelWrapper>
					</Box>

					<LabelWrapper label="Store(s)" marginspace="3px 0 -1px 1rem">
						<LoadAsyncSelectComponent options={storeList}>
							<AsyncSelect
								style={searchBox}
								allowClear
								name="stores"
								fetchOptions={getSaleStoreList}
								apiParams={{ fc_ids: paginateConfig?.fcs || [] }}
								placeholder="Store(s)"
								defaultValue={paginateConfig?.stores}
								value={paginateConfig?.stores}
								onChange={(value) => {
									handleChangeSelect('stores', value);
								}}
								selectOptions={[...(storeList.data || [])]}
								showSearch
							/>
						</LoadAsyncSelectComponent>
					</LabelWrapper>

					<LabelWrapper label="Status(s)" marginspace="3px 0 -1px 1rem">
						<Select
							name="statuses"
							style={searchBox}
							allowClear
							showSearch
							options={ORDER_STATUSES}
							value={paginateConfig?.statuses}
							filterOption={SelectFilterLabelOptions}
							defaultValue={[
								{ value: 'DL', label: 'Delivered' },
								{ value: 'PD', label: 'Partial Delivery' },
							]}
							mode="multiple"
							placeholder="Status(s)"
							onChange={(value) =>
								setpaginateConfig((prev) =>
									handleFilterChange('statuses', value, prev)
								)
							}
						/>
					</LabelWrapper>

					<LabelWrapper label="From Date" marginspace="3px 0 -1px 1rem">
						<DatePicker
							name="invoice_date__gte"
							style={searchBox}
							label="From Date"
							value={
								paginateConfig?.invoice_date__gte
									? moment(paginateConfig?.invoice_date__gte)
									: ''
							}
							disabledDate={disableFutureDate}
							onChange={(value) =>
								setpaginateConfig((prev) =>
									handleFilterChange('invoice_date__gte', value, prev)
								)
							}
							placeholder="From Date"
						/>
					</LabelWrapper>

					<LabelWrapper label="To Date" marginspace="3px 0 -1px 1rem">
						<DatePicker
							name="invoice_date__lte"
							style={searchBox}
							value={
								paginateConfig?.invoice_date__lte
									? moment(paginateConfig?.invoice_date__lte)
									: ''
							}
							label="To Date"
							onChange={(value) =>
								setpaginateConfig((prev) =>
									handleFilterChange('invoice_date__lte', value, prev)
								)
							}
							disabled={!paginateConfig?.invoice_date__gte}
							disabledDate={disableDateRangesNew(paginateConfig?.invoice_date__gte)}
							placeholder="To Date"
						/>
					</LabelWrapper>
				</Flex>
				<Flex style={{ gap: 16 }}>
					<Box style={{ width: '10rem' }}>
						<Button
							style={{ marginBottom: 16 }}
							onClick={() => {
								setOutstandingAmount(null);
								if (!paginateConfig?.stores) {
									toaster.error('Please select store to search');
								}
								setBtnClickSearh(Math.random());
							}}
						>
							Search
						</Button>
					</Box>
				</Flex>
			</Flex>
			{rowData?.rows?.length > 0 && isFinite(outstandingAmount) ? (
				<Box background="#CA5010" padding="10px" marginTop="10px" color="#fff" width="30%">
					Total Outstanding Amount: <Rupee>{outstandingAmount}</Rupee>
				</Box>
			) : null}
			<Box mt={3}>
				<TLTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'id',
					}}
					dispatchAction={getSalesOrderList}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					columns={salesOrderListColumns({
						handleClick,
						defaultSort: paginateConfig?.sort_direction || 'DESC',
						defaultsortField: paginateConfig?.sort_column || 'id',
						handleStockOneCallBack,
					})}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
			<WMSMessage visible={isModalOpen} handleHide={hideModal} data={wmsSuccessMessage} />
		</>
	);
}
