/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { message, Collapse, Button, Row, Col } from 'antd';
import styled from 'styled-components';
import { Box, Text, Image, Flex } from 'atoms';
import { AccordionHeader, PanelData } from 'components/common';
import {
	getSalesOrderAllocation,
	getSalesOrderById,
	getSalesReturnInfo,
} from 'store/fulfilment/salesOrderSlice';
import { AllocationDetailsModal } from 'components/modals';
import ShippingIcon from 'assets/shipping-icon.svg';
import { returnToFcShowPanel } from './salesOrderConfig';
import SalesOrderDetailsTable from './SalesOrderDetailsTable';

const BoxTitleWrapper = styled(Box)`
	background: #ffffff;
	padding: 16px;
	border-radius: 4px;
`;

const RowTitle = styled(Row)`
	.contentLabel {
		font-size: 12px;
		margin-bottom: 5px;
	}
	.bold-text {
		font-size: 14px;
		font-weight: 600;
	}
`;

const BackButton = styled(Button)`
	float: right;
	background-color: #ca5010;
	color: #fff;
	border: 0;
	:hover {
		background-color: #ca5010;
	}
`;

const { Panel } = Collapse;

export default function SalesOrderDetails() {
	const [showAllocationDetail, setShowAllocationDetail] = useState(false);
	const dispatch = useDispatch();
	const { id } = useParams();
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [salesOrderList, setSalesOrderList] = useState({});
	const [salesReturnInfo, setSalesReturnInfo] = useState();
	const [salesOrderDetailsData, setsalesOrderDetailsData] = useState({});

	const headerObj = {
		return_id: searchParams.get('return_id'),
		return_no: searchParams.get('return_no'),
		created_date: searchParams.get('created_at'),
		created_time: searchParams.get('created_time'),
		username: searchParams.get('username'),
	};

	useEffect(() => {
		if (!isEmpty(id)) {
			dispatch(getSalesOrderById({ id })).then((res) => {
				setsalesOrderDetailsData(res);
				if (res?.allocation_id) {
					dispatch(getSalesOrderAllocation({ id: res.allocation_id })).then((res1) => {
						if (res1) delete res1.status;
						const salesData = {
							...res,
							...res1,
						};
						setSalesOrderList(salesData);
					});
				} else {
					setSalesOrderList(res);
					setsalesOrderDetailsData(res);
				}
			});
		} else {
			message.error('Something went wrong!');
			navigate('/order-management/sales-order');
		}
	}, [dispatch]);

	useEffect(() => {
		if (headerObj?.return_id) {
			dispatch(getSalesReturnInfo(headerObj?.return_id)).then((res) => {
				setSalesReturnInfo(res);
			});
		}
	}, [dispatch]);

	console.log(salesOrderDetailsData, 'salesOrderDetailsData');

	return (
		<>
			<Collapse expandIconPosition="right" ghost>
				<Panel
					header={
						<AccordionHeader
							title="Order Details:"
							status={salesOrderDetailsData?.status}
							invoice={salesOrderDetailsData?.invoice_no}
							storeName={salesOrderDetailsData?.Store?.name}
						/>
					}
					key="accoridon"
					className="site-collapse-custom-panel"
				>
					<>
						<Row gutter={32}>
							{returnToFcShowPanel({ salesOrderDetailsData }).map(
								(x) =>
									!x.hidden && (
										<Col key={x?.title} xs={6}>
											<PanelData
												title={x?.title}
												value={x?.value}
												additionalValue={x?.additionalValue || ''}
												type={x?.type || ''}
												infoDetails={x?.infoDetails}
												brandInfo={salesOrderDetailsData?.Brand}
											/>
											{x.hidden}
										</Col>
									)
							)}
						</Row>
						<Button
							mt="20px"
							disabled={salesOrderList?.status === 'PU'}
							onClick={() => setShowAllocationDetail(true)}
						>
							<Flex alignItems="center">
								<Image
									width={16}
									height={16}
									src={ShippingIcon}
									alt="Shipping Icon"
								/>
								<Text ml="8px">View Vehicle Allocation Details</Text>
							</Flex>
						</Button>
						<AllocationDetailsModal
							salesOrderList={salesOrderList}
							showAllocationDetail={showAllocationDetail}
							setShowAllocationDetail={setShowAllocationDetail}
						/>
					</>
				</Panel>
			</Collapse>
			<BoxTitleWrapper mt="30px">
				<RowTitle gutter={32}>
					{headerObj?.return_no && (
						<Col xs={4}>
							<div className="contentLabel">Return No</div>
							<div className="bold-text">{headerObj?.return_no}</div>
						</Col>
					)}

					{headerObj?.username && (
						<Col xs={6}>
							<div className="contentLabel">Created By</div>
							<div className="bold-text" style={{ wordBreak: 'break-word' }}>
								{headerObj?.username}
							</div>
						</Col>
					)}

					{headerObj?.created_date && (
						<Col xs={4}>
							<div className="contentLabel">Return Created Date</div>
							<div className="bold-text">{`${headerObj?.created_date} ${headerObj?.created_time} `}</div>
						</Col>
					)}

					<Col xs={10}>
						<BackButton
							mt={3}
							onClick={() => {
								navigate(`/order-management/sales-order/${id}`, {
									state: { from: location },
								});
							}}
						>
							Go Back
						</BackButton>
					</Col>
				</RowTitle>
			</BoxTitleWrapper>
			<Box mt="30px">
				<SalesOrderDetailsTable tab="return" data={salesReturnInfo} />
			</Box>
		</>
	);
}
