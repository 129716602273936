import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Text, Flex } from 'atoms';
import { setTab } from 'store/invoiceSlice';

const tabs = [
	{ id: 1, text: 'Salesperson', value: '1' },
	{ id: 2, text: 'Deliverer', value: '2' },
];

export default function Tab() {
	const dispatch = useDispatch();
	const { tab = '1' } = useSelector((state) => state.invoice);

	function handleTabChange(type = '1') {
		dispatch(setTab(type));
	}

	return (
		<Flex>
			{tabs?.map((item) => (
				<Title
					key={item?.id}
					onClick={() => handleTabChange(item?.value)}
					isActive={tab === item?.value}
				>
					{item?.text}
				</Title>
			))}
		</Flex>
	);
}

const Title = styled(Text)`
	font-weight: 300;
	font-size: 14px;
	color: ${(props) => props.theme.colors.Silver};
	cursor: pointer;
	margin-right: 1.5rem;

	${({ isActive }) =>
		isActive &&
		css`
			font-weight: 600;
			color: ${(props) => props.theme.colors.secondary};
		`}
`;
