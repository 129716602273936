import React from 'react';
import styled from 'styled-components';
import { Box } from 'atoms';
import { ImageNotAvailable } from 'utils';

const UploadText = styled.div`
	text-align: center;
	color: #e5383b;
	font-size: 18px;
	font-weight: 500;
	background-color: #e8e8e8;
`;

const CarouselStyled = styled(Box)`
	height: 600px;
	background-color: #e8e8e8;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export default function EpodNotAvailableModal() {
	return (
		// <CarouselStyled>
		// 	<UploadText>No Data Found</UploadText>
		// </CarouselStyled>
		<CarouselStyled>
			<ImageNotAvailable />
			<UploadText>Proof Of Delivery not yet Updated!</UploadText>
			<UploadText style={{ fontSize: '11px' }}>
				Proof Of Delivery will be available once Delivery Agent or Transport Manager uploads
				it.
			</UploadText>
		</CarouselStyled>
	);
}
