import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { Text } from 'atoms';
import { Rupee } from 'components';

const columns = [
	{
		title: 'Product Name (Product Code)',
		dataIndex: 'product_name',
		key: 'product_name',
		render: (product_name, record) => {
			console.log('rr', record);

			return (
				<>
					<Text textTransform="uppercase">{product_name[0]}</Text>
					<Text textTransform="uppercase">({product_name[1]})</Text>
					<Rupee>{record?.mrp}</Rupee>
				</>
			);
		},
	},
	{
		title: 'Total Returned',
		dataIndex: 'returned_qty',
		key: 'returned_qty',
	},
	{
		title: 'Bad Qty',
		dataIndex: 'bad_qty',
		key: 'bad_qty',
	},
	{
		title: 'Good Qty',
		dataIndex: 'good_qty',
		key: 'good_qty',
	},
	{
		title: 'Total Missing Qty',
		dataIndex: 'missing_qty',
		key: 'missing_qty',
		render: (missing_qty) => {
			if (missing_qty > 0) {
				return (
					<Text
						style={{
							background: '#FF4747',
							display: 'inline-block',
							padding: '4px 8px',
							borderRadius: '4px',
							color: '#fff',
						}}
					>
						{missing_qty}
					</Text>
				);
			}
			return <Text>{missing_qty}</Text>;
		},
	},
	{
		title: 'Return Reason',
		dataIndex: 'return_reason',
		key: 'return_reason',
		// render: (return_reason) => <Text textTransform="uppercase">{return_reason}</Text>,
	},
];

export default function ReturnsLogShowTable({ data }) {
	console.log('dataaa', data);
	const tableData = data?.map((x, index) => ({
		key: index,
		product_name: [x?.product_name, x?.product_code],
		mrp: x?.mrp,
		returned_qty: x?.returned_qty,
		bad_qty: x?.bad_qty,
		good_qty: x?.good_qty,
		missing_qty: x?.missing_qty,
		return_reason: x?.return_reason,
	}));

	return <Table columns={columns} dataSource={tableData} pagination={false} />;
}

ReturnsLogShowTable.propTypes = {
	data: PropTypes.object,
};
