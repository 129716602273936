/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react/no-array-index-key */
import * as Yup from 'yup';
import { uniqWith } from 'lodash';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

const initialValues = {
	adhoc_collection_source_id: undefined,
	amount: undefined,
	cheque: [
		{
			amount: undefined,
			bank_id: undefined,
			due_date: undefined,
			reference_number: undefined,
		},
	],
	UPI: [{ amount: undefined, reference_number: undefined }],
	NEFT: [{ amount: undefined, reference_number: undefined }],
};

Yup.addMethod(Yup.array, 'unique', function testUnique(message) {
	return this.test('unique', message, function uniques(list) {
		// https://stackoverflow.com/a/52525557/3882241
		const uniqueCheques = uniqWith(
			list,
			(chequeA, chequeAB) =>
				chequeA.bank_id === chequeAB.bank_id &&
				chequeA.reference_number === chequeAB.reference_number &&
				chequeA.bank_id &&
				chequeA.reference_number
		);

		return list.length === uniqueCheques?.length;
	});
});

Yup.addMethod(Yup.array, 'uniqueRef', function testUniqueRef(message) {
	return this.test('uniqueRef', message, function uniquesRefNo(list) {
		const uniqueCheques = uniqWith(
			list,
			(chequeA, chequeAB) =>
				chequeA.reference_number === chequeAB.reference_number && chequeA.reference_number
		);

		return list.length === uniqueCheques.length;
	});
});

const ASFormSchema = Yup.object({
	adhoc_collection_source_id: Yup.string().label('Source').required(),
	amount: Yup.number().label('Cash').positive().min(1).max(1000000),
	cheque: Yup.array()
		.of(
			Yup.object().shape({
				amount: Yup.number().label('Cheque').min(1).max(1000000),
				reference_number: Yup.string().when('amount', {
					is: (amount) => amount > 0,
					then: Yup.string()
						.label('*Cheque Ref no')
						.matches(
							/^(?=\d{6}$)\d*[1-9]\d*/,
							'Please enter valid last 6 digits of Cheque Ref no'
						)
						.required('*Cheque Ref no last 6 digits: is required'),
					otherwise: Yup.string(),
				}),
				bank_id: Yup.number().when('amount', {
					is: (cheque) => cheque > 0,
					then: Yup.number().label('*Bank Name').required('*Bank Name is required'),
					otherwise: Yup.number(),
				}),
				due_date: Yup.string().when('amount', {
					is: (cheque) => cheque > 0,
					then: Yup.string('*Due Date')
						.label('*Due Date')
						.required('*Due Date is required')
						.nullable(),
					otherwise: Yup.string('Due Date').nullable(),
				}),
			})
		)
		.unique('Cheque reference number and bank name must not be same, please change that'),
	UPI: Yup.array()
		.of(
			Yup.object().shape({
				amount: Yup.number().label('UPI').min(1).max(1000000),
				reference_number: Yup.string().when('amount', {
					is: (amount) => amount > 0,
					then: Yup.string()
						.label('*UPI Ref no')
						.min(12)
						.max(16)
						.matches(/^[0-9]+$/i, 'Please enter valid last UPI Ref no')
						.required('*UPI Ref no: is required'),
					otherwise: Yup.string(),
				}),
			})
		)
		.uniqueRef('UPI Reference number must not be same, please change that'),
	NEFT: Yup.array()
		.of(
			Yup.object().shape({
				amount: Yup.number().label('NEFT').min(1).max(1000000),
				reference_number: Yup.string().when('amount', {
					is: (amount) => amount > 0,
					then: Yup.string()
						.label('*NEFT Ref no')
						.min(16)
						.max(22)
						.matches(/^[a-z0-9]+$/i, 'Please enter valid last NEFT Ref no')
						.required('*NEFT Ref no: is required'),
					otherwise: Yup.string(),
				}),
			})
		)
		.uniqueRef('NEFT Reference number must not be same, please change that'),
});

function getPayments(values) {
	const payment = [];

	if (values?.amount) {
		payment.push({
			payment_type: 'Cash',
			amount: values?.amount,
		});
	}

	if (values?.cheque?.length > 0) {
		values?.cheque.forEach(({ reference_number, due_date, amount, bank_id }) => {
			if (reference_number && due_date && amount && bank_id) {
				payment.push({
					payment_type: 'Cheque',
					reference_number,
					due_date: moment(due_date).format(dateFormat),
					amount,
					bank_id,
				});
			}
		});
	}

	if (values?.NEFT?.length > 0) {
		values?.NEFT.forEach(({ reference_number, amount }) => {
			if (reference_number && amount) {
				payment.push({
					payment_type: 'NEFT',
					reference_number,
					amount,
				});
			}
		});
	}

	if (values?.UPI?.length > 0) {
		values?.UPI.forEach(({ reference_number, amount }) => {
			if (reference_number && amount) {
				payment.push({
					payment_type: 'UPI',
					reference_number,
					amount,
				});
			}
		});
	}

	return payment;
}

export function disableDateRanges() {
	// https://stackoverflow.com/a/68923354/3882241
	const FUTURE_RANGE = 81;
	const PAST_RANGE = 45;
	const startDate = moment().subtract(PAST_RANGE, 'days').format(dateFormat);
	const endDate = moment().add(FUTURE_RANGE, 'days').format(dateFormat);

	return function disabledDate(current) {
		const startCheck = current && current < moment(startDate, dateFormat);
		const endCheck = current && current > moment(endDate, dateFormat);

		return startCheck || endCheck;
	};
}

export { initialValues, ASFormSchema, getPayments };
