/* eslint-disable import/namespace */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Box, Flex } from 'atoms';
import { setRecentUrl } from 'store/appSlice';
// import { setRecentUrl, toggleSidebar } from 'store/appSlice';
import { useAuth } from 'hooks';
import { SideBar, MainWindow } from 'components/common';
import { Header } from 'components';
import { redirectToLogin } from 'api/authentication';

export const BodyBox = Styled(Box)`
	width: 100%;
	padding-left: ${(props) => (props?.sideBarStatus ? '40px' : '210px')};
	transition: all 0.3s;
`;

export const FlexBox = Styled(Flex)`
	overflow: hidden;
`;

function PrivateRoute({ children, breadCrumbsList = [], settoggleTheme }) {
	const dispatch = useDispatch();
	const location = useLocation();
	const tokens = useAuth();
	const { me } = useSelector((state) => state.auth);
	const { pathname = null } = location;
	const { sideBarStatus } = useSelector((state) => state.app);

	useEffect(
		() => () => {
			/**
			 * to set last visited page url in redux store
			 */
			if (pathname) {
				console.log('pathname', pathname);
				// dispatch(toggleSidebar(true));
				dispatch(setRecentUrl(pathname));
			}
		},
		[pathname, dispatch]
	);

	if (!me || !me?.id || !tokens || !tokens?.access) {
		redirectToLogin();
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return (
		<FlexBox>
			<SideBar />
			<BodyBox sideBarStatus={sideBarStatus}>
				<p>sdf</p>
				{!isEmpty(breadCrumbsList) && (
					<Header
						sideBarStatus={sideBarStatus}
						settoggleTheme={settoggleTheme}
						urls={breadCrumbsList}
					/>
				)}
				<MainWindow>{children}</MainWindow>
			</BodyBox>
		</FlexBox>
	);
}

PrivateRoute.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	breadCrumbsList: PropTypes.array,
	settoggleTheme: PropTypes.func,
};

export default PrivateRoute;
