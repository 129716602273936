/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { capitalize, upperCase } from 'lodash';
import * as SVGICONS from 'utils/Svg';
import { Box, Button, Flex } from 'atoms';
import { RCTable, LabelWrapper, SearchBar } from 'components/common';
import {
	DownloadQueries,
	handleFilterChange,
	SelectFilterLabelOptions,
	searchBox,
	// initialPaginateConfigWithoutQ,
	PackMasterColums,
	// toAsysnOption,
	// SelectFilterNameOptions,
} from 'utils';
import { useQueryParams } from 'hooks';
import { getBrandsSearchList, getPackMasterList } from 'store/masters';
import { ACTIVE_INACTIVE_CHOICE_STRING } from 'components/forms/onboarding/helper';
import { SearchField } from 'components/common/custom-table/Style';
import { getBrands } from 'store/collectionSlice';
// import { getPackMasterList } from 'store/PackMasterSlice';
export default function PackMaster() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// const [brandsList, setBrandList] = useState([]);
	const [btnClickSearch, setBtnClickSearch] = useState({});
	const [brandOptions, setbrandOptions] = useState([]);
	const [productLists, setProductLists] = useState({});
	const [paginateConfig, setpaginateConfig] = useQueryParams({ filterQuery: DownloadQueries });
	const [rowData, setRowData] = useState({});

	// const { auth } = useSelector((state) => state?.auth);
	useEffect(() => {
		dispatch(getBrandsSearchList({})).then((brandList) => {
			setProductLists(brandList.rows);
		});
	}, [dispatch]);

	useEffect(() => {
		console.log(productLists);
		dispatch(getBrands()).then((res) => {
			setbrandOptions(
				res?.map((y) => ({
					value: y.code,
					label: `${upperCase(y.code)}: ${capitalize(y.name)}`,
				}))
			);
		});
	}, [dispatch]);

	return (
		<>
			<Flex justifyContent="space-between" margin="0 0 1rem">
				<Box>
					<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
						<Select
							name="brands"
							style={searchBox}
							allowClear
							showSearch
							label="brands"
							value={paginateConfig?.brands || undefined}
							onChange={(value) =>
								setpaginateConfig((prev) => ({
									...prev,
									brands: [value],
								}))
							}
							filterOption={(input, option) =>
								option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							options={brandOptions}
						/>
					</LabelWrapper>
					<LabelWrapper label="Search by Product code" marginspace="0 0 5px 1rem">
						<SearchField margin="0px 1rem 1rem">
							<SearchBar
								placeholder="Search by product code"
								queryValue={paginateConfig.product_code}
								onSearch={(val) => {
									setpaginateConfig((prev) => ({
										...prev,
										product_code: val,
										offset: 0,
										limit: 10,
										currentPage: 1,
									}));
								}}
							/>
						</SearchField>
					</LabelWrapper>
					<LabelWrapper label="Status" marginspace="3px 0 -1px 1rem">
						<Select
							name="statuses"
							style={searchBox}
							allowClear
							showSearch
							options={ACTIVE_INACTIVE_CHOICE_STRING}
							value={paginateConfig?.statuses}
							filterOption={SelectFilterLabelOptions}
							placeholder="Status(s)"
							onChange={(value) => {
								setpaginateConfig((prev) =>
									handleFilterChange('statuses', value, prev)
								);
							}}
						/>
					</LabelWrapper>
					<Button onClick={() => setBtnClickSearch(Math.random())}>Search</Button>
				</Box>
				<Button
					height="40px"
					type="variant"
					onClick={() => navigate('/onboarding/masters/packmaster/add')}
				>
					<Flex alignItems="center" justifyContent="center">
						<Box width={32}>
							<SVGICONS.PlusOutlinedIcons />
						</Box>
						<Box>Create New</Box>
					</Flex>
				</Button>
			</Flex>
			<Box>
				<RCTable
					rowKey="id"
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'id',
					}}
					tableDatas={[rowData, setRowData]}
					dispatchAction={getPackMasterList}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					columns={PackMasterColums()}
					triggerBtnSearch={[btnClickSearch, setBtnClickSearch]}
					disableAutoSearch
				/>
			</Box>
		</>
	);
}
