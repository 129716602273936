import React from 'react';
import styled, { keyframes } from 'styled-components';

const loop = Array.from(Array(10).keys());

const placeholderShimmer = keyframes`
0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0; 
  }
`;

export const Box = styled.div`
	width: 90%;
	height: 60px;
	margin: 10px auto;
	background: #f6f7f8;
	background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
	background-repeat: no-repeat;
	background-size: 800px 104px;
	position: relative;
	border-radius: 5px;
	-webkit-animation-duration: 1.5s;
	-webkit-animation-fill-mode: forwards;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-name: ${placeholderShimmer};
	-webkit-animation-timing-function: linear;
`;

export default function Shimmer() {
	return (
		<>
			{loop.map((i) => (
				<Box key={i} />
			))}
		</>
	);
}
