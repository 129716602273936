import React, { useMemo, useState, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box, Flex, Text } from 'atoms';
import Search from 'assets/icons/search.svg';

const Container = styled(Flex)`
	justify-content: center;
	align-items: center;
	min-height: 37px;
	background-color: #ffffff;
	position: relative;
	border: 1px solid #d9d9d9;
	img {
		width: 14px;
		background-color: inherit;
	}
`;

const Input = styled.input.attrs((props) => ({
	type: 'text',
	placeholder: props.placeholder,
}))`
	border: 0;
	width: 80%;
	padding: 10px;
	padding-top: 9px;
	font-size: 12px;
	font-weight: 300;
	color: #3c3c3c;
	background-color: inherit;

	&:focus {
		outline: none;
	}
`;

export default function SearchBar(props) {
	const [cleared, setCleared] = useState(true);
	const inputRef = useRef();
	const { onSearch, queryValue = '', style = {}, label = '', ...rest } = props;

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.value = queryValue;
		}
	}, [queryValue]);

	useEffect(() => {
		if (queryValue) {
			setCleared(false);
		}
	}, [queryValue]);

	const handleSearchChange = useMemo(
		() =>
			debounce((event) => {
				setCleared(event.target.value === '');
				onSearch(event.target.value);
			}, 100),
		[onSearch]
	);

	return (
		<Box>
			{label && (
				<Text fontWeight="600" fontSize="14px" lineHeight="24px">
					{label}
				</Text>
			)}
			<Container style={style}>
				<img src={Search} alt="search icon" />
				<Input {...rest} ref={inputRef} onChange={handleSearchChange} />
				{!cleared && (
					<CloseOutlined
						style={{ fontSize: '10px', position: 'absolute', right: '15px' }}
						onClick={() => {
							inputRef.current.value = '';
							setCleared(true);
							onSearch('');
						}}
					/>
				)}
			</Container>
		</Box>
	);
}

SearchBar.propTypes = {
	onSearch: PropTypes.func,
	queryValue: PropTypes.string,
	style: PropTypes.object,
	label: PropTypes.string,
};
