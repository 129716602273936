/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Switch } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import styled from 'styled-components';
import { getBrandSalesReturnList } from 'store/fulfilment/brandSalesReturnSlice';
import { Box, AsyncSelect, Button, Text } from 'atoms';
import { RCTable, LoadAsyncSelectComponent, LabelWrapper, SearchBar } from 'components/common';
import { getFcListOnLoad, getFcOnSearchList } from 'store/masters';
import {
	brandSalesReturnListColumns,
	handleFilterChange,
	CommonFCBrandsQueries,
	toAsysnOption,
	searchBox,
	SelectFilterLabelOptions,
	initialPaginateConfigWithoutQ,
} from 'utils';
import { useQueryParams } from 'hooks';
import { SearchField } from 'components/common/custom-table/Style';
import { UploadInvoiceModal } from 'components/modals/UploadInvoiceModal';
import PdfModal from 'components/modals/PdfModal';
import ShowGrnUploadLogs from 'components/modals/ShowGrnUploadsLogs';

const TextS = styled(Text)`
	color: #352a35;
	font-size: 10px;
	margin-bottom: 11px;
`;
const StatusLabelWrapper = styled(LabelWrapper)`
	text-align: center;
	margin-right: 20px;
`;
export default function BrandSalesReturn() {
	const dispatch = useDispatch();

	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);
	const [totalData, setTotalData] = useState();
	const [rowData, setRowData] = useState({});
	const [btnClickSearh, setBtnClickSearh] = useState({});
	const [paginateConfig, setpaginateConfig] = useQueryParams({
		filterQuery: CommonFCBrandsQueries,
	});
	const [uploadInvoiceModalOpen, setUploadInvoiceModalOpen] = useState(false);
	const [pdfModalVisible, setPdfModalVisible] = useState({
		showModal: false,
		data: null,
	});
	const [salesReturnLogsModal, setSalesReturnLogsModal] = useState({
		salesLogs: null,
		visible: false,
	});
	const { auth } = useSelector((state) => state?.auth);
	const [filesUploaded, setFilesUploaded] = useState(0);

	useEffect(() => {
		setFcList({ type: 'Loading' });
		console.log(filesUploaded);
		dispatch(
			getFcListOnLoad({ fcIds: paginateConfig?.fcs?.length ? paginateConfig?.fcs : [] })
		).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});
	}, [dispatch]);

	const handleChangeSwitch = (name, value, option) => {
		console.log(name, value, option);
		setpaginateConfig((prev) => ({
			...handleFilterChange(name, value, prev),
		}));
	};

	const handleChangeSelect = (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value

		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}

		const fcValues = value?.value ? [value?.value] : [];
		setpaginateConfig((prev) => ({
			...handleFilterChange(name, fcValues, prev),
			brands: [],
		}));
	};

	function refreshData() {
		window.location.reload();
	}

	const handleUploadModalOpen = (res) => {
		setTotalData(res);
		setUploadInvoiceModalOpen(true);
	};
	const handleInvocieView = (res) => {
		setPdfModalVisible({ showModal: true, data: res });
	};
	const handleSalesLogsModalOpen = (data) => {
		setSalesReturnLogsModal({ salesLogs: data, visible: true });
	};

	const getUpdatedData = () => {
		refreshData();
	};

	return (
		<>
			<Box>
				<LabelWrapper marginspace="-1px 0 5px 0">
					<SearchField style={{ marginRight: '1rem' }}>
						<SearchBar
							placeholder="Search by Return No"
							allowClear
							queryValue={paginateConfig?.return_no}
							onSearch={(val) =>
								setpaginateConfig((prev) => ({
									...prev,
									limit: 10,
									offset: 0,
									currentPage: 1,
									return_no: val,
								}))
							}
							// disabled={!(paginateConfig?.brands?.length > 0)}
							// style={paginateConfig?.brands?.length > 0 ? {} : DisabledSearch}
						/>
					</SearchField>
				</LabelWrapper>

				<LabelWrapper marginspace="3px 0 -1px 1rem">
					<SearchField>
						<SearchBar
							placeholder="Search by Invoice No"
							allowClear
							queryValue={paginateConfig?.invoice_no}
							onSearch={(val) =>
								setpaginateConfig((prev) => ({
									...prev,
									limit: 10,
									offset: 0,
									currentPage: 1,
									invoice_no: val,
								}))
							}
							// disabled={!(paginateConfig?.brands?.length > 0)}
							// style={paginateConfig?.brands?.length > 0 ? {} : DisabledSearch}
						/>
					</SearchField>
				</LabelWrapper>

				<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
					<LoadAsyncSelectComponent
						loadFCs={paginateConfig?.fcs}
						setList={(val) => setBrandList(val)}
						options={fcsList}
					>
						<AsyncSelect
							style={searchBox}
							allowClear
							name="fcs"
							showSearch
							fetchOptions={getFcOnSearchList}
							transformOptions={(val) => toAsysnOption(val)}
							defaultValue={paginateConfig?.fcs}
							placeholder="Select FC(s)"
							onChange={(value, options) => {
								handleChangeSelect('fcs', value, options);
							}}
							selectOptions={[...(fcsList.data || [])]}
						/>
					</LoadAsyncSelectComponent>
				</LabelWrapper>

				<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
					<Select
						name="fields"
						style={searchBox}
						allowClear
						showSearch
						label="brands"
						mode="multiple"
						value={paginateConfig?.brands}
						filterOption={SelectFilterLabelOptions}
						options={brandsList}
						onChange={(value) =>
							setpaginateConfig((prev) => ({
								...prev,
								brands: value,
								...initialPaginateConfigWithoutQ,
							}))
						}
					/>
				</LabelWrapper>

				<StatusLabelWrapper marginspace="3px 0 -1px 1rem">
					<TextS> Quid Deliveries </TextS>
					<Switch
						checkedChildren="On"
						unCheckedChildren="Off"
						onChange={(value, options) => {
							handleChangeSwitch('bnpl_enabled', value, options);
							// setpaginateConfig((prev) => ({
							// 	...prev,
							// 	brands: value,
							// 	...initialPaginateConfigWithoutQ,
							// }))
						}}
					/>
				</StatusLabelWrapper>
				<Button
					style={{ 'margin-left': '50px' }}
					onClick={() => setBtnClickSearh(Math.random())}
				>
					Search
				</Button>
			</Box>
			{/* <Box>
				
			</Box> */}
			<Box mt={3}>
				<RCTable
					rowKey="id"
					tableDatas={[rowData, setRowData]}
					configs={{
						defaultSort: 'DESC',
						defaultsortField: 'id',
					}}
					dispatchAction={getBrandSalesReturnList}
					togglePaginate={[paginateConfig, setpaginateConfig]}
					columns={brandSalesReturnListColumns({
						defaultSort: paginateConfig?.sort_direction || 'DESC',
						defaultsortField: paginateConfig?.sort_column || 'id',
						handleUploadModalOpen,
						handleInvocieView,
						handleSalesLogsModalOpen,
					})}
					triggerBtnSearch={[btnClickSearh, setBtnClickSearh]}
					disableAutoSearch
				/>
			</Box>
			{uploadInvoiceModalOpen && (
				<UploadInvoiceModal
					toggleState={[uploadInvoiceModalOpen, setUploadInvoiceModalOpen]}
					handleUploadModalOpen={handleUploadModalOpen}
					fileCount={1}
					auth={auth}
					setTotalData={setTotalData}
					rowData={totalData || {}}
					salesReturnUpload
					setFilesUploaded={setFilesUploaded}
					refreshData={refreshData}
				/>
			)}
			<PdfModal
				visible={pdfModalVisible?.showModal}
				data={pdfModalVisible?.data}
				onClose={() =>
					setPdfModalVisible({
						showModal: false,
						data: null,
					})
				}
				salesReturn="salesReturn"
				getUpdatedData={getUpdatedData}
			/>
			<ShowGrnUploadLogs
				visible={salesReturnLogsModal.visible}
				onClose={() => setSalesReturnLogsModal({ salesLogs: null, visible: false })}
				data={salesReturnLogsModal?.salesLogs}
				salesReturn="salesReturn"
			/>
		</>
	);
}
