import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'atoms';

const StyledButton = styled(Button)`
	font-size: 14px;
	border-radius: 4px;
	background-color: #86ccff;
	width: 160px;
	height: 40px;
	padding: 8px;
	color: #001c30;
	font-weight: 500px;
	box-shadow: 0px 4px 4px 0px #00000040;
`;

function UploadEpodButton({ handleUploadModalOpen, style }) {
	return (
		<StyledButton onClick={handleUploadModalOpen} style={style}>
			Add More Image
		</StyledButton>
	);
}

export default UploadEpodButton;

UploadEpodButton.propTypes = {
	handleUploadModalOpen: PropTypes.func,
	style: PropTypes.object,
};
