import React from 'react';
import { Form } from 'formik-antd';
import styled from 'styled-components';

export const Input = styled(Form.Item)`
	font-family: 'Poppins', sans-serif;
	/* Input top label */
	.ant-form-item-label {
		top: 15px;
		left: 10px;
		z-index: 10;
		padding: 0;
		label {
			padding: 5px 10px 0 10px;
			height: 12px;
			font-size: 12px;
			color: ${(props) => props.theme.colors.primary};
			background-color: ${(props) => props.theme.colors.white};
			top: -5px;
		}
	}

	.ant-input-affix-wrapper {
		border: 1px solid ${(props) => props.theme.colors.primary};
	}

	/* input Field */
	input {
		z-index: 5;
		height: 45px;
		border: 1px solid ${(props) => props.theme.colors.primary};
		font-size: 15px;
		font-weight: 300;
		line-height: 22px;
		color: ${(props) => props.theme.colors.black};
	}

	/* Password Field */
	.ant-input-password {
		padding: 0px 11px;
	}

	/* Select Field */
	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		border: 1px solid ${(props) => props.theme.colors.primary};
	}

	.ant-select-selector {
		min-height: 45px;

		input {
			min-height: 45px;
		}
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		padding-top: 7px;
	}

	.ant-select-selection-overflow-item > span {
		display: flex;
	}

	.ant-select-selection-item {
		font-size: 15px;
		font-weight: 300;
		color: ${(props) => props.theme.colors.black};
	}
`;

export default function FormInput(props) {
	return (
		<Input
			hasFeedback={false}
			showValidateSuccess={false}
			labelCol={{ span: 24 }}
			wrapperCol={{ span: 24 }}
			{...props}
		/>
	);
}
