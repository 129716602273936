import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Select, message as toaster } from 'antd';
import { isEmpty, uniqBy } from 'lodash';
import { Text, Box, Flex, Button, AsyncSelect } from 'atoms';
import * as SVGICONS from 'utils/Svg';
import { SearchBar, LabelWrapper, LoadAsyncSelectComponent, ACL } from 'components/common';
import { SearchField } from 'components/common/custom-table/Style';
import { getFcListOnLoad, getFcOnSearchList } from 'store/masters';
import {
	GrnLogsTableConfig,
	SelectFilterLabelOptions,
	selectOptionsFunc,
	WMS_STATUS_CHOICE,
	searchBox,
	toAsysnOption,
	SalesOrderQueries,
	UserPersmission,
} from 'utils';
import { showLoader, hideLoader } from 'store/appSlice';
import {
	getGrnList,
	getRetryGrnLogsList,
	getInfoByInvoice,
	getStatusGRNlogs,
	getPaginatedlogs,
} from 'store/fulfilment/grnLogsSlice';
import { WmsFailedLogs } from 'components/modals/WmsFailedLogs';
import { useQueryParams } from 'hooks';

function getData(data = '') {
	return data?.split(':')?.[0];
}

const selectOptions = selectOptionsFunc(WMS_STATUS_CHOICE);

export default function GrnLogs() {
	const dispatch = useDispatch();
	const [rowData, setRowData] = useState();
	const [modalVisible, setModalVisible] = useState('');
	const [wmsErrorInfo, setWmsErrorInfo] = useState('');
	const [selectionType, setSelectionType] = useState(0);
	const [totalDataCount, settotalDataCount] = useState([]);
	const [isStatus, setStatus] = useState(false);
	const [limit, setLimitvalue] = useState(10);

	const [selectedFc, setSelectedFc] = useState();
	const [selectedBrand, setSelectedBrand] = useState();
	const [invoice, setInvoice] = useState();
	const [paginateConfig] = useQueryParams({ filterQuery: SalesOrderQueries });
	const [fcsList, setFcList] = useState({ type: 'NotAsked' });
	const [brandsList, setBrandList] = useState([]);

	useEffect(() => {
		setSelectedBrand(null);
		setFcList({ type: 'Loading' });

		dispatch(getFcListOnLoad({ fcIds: [] })).then((res) => {
			setFcList({ type: 'Success', data: toAsysnOption(res) });
		});
	}, [dispatch]);

	const getErrorData = (data) => {
		const parsedData = JSON.stringify(data.add_info);
		setWmsErrorInfo(parsedData);
	};

	useEffect(() => {
		dispatch(showLoader());
		defaultWMSlogs();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateTableData = (res) => {
		settotalDataCount(res.count);
		setRowData(res.rows);
		dispatch(hideLoader());
	};

	const defaultWMSlogs = () => {
		setSelectionType([]);
		setStatus(false);
		dispatch(getGrnList({ offset: 0, limit: 10, current: 1 })).then((res) => {
			updateTableData(res);
		});
	};

	const pushtoWMSP = async () => {
		if (selectionType.length > 0) {
			dispatch(showLoader());
			dispatch(getRetryGrnLogsList({ selectionType })).then(() => {
				toaster.success('Items pushed to WMS');
				defaultWMSlogs();
			});
		}
	};

	const getInvoice = () => {
		dispatch(showLoader());
		if (invoice && selectedFc && selectedBrand) {
			dispatch(getInfoByInvoice({ invoice, fcs: selectedFc, brands: selectedBrand })).then(
				(result) => {
					updateTableData(result);
				}
			);
		} else {
			defaultWMSlogs();
		}
	};

	const rowSelection = {
		onChange: (newSelectedRowKeys) => {
			setSelectionType(newSelectedRowKeys);
		},
		getCheckboxProps: (record) => ({
			disabled: record.status === 'CREATED',
			name: record.name,
		}),
	};

	const onChangeStatus = (status) => {
		setSelectionType([]);
		dispatch(showLoader());
		if (status) {
			setStatus(status);
			dispatch(getStatusGRNlogs({ status })).then((result) => {
				updateTableData(result);
			});
		} else {
			defaultWMSlogs();
		}
	};

	const hasSelected = selectionType.length;
	const showModal = () => {
		setModalVisible(true);
	};
	const handleOk = () => {
		setModalVisible(false);
	};

	const handleChange = (pagination) => {
		dispatch(showLoader());
		const offset = pagination.current * pagination.pageSize - pagination.pageSize;
		setLimitvalue(pagination.pageSize);
		const { current } = pagination;
		const status = isStatus;
		dispatch(getPaginatedlogs({ status, offset, limit, current })).then((result) => {
			updateTableData(result);
		});
	};

	const handleFCChangeSelect = (name, value, brandsOptions = []) => {
		// as this is asyncselect we need to destructure value from the option value
		if (!isEmpty(brandsOptions) && !isEmpty(value)) {
			const updatedOptions = brandsOptions
				.filter((x) => x.value === value?.value)
				.map((x) => x?.brands || [])
				.flat();

			setBrandList(uniqBy(updatedOptions, (x) => x.value));
		} else {
			setBrandList([]);
		}
	};

	return (
		<>
			<Box className="grnLogsPage" mt={3}>
				<Flex
					width="100%"
					flexWrap="wrap"
					alignItems="center"
					justifyContent="space-between"
					marginBottom="1rem"
				>
					<Flex style={{ gap: '1rem}' }}>
						<LabelWrapper label="Search" marginspace="-1px 0 5px 0">
							<SearchField style={{ marginRight: '10px' }}>
								<SearchBar
									placeholder="Search by Invoice"
									onSearch={(val) => {
										setInvoice(val);
									}}
								/>
							</SearchField>
						</LabelWrapper>
						<LabelWrapper label="FC(s)" marginspace="3px 0 -1px 1rem">
							<LoadAsyncSelectComponent
								loadFCs={paginateConfig?.fcs}
								setList={(val) => setBrandList(val)}
								options={fcsList}
							>
								<AsyncSelect
									style={searchBox}
									name="fcs"
									showSearch
									fetchOptions={getFcOnSearchList}
									transformOptions={(val) => toAsysnOption(val)}
									placeholder="Select FC(s)"
									onChange={(value, options) => {
										setSelectedBrand(null);
										handleFCChangeSelect('fcs', value, options);
										if (value) {
											setSelectedFc(getData(value?.label));
										}
									}}
									selectOptions={[...(fcsList.data || [])]}
								/>
							</LoadAsyncSelectComponent>
						</LabelWrapper>
						<LabelWrapper label="Brands" marginspace="3px 0 -1px 1rem">
							<Select
								name="fields"
								style={searchBox}
								// allowClear
								showSearch
								label="brands"
								value={selectedBrand}
								filterOption={SelectFilterLabelOptions}
								options={brandsList}
								onChange={(value) => {
									if (value) {
										const selected = brandsList.find(
											(item) => item?.value === value
										);
										setSelectedBrand(getData(selected?.label));
									}
								}}
							/>
						</LabelWrapper>
						<Box mr="3rem">
							<Button
								mt="1.1rem"
								onClick={getInvoice}
								disabled={!(invoice && selectedFc && selectedBrand)}
							>
								Search
							</Button>
						</Box>
						<LabelWrapper label="Select Status" marginspace="5px 0 3px 0">
							<Select
								style={{ width: '200px', alignSelf: 'center' }}
								allowClear
								name="fileType"
								placeholder="Select Status"
								showSearch
								filterOption={SelectFilterLabelOptions}
								options={selectOptions}
								onChange={(value) => {
									onChangeStatus(value);
								}}
							/>
						</LabelWrapper>
					</Flex>
					<ACL allowedGroups={UserPersmission.pologs_create}>
						<Button onClick={pushtoWMSP} disabled={!hasSelected}>
							<Flex alignItems="center" justifyContent="center">
								<Box width={36}>
									<SVGICONS.RefreshIcons />
								</Box>
								<Box>Push to WMS</Box>
							</Flex>
						</Button>
					</ACL>
				</Flex>
				<div>
					<Box mb="16px">
						<Text ml="8px">{hasSelected ? `Selected ${hasSelected} items` : ''}</Text>
					</Box>
					<Table
						rowSelection={{
							type: selectionType,
							...rowSelection,
						}}
						columns={GrnLogsTableConfig({
							showModal,
							getErrorData,
						})}
						dataSource={rowData}
						rowKey={(rowItem) => rowItem.id}
						onChange={handleChange}
						pagination={{
							total: totalDataCount,
						}}
					/>
				</div>
			</Box>

			{modalVisible && (
				<WmsFailedLogs
					title="Additional Information"
					visible={modalVisible}
					toggleState={[modalVisible, setModalVisible]}
					onOk={handleOk}
					onCancel={handleOk}
					rowData={wmsErrorInfo}
				/>
			)}
		</>
	);
}
