import { uniq, compact, isEmpty, uniqBy, flattenDeep } from 'lodash';
import moment from 'moment';
import { unScReplace, ORDER_STATUSES } from 'utils';

export function hiddenColumns({ columns }) {
	return columns
		.map((data) =>
			data.hidden
				? {
						value: data.dataIndex,
						label: data.title,
				  }
				: false
		)
		.filter((x) => x);
}

export function stringToArrObj(userList, key) {
	if (isEmpty(userList)) return [];
	const filteredUserTypes = compact(
		uniq(
			userList?.data
				?.map((user) => user?.[key])
				.join(',')
				.split(',')
		)
	);
	return filteredUserTypes?.map((item) => ({
		text: unScReplace(item),
		value: item,
	}));
}

export function findFcs(userList) {
	if (isEmpty(userList)) return [];
	const filteredFcs = compact(uniq(userList?.data?.map((user) => user?.user_fc)));
	return filteredFcs?.map((item) => ({
		text: unScReplace(item),
		value: item,
	}));
}

export const getDeliveryStatus = (list) => {
	if (isEmpty(list)) return [];
	const result = uniqBy(list.data.map((d) => d.status));
	return result.map((d) => ({
		text: unScReplace(d),
		value: d,
	}));
};

export function getPaymentsType(list, key) {
	if (isEmpty(list)) return [];
	let result = list?.data?.map((item) => item?.[key]?.map((it) => it?.payment_type));
	result = uniq(compact(flattenDeep(result)));
	return result?.map((item) => ({
		text: item,
		value: item,
	}));
}

export function getstatus(stats) {
	return ORDER_STATUSES.filter((x) => x.value === stats)[0]?.label;
}

// For all the filterBy displayed in UI, managed by this event
export const handleFilterChange = (name, values, paginateConfig) => {
	console.log('name ', name);
	console.log('values ', values);
	console.log('paginateConfig ', paginateConfig);
	const checkIsDate = name.includes('date');

	if (name !== 'bnpl_enabled' && isEmpty(values) && typeof values !== 'number') {
		const { [name]: RemoveEmpty, ...rest } = paginateConfig;

		return { ...rest, offset: 0, limit: 10, currentPage: 1 };
	}

	return {
		...paginateConfig,
		[name]: checkIsDate ? moment(values).format('YYYY-MM-DD') : values,
		offset: 0,
		limit: 10,
		currentPage: 1,
	};
};

export const handleSearch = (searchKey, paginateConfig) => {
	console.log(searchKey, paginateConfig);

	return {
		...paginateConfig,
		query: searchKey,
		limit: 10,
		offset: 0,
		currentPage: 1,
	};
};
