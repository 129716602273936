import { createSlice } from '@reduxjs/toolkit';
import { message as toaster } from 'antd';
import moment from 'moment';
import { API } from 'api';

// const URL = 'https://api-cdms-staging.ripplr.in/api/';
const URL = process.env.REACT_APP_COLLECTION_API_URL;

export const getNextDayCollectionList =
	({
		status,
		dayRange = [],
		searchText = undefined,
		salesman,
		isDownload,
		filterBrands = [],
		offset = 0,
		limit,
		collection_date,
	}) =>
	async () => {
		const CREDENTIALS = {
			url: `${URL}invoice-handover/list`,
			method: 'post',
			queryParams: {
				limit,
				assigned_status: status,
				day_number: dayRange.join(',') || undefined,
				date_range_days: 3,
				salesman_id: salesman || undefined,
				invoice_no_query: searchText || undefined,
				orders_condition_sheet: isDownload ? 'send' : undefined,
				brand_id: filterBrands || undefined,
				collection_date,
				offset,
			},
		};

		if (isDownload) {
			CREDENTIALS.config = {
				responseType: 'blob',
			};
		}

		return API.common(CREDENTIALS).then((response) => response);
	};

export const getSalesManList =
	({ q, fc_id = null, brand_id = null }) =>
	async (_, getState) => {
		const { auth } = getState();
		const CREDENTIALS = {
			url: `/champ/salesman/list`,
			method: 'post',
			data: {
				sort: {
					sort_column: 'createdAt',
					sort_direction: 'DESC',
				},
				page: {
					offset: 0,
					limit: 100,
				},
				filter: {
					...(q ? { q } : {}),
					fc_id: fc_id || auth?.me?.FCs?.map((x) => x?.id),
					brand_id: brand_id || auth?.me?.Brands?.map((x) => x?.id),
					fields: ['name', 'mobile'],
					active_salesman_only: true,
				},
			},
		};

		return API.common(CREDENTIALS).then((response) => response?.data?.rows);
	};

export const assignSalesMan =
	({ data }) =>
	async () => {
		const CREDENTIALS = {
			url: `${URL}invoice-handover/update`,
			method: 'put',
			data,
		};

		return API.common(CREDENTIALS).then((response) => response?.data);
	};

export const getBrands = () => async () => {
	const CREDENTIALS = {
		url: `/champ/brand/list_v2`,
		method: 'post',
		data: {},
	};
	return API.common(CREDENTIALS).then((response) => response?.data);
};

const initialState = {
	banks: [],
	collectionTypes: [],
	adhocCollections: [],
	adhocCollectionDetails: [],
};

export const collectionSlice = createSlice({
	name: 'collection',
	initialState,
	reducers: {
		setBanks: (state, { payload }) => {
			state.banks = payload;
		},
		setCollectionTypes: (state, { payload }) => {
			state.collectionTypes = payload;
		},
		setAdhocCollections: (state, { payload }) => {
			state.adhocCollections = payload;
		},
		setAdhocCollectionDetails: (state, { payload }) => {
			state.adhocCollectionDetails = payload;
		},
	},
});

export const getBanks = () => async (dispatch) => {
	const CREDENTIALS = {
		url: `${URL}banks`,
	};

	return API.common(CREDENTIALS).then((response) => {
		dispatch(setBanks(response.data));
		return response;
	});
};

export const getCollectionTypes = () => async (dispatch) => {
	const CREDENTIALS = {
		url: `${URL}adhoc-collection/source/list`,
	};

	return API.common(CREDENTIALS).then((response) => {
		const data = response?.data?.map((item) => ({
			...item,
			value: item.id,
			label: item.name,
		}));
		dispatch(setCollectionTypes(data));
		return response;
	});
};

export const getAdditionSettlementList =
	({ date, offset = 0 }) =>
	async (dispatch) => {
		console.log('date', date);
		const CREDENTIALS = {
			url: `${URL}adhoc-collection/list`,
			queryParams: {
				filter_date: moment(date).format('YYYY-MM-DD'),
				offset,
				limit: 10,
			},
		};

		return API.common(CREDENTIALS).then((response) => {
			dispatch(setAdhocCollections(response));
			return response;
		});
	};
export const saveCollection = (data) => async (dispatch, getState) => {
	const CREDENTIALS = {
		url: `${URL}adhoc-collection/create`,
		method: 'post',
		data,
	};

	return API.common(CREDENTIALS).then((response) => {
		toaster.success('Additional Settlement added successfully');
		const { date } = getState().invoice;
		dispatch(getAdditionSettlementList(date));
		return response;
	});
};

export const getCollectionDetails = (id) => async (dispatch) => {
	const CREDENTIALS = {
		url: `${URL}adhoc-collection/details`,
		queryParams: {
			adhoc_collection_id: id,
		},
	};

	return API.common(CREDENTIALS).then((response) => {
		dispatch(setAdhocCollectionDetails(response.data));
		return response;
	});
};

export const getCollectionList =
	({
		offset = 0,
		limit = 10,
		sort_column = 'collection_date',
		sort_direction = 'DESC',
		currentPage,
		query,
		fields,
		...rest
	}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: 'champ/collectionInvoice/list',
			data: {
				sort: { sort_column, sort_direction },
				filter: { ...rest, q: query ? `*${query || ''}*` : undefined },
				page: { offset, limit },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getCollectionById =
	({ id }) =>
	async () => {
		const CREDENTIALS = {
			method: 'get',
			url: `champ/collectionInvoice/${id}`,
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getHandoverColumnsData =
	(day = 'today', salesman = '', filterBrands = '', offset = 0, limit = 50, invoice_no = '') =>
	async () => {
		const CREDENTIALS = {
			method: 'get',
			url: `${URL}invoice-handover/summary?collection_date_filter=${day}&brand_id=${filterBrands}&salesman_id=${salesman}&offset=${offset}&limit=${limit}&invoice_no=${invoice_no}`,
		};
		return API.common(CREDENTIALS).then((response) => response);
	};

export const unassignInvoiceFromSalesman = (data) => async () => {
	const CREDENTIALS = {
		method: 'put',
		url: `${URL}invoice-handover/unassign`,
		data: {
			invoice_array: data,
		},
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export const getAllInvoicesPdf = (salesman_id, collection_date) => async () => {
	const CREDENTIALS = {
		method: 'post',
		url: `${URL}invoice-handover/printassignments`,
		data: {
			salesman_id,
			collection_date,
		},
	};
	return API.common(CREDENTIALS).then((response) => response);
};

export const { setBanks, setCollectionTypes, setAdhocCollections, setAdhocCollectionDetails } =
	collectionSlice.actions;

export default collectionSlice.reducer;
