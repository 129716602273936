import { Table } from 'antd';
import styled from 'styled-components';
import { Flex } from 'atoms';

export const Container = styled.div`
	// max-height: 500px;
	// padding-right: 20px;
	box-shadow: 0px 2px 1px -1px rgb(145 158 171 / 20%), 0px 1px 1px 0px rgb(145 158 171 / 14%),
		0px 1px 3px 0px rgb(145 158 171 / 12%);
	overflow: auto;

	/* width */
	&::-webkit-scrollbar {
		width: 6px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #f8f8f8;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: ${(props) => props.theme.colors.primary};
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;

export const AntdTable = styled(Table)`
	table {
		text-align: left;
		thead {
			tr {
				th.ant-table-cell {
					font-size: 0.75rem;
					color: ${(props) => props.theme.colors.DavysGrey};
					text-align: left;
					font-weight: bold;
					word-break: keep-all;
					word-wrap: break-word;
				}
			}

			.ant-table-selection-column {
				.ant-checkbox-indeterminate {
					.ant-checkbox-inner {
						&::after {
							color: ${(props) => props.theme.colors.Ming};
						}
					}
				}
			}

			.ant-checkbox-indeterminate .ant-checkbox-inner::after {
				background-color: ${(props) => props.theme.colors.primary};
			}
		}

		.ant-checkbox-inner {
			border: 1px solid ${(props) => props.theme.colors.Ming};
			background-color: #fff;
		}

		.ant-checkbox-checked {
			.ant-checkbox-inner {
				&::after {
					border: 2px solid ${(props) => props.theme.colors.Ming};
					border-top: 0;
					border-left: 0;
				}
			}
		}

		.ant-checkbox-input:focus + .ant-checkbox-inner {
			border: 1px solid ${(props) => props.theme.colors.Ming};
		}

		.ant-table-tbody > tr.ant-table-row-selected > td {
			background: transparent;
		}

		.ant-checkbox-wrapper-disabled {
			display: none;
		}
	}
	.ant-pagination-item-active {
		border-color: ${(props) => props.theme.colors.Ming};

		a {
			color: ${(props) => props.theme.colors.primary};
		}
	}

	.ant-pagination-item:hover {
		border-color: ${(props) => props.theme.colors.Ming};
		a {
			color: ${(props) => props.theme.colors.primary};
		}
	}

	.ant-pagination-prev:hover,
	.ant-pagination-next:hover {
		.ant-pagination-item-link {
			border-color: ${(props) => props.theme.colors.Ming};
			color: ${(props) => props.theme.colors.primary};
		}
	}
`;

export const SearchWrapper = styled(Flex)`
	align-items: center;
	background: #ffffff;
	padding: 0 16px;
	margin: 0;
	justify-content: space-between;
`;

export const SearchField = styled(Flex)`
	align-items: center;
	> div {
		width: 200px;
	}
`;
