/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FieldArray } from 'formik';
import { message as toaster, Divider, Modal } from 'antd';
import PropTypes from 'prop-types';
import { Form, DatePicker } from 'formik-antd';
import { isArray } from 'lodash';
import { Box, Flex, FormInput, InputWrapper, CustomInput } from 'atoms';
import { saveCollection } from 'store/collectionSlice';
import { Debug } from '../Debug';
import {
	initialValues,
	ASFormSchema,
	getPayments,
	disableDateRanges,
} from 'components/forms/adhoc';
import {
	StyledSubmitButton,
	LabelTextInv,
	ValueTextInv,
	DatePickerWrapper,
	Select,
	DuplicateError,
} from 'components/forms/adhoc/AdditionalSettlement.style';

const { Option } = Select;

export default function AdditionalSettlementForm({ title, visible, onClose }) {
	const dispatch = useDispatch();
	const { banks = [], collectionTypes = [] } = useSelector((state) => state.collection);
	const formRef = useRef(null);

	useEffect(() => {
		if (formRef.current) {
			formRef.current.resetForm();
		}
	}, [visible]);

	function handleSubmit(values, { setSubmitting, resetForm }) {
		const payment = getPayments(values);

		if (payment.length > 0) {
			dispatch(
				saveCollection({
					adhoc_collection_source_id: values.adhoc_collection_source_id,
					payment,
				})
			)
				.then((res) => {
					console.log('res list all ', res);
					resetForm();
					onClose();
				})
				.catch((er) => {
					console.log('all clear failure', er);
				})
				.finally(() => {
					setSubmitting(false);
				});
		} else {
			toaster.error('Please select atleast one payment .');
			setSubmitting(false);
		}
	}

	return (
		<Modal
			title={title}
			visible={visible}
			onCancel={onClose}
			footer={false}
			width="68%"
			maskClosable={false}
		>
			<Formik
				initialValues={initialValues}
				validationSchema={ASFormSchema}
				enableReinitialize
				onSubmit={handleSubmit}
				innerRef={formRef}
			>
				{({ values, errors }) => (
					<Form layout="vertical">
						<Box marginBottom="20px">
							<FormInput name="adhoc_collection_source_id" label="*Select Source">
								<Select
									getPopupContainer={(trigger) => trigger.parentNode}
									name="adhoc_collection_source_id"
									style={{ width: '220px', height: '35px' }}
									showSearch
									options={collectionTypes}
									filterOption={(input, option) =>
										option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									placeholder="Select Source"
								/>
							</FormInput>
						</Box>
						<Box marginBottom="15px">
							<Flex>
								<ValueTextInv alignSelf="center" flex="0 0 8%">
									Cash
								</ValueTextInv>
								<Box padding="0 5px">
									<FormInput name="amount" label="Amount">
										<InputWrapper name="amount" />
									</FormInput>
								</Box>
							</Flex>
							<Divider />
							<Flex>
								<Box width="100%">
									<FieldArray
										name="cheque"
										render={(arrayHelpers) =>
											values?.cheque?.map((_, index) => (
												<Flex key={index}>
													<ValueTextInv>Cheque</ValueTextInv>
													<Box padding="0 5px">
														<FormInput
															name={`cheque.${index}.amount`}
															label="Amount"
														>
															<InputWrapper
																name={`cheque.${index}.amount`}
															/>
														</FormInput>
													</Box>
													<Flex disabled={!values.cheque[index].amount}>
														<Box padding="0 5px">
															<FormInput
																name={`cheque.${index}.reference_number`}
																label="Cheque Number"
															>
																<InputWrapper
																	name={`cheque.${index}.reference_number`}
																	placeholder="last 6 digits"
																	pattern="[0-9]*"
																	inputMode="numeric"
																	maxLength={6}
																/>
															</FormInput>
														</Box>
														<Box padding="0 5px">
															<FormInput
																name={`cheque.${index}.bank_id`}
																label="Select Bank"
															>
																<Select
																	getPopupContainer={(trigger) =>
																		trigger.parentNode
																	}
																	name={`cheque.${index}.bank_id`}
																	showSearch
																	filterOption={(input, option) =>
																		option.children
																			.toLowerCase()
																			.indexOf(
																				input.toLowerCase()
																			) >= 0
																	}
																	placeholder="Select Bank"
																	style={{ width: '250px' }}
																>
																	{banks?.map((bank) => (
																		<Option
																			key={bank.id}
																			value={bank?.id}
																		>
																			{bank?.name}
																		</Option>
																	))}
																</Select>
															</FormInput>
														</Box>
														<Box padding="0 5px">
															<DatePickerWrapper
																name={`cheque.${index}.due_date`}
																label="Due Date"
															>
																<DatePicker
																	getPopupContainer={(trigger) =>
																		trigger.parentNode
																	}
																	inputReadOnly
																	type="date"
																	name={`cheque.${index}.due_date`}
																	format="DD-MM-YYYY"
																	style={{
																		width: '200px',
																		height: '40px',
																		background: '#faf9f8',
																		border: '1px solid #edebe9',
																	}}
																	placeholder=""
																	disabledDate={disableDateRanges()}
																/>
															</DatePickerWrapper>
														</Box>
													</Flex>
													{index === 0 ? (
														<LabelTextInv
															onClick={() =>
																arrayHelpers.push({
																	amount: undefined,
																	bank_id: undefined,
																	due_date: undefined,
																	reference_number: undefined,
																})
															}
														>
															Add more cheque
														</LabelTextInv>
													) : (
														<LabelTextInv
															onClick={() =>
																arrayHelpers.remove(index)
															}
														>
															Remove Cheque
														</LabelTextInv>
													)}
												</Flex>
											))
										}
									/>
								</Box>
							</Flex>
							{!isArray(errors?.cheque) && <DuplicateError error={errors?.cheque} />}
							<Divider />
							<Flex>
								<Box width="100%">
									<FieldArray
										name="UPI"
										render={(arrayHelpers) =>
											values?.UPI?.map((_, index) => (
												<Flex key={index}>
													<ValueTextInv>UPI</ValueTextInv>
													<Box padding="0 5px">
														<FormInput
															name={`UPI.${index}.amount`}
															label="Amount"
														>
															<InputWrapper
																name={`UPI.${index}.amount`}
															/>
														</FormInput>
													</Box>
													<Box
														padding="0 5px"
														disabled={!values.UPI[index].amount}
													>
														<FormInput
															name={`UPI.${index}.reference_number`}
															label="UPI Reference Number"
														>
															<InputWrapper
																name={`UPI.${index}.reference_number`}
																placeholder="12 to 16 digits"
																pattern="[0-9]*"
																inputMode="numeric"
																maxLength={16}
															/>
														</FormInput>
													</Box>
													{index === 0 ? (
														<LabelTextInv
															onClick={() =>
																arrayHelpers.push({
																	amount: undefined,
																	reference_number: undefined,
																})
															}
														>
															Add more UPI
														</LabelTextInv>
													) : (
														<LabelTextInv
															onClick={() =>
																arrayHelpers.remove(index)
															}
														>
															Remove UPI
														</LabelTextInv>
													)}
												</Flex>
											))
										}
									/>
								</Box>
							</Flex>
							{!isArray(errors?.UPI) && <DuplicateError error={errors?.UPI} />}
							<Divider />
							<Flex>
								<Box width="100%">
									<FieldArray
										name="NEFT"
										render={(arrayHelpers) =>
											values?.NEFT?.map((_, index) => (
												<Flex key={index}>
													<ValueTextInv>NEFT</ValueTextInv>
													<Box padding="0 5px">
														<FormInput
															name={`NEFT.${index}.amount`}
															label="Amount"
														>
															<InputWrapper
																name={`NEFT.${index}.amount`}
															/>
														</FormInput>
													</Box>
													<Box
														padding="0 5px"
														disabled={!values.NEFT[index].amount}
													>
														<FormInput
															name={`NEFT.${index}.reference_number`}
															label="NEFT Reference Number"
														>
															<CustomInput
																name={`NEFT.${index}.reference_number`}
																placeholder="16 to 22 digits"
																maxLength={22}
															/>
														</FormInput>
													</Box>
													{index === 0 ? (
														<LabelTextInv
															onClick={() =>
																arrayHelpers.push({
																	amount: undefined,
																	reference_number: undefined,
																})
															}
														>
															Add more NEFT
														</LabelTextInv>
													) : (
														<LabelTextInv
															onClick={() =>
																arrayHelpers.remove(index)
															}
														>
															Remove NEFT
														</LabelTextInv>
													)}
												</Flex>
											))
										}
									/>
								</Box>
							</Flex>
							{!isArray(errors?.NEFT) && <DuplicateError error={errors?.NEFT} />}
							<Divider />
						</Box>

						<Box textAlign="right">
							<StyledSubmitButton>Add</StyledSubmitButton>
						</Box>
						<Debug />
					</Form>
				)}
			</Formik>
		</Modal>
	);
}

AdditionalSettlementForm.propTypes = {
	title: PropTypes.string,
	visible: PropTypes.bool,
	onClose: PropTypes.func,
};
