import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { RemarksIcon, VerifiedStatus } from 'components/pages/segregator';
import { Flex, Text } from 'atoms';
import { getunique, unScReplace } from 'utils/text';
import { Rupee } from 'components';

function getClassName(showColumns, type) {
	return showColumns.includes(type) ? 'show-table-cell' : 'hide';
}

function getColumns(sourceData, showColumns = [], isDeliverer = false) {
	return [
		{
			title: 'Vehicle Number',
			dataIndex: 'vehicle_no',
			key: 'vehicle_no',
			hidden: isDeliverer,
			render: (vehicle_no) => <span>{vehicle_no}</span>,
		},
		{
			title: 'Driver Name',
			dataIndex: 'driver_name',
			key: 'driver_name',
			hidden: isDeliverer,
			render: (driver_name) => <span>{unScReplace(driver_name)}</span>,
		},
		{
			title: 'Brand',
			dataIndex: 'brand_name',
			key: 'brand_name',
			hidden: false,
			filters: getunique(sourceData, 'brand_name'),
			onFilter: (value, record) => record.brand_name.startsWith(value),
			filterSearch: true,
			render: (brand_name) => <span>{unScReplace(brand_name)}</span>,
		},
		{
			title: 'Name',
			dataIndex: 'salesman_name',
			key: 'salesman_name',
			hidden: false,
			render: (_, record) => (
				<span>{unScReplace(record?.salesman_name || record?.delivery_boy_name)}</span>
			),
		},
		{
			title: 'Phone',
			dataIndex: 'salesman_mobile',
			key: 'salesman_mobile',
			hidden: false,
			render: (_, record) => (
				<span>{record?.salesman_mobile || record?.delivery_boy_mobile}</span>
			),
		},
		{
			title: 'Total Invoice',
			dataIndex: 'no_of_invoices',
			hidden: false,
			key: 'no_of_invoices',
		},
		{
			title: 'Collected Amount',
			dataIndex: 'collected_amount',
			hidden: false,
			key: 'collected_amount',
		},

		{
			title: <Text textAlign="center">Invoice Settled</Text>,
			dataIndex: 'no_bill_back',
			hidden: false,
			key: 'no_bill_back',
			render: (no_bill_back) => <Text textAlign="center">{no_bill_back}</Text>,
		},
		{
			title: <Text textAlign="center">Invoice Returned</Text>,
			dataIndex: 'bill_back',
			hidden: false,
			key: 'bill_back',
			render: (bill_back) => <Text textAlign="center">{bill_back}</Text>,
		},
		{
			title: <Text textAlign="center">Invoice Missing</Text>,
			dataIndex: 'no_of_missing_invoice',
			hidden: false,
			key: 'no_of_missing_invoice',
			render: (no_of_missing_invoice) => (
				<Text textAlign="center">{no_of_missing_invoice}</Text>
			),
		},
		{
			title: 'Cash',
			dataIndex: 'cash_amount',
			key: 'cash_amount',
			hidden: false,
			className: getClassName(showColumns, 'Cash'),
			render: (cash_amount) => <Rupee>{cash_amount || 0}</Rupee>,
		},
		{
			title: 'Cheque',
			dataIndex: 'cheque_amount',
			key: 'cheque_amount',
			hidden: false,
			className: getClassName(showColumns, 'Cheque'),
			render: (cheque_amount) => <Rupee>{cheque_amount || 0}</Rupee>,
		},
		{
			title: 'UPI',
			dataIndex: 'upi_amount',
			key: 'upi_amount',
			hidden: false,
			className: getClassName(showColumns, 'UPI'),
			render: (upi_amount) => <Rupee>{upi_amount || 0}</Rupee>,
		},
		{
			title: 'NEFT',
			dataIndex: 'online_amount',
			key: 'online_amount',
			hidden: false,
			className: getClassName(showColumns, 'NEFT'),
			render: (online_amount) => <Rupee>{online_amount || 0}</Rupee>,
		},
		{
			title: 'Total Collection',
			dataIndex: 'total_amount',
			key: 'total_amount',
			hidden: false,
			className: getClassName(showColumns, 'Total'),
			render: (total_amount) => <Rupee>{total_amount || 0}</Rupee>,
		},
		{
			title: 'Voucher',
			dataIndex: 'voucher',
			hidden: false,
			key: 'voucher',
		},
		{
			title: 'Verification',
			dataIndex: 'verified_by_segregator_status',
			key: 'verified_by_segregator_status',
			hidden: false,
			filters: getunique(sourceData, 'verified_by_segregator_status'),
			onFilter: (value, record) => record.verified_by_segregator_status.startsWith(value),
			filterSearch: true,
			render: (record, data) => <VerifiedStatus data={data} record={record} />,
		},
		{
			title: (
				<Flex justifyContent="center" alignItems="center">
					Remarks
				</Flex>
			),
			dataIndex: 'salesman_name',
			hidden: false,
			key: 'salesman_name',
			render: (_, record) => (
				<Flex justifyContent="center" alignItems="center">
					<RemarksIcon record={record} type="BULK" />
				</Flex>
			),
		},
	].filter((x) => !x.hidden);
}

export default function SalesMenList({ persons, showColumns = [], tab }) {
	return (
		<Table
			rowKey={(record) =>
				`${record?.collected_person_id}  ${record?.unique_key}  ${record?.cid}`
			}
			dataSource={persons}
			columns={getColumns(persons, showColumns, tab !== '2')}
		/>
	);
}

SalesMenList.propTypes = {
	persons: PropTypes.array,
	showColumns: PropTypes.array,
	tab: PropTypes.string,
};
