/* eslint-disable react/no-array-index-key */
import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Modal, Divider } from 'antd';
import PropTypes from 'prop-types';
import { Button, Flex } from 'atoms';

export default function WMSMessage({ visible, handleHide, data = [] }) {
	return (
		<Modal
			title={null}
			visible={visible}
			closable={false}
			onOk={handleHide}
			footer={false}
			maskClosable={false}
			cancelButtonProps={{ style: { display: 'none' } }}
		>
			{data?.map((item, i) => (
				<p key={i}>
					{item?.success ? (
						<CheckCircleOutlined style={{ color: 'green' }} />
					) : (
						<CloseCircleOutlined style={{ color: 'red' }} />
					)}{' '}
					{item?.message}
				</p>
			))}
			<Divider />
			<Flex justifyContent="center" mt="1rem">
				<Button width="42px" onClick={handleHide}>
					Ok
				</Button>
			</Flex>
		</Modal>
	);
}

WMSMessage.propTypes = {
	visible: PropTypes.bool,
	handleHide: PropTypes.func,
	data: PropTypes.array,
};
