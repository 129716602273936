import { Link } from 'react-router-dom';
import viewIcon from 'assets/icons/eye.svg';
import { Text, Image } from 'atoms';
import Rupee from 'components/Rupee';
import DateFormat from 'components/DateFormat';

export const salesReturnListColumns = ({ defaultSort, defaultsortField }) => {
	const sortDirection = defaultSort === 'ASC' ? 'ascend' : 'descend';
	return [
		{
			title: 'Sales Return Date',
			dataIndex: 'return_date',
			key: 'return_date',
			sortId: 1,
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => a.return_date - b.return_date,
			sortOrder: defaultsortField === 'return_date' ? sortDirection : false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={record?.return_date} />
				</Text>
			),
		},
		{
			title: 'Return No',
			dataIndex: 'return_no',
			key: 'return_no',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => a.return_no - b.return_no,
			sortOrder: defaultsortField === 'return_no' ? sortDirection : false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{record?.return_no}
				</Text>
			),
		},
		{
			title: 'Store',
			dataIndex: 'name',
			key: 'name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.Brand?.name}
				</Text>
			),
		},
		{
			title: 'FC',
			dataIndex: 'name',
			key: 'name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.FC?.name}
				</Text>
			),
		},
		{
			title: 'Invoice No',
			dataIndex: 'invoice_no',
			key: 'invoice_no',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			sorter: (a, b) => a.invoice_no - b.invoice_no,
			sortOrder: defaultsortField === 'invoice_no' ? sortDirection : false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000" textTransform="uppercase">
					{record?.Order?.invoice_no}
				</Text>
			),
		},
		{
			title: 'Return Value',
			dataIndex: 'net_amount',
			key: 'net_amount',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<Rupee roundOff={0}>{record?.total_net_amount}</Rupee>
				</Text>
			),
		},
		{
			title: 'Updated By',
			dataIndex: 'full_name',
			key: 'full_name',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					{record?.File?.FileUploadedBy?.full_name}
				</Text>
			),
		},
		{
			title: 'Updated At',
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			sortId: 2,
			overflow: 'hidden',
			hidden: false,
			render: (_, record) => (
				<Text overFlow="hidden" fontSize="12px" color="#000">
					<DateFormat date={record?.updatedAt} />
				</Text>
			),
		},
		{
			title: '',
			dataIndex: 'actions',
			key: 'actions',
			sortId: 6,
			hidden: false,
			render: (_, record) => (
				<Link to={`/order-management/sales-return/${record?.id}/show`}>
					<Image src={viewIcon} alt="edit-icon" height={15} width={20} />
				</Link>
			),
		},
	];
};
