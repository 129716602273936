/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { message, message as toaster } from 'antd';
import { filter, isEmpty, uniqBy } from 'lodash';
import { API } from 'api';

const champPincodeList = '/champ/pincode/list';
const FCLIST_URL = '/champ/fc/list';
const OMS_fileList = process.env.REACT_APP_OMS;

const initialState = {
	fcList: {},
	brandList: {},
	brandData: {},
	bankList: [],
	userDetails: {},
	usersList: [],
};
export const masterSlice = createSlice({
	name: 'masters',
	initialState,
	reducers: {
		setFcList: (state, { payload = {} }) => {
			state.fcList = payload;
		},
		setBrandList: (state, { payload = {} }) => {
			state.brandList = payload;
		},
		setBrandData: (state, { payload = {} }) => {
			state.brandData = payload;
		},
		setBankList: (state, { payload }) => {
			state.bankList = payload;
		},
		setUsersList: (state, { payload }) => {
			state.usersList = payload;
		},
		setUserDetails: (state, { payload }) => {
			state.userDetails = payload;
		},
	},
});

export const getFcList =
	(values = {}) =>
	async (dispatch) => {
		const CREDENTIALS = {
			url: FCLIST_URL,
			method: 'post',
			data: { ...values },
		};

		return API.common(CREDENTIALS).then((response) => {
			dispatch(setFcList(response));
			return response.data;
		});
	};

export const getOMSFcList =
	(values = {}) =>
	async (dispatch) => {
		const CREDENTIALS = {
			url: `${OMS_fileList}+ 'file/list/'`,
			method: 'post',
			data: { ...values },
		};

		return API.common(CREDENTIALS).then((response) => {
			dispatch(setFcList(response));
			return response.data;
		});
	};

export const deleteGRNSignedInvoice =
	(values = {}) =>
	async (dispatch) => {
		const CREDENTIALS = {
			url: `${OMS_fileList}api/delete-grn-signed-invoice`,
			method: 'post',
			data: { ...values },
		};

		return API.common(CREDENTIALS).then((response) => {
			dispatch(setFcList(response));
			return response.data;
		});
	};
export const deleteSalesReturnSignedInvoice =
	(values = {}) =>
	async (dispatch) => {
		const CREDENTIALS = {
			url: `${OMS_fileList}api/delete-creditnote-invoice`,
			method: 'post',
			data: { ...values },
		};

		return API.common(CREDENTIALS).then((response) => {
			dispatch(setFcList(response));
			return response.data;
		});
	};

export const verifyGRNSignedInvoice = (id) => async (dispatch) => {
	const CREDENTIALS = {
		url: `${OMS_fileList}api/verify-grn-signed-invoice`,
		method: 'post',
		data: { file_id: id },
	};

	return API.common(CREDENTIALS).then((response) => {
		dispatch(setFcList(response));
		return response.data;
	});
};

export const toggleWmsCallback =
	(values = {}) =>
	async (dispatch) => {
		const CREDENTIALS = {
			url: `${OMS_fileList}api/wms_callback_toggle`,
			method: 'post',
			data: { ...values },
		};

		return API.common(CREDENTIALS).then((response) => {
			dispatch(setFcList(response));
			return response;
		});
	};

// export const toggleWmsCallbackVerified =
// 	(values = {}) =>
// 	async (dispatch) => {
// 		const CREDENTIALS = {
// 			url: `${OMS_fileList}api/verified_wms_callback_toggle`,
// 			method: 'post',
// 			data: { ...values },
// 		};

// 		return API.common(CREDENTIALS).then((response) => {
// 			dispatch(setFcList(response));
// 			return response;
// 		});
// 	};

export const getFcOnSearchList =
	({ q = '**', client_ids = null }) =>
	async () => {
		const CREDENTIALS = {
			url: FCLIST_URL,
			method: 'post',
			data: {
				filter: {
					user_fcs_only: true,
					user_brands_only: true,
					q: q || '**',
					fields: ['name', 'code'],
					client_ids: client_ids || [],
				},
				page: {
					limit: 25,
					offset: 0,
				},
				sort: {
					sort_column: 'id',
					sort_direction: 'DESC',
				},
			},
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getFcListOnLoad =
	({ fcIds = [], client_ids = [] }) =>
	async () => {
		const CREDENTIALS = {
			url: FCLIST_URL,
			method: 'post',
			data: {
				filter: {
					user_fcs_only: true,
					user_brands_only: true,
					q: '**',
					fields: ['name', 'code'],
					client_ids: client_ids?.length ? client_ids : undefined,
				},
				page: {
					limit: 25,
					offset: 0,
				},
				sort: {
					sort_column: 'id',
					sort_direction: 'DESC',
				},
			},
		};

		const res = await Promise.all([
			!isEmpty(fcIds)
				? API.common({
						...CREDENTIALS,
						data: {
							...CREDENTIALS.data,
							filter: {
								...CREDENTIALS.data.filter,
								fc_ids: fcIds,
								fcs_list_flag: true,
							},
						},
				  })
				: Promise.resolve({}),
			API.common(CREDENTIALS),
		]);

		const [fcData, fcList] = res;

		const removeEmpty = [...(fcList?.data?.rows || []), ...(fcData?.data?.rows || [])].filter(
			(x) => !isEmpty(x)
		);

		return {
			...fcList?.data,
			rows: uniqBy(removeEmpty, (x) => x.id),
		};
	};

export const getFcListForTable =
	({
		offset,
		limit,
		sort_column = 'createdAt',
		sort_direction = 'DESC',
		currentPage,
		fcIds,
		brand_ids,
	}) =>
	async () => {
		const CREDENTIALS = {
			url: FCLIST_URL,
			method: 'post',
			data: {
				sort: { sort_column, sort_direction },
				page: { limit, offset },
				filter: {
					user_fcs_only: true,
					user_brands_only: true,
					brand_ids: brand_ids?.length ? brand_ids : undefined,
					fcs_list_flag: true,
					fc_ids: fcIds ? [fcIds] : undefined,
				},
			},
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const CreateUpdateFC =
	(values, setErrors = {}) =>
	async () => {
		let url = '/champ/fc/create';
		let method = 'post';
		let toastMsg = 'FC added successfully';

		if ('id' in values) {
			url = '/champ/fc/update';
			method = 'put';
			toastMsg = 'FC data updated successfully';
		}

		const CREDENTIALS = {
			url,
			method,
			data: values,
			setErrors,
		};

		return API.common(CREDENTIALS).then((response) => {
			toaster.success(toastMsg);
			return response;
		});
	};

// brands page slice

// get category table list
const BRANDLIST_URL = '/champ/brand/list';
export const getBrandList = (values) => async (dispatch) => {
	const method = 'post';
	const url = BRANDLIST_URL;
	let data = {};
	if (values) {
		data = values;
	} else {
		data = {
			filter: { settlement_type: 'HMBA', user_fcs_only: true, user_brands_only: true },
			sort: { sort_column: 'id', sort_direction: 'ASC' },
			page: { ...values },
		};
	}
	const CREDENTIALS = {
		url,
		method,
		data,
	};

	return API.common(CREDENTIALS).then((response) => {
		dispatch(setBrandList(response));
		return response.data;
	});
};

export const getBrandOnSearchFilter =
	({ q = '**' }) =>
	async () => {
		const CREDENTIALS = {
			url: BRANDLIST_URL,
			method: 'post',
			data: {
				filter: {
					user_fcs_only: true,
					q: q || '**',
				},
				page: {
					limit: 100,
					offset: 0,
				},
				sort: {
					sort_column: 'id',
					sort_direction: 'DESC',
				},
			},
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getDownloadAdapterUploadsFile = (id) => async () => {
	const CREDENTIALS = {
		url: `${OMS_fileList}file/download-error-file/`,
		method: 'post',
		data: {
			file_id: id,
		},
	};

	return API.common(CREDENTIALS).then((response) => response);
};

export const getBrandOnSearchList =
	({ q = '**' }) =>
	async () => {
		const CREDENTIALS = {
			url: BRANDLIST_URL,
			method: 'post',
			data: {
				filter: {
					user_fcs_only: true,
					user_brands_only: true,
					q: q || '**',
					fields: ['name', 'code'],
				},
				page: {
					limit: 25,
					offset: 0,
				},
				sort: {
					sort_column: 'id',
					sort_direction: 'DESC',
				},
			},
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getPickersOnSearchList =
	({ q = '**' }) =>
	async () => {
		const CREDENTIALS = {
			url: '/champ/user/list',
			method: 'post',
			data: {
				filter: {
					groups: [30], // changed from 3 ---> 30
					user_fcs_only: true,
					user_brands_only: true,
					first_name__icontains: q || '**',
				},
				sort: { sort_column: 'id', sort_direction: 'DESC' },
				page: {
					limit: 25,
					offset: 0,
				},
			},
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getBrandListForTable =
	({
		offset,
		limit,
		sort_column = 'createdAt',
		sort_direction = 'DESC',
		query,
		fields,
		includeFC = true,
	}) =>
	async () => {
		const method = 'post';
		const url = BRANDLIST_URL;
		const CREDENTIALS = {
			url,
			method,
			data: {
				sort: { sort_column, sort_direction },
				page: { offset, limit },
				filter: {
					includeFC,
					q: `*${query || ''}*`,
					...(!isEmpty(fields) ? { fields: [fields] } : {}),
				},
			},
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getBrandsSearchList =
	({ q = '', limit = 10 }) =>
	async () => {
		const CREDENTIALS = {
			url: BRANDLIST_URL,
			method: 'post',
			data: {
				filter: { includeFC: true, q: `*${q || ''}*` },
				sort: { sort_column: 'id', sort_direction: 'DESC' },
				page: {
					limit,
					offset: 0,
				},
			},
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

// add Brand
export const CreateUpdateBrands = (values, setErrors) => async () => {
	let url = '/champ/brand/create';
	let method = 'post';
	let toastMessage = 'Brand added successfully';

	if ('id' in values) {
		url = '/champ/brand/update';
		method = 'put';
		toastMessage = 'Brand updated successfully';
	}

	const CREDENTIALS = {
		url,
		method,
		data: values,
		setErrors,
	};

	return API.common(CREDENTIALS).then((response) => {
		toaster.success(toastMessage);
		return response;
	});
};

export const GetBrandId =
	({ brandId }) =>
	async () => {
		const CREDENTIALS = {
			url: `/champ/brand/${brandId}`,
			method: 'get',
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const GetFcById =
	({ id }) =>
	async () => {
		const CREDENTIALS = {
			url: `/champ/fc/${id}`,
			method: 'get',
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

// ends here

// Bank starts here
const BANKLIST_URL = '/champ/bank/list';
export const getBankList =
	({ offset, limit, sort_column = 'name', sort_direction = 'ASC', query, fields }) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: BANKLIST_URL,
			data: {
				sort: { sort_column, sort_direction },
				page: { offset, limit },
				filter: {
					q: `*${query || ''}*`,
					user_fcs_only: true,
					...(!isEmpty(fields) ? { fields: [fields] } : {}),
				},
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const addBank =
	(values, setErrors = {}) =>
	async () => {
		const CREDENTIALS = {
			url: '/champ/bank/create',
			method: 'post',
			data: values,
			setErrors,
		};

		return API.common(CREDENTIALS).then((response) => {
			toaster.success(`${response?.data?.bank?.name} bank added successfully`);
			return response;
		});
	};

export const getBankOnSearchFilter =
	({ q = '**', limit = 25 }) =>
	async () => {
		const CREDENTIALS = {
			url: BANKLIST_URL,
			method: 'post',
			data: {
				filter: {
					user_fcs_only: true,
					q: q || '**',
				},
				page: {
					limit,
					offset: 0,
				},
				sort: {
					sort_column: 'id',
					sort_direction: 'DESC',
				},
			},
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

// Banks ends here
const BaseUrl = process.env?.REACT_APP_OMS;

export const getPackMasterList =
	({
		offset = 0,
		limit = 10,
		sort_column = 'id',
		sort_direction = 'DESC',
		brands = undefined,
		product_code = undefined,
		statuses = undefined,
		currentPage,
		...rest
	}) =>
	async () => {
		const filterParams = {
			user_brands_only: true,
			brands: brands?.length && brands,
			product_code: product_code ? product_code : undefined,
			is_active: statuses === 'false' ? false : true,
			...rest,
		};

		if (brands?.[0] === null || brands?.[0] === undefined) {
			delete filterParams.brands;
		}

		if (statuses === undefined) {
			delete filterParams.is_active;
		}

		if (product_code === undefined) {
			delete filterParams.product_code;
		}

		const CREDENTIALS = {
			method: 'post',
			url: `${BaseUrl}masters/pack-master/list/`,
			data: {
				filter: filterParams,
				sort: {
					sort_column,
					sort_direction,
				},
				page: {
					offset,
					limit,
				},
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};
export const CreateUpdatePackMaster =
	({ data, setErrors = {} }) =>
	async () => {
		const method = 'post';
		let url = `${BaseUrl}masters/pack-master/create/`;

		if (data.id === undefined) {
			delete data.id;
		}

		const payload = {
			brand_code: data.brand_code || '',
			product_name: data.product_name || '',
			product_code: data.product_code || '',
			uom: data.uom || '',
			uom_description: data.uom_description || '',
			conversion_qty: data.conversion_qty || '',
			is_active: data.is_active || false,
		};

		if ('id' in data) {
			url = `${BaseUrl}masters/pack-master/update/`;
			payload.id = data.id;
		}

		const CREDENTIALS = {
			method,
			url,
			data: payload,
			setErrors,
		};

		return API.common(CREDENTIALS).then((res) => res);
	};

export const getPackMasterId = (pack_master_id) => async () => {
	const CREDENTIALS = {
		method: 'get',
		url: `${BaseUrl}masters/pack-master/fetch/${pack_master_id}/`,
	};
	return API.common(CREDENTIALS).then((res) => res);
};

// Users starts here

export const getUsersList =
	({
		offset,
		limit,
		sort = { sort_column: 'created_at', sort_direction: 'DESC' },
		userId,
		groups,
		fcs,
		user_mail,
		name,
		mobile_no,
	}) =>
	async () => {
		const filters = {
			...(groups || fcs ? { groups: groups?.map((x) => x), fcs } : {}),
			...(userId ? { ids: [+userId] } : {}),
			...(user_mail ? { user_mail } : {}),
			...(name ? { name } : {}),
			...(mobile_no ? { mobile_no } : {}),
		};
		const CREDENTIALS = {
			url: '/champ/user/list',
			method: 'post',
			data: {
				sort,
				page: { offset, limit },
				filter: filters,
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const addUser = (values) => async () => {
	const CREDENTIALS = {
		url: '/champ/user/create',
		method: 'post',
		data: values,
	};

	return API.common(CREDENTIALS).then((response) => {
		toaster.success(`${response?.data?.bank?.name} bank added successfully`);
		return response;
	});
};

export const getUserById =
	({ userId }) =>
	async (dispatch) => {
		const CREDENTIALS = {
			url: `/champ/user/${userId}`,
			method: 'get',
		};

		return API.common(CREDENTIALS).then((response) => {
			dispatch(setUserDetails(response.data[0]));
			return response.data;
		});
	};

// Users ends here

// Salesman starts here

export const getSalesmanList =
	({
		offset,
		limit,
		sort = { sort_column: 'createdAt', sort_direction: 'DESC' },
		currentPage,
		salesmen,
		brands,
		mobile_q,
		fcs,
		...rest
	}) =>
	async () => {
		const CREDENTIALS = {
			url: '/champ/salesman/list',
			method: 'post',
			data: {
				sort,
				page: { offset, limit },
				filter: {
					...rest,
					mobile_q: mobile_q ? `*${mobile_q || ''}*` : undefined,
					brand_id: brands?.length ? brands : undefined,
					salesman_id: salesmen?.length ? salesmen : undefined,
					fc_id: fcs?.length ? fcs : undefined,
				},
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getSalesmanById =
	({ salesmanId }) =>
	async () => {
		const CREDENTIALS = {
			url: `/champ/salesman/${salesmanId}`,
		};

		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const CreateUpdateSalesMan =
	({ data, setErrors = {}, salesmanId }) =>
	async () => {
		let url = '/champ/salesman/create';
		let method = 'post';
		let toasterMessage = 'Salesman data created successfully';

		if (salesmanId) {
			url = `/champ/salesman/update/${salesmanId}`;
			method = 'put';
			toasterMessage = 'Salesman data updated successfully';
		}

		const CREDENTIALS = {
			method,
			url,
			data,
			setErrors,
		};

		return API.common(CREDENTIALS).then((res) => {
			message.info(toasterMessage);
			return res;
		});
	};

// Salesman ends here

export const getPincodeList =
	({
		offset,
		limit,
		sort_column = 'id',
		sort_direction = 'ASC',
		query,
		currentPage,
		fields,
		...rest
	}) =>
	async () => {
		const CREDENTIALS = {
			method: 'post',
			url: champPincodeList,
			data: {
				...(fields || query
					? { filter: { ...(query ? { q: `*${query}*` } : {}), fields } }
					: { ...rest }),
				sort: { sort_column, sort_direction },
				page: { offset, limit },
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getPincodebyId = ({ pinCodeId }) => {
	const CREDENTIALS = {
		url: `/champ/pincode/${pinCodeId}`,
		method: 'get',
	};

	return API.common(CREDENTIALS).then((response) => response.data);
};

export const getPincodeOnLoad =
	({ pinCodeId }) =>
	async () => {
		const CREDENTIALS = {
			url: champPincodeList,
			method: 'post',
			data: {
				filter: { fields: ['pincode'] },
				page: {
					limit: 25,
					offset: 0,
				},
				sort: {
					sort_column: 'id',
					sort_direction: 'DESC',
				},
			},
		};

		const [pincode, pincodeOptions] = await Promise.all([
			pinCodeId ? getPincodebyId({ pinCodeId }) : Promise.resolve({}),
			API.common(CREDENTIALS),
		]);

		const removeEmpty = [...(pincodeOptions?.data?.rows || []), pincode].filter(
			(x) => !isEmpty(x)
		);

		return {
			...pincodeOptions?.data,
			rows: uniqBy(removeEmpty, (x) => x.id),
		};
	};

export const getPincodeListOnSearch =
	({ q = '**' }) =>
	async () => {
		const CREDENTIALS = {
			url: champPincodeList,
			method: 'post',
			data: {
				...(q ? { filter: { q, fields: ['pincode'] } } : {}),
				page: {
					limit: 25,
					offset: 0,
				},
				sort: {
					sort_column: 'id',
					sort_direction: 'DESC',
				},
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const getBankNamebyId = ({ bankId }) => {
	const CREDENTIALS = {
		url: `/champ/bank/${bankId}`,
		method: 'get',
	};

	return API.common(CREDENTIALS).then((response) => response.data);
};

export const getBankNameOnLoad =
	({ bankId, limit = 25 }) =>
	async () => {
		const CREDENTIALS = {
			url: BANKLIST_URL,
			method: 'post',
			data: {
				page: {
					limit,
					offset: 0,
				},
				sort: {
					sort_column: 'id',
					sort_direction: 'DESC',
				},
			},
		};

		const [bankname, bankoptions] = await Promise.all([
			bankId ? getBankNamebyId({ bankId }) : Promise.resolve({}),
			API.common(CREDENTIALS),
		]);

		const removeEmpty = [...(bankoptions?.data?.rows || []), bankname].filter(
			(x) => !isEmpty(x)
		);

		return {
			...bankoptions?.data,
			rows: uniqBy(removeEmpty, (x) => x.id),
		};
	};

export const getBankListOnSearch =
	({ q = '**', limit = 25 }) =>
	async () => {
		const CREDENTIALS = {
			url: BANKLIST_URL,
			method: 'post',
			data: {
				...(q ? { filter: { q } } : {}),
				page: {
					limit,
					offset: 0,
				},
				sort: {
					sort_column: 'id',
					sort_direction: 'DESC',
				},
			},
		};
		return API.common(CREDENTIALS).then((response) => response.data);
	};

export const { setFcList, setBrandData, setBrandList, setBankList, setUsersList, setUserDetails } =
	masterSlice.actions;

export default masterSlice.reducer;
