import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';
import styled from 'styled-components';
import { getAllInvoicesPdf } from 'store/collectionSlice';
import { downloadPdf } from './AllocationHelper';

const StyledButton = styled(Button)`
	width: 140px;
	height: 40px;
	border-radius: 4px;
	color: #fff;
	background-color: #007fff;
	font-size: 14px;
	:hover {
		background-color: #007fff;
		border: 1px solid #007fff;
	}
	:focus {
		background-color: #007fff;
		border: 1px solid #007fff;
	}
`;

export default function DownloadButton({ record }) {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	const printApiDispatch = (salesman_id, collection_date) =>
		dispatch(getAllInvoicesPdf(salesman_id, collection_date)).then((response) => response);

	const callPrintApi = (salesman_id, collection_date) => {
		setLoading(true);

		const poll = () => {
			printApiDispatch(salesman_id, collection_date).then((response) => {
				if (response?.status === 'completed') {
					setLoading(false);
					downloadPdf(response?.pdf_url);
				} else if (response?.status === 'processing') {
					setTimeout(poll, 3000);
				} else {
					setLoading(false);
					message.error('Some error occurred. Please try again');
				}
			});
		};

		poll();
	};
	return (
		<StyledButton
			loading={loading}
			onClick={() => callPrintApi(record?.salesman_id, record?.collection_date)}
		>
			{loading ? 'Downloading' : 'Download'}
		</StyledButton>
	);
}

DownloadButton.propTypes = {
	record: PropTypes.object,
};
